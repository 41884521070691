import { Alert, Button, Checkbox } from "@mui/material";
import Page from "app/components/Page";
import PageImageHeader from "app/components/PageImageHeader";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "utils/hooks/useWindowDimensions";
import { selectError, selectIndividualAccountData, selectLoading } from "../redux/selectors";
import IndividualAccountForm from "../BusinessAccount/CommonForms/IndividualAccountForm";
import { valdiator } from "../BusinessAccount/CommonForms/IndividualAccountForm/validator";
import useStyles from "./styles";
import { actions } from "../redux/slice";
import { actions as authActions } from "../../slice";
import { LoggerClass } from "utils/logger";
import Loader from "app/components/Loader";


const IndividualAccount = () => {
  const { width, height } = useWindowDimensions();
  const dispatch = useDispatch();
  const styles = useStyles();
  const navigation = useNavigate();
  const [disabled, setDisabled] = useState<boolean>(true);
  const [errorMsg, setErrorMsg] = useState('');
  const individualAccountData = useSelector(selectIndividualAccountData);
  const error = useSelector(selectError);
  const loading = useSelector(selectLoading);
  const errors = valdiator(individualAccountData);
  const [checked, setChecked] = useState(true);


  useEffect(() => {
    if (error) setErrorMsg(error);
    if (error === "Unauthorized") {  // || error === "MESSAGE.ALREADY_EXIST"
      dispatch(authActions.resetModule());
      dispatch(actions.resetModule());
      navigation("/auth");
    }
  }, [error]);

  useEffect(() => {
    let allFieldsHaveValue = true;

    for (const key in individualAccountData) {
      if (key !== "address2" && individualAccountData.hasOwnProperty(key) && individualAccountData[key].value.length === 0) {
        allFieldsHaveValue = false;
        break;
      }
    }
    if (allFieldsHaveValue && !checked) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }

  }, [individualAccountData]);


  const handleSubmit = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    if (errors.length) {
      dispatch(actions.setIndividualAccountError({ key: 'addIndividualAccount', errors }))
      return
    }
    dispatch(actions.createIndividualAccount({ data: individualAccountData, callback: onLoginSuccess }));  //will open once recieved api
  };

  const onLoginSuccess = () => {
    dispatch(actions.resetError());
    dispatch(actions.resetindividualAccountData());
    LoggerClass.info("Go to Next Screen");
    navigation("/add-threshold");
  }

  return (
    <>
      <Loader loading={loading} />

      <Page>
        <PageImageHeader />
        <div
          style={{
            width: '100%',
            top: height / 2.82,
          }}
          className={styles.sheet}
        >
          <div className={styles.title}>Create an Account</div>
          <div className={styles.title1} style={{
            marginBottom: "10px"
          }}
          >Create a Personal Verified Customer Record</div>
          {errorMsg && (
            <Alert className={styles.error} severity="error">
              {errorMsg}
            </Alert>
          )}
          <IndividualAccountForm />

          <div className={styles.btmTxt}>
            <Checkbox
              name="phoneCheck"
              checked={!checked}
              value={!checked}
              onChange={(e) => {
                if (e.target.checked) {
                  setChecked(false);
                  setDisabled(false);
                } else {
                  setChecked(true);
                  setDisabled(true);
                }
              }}
              sx={{
                color: "#192342",
                "&.Mui-checked": {
                  color: "#192342",
                },
              }}
            />
            <div className={styles.btmTxtOnly}>
              By Checking this box you are agreeing to our
              <span
                className={styles.span}
                onClick={() => {
                  navigation("/create-account");
                }}
              >
                {" "}
                Terms of service
              </span>{" "}
              and{" "}
              <span
                className={styles.span}
                onClick={() => {
                  navigation("/create-account");
                }}
              >
                {" "}
                Privacy Policy
              </span>
              , as well as our partner Dwolla’s Terms of service and Privacy Policy.
            </div>
          </div>
          <div
            style={{
              marginBottom: "10px",
              width: "90%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              margin: 'auto'
            }}
          >
            <Button
              disabled={disabled}
              className={styles.signInButton}
              variant="contained"
              onClick={handleSubmit}
            >
              Continue
            </Button>

          </div>
        </div>
      </Page>
    </>
  );
};

export default IndividualAccount;
