import { ServiceStatus, ServiceType } from "app/containers/LandlordAccount/redux/Landlord.enum";
import { FormElement } from "app/containers/types";

export interface ResponseError {
  message: string;
}
export type RequestError = ResponseError;

export type MessageType = {
  title: string;
  description: string;
  date: Date;
};

export type Document = {
  DocumentId: FormElement,
  PropertyId: FormElement,
  DocumentURL: string,
  Type : FormElement,
  IsActive: Boolean
}

export type serviceRequestData = {
  OpenedBy: FormElement;
  PropertyId : FormElement;
  PropertyAddress : FormElement;
  ServiceType: ServiceType | null;
  Image : Array<Document>;
  Video : Array<Document>;
  Description : FormElement;
};

export type DashboardData = {
  Image: string ;
  RentAmount: number | null;
  DepositAmount: number | null;
  LeaseExpirationDate: string | null;
  PayDay: number | null;
  AllocatedUsers: [] | null;
  PropertyId: string | null;
  Address: string | null;
  Street: string | null;
  Zip: string | null;
  Latitude: number | null;
  Longitude: number | null;
  City: string | null;
  State: string | null;
  Type: string | null;
  Units: number | null;
  Status: string | null;
  OwnerId: string | null;
  OwnerName: string | null;
  CreateAt: string | null;
  IsIDVerified: boolean | null;
  PropertyStatus: string | null;
};

export type MaintananceRequestBody = {
  propertyIds: Array<string>,
  status : number | null,
  openTime : number | null;
  pageNumber : number,
  pageSize : number,
};

export type ServiceRequestListItemType = {
  ServiceRequestID: string;
  ServiceType: ServiceType;
  PropertyId: string | null;
  PropertyAddress: string | null;
  ServiceProviderID: string | null;
  ServiceProviderName: string | null;
  CreatedDate: string | null;
  OpenedBy: string | null;
  WorkStatus: ServiceStatus;
  Description: string | null;
  AcceptedTimeFrame: string | null;
  VerificationOTP: string | null;
  EstimatedCost: string | null;
  Image: string | null;
  Video: string | null;
};

export type PortfolioListItemType = {
  PropertyId: string;
  OwnerId: string;
  Address: string | null;
  Latitude: string | null,
  Longitude: string | null,
  Zip: string | null;
  State: string | null;
  City: string | null;
  Type: number | null;
  Status: number | null;
  Units: number | null;
  PropertyOccupationStatuses : number | null;
  Street: string | null;
  Image: string | null;
  RentAmount : string | null,
  DepositAmount : string | null,
  LeaseExpirationDate : string | null,
  PayDay : number | null,
  AllocatedUsers: Array<AllocatedPropertyUsers>;
};

export type AllocatedPropertyUsers = {
  UserId: string | null;
  UserName: string | null;  
};

export type TenantStateType = {
  messages: {
    items: Array<MessageType>;
  };
  loading: boolean;
  error:string;
  ImageUploadUrl:string;
  dashboardData: DashboardData;
  serviceRequestData: serviceRequestData;
  MaintananceRequestBody: MaintananceRequestBody;
  serviceRequests: Array<ServiceRequestListItemType>;
  selectedServiceRequests: ServiceRequestListItemType;
  selectedPortfolio: PortfolioListItemType;
  requestVideo: string,
  requestThumbnailVideo: string,
  videoFile?: File | null,
  imageUploadUrl : string | null;
  videoUploadedUrl : string | null;
  videoUploadUrl : any | null;
  addedServiceRequest: any;
}; 

export type ImageUploadRequest = {
  propertyId: string | null,
  uploadedByUserId: string | null,
  documentURL: string | null,
  type: string | null,
  isActive: Boolean | null
}
export const TenantState: TenantStateType = {
  selectedServiceRequests: {
    ServiceRequestID: "",
    ServiceType: ServiceType.AC,
    PropertyId: "",
    PropertyAddress: "",
    ServiceProviderID: "",
    ServiceProviderName: "",
    CreatedDate: "",
    OpenedBy: "",
    WorkStatus: ServiceStatus.NEW,
    Description: "",
    AcceptedTimeFrame: "",
    VerificationOTP: "",
    EstimatedCost: "",
    Image: "",
    Video: ""    
  },
  selectedPortfolio : {
    PropertyId: "",
    OwnerId: "",
    Address: "",
    Latitude: "",
    Longitude: "",
    Zip: "",
    State: "",
    City: "",
    Type: null,
    Status: null,
    Units: null,
    PropertyOccupationStatuses : null,
    Street: "",
    Image: "",
    RentAmount : "",
    DepositAmount : "",
    LeaseExpirationDate : "",
    PayDay : 0,
    AllocatedUsers: []
  },
  addedServiceRequest: "",
  requestVideo: "",
  requestThumbnailVideo: "",
  messages: {
    items: [],
  },
  MaintananceRequestBody: {
    propertyIds: [],
    status: null,
    openTime: null,
    pageNumber: 0,
    pageSize: 100
  },
  serviceRequestData : {
    OpenedBy: {value: "" },
    PropertyId: { value: "" },
    PropertyAddress: {value: ""},
    ServiceType: null,
    Image: [],
    Video: [],
    Description: {value:""}
  },
  serviceRequests: [],
  loading: false,
  error:"",
  ImageUploadUrl:"",
  imageUploadUrl:"",
  videoUploadedUrl: "",
  videoUploadUrl: null,
  dashboardData : {
    Image: "",
    RentAmount: 0,
    DepositAmount: 0,
    LeaseExpirationDate: "",
    PayDay: null,
    AllocatedUsers: null,
    PropertyId: "",
    Address: "",
    Street: "",
    Zip: "",
    Latitude: null,
    Longitude: null,
    City: "",
    State: "",
    Type: "",
    Units: null,
    Status: "",
    OwnerId: "",
    OwnerName: "",
    CreateAt: "",
    IsIDVerified: null,
    PropertyStatus: "",
  },
};

export type ContainerState = TenantStateType;
