import {
    Button,
    Typography,
    IconButton,
    InputAdornment,
    TextField,
    Box,
    Grid,
    Dialog,
    Select,
    MenuItem,
    Radio,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  
  import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
  import {
    actions,
    reducer,
    sliceKey,
  } from "../../../../LandlordAccount/redux/slice";
  import { useLandlordSaga } from "../../../../LandlordAccount/redux/saga";
  import { style } from "@mui/system";
  import { useStyles } from "../../styles";
  import OtpInput from "react-otp-input";
  import { LoggerClass as Logger } from "utils/logger";
  
  type Props = {};
  const Remove = ({ showPopup, closePopup, handleRemove = () => {} }) => {
    useInjectReducer({ key: sliceKey, reducer: reducer });
    useInjectSaga({ key: sliceKey, saga: useLandlordSaga });
    const styles = useStyles();
    const [show, setShow] = useState(false);
    const [reportType, setReportType] = useState<any>(null);
    const [selectedProperties, setSelectedProperties] = useState([]);
    const [timeRange, setTimeRane] = useState("");
    const [error, setError] = useState(false);
    const [otp, setOtp] = useState("");
    const [disable, setDisable] = useState(true);
  
    const close = () => {
      setShow(false);
      closePopup();
    };
  
    useEffect(() => {
      if (otp?.length === 4) {
        setDisable(false);
      } else {
        setDisable(true);
      }
    }, [otp?.length]);
  
    useEffect(() => {
      setShow(showPopup);
    }, [showPopup]);
  
    const handleReportTypeChange = (event) => {
      const option = event.target.value;
      Logger.log(event.target);
      setReportType(option);
    };
  
    const handleChange = (otp) => {
      // dispatch(actions.setOtp(otp));
      // if (otp.length === 4) {
      //   dispatch(
      //     actions.verifyOtpRequest({
      //       callback: (url = "/thankyou") => {
      //         dispatch(actions.setOtp(""));
      //         navigate(url);
      //       },
      //     })
      //   );
      // }
      setOtp(otp);
    };
  
    return (
      <Dialog open={show} className={styles.popup}>
        <Box sx={{ display: "flex", flexDirection: "column", margin: "16px" }}>
          <Typography className={styles.modalTitle}>Remove from this propery </Typography>
          <Typography className={styles.modalSubTitle}>
          Do you really want to remove from this property?
          </Typography>
          
         
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: 24,
            }}
          >
            <Button
              // disabled={reportType ? false : true}
              
              className={styles.signInButton}
              onClick={close}
              style={{ marginLeft: 0 }}
             
            >
              Yes
            </Button>
            <Button
             className={styles.outlinebtn}
            //   disabled={disable}
              onClick={handleRemove}
              variant="outlined"
            >
              No
            </Button>
          </div>
        </Box>
      </Dialog>
    );
  };
  
  export default Remove;
  