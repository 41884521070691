import { Box } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useStyles } from "./styles";

const BottomTabs = ({ index, setIndex }) => {
  const styles = useStyles();
  const navigation = useNavigate();
  return (
    <>
      <Box className={styles.navigation}>
        <Box
          className={styles.navView}
          onClick={() => {
            setIndex(0);
            navigation("/landlord-account/");
          }}
        >
          {index === 0 ? <div className={styles.indicator} /> : null}
          <img
            src={
              index === 0
                ? require("../../../assets/dashboard.png")
                : require("../../../assets/inactive_dashboard.png")
            }
            alt=""
            className={styles.icon}
          />
          <div className={index === 0 ? styles.activenavText : styles.navText}>
            Dashboard
          </div>
        </Box>
        <Box
          className={styles.navView}
          onClick={() => {
            setIndex(1);
            navigation("/landlord-account/portfolio");
          }}
        >
          {index === 1 ? <div className={styles.indicator} /> : null}
          <img
            src={
              index !== 1
                ? require("../../../assets/home.png")
                : require("../../../assets/activeHome.png")
            }
            alt=""
            className={styles.icon}
          />
          <div className={index === 1 ? styles.activenavText : styles.navText}>
            Portfolio
          </div>
        </Box>
        <Box
          className={styles.navView}
          onClick={() => {
            setIndex(2);
            navigation("/landlord-account/accounting");
          }}
        >
          {index === 2 ? <div className={styles.indicator} /> : null}
          <img
            src={
              index !== 2
                ? require("../../../assets/profit.png")
                : require("../../../assets/inactive_profit.png")
            }
            alt=""
            className={styles.icon}
          />
          <div className={index === 2 ? styles.activenavText : styles.navText}>
            Accounting
          </div>
        </Box>
        <Box
          className={styles.navView}
          onClick={() => {
            setIndex(3);
            navigation("/landlord-account/landlord-contacts");
          }}
        >
          {index === 3 ? <div className={styles.indicator} /> : null}
          <img
            src={
              index !== 3
                ? require("../../../assets/contactF.png")
                : require("../../../assets/contactFY.png")
            }
            alt=""
            className={styles.icon}
          />
          <div className={index === 3 ? styles.activenavText : styles.navText}>
            Contacts
          </div>
        </Box>
        <Box
          className={styles.navView}
          onClick={() => {
            setIndex(4);
            navigation("/landlord-account/maintanence");
          }}
        >
          {index === 4 ? <div className={styles.indicator} /> : null}
          <img
            src={
              index !== 4
                ? require("../../../assets/maintenance.png")
                : require("../../../assets/activeMaintanence.png")
            }
            alt=""
            className={styles.icon}
          />
          <div className={index === 4 ? styles.activenavText : styles.navText}>
            Maintenance
          </div>
        </Box>
      </Box>
    </>
  );
};

export default BottomTabs;
