import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  container: {
    backgroundColor: "#FFFFFF",
    minHeight: "100%",
    bottom: "auto",
    width: "100%",
    paddingBottom: 100,
  },

  outstandingBox: {
    marginLeft: "16px",
    marginRight: "16px",
    marginBottom: "24px",
    borderRadius: 20,
    backgroundColor: "#FFF7DD",
    marginTop: "24px",
    paddingTop: 16,
    paddingBottom: 12,
  },
  outstanding: {
    textAlign: "center",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "140%",
    letterSpacing: "0.2px",
    color: "#EB5757",
  },
  outstandingDollar: {
    textAlign: "center",

    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "18px",
    lineHeight: "140%",
    letterSpacing: "0.2px",
    color: "#EB5757",
    marginTop: 4,
    marginBottom: 4,
  },
  due: {
    textAlign: "center",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "16px",
    lineHeight: "140%",
    letterSpacing: "0.2px",
    color: "#192342",
  },
  title: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "140%",
    letterSpacing: "0.2px",
    color: "#192342",
    marginBottom: 16,
    marginLeft: "16px",
    marginRight: "16px",
    marginTop: 24,
  },
  title1: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "18px",
    lineHeight: "140%",
    letterSpacing: "0.2px",
    color: "#192342",
    marginLeft: "16px",
    marginRight: "16px",
  },
  monthBox: {
    borderRadius: 10,
    border: "0.5px solid #BCBCBC",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 1px 5px 9px 10px 10px #C4C4C4",
    marginLeft: "16px",
    marginRight: "16px",
    marginBottom: 10,
    paddingBottom: 10,
  },
  box: {
    borderRadius: 10,
    border: "0.5px solid #BCBCBC",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 1px 5px 9px 10px 10px #C4C4C4",
    marginLeft: "16px",
    marginRight: "16px",
    marginBottom: 10,
  },
  rectBox: {
    marginLeft: 10,
    marginRight: 10,
    marginBottom: 6,
    borderRadius: 10,
    backgroundColor: "#F5F5F5",
  },
  redWallet: {
    width: 32,
    height: 32,
    marginBottom: 12,
  },
  payNowButton: {
    marginLeft: 16.5,
    marginRight: 16.5,
    borderRadius: 8,
    height: 50,
    backgroundColor: "#192342",
    marginTop: 14,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
  },
  payNowText: {
    color: "#FFFFFF",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "18px",
    letterSpacing: "0.2px",
  },
  overDueButton: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    backgroundColor: "#EB5757",
    position: "absolute",
    borderTopRightRadius: 10,
    borderBottomLeftRadius: 10,
    right: 16,
  },
  overDueText: {
    fontSize: 10,
    fontWeight: "bold",
    lineHeight: "140%",
    color: "#FFFFFF",
  },
  warningRow: {
    marginTop: 10,
    display: "flex",
    flexDirection: "row",
    marginLeft: 10,
  },
  image: {
    width: 20,
    height: 20,
  },
  monthRow: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 7,
  },
  monthText: {
    paddingRight: 6,
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "140%",
    letterSpacing: "0.2px",
    color: "#EB5757",
  },
  address: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "140%",
    color: "#192342",
    marginBottom: 10,
  },
  detailsRow: {
    marginLeft: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  detailsBox: {
    marginRight: 12,
    paddingLeft: 7,
    paddingRight: 7,
    paddingTop: 10,
    paddingBottom: 10,
    borderRadius: 10,
    backgroundColor: "#F5F5F5",
  },
  invoiceDate: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "140%",
    color: "#EB5757",
    paddingBottom: 10,
  },
  date: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "140%",
    color: "#192342",
  },
  invoiceAmount: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "140%",
    color: "#192342",
    paddingBottom: 10,
  },
  unpaidButton: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
    backgroundColor: "#006CFD",
    position: "absolute",
    borderTopRightRadius: 10,
    borderBottomLeftRadius: 10,
    right: 16,
  },
  monthRental: {
    paddingRight: 6,
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "140%",
    letterSpacing: "0.2px",
    color: "#006CFD",
  },
  title1Row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 14,
    marginRight: 16,
    marginBottom: 16,
  },
  downloadRow: {
    borderRadius: 8,
    backgroundColor: "#192342",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    paddingBottom: 5,
  },
  download: {
    width: 16,
    height: 16,
  },
  downloadReceipts: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "10px",
    lineHeight: "140%",
    letterSpacing: "0.2px",
    color: "#FFFFFF",
    paddingLeft: 6,
  },
  monthDateRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginLeft: 10,
    marginRight: 10,
    marginTop: 10,
  },
  monthRental1: {
    paddingRight: 6,
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "140%",
    letterSpacing: "0.2px",
    color: "#219653",
  },
  confirmRow: {
    display: "flex",
    flexDirection: "row",
    marginBottom: 10,
  },
  confirmText: {
    paddingRight: 3,
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "140%",
    color: "#192342",
  },
  confirmId: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "140%",
    color: "#192342",
  },
  rectBoxRow: {
    paddingRight: 10,
    paddingLeft: 10,
    paddingTop: 10,
    paddingBottom: 10,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  payee: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "140%",
    color: "#192342",
  },
  tabSection: {
    width: '90%',
    display: 'flex',
    margin : 'auto',
    flexDirection : 'column'
  },
  tabButton : {
    color : "#ffffff",
    fontSize : 14,
    fontWeight: 600,
    fontFamily : 'Open Sans',
    borderRadius : 12,
    height : '45px',
    minHeight : '45px',
    "&.Mui-selected": {
      border: "none",
      backgroundColor: "#F0BB00",
      color: "#192342",
    },
  },
  tabList : {
    padding : 10,
    "& .MuiTabs-indicator": {
      display: "none"
    },
    "& .MuiTabs-flexContainer": {
      justifyContent : 'space-around',
    },        
  },
  tabPanel: {
    padding : 0
  },
});

export default useStyles;
