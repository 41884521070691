import { Button, Checkbox, InputAdornment, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import useWindowDimensions from "utils/hooks/useWindowDimensions";
import { actions } from "../../../redux/slice";
import { actions as profileActions } from "../../redux/slice";
import ChangePassword from "../../Components/Dialog";
import OtpVerify from "../../Components/otp";
import Password from "../../Components/password";
import Success from "../../Components/Success";
import { selectBusinessDetails, selectLoading } from "../../redux/selectors";
import { useStyles } from "../../styles";
import CopyRightSection from "app/components/CopyRight";
import Loader from "app/components/Loader";

const LandlordProileDetails = () => {
  const { state }: any = useLocation();
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const styles = useStyles();
  const [emailCheck, setEmailCheck] = useState(false);
  const [phoneCheck, setPhoneCheck] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [otpPopup, setOtpPopup] = useState(false);
  const [passwordPopup, setPasswordPopup] = useState(false);
  const [successPopup, setSuccessPopup] = useState(false);

  const [value, setValue] = React.useState('1');
  const businessDetail = useSelector(selectBusinessDetails);
  const loading = useSelector(selectLoading);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  useEffect(() => {
    dispatch(actions.setShowBottomNavBar({ status: false, index: 0 }));
    dispatch(profileActions.getBusinessDetails());
  }, [dispatch]);

  const closePopup = () => {
    setShowPopup(false);
    setOtpPopup(false);
    setPasswordPopup(false);
  };

  const handleSelect = () => {
    setShowPopup(false);
    setPasswordPopup(false);
  };

  const handleSend = () => {
    setShowPopup(false);
    setOtpPopup(true);
  };

  const handleVerify = () => {
    setOtpPopup(false);
    setPasswordPopup(true);
  };

  const handleChange = () => {
    setPasswordPopup(false);
    setSuccessPopup(true);
  };

  const handleClose = () => {
    setSuccessPopup(false);
  };

  const goBack = () => {
    navigation(-1);
  };

  const handleKeyEvent = (e) => {
    let charCode = (e.which) ? e.which : e.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      e.preventDefault();
    }
  };

  return (
    <>
      <Loader loading={loading} />
      <ChangePassword
        showPopup={showPopup}
        closePopup={closePopup}
        handleSelect={handleSelect}
        handleSend={handleSend}
      />

      <OtpVerify
        showPopup={otpPopup}
        closePopup={closePopup}
        handleVerify={handleVerify}
      />

      <Password
        showPopup={passwordPopup}
        closePopup={closePopup}
        handleSelect={handleSelect}
        handleChange={handleChange}
      />
      <Success
        showPopup={successPopup}
        closePopup={closePopup}
        handleClose={handleClose}
      />

      <div
        style={{
          flexDirection: "column",
          display: "flex",
          borderRadius: 16,
          justifyContent: "center",
        }}
      >
        {!businessDetail.IsIndividual && <div style={{ marginTop: 16 }}>
          <div className={styles.label}>Business Name</div>
          <TextField
            disabled={true}
            color="primary"
            variant="outlined"
            id="nickName"
            placeholder={"Business Name"}
            name="clientId"
            value={businessDetail.BusinessName}
            className={styles.textStyle}
          />
        </div>
        }
        <div>
          <div className={styles.label}>First Name</div>
          <TextField
            disabled={true}
            color="primary"
            variant="outlined"
            id="nickName"
            placeholder={"First Name"}
            name="clientId"
            value={businessDetail.FirstName}
            className={styles.textStyle}
          />
        </div>
        <div>
          <div className={styles.label}>Last Name</div>
          <TextField
            disabled={true}
            color="primary"
            variant="outlined"
            id="nickName"
            placeholder={"Last Name"}
            name="clientId"
            value={businessDetail.LastName}
            className={styles.textStyle}
          />
        </div>
        <div>
          <div className={styles.label}>E-Mail</div>
          <TextField
            disabled={true}
            color="primary"
            variant="outlined"
            required
            id="nickName"
            placeholder={"Email"}
            name="clientId"
            value={businessDetail.Email}
            className={styles.textStyle}
          />
        </div>


        <div>
          <div className={styles.labelBlue}>Phone Number</div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: '100%',
             // margin: 'auto',
            }}
          >
            <div style={{ flex: '1' }}>
              <TextField
                disabled={true}
                color="primary"
                variant="outlined"
                id="nickName"
                placeholder={"Phone no"}
                name="clientId"
                value={businessDetail.Phone}
                className={styles.textStyle1}
              />
              {state === "changeMobileNumber" && <Typography style={{ marginLeft: 32, color: 'green', fontSize: '10px', marginBottom: '10px' }}>
                A new phone number is successfully verified
              </Typography>}
            </div>
            <div
              onClick={() => {
                navigation("/landlord-account/change-mobile", { state: 'changeMobileNumber' });
              }}
              className={styles.box1}
            >
              Change
            </div>
          </div>
        </div>
        <div>
          <div className={styles.labelBlue}>Threshold Amount</div>
          <TextField
            color="primary"
            variant="outlined"
            id="nickName"
            placeholder={"75$"}
            name="clientId"
            value={businessDetail.ThresholdAmt}
            className={styles.textStyle}
            onKeyDown={handleKeyEvent}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <div className={styles.leftSide}>$</div>
                </InputAdornment>
              ),
            }}
          />
          <div className={styles.thresholdAmountHintTxtStyle}>Minimum 75$</div>
        </div>
        <div className={styles.checkBoxView}>
          <Checkbox
            value={emailCheck}
            onClick={() => {
              setEmailCheck(!emailCheck);
            }}
            sx={{
              color: "#192342",
              "&.Mui-checked": {
                color: "#192342",
              },
            }}
          />
          <div className={styles.checkBoxTitle}>
            Service request estimates below <span style={{ fontWeight: '600' }}>Threshold amount</span> will be automatically approved.
          </div>
        </div>
        <div className={styles.checkBoxView}>
          <Checkbox
            value={phoneCheck}
            onClick={() => {
              setPhoneCheck(!phoneCheck);
            }}
            sx={{
              color: "#192342",
              "&.Mui-checked": {
                color: "#192342",
              },
            }}
          />
          <div className={styles.checkBoxTitle}>
            Send me notifications via SMS
          </div>
        </div>
        <div
          onClick={() => {
            setShowPopup(true);
          }}
          className={styles.boxButton}
        >
          Change password
        </div>
      </div>
      <CopyRightSection />

    </>
  );
};

export default LandlordProileDetails;
