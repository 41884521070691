import { Button, Typography, IconButton, InputAdornment } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useStyles from "./styles";
import SRE_Logo from "../../../assets/logo.png";
import arrowBack from "../../../assets/backArrow.png";
import paypal from "../../../assets/paypal.png";
import paypalText from "../../../assets/paypalText.png";
import checkImage from "../../../assets/check.png";
import venmoImage from "../../../assets/venmo.png";
import zelleImage from "../../../assets/zelle.png";
import { Link } from "react-router-dom";
import CloseIcon from "../../../assets/close.png";
import Dialog from "@mui/material/Dialog";
type Props = {};
const PaymentMethodDetails = (props: Props) => {
  const styles = useStyles();
  const navigate = useNavigate();
  const [zelle, setZelle] = useState("");
  const [zelleError, setZelleError] = useState(false);
  const [venmo, setVenmo] = useState("");
  const [venmoError, setVenmoError] = useState(false);
  const [keyDown, setKeyDown] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  //dialog params
  const [password, setPassword] = useState("");
  const [paypalLoginSuccess, setPaypalLoginSuccess] = useState(false);
  const [showCheckInPaypal, setShowCheckInPaypal] = useState(false);
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [keyDownDialog, setKeyDownDialog] = useState(false);

  const setPopup = () => {
    setShowPopup(true);
  };
  const onKeyDown = (e) => {
    setKeyDown(true);
  };
  const onZelleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setZelle(event.target.value);
  };
  const onVenmoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVenmo(event.target.value);
  };

  const goBack = () => {
    navigate(-1);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    var mobileRegex = /^([0-9]{10})$/;
    if (zelle.length === 0 || zelle === "" || !mobileRegex.test(zelle)) {
      setZelleError(true);
      setTimeout(() => {
        setZelleError(false);
      }, 5000);
    } else if (venmo.length === 0 || venmo === "" || !mobileRegex.test(venmo)) {
      setVenmoError(true);
      setTimeout(() => {
        setVenmoError(false);
      }, 5000);
    } else {
      navigate("/id-verification");
    }
  };
  const handleClose = () => {
    setShowPopup(false);
  };

  const handleSubmitDialog = (e) => {
    e.preventDefault();
    var emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (email.length === 0 || email === "" || !emailRegex.test(email)) {
      setEmailError(true);
      setTimeout(() => {
        setEmailError(false);
      }, 5000);
    } else if (password.length <= 7 || password === "") {
      setPasswordError(true);
      setTimeout(() => {
        setPasswordError(false);
      }, 5000);
    } else {
      setPaypalLoginSuccess(true);
    }
  };
  const onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
    // dispatch(actions.setForm({ key: `loginForm.${name}`, value }));
  };
  const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  const onKeyDownDialog = () => {
    setKeyDownDialog(true);
  };
  const handelBackToLogin = () => {
    setShowCheckInPaypal(true);
    setShowPopup(false);
  };
  return (
    <>
      <Dialog
        open={showPopup}
        className={!paypalLoginSuccess ? styles.popup : styles.popupLowHeight}
        onClose={handleClose}
      >
        <InputAdornment
          position="end"
          sx={{
            height: 0,
            margin: "16px 8px 0 0",
            position: "absolute",
            right: "0",
          }}
        >
          <span style={{ cursor: "pointer" }}>
            <IconButton sx={{ color: "#D1D0D0" }} onClick={handleClose}>
              <img src={CloseIcon} alt={"X"} />
            </IconButton>
          </span>
        </InputAdornment>
        {!paypalLoginSuccess && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "flex-start",
              padding: "10px",
              margin: 0,
            }}
          >
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                marginLeft: "31%",
                alignItems: "center",
              }}
            >
              <div style={{ display: "block" }}>
                <img src={paypalText} alt={"Paypal"} />
              </div>
              <Typography className={styles.dialogTypo}>
                Log in with PayPal
              </Typography>
            </span>
            <span
              className={!emailError ? styles.inputSpan : styles.errorInputSpan}
            >
              <input
                className={styles.inputs}
                placeholder="Enter Email"
                value={email}
                type="text"
                onChange={onEmailChange}
                onKeyDown={onKeyDownDialog}
              />
            </span>
            {emailError && (
              <Typography className={styles.errorTypo}>
                Invalid Email
              </Typography>
            )}
            <span
              className={
                !passwordError ? styles.inputSpan : styles.errorInputSpan
              }
            >
              <input
                className={styles.inputs}
                placeholder="Enter Password"
                value={password}
                type="password"
                onChange={onPasswordChange}
                onKeyDown={onKeyDownDialog}
              />
            </span>
            {passwordError && (
              <Typography className={styles.errorTypo}>
                Invalid Password
              </Typography>
            )}
            <Button
              disabled={!keyDownDialog}
              className={styles.signInButton}
              variant="contained"
              onClick={handleSubmitDialog}
            >
              Login
            </Button>
          </div>
        )}
        {paypalLoginSuccess && (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "flex-start",
              padding: "10px",
              margin: 0,
            }}
          >
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
              }}
            >
              <IconButton
                style={{
                  height: "78px",
                  width: "78px",
                  backgroundColor: "#ffffff",
                  borderRadius: "50%",
                  display: "inline-block",
                  boxShadow:
                    "0 2px 2px 0 rgb(0 0 0 / 20%), 0px 2px 13px 0 rgb(0 0 0 / 15%)",
                  borderWidth: 1,
                  borderColor: "rgba(0,0,0,0.2)",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "15px",
                }}
              >
                <img src={checkImage} alt={"Done"} />
              </IconButton>
              <Typography className={styles.dialogTypo}>
                Login with PayPal is successfully done.
              </Typography>
            </span>
            <Button
              className={styles.signInButton}
              variant="contained"
              onClick={handelBackToLogin}
            >
              Continue
            </Button>
          </div>
        )}
      </Dialog>
      {/* /////////////////////////////////////////////////////////////////////////////////////// */}
      {/* /////////////////////////////////////////////////////////////////////////////////////// */}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          background: "#FFF7DD",
        }}
      >
        <div className={styles.upperContainer}>
          <IconButton
            sx={{ width: "24px", height: "24px", margin: "20px 0 0px 20px" }}
            onClick={goBack}
          >
            <img
              src={arrowBack}
              style={{
                height: 16,
                width: 16,
              }}
              alt="<-"
            />
          </IconButton>
          <div className={styles.logoContainer}>
            <img src={SRE_Logo} alt="logo" className={styles.logo} />
          </div>
        </div>
        <div className={styles.lowerContainer}>
          <Typography className={styles.pageHeading}>My Property</Typography>
          <div className={styles.centerContainer}>
            <Typography className={styles.pageTypo2}>
              Please enter additional data about choosen methods to receive
              rental incomings
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "flex-start",
              padding: "10px",
            }}
          ></div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              width: "100%",
            }}
          >
            <Typography className={styles.pageTypoThreshold}>
              Zelle Phone Number
            </Typography>
            <span
              className={!zelleError ? styles.inputSpan : styles.errorInputSpan}
            >
              <input
                className={styles.inputs}
                placeholder="Enter Phone number"
                value={zelle}
                type="text"
                onChange={onZelleChange}
                onKeyDown={onKeyDown}
              />
              <InputAdornment position="end">
                <span style={{ cursor: "pointer" }}>
                  <IconButton sx={{ color: "#D1D0D0" }}>
                    <img src={zelleImage} alt={""} />
                  </IconButton>
                </span>
              </InputAdornment>
            </span>
            {zelleError && (
              <Typography className={styles.errorTypo}>
                Invalid Phone Number
              </Typography>
            )}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-around",
              width: "100%",
            }}
          >
            <Typography className={styles.pageTypoThreshold}>
              Venmo Phone Number
            </Typography>
            <span
              className={!venmoError ? styles.inputSpan : styles.errorInputSpan}
            >
              <input
                className={styles.inputs}
                placeholder="Enter Phone number"
                value={venmo}
                type="text"
                onChange={onVenmoChange}
                onKeyDown={onKeyDown}
              />
              <InputAdornment position="end">
                <span style={{ cursor: "pointer" }}>
                  <IconButton sx={{ color: "#D1D0D0" }}>
                    <img src={venmoImage} alt={""} />
                  </IconButton>
                </span>
              </InputAdornment>
            </span>
            {venmoError && (
              <Typography className={styles.errorTypo}>
                Invalid Phone Number
              </Typography>
            )}
            {!showCheckInPaypal ? (
              <button className={styles.buttonSpan} onClick={setPopup}>
                <InputAdornment position="end">
                  <span style={{ cursor: "pointer" }}>
                    <IconButton sx={{ color: "#D1D0D0" }}>
                      <img src={paypal} alt={""} />
                    </IconButton>
                  </span>
                </InputAdornment>
                <Typography className={styles.buttonPaypal}>
                  Log in with PayPal
                </Typography>
              </button>
            ) : (
              <span className={styles.inputSpanPaypal}>
                <InputAdornment position="end">
                  <span style={{ cursor: "pointer" }}>
                    <IconButton sx={{ color: "#D1D0D0" }}>
                      <img src={checkImage} alt={""} />
                    </IconButton>
                  </span>
                </InputAdornment>
                <InputAdornment position="end">
                  <span style={{ cursor: "pointer" }}>
                    <IconButton sx={{ color: "#D1D0D0" }}>
                      <img src={paypal} alt={""} />
                    </IconButton>
                  </span>
                </InputAdornment>
                <Typography className={styles.buttonPaypal}>
                  Log in with PayPal
                </Typography>
              </span>
            )}
          </div>
          <div
            style={{
              marginBottom: "10px",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Button
              disabled={!keyDown}
              className={styles.signInButton}
              variant="contained"
              onClick={handleSubmit}
            >
              Continue
            </Button>
            <Typography className={styles.bottomTypo}>
              Do you need help?
              <br />
              Contact us at{" "}
              <Link className={styles.bottomLink} to="/welcomScreen">
                Support@Sre-Management.com
              </Link>
            </Typography>
          </div>
        </div>
      </div>
    </>
  );
};
export default PaymentMethodDetails;
