import { LoginResponse } from "types/LoginResponse";
import { createSlice } from "utils/@reduxjs/toolkit";

import { createAction, PayloadAction } from "@reduxjs/toolkit";
import set from "lodash/set";
import {
  Auth,
  ContainerState,
  CreateAccountSRE,
  initialAuthState,
  LoginErrorType,
  LoginForm,
  SREUser,
  VerificationForm,
} from "./types";
import { FormError } from "../types";
import { LoggerClass as Logger } from "utils/logger";

export const logoutSuccess = createAction("LOGOUT_SUCCESS");
// The initial state of the GithubRepoForm container

const formSlice = createSlice({
  name: "authState",
  initialState: initialAuthState,
  reducers: {
    setForm: (
      state,
      action: PayloadAction<{ name: string; value: string }>
    ) => {
      const { name, value } = action.payload;
      set(state.form, `${name}.value`, value);
      set(state.form, `${name}.error`, "");
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setType: (state, action: PayloadAction<any>) => {
      state.type = action.payload;
    },
    setLoginForm: (
      state,
      action: PayloadAction<{ name: string; value: string }>
    ) => {
      const { name, value } = action.payload;
      set(state.loginForm, `${name}.value`, value);
      set(state.loginForm, `${name}.error`, "");
    },
    setAccountSRE: (
      state,
      action: PayloadAction<{ name: string; value: string; form?: string }>
    ) => {
      const form = action.payload.form ? action.payload.form : "form";
      const { name, value } = action.payload;
      set(state.createAccount, `${name}.value`, value);
      set(state.createAccount, `${name}.error`, "");
    },
    setPropertyDetails: (
      state,
      action: PayloadAction<{ name: string; value: string; form?: string }>
    ) => {
      const form = action.payload.form ? action.payload.form : "form";
      const { name, value } = action.payload;
      set(state.selectedProperty, `${name}.value`, value);
      set(state.selectedProperty, `${name}.error`, "");
    },
    setPropertyDetailsError(
      state,
      action: PayloadAction<{
        key: string;
        errors: Array<FormError>;
      }>
    ) {
      action.payload.errors.forEach((error) => {
        set(state.selectedProperty, `${error.name}.error`, error.error);
      });
    },
    setAccountSREError(
      state,
      action: PayloadAction<{
        key: string;
        errors: Array<FormError>;
      }>
    ) {
      action.payload.errors.forEach((error) => {
        set(state.createAccount, `${error.name}.error`, error.error);
      });
    },
    setLoginFormErrors(
      state,
      action: PayloadAction<{
        key: string;
        errors: Array<FormError>;
      }>
    ) {
      // const { key } = action.payload;
      action.payload.errors.forEach((error) => {
        set(state.loginForm, `${error.name}.error`, error.error);
      });
    },
    setFormErrors(
      state,
      action: PayloadAction<{
        key: string;
        errors: Array<FormError>;
      }>
    ) {
      // const { key } = action.payload;
      action.payload.errors.forEach((error) => {
        set(state.form, `${error.name}.error`, error.error);
      });
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    resetModule: (state) => {
      state.error = initialAuthState.error;
      state.form = initialAuthState.form;
      state.loading = initialAuthState.loading;
      state.otp = initialAuthState.otp;
      state.token = initialAuthState.token;
      state.createAccount = initialAuthState.createAccount;
    },
    resetProperty: (state) => {
      state.addProperty = initialAuthState.addProperty;
    },
    resetCreateAccount: (state) => {
      state.createAccount.name = initialAuthState.createAccount.name;
      state.createAccount.role = initialAuthState.createAccount.role;
    },
    resetPhoneNumber: (state) => {
      state.verification = initialAuthState.verification;
    },
    resetOTP: (state) => {
      state.otp = initialAuthState.otp;
    },
    resetLogin: (state) => {
      state.loginForm = initialAuthState.loginForm;
    },
    setOtp: (state, action) => {
      state.otp.value = action.payload;
      // state.error = "";
    },
    logout(state) {},
    login(state) {
      state.loading = true;
      state.error = null;
    },
    createAccountRequest: (state, action) => {
      state.loading = true;
    },
    forgotPassword(state, action: PayloadAction<{ callback: () => void }>) {
      state.loading = true;
    },
    loginSuccess(state, action: PayloadAction<LoginResponse>) {
      state.form = initialAuthState.form;
      state.loading = false;
      state.error = null;
    },
    loginError(state, action: PayloadAction<any>) {
      state.error = action.payload;
      state.loading = false;
    },
    setError(state, action) {
      state.error = action.payload.message;
      state.invalidAttempts.attempts = action.payload.attempts;
      state.invalidAttempts.duration = action.payload.duration;
      state.loading = false;
    },
    createAccountSuccess: (state, action) => {
      state.loading = false;
    },
    SignUp: (state, action: PayloadAction<{ callback: () => void }>) => {
      state.loading = true;
    },
    createAccountWithRole: (state, action: PayloadAction<any>) => {
      state.loading = true;
    },
    createAccountWithRoleSuccess: (state, action: PayloadAction<SREUser>) => {
      state.user = { ...action.payload };
      state.token = action.payload.jwtToken;
      state.loading = false;
    },
    createAccountWithRoleError(state, action: PayloadAction<any>) {
      state.error = action.payload;
      state.loading = false;
    },
    createAccountSRE: (state, action: PayloadAction<CreateAccountSRE>) => {
      state.loading = true;
      state.createAccount = action.payload;
      state.loading = false;
    },
    createAccountSRESuccess: (state, action) => {
      state.loading = false;
    },
    verifyOtpRequest: (state, action) => {
      state.loading = true;
    },
    selectedTypes: (state, action) => {
      state.userType = action.payload;
    },
    getPropertiesAddressList: (state, action) => {
      state.loading = true;
    },
    getPropertiesAddressListSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.propertyList = action.payload;
    },
    setPropertiesAddressListSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.propertyList = action.payload;
    },
    getPropertiesAddressListFailed: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
    },
    otpRequest: (state, action) => {
      state.loading = true;
    },
    setVerificationSRE: (
      state,
      action: PayloadAction<{ name: string; value: string; form?: string }>
    ) => {
      const { name, value } = action.payload;
      set(state.verification, `${name}.value`, value);
      set(state.verification, `${name}.error`, "");
    },
    otpReceivedSuccess: (state, action) => {
      state.loading = false;
    },
    otpReceivedError: (state, action) => {
      state.otp.error = action.payload;
      state.loading = false;
    },
    setOtpSRE: (state, action: PayloadAction<{ value: string }>) => {
      state.otp.value = action.payload.value;
    },

    setOtpErrorSRE: (state) => {
      state.otp.error = false;
    },

    verificationOtp: (state, action) => {
      state.loading = true;
    },
    otpVerifiedSuccess: (state, action) => {
      state.loading = false;
    },
    setotpVerifiedError: (state) => {
      state.otp.error = state.otp.error;
    },
    otpVerifiedError: (state, action) => {
      state.otp.error = !action.payload;
      state.loading = false;
    },
    getLandlordViaProperty: (state, action) => {
      state.loading = true;
    },
    getLandlordViaPropertySuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.landLordData = action.payload;
    },
    getLandlordViaPropertyFailed: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
    },
    LinkTanentToLandLord: (state, action) => {
      state.loading = true;
    },
    LinkTanentToLandLordSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.tanentLandLordAsso = action.payload;
    },
    LinkTanentToLandLordFailed: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
    },
    setLoginSuccess(state, action: PayloadAction<SREUser>) {
      state.user = { ...action.payload };
      state.token = action.payload.jwtToken;
      state.loading = false;
      state.error = null;
    },
    setLoginError(state, action: PayloadAction<any>) {
      state.error = action.payload;
      state.loading = false;
    },
    setCompany: (state, action: PayloadAction<{ company: string }>) => {
      state.company = action.payload.company;
      state.error = null;
    },
    createCompanyListRequest: (
      state,
      action: PayloadAction<{ callback: () => void }>
    ) => {
      state.loading = true;
      state.error = null;
    },
    createCompanyListSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
    },
    createCompanyListError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    otpCommonError: (state, action) => {
      state.error = action.payload;
    },
    setPropertyData: (
      state,
      action: PayloadAction<{ name: string; value: string; form?: string }>
    ) => {
      const form = action.payload.form ? action.payload.form : "form";
      const { name, value } = action.payload;
      set(state.addProperty, `${name}.value`, value);
      set(state.addProperty, `${name}.error`, "");
    },
    addPropertyData: (state, action: PayloadAction<any>) => {
      state.loading = true;
    },
    addPropertyDataSuccess: (state, action: PayloadAction<any>) => {
      state.addedProperty = action.payload.Data;
      state.loading = false;
    },
    addPropertyDataError(state, action: PayloadAction<any>) {
      state.error = action.payload;
      state.loading = false;
    },
    addPropertyFormDataError(
      state,
      action: PayloadAction<{
        key: string;
        errors: Array<FormError>;
      }>
    ) {
      action.payload.errors.forEach((error) => {
        set(state.addProperty, `${error.name}.error`, error.error);
      });
    },
    setLeaseData: (
      state,
      action: PayloadAction<{ name: string; value: string; form?: string }>
    ) => {
      const form = action.payload.form ? action.payload.form : "form";
      const { name, value } = action.payload;
      set(state.addLease, `${name}.value`, value);
      set(state.addLease, `${name}.error`, "");
    },
    resetLeaseData: (state) => {
      state.addLease = initialAuthState.addLease;
      state.addedLease = initialAuthState.addedLease;
    },
    addLeaseData: (state, action: PayloadAction<any>) => {
      state.loading = true;
    },
    addLeaseDataSuccess: (state, action: PayloadAction<any>) => {
      state.addedLease = action.payload.Data;
      state.loading = false;
    },
    addLeaseDataError(state, action: PayloadAction<any>) {
      state.error = action.payload;
      state.loading = false;
    },
    setUploadedImage(state, action: PayloadAction<File>) {
      state.uploadedImageFile = action.payload;
    },
    setUploadedDocument(state, action: PayloadAction<File>) {
      state.uploadedDocFile = action.payload;
    },
    getDocumentUploadUrl(state, action) {
      state.loading = true;
    },
    getDocumentUploadUrlSuccess(state, action) {
      state.loading = false;
      if (action.payload.type === "properties/images") {
        state.uploadedImageURL = action.payload.response;
      } else {
        state.uploadedDocumentURL = action.payload.response;
      }
    },
    getDocumentUploadUrlError(state, action) {
      state.error = action.payload;
      state.loading = false;
    },

    setDashboardData: (
      state, action
    ) => {
      Logger.log("Auth Slice");
      state.loading = true;
    },
    
    setDashboardDataSuccess: (
      state,
      action: PayloadAction<any>
    ) => {
      state.loading = false;
    },

    setDashboardDataError(state, action: PayloadAction<any>) {
      state.error = action.payload;
      state.loading = false;
    },

    resetError: (state) => {
      state.error = initialAuthState.error;
    },
  },
});

export const { actions, reducer, name: sliceKey } = formSlice;
