import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  profile: {
    width: 35,
    height: 35,
    marginRight: 13.5,
    borderRadius:20
  },
  name: {
    fontSize: 18,
    color: "#192342",
    fontFamily: "Open Sans",
    fontWeight: "600",
    alignSelf: "center",
  },
  logOutButton: {
    "&:disabled": {
      background: "#C4C4C4",
      color: "#6B6B6B",
    },
    display: "flex",
    margin: "0px 16px",
    width: "91%",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: "50px",
    background: "#ffffff",
    color: "#192342",
    borderRadius: "10px",
    border: "0.5px solid #BCBCBC",
    boxShadow:
      "10px 18px 8px rgba(168, 168, 168, 0.01), 5px 10px 7px rgba(168, 168, 168, 0.05), 2px 5px 5px rgba(168, 168, 168, 0.09), 1px 1px 3px rgba(168, 168, 168, 0.1), 0px 0px 0px rgba(168, 168, 168, 0.1)",
    "&:hover": {
      background: "#ffffff",
      color: "#192342",
    },
  },
  profileView: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
    justifyContent: "space-between",
    marginLeft: 16,
    marginRight: 16,
    paddingTop: 16,
    marginBottom: 20,
  },
  icon: {
    height: 32,
    width: 32,
    alignSelf: "center",
    "-webkit-transform": "rotate(0deg)",
  },
  iconRotate: {
    height: 32,
    width: 32,
    alignSelf: "center",
    "-webkit-transform": "rotate(180deg)",
  },
  innerView: {
    display: "flex",
    flexDirection: "row",
  },
});
