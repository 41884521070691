import {Alert, Button, Typography} from "@mui/material";
import Page from "app/components/Page";
import PageImageHeader from "app/components/PageImageHeader";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "utils/hooks/useWindowDimensions";
import { selectControllerOwnerData, selectError, selectLoading } from "../../redux/selectors";
import { valdiator } from "../CommonForms/BusinessControllerOwnerForm/validator";
import useStyles from "./styles";
import { actions } from "../../redux/slice";
import BusinessControllerOwnerForm from "../CommonForms/BusinessControllerOwnerForm";
import { PersonType, UserTypeEnum } from "../../redux/enum";
import { LoggerClass } from "utils/logger";
import Loader from "app/components/Loader";
import CopyRightSection from "app/components/CopyRight";


const BusinessAccountController = () => {
  const { width, height } = useWindowDimensions();
  const dispatch = useDispatch();
  const styles = useStyles();
  const navigation = useNavigate();
  const [disabled, setDisabled] = useState<boolean>(true);
  const businessControllerData = useSelector(selectControllerOwnerData);
  const errors = valdiator(businessControllerData);
  const loading = useSelector(selectLoading);
  const [errorMsg, setErrorMsg] = useState('');
  const error = useSelector(selectError);



  useEffect(() => {
    if (error) setErrorMsg(error);
    if (error === "Unauthorized") {  
        navigation("/");
      }
    }, [error]);

  useEffect(() => {
  dispatch(actions.setControllerOwnerData({ name : "userType", value : UserTypeEnum.CONTROLLER+"", form: "addControllerOwner" }));
  let allFieldsHaveValue = true;
  for (const key in businessControllerData) {
    if ((Number(businessControllerData['personType'].value) === PersonType.US) && (key !== "passportNumber" && key !== "issuanceCountry" && key !== "address2" && key !== "country" && key !== "ownerAndControllerId" && key !== "userInfoId" && businessControllerData.hasOwnProperty(key) && businessControllerData[key].value.length === 0)) {
        allFieldsHaveValue = false;
      break;
    } 
    
    if ((Number(businessControllerData['personType'].value) === PersonType.NON_US) && (key !== "ssn" && key !== "address2" && key !== "ownerAndControllerId" && key !== "userInfoId" && businessControllerData.hasOwnProperty(key) && businessControllerData[key].value.length === 0)) {
      allFieldsHaveValue = false;
    break;
    } 
  }
    if (allFieldsHaveValue) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }

  }, [businessControllerData]);
  
  const handleSubmit = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    if(errors.length){
      dispatch(actions.setControllerOwnerError({ key: 'addBusinessAccount', errors }))
      return
    }
    dispatch(actions.createControllerOwner({data: businessControllerData, callback: onLoginSuccess}));
 
  };

  const onLoginSuccess = () => {
    dispatch(actions.resetOwnerAndController());
    navigation("/add-threshold");
    LoggerClass.info("Go to Next Screen");  
  };

  return (
    <>
    <Loader loading={loading} />    
    <Page>
      <PageImageHeader/>
      <div
        style={{
          width: '100%',
          top: height / 2.82,
        }}
        className={styles.sheet}
      >
        <div className={styles.title}>Create an Account</div>
        <div className={styles.title1} style={{
            marginBottom: "10px"}}
            >Add Controller</div> 
          <div className={styles.subHeading}>
          A Controller is Any natural individual who holds significant responsibilities to control, manage, or direct a company or other corporate entity (i.e. CEO, CFO, General Partner, President, etc). A company may have more than one controller, but only one controller’s information must be collected.</div> 
          {errorMsg && (
<Alert className={styles.error} severity="error">
{errorMsg}
</Alert>
)}
  <BusinessControllerOwnerForm formType={'Controller'}/>

        <div
          style={{
            marginBottom: "10px",
            width: "90%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            margin: 'auto'
          }}
        >
          <Button
            disabled={disabled}
            className={styles.signInButton}
            variant="contained"
            onClick={handleSubmit}
          >
            Continue
          </Button>          
        </div>   

        <CopyRightSection/>        
      </div>
    </Page>
    </>
  );
};

export default BusinessAccountController;
