import { Button, Typography, IconButton, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useStyles from "./styles";
import arrowBack from "../../../../assets/backArrow.png";
import deletepng from "../assets/delete.png";
import accountpng from "../assets/account.png";
import editpng from "../assets/edit.png";
import messagepng from "../assets/message.png";
import settingpng from "../assets/setting.png";
import statpng from "../assets/stat.png";
import mappng from "../assets/map.png";
import redpinpng from "../../../../assets/redpin.png";
import { useSelector } from "react-redux";
import {
  getSelectedPortfolio,
  selectDailogStatus,
  selectEditProperty,
  selectLoading,
  selectPropertyError,
} from "../redux/selector";
import { useDispatch } from "react-redux";
import { actions } from "../redux/slice";
import PropertyReportDialog from "./Components/Dialog";
import EditPropertyDialog from "./Components/EditProperty";
import Delete from "./Components/Delete";
import Register from "./Components/Register";
import DeleteTenant from "./Components/DeleteTenant";
import Message from "./Components/Message";
import DeletePropertyDialog from "./Components/DeleteProperty";

import GoogleMapReact from "google-map-react";
import { GOOGLE_API } from "utils/constants";
import moment from "moment";
import Loader from "app/components/Loader";
import { LoggerClass as Logger } from "utils/logger";

const tenantName = [{ name: "John Snow" }, { name: "Sansa Snow" }];

const AnyReactComponent = ({ text }) => (
  <div style={{ color: "black", fontWeight: "700" }}>{text}</div>
);

type Props = {};
const MyProperty = (props: Props) => {
  const styles = useStyles();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [showDeletePropertyPopup, setShowDeletePropertyPopup] = useState(false);
  const [username, setUsername] = useState("");
  const [nextRantalPay, setnextRantalPay] = useState("");
  const [showDeletePopup, setShowDeletePopup] = useState<any>(false);
  const [showRegisterPopUp, setShowRegisterPopUp] = useState(false);
  const [deleteTenantPopUp, setDeleteTenantPopup] = useState(false);
  const [selectedCheck, setSelectedCheck] = useState([]);
  const [confirmMsg, setConfirmMsg] = useState(false);
  const propertyError = useSelector(selectPropertyError);

  const latitude = localStorage.getItem("latitude");
  const longitude = localStorage.getItem("longitude");

  const dailogStatus = useSelector(selectDailogStatus);
  const loading = useSelector(selectLoading);

  useEffect(() => {}, [showEditPopup]);

  useEffect(() => {
    if (propertyError === "Unauthorized") {
      dispatch(actions.resetModule());
      dispatch(actions.resetError());
      navigate("/auth");
    }
  }, [propertyError]);

  useEffect(() => {
    Logger.log(dailogStatus);
    if (dailogStatus) {
      setShowEditPopup(true);
    }
  }, [dailogStatus]);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(actions.setShowBottomNavBar({ status: false, index: 1 }));
    dispatch(
      actions.setSelectedPortfolioDetails({ id: location.search.substring(4) })
    );
    localStorage.setItem("property", location.search.substring(4));
    dispatch(actions.setLeaseState("editProperty"));
  }, []);

  const closePopup = () => {
    setShowPopup(false);
    setShowDeletePopup(false);
    setShowRegisterPopUp(false);
    setDeleteTenantPopup(false);
    setConfirmMsg(false);
  };

  const goBack = () => {
    navigate("/landlord-account/portfolio");
  };
  const property = useSelector(getSelectedPortfolio);

  const defaultProps = {
    center: {
      lat: property?.Latitude ? Number(property?.Latitude) : Number(latitude),
      lng: property?.Longitude
        ? Number(property?.Longitude)
        : Number(longitude),
    },
    zoom: 12,
  };

  useEffect(() => {
    let fullname = "";
    property?.AllocatedUsers?.map((user, index) => {
      let tempname = user.UserName;
      if (index !== 0) {
        fullname = fullname + "," + tempname;
      } else {
        fullname = user.UserName + "";
      }
    });
    setUsername(fullname);

    const currentDate = new Date();
    currentDate.setDate(property?.PayDay ? Number(property?.PayDay) : 1);
    currentDate.setMonth(currentDate.getMonth() + 1);
    const PayDay = currentDate.toISOString().substring(0, 10);
    setnextRantalPay(PayDay);
  }, [property]);

  const handleGenerateReport = () => {
    setShowPopup(true);
  };

  const handleEditClick = () => {
    setShowEditPopup(true);
  };

  const closeEditPopup = () => {
    setShowEditPopup(false);
  };
  const closeDeletePropertyPopup = () => {
    setShowDeletePropertyPopup(false);
  };
  const handleChange = () => {
    setShowDeletePopup(false);
    setShowRegisterPopUp(true);
  };

  const handleDelete = (selectedCheck) => {
    setSelectedCheck(selectedCheck);
    setDeleteTenantPopup(false);
    setConfirmMsg(true);
  };

  return (
    <>
      <Loader loading={loading} />
      {showPopup && (
        <PropertyReportDialog
          showPopup={showPopup}
          closePopup={closePopup}
          address={property?.Address}
        />
      )}
      {showEditPopup && (
        <EditPropertyDialog
          showPopup={showEditPopup}
          closePopup={closeEditPopup}
          address={property?.Address}
          occupationStatus={property?.Status}
          propertyId={property?.PropertyId}
        />
      )}
      {showDeletePropertyPopup && (
        <DeletePropertyDialog
          showPopup={showDeletePropertyPopup}
          closePopup={closeDeletePropertyPopup}
          address={property?.Address}
          id={property?.PropertyId}
        />
      )}

      <Delete
        showPopup={showDeletePopup}
        closePopup={closePopup}
        handleChange={handleChange}
      />
      <Register showPopup={showRegisterPopUp} closePopup={closePopup} />
      <DeleteTenant
        showPopup={deleteTenantPopUp}
        values={property?.AllocatedUsers}
        closePopup={closePopup}
        handleDelete={(selectedCheck: any) => {
          handleDelete(selectedCheck);
        }}
      />
      <Message
        showPopup={confirmMsg}
        selectedCheck={property?.AllocatedUsers}
        closePopup={closePopup}
      />
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "16px 16px",
        }}
      >
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <IconButton
              sx={{
                width: "16px",
                height: "16px",
                padding: "0",
                alignSelf: "center",
              }}
              onClick={goBack}
            >
              <img
                src={arrowBack}
                style={{ objectFit: "contain", width: "100%" }}
                alt="<-"
              />
            </IconButton>
            <Typography className={styles.title}>My Property</Typography>
            <Box
              sx={{
                width: "35px",
                height: "35px",
                padding: "0",
                alignSelf: "center",
              }}
            >
              <img
                src={accountpng}
                style={{ objectFit: "cover", width: "100%" }}
                alt={"Account"}
              />
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "200px",
              marginTop: "24px",
              filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
              position: "relative",
            }}
          >
            <img
              src={property?.Image ? property?.Image : "/homeImageOne.png"}
              style={{
                objectFit: "cover",
                width: "100%",
                height: "200px",
                borderRadius: "10px",
              }}
              alt="no image"
            />
            <Box
              sx={{
                position: "absolute",
                top: "10px",
                left: "10px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{ marginRight: "10px" }}
                className={styles.typo40014}
              >
                <label className={styles.typo60014}>Rent</label> :{" "}
                {property?.RentAmount}$
              </Typography>
              <Typography className={styles.typo40014}>
                <label className={styles.typo60014}>Deposit</label> :{" "}
                {property?.DepositAmount}$
              </Typography>
            </Box>
            <Box
              sx={{
                position: "absolute",
                bottom: "0px",
                right: "0px",
                background: "#F0BB00",
                borderRadius: "10px 0px",

                height: "31px",
              }}
            >
              <Typography className={styles.typo12600button}>
                {property?.PropertyOccupationStatuses === 1
                  ? "Pending Tanent Approval"
                  : "Ok"}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              height: "260px",
              border: "0.5px solid #BCBCBC",
              boxShadow:
                "9px 19px 8px rgba(199, 199, 199, 0.01), 5px 11px 7px rgba(199, 199, 199, 0.05), 2px 5px 5px rgba(199, 199, 199, 0.09), 1px 1px 3px rgba(199, 199, 199, 0.1), 0px 0px 0px rgba(199, 199, 199, 0.1)",
              borderRadius: "0px 0px 10px 10px",
              marginTop: "-15px",
              padding: "10px",
            }}
          >
            <Typography className={styles.address}>
              {property?.Address + " "}
            </Typography>
            <Box sx={{ width: "100%", height: "220px", padding: "10px" }}>
              <GoogleMapReact
                bootstrapURLKeys={{ key: `${GOOGLE_API}` }}
                defaultCenter={defaultProps.center}
                defaultZoom={defaultProps.zoom}
              >
                <img src={redpinpng} />
                <AnyReactComponent text={property?.Address} />
              </GoogleMapReact>
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              border: "0.5px solid #BCBCBC",
              boxShadow:
                "9px 19px 8px rgba(199, 199, 199, 0.01), 5px 11px 7px rgba(199, 199, 199, 0.05), 2px 5px 5px rgba(199, 199, 199, 0.09), 1px 1px 3px rgba(199, 199, 199, 0.1), 0px 0px 0px rgba(199, 199, 199, 0.1)",
              borderRadius: "10px",
              padding: "10px",
              margin: "24px 0 10px 0",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Typography className={styles.typo13700}>
                Property’s Occupation Status
              </Typography>
              <IconButton
                sx={{ width: "15px", height: "15px", padding: "0" }}
                onClick={handleEditClick}
              >
                <img
                  src={editpng}
                  style={{ objectFit: "cover", width: "100%" }}
                  alt={"edit"}
                />
              </IconButton>
            </Box>
            <Typography className={styles.typoLeft40016}>
              {property?.Status === 0
                ? "Vacant"
                : property?.Status === 1
                ? "Tenant Occupied"
                : "Owner Occupied"}
            </Typography>
          </Box>
          {property?.Status === 1 && username && (
            <>
              <Box
                sx={{
                  width: "100%",
                  border: "0.5px solid #BCBCBC",
                  boxShadow:
                    "9px 19px 8px rgba(199, 199, 199, 0.01), 5px 11px 7px rgba(199, 199, 199, 0.05), 2px 5px 5px rgba(199, 199, 199, 0.09), 1px 1px 3px rgba(199, 199, 199, 0.1), 0px 0px 0px rgba(199, 199, 199, 0.1)",
                  borderRadius: "10px",
                  padding: "10px",
                  margin: "0px 0 10px 0",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography className={styles.typo13700}>
                    Tenant name
                  </Typography>
                  <Box sx={{ width: "15px", height: "15px", padding: "0" }}>
                    <img
                      src={deletepng}
                      style={{ objectFit: "cover", width: "100%" }}
                      alt={"delete"}
                      onClick={() => {
                        setSelectedCheck([]);
                        setDeleteTenantPopup(true);
                      }}
                    />
                  </Box>
                </Box>
                <Typography className={styles.typoLeft40016}>
                  {username}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  margin: "0px 0 24px 0",
                }}
              >
                <Box
                  sx={{
                    width: "48%",
                    height: "66px",
                    border: "0.5px solid #BCBCBC",
                    boxShadow:
                      "9px 19px 8px rgba(199, 199, 199, 0.01), 5px 11px 7px rgba(199, 199, 199, 0.05), 2px 5px 5px rgba(199, 199, 199, 0.09), 1px 1px 3px rgba(199, 199, 199, 0.1), 0px 0px 0px rgba(199, 199, 199, 0.1)",
                    borderRadius: "10px",
                    padding: "10px",
                  }}
                >
                  <Typography className={styles.typo13700}>
                    Next rental payment
                  </Typography>
                  <Typography className={styles.typoLeft40016}>
                    {nextRantalPay}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "48%",
                    height: "66px",
                    border: "0.5px solid #BCBCBC",
                    boxShadow:
                      "9px 19px 8px rgba(199, 199, 199, 0.01), 5px 11px 7px rgba(199, 199, 199, 0.05), 2px 5px 5px rgba(199, 199, 199, 0.09), 1px 1px 3px rgba(199, 199, 199, 0.1), 0px 0px 0px rgba(199, 199, 199, 0.1)",
                    borderRadius: "10px",
                    padding: "10px",
                  }}
                >
                  <Typography className={styles.typo13700}>
                    Lease Expiration
                  </Typography>
                  <Typography className={styles.typoLeft40016}>
                    {moment(property?.LeaseExpirationDate).format("YYYY-MM-DD")}
                  </Typography>
                </Box>
              </Box>
            </>
          )}
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Button
              className={styles.signInButton}
              variant="contained"
              onClick={handleGenerateReport}
            >
              <IconButton
                sx={{
                  width: "14px",
                  height: "18px",
                  marginRight: "10px",
                  padding: "0",
                  alignSelf: "center",
                }}
              >
                <img
                  src={statpng}
                  style={{ objectFit: "contain", width: "100%" }}
                  alt="<-"
                />
              </IconButton>
              <Typography className={styles.exitButtonTypo}>
                Generate Year-To-Date report
              </Typography>
            </Button>
            <Button className={styles.yellowButton} variant="contained">
              <IconButton
                sx={{
                  width: "14px",
                  height: "18px",
                  padding: "0",
                  marginRight: "10px",
                  alignSelf: "center",
                }}
              >
                <img
                  src={settingpng}
                  style={{ objectFit: "contain", width: "100%" }}
                  alt="<-"
                />
              </IconButton>
              <Typography className={styles.typo70014}>
                Open Service Request
              </Typography>
            </Button>
            <Button
              className={styles.signInButtonColorInvert}
              variant="contained"
              onClick={() => {}}
            >
              <IconButton
                sx={{
                  width: "14px",
                  height: "18px",
                  padding: "0",
                  marginRight: "10px",
                  alignSelf: "center",
                }}
              >
                <img
                  src={messagepng}
                  style={{ objectFit: "contain", width: "100%" }}
                  alt="<-"
                />
              </IconButton>
              <Typography className={styles.typo70014}>Messenger</Typography>
            </Button>
            <Button
              className={styles.redButton}
              variant="contained"
              onClick={() => {
                setShowDeletePropertyPopup(true);
              }}
            >
              <IconButton
                sx={{
                  width: "14px",
                  height: "18px",
                  padding: "0",
                  marginRight: "10px",
                  alignSelf: "center",
                }}
                //   onClick={goBack}
                onClick={() => {}}
              >
                <img
                  src={deletepng}
                  style={{ objectFit: "contain", width: "100%" }}
                  alt="<-"
                />
              </IconButton>
              <Typography
                sx={{ color: "#EB5757 !important" }}
                className={styles.typo70014}
              >
                Delete
              </Typography>
            </Button>
          </Box>
        </>
      </Box>
    </>
  );
};

export default MyProperty;
