import React, { useEffect, useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers";
const DatePickerBusiness = (
  {
    handleChange,
    value,
    width
  },
  props
) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <DesktopDatePicker
      onChange={handleChange}
      value={value}
      disableFuture
      sx={{width: width}}
    />
  </LocalizationProvider>
    );
};

export default DatePickerBusiness;
