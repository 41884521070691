import { IS_DEVELOPMENT } from "./constants";

export class LoggerClass {
    
    static log(...args) {
        IS_DEVELOPMENT && console.log(...args);
    }
    static warn(...args) {
        IS_DEVELOPMENT && console.warn(...args);
    }
    static info(...args) {
        IS_DEVELOPMENT &&
        console.info(
          '%c Custom Log:',
          'background: blue; color: white;',
          ...args,
        );
    }
  }