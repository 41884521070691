import { Button, Typography, IconButton, InputAdornment } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useStyles from "./styles";
import SRE_Logo from "../../../assets/logo.png";
import arrowBack from "../../../assets/backArrow.png";
import { Link } from "react-router-dom";
type Props = {};
const IDVerificationFailed = (props: Props) => {
  // const { width, height } = useWindowDimensions();
  const styles = useStyles();
  const navigate = useNavigate();
  // const loading = useSelector(selectLoading);
  const [amount, setAmount] = useState("");
  const [amountError, setAmountError] = useState(false);
  const [keyDown, setKeyDown] = useState(false);
  const onKeyDown = (e) => {
    setKeyDown(true);
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(event.target.value);
    // dispatch(actions.setForm({ key: `loginForm.${name}`, value }));
  };

  const goBack = () => {
    navigate(-1);
  };

  const handleSubmit = () => {
    navigate("/id-verification-success");
  };

  const checkBox = () => {};
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        background: "#FFF7DD",
      }}
    >
      <div className={styles.upperContainer}>
        <IconButton
          sx={{ width: "24px", height: "24px", margin: "20px 0 0px 20px" }}
          onClick={goBack}
        >
          <img
            src={arrowBack}
            style={{
              height: 16,
              width: 16,
            }}
            alt="<-"
          />
        </IconButton>
        <div className={styles.logoContainer}>
          <img src={SRE_Logo} alt="logo" className={styles.logo} />
        </div>
      </div>
      <div className={styles.lowerContainer}>
        <Typography className={styles.pageHeading}>My Property</Typography>
        <div className={styles.centerContainer}>
          <Typography
            sx={{ color: "#EB5757 !important", margin: "14px 0px 30px 0px" }}
            className={styles.pageTypo0}
          >
            ID Verification is failed!
          </Typography>
          <Button
            className={styles.signInButton}
            sx={{ margin: "0 0 24px 0" }}
            variant="contained"
            onClick={handleSubmit}
          >
            <Typography
              sx={{
                fontFamily: "Open Sans",
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: "14px",
                letterSpacing: "0.2px",
              }}
            >
              Try again
            </Typography>
          </Button>
          <Typography
            className={styles.pageTypo5}
            sx={{
              position: "relative",
              marginRight: "auto",
              left: "0",
              textAlign: "center",
            }}
          >
            Pending On-boarding:
          </Typography>
        </div>
        <span className={styles.whiteSpan}>
          <Typography
            sx={{
              fontFamily: "Open Sans",
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "16px",
              letterSpacing: "0.1px",
            }}
          >
            OLD 356 STEESE HWY, Boston, USA, 5668
          </Typography>
        </span>
        <Typography
          sx={{
            fontFamily: "Open Sans",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "16px",
            letterSpacing: "0.1px",
          }}
        >
          Adding properties will be Processed only when ID Verification is
          successfully completed.
        </Typography>
        <Typography className={styles.bottomTypo}>
          Do you need help?
          <br />
          Contact us at{" "}
          <Link className={styles.bottomLink} to="/welcomScreen">
            Support@Sre-Management.com
          </Link>
        </Typography>
      </div>
    </div>
  );
};

export default IDVerificationFailed;
