import { colors, createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    // mode: "light",
    background: {
      default: "#ffffff",
      paper: "#ffffff",
    },
    primary: {
      main: "#F0BB00",
    },
    secondary: {
      main: "#EB5757",
    },

    text: {
      primary: "#192342",
      secondary: "red",
      disabled: "#7D7D7D",
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          // background:
          //   'linear-gradient(90deg, #C6AC73 -13.37%, #9F854C 113.95%)',
          background: "#3B85C2",
          color: "#ffffff",
        },
        root: {
          padding: "10px 30px",
          borderRadius: "10px",
          width: "90%",
          fontWeight: "600",
          fontSize: "18px",
          background: "#8BC4C1",
          color: "#ffffff",
          backgroundColor: "#82BCEE",
          "&:hover": {
            // opacity: '0.2',
            //   backgroundColor: '#82BCEE',
            background: "#192342",
            color: "#ffffff",
          },
          "&:active": {
            // opacity: '0.2',
            //   backgroundColor: '#82BCEE',
            background: "#192342",
            color: "#ffffff",
          },
          "&.Mui-disabled": {
            //   border: '1px solid #8BC4C1',
            //   opacity: '0.2',
            backgroundColor: "#C4C4C4",
            color: "#6B6B6B",
          },
        },
        outlined: {
          background: "none",
          color: "#192342",
          border: "2px solid #192342",
          "&:hover": {
            // border: '1px solid rgba(236, 250, 236, 0.2)',
            border: "2px solid #192342",
            background: "none",
            // opacity: '0.2',
            color: "black",
          },
          "&:active": {
            // border: '1px solid rgba(236, 250, 236, 0.2)',
            // opacity: '0.2',
            backgroundColor: "none",
            border: "2px solid #192342",
            color: "#192342",
          },
          "&.Mui-active": {
            border: "2px solid #192342",
            opacity: "0.2",
            backgroundColor: "#3B85C2",
            color: "#1F1F1E",
          },
        },
        text: {
          background: "none",
          border: "none",
          color: "black",
          "& .css-gcc2o7-MuiButton-startIcon": {
            marginRight: "4px",
            marginLeft: 0,
            marginBottom: "3px",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          background: "#FFF7DD",
          borderRadius: "25px",
          // color: 'red',
          height: 48,
          border: "0.1px solid #FFF7DD",
          fontSize: "16px",
          "input[type=text]:focus": {
            color: "#192342",
            //   opacity: '1.0 !important',
            // border: '1px  black',
          },

          "&:active": {
            // opacity: '0.2',
            // backgroundColor: '#8BC4C1',
            //   color: 'red',
            // border: '1px solid black',
          },
          "& focused": {
            border: "1px solid black",
          },
          "&.Mui-disabled": {
            backgroundColor: "#E0E0E0",
            color: "#6B6B6B",
          },
        },
      },
    },
  },
  typography: {
    // fontFamily:'Source Sans 3 sans-serif !important',
    allVariants: {
      textTransform: "none",
      fontFamily: `sans-serif `,
    },
  },
});

export default theme;
