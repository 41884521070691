import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  error: {
    width:'80%',
    alignSelf:'center',
    marginTop:24
},
title:{
  fontSize: 18,
  fontWeight: "600",
  color: "#192342",
  alignSelf: "center",
  marginTop:'10%',
  fontFamily:"Open Sans",
  marginBottom:6    
},
title1: {
  fontSize: 16,
  fontWeight: "600",
  color: "#192342",
  alignSelf: "center",
  marginTop:20,
  fontFamily:"Open Sans",
  marginBottom:6 
},  
  sheet: {
    display: "flex",
    flex: 1,
    backgroundColor: "#ffffff",
    position: "absolute",
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    flexDirection: "column",
  },
  signInButton: {
    "&:disabled": {
      background: "#C4C4C4",
      color: "#6B6B6B",
    },
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 16px",
    gap: "10px",
    height: "50px",
    background: "#192342",
    color: "#ffffff",
    borderRadius: "8px",
    marginTop: 19,
    marginBottom: 24,
    "&:hover": {
      background: "#192342",
      color: "#ffffff",
    },
  },
  btmTxt: {
    fontSize: 12,
    fontWeight: "400",
    // alignSelf: "center",
    marginTop: 24,
    // marginBottom: 40,
    textAlign: "left",
    marginLeft: 20,
    marginRight: 20,
    display: 'flex',
    alignItems: 'flex-start'
  }, 
  span: { color: "#006CFD", cursor: "pointer" },
  btmTxtOnly: {

  },
});

export default useStyles;
