import React from "react";

import { Box } from "@mui/material";

const Page = (props) => {
  return (
    <Box
      //   mx={"auto"}
      bgcolor={"#FFFFFF"}
      //   className={props?.className}
      minHeight="100vh"
      //   display={"flex"}
      //   minHeight="100%"
      //   flex={1}
      //   position="absolute"
      //   bottom="auto"
    >
      <Box position={"relative"}>{props?.children}</Box>
    </Box>
  );
};

export default Page;
