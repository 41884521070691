import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useStyles from "../styles";
import { actions } from "../../redux/slice";
import { useDispatch } from "react-redux";
import { LoggerClass as Logger } from "utils/logger";

type Props = {};
const Messenger = (props: Props) => {
  const styles = useStyles();
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const goBack = () => {
    navigation(-1);
  };
  const [chatMessage, setChatMessage] = useState<any>();
  const [typeMessage, setTypeMessage] = useState(false);
  const [chatArray, setChatArray] = useState([
    {
      title: "John Smith",
      description:
        "Hello, I need to postpone the rental payment this month for few days.",
      time: "15:15",
    },
    {
      title: "Me",
      description: "No problem",
      time: "15:20",
    },
    {
      title: "John Smith",
      description: "Thanks! I will Pay on sunday",
      time: "15:22",
    },
  ]);
  useEffect(() => {
    dispatch(actions.setShowBottomNavBar({ status: false, index: 2 }));
  }, []);
  return (
    <>
      <div className={styles.container}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "24px",
            marginTop: 3,
          }}
        >
          <IconButton onClick={goBack}>
            <ArrowBackIcon
              sx={{
                height: 24,
                width: 24,
                color: "#192342",
              }}
            />
          </IconButton>
          <Typography className={styles.titleAlignCenter}>Messenger</Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            paddingRight: "16px",
            position: "relative",
            marginBottom: "24px",
            marginTop: "24px",
            backgroundColor: "#F0BB00",
            height: 67,
          }}
        >
          <div className={styles.houseRow}>
            <div style={{ float: 'left', width: '14%' }}>
            <img
              src={require("../../../../../app/assets/house1.png")}
              style={{ width: 44, height: 44, borderRadius: 10 }}
            />
            </div>
            <div style={{ paddingLeft: 10, width: '100%' }}>
              <Typography className={styles.address}>
                3429 Adkins Rd Nw Atlanta, 3429 Adkins Rd Nw Atlanta 3429 Adkins Rd Nw Atlanta
              </Typography>
              <Typography className={styles.Names}>John Smith</Typography>
            </div>
          </div>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginBottom: "24px",
          }}
        >
          <Typography className={styles.dob}> Jul 13, 2022</Typography>
        </Box>

        <div style={{ marginBottom: 100 }}>
          {chatArray.map((item, index) => {
            return (
              <div
                className={
                  item.title === "Me"
                    ? styles.messageCard
                    : styles.alternativeCard
                }
              >
                <div className={styles.title}>{item?.title}</div>
                <div className={styles.description}>{item?.description}</div>
                <div className={styles.time}>{item?.time}</div>
              </div>
            );
          })}
        </div>

        <TextField
          sx={{
            mb: 0,
            "& fieldset": {
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
              height: 50,
            },
          }}
          placeholder={"Type a message"}
          value={chatMessage}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setChatMessage(event.target.value);
            if(event.target.value.length > 0) {
              setTypeMessage(true);
            } else {
              setTypeMessage(false);
            }           
          }}
          className={styles.textStyle}
          style={{ position: "fixed", bottom: 20 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img
                  src={require("../../../../../app/assets/emojis.png")}
                  alt=""
                  style={{ height: 24, width: 24 }}
                />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {typeMessage ? 
                <img
                src={require("../../../../../app/assets/sendYellow.png")}
                alt=""
                style={{ height: 24, width: 24, cursor: "pointer" }}
                onClick={() => {
                  Logger.log("chatMessage", chatMessage);
                  setChatArray((chatArray) => [
                    ...chatArray,
                    {
                      title: "Me",
                      description: chatMessage,
                      time: "20.15",
                    },
                  ]);
                  setChatMessage("");
                  setTypeMessage(false);
                }}
              /> :
              <img
                  src={require("../../../../../app/assets/send.png")}
                  alt=""
                  style={{ height: 24, width: 24 }}                  
                /> }
                
              </InputAdornment>
            ),
          }}
        />
      </div>
    </>
  );
};
export default Messenger;
