import * as React from "react";
import useStyles from "./styles";
import { Box } from "@mui/material";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { useState } from "react";
import { useDispatch } from "react-redux";

export default function SearchGoogleAddress({ handleChange, value, name }) {
  const dispatch = useDispatch();

  const styles = useStyles();

  const [googleAddress, setGoogleAddress] = useState(null);
  const getCords = (value) => {
    const returnObject = { address: '', street: '', zip : '', city: '', state : '', latitude : '', longitude: ''};
        geocodeByAddress(value)
       .then(async (results) =>  {
        await getLatLng(results[0]).then(({ lat, lng }) =>{ 
          returnObject.latitude = lat+"";
          returnObject.longitude = lng+"";
        });
        returnObject.address = results[0].formatted_address;
        returnObject.street = results[0].formatted_address;
        for (let component of results[0].address_components) {
          if (component.types.includes('postal_code')) {
            returnObject.zip = component.long_name;
          }
          if (component.types.includes('administrative_area_level_1')) {
            returnObject.state = component.long_name;
          }
          if (component.types.includes('locality')) {
            returnObject.city = component.long_name;
          }
        }
        handleChange(returnObject);

      }
      );      
  };

  return (
    <Box sx={{ width: "100%" }}>
<GooglePlacesAutocomplete
             autocompletionRequest={{
              componentRestrictions: {
                country: 'us'
              }
             }}
             selectProps={{
               type: "regions",
               placeholder: "Start typing street name",
               googleAddress,
               onChange: (changedText) => {                 
                 getCords(changedText.value.description);
                 setGoogleAddress(changedText);
               },
               styles: {
                  loadingIndicator: (provided) => ({
                   ...provided,
                   display: "none",
                   visibility: "hidden",
                 }),
                 indicatorSeparator: (provided) => ({
                   ...provided,
                   background: "red",
                   display: "none",
                   visibility: "hidden",
                 }),
                 Svg: (provided) => ({
                   provided,
                   display: "none",
                   visibility: "hidden",
                 }),
                 indicatorContainer: (provided) => ({
                   ...provided,
                   display: "none !important",
                   visibility: "hidden",
                 }),
                 control: (provided) => ({
                   ...provided,
                   background: "none !important",
                   borderStyle: "unset !important",
                   border: "none !important",
                   boxShadow: "none",
                   padding: '10px 16px',
                 }),
                 input: (provided) => ({
                   ...provided,
                   width: "100%",
                   height: "48px",
                   background: "#FFF7DD",
                   color: "#7D7D7D",
                   borderRadius: "20px",
                   border: "unset",
                   outline: "none",
                   padding: '10px 1px',
                 }),
                 selectValue: (provided) => ({
                   ...provided,
                   width: "100%",
                   height: "48px",
                   background: "red",
                   borderRadius: "20px",
                   border: "unset",
                   outline: "none",
                   padding: '10px 16px',
                 }),
               },
             }}
           />
           </Box>
  );
}
