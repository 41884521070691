import Page from "app/components/Page";
import PageImageHeader from "app/components/PageImageHeader";
import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Alert,
  Button,
  InputAdornment,
  Radio,
  TextField,
  Typography,
} from "@mui/material";
import { useStyles } from "./styles";
import { useSelector } from "react-redux";
import { selectCreateAccountSRE, selectCreateAccountSREError } from "../selectors";
import { useDispatch } from "react-redux";
import { actions } from "../slice";
import useWindowDimensions from "utils/hooks/useWindowDimensions";

type Props = {};

const AccountType = (props: Props) => {
  const tempData = useSelector(selectCreateAccountSRE);
  const error = useSelector(selectCreateAccountSREError);
  const { width, height } = useWindowDimensions();
  const [errorMsg, setErrorMsg] = useState('');
  const [selectedBox, setSelectedBox] = useState("tenant");
  const [disabled, setDisabled] = useState(true);
  const [errorMessage, setErrorMessage] = useState(false);  
  const dispatch = useDispatch();

  const styles = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    if (error) setErrorMsg(error);
  }, [error]);

  useEffect(() => {
    if (tempData.name.value.trim() && tempData.role.value == "tenant") {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [tempData.name.value]);

  useEffect(() => {
    if (tempData.landLordType.value.trim() && tempData.role.value == "landlord") {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [tempData.landLordType.value]);

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch(
      actions.setAccountSRE({ name, value, form: 'createAccount' }),
    );
  }; 

  const handleSubmit = () => {
    dispatch(actions.createAccountWithRole({data: tempData, callback: onLoginSuccess}));
  };

  const onLoginSuccess = () => {
    // if(tempData.role.value === "landlord" && tempData.landLordType.value === "business") {
    //     navigate("/business-account", { state: selectedBox });
    // } else if(tempData.role.value === "landlord" && tempData.landLordType.value === "individual") {
    //     navigate("/individual-account", { state: selectedBox });
    // } else {
    //     dispatch(actions.resetPhoneNumber());
    //     navigate("/verification", { state: selectedBox });
    // }
    dispatch(actions.resetPhoneNumber());
    navigate("/verification", { state: selectedBox });
  };
 
  return (
    <Page>
      <PageImageHeader
        subHeading={
          <div style={{ display: "flex" }}>
            <Typography sx={{ fontSize: 14, color: "#fff" }}>
              {"have account"}
            </Typography>
            <Typography
              onClick={() => {
                navigate("/auth");
              }}
              sx={{
                fontSize: 14,
                color: "#FAA61A",
                fontWeight: "bold",
                textDecoration: "underline",
                marginLeft: "4px",
              }}
            >
              {"login"}
            </Typography>
          </div>
        }

      />

      <div
        style={{
          width: width,

          top: height / 2.82,
        }}
        className={styles.sheet}
      >
        <div className={styles.title}>Create an Account</div>
        {errorMsg && (
        <Alert className={styles.error} severity="error">
          {errorMsg}
        </Alert>
      )}
        <div className={styles.title}>Choose an Account type</div>
        
        <div className={styles.subTitle}>
          Select the account type that suits you the best. Every account has a
          different set of tools.
        </div>

        <div
          className={selectedBox === "tenant" ? styles.colorBox : styles.box}
          onClick={() => {
            dispatch(actions.setAccountSRE({ name: 'role', value: 'tenant', form: 'createAccount' }));
            dispatch(actions.setAccountSRE({ name: 'landLordType', value: '', form: 'createAccount' }));
            dispatch(actions.selectedTypes("tenant"));
            setSelectedBox("tenant");
          }}
        >           
          <div
            className={
              selectedBox === "tenant" ? styles.colorBoxTitle : styles.boxTitle
            }
          >
            TENANT
          </div>
          <div className={styles.boxSubTitle}>
            Pay Rent or open service request online
          </div>
        </div>

        <div
          className={selectedBox === "landlord" ? styles.colorBox : styles.box}
          onClick={() => {
            dispatch(actions.setAccountSRE({ name: 'role', value: 'landlord', form: 'createAccount' }));
            dispatch(actions.setAccountSRE({ name: 'name', value: '', form: 'createAccount' }));
            dispatch(actions.selectedTypes("landlord"));
            setSelectedBox("landlord");
          }}
        >         
          <div
            className={
              selectedBox === "landlord"
                ? styles.colorBoxTitle
                : styles.boxTitle
            }
          >
            PROPERTY OWNER
          </div>
          <div className={styles.boxSubTitle}>
            Collect rent and manage the rental investment
          </div>
        </div>
        
        {tempData.role.value === "tenant" ? <TextField
          sx={{
            mb: 0,
            "& fieldset": {
              border: "1px solid #FFF7DD",
              alignItems: "center",              
            },
          }}
          color="primary"
          variant="outlined"
          required
          id="name"
          placeholder={"Enter Full Name"}
          name="name"
          value={tempData.name.value}
          onChange={handleFieldChange}
          className={styles.textStyle}
            style={{ borderRadius: 16, marginLeft: 5 }}           
        />
        : 

          <div className=""> 
          <Typography style={{textAlign: 'center', margin: 'auto', width: '90%',  marginTop: '30px'}}>Select Individual or Business account.The relevant KYC verification process is required.</Typography>
           <div style={{margin: '20px 30px 0 30px', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <Radio
                className={styles.checkbox}
                checked={tempData.landLordType.value === "individual"}
                name="landLordType"
                onChange={handleFieldChange}
                value="individual"
              />
              <div className={styles.checkboxText}>Individual</div>
              <Radio
              className={styles.checkbox}
              name="landLordType"
              checked={tempData.landLordType.value === "business"}
              onChange={handleFieldChange}
              value="business"
            />
            <div className={styles.checkboxText}>Business</div>
            </div>
            </div>
        }

        <Button
          type="submit"
          variant="contained"
          sx={{
            width: width / 1.15,
          }}
          disabled={disabled}
          className={styles.btn}
          onClick={handleSubmit}          
        >
          Continue
        </Button>

       
      </div>
    </Page>
  );
};

export default AccountType;
