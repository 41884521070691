import {
  Button,
  Checkbox,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import Page from "app/components/Page";
import PageImageHeader from "app/components/PageImageHeader";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import useWindowDimensions from "utils/hooks/useWindowDimensions";
import { selectError, selectVerificationSRE } from "../selectors";
import { actions } from "../slice";
import { useStyles } from "./styles";
import { actions as landlordActions } from "../../LandlordAccount/redux/slice"

const numberValidation = new RegExp("^[0-9,\b][0-9,\b]*$");

const Verification = () => {
  const { width, height } = useWindowDimensions();
  const dispatch = useDispatch();
  const styles = useStyles();
  const navigation = useNavigate();
  const verification = useSelector(selectVerificationSRE);
  const [disabled, setDisabled] = useState(false);
  const location = useLocation();
  const { state } = location;
  const redirect = useSelector(selectError);
  useEffect(() => {
    if (redirect === "Unauthorized") {
      dispatch(actions.resetModule());
      navigation("/auth");
    }
  }, [redirect]);

  useEffect(() => {
    if(state === 'changeMobileNumber') {
    dispatch(landlordActions.setShowBottomNavBar({ status: false, index: 0 }));
    }
  }, [dispatch]);

  useEffect(() => {
    if (
      (state !== 'changeMobileNumber' && verification.phoneNumber.value.length === 10 && verification.phoneNumber.value.toLowerCase()
      .match(numberValidation)) && 
      (verification.emailCheck.value || verification.phoneCheck.value)
    ) {
      setDisabled(false);
    } else if (
      (state === 'changeMobileNumber' && verification.phoneNumber.value.length === 10 && verification.phoneNumber.value.toLowerCase()
      .match(numberValidation))
    ) {
      setDisabled(false);
    }else {
      setDisabled(true);
    }
  }, [
    verification.emailCheck,
    verification.phoneCheck,
    verification.phoneNumber.value,
  ]);

  const onChangeEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch(actions.setVerificationSRE({ name, value, form: "verification" }));
  };

  const handleCheck = (e: any) => {
    const { name, checked: value } = e.target;
    dispatch(actions.setVerificationSRE({ name, value, form: "verification" }));
  };

  const handleContinue = () => {
    dispatch(actions.otpRequest({ data: verification, callback: otpSent }));
  };

  const otpSent = () => {
    dispatch(actions.resetOTP());
    if(state === 'changeMobileNumber') {
      navigation("/landlord-account/verify-otp", {state : state});
    } else {
      navigation("/otp");
    }    
  };

 
  const handleKeyEvent = (e) => {
    let charCode = (e.which) ? e.which : e.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
        e.preventDefault();
    }
  };

  return (
    <Page>
      <PageImageHeader
        subHeading={
          <div style={{ display: "flex" }}>
            <Typography sx={{ fontSize: 14, color: "#fff" }}>
              {"have account"}
            </Typography>
            <Typography
              sx={{
                fontSize: 14,
                color: "#FAA61A",
                fontWeight: "bold",
                textDecoration: "underline",
                marginLeft: "4px",
              }}
            >
              {"login"}
            </Typography>
          </div>
        }
      />

      <div
        style={{
          width: width,
          top: height / 2.82,
        }}
        className={styles.sheet}
      >
        <div className={styles.title}>{state === 'changeMobileNumber' ? 'Change Phone Number' : 'My Property' }</div>
        {state === 'changeMobileNumber' && <div className={styles.title1}>Add new phone number</div> }

       {state !== 'changeMobileNumber' ? ( <div className={styles.subTitle}>
          <span className={styles.spanTitle}>Add your phone number</span> to be
          used with notifications and communications
        </div> ) : <div className={styles.subTitle}>To be used with notifications and messages</div>}
        <TextField
          color="primary"
          variant="outlined"
          required
          id="nickName"
          placeholder={"Phone Number"}
          name="phoneNumber"
          value={verification.phoneNumber.value}
          onChange={onChangeEvent}
          onKeyDown={handleKeyEvent}
          className={styles.textStyle}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <div className={styles.leftSide}>+1</div>
              </InputAdornment>
            ),
          }}
        />
        
        {state !== 'changeMobileNumber' && (
        <><div className={styles.checkBoxView}>
          <Checkbox
            name="emailCheck"
            checked={verification.emailCheck.value}
            value={verification.emailCheck.value}
            onClick={handleCheck}
            sx={{
              color: "#192342",
              "&.Mui-checked": {
                color: "#192342",
              },
            }}
          />
          <div className={styles.checkBoxTitle}>
            Send me notifications via Email
          </div>
        </div>
        <div className={styles.checkBoxView}>
          <Checkbox
            name="phoneCheck"
            checked={verification.phoneCheck.value}
            value={verification.phoneCheck.value}
            onClick={handleCheck}
            sx={{
              color: "#192342",
              "&.Mui-checked": {
                color: "#192342",
              },
            }}
          />
          <div className={styles.checkBoxTitle}>
            Send me notifications via SMS
          </div>
        </div>
        </> )
      }

        <Button
          variant="contained"
          sx={{
            width: width / 1.15,
          }}
          disabled={disabled}
          className={styles.btn}
          onClick={handleContinue}
        >
          Continue
        </Button>

        <div className={styles.footerTxt}>Do you need help?</div>
        <div className={styles.footerTxt}>
          Contact us at{" "}
          <span className={styles.footTxt}> Support@Sre-Management.com</span>
        </div>
      </div>
    </Page>
  );
};

export default Verification;
