import Logout from "app/containers/LandlordAccount/Dashboard/Components/Logout";
import useWindowDimensions from "utils/hooks/useWindowDimensions";
import { Button } from "@mui/material";
import React, { useState } from "react";
import LogoutDialog from "./LogoutDialog/LogoutDialog";
import { useStyles } from "./styles";
import { selectUser } from "app/containers/Auth/selectors";
import { useSelector } from "react-redux";
import { selectLoggedInUserData } from "app/containers/LandlordAccount/redux/selector";


type Props = {
  imageId?: string;
  title?: string;
  rightIcon?: boolean;
  handleGallery?: () => void;
  handleNavigation?: () => void;
};

const Header = ({
  imageId = "",
  rightIcon = true,
  handleGallery = () => {},
  handleNavigation = () => {},
}: Props) => {
  const styles = useStyles();
  const [showPopup, setShowPopup] = useState(false);
  const { width, height } = useWindowDimensions();
  const [logoutPopUp, setLogOut] = useState(false);

  const [flipArrow, setFlipArrow] = useState(false);
  const [confirmLogout, setConfirmLogOut] = useState(false);

  const loggedInUser = useSelector(selectUser);
  const loggedInUserData = useSelector(selectLoggedInUserData);

  const arrowClick = () => {
    setFlipArrow(!flipArrow);
  };

  const logoutClick = () => {
    setConfirmLogOut(true);
  };
  const closeLogoutPopup = () => {
    setConfirmLogOut(false);
    setFlipArrow(!flipArrow);
  };

  const closeLeasePopup = () => {
    setLogOut(false);
  };

  return (
    <div>
      <Logout showPopup={logoutPopUp} closePopup={closeLeasePopup} />
      <div className={styles.profileView}>
        <div
          onClick={() => {
            if (rightIcon) {
              handleNavigation();
            }
          }}
          className={styles.innerView}
        >
          {!rightIcon ? (
            <img
              onClick={() => {
                handleGallery();
              }}
              src={require("../../assets/addImage.png")}
              alt=""
              style={{
                height: 18,
                width: 18,
                // display: "flex",
                // flex: 1,
                // alignSelf: "end",
                position: "absolute",
                marginLeft: 25,
              }}
            />
          ) : null}
          <img
            src={
              imageId !== "" && imageId
                ? imageId
                : require("../../assets/profile.png")
            }
            alt=""
            className={styles.profile}
          />
          <div className={styles.name}>{loggedInUserData.FirstName || loggedInUserData.LastName ? loggedInUserData.FirstName+" "+loggedInUserData.LastName : localStorage.getItem('ownerName')}</div>
        </div>
        {rightIcon ? (
          <img
            src={
              showPopup
                ? require("../../assets/uparrow.png")
                : require("../../assets/downArrow.png")
            }
            alt=""
            className={styles.icon}
            onClick={() => {
              setShowPopup(!showPopup);
            }}
          />
        ) : null}
      </div>
      {showPopup ? (
        <div
          style={{
            backgroundColor: "white",
            display: "flex",
            justifyContent: "center",
            border: "1px solid #BCBCBC",
            borderRadius: 10,
            marginLeft: 16,
            marginRight: 16,
            paddingTop: 10,
            paddingBottom: 10,
            marginTop: 10,
          }}
          onClick={() => {
            setShowPopup(false);
            setLogOut(true);
          }}
        >
          Log out
        </div>
      ) : null}

      {flipArrow && (
        <Button onClick={logoutClick} className={styles.logOutButton}>
          Logout
        </Button>
      )}
    </div>
  );
};

export default Header;
