import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  menuPaper: {
    height: "195px",
  },
  select: {
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "none !important",
      borderRadius: "none",
      color: "#FF0000"
    },
    border: "none !important",
    height: "48px",
    background: "#FFF7DD",
    borderRadius: "20px",
    margin: "5px 0px 0px 0px",
    color: '#ada796',
    font: 'inherit',
  },
  typog: {
    color: '#7D7D7D'
  }
});

export default useStyles;
