import { Box, Button, IconButton, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import useStyles from "../../styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import StepOneComponent from "./StepOne";
import { useNavigate } from "react-router-dom";
import StepTwoComponent from "./StepTwo";
import StepThreeComponent from "./StepThree";
import { useDispatch, useSelector } from 'react-redux';
import { selectLoading } from "app/containers/CommonComponent/Property/redux/selector";

import { selectServiceRequestData } from "app/containers/LandlordAccount/redux/selector";
import { actions } from "app/containers/LandlordAccount/redux/slice";
import Loader from "app/components/Loader";
import { LoggerClass as Logger } from "utils/logger";
const AddNewServiceRequest = ({ returnToParent }) => {
  const styles = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [serviceTypeSelected, setServiceTypeSelected] = useState("");
  const [imageUploaded, setImageUploaded] = useState<any>("");
  const [imageStatus, setImageStatus] = useState(true);
  const [videoStatus, setVideoStatus] = useState(true);
  const [stepOne, showStepOne] = useState(true);
  const [stepTwo, showStepTwo] = useState(false);
  const [stepThree, showStepThree] = useState(false);
  const [buttonEnable, setButtonEnable] = useState(true);
  const [description, setDescription] = useState("");


  const serviceRequestData = useSelector(selectServiceRequestData);
  const loading = useSelector(selectLoading);

  Logger.log(serviceRequestData);
 
  const goBack = () => {
    if (stepOne) {
      returnToParent();
      dispatch(actions.resetUploadedUrl());
    } else if (stepTwo) {
      showStepTwo(false);
      showStepOne(true);
    } else if (stepThree) {
      showStepThree(false);
      showStepTwo(true);
    }
  };
  const handleNextClick = () => {
    if (stepOne === true) {
      showStepOne(false);
      showStepTwo(true);
      dispatch(actions.resetUploadedUrl());
    } else if (stepTwo === true) {
      showStepTwo(false);
      showStepThree(true);
    } else if (stepThree === true) {
      showStepThree(false);
      returnToParent();
    }
  };
  useEffect(() => {}, [buttonEnable]);
  return (
    <>
    <Loader loading={loading} />
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        margin: "16px 16px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          marginBottom: "24px",
        }}
      >
        <IconButton onClick={goBack}>
          <ArrowBackIcon
            sx={{
              height: 24,
              width: 24,
              color: "#192342",
            }}
          />
        </IconButton>
        <Typography className={styles.typo60018AlignCenter}>
          Create service request
        </Typography>
      </Box>
      {stepOne && (
        <StepOneComponent
          inputTypes={serviceTypeSelected}
          selectedService={(output) => {
            setButtonEnable(output.status);
            setServiceTypeSelected(output.serviceType);
          }}
        />
      )}
      {stepTwo && (
        <StepTwoComponent
          inputImage={imageUploaded}
          imageStatus={imageStatus}
          videoStatus={videoStatus}
          selectedService={(output) => {
            setButtonEnable(output.status);
            setImageUploaded(output.uploadedImage);
            setImageStatus(output.imageStatus);
            setVideoStatus(output.videoStatus);
          }}
        />
      )}

      {stepThree && (
        <StepThreeComponent
          descriptionProp={description}
          selectedService={(output) => {
            setButtonEnable(output.status);
            setDescription(output.description);
          }}
        />
      )}

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          marginBottom: "100px",
        }}
      >
        <Button
          className={styles.signInButton}
          disabled={buttonEnable}
          variant="contained"
          onClick={handleNextClick}
        >
          {stepThree ? "Submit" : "Next"}
        </Button>
      </Box>
    </Box>
    </>
  );
};

export default AddNewServiceRequest;
