import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  sheet: {
    display: "flex",
    flex: 1,
    backgroundColor: "#ffffff",
    position: "absolute",
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    flexDirection: "column",
    paddingBottom: 20,
  },
  title: {
    fontSize: 18,
    fontWeight: "600",
    color: "#192342",
    alignSelf: "center",
    // marginTop:20,
    fontFamily: "Open Sans",
    marginBottom: 24,
    marginTop: 24,
  },
  subTitle: {
    fontSize: 14,
    color: "#192342",
    fontWeight: "400",
    // marginTop: 16,
    alignSelf: "center",
    textAlign: "center",
    fontFamily: "Open Sans",
    marginBottom: 10,
    marginLeft: 36,
    marginRight: 36,
  },
  textStyle: {
    width: "100%",
    alignSelf: "center",
    marginTop: 5,
    // marginBottom:10
  },
  leftSide: {
    backgroundColor: "#FFFBEE",
    color: "#7D7D7D",
    paddingLeft: 10,
    paddingRight: 10,
    fontSize: 16,
    borderRadius: 6,
    paddingTop: 2,
    paddingBottom: 2,
  },
  checkBoxView: {
    flexDirection: "row",
    display: "flex",
    flex: 1,
    marginBottom: 10,
    marginleft: 19,
  },
  checkBoxTitle: {
    alignSelf: "center",
    fontSize: 14,
  },
  btn: {
    fontSize: "14px",
    fontWeight: 700,
    alignSelf: "center",
    padding: "12px 0",
    borderRadius: "10px",
    backgroundColor: "#192342",
    // margin: "30px 0px",
    color: "#FFFFFF",
    marginTop: 40,
    // display:'flex',
    // flex:1,
    marginBottom: 10,
  },
  smallbtn: {
    backgroundColor: "#F0BB00",
  },
  footerTxt: {
    fontSize: 12,
    fontWeight: 400,
    fontFamily: "Open Sans",
    textAlign: "center",
    color: "#192342",
  },
  footTxt: {
    fontSize: 12,
    fontWeight: 600,
    fontFamily: "Open Sans",
    textAlign: "center",
    color: "#006CFD",
  },
  spanTitle: {
    fontWeight: "600",
  },
  label: {
    fontSize: 14,
    fontFamily: "Open Sans",
    color: "#192342",
    marginTop: 24,
    // marginBottom:5
  },
  inputView: { marginLeft: 16, marginRight: 16 },
  error: {
    width: "80%",
    alignSelf: "center",
    marginTop: 24,
  },
});
