import React, { useEffect, useState } from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers";
const DatePicker = (
  {
    handleChange,
    value
  },
  props
) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
    <DesktopDatePicker
      onChange={handleChange}
      value={value}
      disablePast
    />
  </LocalizationProvider>
    );
};

export default DatePicker;
