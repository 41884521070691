import { Button, Typography, IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import OtpVerification from "../OTPVerification";
import useStyles from "./styles";
import { Link } from "react-router-dom";
import PageContainer from "app/components/Container/PageContainer";
import { emailValidator } from "utils/helpers";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { selectError, selectLoading, selectLoginForm, selectUser } from "../selectors";
import { actions } from "../slice";
import Loader from "app/components/Loader";
import { LoggerClass } from "utils/logger";
type Props = {};
const Login = (props: Props) => {
  const styles = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const login = useSelector(selectLoginForm);
  const user = useSelector(selectUser);
  const loginError = useSelector(selectError);
  const [isOtpVerify, setIsOtpVerify] = useState<boolean>(false);
  const [error, setError] = useState(false);
  const [visible, setVisible] = useState(false);
  const [keyDown, setKeyDown] = useState(false);
  const loading = useSelector(selectLoading);

  const changePasswordType = () => {
    setVisible(!visible);
  };
  useEffect(() => {
    if (loginError === "Unauthorized") {
      setError(true);
      setTimeout(() => {
        setError(false);
      }, 5000);
    }
  }, [loginError]);
  useEffect(() => {
    if (
      login.email.value.length === 0 ||
      login.email.value === "" ||
      !emailValidator(login.email.value)
    ) {
      setKeyDown(false);
    } else if (
      login.password.value.length <= 7 ||
      login.password.value === ""
    ) {
      setKeyDown(false);
    } else {
      setKeyDown(true);
    }
  }, [login.email, login.password]);

  useEffect(() => {
    if (user.jwtToken && user.userId) {
      handleRedirectAfterLogin();
    }
  }, [user, !loginError]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch(actions.setLoginForm({ name, value }));
  };

  const handleSubmit = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    LoggerClass.log(e);
    dispatch(actions.login());
  };

  const handleRedirectAfterLogin = () => {
    if (user.isPhoneNoVerified === false) {
      navigate("/verification");
    } else if (user.role === "landlord") {
      navigate("/landlord-account");
    } else if (user.role === "tenant") {      
      dispatch(actions.setDashboardData({userId : user.userId, callback: confirmTanantEvent}));
    } else if (!loginError) {
      setError(true);
    }
  };

  const confirmTanantEvent = (returnMessage) => {
    if(returnMessage === 'MESSAGE.NOT_FOUND') {
      navigate("/address");
      dispatch(actions.resetError()); 
    } else {
      navigate("/tenant-account");
    }    
  };

  if (isOtpVerify) {
    return <OtpVerification />;
  }

  return (
    <>
    <Loader loading={loading} />
    <PageContainer>
      <Typography className={styles.pageHeading}>Sign in with Email</Typography>
      <div className={styles.fieldBox}>
        <span className={styles.inputSpan}>
          <input
            className={styles.inputs}
            placeholder="Email e.g. Jon.Snow@thewall.north"
            value={login.email.value}
            name="email"
            type="text"
            onChange={onChange}
          />
        </span>
        <span className={styles.inputSpan}>
          <input
            className={styles.inputs}
            placeholder="Password e.g W!nt3rIsCom1ng"
            value={login.password.value}
            name="password"
            type={!visible ? "password" : "text"}
            onChange={onChange}
            autoComplete="false"
          />
          <InputAdornment position="end">
            <span onClick={changePasswordType} style={{ cursor: "pointer" }}>
              <IconButton sx={{ color: "#D1D0D0" }}>
                {!visible ? (
                  <VisibilityOff sx={{ color: "#D1D0D0" }} />
                ) : (
                  <Visibility sx={{ color: "#D1D0D0" }} />
                )}
              </IconButton>
            </span>
          </InputAdornment>
        </span>
      </div>
      <div className={styles.reForgo}>
        <div style={{ display: "flex", flexDirection: "row", width: "50%" }}>
          <input type="checkbox" className={styles.checkbox} />
          <Typography className={styles.checkboxTypos}>Remember me</Typography>
        </div>
        <Link className={styles.linkForgot} target={""} to={"/forgot-password"}>
          Forgot the Password?
        </Link>
      </div>
      {error ? (
        <div className={styles.footerTxt} style={{ color: "#EB5757" }}>
          {/* {loginError} */}
          {"Invalid email or password"}
        </div>
      ) : null}
      <Button
        disabled={!keyDown}
        className={styles.signInButton}
        variant="contained"
        onClick={handleSubmit}
      >
        Sign in
      </Button>
    </PageContainer>
    </>
  );
};

export default Login;
