import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  container: {
    backgroundColor: "#FFFFFF",
    minHeight: "100%",
    bottom: "auto",
    width: "100%",
    paddingBottom: 100,
  },
  typo40014: {
    borderRadius: "8px",
    padding: "5px 12px",
    background: "white",
    zIndex: "200",
    textAlign: "center",
    height: "fit-content",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "18px",
    letterSpacing: "0.2px",
    color: "#192342",
  },
  typo60014: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "18px",
    letterSpacing: "0.2px",
    color: "#192342",
  },
  typo12600button: {
    padding: "5px 12px",
    textAlign: "center",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "18px",
    letterSpacing: "0.2px",
    color: "#192342",
  },
  address: {
    textAlign: "center",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "140%",
    letterSpacing: "0.2px",
    marginTop: "15px",
    color: "#192342",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  typoLeft40016: {
    textAlign: "left",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "140%",
    letterSpacing: "0.2px",
    color: "#192342",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  typo13700: {
    alignSelf: "center",
    textAlign: "left",
    height: "fit-content",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "140%",
    letterSpacing: "0.2px",
    color: "#192342",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  typo70014: {
    textAlign: "center",
    height: "fit-content",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "18px",
    letterSpacing: "0.2px",
    color: "#192342",
  },
  signInButton: {
    "&:disabled": {
      background: "#C4C4C4",
      color: "#6B6B6B",
    },
    display: "flex",
    flexDirection: "row",
    width: "92%",
    justifyContent: "center",
    alignItems: "center",
    height: "50px",
    background: "#192342",
    color: "#ffffff",
    borderRadius: "8px",
    margin: "10px 0px",
    marginLeft: "16px",
    "&:hover": {
      background: "#192342",
      color: "#ffffff",
    },
  },
  signInButtonYellow: {
    "&:disabled": {
      background: "#C4C4C4",
      color: "#6B6B6B",
    },
    display: "flex",
    flexDirection: "row",
    width: "92%",
    justifyContent: "center",
    alignItems: "center",
    height: "50px",
    background: "#F0BB00",
    color: "#192342",
    borderRadius: "8px",
    margin: "10px 0px",
    marginLeft: "16px",
    "&:hover": {
      background: "#F0BB00",
      color: "#192342",
    },
  },
  signInButtonInverted: {
    "&:disabled": {
      background: "#FFFFFF",
      color: "#6B6B6B",
      outline: "#6B6B6B auto",
    },
    display: "flex",
    flexDirection: "row",
    width: "92%",
    justifyContent: "center",
    alignItems: "center",
    height: "50px",
    background: "#ffffff",
    color: "#192342",
    borderRadius: "8px",
    margin: "10px 0px",
    marginLeft: "16px",
    "&:hover": {
      background: "#ffffff",
      color: "#192342",
    },
  },
  exitButtonTypo: {
    textAlign: "center",
    height: "fit-content",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "18px",
    letterSpacing: "0.2px",
    color: "#FFFFFF",
  },
  exitButtonTypoDisabled: {
    textAlign: "center",
    height: "fit-content",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "18px",
    letterSpacing: "0.2px",
    color: "#6B6B6B",
  },
  exitButtonTypoBlue: {
    textAlign: "center",
    height: "fit-content",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "18px",
    letterSpacing: "0.2px",
    color: "#192342",
  },
  exitButtonTypoDisabledBlue: {
    textAlign: "center",
    height: "fit-content",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "18px",
    letterSpacing: "0.2px",
    color: "#6B6B6B",
  },
  onImageLabel: {
    fontSize: 16,
    fontWeight: "500",
    lineHeight: 1.4,
    letterSpacing: 0.2,
  },
  onImageLableContainer: {
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 5,
    paddingBottom: 5,
    borderRadius: 8,
    backgroundColor: "white",
    marginRight: 10,
  },
  imageButton: {
    right:"45px",
    position:'absolute',
  },
});

export default useStyles;
