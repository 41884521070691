import { Box, Button, Checkbox, TextField, Typography } from "@mui/material";
import Header from "app/components/Header";
import React, { useState } from "react";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { handleChange } from "utils/helpers";
import useWindowDimensions from "utils/hooks/useWindowDimensions";
import ChangePassword from "./Components/Dialog";
import OtpVerify from "./Components/otp";
import Password from "./Components/password";
import Remove from "./Components/remove";
import Success from "./Components/Success";
import { useStyles } from "./styles";
import { LoggerClass as Logger } from "utils/logger";

const TenantProfile = () => {
  const { width, height } = useWindowDimensions();
  const { state }: any = useLocation();
  const navigation = useNavigate();
  const styles = useStyles();
  const [emailCheck, setEmailCheck] = useState(false);
  const [phoneCheck, setPhoneCheck] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [otpPopup, setOtpPopup] = useState(false);
  const [passwordPopup, setPasswordPopup] = useState(false);
  const [successPopup, setSuccessPopup] = useState(false);
  const [removePopup, setRemovePopup] = useState(false);

  Logger.log("state", state);

  const closePopup = () => {
    setShowPopup(false);
    setOtpPopup(false);
    setPasswordPopup(false);
  };

  const handleSelect = () => {
    setShowPopup(false);
    setPasswordPopup(false);
  };

  const handleSend = () => {
    setShowPopup(false);
    setOtpPopup(true);
  };

  const handleVerify = () => {
    setOtpPopup(false);
    setPasswordPopup(true);
  };

  const handleChange = () => {
    setPasswordPopup(false);
    setSuccessPopup(true);
  };

  const handleClose = () => {
    setSuccessPopup(false);
  };

  const handleRemove = () => {
    setRemovePopup(false);
  };

  const goBack = () => {
    Logger.log("state", state?.source);

    if (state?.source) {
      navigation(state?.source);
    } else {
      navigation(-1);
    }
  };
  return (
    <div>
      <ChangePassword
        showPopup={showPopup}
        closePopup={closePopup}
        handleSelect={handleSelect}
        handleSend={handleSend}
      />

      <OtpVerify
        showPopup={otpPopup}
        closePopup={closePopup}
        handleVerify={handleVerify}
      />

      <Password
        showPopup={passwordPopup}
        closePopup={closePopup}
        handleSelect={handleSelect}
        handleChange={handleChange}
      />
      <Success
        showPopup={successPopup}
        closePopup={closePopup}
        handleClose={handleClose}
      />
      <Remove
        showPopup={removePopup}
        closePopup={closePopup}
        handleRemove={handleRemove}
      />

      <div
        style={{
          flexDirection: "row",
          display: "flex",
          marginTop: 25,
          marginBottom: 25,
          marginLeft: 16,
          marginRight: 15,
          paddingRight: 15,
          paddingLeft: 15,
          //   paddingTop: 10,
          //   paddingBottom: 10,
          borderRadius: 16,
          justifyContent: "center",
        }}
      >
        <img
          src={require("../../../assets/backArrow.png")}
          alt=""
          style={{
            height: 16,
            width: 16,
            position: "absolute",
            zIndex: 3,
            left: 16,
          }}
          onClick={goBack}
        />
        <div
          style={{ fontFamily: "Open Sans", fontSize: 18, fontWeight: "600" }}
        >
          Account
        </div>
      </div>

      <Header
        imageId={state?.selected}
        title="ID: 100035642"
        rightIcon={false}
        handleGallery={() => {
          navigation("/tenant-account/gallery", { state: state });
        }}
      />
      <div style={{ marginTop: 16 }}>
        <div className={styles.label}>Display Name</div>
        <TextField
          color="primary"
          variant="outlined"
          required
          placeholder={"Jhon"}
          onChange={(event) => {
          }}
          className={styles.textStyle}
        />
      </div>
      <div>
        <div className={styles.label}>First Name</div>
        <TextField
          color="primary"
          variant="outlined"
          required
          placeholder={"Jhon"}
          name="clientId"
          onChange={(event) => {
          }}
          className={styles.textStyle}
        />
      </div>
      <div>
        <div className={styles.label}>Source name</div>
        <TextField
          color="primary"
          variant="outlined"
          required
          id="nickName"
          placeholder={"Smith"}
          name="clientId"
          onChange={(event) => {
          }}
          className={styles.textStyle}
        />
      </div>
      <div>
        <div className={styles.label}>Phone number</div>
        <TextField
          color="primary"
          variant="outlined"
          required
          //   fullWidth
          id="nickName"
          placeholder={"+917-12-123456"}
          name="clientId"
          // value={phoneNumber}
          type="number"
          onChange={(event) => {
            //   if (numberValidation.test(event.target.value)) {
            //     setPhoneNumber(event.target.value);
            //   }
          }}
          className={styles.textStyle}
          //   error={form.clientId.error}
          //   color={form.clientId.error ? "primary" : "secondary"}
          //   style={{ borderRadius: 16,marginLeft }}
        />
      </div>
      <div>
        <div className={styles.label}>E-mail</div>
        <TextField
          color="primary"
          variant="outlined"
          required
          //   fullWidth
          id="nickName"
          placeholder={"john.smith@gmail.com"}
          name="clientId"
          // value={phoneNumber}
          type="email"
          onChange={(event) => {
            //   if (numberValidation.test(event.target.value)) {
            //     setPhoneNumber(event.target.value);
            //   }
          }}
          className={styles.textStyle}
          //   error={form.clientId.error}
          //   color={form.clientId.error ? "primary" : "secondary"}
          //   style={{ borderRadius: 16,marginLeft }}
        />
      </div>
      <div className={styles.checkBoxView}>
        <Checkbox
          value={emailCheck}
          onClick={() => {
            setEmailCheck(!emailCheck);
          }}
          sx={{
            color: "#192342",
            "&.Mui-checked": {
              color: "#192342",
            },
          }}
        />
        <div className={styles.checkBoxTitle}>
          Send me notifications via Email
        </div>
      </div>
      <div className={styles.checkBoxView}>
        <Checkbox
          value={phoneCheck}
          onClick={() => {
            setPhoneCheck(!phoneCheck);
          }}
          sx={{
            color: "#192342",
            "&.Mui-checked": {
              color: "#192342",
            },
          }}
        />
        <div className={styles.checkBoxTitle}>
          Send me notifications via SMS
        </div>
      </div>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          padding: "0px 16px",
          marginBottom: "24px",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "54px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: "24px",
          }}
        >
          <Box sx={{ width: "48%", height: "54px" }}>
            <Button
              onClick={() => {
                setShowPopup(true);
              }}
              className={styles.yellowButton}
            >
              <Typography className={styles.typo60014}>
                Change password
              </Typography>
            </Button>
          </Box>
          <Box sx={{ width: "48%", height: "54px" }}>
            <Button
              onClick={() => {
                setRemovePopup(true);
              }}
              className={styles.yellowButton}
            >
              <Typography className={styles.typo60014}>
                Remove from this property
              </Typography>
            </Button>
          </Box>
        </Box>

        <Button
          className={styles.signInButton}
          sx={{ marginBottom: "16px" }}
          onClick={() => {}}
        >
          <Typography
            className={styles.typo70014}
            sx={{ color: "#FFFFFF !important" }}
          >
            Apply Changes
          </Typography>
        </Button>

        <Button type="submit" className={styles.signInButtonColorInvert}>
          <Typography className={styles.typo70014}>Cancel Changes</Typography>
        </Button>
      </Box>
      <div className={styles.footerTxt}>Do you need help?</div>
      <div className={styles.footerTxt}>
        Contact us at{" "}
        <span className={styles.footTxt}> Support@Sre-Management.com</span>
      </div>
    </div>
  );
};

export default TenantProfile;
