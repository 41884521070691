import {Box, Button, Checkbox, Typography} from "@mui/material";
import Page from "app/components/Page";
import PageImageHeader from "app/components/PageImageHeader";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "utils/hooks/useWindowDimensions";
import { selectCreatedBusinessData, selectLoading, selectownerList, selectownerListRequest} from "../../redux/selectors";
import useStyles from "./styles";
import { actions } from "../../redux/slice";
import Loader from "app/components/Loader";
import deletepng from "../../../../../assets/delete.png";
import ownerpng from "../../../../../assets/owner.png";
import CopyRightSection from "app/components/CopyRight";


const AddAndShowOwner = ({callingFrom}) => {
  const { width, height } = useWindowDimensions();
  const dispatch = useDispatch();
  const styles = useStyles();
  const navigation = useNavigate();
  const [disabled, setDisabled] = useState<boolean>(true);
  const [list, setList] = useState<any>(null);
  const [checked, setChecked] = useState(false);
  const [upperHight, setUpperHight] = useState<number>(height);

  const ownerList = useSelector(selectownerList);
  const createdbusinessData = useSelector(selectCreatedBusinessData);  
  const loading = useSelector(selectLoading);
  const ownerListRequest = useSelector(selectownerListRequest);

  useEffect(() => {
    if(callingFrom === "notLoggedin") {
      setUpperHight(2.82);
    }
    dispatch(actions.setOwnerDataRequest({ name : "UserId", value : createdbusinessData.UserId ? createdbusinessData.UserId+"" : localStorage.getItem("ownerId")+"", form: "getOwnerData" }));
    dispatch(actions.setOwnerDataRequest({ name : "BussinesId", value : createdbusinessData.BusinessId ? createdbusinessData.BusinessId+"" : localStorage.getItem("businessId")+"", form: "getOwnerData" }));
  }, [dispatch]);

  const createdOwner = (e) => {
  }
  useEffect(() => {
    if(ownerListRequest.UserId.value && ownerListRequest.BussinesId.value) {
        dispatch(actions.getOwnerList({data : ownerListRequest, callback : createdOwner}));
    }
  }, [ownerListRequest]);  

  useEffect(() => {
    if (ownerList) {
      setList(ownerList);
    } else {
    }
  }, [ownerList]);

  const addOwnerButton = () => {
     navigation("/add-owner", {state : callingFrom});    
  };

  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(!checked);
 };

 const handleSubmit = (e) => {
    navigation('/id-verification');
 };

  return (
    <>
    <Loader loading={loading} />    
    <Page>
      {callingFrom === "notLoggedin" && <PageImageHeader/>}
      <div
        className={styles.sheet}
      >
        {callingFrom === "notLoggedin" && <>
        <Typography className={styles.title} style={{ marginBottom: "10px"}} >Add Owner</Typography> 
        <Typography className={styles.subHeading} style={{ marginBottom: "10px"}} >
        Due to regulatory requirements we need to identify anyone who owns 25% or more of the organization. This can include venture capitalists, investors, managing directors, individual owners, etc. 
        </Typography> 
        </>
      }
        <div
          style={{
            marginBottom: "10px",
            width: "90%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            margin: 'auto'
          }}
        >
        {list?.UserDetails?.map((item, index) => {
            return (
            <>
            <Box className={styles.section} id={item.OwnerAndControllerId}>
                <Typography className={styles.titleName}>{item.FirstName + " "+item.LastName}</Typography>
                {item.DwollaVerificationStatus === "document" && (<Button className={styles.ownerButton} onClick={(e) => { 
                   localStorage.setItem("benificaryType", 1+"");
                   navigation("/upload-document?type=business", { state: 'business' });
                }}
                >Upload Document</Button>)}
                {item.DwollaVerificationStatus === "incomplete" && (<Button className={styles.ownerButton} onClick={(e) => { 
                  navigation("/add-owner?UserInfoId="+item.UserInfoId, {state : callingFrom});
                }}>Add Information</Button>)}
                {item.DwollaVerificationStatus === "verified" && (<Typography sx={{color: 'green'}}>Verified</Typography>)}
                <span className={styles.deleteSpan}><img src={deletepng}/></span>
            </Box>
            </>
            )
        }
        )}

        <Button
              // disabled={disabled}
              className={styles.AddOwnerButton}
              variant="contained"
              onClick={addOwnerButton}
            >
              <img src={ownerpng} style={{marginRight: '20px'}}/>Add Owner
            </Button> 

        <div className={styles.btmTxt}>
          <Checkbox
              name="phoneCheck"
              checked={checked}
              value={checked}
              onChange={handleCheck}            
              sx={{
                color: "#192342",
                paddingLeft: '0px !important',
                "&.Mui-checked": {
                  color: "#192342",
                },
              }}
            />
            <div className={styles.btmTxtOnly}>
            I hereby certify that the information provided for Matt’s Hats Controller and Beneficial Owners is complete and correct
            </div>
          </div>        
        
        
          {callingFrom === "notLoggedin" && <Button
          disabled={!checked}
          className={styles.signInButton}
            variant="contained"
            onClick={handleSubmit}
          >
            Submit
          </Button> 
          }
          {callingFrom === "Loggedin" && <><Button
          disabled={!checked}
          className={styles.signInButton1}
            variant="contained"
          >
            Apply Changes
          </Button> 
          <Button
          className={styles.AddOwnerButton}
            variant="contained"
            onClick={() => {
              navigation('landlord-account/landlord-profile');
            }}
          >
            Cancel Changes
          </Button> 
          </>
        }         
        </div>     
      </div>
    </Page>
    <CopyRightSection/>
    </>
  );
};

export default AddAndShowOwner;
