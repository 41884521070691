import {
  Button,
  Typography,
  IconButton,
  InputAdornment,
  Box,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useStyles from "./styles";
import searchpng from "../../../assets/search.png";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import AddIcon from "@mui/icons-material/Add";
import { actions } from "../redux/slice";
import { actions as propertyActions } from "../../CommonComponent/Property/redux/slice"
import { useDispatch, useSelector } from "react-redux";
import { selectPropertyListDD } from "../../CommonComponent/Property/redux/selector";
import { getServiceRequests, selectLoading, selectMaintananceRequest, selectServiceRequestData } from "../redux/selector";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Filter from "./filter";
import Header from "../../../components/Header";
import Loader from "app/components/Loader";
import serviceBackIcon from "./assets/serviceRequestBlue.png";
import AddServiceDialog from "./Components/Dialog";
import AddNewServiceRequest from "./Components/AddNewServiceRequest";
import ServiceTickets from "./Components/ServiceRequestTickets";
import { selectUser } from "app/containers/Auth/selectors";
import { ServiceStatus, ServiceType } from "../redux/Landlord.enum";
import { ServiceRequestListItemType } from "../redux/types";
type Props = {};
const Maintanence = (props: Props) => {
  const { state = false }: any = useLocation();
  const styles = useStyles();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const serviceRequests = useSelector(getServiceRequests);
  const propertyList = useSelector(selectPropertyListDD);
  const maintananceRequest = useSelector(selectMaintananceRequest);
  const loggedInUser = useSelector(selectUser);
  const loading = useSelector(selectLoading);
  const serviceRequestData = useSelector(selectServiceRequestData);

  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState(state);
  const [showFilter, setShowFilter] = useState(false);
  const [list, setList] = useState<any>(null);
  const [clickedStatus, setClickedStatus] = useState<ServiceRequestListItemType>();
  const [showAddNewServiceRequest, setShowAddNewServiceRequest] =
    useState(false);
  const [showServiceTickets, setShowServiceTickets] = useState(false);

  const userId = loggedInUser?.userId ? loggedInUser?.userId : JSON.parse(localStorage.getItem('loginResponse')!).userId;
  const jwtToken = loggedInUser?.jwtToken ? loggedInUser?.jwtToken : JSON.parse(localStorage.getItem('loginResponse')!).jwtToken;



  const closePopup = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    if (serviceRequests) {
      setList(serviceRequests);
    } else {
    }
  }, [serviceRequests]);

  useEffect(() => {
    dispatch(actions.setShowBottomNavBar({ status: true, index: 4 }));
    dispatch(propertyActions.resetPropertiesDropdownListSuccess());
    dispatch(actions.restMaintananceRequest());

    if (jwtToken && userId) {
      dispatch(propertyActions.getPropertyListViaOwnerId());
    }

  }, [dispatch]);


  useEffect(() => {
    if (propertyList?.items.length > 0) {
      const propertyIds = propertyList?.items?.map(property => property.PropertyId);
      dispatch(actions.setMaintainanceProperty(propertyIds));
    }
  }, [propertyList]);

  useEffect(() => {
    if (maintananceRequest?.propertyIds.length > 0) {
      dispatch(actions.getServices(maintananceRequest));
    }
  }, [maintananceRequest]);


  const onChange = (e) => {
    setSearch(e.target.value);
    const filteredList = serviceRequests.filter(obj => {
      return obj.PropertyAddress?.toLowerCase().includes(e.target.value);
    });
    if (filteredList) {
      setList(filteredList);
    } else {
    }
  };

  const closeFilter = () => {
    setShowFilter(false);
  };

  const filterTask = () => {
    setShowFilter(false);
  };

  const showFilterTrue = () => {
    setShowFilter(true);
  };

  useEffect(() => {
  }, [showFilter]);


  const serviceOpenTime = (CreatedDate) => {
    const today = new Date();
    const createdDate = new Date(CreatedDate);
    const diffTime = today.getTime() - createdDate.getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) - 1;
    return diffDays > 1 ? diffDays + " days" : diffDays + " day";
  }

  const serviceType = (type) => {
    switch (type) {
      case ServiceType.AC:
        return 'A/C';
      case ServiceType.ELECTRICAL:
        return 'Electrical';
      case ServiceType.HANDYMAN:
        return 'Handyman';
      case ServiceType.LOCKSMITH:
        return 'LockSmith';
      case ServiceType.PLUMBLING:
        return 'Plumbling';
      case ServiceType.STRUCTURE_SERVICE:
        return 'Structure Service';
      default:
        return '';
    }
  }

  const serviceStatus = (status) => {
    switch (status) {
      case ServiceStatus.NEW:
        return 'New';
      case ServiceStatus.SELECT_TIME_FRAME:
        return 'Select Time Frame';
      case ServiceStatus.SCHEDULED_PENDING_PAYMENT:
        return 'Scheduled, Pending Payment';
      case ServiceStatus.PENDING_APPROVAL:
        return 'Pending Approval';
      case ServiceStatus.INPROGRESS:
        return 'InProgress';
      case ServiceStatus.COMPLETED_PENDING_PAYMENT:
        return 'Completed, Pending Payment';
      case ServiceStatus.COMPLETED:
        return 'Completed';
      case ServiceStatus.REOPEN:
        return 'ReOpen';
      case ServiceStatus.DISCARD:
        return 'Discard';
      default:
        return '';
    }
  }

  const onCreatedSuccess = () => {
    if (maintananceRequest?.propertyIds.length > 0) {
      dispatch(actions.getServices(maintananceRequest));
    }
  };

  return (
    <>
      <Loader loading={loading} />
      {showFilter && (
        <Filter closePass={closeFilter} continuePass={filterTask} />
      )}
      {showAddNewServiceRequest && (
        <AddNewServiceRequest
          returnToParent={() => {
            dispatch(actions.resetVideoFile());
            setShowAddNewServiceRequest(false);
            if (serviceRequestData.Description.value) {
              dispatch(actions.createServiceRequest({ data: serviceRequestData, callback: onCreatedSuccess }));
            }
          }}
        />
      )}
      {showPopup && (
        <AddServiceDialog
          showPopup={showPopup}
          closePopup={closePopup}
          continueClick={() => {
            setShowAddNewServiceRequest(true);
            setShowPopup(false);
          }}
        />
      )}
      {showServiceTickets ? (
        <ServiceTickets
          clickedStatus={clickedStatus}
          setShowServiceTickets={setShowServiceTickets}
        />
      ) : (
        <>
          {!showFilter && !showAddNewServiceRequest && (
            <>
              {" "}
              <Header
                rightIcon={true}
                handleNavigation={() => {
                  navigate("/landlord-account/landlord-profile");
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "16px 16px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginBottom: "113px",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography className={styles.typoLeftAlignedTitleNoWrap}>
                      Service Requests
                    </Typography>
                    <Box className={styles.inputSpan}>
                      <input
                        className={styles.inputs}
                        placeholder="Search by Property address"
                        value={search}
                        type="text"
                        onChange={onChange}
                      />
                      <InputAdornment position="end">
                        <span style={{ cursor: "pointer" }}>
                          <IconButton sx={{ color: "#D1D0D0" }}>
                            <img src={searchpng} alt={""} />
                          </IconButton>
                        </span>
                      </InputAdornment>
                    </Box>
                    <Box
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginBottom: "16px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          width: "100%",
                        }}
                      >
                        <Button
                          className={styles.yellowButton}
                          variant="contained"
                          onClick={showFilterTrue}
                        >
                          <IconButton
                            sx={{
                              width: "14px",
                              height: "18px",
                              padding: "0",
                              marginRight: "10px",
                              alignSelf: "center",
                            }}
                          >
                            <FilterAltIcon sx={{ color: "#192342" }} />
                          </IconButton>
                          <Typography className={styles.typo70014}>
                            Filter options
                          </Typography>
                        </Button>
                      </Box>
                      <Button
                        className={styles.signInButton}
                        variant="contained"
                        onClick={() => {
                          setShowPopup(true);
                        }}
                      >
                        <IconButton
                          sx={{
                            width: "14px",
                            height: "18px",
                            marginRight: "10px",
                            padding: "0",
                            alignSelf: "center",
                          }}
                        >
                          <AddIcon sx={{ color: "white" }} />
                        </IconButton>
                        <Typography className={styles.exitButtonTypo}>
                          Add Service Request
                        </Typography>
                      </Button>
                    </Box>
                  </Box>


                  <>
                    {list?.map((item, index) => {

                      return (
                        <>
                          <Box
                            sx={{
                              width: "100%",
                              height: "195px",
                              border: "0.5px solid #BCBCBC",
                              boxShadow:
                                "9px 19px 8px rgba(199, 199, 199, 0.01), 5px 11px 7px rgba(199, 199, 199, 0.05), 2px 5px 5px rgba(199, 199, 199, 0.09), 1px 1px 3px rgba(199, 199, 199, 0.1), 0px 0px 0px rgba(199, 199, 199, 0.1)",
                              borderRadius: "10px",
                              padding: "10px",
                              position: "relative",
                              marginBottom: "16px",
                              backgroundColor: "#FFF7DD",
                            }}
                            className={item?.OpenedBy === userId ? styles.whiteBackground : styles.yellowBackground}
                          >
                            <Box
                              sx={{
                                position: "absolute",
                                bottom: "0px",
                                right: "0px",
                                background: "#F0BB00",
                                borderRadius: "10px 0px",
                                height: "31px",
                              }}
                            >
                              <Typography className={styles.typo12600button}>
                                {serviceStatus(item?.WorkStatus)}
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "flex-start",
                                margin: "10px 0px",
                                alignItems: "center",
                              }}
                            >
                              <IconButton
                                onClick={() => {
                                  setShowServiceTickets(true);
                                  setClickedStatus(item);
                                }}
                                sx={{
                                  width: "34px",
                                  height: "34px",
                                  padding: "0",
                                  alignSelf: "center",
                                  marginRight: "10px",
                                }}
                              >
                                <img src={serviceBackIcon} alt="<-" />
                              </IconButton>
                              <Typography className={styles.typo60014}>
                                {serviceType(item?.ServiceType) + " #" + item?.ServiceRequestID}
                              </Typography>
                              <div
                                style={{
                                  paddingLeft: 10,
                                  alignItems: "center",
                                  //  justifyContent: "center",
                                  display: "flex",
                                }}
                              >
                                {item?.OpenedBy !== userId && (<img
                                  src={require("../../../../../src/app/assets/dot.png")}
                                  style={{ width: 7, height: 7 }}
                                />)
                                }
                              </div>
                            </Box>
                            <Typography className={styles.address}>
                              <LocationOnIcon sx={{ color: "#192342" }} />
                              {item?.PropertyAddress}
                            </Typography>
                            <Box
                              sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                marginTop: "12px",
                              }}
                            >
                              <Box
                                sx={{
                                  padding: " 5px 10px",
                                  width: "48%",
                                  height: " 52px",
                                  background: " #F5F5F5",
                                  borderRadius: " 10px",
                                  backgroundColor: "#FFEFBF",
                                }}
                              >
                                <Typography className={styles.typo12700}>
                                  Service Provider
                                </Typography>
                                <Typography className={styles.address}>
                                  {item?.ServiceProviderName}
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  padding: " 5px 10px",
                                  width: "48%",
                                  height: " 52px",
                                  background: " #F5F5F5",
                                  borderRadius: " 10px",
                                  backgroundColor: "#FFEFBF",
                                }}
                              >
                                <Typography className={styles.typo12700}>
                                  Open time
                                </Typography>
                                <Typography className={styles.address}>
                                  {serviceOpenTime(item?.CreatedDate)}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>
                        </>
                      );
                    })}
                  </>

                </Box>
              </Box>
            </>
          )}
        </>
      )}
    </>
  );
};

export default Maintanence;
