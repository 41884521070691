import { createSlice } from "../../../../utils/@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { ServiceRequestState, ServiceRequestType } from "./types";

const initialState = ServiceRequestState;

export const serviceRequestSlice = createSlice({
  name: "serviceRequestState",
  initialState,
  reducers: {
    getList: (state) => {
      state.loading = true;
    },
    getPropertiesListSuccess: (
      state,
      action: PayloadAction<{ items: any }>
    ) => {
      state.loading = false;
      state.list.items = action.payload.items;
    },
    addTimeFrame: (state, action: PayloadAction<ServiceRequestType>) => {
      state.loading = false;
      state.list.items = [...state.list.items, action.payload];
    },
  },
});

export const { reducer, actions, name: sliceKey } = serviceRequestSlice;
