import {
  Button,
  Typography,
  Box,
  Dialog,
  IconButton,
  Checkbox,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import checkImage from "../../../../../../assets/check.png";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import { actions } from "../../../redux/slice";
import { useNavigate } from "react-router";
const DeletePropertyDialog = ({ showPopup, closePopup, address, id }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const styles = useStyles();
  const [show, setShow] = useState(false);
  const [selectedDeleteReason, setSelectedDeleteReason] = useState("");
  const [okScreen, setOkScreen] = useState(false);
  const [showDeleteOptions, setShowDeleteOptions] = useState(false);
  const close = () => {
    setShow(false);
    closePopup();
    dispatch(
      actions.deletePropertyById({
        id: id,
      })
    );
    navigate("/landlord-account/portfolio");
  };
  const closeAndHold = () => {
    setShow(false);
    closePopup();
    dispatch(
      actions.deletePropertyById({
        id: id,
      })
    );
  };
  const showNext = () => {
    setShowDeleteOptions(false);
    setOkScreen(true);
  };
  useEffect(() => {
    setShow(showPopup);
  }, [showPopup]);
  useEffect(() => {}, [showNext]);

  const onStatusChange = (e) => {
    setSelectedDeleteReason(e.target.value);
  };

  const handleDeleteOptions = () => {
    setShowDeleteOptions(true);
  };

  return (
    <>
      <Dialog open={show} className={styles.popup}>
        <Box sx={{ display: "flex", flexDirection: "column", margin: "16px" }}>
          {!showDeleteOptions && !okScreen && (
            <>
              <Typography className={styles.title}>Delete Property</Typography>
              <Typography
                sx={{ margin: "10px 0px" }}
                className={styles.address}
              >
                {address}
              </Typography>
              <Typography
                sx={{ margin: "6px 0px 10px 0px" }}
                className={styles.pageTypo2}
              >
                Do you really want to delete this property?
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{ display: "flex", width: "48%" }}
                  onClick={closeAndHold}
                >
                  <Button className={styles.signInButtonColorInvert}>
                    Cancel
                  </Button>
                </Box>
                <Box sx={{ display: "flex", width: "48%" }}>
                  <Button
                    className={styles.signInButton}
                    onClick={handleDeleteOptions}
                  >
                    Delete
                  </Button>
                </Box>
              </Box>
            </>
          )}
          {okScreen && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                margin: "16px",
                alignItems: "center",
              }}
            >
              <IconButton className={styles.closeIcon} onClick={close}>
                <CloseIcon />
              </IconButton>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "16px",
                  alignItems: "center",
                  marginBottom: "30px",
                }}
              >
                <IconButton className={styles.checkIcon}>
                  <img src={checkImage} alt={"Done"} />
                </IconButton>
              </Box>
              <Typography
                className={styles.title}
                sx={{ marginBottom: "10px" }}
              >
                The Property {address} is deleted
              </Typography>
            </Box>
          )}
          {showDeleteOptions && (
            <>
              <Typography className={styles.title}>Delete Property</Typography>
              <Typography
                sx={{ margin: "10px 0px" }}
                className={styles.address}
              >
                {address}
              </Typography>
              <Typography
                sx={{ margin: "6px 0px 10px 0px" }}
                className={styles.pageTypo2}
              >
                Please choose the reason of deletion
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "16px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Checkbox
                    id="vac"
                    className={styles.checkbox}
                    checked={
                      selectedDeleteReason === "No longer owning the property"
                    }
                    value="No longer owning the property"
                    onChange={onStatusChange}
                  />
                  <label htmlFor="vac" className={styles.typoBlue40016}>
                    No longer owning the property
                  </label>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Checkbox
                    id="tocc"
                    className={styles.checkbox}
                    checked={
                      selectedDeleteReason === "No longer using the application"
                    }
                    value="No longer using the application"
                    onChange={onStatusChange}
                  />
                  <label htmlFor="tocc" className={styles.typoBlue40016}>
                    No longer using the application
                  </label>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Checkbox
                    id="oocc"
                    className={styles.checkbox}
                    checked={selectedDeleteReason === "Other"}
                    value="Other"
                    onChange={onStatusChange}
                  />
                  <label htmlFor="oocc" className={styles.typoBlue40016}>
                    Other
                  </label>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box sx={{ display: "flex", width: "48%" }}>
                  <Button
                    className={styles.signInButtonColorInvert}
                    onClick={closeAndHold}
                  >
                    Cancel
                  </Button>
                </Box>
                <Box sx={{ display: "flex", width: "48%" }}>
                  <Button
                    disabled={selectedDeleteReason === ""}
                    className={styles.signInButton}
                    onClick={showNext}
                  >
                    Delete
                  </Button>
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Dialog>
    </>
  );
};

export default DeletePropertyDialog;
