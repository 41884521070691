import { createSlice } from "../../../../utils/@reduxjs/toolkit";
import { PayloadAction } from "@reduxjs/toolkit";
import { MessageType, TenantState } from "./types";
import set from "lodash/set";


const initialState = TenantState;

export const tenantSlice = createSlice({
  name: "tenantState",
  initialState,
  reducers: {
    setError(state, action: PayloadAction<any>) {
      state.error = action.payload;
      state.loading = false;
    },
    getMessages: (state) => {
      state.loading = true;
    },
    getMessagesSuccess: (state, action: PayloadAction<MessageType[]>) => {
      state.loading = false;
      state.messages.items = action.payload;
    },
    getDashboardData: (state, action) => {
      state.loading = true;
    },

    getDashboardDataSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.dashboardData = action.payload;
    },

    setMaintainanceProperty: (state, action: PayloadAction<any>) => {
      state.MaintananceRequestBody.propertyIds[0] = action.payload;        
    },

    getDashboardDataError(state, action: PayloadAction<any>) {
      state.error = action.payload;
      state.loading = false;
    },

    resetModule: (state) => {
      state = initialState;
    },

    resetDashbord: (state) => {
      state.dashboardData = initialState.dashboardData;
    },

    resetError: (state) => {
      state.error = initialState.error;
    },

    getDocumentUploadUrl(state, action) {
      state.loading = true;
    },

    getDocumentUploadUrlSuccess(state, action) {
      state.ImageUploadUrl = action.payload;
    },
    setLoaderFalse(state) {
      state.loading = false;
    },
    getDocumentUploadUrlError(state, action) {
      state.error = action.payload;
      state.loading = false;
    },
    getServices: (state, action) => {
      state.loading = true;
    },
    getServicesSuccess: (
      state,
      action: PayloadAction<any>
    ) => {
      state.loading = false;
      state.serviceRequests = action.payload.Data
    },
    getServicesError(state, action: PayloadAction<any>) {
      state.error = action.payload;
      state.loading = false;
    },
    ServiceRequestData: (  state,
      action: PayloadAction<{ name: string; value: string, form?: string }>
    ) => {
      const form = action.payload.form ? action.payload.form : 'form';
      const { name, value } = action.payload;      
      set(state.serviceRequestData, `${name}.value`, value);
      set(state.serviceRequestData, `${name}.error`, "");
    },
    resetServiceRequestData: (state) => {
      state.serviceRequestData = initialState.serviceRequestData;
    },
    ServiceRequestTypeData: (state, action: PayloadAction<any>) => {
      state.serviceRequestData.ServiceType = action.payload;
    },
    setVideoFile(state, action) {
      state.videoFile = action.payload.file
      state.requestThumbnailVideo = action.payload.file.url
      state.requestVideo = action.payload.file.url
      //state.loading = true;
    },
    resetVideoFile: (state) => {
      state.videoFile = initialState.videoFile;
    },
    restMaintananceRequest: (state) => {
      state.MaintananceRequestBody = initialState.MaintananceRequestBody;
    },
    getUploadUrl(state, action) {
      state.loading = true;
    },
    getUploadUrlSuccess(state, action) {
      state.loading = false;
      state.videoUploadUrl = action.payload;
    },
    resetUploadedUrl: (state) => {
      state.videoUploadUrl = initialState.videoUploadUrl;        
      state.imageUploadUrl = initialState.imageUploadUrl;
      state.videoFile = initialState.videoFile;
    },
    updateVideoUrl(state, action) {
      state.videoUploadedUrl = action.payload;
    },
    getUploadUrlError(state, action: PayloadAction<any>) {
      state.error = action.payload;
      state.loading = false;
    },
    startLoading(state) {
      state.loading = true;
    },
    stopLoading(state) {
      state.loading = false;
    },
    createServiceRequest: (state, action: PayloadAction<any>) => {
      state.loading = true;
    },
    createServiceRequestSuccess: (state, action: PayloadAction<any>) => {
      state.addedServiceRequest = action.payload.Data;
      state.loading = false;
    },
    createServiceRequestError(state, action: PayloadAction<any>) {
      state.error = action.payload;
      state.loading = false;
    },
    setSelectedServiceRequestStatusType: (state, action: PayloadAction<any>) => {
      state.selectedServiceRequests.WorkStatus = action.payload;
    },
    setSelectedServiceRequest: (state, action: PayloadAction<any>) => {
      state.loading = true;
    },
    setSelectedServiceRequestSuccess: (state, action: PayloadAction<any>) => {
      state.selectedServiceRequests = action.payload.Data;
      state.loading = false;
    },
    setSelectedServiceRequestError(state, action: PayloadAction<any>) {
      state.error = action.payload;
      state.loading = false;
    },
    setSelectedPortfolioDetails: (state, action) => {
      state.loading = true;
    },
    setSelectedPortfolioDetailsSuccess: (state, action) => {
      state.loading = false;
      state.selectedPortfolio = action.payload;
    },
    setSelectedPortfolioDetailsError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { reducer, actions, name: sliceKey } = tenantSlice;
