import { FormError } from "app/containers/types";
import { addProperty } from "../redux/types";

export function valdiator(form: addProperty): Array<FormError> {
  const errors: Array<FormError> = [];
    if (form.units.value == 0 && (form.type.value == "1" || form.type.value == 1)) {
    errors.push({
      name: "units",
      error: "Invalid Unit Number",
    });
  } 

  if (form.street.value && form.street.value.length < 2) {
    errors.push({
      name: "street",
      error: "Type at least 2 characters for Street Address field",
    });
  }

  return errors;
}
