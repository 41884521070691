/**
 * Gets the repositories of the user from Github
 */
import { toast } from "react-toastify";
import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { API_URL } from "utils/constants";
import { getDefaultHeaders } from "utils/helpers";
import { request } from "utils/request";
import { actions } from "./slice";
import { LoggerClass as Logger, LoggerClass } from "utils/logger";
import { selectToken, selectUser } from "../../../Auth/selectors";
import queryString from "query-string";

/**
 * Github repos request/response handler
 */

const businessDetail = {
  "Result": true,
  "Data": {
    "FirstName": "TestF",
    "LastName": "TestL",
    "BusinessName": "Test BusinessName",
    "Email": "TEST1234@yopmail.com",
    "Phone": "9800087654",
    "ThresholdAmt": "1000",
    "BusinessEIN": "12121212"
  },
  "Message": "MESSAGE.SUCCESS"
};


 export function* getBusinessDetails() {
    yield delay(500);
    try {    
    const token = yield select(selectToken);
    const user = yield select(selectUser);

    const options = {
      method: "GET",
      headers: { ...getDefaultHeaders(), Authorization: `Bearer ${token}` }    
    };
    const queries = queryString.stringify({
      UserId: user.userId ? user.userId : JSON.parse(localStorage.getItem('loginResponse')!).userId,
      ownerType : 'business'
    });
  
      const response = yield call(
        request,
        `${API_URL}/userManagement/Business/GetBusinessDetailsByUserId?${queries}`,
        options
      )
  
      if(response.Status === 401 || response.Status === 400) {      
        yield put(actions.getBusinessDetailsError(response.Message));
        return false;
      }
  
      if(!response.Result) {
        yield put(actions.getBusinessDetailsError(response.Message));
        return false;
      }    
  
      yield put(actions.getBusinessDetailsSuccess(response.Data)); 
    } catch (err: any) {
      yield put(actions.getBusinessDetailsError(err));
      return false;
    }
  }

  
  export function* UpdateMobileNumber(action) {
    yield delay(500);
    try {    
    const token = yield select(selectToken);
    const user = yield select(selectUser);

    const options = {
      method: "POST",
      headers: { ...getDefaultHeaders(), Authorization: `Bearer ${token}` },
      body: JSON.stringify({
        userId: user.userId ? user.userId : JSON.parse(localStorage.getItem('loginResponse')!).userId,
        phoneNumber : action.payload.data
      }),  
    }; 
    
      yield put(actions.UpdateMobileNumberSuccess("Updated"));
      yield call(action.payload?.callback);
    } catch (err: any) {
      yield put(actions.UpdateMobileNumberError(err));
      return false;
    }
  }

/**
 * Root saga manages watcher lifecycle
 */
export function* landlordProfileSaga() {    
    yield takeLatest(actions.getBusinessDetails.type, getBusinessDetails);
    yield takeLatest(actions.UpdateMobileNumber.type, UpdateMobileNumber);
}
