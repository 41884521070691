export enum  BusinessType {
    LLCS,  //0
    CORPORATION, //1
    SOLE_PARTNERSHIP, //2
    TRUST, //3
    UNICORPORATED_ASSOCIATION,  //4
    PUBLICLY_TRADED_CORPORATIONS, //5
    NON_PROFITS, //6
    PARTNERSHIP //7
}

export enum  BusinessTypeValue {
    LLCS = "LLCs",  
    CORPORATION = "Corporation", //1
    SOLE_PARTNERSHIP = "Sole Partnership", //2
    TRUST = "Trust", //3
    UNICORPORATED_ASSOCIATION = "Unicorporated Association",  //4
    PUBLICLY_TRADED_CORPORATIONS = "Publicly Traded Corporation", //5
    NON_PROFITS = "Non Profits", //6
    PARTNERSHIP= "Partnership" //7
}
export enum  PersonType {
    NON_US, //0
    US,  //1   
}

export enum UserTypeEnum { 
    CONTROLLER, // 0 
    OWNER   // 1
}
