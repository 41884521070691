import React from "react";
import { Outlet } from "react-router";
import BottomTabs from "./BottomTabs/";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import { reducer, sliceKey } from "./redux/slice";
import { useTenantSaga } from "./redux/saga";
const TenantPage = () => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: useTenantSaga });
  return (
    <>
      <Outlet />
    </>
  );
};
export default TenantPage;
