import {
  Button,
  Typography,
  IconButton,
  InputAdornment,
  Input,
  Box,
  SelectChangeEvent,
  CircularProgress,
  TextField,
} from "@mui/material";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useStyles from "./styles";
import arrowBack from "../../../../assets/backArrow.png";
import dollar from "../../../../assets/dollar.png";
import uploadpng from "../../../../assets/upload.png";
import CloseIcon from "@mui/icons-material/Close";
import docpng from "../../../../assets/doc.png";
import PaymentDay from "app/components/PaymentDay";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  addedLease,
  addLeaseData,
  checkLeaseStateData,
  getSelectedPortfolio,
  PortfolioUpdateRequestBody,
  selectedPropertyIdViaDropdown,
  selectImageFile,
  selectImageUploadUrl,
  selectPropertyError,
  setPropertyStatusCheck,
  addedLeaseCondition,
  selectLoading,
  checkStateStatusData,
} from "app/containers/CommonComponent/Property/redux/selector";
import { useDispatch } from "react-redux";
import { actions } from "app/containers/CommonComponent/Property/redux/slice";
import { actions as S3UploadActions } from "app/containers/CommonComponent/S3UploadSaga/slice";
import { selectUser } from "app/containers/Auth/selectors";
import DatePicker from "app/components/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import Loader from "app/components/Loader";
import PropertyRegisteredDialog from "../AddProperty/Component/PropertyRegisteredDialog";
import { LoggerClass as Logger } from "utils/logger";
type Props = {};
const AddLease = ({
  goBackProp = () => {},
  submission = () => {},
  checkme = false,
  titleProp = "",
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const today = dayjs();
  const [leaseExpiration, setLeaseExpiration] = useState<Dayjs | null>(today);
  const [leaseExpirationError, setLeaseExpirationError] = useState(false);
  const [paymentDayError, setPaymentDayError] = useState(false);
  const [rentAmountError, setRentAmountError] = useState(false);
  const [depositAmount, setDepositAmount] = useState("");
  const [depositAmountError, setDepositAmountError] = useState(false);
  const [keyDown, setKeyDown] = useState(false);
  const [docUploaded, setDocUploaded] = useState(false);
  const [docName, setDocName] = useState("");
  const [updateDocName, setupdateDocName] = useState("");
  const [uploadDocumentLoader, setuploadDocumentLoader] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  const [checkUploaded, setcheckUploaded] = useState(false);

  const addLease = useSelector(addLeaseData);
  const loggedInUser = useSelector(selectUser);
  const propertyError = useSelector(selectPropertyError);
  const selectedProperty = useSelector(selectedPropertyIdViaDropdown);
  const checkLeaseState = useSelector(checkLeaseStateData);
  const propertyUpdateRequest = useSelector(PortfolioUpdateRequestBody);
  const localPropertyId = localStorage.getItem("property");
  const localUserId = JSON.parse(localStorage.getItem("loginResponse")!).userId;
  const setPropertyStatus = useSelector(setPropertyStatusCheck);
  const LeaseCondition = useSelector(addedLeaseCondition);
  const loading = useSelector(selectLoading);
  const checkStateStatus = useSelector(checkStateStatusData);

  useEffect(() => {
    if (propertyError === "Unauthorized") {
      dispatch(actions.resetModule());
      dispatch(actions.resetError());
      navigate("/auth");
    }
  }, [propertyError]);
  useEffect(() => {
    Logger.log(leaseExpiration);
    dispatch(
      actions.setLeaseData({
        name: "LeaseExpirationDate",
        value:
          leaseExpiration !== null ? leaseExpiration?.format("YYYY-MM-DD") : "",
        form: "addLease",
      })
    );
  }, [leaseExpiration]);
  useEffect(() => {
    dispatch(
      actions.setLeaseData({
        name: "propertyId",
        value: localPropertyId + "",
        form: "addLease",
      })
    );
    dispatch(
      actions.setLeaseData({
        name: "ownerId",
        value: loggedInUser.userId ? loggedInUser.userId + "" : localUserId,
        form: "addLease",
      })
    );

    if (addLease.LeaseId.value) {
      setcheckUploaded(true);
      setDocUploaded(true);
    }
  }, []);

  let uploadedDocument;

  const goBack = () => {
    if (checkLeaseState === "editProperty") {
      navigate("/landlord-account/my-property?id=" + localPropertyId);
    } else {
      navigate("/landlord-account");
    }
  };

  const handleDocumentUpload = (event) => {
    uploadedDocument.click();
  };

  const closeDoc = () => {
    dispatch(actions.resetLeaseDocument());

    setDocUploaded(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    dispatch(actions.setLeaseData({ name, value, form: "addLease" }));
  };

  const handleLeaseDateChange = (value) => {
    setLeaseExpiration(value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (checkStateStatus === "Auth") {
      dispatch(actions.addLeaseData(addLease));
      setShowPopup(true);
    } else {
      if (addLease?.LeaseId.value) {
        dispatch(actions.updateLeaseData(addLease));
      } else {
        dispatch(actions.addLeaseData(addLease));
      }
      if (checkLeaseState === "editProperty") {
        dispatch(actions.checkAddedLeaseState("UpdatedLease"));
        dispatch(
          actions.setpropertyUpdateRequest({
            name: "propertyId",
            value: localPropertyId + "",
            form: "updateProperty",
          })
        );
        dispatch(
          actions.setpropertyUpdateRequest({
            name: "status",
            value: "1",
            form: "updateProperty",
          })
        );
      } else {
        navigate("/landlord-account");
      }
    }
  };

  useEffect(() => {
    if (LeaseCondition) {
      dispatch(actions.updatePropertyStatus(propertyUpdateRequest));
      dispatch(actions.setDailogState(true));
    }
  }, [LeaseCondition]);

  useEffect(() => {
    if (setPropertyStatus) {
      dispatch(actions.checkAddedLeaseState(""));
      dispatch(actions.setLeaseState(""));
      dispatch(
        actions.setSelectedPortfolioDetails({
          id: propertyUpdateRequest?.propertyId
            ? propertyUpdateRequest?.propertyId
            : localPropertyId,
        })
      );
      dispatch(actions.setDailogState(true));
      navigate("/landlord-account/my-property?id=" + localPropertyId);
    }
  }, [setPropertyStatus]);

  const handleImageFileChange = (event) => {
    setuploadDocumentLoader(true);
    setDocUploaded(true);
    setDocName(event.target.files[0].name);
    if (addLease?.LeaseId.value) {
      setupdateDocName(event.target.files[0].name);
    }
    dispatch(
      actions.setImageFile({
        file: {
          data: event.target.files[0],
          type: "properties/documents",
          url: URL.createObjectURL(event.target.files[0]),
        },
      })
    );

    dispatch(
      S3UploadActions.getDocumentUploadUrl({
        fileData: event.target.files[0],
        contentType: "properties/documents",
      })
    );
  };

  const handleKeyEvent = (e) => {
    if (e.key === "e" || e.key === ".") {
      e.preventDefault();
    }
    let charCode = e.which ? e.which : e.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    if (
      docUploaded &&
      addLease.DepostiAmount.value.length !== 0 &&
      addLease.RentAmount.value.length !== 0 &&
      addLease.RentAmount.value !== "0" &&
      addLease.DepostiAmount.value != "0"
    ) {
      setKeyDown(false);
    } else {
      setKeyDown(true);
    }
  }, [
    depositAmount,
    docUploaded,
    addLease.DepostiAmount.value,
    addLease.LeaseExpirationDate.value,
    addLease.RentAmount.value,
  ]);

  useEffect(() => {
    dispatch(
      actions.setLeaseData({
        name: "PropertyId",
        value: selectedProperty + "",
        form: "addLease",
      })
    );
    dispatch(
      actions.setLeaseData({
        name: "ownerId",
        value: loggedInUser.userId + "",
        form: "addLease",
      })
    );
  }, []);

  return (
    <>
      <Loader loading={loading} />
      {showPopup && (
        <PropertyRegisteredDialog
          popupProp={showPopup}
          popupFunction={() => setShowPopup(false)}
        />
      )}

      <div
        className={
          checkme ? styles.lowerContainerNoPadding : styles.lowerContainer
        }
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <IconButton
            sx={{
              width: "16px",
              height: "16px",
              padding: "0",
              alignSelf: "center",
            }}
            onClick={goBack}
          >
            <img
              src={arrowBack}
              style={{ objectFit: "contain", width: "100%" }}
              alt="<-"
            />
          </IconButton>
          <Typography className={styles.pageHeading}>
            {checkme
              ? titleProp
              : addLease?.LeaseId.value
              ? "Update Lease"
              : "Add New Lease"}
          </Typography>
        </Box>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "flex-start",
            padding: "4px",
          }}
        >
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "row",
              flexWrap: "wrap",
              alignItems: "flex-start",
            }}
          >
            <div style={{ width: "48%", marginRight: "2%" }}>
              <Typography className={styles.pageTypoThreshold}>
                Lease expiration
              </Typography>
              <DatePicker
                handleChange={handleLeaseDateChange}
                value={leaseExpiration}
              />
              {leaseExpirationError && (
                <Typography className={styles.errorTypo}>
                  Invalid Date
                </Typography>
              )}
            </div>
            <div style={{ width: "48%", marginLeft: "2%" }}>
              <Typography className={styles.pageTypoThreshold}>
                Payment Day
              </Typography>
              <PaymentDay
                value={addLease?.PayDay.value}
                name="PayDay"
                handleChange={handleChange}
              />
              <Typography className={styles.pageTypoMinimum}>
                The date of every month
              </Typography>
              {paymentDayError && (
                <Typography className={styles.errorTypo}>
                  Invalid Date
                </Typography>
              )}
            </div>

            <div style={{ width: "48%", marginRight: "2%", marginTop: 20 }}>
              <Typography className={styles.pageTypoThreshold}>
                Rent Amount
              </Typography>

              <TextField
                className={styles.inputs}
                placeholder="Enter Amount"
                value={addLease.RentAmount.value}
                type="number"
                name="RentAmount"
                variant="outlined"
                onChange={handleChange}
                onKeyDown={handleKeyEvent}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <span style={{ cursor: "pointer" }}>
                        <IconButton sx={{ color: "#D1D0D0" }}>
                          <img src={dollar} alt={""} />
                        </IconButton>
                      </span>
                    </InputAdornment>
                  ),
                }}
              ></TextField>

              {rentAmountError && (
                <Typography className={styles.errorTypo}>
                  Amount Required
                </Typography>
              )}
            </div>
            <div style={{ width: "48%", marginLeft: "2%", marginTop: 20 }}>
              <Typography className={styles.pageTypoThreshold}>
                Deposit Amount
              </Typography>
              <TextField
                className={styles.inputs}
                variant="outlined"
                placeholder="Enter Amount"
                value={addLease.DepostiAmount.value}
                type="number"
                name="DepostiAmount"
                onChange={handleChange}
                onKeyDown={handleKeyEvent}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <span style={{ cursor: "pointer" }}>
                        <IconButton sx={{ color: "#D1D0D0" }}>
                          <img src={dollar} alt={""} />
                        </IconButton>
                      </span>
                    </InputAdornment>
                  ),
                }}
              ></TextField>

              {depositAmountError && (
                <Typography className={styles.errorTypo}>
                  Invalid Amount
                </Typography>
              )}
            </div>
          </div>
          <div>
            {!docUploaded && (
              <Typography className={styles.pageTypoH} style={{ marginTop: 5 }}>
                Upload the{" "}
                <label className={styles.pageTypoThresholdWP}>
                  Lease document
                </label>{" "}
                and any document related to this lease
              </Typography>
            )}
          </div>
        </div>
        {!checkUploaded ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "flex-start",
              padding: "4px",
            }}
          >
            {!docUploaded && (
              <Button
                className={styles.signInButtonColorInvert}
                variant="contained"
                onClick={handleDocumentUpload}
              >
                <InputAdornment position="end">
                  <span style={{ cursor: "pointer" }}>
                    <IconButton sx={{ color: "#D1D0D0" }}>
                      <img src={uploadpng} alt={""} />
                    </IconButton>
                  </span>
                </InputAdornment>
                <Typography
                  sx={{
                    fontFamily: "Open Sans",
                    fontStyle: "normal",
                    fontWeight: 700,
                    fontSize: "14px",
                    letterSpacing: "0.2px",
                  }}
                >
                  Upload Document
                </Typography>
                <input
                  type="file"
                  accept=".pdf"
                  ref={(refParam) => (uploadedDocument = refParam)}
                  value={uploadedDocument}
                  onChange={handleImageFileChange}
                  hidden
                />
              </Button>
            )}

            {docUploaded && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <Typography className={styles.pageTypoUI}>
                  Uploaded documents
                </Typography>
                <Box className={styles.boxLease}>
                  <span style={{ display: "flex", alignItems: "center" }}>
                    <IconButton sx={{ cursor: "pointer", color: "#D1D0D0" }}>
                      <img src={docpng} alt={""} />
                    </IconButton>
                    <Typography
                      sx={{
                        fontFamily: "Open Sans",
                        fontStyle: "normal",
                        fontWeight: 700,
                        fontSize: "14px",
                        letterSpacing: "0.2px",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        maxWidth: "160px",
                      }}
                    >
                      {docName.substring(0, docName.length - 4)}
                    </Typography>
                  </span>
                  <span style={{ cursor: "pointer" }}>
                    <IconButton onClick={closeDoc}>
                      <CloseIcon sx={{ color: "#7D7D7D" }} />
                    </IconButton>
                  </span>
                </Box>
              </Box>
            )}
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "flex-start",
              padding: "4px",
            }}
          >
            {!docUploaded && (
              <Button
                className={styles.signInButtonColorInvert}
                variant="contained"
                onClick={handleDocumentUpload}
              >
                <InputAdornment position="end">
                  <span style={{ cursor: "pointer" }}>
                    <IconButton sx={{ color: "#D1D0D0" }}>
                      <img src={uploadpng} alt={""} />
                    </IconButton>
                  </span>
                </InputAdornment>
                <Typography
                  sx={{
                    fontFamily: "Open Sans",
                    fontStyle: "normal",
                    fontWeight: 700,
                    fontSize: "14px",
                    letterSpacing: "0.2px",
                  }}
                >
                  Upload Document
                </Typography>
                <input
                  type="file"
                  accept=".pdf"
                  ref={(refParam) => (uploadedDocument = refParam)}
                  value={uploadedDocument}
                  onChange={handleImageFileChange}
                  hidden
                />
              </Button>
            )}

            {docUploaded && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <Typography className={styles.pageTypoUI}>
                  Uploaded documents
                </Typography>
                <Box className={styles.boxLease}>
                  <span style={{ display: "flex", alignItems: "center" }}>
                    <IconButton sx={{ cursor: "pointer", color: "#D1D0D0" }}>
                      <img src={docpng} alt={""} />
                    </IconButton>
                    <Typography
                      sx={{
                        fontFamily: "Open Sans",
                        fontStyle: "normal",
                        fontWeight: 700,
                        fontSize: "14px",
                        letterSpacing: "0.2px",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        maxWidth: "160px",
                      }}
                    >
                      {updateDocName !== "" ? (
                        <span>{docName.substring(0, docName.length - 4)}</span>
                      ) : (
                        <span>
                          {addLease?.Documents[0]?.DocumentURL
                            ? addLease?.Documents[0]?.DocumentURL.substring(
                                addLease?.Documents[0]?.DocumentURL.lastIndexOf(
                                  "/"
                                ) + 1
                              )
                            : "no document"}
                        </span>
                      )}
                    </Typography>
                  </span>
                  <span style={{ cursor: "pointer" }}>
                    <IconButton onClick={closeDoc}>
                      <CloseIcon sx={{ color: "#7D7D7D" }} />
                    </IconButton>
                  </span>
                </Box>
              </Box>
            )}
          </div>
        )}
        <div
          style={{
            marginBottom: "10px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            disabled={keyDown}
            className={styles.signInButton}
            variant="contained"
            onClick={handleSubmit}
          >
            {addLease?.LeaseId.value ? "Update" : "Add"}
          </Button>
        </div>
      </div>
    </>
  );
};

export default AddLease;
