import { FormError } from "app/containers/types";
import { selectedProperty } from "../types";

export function valdiator(form: selectedProperty): Array<FormError> {
  const errors: Array<FormError> = [];
    if (form.Zip.value && form.Zip.value.length > 10 && form.Zip.value.length !== 0) {
    errors.push({
      name: "Zip",
      error: "Invalid ZIP code",
    });
  }
 

  if (form.Address.value && form.Address.value.length < 2) {
    errors.push({
      name: "Address",
      error: "Type at least 2 characters for Street Address field",
    });
  }

  return errors;
}
