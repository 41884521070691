import useStyles from "./styles";
import { Typography } from "@mui/material";
import { selectBusinessAccountData } from "../../../redux/selectors";
import { actions } from "../../../redux/slice";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BusinessType } from "../../../redux/enum";
import BusinessTypeDropDown from "app/components/BusinessTypeDropDown";
import { selectUser } from "app/containers/Auth/selectors";
import dayjs, { Dayjs } from "dayjs";
import DatePickerBusiness from "app/components/DatePickerBusiness";
import moment from "moment";


export default function BusinessAccountForm() {
    const dispatch = useDispatch();
    const styles = useStyles();
    const navigation = useNavigate();
    const businessAccountData = useSelector(selectBusinessAccountData);
    const loggedinUser = useSelector(selectUser);
    const [businessType, setBusinessType] = useState<string>("Choose Business Type");
    const [showSSN, setShowSSN] = useState<boolean>(false);
    const today = dayjs();
    const [ownerDOB, setOwnerDOB] = useState<Dayjs | null>(today);

    useEffect(() => {
        dispatch(
            actions.setbusinessAccountData({
                name: "ownerDOB",
                value: ownerDOB !== null ? ownerDOB?.format("YYYY-MM-DD") : "",
                form: "addBusinessAccount",
            })
        );
    }, [ownerDOB]);

    useEffect(() => {
        dispatch(actions.setbusinessAccountData({ name: 'email', value: loggedinUser?.userEmail ? loggedinUser?.userEmail + "" : JSON.parse(localStorage.getItem("loginResponse")!).userEmail, form: "addBusinessAccount" }));
        dispatch(actions.setbusinessAccountData({ name: 'userId', value: loggedinUser?.userId ? loggedinUser?.userId + "" : JSON.parse(localStorage.getItem("loginResponse")!).userId, form: "addBusinessAccount" }));
    }, [loggedinUser]);

    const onChangeEvent = (event) => {
        const { name, value } = event.target;

        if(name == 'businessEIN' || name == 'zip' || name == "ownerSSN"){
            dispatch(
                actions.setbusinessAccountData({ name, value: value.trim().replace(/[^\w\s]/gi, ""), form: "addBusinessAccount" })
            );
        }else{
            dispatch(
                actions.setbusinessAccountData({ name, value: value.trim(), form: "addBusinessAccount" })
            );
        }
        // dispatch(
        //     actions.setbusinessAccountData({ name, value: value.trim(), form: "addBusinessAccount" })
        // );
    }
    const handleBusinessTypeChange = (event) => {
        dispatch(actions.setbusinessAccountData({ name: 'businessEIN', value: '', form: "addBusinessAccount" }));
        dispatch(actions.setbusinessAccountData({ name: 'ownerSSN', value: '', form: "addBusinessAccount" }));
        dispatch(actions.setbusinessAccountData({ name: 'ownerDOB', value: '', form: "addBusinessAccount" }));

        setShowSSN(false);
        const option = event.target.value;
        const BusinessTypeName = option.split("_")[1];
        const BusinessTypeValue = Number(option.split("_")[0]);
        setBusinessType(BusinessTypeName);
        dispatch(actions.setBusinessType(BusinessTypeValue));
        if (BusinessTypeValue === BusinessType.SOLE_PARTNERSHIP || BusinessTypeValue === BusinessType.TRUST || BusinessTypeValue === BusinessType.UNICORPORATED_ASSOCIATION) {
            setShowSSN(true);
        }
    };

    const onOwnerDOBChangeEvent = (value) => {
        setOwnerDOB(value);
    };

    const handleKeyEvent = (e) => {
        let charCode = (e.which) ? e.which : e.keyCode;
        if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
            e.preventDefault();
        }
    };

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                width: "90%",
                alignItems: "flex-start",
                padding: "4px",
                margin: 'auto'
            }}
        >

            <div className={styles.disabledInputSpan}>
                <Typography
                    sx={{ color: "7D7D7D !important" }}
                    className={styles.pageTypoThresholdDisabled}
                >
                    Email Address
                </Typography>
                <input
                    className={styles.inputs}
                    placeholder="Enter Email Address"
                    name="email"
                    value={businessAccountData.email.value}
                    type="text"
                    disabled
                    onChange={onChangeEvent}
                />
                {businessAccountData.email.error && <Typography sx={{ color: '#922', marginLeft: '3%', width: '90%', alignSelf: 'center' }} >{businessAccountData.email.error}</Typography>}

            </div>

            <div className={styles.disabledInputSpan}>
                <Typography className={styles.pageTypoThresholdDisabled}>
                    First Name
                </Typography>
                <input
                    className={styles.inputs}
                    placeholder="Enter First Name"
                    name="firstName"
                    value={businessAccountData.firstName.value}
                    type="text"
                    onChange={onChangeEvent}
                />
                {businessAccountData.firstName.error && <Typography sx={{ color: '#922', marginLeft: '3%', width: '90%', alignSelf: 'center' }} >{businessAccountData.firstName.error}</Typography>}

            </div>


            <div className={styles.disabledInputSpan}>
                <Typography className={styles.pageTypoThresholdDisabled}>
                    Last Name
                </Typography>
                <input
                    className={styles.inputs}
                    placeholder="Enter Last Name"
                    name="lastName"
                    value={businessAccountData.lastName.value}
                    type="text"
                    onChange={onChangeEvent}
                />
                {businessAccountData.lastName.error && <Typography sx={{ color: '#922', marginLeft: '3%', width: '90%', alignSelf: 'center' }} >{businessAccountData.lastName.error}</Typography>}

            </div>


            <div className={styles.disabledInputSpan}>
                <Typography className={styles.pageTypoThresholdDisabled}>
                    Legal Business Name
                </Typography>
                <input
                    className={styles.inputs}
                    placeholder="Enter Legal business name"
                    name="businessName"
                    value={businessAccountData.businessName.value}
                    type="text"
                    onChange={onChangeEvent}
                />
                {businessAccountData.businessName.error && <Typography sx={{ color: '#922', marginLeft: '3%', width: '90%', alignSelf: 'center' }} >{businessAccountData.businessName.error}</Typography>}
            </div>
            <div className={styles.disabledInputSpan}>
                <Typography className={styles.pageTypoThresholdDisabled}>
                    Business Type
                </Typography>
                <BusinessTypeDropDown value={businessType} name="businessType" handleChange={handleBusinessTypeChange} />
                {businessAccountData.businessType.error && <Typography sx={{ color: '#922', marginLeft: '3%', width: '90%', alignSelf: 'center' }} >{businessAccountData.businessType.error}</Typography>}
            </div>
            {showSSN ? <div
                style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "row",
                    flexWrap: "wrap",
                    alignItems: "flex-start",
                }}
            >
                <div style={{ width: "49%", marginRight: "1%" }}>

                    <div className={styles.disabledInputSpan}>
                        <Typography className={styles.pageTypoThresholdDisabledOne}>
                            Owner SSN
                        </Typography>
                        <input
                            className={styles.inputs}
                            placeholder="Enter Owner SSN"
                            name="ownerSSN"
                            value={businessAccountData.ownerSSN.value}
                            type="text"
                            onChange={onChangeEvent}
                            onKeyDown={handleKeyEvent}
                        />
                        {businessAccountData.ownerSSN.error && <Typography sx={{ color: '#922', marginLeft: '3%', width: '90%', alignSelf: 'center' }} >{businessAccountData.ownerSSN.error}</Typography>}
                    </div>
                </div>
                <div style={{ width: "49%", marginRight: "1%" }}>
                    <div className={styles.disabledInputSpan}>
                        <Typography className={styles.pageTypoThresholdDisabledOne}>
                            Owner DOB
                        </Typography>
                        <DatePickerBusiness
                            handleChange={onOwnerDOBChangeEvent}
                            value={ownerDOB}
                            width={'100%'}
                        />
                        {businessAccountData.ownerDOB.error && <Typography sx={{ color: '#922', marginLeft: '3%', width: '90%', alignSelf: 'center' }} >{businessAccountData.ownerDOB.error}</Typography>}
                    </div>

                </div>
            </div> :
                <>
                    <div className={styles.disabledInputSpan}>
                        <Typography className={styles.pageTypoThresholdDisabled}>
                            Business EIN
                        </Typography>
                        <input
                            className={styles.inputs}
                            placeholder="Enter Business EIN"
                            name="businessEIN"
                            value={businessAccountData.businessEIN.value}
                            type="text"
                            onChange={onChangeEvent}
                            onKeyDown={handleKeyEvent}
                        />
                        {businessAccountData.businessEIN.error && <Typography sx={{ color: '#922', marginLeft: '3%', width: '90%', alignSelf: 'center' }} >{businessAccountData.businessEIN.error}</Typography>}
                    </div>
                </>
            }
            <div className={styles.disabledInputSpan}>
                <Typography className={styles.pageTypoThresholdDisabled}>
                    Business Address
                </Typography>
                <input
                    className={styles.inputs}
                    placeholder="Enter Street name and number"
                    name="businessAddress"
                    value={businessAccountData.businessAddress.value}
                    type="text"
                    onChange={onChangeEvent}
                />
            </div>
            {businessAccountData.businessAddress.error && <Typography sx={{ color: '#922', marginLeft: '3%', width: '90%', alignSelf: 'center' }} >{businessAccountData.businessAddress.error}</Typography>}

            <div className={styles.disabledInputSpan}>
                <input
                    className={styles.inputs}
                    placeholder="Aprt, floor, suite, bldg #"
                    name="businessAddress2"
                    value={businessAccountData.businessAddress2.value}
                    type="text"
                    onChange={onChangeEvent}
                />
                <Typography sx={{ marginLeft: '3%', width: '90%', color: '#ccc', fontSize: '12px', alignSelf: 'center' }} >Optional field</Typography>

            </div>


            <div className={styles.disabledInputSpan}>
                <Typography className={styles.pageTypoThresholdDisabled}>
                    City
                </Typography>
                <input
                    className={styles.inputs}
                    placeholder="Enter City"
                    name="city"
                    value={businessAccountData.city.value}
                    type="text"
                    onChange={onChangeEvent}
                />
            </div>
            {businessAccountData.city.error && <Typography sx={{ color: '#922', marginLeft: '3%', width: '90%', alignSelf: 'center' }} >{businessAccountData.city.error}</Typography>}

            <div
                style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "row",
                    flexWrap: "wrap",
                    alignItems: "flex-start",
                }}
            >
                <div style={{ width: "49%", marginRight: "1%" }}>
                    <Typography className={styles.pageTypoThresholdDisabledOne}>
                        State
                    </Typography>
                    <div className={styles.disabledInputSpan}>
                        <input
                            className={styles.inputs}
                            placeholder="State"
                            name="state"
                            value={businessAccountData.state.value}
                            type="text"
                            onChange={onChangeEvent}
                        />
                    </div>
                    {businessAccountData.state.error && <Typography sx={{ color: '#922', marginLeft: '3%', width: '90%', alignSelf: 'center' }} >{businessAccountData.state.error}</Typography>}

                </div>
                <div style={{ width: "49%", marginRight: "1%" }}>
                    <Typography className={styles.pageTypoThresholdDisabledOne}>
                        Zip Code
                    </Typography>
                    <div className={styles.disabledInputSpan}>
                        <input
                            className={styles.inputs}
                            placeholder="Enter City"
                            name="zip"
                            value={businessAccountData.zip.value}
                            type="text"
                            onChange={onChangeEvent}
                            onKeyDown={handleKeyEvent}
                        />
                    </div>
                    {businessAccountData.zip.error && <Typography sx={{ color: '#922', marginLeft: '3%', width: '90%', alignSelf: 'center' }} >{businessAccountData.zip.error}</Typography>}
                </div>
            </div>
        </div>
    );
}
