import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  signInButton: {
    "&:disabled": {
      background: "#C4C4C4",
      color : "#6B6B6B",
    },
    display: "flex",
    flexDirection: "row",
    width: "95%",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 16px",
    gap: "10px",
    height: "50px",
    background: "#192342",
    color: "#ffffff",
    borderRadius: "8px",
    marginTop: 41,
    "&:hover": {
      background: "#192342",
      color: "#ffffff",
    },
  },
  inputs: {
    padding: "10px 16px",
    width: "100%",
    height: "48px",
    background: "#FFF7DD",
    color: "#7D7D7D",
    borderRadius: "20px",
    border: "unset",
    margin: "5px",
    "&:focus": {
      outline: "none",
    },
  },
  pageHeading: {
    marginBottom: "24px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: "25px",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "140%",
    letterSpacing: "0.2px",
    color: "#192342",
  },
  inputSpan: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    padding: "10px 16px 10px 0px",
    width: "100%",
    height: "48px",
    background: "#FFF7DD",
    color: "#7D7D7D",
    borderRadius: "20px",
    border: "unset",
    margin: "5px",
    alignItems: "center",
  },
  centerContainer: {
    width: "100%",
    height: "12%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 0,
    margin: "0px 46px 0px 46px",
  },
  pageTypo2: {
    textAlign: "center",
    height: "fit-content",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "140%",
    letterSpacing: "0.2px",
    marginBottom: "5%",
    color: "#192342",
  },
  lowerContainer: {
    height: "68%",
    background: "#FFFFFF",
    boxShadow:
      "0px -37px 15px rgba(222, 222, 222, 0.01), 0px -21px 12px rgba(222, 222, 222, 0.05), 0px -9px 9px rgba(222, 222, 222, 0.09), 0px -2px 5px rgba(222, 222, 222, 0.1), 0px 0px 0px rgba(222, 222, 222, 0.1)",
    borderRadius: "30px 30px 0px 0px",
    padding: "7px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
});

export default useStyles;
