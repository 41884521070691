import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import useStyles from "./styles";
import { Typography } from "@mui/material";
export default function CountryDropDown({ handleChange, value, name }) {
  
  const styles = useStyles();
  const countryList = [
    "AF",
    "AL",
    "DZ",
    "AU",
    "BR",
    "KH",
    "CN",
    "DE",
    "IN"    
  ];

  return (
        <Select
            MenuProps={{ classes: { paper: styles.menuPaper } }}
            sx={{maxHeight:'100px', width: '100%'}}
            id="country"
            name={name}
            value={value}
            className={styles.select}
            onChange={
              handleChange}
            renderValue={() => (
              <Typography
               className={styles.typog}>
                {value}
              </Typography>
            )}
          >            
            {countryList.map((value, index) => {
            return (
            <MenuItem key={index * 89} value={value}>
            {value}
            </MenuItem>
            );
            })}                    
      </Select>
  );
}
