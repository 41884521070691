import { FormError } from "../../../../app/containers/types";
import set from "lodash/set";
import { createSlice } from "../../../../utils/@reduxjs/toolkit";

import { PayloadAction } from "@reduxjs/toolkit";
import { PortfolioListItemType, PropertyListItemType, S3UploadState } from "./types";
import moment from "moment";

const initialState = S3UploadState;

export const s3UploadSlice = createSlice({
  name: "s3UploadState",
  initialState,
  reducers: {      
      getDocumentUploadUrl(state, action) {
        state.loading = true;
      },
      getDocumentUploadUrlSuccess(state, action) {
        state.loading = false;
        if (action.payload.type === "properties/images") {
          state.imageUploadUrl = action.payload.response;
        } else {
          state.documentUploadUrl = action.payload.response;
        }
      },
      getDocumentUploadUrlError(state, action) {
        state.error = action.payload;
        state.loading = false;
      },

      setVideoFile(state, action) {
        state.videoFile = action.payload.file
        state.requestThumbnailVideo = action.payload.file.url
        state.requestVideo = action.payload.file.url
        //state.loading = true;
      },
      resetVideoFile: (state) => {
        state.videoFile = initialState.videoFile;
      },
  
      getUploadUrl(state, action) {
        state.loading = true;
      },
      getUploadUrlSuccess(state, action) {
        state.loading = false;
        state.videoUploadUrl = action.payload;
      },
      resetUploadedUrl: (state) => {
        state.videoUploadUrl = initialState.videoUploadUrl;        
        state.imageUploadUrl = initialState.imageUploadUrl;
        state.videoFile = initialState.videoFile;
      },
      updateVideoUrl(state, action) {
        state.videoUploadedUrl = action.payload;
      },
      getUploadUrlError(state, action: PayloadAction<any>) {
        state.error = action.payload;
        state.loading = false;
      },
      startLoading(state) {
        state.loading = true;
      },
      stopLoading(state) {
        state.loading = false;
      },
  },
});

export const { reducer, actions, name: sliceKey } = s3UploadSlice;
