import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import useStyles from "./styles";
import { Typography } from "@mui/material";
import { BusinessType, BusinessTypeValue } from "app/containers/Auth/CreateBusinessAccount/redux/enum";
export default function BusinessTypeDropDown({ handleChange, value, name }) {
  
  const styles = useStyles();
  return (
        <Select
            // MenuProps={{ classes: { paper: styles.menuPaper } }}
            sx={{maxHeight:'100px', width: '100%'}}
            id="businessType"
            name={name}
            value={value}
            className={styles.select}
            onChange={
              handleChange}
            renderValue={() => (
              <Typography
               className={styles.typog}>
                {value}
              </Typography>
            )}
          >
              <MenuItem style={{whiteSpace: 'normal'}}
                  value={BusinessType.LLCS+"_"+BusinessTypeValue.LLCS}>{BusinessTypeValue.LLCS}
              </MenuItem>
              <MenuItem style={{whiteSpace: 'normal'}}
                  value={BusinessType.CORPORATION+"_"+BusinessTypeValue.CORPORATION}>{BusinessTypeValue.CORPORATION}
              </MenuItem>
              <MenuItem style={{whiteSpace: 'normal'}}
                  value={BusinessType.SOLE_PARTNERSHIP+"_"+BusinessTypeValue.SOLE_PARTNERSHIP}>{BusinessTypeValue.SOLE_PARTNERSHIP}
              </MenuItem>
              <MenuItem style={{whiteSpace: 'normal'}}
                  value={BusinessType.TRUST+"_"+BusinessTypeValue.TRUST}>{BusinessTypeValue.TRUST}
              </MenuItem>
              <MenuItem style={{whiteSpace: 'normal'}}
                  value={BusinessType.UNICORPORATED_ASSOCIATION+"_"+BusinessTypeValue.UNICORPORATED_ASSOCIATION}>{BusinessTypeValue.UNICORPORATED_ASSOCIATION}
              </MenuItem>
              <MenuItem style={{whiteSpace: 'normal'}}
                  value={BusinessType.PUBLICLY_TRADED_CORPORATIONS+"_"+BusinessTypeValue.PUBLICLY_TRADED_CORPORATIONS}>{BusinessTypeValue.PUBLICLY_TRADED_CORPORATIONS}
              </MenuItem>
              <MenuItem style={{whiteSpace: 'normal'}}
                  value={BusinessType.NON_PROFITS+"_"+BusinessTypeValue.NON_PROFITS}>{BusinessTypeValue.NON_PROFITS}
              </MenuItem>
              <MenuItem style={{whiteSpace: 'normal'}}
                  value={BusinessType.PARTNERSHIP+"_"+BusinessTypeValue.PARTNERSHIP}>{BusinessTypeValue.PARTNERSHIP}
              </MenuItem>        
      </Select>
  );
}
