import { FormError } from "app/containers/types";
import { CreateBusinessAccountSRE } from "../../../redux/types";
import { ageValidator, emailValidator, stateValidator } from "utils/helpers";
import { BusinessType } from "../../../redux/enum";

export function valdiator(form: CreateBusinessAccountSRE): Array<FormError> {
  const errors: Array<FormError> = [];
  if (!form.email.value) {
    errors.push({
      name: "email",
      error: "Email is required",
    });
  }
  if (form.email.value && !emailValidator(form.email.value)) {
    errors.push({
      name: "email",
      error: "Enter valid email",
    });
  }
  if (!form.firstName.value) {
    errors.push({
      name: "firstName",
      error: "Firstname is required",
    });
  }
  if (!form.lastName.value) {
    errors.push({
      name: "lastName",
      error: "Lastname is required",
    });
  }
  if (form.businessType.value < 0) {
    errors.push({
      name: "businessType",
      error: "Business Type is required",
    });
  }
  if (!form.businessName.value) {
    errors.push({
      name: "businessName",
      error: "Business Name is required",
    });
  }
  if (!form.businessAddress.value) {
    errors.push({
      name: "businessAddress",
      error: "Business Address is required",
    });
  }
  if ((Number(form.businessType.value) === BusinessType.LLCS || Number(form.businessType.value) === BusinessType.CORPORATION || Number(form.businessType.value) === BusinessType.NON_PROFITS || Number(form.businessType.value) === BusinessType.PARTNERSHIP || Number(form.businessType.value) === BusinessType.PUBLICLY_TRADED_CORPORATIONS) && !form.businessEIN.value) {
    errors.push({
      name: "businessEIN",
      error: "Business EIN is required",
    });
  }

  if ((Number(form.businessType.value) === BusinessType.LLCS || Number(form.businessType.value) === BusinessType.CORPORATION || Number(form.businessType.value) === BusinessType.NON_PROFITS || Number(form.businessType.value) === BusinessType.PARTNERSHIP || Number(form.businessType.value) === BusinessType.PUBLICLY_TRADED_CORPORATIONS) && form.businessEIN.value && form.businessEIN.value.length !== 9) {
    errors.push({
      name: "businessEIN",
      error: "Business EIN should be 9 digit",
    });
  }

  if((Number(form.businessType.value) === BusinessType.SOLE_PARTNERSHIP || Number(form.businessType.value) === BusinessType.TRUST || Number(form.businessType.value) === BusinessType.UNICORPORATED_ASSOCIATION) && !form.ownerSSN.value)
    {
    errors.push({
      name: "ownerSSN",
      error: "Owner SSN is required",
    });
  }
  if((Number(form.businessType.value) === BusinessType.SOLE_PARTNERSHIP || Number(form.businessType.value) === BusinessType.TRUST || Number(form.businessType.value) === BusinessType.UNICORPORATED_ASSOCIATION) && form.ownerSSN.value && form.ownerSSN.value.length !== 9)
  {
  errors.push({
    name: "ownerSSN",
    error: "Owner SSN should be 9 digits",
  });
}
  if((Number(form.businessType.value) === BusinessType.SOLE_PARTNERSHIP || Number(form.businessType.value) === BusinessType.TRUST || Number(form.businessType.value) === BusinessType.UNICORPORATED_ASSOCIATION) && !form.ownerDOB.value)
    {
      errors.push({
      name: "ownerDOB",
      error: "Owner DOB is required",
    });
  }

  if((Number(form.businessType.value) === BusinessType.SOLE_PARTNERSHIP || Number(form.businessType.value) === BusinessType.TRUST || Number(form.businessType.value) === BusinessType.UNICORPORATED_ASSOCIATION) && form.ownerDOB.value && !ageValidator(form.ownerDOB.value))
  {
    errors.push({
    name: "ownerDOB",
    error: "Must be between 18 to 125 years of age.",
    });
  }

  if (!form.city.value) {
    errors.push({
      name: "city",
      error: "City is required",
    });
  }

  if (!form.state.value) {
    errors.push({
      name: "state",
      error: "State is required",
    });
  }

  if (form.state.value && !stateValidator(form.state.value)) {
    errors.push({
      name: "state",
      error: "State must be a 2-letter abbreviation with Uppercase.",
    });
  }

  if (!form.zip.value) {
    errors.push({
      name: "zip",
      error: "Zip is required",
    });
  }

  if (form.zip.value && form.zip.value.length < 5) {
    errors.push({
      name: "zip",
      error: "Invalid Zip",
    });
  }

  return errors;
}
