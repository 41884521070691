import {
  Button,
  Typography,
  IconButton,
  InputAdornment,
  TextField,
  Box,
  Grid,
  Dialog,
  Select,
  MenuItem,
  Radio,
  Checkbox,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { style } from "@mui/system";
import useStyles from "./styles";
import OtpInput from "react-otp-input";
import { LoggerClass as Logger } from "utils/logger";

type Props = {};
const TenantRemovedDialog = ({
  showPopup,
  closePopup = () => {},
  handleClose = () => {},
  handleSelect,
}) => {
 
  const styles = useStyles();
  const [show, setShow] = useState(false);
  const [otp, setOtp] = useState("");
  const [disable, setDisable] = useState(true);
  const [selectedCheck, setSelectedCheck] = useState<any>([]);

  const close = () => {
    setShow(false);
    closePopup();
  };

  useEffect(() => {
    if (otp?.length === 4) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [otp?.length]);

  useEffect(() => {
    setSelectedCheck([]);
    setShow(showPopup);
  }, [showPopup]);

  return (
    <Dialog open={show} className={styles.popup}>
      <Box sx={{ display: "flex", flexDirection: "column", margin: "16px" }}>
        <div
          style={{
            fontFamily: "Open Sans",
            fontSize: 18,
            color: "#192342",
            fontWeight: "600",
            textAlign: "center",
          }}
        >
          You have been removed from this property 
          
        </div>

        <div
          style={{
            fontFamily: "Open Sans",
            fontSize: 16,
            color: "#192342",
            fontWeight: "400",
            textAlign: "left",
            marginTop: 10
          }}
        >
          We received a request to remove you from the property. A landlord might decide to remove a tenant from the property if he is not owning the property anymore, or if the tenant lease is expired.
        </div>

        <div
          style={{
            fontFamily: "Open Sans",
            fontSize: 16,
            color: "#192342",
            fontWeight: "400",
            textAlign: "left",
            marginTop: 10
          }}
        >
          For this reason, You will be redirected to add your new residence address screen.
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: 25,
            marginBottom: 25
          }}
        >
          <Button
            className={styles.signInButton}
            onClick={() => {
              handleSelect(selectedCheck);
            }}
            style={{ marginLeft: 0 }}
            
          >
            Continue
          </Button>

         
        </div>
        <div className={styles.footerTxt}>Do you need help?</div>
        <div className={styles.footerTxt}>
          Contact us at{" "}
          <span className={styles.footTxt}> Support@Sre-Management.com</span>
        </div>
      </Box>
    </Dialog>
  );
};

export default TenantRemovedDialog;
