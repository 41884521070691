import { Typography, IconButton, Box } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import useStyles from "./styles";
import emailIcon from "../../../assets/email.png";
import PageContainer from "app/components/Container/PageContainer";
type Props = {};
const EmailVerification = (props: Props) => {
  const styles = useStyles();
  const navigate = useNavigate();

  const goToReset = () => {
    navigate("/reset-password");
  };
  return (
    <PageContainer>
      <Box className={styles.lowerContainer}>
        <Typography className={styles.pageHeading}>
          Email Verification
        </Typography>
        <Box className={styles.centerContainer}>
          <Typography className={styles.pageTypo2}>
            Please check your inbox to continue for the next steps
          </Typography>
          <IconButton
            className={styles.iconButton}
            onClick={goToReset}
          >
            <img src={emailIcon} alt="Back" />
          </IconButton>
        </Box>
        </Box>
    </PageContainer>
  );
};
export default EmailVerification;
