import { RootState } from "../../../../types";
import { createSelector } from "@reduxjs/toolkit";
import { ServiceRequestState } from "./types";

const selectDomain = (state: RootState) => state.serviceRequestState || ServiceRequestState;

export const selectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const selectList = createSelector([selectDomain], (state) => state.list);

