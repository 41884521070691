import {
  Button,
  Typography,
  IconButton,
  InputAdornment,
  Box,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useStyles from "./styles";
import searchpng from "../../../../assets/search.png";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import AddIcon from "@mui/icons-material/Add";
import { actions } from "../redux/slice";
import { actions as actionsLandlord} from "../../../LandlordAccount/redux/slice";
import { useDispatch, useSelector } from "react-redux";
import { getPropertyListResponse, getPropertyRequest, selectList, selectLoading, selectPropertyError } from "../redux/selector";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PortfolioDialog from "./Components/Dialog";
import Filter from "./filter";
import Header from "../../../../components/Header";
import Loader from "app/components/Loader";
import { selectUser } from "app/containers/Auth/selectors";
type Props = {};
const Portfolio = (props: Props) => {
  const { state = false }: any = useLocation();
  const styles = useStyles();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState(state);
  const [showFilter, setShowFilter] = useState(false);
  const [list, setList] = useState<any>(null);
  const loading = useSelector(selectLoading);
  const propertyRequest = useSelector(getPropertyRequest);
  const loggedInUser = useSelector(selectUser);
  const listitems = useSelector(getPropertyListResponse);

const [scrollPosition, setScrollPosition] = useState(0);

const propertyError = useSelector(selectPropertyError);

  useEffect(() => {
    if (propertyError === "Unauthorized") {
      dispatch(actions.resetModule());
      dispatch(actions.resetError());   
      navigate("/auth");
    }
  }, [propertyError]);

  
const handleScroll = () => {
  const position = window.pageYOffset;
  setScrollPosition(position);
};

useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
}, []);


  const closePopup = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    if (listitems) {
      setList(listitems);
    } else {
    }
  }, [listitems]);


  useEffect(() => {
    dispatch(actions.resetPropertyData());
    dispatch(actions.setStateStatus(""));
    dispatch(actions.setpropertyRequest({ name: 'ownerId', value: loggedInUser.userId+"", form: "getProperty" }));
    dispatch(actions.getList());
    dispatch(actions.getPropertyList(propertyRequest));
    dispatch(actionsLandlord.setShowBottomNavBar({ status: true, index: 1 }));
  }, [dispatch]);
  const handleClickProperty = () => {
    dispatch(actions.setStateStatus("portfolio"));
    navigate("/landlord-account/add-new-property");
  };
  const onChange = (e) => {
    setSearch(e.target.value);
    const filteredList = listitems.filter(obj => {
      return obj.Address?.toLowerCase().includes(e.target.value);
    });
    if (filteredList) {
      setList(filteredList);
    } else {
    }
  };

  const handleSelectedProperty = (property) => {
    if (property.PropertyId !== "") {
      dispatch(actions.setSelectedPortfolio(property));
      dispatch(actions.setSelectedPortfolioDetails({ id: property.PropertyId }));
    }
    navigate("/landlord-account/my-property?id=" + property.PropertyId);
  };

  const closeFilter = () => {
    setShowFilter(false);
  };

  const filterTask = () => {
    setShowFilter(false);
  };

  const showFilterTrue = () => {
    setShowFilter(true);
  };

  useEffect(() => {
  }, [showFilter]);

  return (
    <>
    <Loader loading={loading} />
      {!showFilter && (
        <Header
          rightIcon={true}
          handleNavigation={() => {
            navigate("/landlord-account/landlord-profile");
          }}
        />
      )}
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "16px 16px",
        }}
      >
        {showPopup && (
          <PortfolioDialog showPopup={showPopup} closePopup={closePopup} />
        )}
        {showFilter && (
          <Filter closePass={closeFilter} continuePass={filterTask} />
        )}

        {!showFilter && (
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              marginBottom: "113px",
            }}
          >
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography className={styles.typoLeftAligned}>
                My {list?.length >= 2 ? 'Properties' : 'Property'} 
              </Typography>
              <Box className={styles.inputSpan}>
                <input
                  className={styles.inputs}
                  placeholder="Search by Location, Area or ZIP Code"
                  value={search}
                  type="text"
                  onChange={onChange}
                />
                <InputAdornment position="end">
                  <span style={{ cursor: "pointer" }}>
                    <IconButton sx={{ color: "#D1D0D0" }}>
                      <img src={searchpng} alt={""} />
                    </IconButton>
                  </span>
                </InputAdornment>
              </Box>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                     
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <Box sx={{ width: "48%" }}>
                    <Button
                      className={styles.yellowButton}
                      variant="contained"
                      onClick={showFilterTrue}
                    >
                      <IconButton
                        sx={{
                          width: "14px",
                          height: "18px",
                          padding: "0",
                          marginRight: "10px",
                          alignSelf: "center",
                        }}
                      >
                        <FilterAltIcon sx={{ color: "#192342" }} />
                      </IconButton>
                      <Typography className={styles.typo70014}>
                        Filter by
                      </Typography>
                    </Button>
                  </Box>
                  <Box sx={{ width: "48%" }}>
                    <Button
                      className={styles.yellowButton}
                      variant="contained"
                      onClick={() => {
                        setShowPopup(true);
                      }}
                    >
                      <Typography className={styles.typo70014}>
                        Generate reports
                      </Typography>
                    </Button>
                  </Box>
                </Box>
                <Button className={styles.signInButton} variant="contained" onClick={handleClickProperty}>
                  <IconButton
                    sx={{
                      width: "14px",
                      height: "18px",
                      marginRight: "10px",
                      padding: "0",
                      alignSelf: "center",
                    }}
                  >
                    <AddIcon sx={{ color: "white" }} />
                  </IconButton>
                  <Typography className={styles.exitButtonTypo}>
                    Add new property
                  </Typography>
                </Button>
              </Box>
            </Box>
           
              <>
                {list?.length > 0 ? "" : 
                <Box>
                </Box>}
                {list?.map((item, index) => {
                  const currentDate = new Date();
                  currentDate.setDate(item.PayDay);
                  currentDate.setMonth(currentDate.getMonth() + 1);
                  const PayDay = currentDate.toISOString().substring(0, 10);

                  let fullName="";    

                  if(item.AllocatedUsers.length >= 1) { 
                              
                  item.AllocatedUsers.map((user, t) => {
                    let tempname = user.UserName;
                    if (t !== 0) {
                      fullName = fullName + "," + tempname;
                    } else {
                      fullName = user.UserName;
                    }
                  })
                }
                  return (
                    <>
                      <Box
                        sx={{
                          width: "100%",
                          marginTop: "24px",
                          filter:
                            "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
                          position: "relative",
                        }}
                      >
                        <IconButton
                          sx={{
                            width: "100%",
                            height: "130px",
                            borderRadius: "10px",
                            padding:"0px",
                          }}
                          onClick={(event) => {
                            handleSelectedProperty(item);
                          }}
                        >
                          <img
                            src={item?.Image ? item?.Image : '/homeImageOne.png'}
                            style={{
                              objectFit: "cover",
                              width: "100%",
                              height: "130px",
                              borderRadius: "10px",
                            }}
                            alt="no image"
                          />
                        </IconButton>
                        <Box
                          sx={{
                            position: "absolute",
                            top: "10px",
                            left: "10px",
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            sx={{ marginRight: "10px" }}
                            className={styles.typo40014}
                          >
                            {item.Status === 2 ? 'Owner Occupied' : item.Status === 1 ? 'Tenant Occupied' : 'Vacant'}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            position: "absolute",
                            bottom: "0px",
                            right: "0px",
                            background: "#F0BB00",
                            borderRadius: "10px 0px",
                             
                            height: "31px",
                          }}
                        >
                          <Typography className={styles.typo12600button}>
                            {item.PropertyOccupationStatuses === 1 ? 'Pending Tanent Approval' : 'Ok'}
                          </Typography>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          width: "100%",
                          border: "0.5px solid #BCBCBC",
                          boxShadow:
                            "9px 19px 8px rgba(199, 199, 199, 0.01), 5px 11px 7px rgba(199, 199, 199, 0.05), 2px 5px 5px rgba(199, 199, 199, 0.09), 1px 1px 3px rgba(199, 199, 199, 0.1), 0px 0px 0px rgba(199, 199, 199, 0.1)",
                          borderRadius: "0px 0px 10px 10px",
                          zIndex: -1,
                          marginTop: "-5px",
                          padding: "10px",
                        }}
                      >
                        <Typography className={styles.address}>
                          <LocationOnIcon sx={{ color: "#192342" }} />
                          {item.Address}
                        </Typography>

                        {item.Status === 1 &&
                          item.AllocatedUsers.length >= 1 && (
                            <Box
                              sx={{
                                 
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <Box
                                sx={{
                                  padding: " 5px 10px",
                                  width: "48%",
                                  height: " 52px",
                                  background: " #F5F5F5",
                                  borderRadius: " 10px",
                                }}
                              >
                                <Typography className={styles.typo12700}>
                                  Tenant Name
                                </Typography>
                                <Typography className={styles.address}>                                  
                                  { fullName ? fullName : '' }
                                </Typography>
                              </Box>
                              <Box
                                sx={{
                                  padding: " 5px 10px",
                                  width: "48%",
                                  height: " 52px",
                                  background: " #F5F5F5",
                                  borderRadius: " 10px",
                                }}
                              >
                                <Typography className={styles.typo12700}>
                                  Next rental payment
                                </Typography>
                                <Typography className={styles.address}>                                 

                                  {PayDay}
                                </Typography>
                              </Box>
                            </Box>
                          )}
                      </Box>
                    </>
                  );
                })}
              </>
            
          </Box>
        )}
      </Box>
    </>
  );
};

export default Portfolio;
