import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  tabSection: {
    width: '90%',
    display: 'flex',
    margin : 'auto',
    flexDirection : 'column'
  },
  tabButton : {
    color : "#ffffff",
    fontSize : 14,
    fontWeight: 600,
    fontFamily : 'Open Sans',
    borderRadius : 12,
    height : '45px',
    minHeight : '45px',
    "&.Mui-selected": {
      border: "none",
      backgroundColor: "#F0BB00",
      color: "#192342",
    },
  },
  tabList : {
    padding : 10,
    "& .MuiTabs-indicator": {
      display: "none"
    },
    "& .MuiTabs-flexContainer": {
      justifyContent : 'space-around',
    },        
  },
  tabPanel: {
    padding : 0
  },
    checkBoxView:{
        flexDirection:'row',
        display:'flex',
        flex:1,
        marginBottom:10,
        // backgroundColor:"red",
        paddingLeft:15
        // marginleft:29,
        
    },
    checkBoxTitle:{
        alignSelf:'center',
        fontSize:14
    },textStyle: {
        width:'100%',
        alignSelf:'center',
        // marginTop:24,
        marginBottom:10,
        // marginLeft:16,
        // marginRight:16
    },
    textStyle1: {
      width:'100%',
        alignSelf:'center',
        // marginTop:24,
        marginBottom:0,
       // marginLeft:16,
    },
    leftSide:{
      color:'#7D7D7D',
      paddingLeft:10,
      paddingRight:10,
      fontSize:18,
      fontWeight: '600',
      borderRadius:6,
      paddingTop:2,
      paddingBottom:2
      },
    label:{
        fontSize:14,
        color:'#192342',
        marginLeft:32,
        // marginRight:16,
        marginBottom:5
      },
      labelBlue:{
        fontSize:14,
        marginLeft:32,
        // marginRight:16,
        marginBottom:5,
        marginRight:16,
        color:'#192342',
        fontWeight:'600',
      },
      box:{
        backgroundColor:'#F0BB00',
        color:'#192342',
        fontSize:'14px',
        fontWeight:'600',
        fontFamily:'Open Sans',
        paddingLeft:13,
        paddingRight:13,paddingTop:7,
        paddingBottom:7,
        borderRadius:8,
        textAlign:'center',
        display:'flex',
        flex:0.47,
        justifyContent:'center',
        alignItems:'center'
      },
      box1: {
        backgroundColor:'#F0BB00',
        color:'#192342',
        fontSize:'14px',
        fontWeight:'600',
        fontFamily:'Open Sans',
        paddingLeft:13,
        paddingRight:13,paddingTop:7,
        paddingBottom:7,
        marginLeft: 10,
        borderRadius:8,
        textAlign:'center',
        display:'flex',
        flex:0,
        justifyContent:'center',
        alignItems:'center',
        height: '50px'
      },
      boxButton:{
        backgroundColor:'#F0BB00',
        color:'#192342',
        fontSize:'14px',
        fontWeight:'600',
        fontFamily:'Open Sans',
        paddingLeft:16,
        paddingRight:16,
        paddingTop:16,
        paddingBottom:16,
        borderRadius:8,
        textAlign:'center',
        display:'flex',
        flex:0.47,
        justifyContent:'center',
        alignItems:'center',
        width: '90%',
        margin: 'auto',
        marginBottom: '20px !important'
      },
      footerTxt:{
        fontSize:12,
        fontWeight:400,
        fontFamily:"Open Sans",
        textAlign:'center',
        color:'#192342'
      },
      footTxt:{
        fontSize:12,
        fontWeight:600,
        fontFamily:"Open Sans",
        textAlign:'center',
        color:'#006CFD',

      },
      btn:{
        fontSize: "14px",
            fontWeight: 700,
            alignSelf: "center",
            padding: "12px 0",
            borderRadius: "10px",
            backgroundColor: "#192342",
            margin: "30px 0px",
            color: "#FFFFFF",
            marginLeft:16,
            marginRight:16
      },outlinebtn:{ fontSize: "16px",alignSelf: "center",marginBottom:24,marginLeft:16,
      marginRight:16,width:'90%',fontWeight: '600', fontFamily: 'Open Sans'},
      popup: {
        "& .MuiDialog-container": {},
        "& .MuiPaper-root ": {
          width: "100%",
          overflow: "auto",
        //   padding: "1%",
          marginTop: "10%",
          background: "#FFFFFF",
          boxShadow:
            "11px 24px 11px rgba(61, 61, 61, 0.01), 6px 13px 9px rgba(61, 61, 61, 0.05), 3px 6px 7px rgba(61, 61, 61, 0.09), 1px 1px 4px rgba(61, 61, 61, 0.1), 0px 0px 0px rgba(61, 61, 61, 0.1)",
          borderRadius: "30px",
        },
      },
      modalTitle:{
        fontFamily:'Open Sans',
        fontWeight:'600',
        fontSize:16,
        color:'192342',
        textAlign:'center',
        marginBottom:'10px'

      },
      modalSubTitle:{
        fontFamily:'Open Sans',
        fontWeight:'400',
        fontSize:16,
        color:'192342',
        textAlign:'center'
      },
      typo60014: {
        fontFamily: "Open Sans",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "14px",
        lineHeight: "18px",
        letterSpacing: "0.2px",
        color: "#192342",
        marginTop:'10px'
      },
      signInButton: {
        "&:disabled": {
          background: "#C4C4C4",
          color: "#6B6B6B",
        },
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        height: "50px",
        background: "#192342",
        color: "#ffffff",
        borderRadius: "8px",
        margin: "0px 0px",
        fontWeight: '600',
        fontSize: '16px',
        fontFamily:'Open Sans',
        "&:hover": {
          background: "#192342",
          color: "#ffffff",
        },
      },
      otpInputRoot: {
        "& input": {
          background: "transparent",
          outline: "none",
          border: "none",
          borderBottom: "2px solid #FFFFFF",
          // width: "100%!important",
          fontSize: "42px",
          color: "#FFF",
          padding: '11px 0px'
        },
        // width: 64,
        // marginTop: 100,
        display:'flex',
        flex:1,
        justifyContent:"center",
        
      },
      thresholdAmountHintTxtStyle: {
        fontWeight: '400',
        fontSize: 10,
        color:'#192342',
        marginLeft:32,
        marginBottom:5,
        marginRight:16,
        marginTop: -10
      },
})
