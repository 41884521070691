import { Button, Typography, IconButton, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useStyles from "./styles";
import SRE_Logo from "../../../../assets/logo.png";
import arrowBack from "../../../../assets/backArrow.png";
import { useDispatch } from "react-redux";
import SearchGoogleAddress from '../../../../components/SearchGoogleAddress';
import {
  actions,
  reducer,
  sliceKey,
} from "app/containers/CommonComponent/Property/redux/slice";
import { useInjectReducer } from "utils/redux-injectors";
import { useSelector } from "react-redux";
import { addPropertyFormData, checkStateStatusData, selectPropertyError } from "../redux/selector";

import { valdiator } from "./validator";
import { selectUser } from "app/containers/Auth/selectors";
import PropertyRegisteredDialog from "./Component/PropertyRegisteredDialog";
type Props = {};
const AddNewProperty = (props: Props) => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  const styles = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [streetError, setStreetError] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

 
  const addProperty = useSelector(addPropertyFormData);
  const loggedInUser = useSelector(selectUser);
  const error = useSelector(selectPropertyError);
  const checkStateStatus = useSelector(checkStateStatusData);
  const userId = JSON.parse(localStorage.getItem('loginResponse')!).userId;
  const userName = JSON.parse(localStorage.getItem('loginResponse')!).firstName+" "+JSON.parse(localStorage.getItem('loginResponse')!).lastName;

  useEffect(() => {
    if (error === "Unauthorized") {
      dispatch(actions.resetModule());
      navigate("/auth");
    }
  }, [error]);

  const errors = valdiator(addProperty);
  
  useEffect(() => {
    dispatch(
      actions.setPropertyData({
        name: "ownerId",
        value: loggedInUser?.userId ? loggedInUser?.userId+"" : userId,
        form: "addProperty",
      })
    );  
    dispatch(
      actions.setPropertyData({
        name: "ownerName",
        value: loggedInUser?.firstName ? loggedInUser.firstName+" "+loggedInUser.lastName : userName,
        form: "addProperty",
      })
    );   
    dispatch(actions.resetLeaseData());
    dispatch(actions.setShowBottomNavBar({ status: true, index: 1 }));
  });

  const goBack = () => {
    navigate(-1);
  };

  const handleKeyEvent = (e) => {
    var regex = /^[A-Za-z0-9 ]+$/;
    if(!regex.test(e.key)) {
      e.preventDefault();
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
        if(errors.length){
      dispatch(actions.addPropertyFormDataError({ key: 'addProperty', errors }))
      return
    }
    dispatch(actions.addPropertyData({data: addProperty, callback: onLoginSuccess}));
  };

  const onLoginSuccess = () => {  
      console.log(checkStateStatus);  
      if(checkStateStatus === "Auth") {
        setShowPopup(false);
      if(Number(addProperty?.occupationStatus.value) === 1 || addProperty?.occupationStatus.value === "1") {
        navigate("/add-lease");
        dispatch(actions.resetProperty());
      } else {
        setShowPopup(true);
      }
    } else if(checkStateStatus === "portfolio") {
      navigate("/landlord-account/portfolio");
    } else {
      navigate("/landlord-account");
    }
    };

  const onChangeEvent = (e) => {
    const { name, value } = e.target;
    if(name == "type" && value == "0") {
      dispatch(
        actions.setPropertyData({ name: 'units', value: '0', form: "addProperty" })
      );
    }
    dispatch(
      actions.setPropertyData({ name, value, form: "addProperty" })
    );
  };

  const onChangeEventViaGoogleApi = (response) => {
      for (const data in response) {
      dispatch(
        actions.setPropertyData({ name: data , value: response[data], form: "addProperty" })
      );
    }
  }

  useEffect(() => {
    if (errors.length) {
      dispatch(actions.addPropertyFormDataError({ key: 'addProperty', errors }))
      return;
    }
   }, [addProperty.street.value]);

  useEffect(() => {
    if (errors.length) {
      dispatch(actions.addPropertyFormDataError({ key: 'addProperty', errors }))
      return;
    }    
  }, [addProperty.units.value]);

  return (
    <>
     {showPopup && (
        <PropertyRegisteredDialog
          popupProp={showPopup}
          popupFunction={() => {
            setShowPopup(false)            
          }}
        />
      )}
      
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        background: "#FFF7DD",
      }}
    >
      <div className={styles.upperContainer}>
        <IconButton
          sx={{ width: "24px", height: "24px", margin: "20px 0 0px 20px" }}
          onClick={goBack}
        >
          <img
            src={arrowBack}
            style={{
              height: 16,
              width: 16,
            }}
            alt="<-"
          />
        </IconButton>
        <div className={styles.logoContainer}>
          <img src={SRE_Logo} alt="logo" className={styles.logo} />
        </div>
      </div>
      <div className={styles.lowerContainer}>
        <Typography className={styles.pageHeading}>Add Property</Typography>
        <div className={styles.centerContainer}>
          <Typography className={styles.pageTypoone}>
            Choose the property type to add
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "flex-start",
            padding: "4px",
          }}          
        >
          <div className={styles.radioDiv}>
            <input
              checked={addProperty.type.value === "1"}
              name="type"
              type="radio"
              value="1"
              id="radio-multi"
              className={styles.checkbox}
              onChange={onChangeEvent}
            />
            <label htmlFor="radio-multi" className={styles.checkboxTypos}>
              Multi-Unit
            </label>
          </div>
          {addProperty.type.value === "1" && (
            <>
              <span className={styles.inputSpan}>
                <input
                  className={styles.inputs}
                  placeholder="Enter unit number"
                  value={addProperty.units.value}
                  name="units"
                  onChange={onChangeEvent}
                  onKeyDown={handleKeyEvent}
                  type="text"
                  min="0"
                />
               
              </span>
              {addProperty.units.error && (
                <Typography className={styles.errorTypo}>
                  {addProperty.units.error}
                </Typography>
              )}
            </>
          )}
          <div
            className={styles.radioDiv}
            style={{ marginBottom: "24px !important" }}
          >
            <input
              checked={addProperty.type.value === "0"}
              type="radio"
              name="type"
              onChange={onChangeEvent} 
              value="0"
              id="radio-single"
              className={styles.checkbox}
            />
            <label htmlFor="radio-single" className={styles.checkboxTypos}>
              Single-Family Home
            </label>
          </div>
          <Typography sx={{ color: "7D7D7D !important" }}
            className={styles.pageTypoThresholdDisabled}
          >Street</Typography>
          <span
            className={!streetError ? styles.inputSpan : styles.errorInputSpan}
          >
          
          <SearchGoogleAddress value={addProperty.street.value} name="street" 
          handleChange={onChangeEventViaGoogleApi}/>
          </span>
          {addProperty.street.error && (
            <Typography className={styles.errorTypo}>
              {addProperty.street.error}
            </Typography>
          )}
          <Typography
            sx={{ color: "7D7D7D !important" }}
            className={styles.pageTypoThresholdDisabled}
          >
            City
          </Typography>
          <span className={styles.disabledInputSpan}>
            <input
              disabled
              className={styles.inputs}
              placeholder="City"
              name="city"
              value={addProperty.city.value}
              type="text"
              onChange={onChangeEvent}
              />
          </span>
          <Typography className={styles.pageTypoThresholdDisabled}>
            State
          </Typography>
          <span className={styles.disabledInputSpan}>
            <input
              disabled
              className={styles.inputs}
              placeholder="State"
              name="state"
              value={addProperty.state.value}
              type="text"
              onChange={onChangeEvent}
            />
          </span>
          <Typography className={styles.pageTypoThresholdDisabled}>
            Zip Code
          </Typography>
          <span className={styles.disabledInputSpan}>
            <input
              disabled
              className={styles.inputs}
              placeholder="Zip Code"
              name="zip"
              value={addProperty.zip.value}
              type="text"
              onChange={onChangeEvent}
            />
          </span>
          <Typography sx={{ marginTop: "10px" }} className={styles.pageTypo2}>
            Choose Property’s Occupation Status
          </Typography>
          <div className={styles.radioDiv}>
            <input
              checked={addProperty.occupationStatus.value === "0"}
              type="radio"
              name="occupationStatus"
              onChange={onChangeEvent}
              value="0"
              id="radio-vacant"
              className={styles.checkbox}
            />
            <label htmlFor="radio-vacant" className={styles.checkboxTypos}>
              Vacant
            </label>
          </div>
          <div className={styles.radioDiv}>
            <input
              checked={addProperty.occupationStatus.value === "1"}
              type="radio"
              name="occupationStatus"
              onChange={onChangeEvent}
              value="1"
              id="radio-tenant"
              className={styles.checkbox}
            />
            <label htmlFor="radio-tenant" className={styles.checkboxTypos}>
              Tenant Occupied
            </label>
          </div>
          <div className={styles.radioDiv}>
            <input
              checked={addProperty.occupationStatus.value === "2"}
              type="radio"
              name="occupationStatus"
              onChange={onChangeEvent}
              value="2"
              id="radio-owner"
              className={styles.checkbox}
            />
            <label htmlFor="radio-owner" className={styles.checkboxTypos}>
              Owner Occupied
            </label>
          </div>
        </div>
        <div
          style={{
            marginBottom: "10px",
            paddingBottom: '100px',
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            disabled={
              !(
                addProperty.street.value !== "" &&
                addProperty.occupationStatus.value !== "" &&
                addProperty.type.value !== ""
              )
            }
            className={styles.signInButton}
            variant="contained"
            onClick={handleSubmit}
          >
            Continue
          </Button>
          
        </div>
      </div>
    </div>
    </>
  );
};
export default AddNewProperty;
