import { Button, Typography, IconButton, InputAdornment } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useStyles from "./styles";
import SRE_Logo from "../../../assets/logo.png";
import arrowBack from "../../../assets/backArrow.png";
import dollar from "../../../assets/dollar.png";
import { Link } from "react-router-dom";
type Props = {};
const ChangePaymentMethod = (props: Props) => {
  // const { width, height } = useWindowDimensions();
  const styles = useStyles();
  const navigate = useNavigate();
  // const loading = useSelector(selectLoading);
  const [amount, setAmount] = useState("");
  const [amountError, setAmountError] = useState(false);
  const [keyDown, setKeyDown] = useState(false);
  const onKeyDown = (e) => {
    setKeyDown(true);
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAmount(event.target.value);
    // dispatch(actions.setForm({ key: `loginForm.${name}`, value }));
  };

  const goBack = () => {
    navigate(-1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let tempAmount = parseInt(amount);
    if (
      tempAmount === undefined ||
      tempAmount === null ||
      tempAmount === 0 ||
      tempAmount < 250
    ) {
      setAmountError(true);
      setTimeout(() => {
        setAmountError(false);
      }, 5000);
    } else {
      navigate("/payment-method-details");
    }
  };

  const checkBox = () => {};
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        background: "#FFF7DD",
      }}
    >
      <div className={styles.upperContainer}>
        <IconButton
          sx={{ width: "24px", height: "24px", margin: "20px 0 0px 20px" }}
          onClick={goBack}
        >
          <img
            src={arrowBack}
            style={{
              height: 16,
              width: 16,
            }}
            alt="<-"
          />
        </IconButton>
        <div className={styles.logoContainer}>
          <img src={SRE_Logo} alt="logo" className={styles.logo} />
        </div>
      </div>
      <div className={styles.lowerContainer}>
        <Typography className={styles.pageHeading}>My Property</Typography>
        <div className={styles.centerContainer}>
          <Typography className={styles.pageTypo2}>
            Please choose a method to receive rental incomings
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "flex-start",
            padding: "10px",
          }}
        >
          <div>
            <input
              type="checkbox"
              className={styles.checkbox}
              onChange={checkBox}
            />
            <Typography className={styles.checkboxTypos}>Zelle</Typography>
          </div>
          <div>
            <input
              type="checkbox"
              className={styles.checkbox}
              onChange={checkBox}
            />
            <Typography className={styles.checkboxTypos}>Venmo</Typography>
          </div>
          <div>
            <input
              type="checkbox"
              className={styles.checkbox}
              onChange={checkBox}
            />
            <Typography className={styles.checkboxTypos}>
              PayPal - Business Account
            </Typography>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-around",
            width: "100%",
          }}
        >
          <Typography className={styles.pageTypoThreshold}>
            Threshold amount
          </Typography>
          <span
            className={!amountError ? styles.inputSpan : styles.errorInputSpan}
          >
            <input
              className={styles.inputs}
              placeholder="Enter threshold amount"
              value={amount}
              type="number"
              onChange={onChange}
              onKeyDown={onKeyDown}
            />
            <InputAdornment position="end">
              <span style={{ cursor: "pointer" }}>
                <IconButton sx={{ color: "#D1D0D0" }}>
                  <img src={dollar} alt={""} />
                </IconButton>
              </span>
            </InputAdornment>
          </span>
          <Typography className={styles.pageTypoMinimum}>
            Minimum 250$
          </Typography>
          {amountError && (
            <Typography className={styles.errorTypo}>Invalid Amount</Typography>
          )}
        </div>
        <div
          style={{
            marginBottom: "10px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            disabled={!keyDown}
            className={styles.signInButton}
            variant="contained"
            onClick={handleSubmit}
          >
            Continue
          </Button>
          <Typography className={styles.bottomTypo}>
            Do you need help?
            <br />
            Contact us at{" "}
            <Link className={styles.bottomLink} to="/welcomScreen">
              Support@Sre-Management.com
            </Link>
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default ChangePaymentMethod;
