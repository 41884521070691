import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  MenuItem,
  Radio,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { ChangeEvent, useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import useStyles from "../../styles";
import uploadpng from "../../../../../assets/upload.png";
import ApprovePopUp from "../ApprovePopup";
import RejectedPopUp from "../RejectedPopup";
import { ServiceStatus, ServiceType } from "app/containers/LandlordAccount/redux/Landlord.enum";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "app/containers/LandlordAccount/redux/slice";
import {actions as propertyActions } from "../../../../CommonComponent/Property/redux/slice";
import { getSelectedPortfolio, selectServiceRequests } from "app/containers/LandlordAccount/redux/selector";
type Props = {};
const ServiceTickets = ({ clickedStatus, setShowServiceTickets }) => {
  const styles = useStyles();
  const [index, setIndex] = useState(3);
  const dispatch = useDispatch();
  const [disabledButtons, setDisabledButtons] = useState();
  const [approvePopup, setApprovePopup] = useState(false);
  const [rejectPopup, setRejectPopup] = useState(false);
  const [username, setUsername] = useState("");

  const handleReportTypeChange = (event) => {
    const option = event.target.value;
    //dispatch(actions.setSelectedServiceRequestStatusType(event.target.value));
  };
  const [docName, setDocName] = useState("");
  const [docUploaded, setDocUploaded] = useState(false);  
  const selectedRequests = useSelector(selectServiceRequests);
  const selectedPortfolio = useSelector(getSelectedPortfolio);

  useEffect(() => {
    if(clickedStatus?.ServiceRequestID) 
    {
      dispatch(actions.setSelectedServiceRequest({id : clickedStatus?.ServiceRequestID, callback : getPropertyDetails}));
    }
  }, [clickedStatus?.ServiceRequestID]);

  const getPropertyDetails = () => {
    dispatch(
      propertyActions.setSelectedPortfolioDetails({ id: selectedRequests?.PropertyId ? selectedRequests?.PropertyId : clickedStatus?.PropertyID })
    );
  }

  let uploadedDocument;

  const serviceType = (type) => { 
    switch (type) {
      case ServiceType.AC:
        return 'A/C';
      case ServiceType.ELECTRICAL:
        return 'Electrical';
      case ServiceType.HANDYMAN:
        return 'Handyman';
      case ServiceType.LOCKSMITH:
        return 'LockSmith';
      case ServiceType.PLUMBLING:
        return 'Plumbling';
      case ServiceType.STRUCTURE_SERVICE:
        return 'Structure Service';
      default:
        return '';
    }
  }

  const serviceStatus = (status) => { 
    switch (status) {
      case ServiceStatus.NEW:
        return 'New';
      case ServiceStatus.SELECT_TIME_FRAME:
        return 'Select Time Frame';
      case ServiceStatus.SCHEDULED_PENDING_PAYMENT:
        return 'Scheduled, Pending Payment';
      case ServiceStatus.PENDING_APPROVAL:
        return 'Pending Approval';
      case ServiceStatus.INPROGRESS:
        return 'InProgress';
      case ServiceStatus.COMPLETED_PENDING_PAYMENT:
        return 'Completed, Pending Payment';
      case ServiceStatus.COMPLETED:
        return 'Completed';
      case ServiceStatus.REOPEN:
        return 'ReOpen';
      case ServiceStatus.DISCARD:
        return 'Discard';
      default:
        return '';
    }
  }

  useEffect(() => {
    let fullname = "";
    selectedPortfolio?.AllocatedUsers?.map((user, index) => {
      let tempname = user.UserName;
      if (index !== 0) {
        fullname = fullname + "," + tempname;
      } else {
        fullname = user.UserName + "";
      }
    });
    setUsername(fullname);
}, [selectedPortfolio]);
  
  const goBack = () => {
    setShowServiceTickets(false);
  };
  const handleDocumentUpload = (event) => {
    uploadedDocument.click();
  };

  const closePopup = () => {
    setApprovePopup(false);
    setRejectPopup(false);
  };

  const closeDoc = () => {
    setDocUploaded(false);
  };

  return (
    <>
      {approvePopup ? (
        <ApprovePopUp
          showPopup={approvePopup}
          closePopup={closePopup}
          continueClick={() => {
            setApprovePopup(false);
          }}
        />
      ) : null}

      {rejectPopup ? (
        <RejectedPopUp
          showPopup={rejectPopup}
          closePopup={closePopup}
          continueClick={() => {
            setRejectPopup(false);
          }}
        />
      ) : null}

      <div className={styles.container}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "24px",
            alignItems: "center",
          }}
        >
          <IconButton onClick={goBack}>
            <ArrowBackIcon
              sx={{
                height: 24,
                width: 24,
                color: "#192342",
              }}
            />
          </IconButton>
          <Typography className={styles.headerTitle}>
            Service request ticket {serviceType(selectedRequests?.ServiceType)} - #{selectedRequests?.ServiceRequestID}
          </Typography>
        </Box>
        {selectedRequests?.WorkStatus === ServiceStatus.PENDING_APPROVAL ||
        selectedRequests?.WorkStatus === ServiceStatus.COMPLETED_PENDING_PAYMENT || selectedRequests?.WorkStatus === ServiceStatus.NEW ? (
          <>
            <div className={styles.label}>Address</div>
            <TextField
              color="primary"
              variant="outlined"
              required
              name="PropertyAddress"
              value={selectedRequests?.PropertyAddress}
              disabled={true}
              onChange={(event) => {              
              }}
              className={styles.textStyle}              
            />
          </>
        ) : null}
        {selectedRequests?.WorkStatus === ServiceStatus.PENDING_APPROVAL ||
        selectedRequests?.WorkStatus === ServiceStatus.COMPLETED_PENDING_PAYMENT  || selectedRequests?.WorkStatus === ServiceStatus.NEW ? (
          <>
            <div>
              <div className={styles.label}>Tenant</div>
              <TextField
                color="primary"
                variant="outlined"
                required
                name="tanantName"
                value={username ? username : "N/A"}
                disabled={true}
                onChange={(event) => {
                }}
                className={styles.textStyle}
              />
            </div>
          </>
        ) : null}
        {selectedRequests?.WorkStatus === ServiceStatus.PENDING_APPROVAL ||
        selectedRequests?.WorkStatus === ServiceStatus.COMPLETED_PENDING_PAYMENT  || selectedRequests?.WorkStatus === ServiceStatus.NEW ? (
          <>
            <div>
              <div className={styles.label}>Service Provider</div>
              <TextField
                color="primary"
                variant="outlined"
                required
                placeholder={"N/A"}
                name="ServiceProviderName"
                value={selectedRequests?.ServiceProviderName && selectedRequests?.ServiceProviderName !== " " ? selectedRequests?.ServiceProviderName : "N/A"}
                disabled={true}
                onChange={(event) => {
                }}
                className={styles.textStyle}               
              />
            </div>
          </>
        ) : null}

        {selectedRequests?.WorkStatus === ServiceStatus.COMPLETED_PENDING_PAYMENT  || selectedRequests?.WorkStatus === ServiceStatus.NEW ? (
          <>
            <div className={styles.label}>Job Status</div>
            <Select
              id="reportType"
              value={selectedRequests?.WorkStatus}
              className={styles.select}
              onChange={handleReportTypeChange}
              renderValue={() => (
                <Typography className={styles.typoGray40016}>
                  {serviceStatus(selectedRequests?.WorkStatus)}
                </Typography>
              )}
            >
              <MenuItem value={ServiceStatus.SELECT_TIME_FRAME}>
                {serviceStatus(ServiceStatus.SELECT_TIME_FRAME)}
              </MenuItem>
              <MenuItem value={ServiceStatus.NEW}>{serviceStatus(ServiceStatus.NEW)}</MenuItem>
              <MenuItem value={ServiceStatus.SCHEDULED_PENDING_PAYMENT}>
                {serviceStatus(ServiceStatus.SCHEDULED_PENDING_PAYMENT)}
              </MenuItem>
              <MenuItem value={ServiceStatus.INPROGRESS}>{serviceStatus(ServiceStatus.INPROGRESS)}</MenuItem>
              <MenuItem value={ServiceStatus.PENDING_APPROVAL}>
                {serviceStatus(ServiceStatus.PENDING_APPROVAL)}
              </MenuItem>
              <MenuItem value={ServiceStatus.COMPLETED_PENDING_PAYMENT}>
                {serviceStatus(ServiceStatus.COMPLETED_PENDING_PAYMENT)}
              </MenuItem>
              <MenuItem value={ServiceStatus.COMPLETED}>{serviceStatus(ServiceStatus.COMPLETED)}</MenuItem>
              <MenuItem value={ServiceStatus.REOPEN}>{serviceStatus(ServiceStatus.REOPEN)}</MenuItem>
              <MenuItem value={ServiceStatus.DISCARD}>{serviceStatus(ServiceStatus.DISCARD)}</MenuItem>
            </Select>
          </>
        ) : null}
        {selectedRequests?.WorkStatus === ServiceStatus.PENDING_APPROVAL ||
        selectedRequests?.WorkStatus === ServiceStatus.COMPLETED_PENDING_PAYMENT  || selectedRequests?.WorkStatus === ServiceStatus.NEW ? (
          <>
            <div>
              <div className={styles.label}>Service Estimate</div>
              <TextField
                color="primary"
                variant="outlined"
                required
                placeholder={"N/A"}
                disabled={true}
                value={selectedRequests?.EstimatedCost ? selectedRequests?.EstimatedCost : "N/A"}
                type="text"
                onChange={(event) => {
                }}
                className={styles.textStyle}
              />
            </div>
          </>
        ) : null}

        {selectedRequests?.WorkStatus === ServiceStatus.PENDING_APPROVAL  || selectedRequests?.WorkStatus === ServiceStatus.NEW ? (
          <>
            <div>
              <div className={styles.label}>Ticket Updates</div>
              <TextField
                color="primary"
                variant="outlined"
                required
                placeholder={
                  "N/A"
                }
                disabled={true}
                value={selectedRequests?.Description ? selectedRequests?.Description : "N/A"}
                type="text"
                onChange={(event) => {
                }}
                className={styles.textStyle}
              />
            </div>
          </>
        ) : null}
        {selectedRequests?.WorkStatus === ServiceStatus.COMPLETED_PENDING_PAYMENT ? (
          <>
            <div className={styles.label}>Ticket Updates</div>
            <div className={styles.card}>
              <div className={styles.cardText}>
                <div>
                  <li> Service pro authentication success;</li>{" "}
                </div>
                <div>
                  <li> John says: “need to replace the tap”;</li>
                </div>
                <div>
                  <li> John updated Job Estimate - 600$;</li>
                </div>
                <div>
                  {" "}
                  <li>
                    Landlord Approved the estimate with Comment - “Please Upload
                    the receipt”;
                  </li>{" "}
                </div>
                <div>
                  <li>
                    John says - “Handyman replaced the Tap, it works okay now. I
                    have uploaded the receipt and I am closing the ticket”.
                  </li>
                </div>
              </div>
            </div>
          </>
        ) : null}
        {selectedRequests?.WorkStatus === ServiceStatus.COMPLETED_PENDING_PAYMENT  || selectedRequests?.WorkStatus === ServiceStatus.NEW ? (
          <>
            <div>
              <div className={styles.label}>Post a Ticket Update</div>
              <TextField
                color="primary"
                variant="outlined"
                required
                placeholder={"Type here"}
                type="email"
                onChange={(event) => {
                }}
                className={styles.textStyle}
              />
            </div>
          </>
        ) : null}

        {selectedRequests?.WorkStatus === ServiceStatus.PENDING_APPROVAL ? (
          <>
            <Button
              className={styles.signInButtonYellow}
              style={{ height: 50 }}
              onClick={() => {}}
              disabled={disabledButtons}
            >
              <Typography
                className={
                  disabledButtons
                    ? styles.exitButtonTypoDisabledBlue
                    : styles.exitButtonTypoBlue
                }
              >
                View media
              </Typography>
            </Button>
          </>
        ) : null}

        {selectedRequests?.WorkStatus === ServiceStatus.PENDING_APPROVAL ? (
          <>
            <div>
              <div className={styles.label}>Comment</div>
              <TextField
                color="primary"
                variant="outlined"
                required
                placeholder={"Type a comment"}
                type="email"
                onChange={(event) => {
                }}
                className={styles.textStyle}
              />
            </div>
          </>
        ) : null}

        {selectedRequests?.WorkStatus === ServiceStatus.COMPLETED_PENDING_PAYMENT  || selectedRequests?.WorkStatus === ServiceStatus.NEW ? (
          <>
            <Button
              className={styles.signInButtonColorInvert}
              style={{ marginLeft: 16, marginRight: 16, width: "92%" }}
              variant="contained"
              onClick={handleDocumentUpload}
            >
              <InputAdornment position="end">
                <span style={{ cursor: "pointer" }}>
                  <IconButton sx={{ color: "#D1D0D0" }}>
                    <img src={uploadpng} alt={""} />
                  </IconButton>
                </span>
              </InputAdornment>
              <Typography
                sx={{
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  fontWeight: 700,
                  fontSize: "14px",
                  letterSpacing: "0.2px",
                }}
              >
                Upload a photo/video
              </Typography>
              <input
                type="file"
                accept=".pdf"
                ref={(refParam) => (uploadedDocument = refParam)}
                value={uploadedDocument}
                onChange={async (e: ChangeEvent<HTMLInputElement>) => {
                  if (e.target.files) {
                    setDocUploaded(true);
                    setDocName(e.target.files[0].name);
                  }
                }}
                hidden
              />
            </Button>
          </>
        ) : null}
        {selectedRequests?.WorkStatus === ServiceStatus.COMPLETED_PENDING_PAYMENT  || selectedRequests?.WorkStatus === ServiceStatus.NEW ? (
          <>
            <Button
              className={styles.signInButtonYellow}
              style={{ height: 50 }}
              onClick={() => {}}
              disabled={disabledButtons}
            >
              <Typography
                className={
                  disabledButtons
                    ? styles.exitButtonTypoDisabledBlue
                    : styles.exitButtonTypoBlue
                }
              >
                View media
              </Typography>
            </Button>
          </>
        ) : null}
        {selectedRequests?.WorkStatus === ServiceStatus.COMPLETED_PENDING_PAYMENT ? (
          <>
            <Button
              className={styles.signInButton}
              onClick={() => {}}
              style={{ marginTop: 24 }}
              disabled={disabledButtons}
            >
              <Typography
                className={
                  disabledButtons
                    ? styles.exitButtonTypoDisabled
                    : styles.exitButtonTypo
                }
              >
                Submit{" "}
              </Typography>
            </Button>
          </>
        ) : null}

        {selectedRequests?.WorkStatus === ServiceStatus.PENDING_APPROVAL ? (
          <>
            <Button
              className={styles.signInButton}
              onClick={() => {
                setApprovePopup(true);
              }}
              style={{ marginTop: 24 }}
              disabled={disabledButtons}
            >
              <Typography
                className={
                  disabledButtons
                    ? styles.exitButtonTypoDisabled
                    : styles.exitButtonTypo
                }
              >
                Approve
              </Typography>
            </Button>

            <Button
              className={styles.outlinebtn}
              variant="outlined"
              onClick={() => {
                setRejectPopup(true);
              }}
              style={{ marginTop: 24 }}
              disabled={disabledButtons}
            >
              Reject
            </Button>
          </>
        ) : null}
      </div>
    </>
  );
};

export default ServiceTickets;
