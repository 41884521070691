import { FormError } from "app/containers/types";
import { CreateAccountSRE } from "../types";
import { emailValidator, passwordValidator } from "utils/helpers";

export function valdiator(form: CreateAccountSRE): Array<FormError> {
  const errors: Array<FormError> = [];
  if (!form.email.value) {
    errors.push({
      name: "email",
      error: "Email is required",
    });
  }
  if (form.email.value && !emailValidator(form.email.value)) {
    errors.push({
      name: "email",
      error: "Please enter valid email",
    });
  }
  if (!form.password.value) {
    errors.push({
      name: "password",
      error: "Password is required",
    });
  }

  if (form.password.value && !passwordValidator(form.password.value)) {
    errors.push({
      name: "password",
      error: "Password should contain at least 1 uppercase, 1 lowercase, 1 number and 1 symbol",
    });
  }

  if (form.password.value && !form.confirmPassword.value) {
    errors.push({
      name: "confirmPassword",
      error: "Confirm password is required",
    });
  }

  if (form.password.value && form.confirmPassword.value && !passwordValidator(form.confirmPassword.value) || (form.password.value && form.confirmPassword.value && form.confirmPassword.value != form.password.value)) {
    errors.push({
      name: "confirmPassword",
      error: "Both passwords should match.",
    });
  }
  
  return errors;
}
