import { Button } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "utils/hooks/useWindowDimensions";
import { actions } from "../slice";
import { useStyles } from "./styles";

const WelcomeScreen = () => {
  const { width, height } = useWindowDimensions();
  const styles = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const goToAuth = () => {
    dispatch(actions.resetLogin());
    navigate("/auth");
  };
  return (
    <div className={styles.container}>
      <img
        src={require("../../../assets/house.png")}
        alt=""
        style={{ width: width, height: height / 2 }}
      />
      <div
        style={{
          width: width,
          top: height / 2.15,
        }}
        className={styles.sheet}
      >
        <img
          src={require("../../../assets/logo.png")}
          alt=""
          className={styles.logo}
        />
        <div className={styles.title}>Welcome to SRE Management</div>
        <div className={styles.subtitle}>Let’s set in to continue</div>
        <Button
          type="submit"
          variant="contained"
          className={styles.signInButton}
          onClick={goToAuth}
        >
          Sign in with Email
        </Button>
        <div className={styles.contentTxt}>Or continue with</div>
        <div className={styles.imgView}>
          <img
            src={require("../../../assets/google.png")}
            alt=""
            style={{ marginRight: 16 }}
            className={styles.icon}
          />
          <img
            src={require("../../../assets/ios.png")}
            alt=""
            className={styles.icon}
          />
        </div>
        <div className={styles.btmTxt}>
          Don’t you have an account?
          <span
            className={styles.span}
            onClick={() => {
              dispatch(actions.resetModule());
              navigate("/create-account");
            }}
          >
            {" "}
            Sign Up
          </span>
        </div>
      </div>
    </div>
  );
};

export default WelcomeScreen;
