import { makeStyles } from "@mui/styles";
import HeaderImage from "../../assets/header-image.png";

export const useStyles = makeStyles({
  headerContainer: {
    // position: "fixed",
    // top: 0,
    // zIndex: 5,
    // backgroundImage: `url(${HeaderImage})`,
    // backgroundSize: "cover",
    // backgroundPosition: "left",
    // minHeight: 169,
    // borderBottomLeftRadius: 20,
    // borderBottomRightRadius: 20,
    width: "100%",
    backgroundColor:'#FFF7DD',
    justifyContent:'center',
    display:'flex',
    alignItems:'center'
    
  },
  heading: {
    fontWeight: 800,
    lineHeight: "28px",
    fontSize: 24,
    color: "#FAA61A",
  },
  subHeading: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: "16px",
    color: "#FFF",
  },
  icon:{
    width:227,
    height:121,

  },
  backArrrow:{
    width:16,
    height:16,
    position:"absolute",
    top:20,
    left:16
  }
});
