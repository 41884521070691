import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
    sheet: {
        display: "flex",
          flex: 1,
          backgroundColor: "#ffffff",
          position: "absolute",
          borderTopLeftRadius: 30,
          borderTopRightRadius: 30,
          flexDirection: "column",
          paddingBottom:20
        //   paddingLeft:16,
        // paddinRight:16  
    },
    title:{
        fontSize: 18,
        fontWeight: "600",
        color: "#192342",
        alignSelf: "center",
        marginTop:20,
        fontFamily:"Open Sans",
        marginBottom:6    
      },
      title1: {
        fontSize: 16,
        fontWeight: "600",
        color: "#192342",
        alignSelf: "center",
        marginTop:20,
        fontFamily:"Open Sans",
        marginBottom:6 
      },
      subTitle:{
        fontSize: 14,
        fontFamily:"Open Sans",
        color: "#192342",
        fontWeight: "400",
        marginTop: 16,
        alignSelf: "center",
        textAlign:'center',
        marginBottom: 10,
        marginLeft:36,
        marginRight:36
      },
    inputs: {
      width: "100%",
      color: "#7D7D7D",
      font: 'inherit',
      borderRadius: "20px",
      border: "unset",
      margin: "5px 0px 0px 0px",
      "&:focus": {
        outline: "none",
      },
  
      "&:disabled": {
        background: "#E0E0E0",
        color: "#7D7D7D",
      },
    }, 
    leftSide:{
        color:'#7D7D7D',
        paddingLeft:10,
        paddingRight:10,
        fontSize:18,
        fontWeight: '600',
        borderRadius:6,
        paddingTop:2,
        paddingBottom:2
        },checkBoxView:{
            flexDirection:'row',
            display:'flex',
            flex:1,
            marginBottom:10,
            // backgroundColor:"red",
            paddingLeft:15
            // marginleft:29,
            
        },
        checkBoxTitle:{
            alignSelf:'center',
            fontSize:14
        }, btn:{
        fontSize: "14px",
            fontWeight: 700,
            alignSelf: "center",
            padding: "12px 0",
            borderRadius: "10px",
            backgroundColor: "#192342",
            margin: "30px 0px",
            color: "#FFFFFF",
      },footerTxt:{
        fontSize:12,
        fontWeight:400,
        fontFamily:"Open Sans",
        textAlign:'center',
        color:'#192342'
      },
      footTxt:{
        fontSize:12,
        fontWeight:600,
        fontFamily:"Open Sans",
        textAlign:'center',
        color:'#006CFD',

      },spanTitle:{
        fontWeight:'600'
      },
      disabledInputSpan: {
        width: "100%",
        marginBottom: "20px",
        alignItems: "center",
        border: 'none !important',
      },
      pageTypoThresholdDisabled: {
        textAlign: "left",
        height: "fit-content",
        fontFamily: "Open Sans",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "14px",
        lineHeight: "19px",
        letterSpacing: "0.2px",
        margin: "0 0 0 3%",
        color: "#192342",
      },
})