/**
 * Homepage selectors
 */

import { RootState } from "types";

import { createSelector } from "@reduxjs/toolkit";

import { initialAuthState as initialState } from "./types";
import { stat } from "fs";

// TODO: Add an explanation for this
const selectDomain = (state: RootState) => state.authState;

export const selectAuth = createSelector(
  [selectDomain],
  (authState) => authState
);
export const selectCreateAccountForm = createSelector(
  [selectDomain],
  (authState) => authState.form
);

export const selectLoginForm = createSelector(
  [selectDomain],
  (state) => state.loginForm
);
export const selectType = createSelector([selectDomain], (state) => state.type);

export const selectToken = createSelector(
  [selectDomain],
  (state) => state.token
);

export const selectLoading = createSelector(
  [selectDomain],
  (authState) => authState.loading
);

export const selectOtp = createSelector([selectDomain], (state) => state.otp);

export const selectOtpError = createSelector([selectDomain], (state) => state.otp.error);

export const selectError = createSelector(
  [selectDomain],
  (authState) => authState.error
);

export const selectErrorMessage = createSelector(
  [selectDomain],
  (state) => state.error
);
export const selectInvalidAttempts = createSelector(
  [selectDomain],
  (state) => state.invalidAttempts
);

export const selectCreateAccountSRE = createSelector(
  [selectDomain],
  (state) => state.createAccount
);

export const selectCreateAccountSREError = createSelector(
  [selectDomain],
  (state) => state.error
);
export const selectVerificationSRE = createSelector(
  [selectDomain],
  (state) => state.verification
);

export const userSelectedTypes = createSelector(
  [selectDomain],
  (state) => state.userType
);

export const selectUser = createSelector([selectDomain], (state) => state.user);

export const selectedProperty = createSelector(
  [selectDomain],
  (state) => state.selectedProperty
);

export const getPropertyAddressList = createSelector(
  [selectDomain],
  (state) => state.propertyList
);

export const getLandlordData = createSelector(
  [selectDomain],
  (state) => state.landLordData
);

export const selectCompany = createSelector(
  [selectDomain],
  (state) => state.company
);

export const addPropertyFormData = createSelector(
  [selectDomain],
  (state) => state.addProperty
);

export const addLeaseData = createSelector(
  [selectDomain],
  (state) => state.addLease
);

export const addedProperty = createSelector(
  [selectDomain],
  (state) => state.addedProperty
);
export const selectUploadedImage = createSelector(
  [selectDomain],
  (state) => state.uploadedImageFile
);
export const selectUploadedDocument = createSelector(
  [selectDomain],
  (state) => state.uploadedDocFile
);
export const selectUpoadedDocURL = createSelector(
  [selectDomain],
  (state) => state.uploadedDocumentURL
);
export const selectUploadedImageURL = createSelector(
  [selectDomain],
  (state) => state.uploadedImageURL
);
