import {
  Button,
  Typography,
  IconButton,
  InputAdornment,
  TextField,
  Box,
  Grid,
  Dialog,
  Select,
  MenuItem,
  Radio,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import { actions, reducer, sliceKey } from "../../../redux/slice";
import { useLandlordSaga } from "../../../redux/saga";
import { style } from "@mui/system";
import { useStyles } from "../../styles";
import { useNavigate } from "react-router-dom";

type Props = {};
const Logout = ({ showPopup, closePopup }) => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: useLandlordSaga });

  const styles = useStyles();
  const navigation = useNavigate();
  const [show, setShow] = useState(false);
  const close = () => {
    setShow(false);
    closePopup();
  };

  useEffect(() => {
    setShow(showPopup);
  }, [showPopup]);

  return (
    <Dialog open={show} className={styles.popup}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          margin: "16px",
        }}
      >
        <Typography
          className={styles.modalSubTitle}
          style={{ marginTop: 30, marginBottom: 30 }}
        >
          Do you really want to log out?
        </Typography>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: 30,
          }}
        >
          <Button
            // disabled={reportType ? false : true}
            className={styles.signInButton}
            onClick={() => {
              navigation("/welcomeScreen");
            }}
          >
            Yes
          </Button>
          <Button
            variant="outlined"
            className={styles.outlinebtn}
            onClick={close}
          >
            No
          </Button>
        </div>
      </Box>
    </Dialog>
  );
};

export default Logout;
