export enum  ServiceType {
    AC,  //0
    ELECTRICAL, //1
    PLUMBLING, //2
    HANDYMAN, //3
    LOCKSMITH,  //4
    STRUCTURE_SERVICE //5
}

export enum  ServiceStatus {
    NEW,  //0
    SELECT_TIME_FRAME, //1
    SCHEDULED_PENDING_PAYMENT, //2
    PENDING_APPROVAL, //3
    INPROGRESS,  //4
    COMPLETED_PENDING_PAYMENT, //5
    COMPLETED, //6
    REOPEN, //7
    DISCARD, //8
}