import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  sheet: {
    display: "flex",
    flex: 1,
    backgroundColor: "#ffffff",
    position: "absolute",
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    flexDirection: "column",
  },
  logo: {
    height: 121,
    width: 227,
    marginTop: 24,
    alignSelf: "center",
  },
  logoContainer: {
    display: "flex",
    justifyContent: "center",
  },
  title: {
    fontSize: 20,
    fontWeight: "700",
    color: "#F0BB00",
    alignSelf: "center",
  },
  subtitle: {
    fontSize: 18,
    color: "#192342",
    fontWeight: "600",
    marginTop: 16,
    alignSelf: "center",
    marginBottom: 10,
  },
  btn: {
    fontSize: "14px",
    fontWeight: 700,
    alignSelf: "center",
    padding: "12px 0",
    borderRadius: "10px",
    backgroundColor: "#192342",
    margin: "30px 0px",
    color: "#FFFFFF",
  },
  contentTxt: {
    marginTop: 10,
    fontSize: 14,
    fontWeight: "400",
    alignSelf: "center",
    color: "#192342",
  },
  imgView: {
    flexDirection: "row",
    flex: 1,
    justifyContent: "center",
    display: "flex",
    marginTop: 16,
  },
  icon: {
    height: 36,
    width: 36,
  },
  btmTxt: {
    fontSize: 16,
    fontWeight: "400",
    alignSelf: "center",
    marginTop: 24,
    // marginBottom: 40,
  },
  span: {
    color: "#F0BB00",
    fontWeight: "700",
  },
  signInButton: {
    "&:disabled": {
      background: "#C4C4C4",
      color : "#6B6B6B",
    },
    display: "flex",
    flexDirection: "row",
    width: "95%",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 16px",
    gap: "10px",
    height: "50px",
    background: "#192342",
    color: "#ffffff",
    borderRadius: "8px",
    marginTop: 41,
    "&:hover": {
      background: "#192342",
      color: "#ffffff",
    },
  },

  inputs: {
    padding: "10px 16px",
    width: "100%",
    height: "48px",
    background: "#FFF7DD",
    color: "#7D7D7D",
    borderRadius: "20px",
    border: "unset",
    margin: "5px",
    "&:focus": {
      outline: "none",
    },
  },
  errorInputSpan: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    padding: "10px 16px 10px 0px",
    width: "100%",
    height: "48px",
    background: "#FFF7DD",
    color: "#7D7D7D",
    borderRadius: "20px",
    border: "unset",
    margin: "5px",
    alignItems: "center",
    outline: "#EB5757 auto",
  },
  errorTypo: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "19px",
    alignSelf: "flex-start",
    color: "#EB5757",
    marginLeft: "20px",
    padding: "5px",
  },
  inputSpan: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    padding: "10px 16px 10px 0px",
    width: "100%",
    height: "48px",
    background: "#FFF7DD",
    color: "#7D7D7D",
    borderRadius: "20px",
    border: "unset",
    margin: "5px",
    alignItems: "center",
  },
  setBox:{
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
  },
  pageHeading: {
    margin: "24px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: "25px",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "140%",
    letterSpacing: "0.2px",
    color: "#192342",
    textAlign: "center",
  },
  centerContainer: {
    width: "100%",
    height: "12%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 0,
    margin: "0px 46px 0px 46px",
  },
  pageTypo1: {
    marginBottom: "6px",
    textAlign: "center",
    height: "22px",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "140%",
    letterSpacing: "0.2px",
    color: "#192342",
  },
  btone: {
    width: "66px",
    height: "22px",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "16px",
    textAlign: "center",
    letterSpacing: "0.1px",
    color: "#F0BB00",
  },
  btoneChange: {
    width: "66px",
    height: "22px",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "16px",
    textAlign: "center",
    letterSpacing: "0.1px",
    color: "#FFF7DD",
  },
  bttwo: {
    width: "164px",
    height: "41px",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    textAlign: "center",
    letterSpacing: "0.1px",
    color: "#192342",
  },

  buttonSwitch: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "187px",
    height: "114px",
    border: "0.5px solid #BCBCBC",
    boxShadow:
      "8px 11px 5px rgba(181, 181, 181, 0.01), 4px 6px 5px rgba(181, 181, 181, 0.05), 2px 3px 3px rgba(181, 181, 181, 0.09), 0px 1px 2px rgba(181, 181, 181, 0.1), 0px 0px 0px rgba(181, 181, 181, 0.1)",
    borderRadius: "20px",
    "&hover": {
      background: "white",
    },
  },
  buttonSelected: {
    display: "flex",
    background: "#F0BB00",
    flexDirection: "column",
    alignItems: "center",
    width: "187px",
    height: "114px",
    border: "0.5px solid #BCBCBC",
    boxShadow:
      "8px 11px 5px rgba(181, 181, 181, 0.01), 4px 6px 5px rgba(181, 181, 181, 0.05), 2px 3px 3px rgba(181, 181, 181, 0.09), 0px 1px 2px rgba(181, 181, 181, 0.1), 0px 0px 0px rgba(181, 181, 181, 0.1)",
    borderRadius: "20px",
    "&hover": {
      background: "#F0BB00",
    },
  },
  pageTypo2: {
    textAlign: "center",
    height: "fit-content",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "140%",
    letterSpacing: "0.2px",
    marginBottom: "5%",
    color: "#192342",
  },
  checkbox: {
    marginRight: "6px",
    "&:checked": {
      accentColor: "#192342",
    },
  },
  checkboxTypos: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    color: "#192342",
    display: "contents",
    marginLeft: "6px",
  },
  linkForgot: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    color: "#006CFD",
  },
  upperContainer: {
    height: "32%",
  },
});

export default useStyles;
