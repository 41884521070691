export interface ResponseError {
  message: string;
}
export type RequestError = ResponseError;

export type ServiceRequestType = {
  serviceRequestId: string;
  serviceProviderId: string;
  from: string;
  to: string;
  serviceDate: Date;
};

export type ServiceRequestStateType = {
  list: {
    items: Array<ServiceRequestType>;
  };
  loading: boolean;
};

export const ServiceRequestState: ServiceRequestStateType = {
  list: {
    items: [],
  },
  loading: false,
};

export type ContainerState = ServiceRequestStateType;
