/**
 * Homepage selectors
 */

 import { RootState } from "types";

 import { createSelector } from "@reduxjs/toolkit";
import { UploadDocumentState } from "./types";
 
 const selectDomain = (state: RootState) => state.uploadDocumentState || UploadDocumentState;

export const selectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);

export const selectError = createSelector(
  [selectDomain],
  (state) => state.error
);
export const selectDocument = createSelector(
  [selectDomain],
  (state) => state.uploadDocument
);


 
 