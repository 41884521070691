import React, { useEffect, useTransition } from "react";
import { useInjectReducer } from "redux-injectors";
import { prefixer } from "stylis";
import rtlPlugin from "stylis-plugin-rtl";
import rtl from "jss-rtl";
import createCache from "@emotion/cache";
import { CacheProvider } from "@emotion/react";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import { create } from "jss";
import { StylesProvider, jssPreset } from "@mui/styles";
import MuiTheme from "./mui";
import {
  reducer,
  selectDirection,
  themeSliceKey,
  toggleDirection,
} from "./slice";
import { useSelector } from "react-redux";
import { LoggerClass as Logger } from "utils/logger";
import { useDispatch } from "react-redux";

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const cacheRtl = createCache({
  key: "muirtl",
  stylisPlugins: [prefixer, rtlPlugin],
});
export const ThemeProvider = (props) => {
  
  useInjectReducer({ key: themeSliceKey, reducer: reducer });
  const searchParams = new URL(window.location.href).searchParams;
//   const language = searchParams.get("hl");
  const dispatch = useDispatch();

  const themeDirection = useSelector(selectDirection);
  Logger.log({ themeDirection });

  return (
    <StylesProvider jss={jss}>
      <CacheProvider value={cacheRtl}>
        <MuiThemeProvider
          theme={{ ...MuiTheme,}}
        >
          {props.children}
        </MuiThemeProvider>
      </CacheProvider>
    </StylesProvider>
  );
};
