import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import stepOneImage from "../../assets/step1.png";
import acImage from "../../assets/acService.png";
import electricalImage from "../../assets/electricService.png";
import locksmithImage from "../../assets/locksmithService.png";
import maintainImage from "../../assets/maintainanceService.png";
import structureImage from "../../assets/structureService.png";
import waterServiceImage from "../../assets/waterService.png";
import useStyle from "../../styles";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "app/containers/Tenant/redux/slice";
import { ServiceType } from "app/containers/LandlordAccount/redux/Landlord.enum";
import { selectUser } from "app/containers/Auth/selectors";
import { selectDashboardData } from "app/containers/Tenant/redux/selector";

const StepOneComponent = ({ selectedService, inputTypes }) => {
  const styles = useStyle();
  const [selected, setSelected] = useState(inputTypes);
  const dispatch = useDispatch();
  const loggedInUser = useSelector(selectUser);
  const dashboardData = useSelector(selectDashboardData); 

  const userId = loggedInUser?.userId ? loggedInUser?.userId : JSON.parse(localStorage.getItem('loginResponse')!).userId;
  const propertyId = dashboardData?.PropertyId ? dashboardData?.PropertyId : localStorage.getItem('property');
  const propertyAddress = dashboardData?.Address ? dashboardData?.Address : localStorage.getItem('propertyAddress');

  
  useEffect(() => {
    dispatch(actions.ServiceRequestData({ name: 'OpenedBy', value : userId+"", form: "addService" }));
    dispatch(actions.ServiceRequestData({ name: 'PropertyId', value : propertyId+"", form: "addService" }));
    dispatch(actions.ServiceRequestData({ name: 'PropertyAddress', value : propertyAddress+"", form: "addService" }));
  }, [])
  useEffect(() => {
     if (selected >= 0 && selected !== "") {
      selectedService({ status: false, serviceType: selected });
    } else {      
      selectedService({ status: true, serviceType: selected });
    }    
    dispatch(actions.ServiceRequestTypeData(selected));
  }, [selected]);
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "24px",
        }}
      >
        <img
          src={stepOneImage}
          style={{ width: "80px", height: "80px" }}
          alt="Step 1"
        />
      </Box>
      <Typography
        sx={{ marginBottom: "24px" }}
        className={styles.typo60018AlignCenter}
      >
        Select the category of the request
      </Typography>
      <Box
        sx={{ display: "flex", flexDirection: "column", marginBottom: "24px" }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
            marginBottom: "19px",
          }}
        >
          <Box
            className={
              selected === ServiceType.AC
                ? styles.selectedServiceType
                : styles.serviceTypes
            }
            onClick={() => {
              if (selected === ServiceType.AC) {
                setSelected("");
              } else {
                setSelected(ServiceType.AC);
              }
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={acImage} alt="AC Image" />
            </Box>
            <Typography className={styles.typo40016}>A/C</Typography>
          </Box>
          <Box
            className={
              selected === ServiceType.ELECTRICAL
                ? styles.selectedServiceType
                : styles.serviceTypes
            }
            onClick={() => {
              if (selected === ServiceType.ELECTRICAL) {
                setSelected("");
              } else {
                setSelected(ServiceType.ELECTRICAL);
              }
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={electricalImage} alt="Electrical Image" />
            </Box>
            <Typography className={styles.typo40016}>Electrical</Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
            marginBottom: "19px",
          }}
        >
          <Box
            className={
              selected === ServiceType.PLUMBLING
                ? styles.selectedServiceType
                : styles.serviceTypes
            }
            onClick={() => {
              if (selected === ServiceType.PLUMBLING) {
                setSelected("");
              } else {
                setSelected(ServiceType.PLUMBLING);
              }
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={waterServiceImage} alt="Plumbing Image" />
            </Box>
            <Typography className={styles.typo40016}>Plumbing</Typography>
          </Box>
          <Box
            className={
              selected === ServiceType.HANDYMAN
                ? styles.selectedServiceType
                : styles.serviceTypes
            }
            onClick={() => {
              if (selected === ServiceType.HANDYMAN) {
                setSelected("");
              } else {
                setSelected(ServiceType.HANDYMAN);
              }
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={maintainImage} alt="Handyman Image" />
            </Box>
            <Typography className={styles.typo40016}>Handyman</Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
            marginBottom: "19px",
          }}
        >
          <Box
            className={
              selected === ServiceType.LOCKSMITH
                ? styles.selectedServiceType
                : styles.serviceTypes
            }
            onClick={() => {
              if (selected === ServiceType.LOCKSMITH) {
                setSelected("");
              } else {
                setSelected(ServiceType.LOCKSMITH);
              }
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={locksmithImage} alt="LockSmith Image" />
            </Box>
            <Typography className={styles.typo40016}>LockSmith</Typography>
          </Box>
          <Box
            className={
              selected === ServiceType.STRUCTURE_SERVICE
                ? styles.selectedServiceType
                : styles.serviceTypes
            }
            onClick={() => {
              if (selected === ServiceType.STRUCTURE_SERVICE) {
                setSelected("");
              } else {
                setSelected(ServiceType.STRUCTURE_SERVICE);
              }
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <img src={structureImage} alt="Structure Services Image" />
            </Box>
            <Typography className={styles.typo40016}>
              Structure Services
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default StepOneComponent;

