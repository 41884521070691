import Header from "app/components/Header";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useStyles from "./styles";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import { useDispatch } from "react-redux";
import DeleteTenant from "app/containers/CommonComponent/Property/MyProperty/Components/DeleteTenant";
import DownloadAllDialog from "../DownloadAllDialog";

type Props = {};
const IncomingFunds = (props: Props) => {
  const styles = useStyles();
  const [index, setIndex] = useState(2);
  const navigation = useNavigate();
  const [downloadAllPopup, setDownloadAllPopup] = useState(false);

  const handleDownloadAll = (selectedCheck) => {
    setDownloadAllPopup(false);
  };

  const closePopup = () => {
    setDownloadAllPopup(false);
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.title}>Unpaid invoices</div>
        <div className={styles.monthBox}>
          <div className={styles.overDueButton}>
            <div className={styles.overDueText}>Failed</div>
          </div>
          <div className={styles.warningRow}>
            <img
              src={require("../../../../../../app/assets/warning.png")}
              className={styles.image}
              alt=""
            />
            <div style={{ paddingLeft: 6 }}>
              <div className={styles.monthRow}>
                <div className={styles.monthText}>August rental payment </div>
                <img
                  src={require("../../../../../../app/assets/rightArrow.png")}
                  className={styles.image}
                  alt=""
                />
              </div>
              <div className={styles.address}>
                3429 Adkins Rd. Nw Atlanta, GA 30331
              </div>
            </div>
          </div>

          <div className={styles.rectBox}>
            <div className={styles.rectBoxRow}>
              <div className={styles.invoiceAmount}>Invoice date</div>
              <div className={styles.date}>14/08/2022</div>
            </div>
          </div>

          <div className={styles.rectBox}>
            <div className={styles.rectBoxRow}>
              <div className={styles.invoiceAmount}>Invoice amount</div>
              <div className={styles.date}>1500$</div>
            </div>
          </div>

          <div className={styles.rectBox}>
            <div className={styles.rectBoxRow}>
              <div className={styles.invoiceAmount}>Payer</div>
              <div className={styles.date}>Michal Jackson</div>
            </div>
          </div>
       
          <div className={styles.rectBox}>
            <div className={styles.rectBoxRow}>
              <div className={styles.invoiceAmount}>Transaction ID</div>
              <div className={styles.date}>T000-12345</div>
            </div>
          </div>
          
        </div>

        <div className={styles.monthBox}>
          <div className={styles.unpaidButton}>
            <div className={styles.overDueText}>Pending</div>
          </div>
          <div className={styles.warningRow}>
            <img
              src={require("../../../../../../app/assets/exclaim.png")}
              className={styles.image}
              alt=""
            />
            <div style={{ paddingLeft: 6 }}>
              <div className={styles.monthRow}>
                <div className={styles.monthRental}>
                  September rental payment
                </div>
                <img
                  src={require("../../../../../../app/assets/rightArrow.png")}
                  className={styles.image}
                  alt=""
                />
              </div>
              <div className={styles.address}>
                3429 Adkins Rd. Nw Atlanta, GA 30331
              </div>
            </div>
          </div>

          <div className={styles.rectBox}>
            <div className={styles.rectBoxRow}>
              <div className={styles.invoiceAmount}>Invoice date</div>
              <div className={styles.date}>14/08/2022</div>
            </div>
          </div>

          <div className={styles.rectBox}>
            <div className={styles.rectBoxRow}>
              <div className={styles.invoiceAmount}>Invoice amount</div>
              <div className={styles.date}>1500$</div>
            </div>
          </div>

          <div className={styles.rectBox}>
            <div className={styles.rectBoxRow}>
              <div className={styles.invoiceAmount}>Payer</div>
              <div className={styles.date}>Michal Jackson</div>
            </div>
          </div>
       
          <div className={styles.rectBox}>
            <div className={styles.rectBoxRow}>
              <div className={styles.invoiceAmount}>Transaction ID</div>
              <div className={styles.date}>T000-12345</div>
            </div>
          </div>
        </div>

        <div className={styles.title1Row}>
          <div className={styles.title1}>All Transactions</div>
          <div className={styles.downloadRow}>
            <img
              src={require("../../../../../../app/assets/download.png")}
              className={styles.download}
              alt=""
            />
            <div className={styles.downloadReceipts} onClick={()=>{setDownloadAllPopup(true)}}>Download All</div>
          </div>
        </div>
        <div className={styles.box}>
          <div className={styles.monthDateRow}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <img
                src={require("../../../../../../app/assets/greenTick.png")}
                className={styles.image}
                alt=""
              />
              <div style={{ paddingLeft: 6 }}>
                <div className={styles.monthRow}>
                  <div className={styles.monthRental1}>July rental payment</div>

                  <img
                    src={require("../../../../../../app/assets/blackDownload.png")}
                    className={styles.image}
                    alt=""
                  />
                </div>
                <div className={styles.confirmRow}>
                  <div className={styles.confirmText}>Confirmation</div>
                  <div className={styles.confirmId}>#325884965</div>
                </div>
              </div>
            </div>

            <div className={styles.confirmId}>14/07/2022</div>
          </div>

          <div className={styles.rectBox}>
            <div className={styles.rectBoxRow}>
              <div className={styles.invoiceAmount}>Invoice date</div>
              <div className={styles.date}>14/08/2022</div>
            </div>
          </div>

          <div className={styles.rectBox}>
            <div className={styles.rectBoxRow}>
              <div className={styles.invoiceAmount}>Invoice amount</div>
              <div className={styles.date}>1500$</div>
            </div>
          </div>

          <div className={styles.rectBox}>
            <div className={styles.rectBoxRow}>
              <div className={styles.invoiceAmount}>Payer</div>
              <div className={styles.date}>Michal Jackson</div>
            </div>
          </div>
       
          <div className={styles.rectBox}>
            <div className={styles.rectBoxRow}>
              <div className={styles.invoiceAmount}>Transaction ID</div>
              <div className={styles.date}>T000-12345</div>
            </div>
          </div>
        </div>

        <div className={styles.monthBox}>
          <div className={styles.overDueButton}>
            <div className={styles.overDueText}>Failed</div>
          </div>
          <div className={styles.warningRow}>
            <img
              src={require("../../../../../../app/assets/warning.png")}
              className={styles.image}
              alt=""
            />
            <div style={{ paddingLeft: 6 }}>
              <div className={styles.monthRow}>
                <div className={styles.monthText}>August rental payment </div>
                <img
                  src={require("../../../../../../app/assets/rightArrow.png")}
                  className={styles.image}
                  alt=""
                />
              </div>
              <div className={styles.address}>
                3429 Adkins Rd. Nw Atlanta, GA 30331
              </div>
            </div>
          </div>

          <div className={styles.rectBox}>
            <div className={styles.rectBoxRow}>
              <div className={styles.invoiceAmount}>Invoice date</div>
              <div className={styles.date}>14/08/2022</div>
            </div>
          </div>

          <div className={styles.rectBox}>
            <div className={styles.rectBoxRow}>
              <div className={styles.invoiceAmount}>Invoice amount</div>
              <div className={styles.date}>1500$</div>
            </div>
          </div>

          <div className={styles.rectBox}>
            <div className={styles.rectBoxRow}>
              <div className={styles.invoiceAmount}>Payer</div>
              <div className={styles.date}>Michal Jackson</div>
            </div>
          </div>
       
          <div className={styles.rectBox}>
            <div className={styles.rectBoxRow}>
              <div className={styles.invoiceAmount}>Transaction ID</div>
              <div className={styles.date}>T000-12345</div>
            </div>
          </div>
          
        </div>

      </div>
      <DownloadAllDialog
        showPopup={downloadAllPopup}
        closePopup={closePopup}
        handleSelect={(selectedCheck: any) => {
          handleDownloadAll(selectedCheck);
        }}
      />
    </>
  );
};
export default IncomingFunds;
