import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import Header from "app/components/Header";
import AddAndShowOwner from "app/containers/Auth/CreateBusinessAccount/BusinessAccount/AddAndShowOwner";
import { BusinessType } from "app/containers/Auth/CreateBusinessAccount/redux/enum";
import FundingsSource from "app/containers/Auth/FundingsSource";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import useWindowDimensions from "utils/hooks/useWindowDimensions";
import { actions } from "../redux/slice";
import LandlordProileDetails from "./Components/Details";
import { useStyles } from "./styles";
import { selectBusinessDetails, selectError } from "./redux/selectors";

const LandlordProfile = () => {
  const { width, height } = useWindowDimensions();
  const { state }: any = useLocation();
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const styles = useStyles();
  const [value, setValue] = React.useState('1');
  const [showOwner, setShowOwner] = React.useState(false);
 const error = useSelector(selectError);
 const businessDetails = useSelector(selectBusinessDetails);

  useEffect(() => {
    if (error === "Unauthorized") {
      navigation("/auth");
    }
  }, [error]);

  useEffect(() => {    
      if((!businessDetails.IsIndividual && (Number(businessDetails.BusinessType) === BusinessType.LLCS || Number(businessDetails.BusinessType) === BusinessType.CORPORATION || Number(businessDetails.BusinessType) === BusinessType.NON_PROFITS || Number(businessDetails.BusinessType) === BusinessType.PARTNERSHIP || Number(businessDetails.BusinessType) === BusinessType.PUBLICLY_TRADED_CORPORATIONS))){
        setShowOwner(true);
      } else 
      {
        setShowOwner(false);
      }
  }, [businessDetails]);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  
  useEffect(() => {
    dispatch(actions.setShowBottomNavBar({ status: false, index: 0 }));
  });
  
  const goBack = () => {
    navigation('/landlord-account');
  };
  return (
    <div>
      <div
        style={{
          flexDirection: "row",
          display: "flex",
          marginTop: 25,
          marginBottom: 25,
          marginLeft: 16,
          marginRight: 15,
          paddingRight: 15,
          paddingLeft: 15,
          borderRadius: 16,
          justifyContent: "center",
        }}
      >
        <img
          src={require("../../../assets/backArrow.png")}
          alt=""
          style={{
            height: 16,
            width: 16,
            position: "absolute",
            zIndex: 3,
            left: 16,
          }}
          onClick={goBack}
        />
        <div
          style={{ fontFamily: "Open Sans", fontSize: 18, fontWeight: "600" }}
        >
          Account
        </div>
      </div>

      <Header
        imageId={state}
        title="ID: 100035642"
        rightIcon={false}
        handleGallery={() => {
          navigation("/landlord-account/gallery");
        }}
      />   

<Box className={styles.tabSection}>
      <TabContext value={value}>
        <Box sx={{ background: '#192342', borderRadius : '10px' }}>
          <TabList onChange={handleChangeTab} className={styles.tabList} aria-label="lab API tabs example">
            <Tab className={styles.tabButton} label="Details" value="1"  sx={{width: '33%'}}/>
            {showOwner && <Tab className={styles.tabButton} label="Owners" value="2" sx={{width: '33%'}}/> }
            <Tab className={styles.tabButton} label="Fundings" value="3" sx={{width: '33%'}}/>
          </TabList>
        </Box>
        <TabPanel className={styles.tabPanel} value="1">
          <LandlordProileDetails/>
        </TabPanel>
        <TabPanel className={styles.tabPanel} sx={{marginTop : 2}} value="2">
          <AddAndShowOwner callingFrom={'Loggedin'}/>
        </TabPanel>
        <TabPanel className={styles.tabPanel} value="3">
          <FundingsSource callingFrom={'Loggedin'}/>
        </TabPanel>
      </TabContext>
    </Box>  

    </div>
  );
};

export default LandlordProfile;
