/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import { makeStyles } from "@mui/styles";
import { AuthPage } from "app/containers/Auth";
import { userRepoSaga } from "app/containers/Auth/saga";
import { usePropertySaga } from "app/containers/CommonComponent/Property/redux/saga";
import { useS3UploadSaga } from "app/containers/CommonComponent/S3UploadSaga/saga";
import { businessAccountSaga } from "app/containers/Auth/CreateBusinessAccount/redux/saga";
import { reducer as businessReducer, sliceKey as businessSlice, actions as businessActions} from "app/containers/Auth/CreateBusinessAccount/redux/slice";
import { reducer, sliceKey, actions } from "app/containers/Auth/slice";
import { reducer as propertyReducer, sliceKey as propertySlice, actions as propertyActions} from "app/containers/CommonComponent/Property/redux/slice";
import { reducer as s3UploadReducer, sliceKey as s3UploadSlice, actions as s3UploadActions} from "app/containers/CommonComponent/S3UploadSaga/slice";
import { fundingsSaga } from "app/containers/Auth/FundingsSource/redux/saga";
import { reducer as fundingsReducer, sliceKey as fundingsSlice, actions as fundingsActions} from "app/containers/Auth/FundingsSource/redux/slice";
import { uploadDocumentSaga } from "app/containers/Auth/UploadDocument/redux/saga";
import { reducer as uploadDocumentReducer, sliceKey as uploadDocumentSlice, actions as uploadDocumentActions} from "app/containers/Auth/UploadDocument/redux/slice";
import { landlordProfileSaga } from "app/containers/LandlordAccount/LandlordProfile/redux/saga";
import { reducer as landlordProfileReducer, sliceKey as landlordProfileSlice, actions as landlordProfileActions} from "app/containers/LandlordAccount/LandlordProfile/redux/slice";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useDispatch } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import useWindowDimensions from "utils/hooks/useWindowDimensions";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import ChangePaymentMethod from "./containers/Auth/ChangePaymentMethod";
import AccountType from "./containers/Auth/AccountType";
import CreateAccount from "./containers/Auth/CreateAccount";
import EmailVerification from "./containers/Auth/EmailVerification";
import ForgotPassword from "./containers/Auth/ForgotPassword";
import Login from "./containers/Auth/Login";
import OtpVerification from "./containers/Auth/OTPVerification";
import ResetPassword from "./containers/Auth/ResetPassword";
import ResetSuccessfull from "./containers/Auth/ResetPassword/SuccessPage";
import Splash from "./containers/Auth/Splash";
import Undermaintainance from "./containers/Auth/Undermaintainance";
import WelcomeScreen from "./containers/Auth/WelcomeScreen";
import { UnsupportedScreen } from "./containers/UnsupportedScreen";
import Verification from "./containers/Auth/Verification";
import Threshold from "./containers/Auth/Threshold";
import BusinessAccount from "./containers/Auth/CreateBusinessAccount/BusinessAccount";
import SucessfullVerification from "./containers/Auth/SucessfullVerification";
import PaymentMethodDetails from "./containers/Auth/ChangePaymentMethod/PaymentMethodDetails";
import AccountAddress from "./containers/Auth/AccountAddress";
import ZipCode from "./containers/Auth/ZipCode";
import NotListed from "./containers/Auth/NotListed";
import IDVerification from "./containers/Auth/IDVerification";
import IDVerificationRequiered from "./containers/Auth/IDVerification/idverificationReq";
import IDVerificationPending from "./containers/Auth/IDVerification/WaitingStage";
import IDVerificationFailed from "./containers/Auth/IDVerification/idverificationFailed";
import IDVerificationSuccess from "./containers/Auth/IDVerification/idverificationSuccess";
// import AddProperty from "./containers/Auth/AddProperty";
import ConfirmLandlord from "./containers/Auth/ConfirmLandlord";
import WelcomeLandlord from "./containers/Auth/WelcomeLandLord";
// import AddLease from "./containers/Auth/AddProperty/AddLease";
import AddCompany from "./containers/Auth/AddCompany";
import ChangeControl from "./containers/Auth/ChangeControl/ChangeControl";
import Portfolio from "./containers/CommonComponent/Property/Portfolio";
import Dashboard from "./containers/LandlordAccount/Dashboard";
import AddNewLease from "./containers/CommonComponent/Property/AddNewLease";
import MyProperty from "./containers/CommonComponent/Property/MyProperty";
import LandlordProfile from "./containers/LandlordAccount/LandlordProfile";
import ChangeMobileNumber from "./containers/LandlordAccount/ChangeMobileNumber";
import LandlordPage from "./containers/LandlordAccount";
import Gallery from "./containers/LandlordAccount/LandlordProfile/Components/gallery";
import TenantDeletedPropertyDialog from "./containers/CommonComponent/Property/MyProperty/Components/DialogsAdditional/TenantPopup";
import TenantDashboard from "./containers/Tenant/Dashboard";
import Maintanence from "./containers/LandlordAccount/Maintanence";
import TenantProfile from "./containers/Tenant/TenantProfile";
import Accounting from "./containers/Tenant/Accounting";
import Maintenance from "./containers/Tenant/Maintenance";
import TenantGallery from "./containers/Tenant/TenantProfile/Components/gallery";
//import AddNewServiceRequest from "./containers/Tenant/Maintenance/Components/AddNewServiceRequest";
import Contacts from "./containers/Tenant/Contacts";
import LandlordContacts from "./containers/LandlordAccount/Contacts";
import Messenger from "./containers/LandlordAccount/Contacts/Messenger";
import HandleServiceRequest from "./containers/ServiceProvider/containers/HandleServiceRequest";
import TenantPage from "./containers/Tenant";
import AddNewProperty from "./containers/CommonComponent/Property/AddProperty" 
import IndividualAccount from "./containers/Auth/CreateBusinessAccount/IndividualAccount";
import BusinessAccountController from "./containers/Auth/CreateBusinessAccount/BusinessAccount/Controller";
import BusinessAccountOwner from "./containers/Auth/CreateBusinessAccount/BusinessAccount/Owner";
import FundingsSource from "./containers/Auth/FundingsSource";
import UploadDocument from "./containers/Auth/UploadDocument";
import AddAndShowOwner from "./containers/Auth/CreateBusinessAccount/BusinessAccount/AddAndShowOwner";
import LandlordAccounting from "./containers/LandlordAccount/Accounting";
import PayMyRent from "./containers/Tenant/Accounting/components/PayMyRent";
const useStyles = makeStyles({
  appRoot: {
    width: "100%",
    height: "100%",
  },
});

export function App() {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: userRepoSaga });

  useInjectReducer({ key: propertySlice, reducer: propertyReducer });
  useInjectSaga({ key: propertySlice, saga: usePropertySaga });

  useInjectReducer({ key: s3UploadSlice, reducer: s3UploadReducer });
  useInjectSaga({ key: s3UploadSlice, saga: useS3UploadSaga });

  useInjectReducer({ key: businessSlice, reducer: businessReducer });
  useInjectSaga({ key: businessSlice, saga: businessAccountSaga });
  
  useInjectReducer({ key: fundingsSlice, reducer: fundingsReducer });
  useInjectSaga({ key: fundingsSlice, saga: fundingsSaga });

  useInjectReducer({ key: uploadDocumentSlice, reducer: uploadDocumentReducer });
  useInjectSaga({ key: uploadDocumentSlice, saga: uploadDocumentSaga });

  useInjectReducer({ key: landlordProfileSlice, reducer: landlordProfileReducer });
  useInjectSaga({ key: landlordProfileSlice, saga: landlordProfileSaga });

  const styles = useStyles();
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const searchParams = new URL(window.location.href).searchParams;
  const token = searchParams.get("authToken");
  useEffect(() => {
    if (token) {
      dispatch(actions.setToken(token));
    }
  }, [token]);
  return (
    <BrowserRouter>
      <Helmet titleTemplate="SRE Management" defaultTitle="SRE Management">
        <meta name="description" content="SRE Management" />
      </Helmet>
      <div className={styles.appRoot}>
        {width && width <= 850 ? (
          <>
            <Routes>
              <Route path="/*" element={<AuthPage />}>
                <Route path="otp" element={<OtpVerification />} />
                <Route path="auth" element={<Login />} />
                <Route index element={<Splash />} />
                <Route path="welcomeScreen" element={<WelcomeScreen />} />
                <Route path="forgot-password" element={<ForgotPassword />} />                
                <Route path="verification" element={<Verification />} />
                <Route path="address" element={<AccountAddress />} />
                <Route path="zip-code" element={<ZipCode />} />
                <Route path="address-notlisted" element={<NotListed />} />
                <Route path="confirm-landlord" element={<ConfirmLandlord />} />
                <Route path="welcome-landlord" element={<WelcomeLandlord />} />
                <Route path="add-company" element={<AddCompany />} />
                <Route path="landlord-account" element={<LandlordPage />}>
                  <Route index element={<Dashboard />} />
                  <Route path="add-new-lease" element={<AddNewLease />} />
                  <Route path="add-new-property" element={<AddNewProperty />} />
                  <Route path="portfolio" element={<Portfolio />} />
                  <Route path="maintanence" element={<Maintanence />} />
                  <Route path="my-property" element={<MyProperty />} />
                  <Route path="gallery" element={<Gallery />} />
                  <Route path="accounting" element={<LandlordAccounting />} />
                  <Route
                    path="landlord-contacts"
                    element={<LandlordContacts />}
                  />
                  <Route path="messenger" element={<Messenger />} />
                  <Route
                    path="landlord-profile"
                    element={<LandlordProfile />}
                  />
                  <Route path="change-mobile" element={<Verification />} />
                  <Route path="verify-otp" element={<OtpVerification />} />
                  <Route path="success-verification" element={<SucessfullVerification />} />
                </Route>
                <Route path="tenant-account" element={<TenantPage />}>
                  <Route index element={<TenantDashboard />} />
                  <Route path="tenant-profile" element={<TenantProfile />} />
                  <Route path="accounting" element={<Accounting />} />
                  <Route path="contacts" element={<Contacts />} />
                  <Route path="maintenance" element={<Maintenance />} />
                  <Route path="gallery" element={<TenantGallery />} />
                  <Route path="pay-my-rent" element={<PayMyRent />} />
                </Route>
                <Route path="service-provider">
                  <Route
                    path="handle-service-request"
                    element={<HandleServiceRequest />}
                  />
                </Route>
                <Route
                  path="success-verification"
                  element={<SucessfullVerification />}
                />
                <Route
                  path="email-verification"
                  element={<EmailVerification />}
                />
                <Route path="reset-password" element={<ResetPassword />} />
                <Route
                  path="reset-successfull"
                  element={<ResetSuccessfull />}
                />
              </Route>
              <Route
                path="/change-payment-method"
                element={<ChangePaymentMethod />}
              />
              <Route
                path="/payment-method-details"
                element={<PaymentMethodDetails />}
              />
              <Route path="/id-verification" element={<IDVerification />} />
              <Route
                path="/id-verification-required"
                element={<IDVerificationRequiered />}
              />
              <Route
                path="/id-verification-pending"
                element={<IDVerificationPending />}
              />
              <Route
                path="/id-verification-failed"
                element={<IDVerificationFailed />}
              />
              <Route
                path="/id-verification-success"
                element={<IDVerificationSuccess />}
              />
              {/* temporary routes or routes to be removed in future */}
              <Route path="work-in-progress" element={<Undermaintainance />} />
              <Route
                path="/tenant-property-disabled"
                element={<TenantDashboard disabledButtonsProp={true} />}
              />
              <Route
                path="/tenant-popups"
                element={<TenantDeletedPropertyDialog />}
              />
              <Route path="/add-property" element={<AddNewProperty />} />
              <Route path="/add-lease" element={<AddNewLease />} />
              <Route path="/change-control" element={<ChangeControl />} />
              <Route path="/create-account" element={<CreateAccount />} />
              <Route path="/account-type" element={<AccountType />} />
              <Route path="/add-threshold" element={<Threshold />} />
              <Route path="/business-account" element={<BusinessAccount/>} />
              <Route path="/individual-account" element={<IndividualAccount/>} />
              <Route path="/add-controller" element={<BusinessAccountController/>} />
              <Route path="/add-owner" element={<BusinessAccountOwner/>} /> 
              <Route path="/funding-source" element={<FundingsSource callingFrom={'notLoggedin'}/>} /> 
              <Route path="/upload-document" element={<UploadDocument/>} /> 
              <Route path="/owner" element={<AddAndShowOwner callingFrom={'notLoggedin'}/>} />        
            </Routes>
          </>
        ) : (
          <UnsupportedScreen />
        )}
      </div>
    </BrowserRouter>
  );
}
