import { selectToken, selectUser } from "app/containers/Auth/selectors";
import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { APP_API_URL } from "utils/constants";
import { fileRequest, request } from "utils/request";
import { actions } from "./slice";

export function* getDocumentUploadUrlRequest(action: any) {
  yield delay(500);
  try {
    const token = yield select(selectToken);
    let formData = new FormData();
    formData.append("file", action.payload.fileData);
    formData.append("path", action.payload.contentType);
    const options = {
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      body: formData,
    };
    const response = yield call(
      fileRequest,
      `${APP_API_URL}/s3upload/FileUploader`,
      options
    );
    if( response.Status === 401) {
      yield put(actions.getDocumentUploadUrlError("Unauthorized"));
      return false;
    }
    if (response.bucketURL) {
      yield put(actions.getDocumentUploadUrlSuccess({type: action.payload.contentType, response : response.bucketURL}));
    } else {
      yield put(actions.getDocumentUploadUrlError("Document Not Uploaded"));
    }
  } catch (err: any) {
    yield put(actions.getDocumentUploadUrlError(err));
  }
}

export function* getUploadUrlRequest(action: any) {
   yield delay(500);
   try {
  const token = yield select(selectToken);
  let formData = new FormData();
  formData.append("fileName", action.payload.fileName);
  formData.append("path", action.payload.contentType);
  const options = {
    method: "POST",
    headers: { Authorization: `Bearer ${token}` },
    body: formData,
  };

    const response = yield call(
      request,
      `${APP_API_URL}/s3upload/FileUploader/GetPreSignedURLForFileUpload`,
      options
    )

    yield put(actions.getUploadUrlSuccess(response));
  } catch (err: any) {
    yield put(actions.getUploadUrlError(err));
  }
}
export function* useS3UploadSaga() {
  yield takeLatest(actions.getUploadUrl.type, getUploadUrlRequest);
  yield takeLatest(actions.getDocumentUploadUrl.type, getDocumentUploadUrlRequest);
}
