import { Button, Typography, IconButton, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useStyles from "../styles";
import checkImage from "../../../../../assets/check.png";
import Dialog from "@mui/material/Dialog";
import { useDispatch, useSelector } from "react-redux";
import { screenNav } from "app/containers/LandlordAccount/redux/selector";
import { addedProperty } from "../../redux/selector";
import { actions } from "../../redux/slice";
const PropertyRegisteredDialog = ({ popupProp, popupFunction }) => {
  const styles = useStyles();
  const navigate = useNavigate();
  const screen = useSelector(screenNav);
  const property = useSelector(addedProperty);
  const dispatch = useDispatch();

  const [showPopup, setShowPopup] = useState(false);
  useEffect(() => {
    setShowPopup(popupProp);
  }, [popupProp]);
  return (
    <Dialog
      open={showPopup}
      className={styles.popup}
      onClose={() => {
        setShowPopup(false);
        popupFunction();
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "100%",
          alignItems: "center",
          padding: "10px",
          margin: 0,
        }}
      >
        <span
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "100%",
          }}
        >
          <IconButton
            style={{
              height: "78px",
              width: "78px",
              backgroundColor: "#ffffff",
              borderRadius: "50%",
              display: "inline-block",
              boxShadow:
                "0 2px 2px 0 rgb(0 0 0 / 20%), 0px 2px 13px 0 rgb(0 0 0 / 15%)",
              borderWidth: 1,
              borderColor: "rgba(0,0,0,0.2)",
              alignItems: "center",
              justifyContent: "center",
              margin: "15px",
            }}
          >
            <img src={checkImage} alt={"Done"} />
          </IconButton>
          <span className={styles.whiteSpan}>
            <Typography
              sx={{
                fontFamily: "Open Sans",
                fontStyle: "normal",
                fontWeight: 400,
                fontSize: "14px",
                letterSpacing: "0.1px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                padding: "10px",
              }}
            >
              {property?.Address}
            </Typography>
          </span>
          <Typography className={styles.dialogTypo}>
            Property has been successfully registered in your account!
            <br />
            Add more units in this property or click continue.
          </Typography>
        </span>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "70%",
          }}
        >
          <Button
            className={styles.signInButtonColorInvert}
            variant="contained"
            onClick={() => {
              setShowPopup(false);
              dispatch(actions.resetLeaseData());
              dispatch(actions.resetProperty());
              navigate("/add-property");
            }}
          >
            Add
          </Button>
          <Button
            className={styles.signInButton}
            variant="contained"
            onClick={() => {
              setShowPopup(false);
              if (screen === "tenantOccupied") {
                navigate("/change-control");
              } else {
                navigate("/landlord-account");
              }
            }}
          >
            Continue
          </Button>
        </Box>
      </div>
    </Dialog>
  );
};

export default PropertyRegisteredDialog;
