import {
  Button,
  Typography,
  Box,
  Dialog,
  Select,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useStyles from "../../styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useSelector } from "react-redux";
import { selectList } from "../../../../LandlordAccount/redux/selector";
import { useDispatch } from "react-redux";
import { actions } from "../../../../LandlordAccount/redux/slice";
import OtpInput from "react-otp-input";

const OtpPopUp = ({ showPopup, closePopup, continueClick }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const listitems = useSelector(selectList);
  const { items } = listitems;
  const [show, setShow] = useState(false);
  const [propertySelect, setPropertySelect] = useState<any>("Choose property");
  const [buttonEnable, setButtonEnable] = useState(true);
  const [error, setError] = useState(false);
  const [otp, setOtp] = useState("");
  const [disable, setDisable] = useState(true);

  const close = () => {
    setShow(false);
    closePopup();
  };
  useEffect(() => {
    dispatch(actions.getList());
  }, []);

  useEffect(() => {
    if (otp?.length === 4) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [otp?.length]);
  useEffect(() => {
    if (propertySelect !== "Choose property") {
      setButtonEnable(false);
    } else {
      setButtonEnable(true);
    }
  }, [propertySelect]);
  useEffect(() => {
    setShow(showPopup);
  }, [showPopup]);

  const NewIcon = (props) => (
    <KeyboardArrowDownIcon {...props} sx={{ color: "#192342" }} />
  );

  const handleChange = (otp) => {
    setOtp(otp);
  };

  return (
    <Dialog open={show} className={styles.popup}>
      <Box sx={{ display: "flex", flexDirection: "column", margin: "16px" }}>
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
        </div>
        <div className={styles.typoWrap40016}>
          The Service provider applied for this ticket receievd SMS with code
          for verification purposes. Please type here the code:
        </div>

        <OtpInput
          inputStyle={{
            height: 40,
            width: 40,
            backgroundColor: "#FFF7DD",
            justifyContent: "center",
            display: "flex",
            color: "#192342",
            fontSize: 16,
            borderRadius: 4,
            marginRight: 5,
          }}
          hasErrored={error}
          errorStyle={{ border: "1px solid #EB5757" }}
          focusStyle={{ backgroundColorL: "red" }}
          value={otp}
          className={styles.otpInputRoot}
          onChange={handleChange}
          numInputs={4}
          isInputNum={true}
        />

        <div className={styles.typoWrap40016}>
          The provider did not recieve the code? Press{" "}
          <span style={{ textDecorationLine: "underline", color: "#006CFD" }}>
            here
          </span>{" "}
          to send him again
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: 14,
          }}
        >
          <Button
            // disabled={reportType ? false : true}
            className={styles.outlinebtn}
            onClick={close}
            style={{ marginLeft: 0 }}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            className={styles.signInButton}
            disabled={disable}
            onClick={continueClick}
          >
            Verify
          </Button>
        </div>
      </Box>
    </Dialog>
  );
};

export default OtpPopUp;
