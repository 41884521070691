import set from "lodash/set";
import { createSlice } from "utils/@reduxjs/toolkit";
import { createAction, PayloadAction } from "@reduxjs/toolkit";
import { bussinessAccountState } from "./types";
import { BusinessType } from "./enum";
import { FormError } from "app/containers/types";
import moment from "moment";

export const logoutSuccess = createAction("LOGOUT_SUCCESS");
// The initial state of the GithubRepoForm container

const businessAccountSlice = createSlice({
  name: "businessAccountState",
  initialState: bussinessAccountState,
  reducers: {
    //set data via dispatch

    setbusinessAccountData: (
      state,
      action: PayloadAction<{ name: string; value: string; form?: string }>
    ) => {
      const form = action.payload.form ? action.payload.form : "form";
      const { name, value } = action.payload;
      set(state.createBusinessAccount, `${name}.value`, value);
      set(state.createBusinessAccount, `${name}.error`, "");
    },

    setBusinessType: (
      state,
      action: PayloadAction<BusinessType>
    ) => {
        state.createBusinessAccount.businessType.value = Number(action.payload)
        state.createBusinessAccount.businessType.error = ""
    },

    setBusinessAccountError(
      state,
      action: PayloadAction<{
        key: string;
        errors: Array<FormError>;
      }>
    ) {
      action.payload.errors.forEach((error) => {
        set(state.createBusinessAccount, `${error.name}.error`, error.error);
      });
    },

    createBusinessAccount(state,action) {
      state.loading = true;
    },
    createBusinessAccountSuccess(state,action) {
      state.loading = false;
      state.createdBusinessData = action.payload.Data;
    },
    createBusinessAccountError(state,action) {
      state.loading = false;
      state.error = action.payload;
    },
    setControllerOwnerData: (
      state,
      action: PayloadAction<{ name: string; value: string; form?: string }>
    ) => {
      const form = action.payload.form ? action.payload.form : "form";
      const { name, value } = action.payload;
      set(state.createControllerOwnerSRE, `${name}.value`, value);
      set(state.createControllerOwnerSRE, `${name}.error`, "");
    },

    setControllerOwnerError(
      state,
      action: PayloadAction<{
        key: string;
        errors: Array<FormError>;
      }>
    ) {
      action.payload.errors.forEach((error) => {
        set(state.createControllerOwnerSRE, `${error.name}.error`, error.error);
      });
    },
    resetOwnerAndController(state) {
      state.createControllerOwnerSRE = bussinessAccountState.createControllerOwnerSRE;
      // state.createBusinessAccount = bussinessAccountState.createBusinessAccount;
      // state.error = bussinessAccountState.error;
    },
    resetError(state) {
      state.error = bussinessAccountState.error;
    },    
    createControllerOwner(state,action) {
      state.loading = true;
    },
    createControllerOwnerSuccess(state,action) {
      state.loading = false;
      state.createdControllerOwnerData = action.payload.Data;
    },
    createControllerOwnerError(state,action) {
      state.loading = false;
      state.error = action.payload;
    },
    updateOwners(state,action) {
      state.loading = true;
    },
    updateOwnersSuccess(state,action) {
      state.loading = false;
      state.createdControllerOwnerData = action.payload.Data;
    },
    updateOwnersError(state,action) {
      state.loading = false;
      state.error = action.payload;
    },  
    createOwners(state,action) {
      state.loading = true;
    },
    createOwnersSuccess(state,action) {
      state.loading = false;
      state.createdControllerOwnerData = action.payload.Data;
    },
    createOwnersError(state,action) {
      state.loading = false;
      state.error = action.payload;
    },    
    resetModule: (state) => {
      state.error = bussinessAccountState.error;
      state.loading = bussinessAccountState.loading;
      state.createBusinessAccount = bussinessAccountState.createBusinessAccount;
      state.createControllerOwnerSRE = bussinessAccountState.createControllerOwnerSRE;
      state.createdBusinessData = bussinessAccountState.createdBusinessData;
    },
    setindividualAccountData: (
      state,
      action: PayloadAction<{ name: string; value: string; form?: string }>
    ) => {
      const form = action.payload.form ? action.payload.form : "form";
      const { name, value } = action.payload;
      set(state.createIndividualAccount, `${name}.value`, value);
      set(state.createIndividualAccount, `${name}.error`, "");
    },
    resetindividualAccountData : (state) => {
      state.createIndividualAccount = bussinessAccountState.createIndividualAccount;
    },
    createIndividualAccount(state,action) {
      state.loading = true;
    },
    createIndividualAccountSuccess(state,action) {
      state.loading = false;
      state.createdIndividualAccount = action.payload.Data;
    },
    createIndividualAccountError(state,action) {
      state.loading = false;
      state.error = action.payload;
    },
    setIndividualAccountError(
      state,
      action: PayloadAction<{
        key: string;
        errors: Array<FormError>;
      }>
    ) {
      action.payload.errors.forEach((error) => {
        set(state.createIndividualAccount, `${error.name}.error`, error.error);
      });
    },
   
    setOwnerDataRequest: (
      state,
      action: PayloadAction<{ name: string; value: string; form?: string }>
    ) => {
      const form = action.payload.form ? action.payload.form : "form";
      const { name, value } = action.payload;
      set(state.getOwnerListRequest, `${name}.value`, value);
      set(state.getOwnerListRequest, `${name}.error`, "");
    },

    getOwnerList(state, action) {
      state.loading = true;
    },
    getOwnerListSuccess(state,action) {
      state.loading = false;
      state.ownerList = action.payload.Data;
    },
    getOwnerListError(state,action) {
      state.loading = false;
      state.error = action.payload;
    },
    setthresholdData: (
      state,
      action: PayloadAction<{ name: string; value: string; form?: string }>
    ) => {
      const form = action.payload.form ? action.payload.form : "form";
      const { name, value } = action.payload;
      set(state.addThresholdData, `${name}.value`, value);
      set(state.addThresholdData, `${name}.error`, "");
    },
    setthresholdError(
      state,
      action: PayloadAction<{
        key: string;
        errors: Array<FormError>;
      }>
    ) {
      action.payload.errors.forEach((error) => {
        set(state.addThresholdData, `${error.name}.error`, error.error);
      });
    },
    addThreshold(state,action) {
      state.loading = true;
    },
    addThresholdSuccess(state,action) {
      state.loading = false;
      state.addedThreshold = action.payload.Data;
    },
    addThresholdError(state,action) {
      state.loading = false;
      state.error = action.payload;
    },
    getOwners(state,action) {
      state.loading = true;
    },
    getOwnersSuccess(state,action) {
      state.loading = false;
      state.createControllerOwnerSRE.personType.value = Number(action.payload.IsUSPerson);
      state.createControllerOwnerSRE.firstName.value = action.payload.FirstName;
      state.createControllerOwnerSRE.lastName.value = action.payload.LastName;
      state.createControllerOwnerSRE.dob.value = moment(action.payload.DOB).format("YYYY-MM-DD");
      state.createControllerOwnerSRE.address.value = action.payload.Address1;
      state.createControllerOwnerSRE.address2.value = action.payload.Address2;
      state.createControllerOwnerSRE.ssn.value = action.payload.SSN;
      state.createControllerOwnerSRE.userInfoId.value = action.payload.UserInfoId;
      state.createControllerOwnerSRE.city.value = action.payload.City;
      state.createControllerOwnerSRE.state.value = action.payload.State;
      state.createControllerOwnerSRE.zip.value = action.payload.ZipCode;
      state.createControllerOwnerSRE.businessId.value = action.payload.BusinessId;
      state.createControllerOwnerSRE.ownerAndControllerId.value = action.payload.OwnerAndControllerId;
      state.createControllerOwnerSRE.passportNumber.value = action.payload.PassportNumber;
      state.createControllerOwnerSRE.issuanceCountry.value = action.payload.IssuanceCountry;
      state.createControllerOwnerSRE.userType.value = action.payload.UserType;
      state.createControllerOwnerSRE.country.value = action.payload.Country;
    },
    getOwnersError(state,action) {
      state.loading = false;
      state.error = action.payload;
    },    
  },
});

export const { actions, reducer, name: sliceKey } = businessAccountSlice;
