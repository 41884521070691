import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  note: { 
    fontSize: '10px !important',
    font: 'inherit',
    textAlign: 'center',
    marginTop: '7px'
    },
  signInButtonColorInvert: {
    "&:disabled": {
      background: "#C4C4C4",
      color: "#6B6B6B",
    },
    display: "flex",
    flexDirection: "row",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px 16px",
    gap: "10px",
    height: "50px",
    background: "#ffffff",
    color: "#192342",
    borderRadius: "16px",
    marginTop: 10,
    border: "1px solid #192342",
    "&:hover": {
      background: "#ffffff",
      color: "#192342",
      border: "1px solid #192342",
    },
  },
});

export default useStyles;
