import {
  Button,
  Typography,
  Box,
  Dialog,
  Select,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useStyles from "../../styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useSelector } from "react-redux";
import { selectList } from "../../../../LandlordAccount/redux/selector";
import { useDispatch } from "react-redux";
import { actions } from "../../../../LandlordAccount/redux/slice";

const RejectedPopUp = ({ showPopup, closePopup, continueClick }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const listitems = useSelector(selectList);
  const { items } = listitems;
  const [show, setShow] = useState(false);
  const [propertySelect, setPropertySelect] = useState<any>("Choose property");
  const [buttonEnable, setButtonEnable] = useState(true);
  const close = () => {
    setShow(false);
    closePopup();
  };
  useEffect(() => {
    dispatch(actions.getList());
  }, []);

  useEffect(() => {
    if (propertySelect !== "Choose property") {
      setButtonEnable(false);
    } else {
      setButtonEnable(true);
    }
  }, [propertySelect]);
  useEffect(() => {
    setShow(showPopup);
  }, [showPopup]);

  const NewIcon = (props) => (
    <KeyboardArrowDownIcon {...props} sx={{ color: "#192342" }} />
  );

  return (
    <Dialog open={show} className={styles.popup}>
      <Box sx={{ display: "flex", flexDirection: "column", margin: "16px" }}>
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            display: "flex",
          }}
        >
          <img
            src={require("../../../../../assets/redCrossCancel.png")}
            style={{ width: 78, height: 78 }}
          />
        </div>
        <div className={styles.typoWrap40016}>
          Job estimate has been rejected!
        </div>

        <Button
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            height: "50px",
            background: "#192342",
            color: "#ffffff",
            borderRadius: "8px",
            marginTop: 24,
            marginBottom: 24,
          }}
          onClick={continueClick}
        >
          OK
        </Button>
      </Box>
    </Dialog>
  );
};

export default RejectedPopUp;
