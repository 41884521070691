import {
  Button,
  Typography,
  IconButton,
  InputAdornment,
  TextField,
  Box,
  Grid,
  Dialog,
  Select,
  MenuItem,
  Radio,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import { actions, reducer, sliceKey } from "../../../redux/slice";
import { useLandlordSaga } from "../../../redux/saga";
import { style } from "@mui/system";
import { useStyles } from "../../styles";
import { LoggerClass as Logger } from "utils/logger";

type Props = {};
const ChangePassword = ({
  showPopup,
  closePopup,
  handleSelect = () => {},
  handleSend = () => {},
}) => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: useLandlordSaga });
  const styles = useStyles();
  const [show, setShow] = useState(false);
  const close = () => {
    handleSelect();
  };

  useEffect(() => {
    setShow(showPopup);
  }, [showPopup]);

  return (
    <Dialog open={show} className={styles.popup}>
      <Box sx={{ display: "flex", flexDirection: "column", margin: "16px" }}>
        <Typography className={styles.modalTitle}>Change password </Typography>
        <Typography className={styles.modalSubTitle}>
          We will send 4 digit code to your phone number for the verfication
          process.
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <div>
            <div
              className={styles.label}
              style={{ marginLeft: 10, marginRight: 0, marginTop: 16 }}
            >
              Phone Number
            </div>
            <TextField
              color="primary"
              variant="outlined"
              required
              disabled={true}
              id="nickName"
              placeholder={"+1 461 342 234"}
              name="clientId"
              value={"+1 461 342 234"}
              type="number"
              onChange={(event) => {
              }}
              className={styles.textStyle}
              style={{ marginLeft: 0, marginRight: 0, width: "100%" }}
            />
          </div>
        </Box>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: 14,
          }}
        >
          <Button
            className={styles.outlinebtn}
            onClick={close}
            style={{ marginLeft: 0 }}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button className={styles.signInButton} onClick={handleSend}>
            Send
          </Button>
        </div>
      </Box>
    </Dialog>
  );
};

export default ChangePassword;
