import { makeStyles } from "@mui/styles";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useWindowDimensions from "utils/hooks/useWindowDimensions";

const Splash = () => {
  const { width, height } = useWindowDimensions();

  const navigation = useNavigate();
  const { macAddress } = useParams();

  useEffect(() => {
    setTimeout(() => {
      navigation(`/welcomeScreen`);
    }, 3000);
  });

  return (
    <div>
      <img
        src={require("../../../assets/Spalsh.png")}
        alt=""
        // className={styles.splash}
        style={{ height: height, width: width }}
      />
    </div>
  );
};

export default Splash;
