import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  otpScreenRoot: {
    // display: "flex",
    // alignItems: "center",
    // justifyContent: "center",
    height: "100%",
    color: "#FFF",
  },
  otpInputRoot: {
    "& input": {
      background: "transparent",
      outline: "none",
      border: "none",
      borderBottom: "2px solid #FFFFFF",
      // width: "100%!important",
      fontSize: "42px",
      color: "#FFF",
      padding: '11px 0px'
    },
    // width: 64,
    // marginTop: 100,
    display:'flex',
    flex:1,
    justifyContent:"center",
    
  },
  inputRoot: {},
  sheet: {
    display: "flex",
      flex: 1,
      backgroundColor: "#ffffff",
      position: "absolute",
      borderTopLeftRadius: 30,
      borderTopRightRadius: 30,
      flexDirection: "column",
      paddingBottom:20,
      paddingLeft:16,
    paddinRight:16  
},
title:{
    fontSize: 18,
    fontWeight: "600",
    color: "#192342",
    alignSelf: "center",
    marginTop:20,
    fontFamily:"Open Sans",
    marginBottom:6

  },
  subTitle:{
    fontSize: 12,
    color: "#192342",
    fontWeight: "400",
    marginTop: 16,
    alignSelf: "center",
    textAlign:'center',
    marginBottom: 10,
    marginLeft:36,
    marginRight:36
  },
  footerTxt:{
    fontSize:12,
    fontWeight:400,
    fontFamily:"Open Sans",
    textAlign:'center',
    color:'#192342',
    marginTop:16,
    // marginBottom:40
  },
  footTxt:{
    fontSize:12,
    fontWeight:600,
    fontFamily:"Open Sans",
    textAlign:'center',
    color:'#006CFD',

  },spanTitle:{
    fontWeight:'600'
  },btn:{
    fontSize: "14px",
        fontWeight: 700,
        alignSelf: "center",
        padding: "12px 0",
        borderRadius: "10px",
        backgroundColor: "#192342",
        margin: "30px 0px",
        color: "#FFFFFF",
  }
});
