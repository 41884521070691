import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import OtpVerification from "../OTPVerification";
import useStyles from "./styles";
import { emailValidator } from "utils/helpers";
import PageContainer from "app/components/Container/PageContainer";
type Props = {};
const ForgotPassword = (props: Props) => {
  const styles = useStyles();
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const [isOtpVerify, setIsOtpVerify] = useState<boolean>(false);
  const [keyDown, setKeyDown] = useState(false);
  useEffect(() => {
    if (email.length === 0 || email === "" || !emailValidator(email)) {
      setKeyDown(false);
    } else {
      setKeyDown(true);
    }
  }, [email]);
  const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    navigate("/email-verification");
  };

  if (isOtpVerify) {
    return <OtpVerification />;
  }
  return (
    <PageContainer>
      <Box className={styles.lowerContainer}>
        <Typography className={styles.pageHeading}>
          Forgot the Password?
        </Typography>
        <Box className={styles.centerContainer}>
          <Typography className={styles.pageTypo2}>
            Enter your email id for the verfication process we will send 4 digit
            code to your email.
          </Typography>
          <span className={styles.inputSpan}>
            <input
              className={styles.inputs}
              placeholder="Enter Email"
              value={email}
              type="text"
              onChange={onEmailChange}
            />
          </span>
          <Button
            disabled={!keyDown}
            className={styles.signInButton}
            variant="contained"
            onClick={handleSubmit}
          >
            Continue
          </Button>
        </Box>
      </Box>
    </PageContainer>
  );
};

export default ForgotPassword;
