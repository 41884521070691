/**
 * Homepage selectors
 */

 import { RootState } from "types";

 import { createSelector } from "@reduxjs/toolkit";
import { FundingState } from "./types";
 
 const selectDomain = (state: RootState) => state.fundingState || FundingState;

export const selectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);

export const selectError = createSelector(
  [selectDomain],
  (state) => state.error
);

export const selectFundingSource = createSelector(
  [selectDomain],
  (state) => state.fundingSource
);

export const selectLinkToken = createSelector(
  [selectDomain],
  (state) => state.linkToken
);

export const selectCreatedAccessToken = createSelector (
  [selectDomain],
  (state) => state.createdAccessToken
);

export const selectIdVerificationRequest = createSelector (
  [selectDomain],
  (state) => state.idVerificationRequest
);

export const selectIdVerificationUser = createSelector (
  [selectDomain],
  (state) => state.idVerificationUserResponse
);

export const selectIdVerificationBank = createSelector (
  [selectDomain],
  (state) => state.idVerificationBankResponse
);

export const selectIdVerificationOwner = createSelector (
  [selectDomain],
  (state) => state.idVerificationOwnerResponse
);


 
 