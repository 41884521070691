import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
    popup: {
        "& .MuiDialog-container": {},
        "& .MuiPaper-root ": {
          width: "100%",
          overflow: "auto",
          padding: "1%",
          marginTop: "10%",
          background: "#FFFFFF",
          boxShadow:
            "11px 24px 11px rgba(61, 61, 61, 0.01), 6px 13px 9px rgba(61, 61, 61, 0.05), 3px 6px 7px rgba(61, 61, 61, 0.09), 1px 1px 4px rgba(61, 61, 61, 0.1), 0px 0px 0px rgba(61, 61, 61, 0.1)",
          borderRadius: "30px",
        },
      },
      modalTitle: {
        fontFamily: "Open Sans",
        fontWeight: "600",
        fontSize: 18,
        color: "#192342",
        textAlign: "center",
        marginBottom: "10px",
      },
      modalSubTitle: {
        fontFamily: "Open Sans",
        fontWeight: "400",
        fontSize: 16,
        color: "#192342",
        textAlign: "left",
        marginBottom: '10px',
      },
      signInButton: {
        "&:disabled": {
          background: "#C4C4C4",
          color: "#6B6B6B",
        },
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px 16px",
        gap: "10px",
        height: "50px",
        background: "#192342",
        color: "#ffffff",
        borderRadius: "8px",
        marginTop: 19,
        marginBottom: 24,
        "&:hover": {
          background: "#192342",
          color: "#ffffff",
        },
      },
});
