import { Alert, Button, Typography} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useStyles } from "../styles";
import { useDispatch, useSelector } from "react-redux";
import { usePlaidLink } from 'react-plaid-link';
import { selectCreatedAccessToken, selectLoading, selectError } from "../redux/selectors";
import Loader from "app/components/Loader";
import { actions } from "../redux/slice";
import { selectCreatedBusinessData, selectCreatedIndividualData, selectCreatedControllerOwnerData } from "../../CreateBusinessAccount/redux/selectors";
import { selectUser } from "../../selectors";
import { LoggerClass } from "utils/logger";
import { useNavigate } from "react-router-dom";
import { BusinessType } from "../../CreateBusinessAccount/redux/enum";
import BankVerification from "../../IDVerification/Component/BankVerification";


const PlaidConnect = ({connectionResponse, linkToken, callingFrom}) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const navigation = useNavigate(); 

  const [errorMsg, setErrorMsg] = useState('');
  const loading = useSelector(selectLoading);
 
  const createdAccessToken = useSelector(selectCreatedAccessToken);
  const createdBusinessData = useSelector(selectCreatedBusinessData);
  const createdIndividualData = useSelector(selectCreatedIndividualData);  
  const createdControllerOwnerData = useSelector(selectCreatedControllerOwnerData);
  const ownerId = localStorage.getItem("ownerId");
  const ownerName = localStorage.getItem("ownerName");
  const ownerType = localStorage.getItem("ownerType");
  const businessType = localStorage.getItem("businessType");
  const UserInfoId = (ownerType === "business" && (Number(businessType) === BusinessType.LLCS || Number(businessType) === BusinessType.CORPORATION || Number(businessType) === BusinessType.NON_PROFITS || Number(businessType) === BusinessType.PARTNERSHIP || Number(businessType) === BusinessType.PUBLICLY_TRADED_CORPORATIONS)) ?  createdControllerOwnerData.UserInfoId : (ownerType === "business") ? createdBusinessData.UserInfoId : createdIndividualData.UserInfoId;
  const loggedinUser = useSelector(selectUser);
  const error = useSelector(selectError);
  useEffect(() => {
    console.log("UserInfoId",UserInfoId,loggedinUser);
    
  
    return () => {
    
    }
  }, [UserInfoId,loggedinUser])
  

  useEffect(() => {
    if (error) setErrorMsg(error);
    if (error === "Unauthorized") { 
        dispatch(actions.resetModule());
        navigation("/auth");
      }
    }, [error]);

  const onSuccess = React.useCallback((public_token, metadata) => {
    dispatch(actions.setCreatedAccessToken(metadata.account_id));
    dispatch(actions.setUserId(loggedinUser?.userId ? loggedinUser?.userId+"" : ownerId));
    dispatch(actions.setUserName(loggedinUser?.firstName ? loggedinUser?.firstName+"" : ownerName));
    dispatch(actions.setUserInfoId(UserInfoId));
    dispatch(actions.exchangePublicToken({data : public_token, callback : exchangeTokenSuccessCallback}));
  }, []);

  const onExit = React.useCallback((err, metadata) => {
     LoggerClass.info(err);
     }, []);

  const onEvent = React.useCallback((eventName, metadata) => {
    LoggerClass.info(eventName);
  }, []);

  const config: Parameters<typeof usePlaidLink>[0] = {
    token: linkToken,
    receivedRedirectUri: undefined,  //window.location.href,
    onSuccess,
    onExit,
    onEvent
  };
  const { open, ready } = usePlaidLink(config);

  const exchangeTokenSuccessCallback = () => {
    LoggerClass.info(createdAccessToken);    
  }

  const createdAccessTokenSuccessCallback = () => {
    connectionResponse(true);
  }

  useEffect(() => {   
    if(createdAccessToken.accessToken && createdAccessToken.accountId && callingFrom !== "Loggedin") {
      dispatch(actions.createProcessToken({data : createdAccessToken, callback : createdAccessTokenSuccessCallback}));
      }
  }, [createdAccessToken]);

  return ( 
    <>
    <Loader loading={loading} />


    {errorMsg && (
<Alert className={styles.error} severity="error">
{errorMsg}
</Alert>
)}
    <div> 
        <div className={styles.subTitle}>
        <Typography>
          Connecting a bank account allows you to send and receive funds using this platform.
        </Typography>
        <Typography>
          Bank connection and Funds transactions are provided by Dwolla, we are not storing any data in our systems.
        </Typography>
        </div>
        {callingFrom === "Loggedin" && <BankVerification connectionResponse={(output) => {
        if(output) {
          console.log("Output"+output);       
          }
      }}/>}
        <Button
        className={styles.ConnectInButton}
        variant="contained"
        onClick={() => open()}
        disabled={!ready}
        >
        {callingFrom !== "Loggedin" ? 'Connect with Plaid' : 'Reconnect with Plaid'} 
        </Button>
    </div> 
    </> 
  );
};

export default PlaidConnect;
