import useStyles from "./styles";
import { Checkbox, Typography } from "@mui/material";
import { selectIndividualAccountData } from "../../../redux/selectors";
import { actions } from "../../../redux/slice";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { selectUser } from "app/containers/Auth/selectors";
import dayjs, { Dayjs } from "dayjs";
import DatePickerBusiness from "app/components/DatePickerBusiness";


export default function IndividualAccountForm() {
    const dispatch = useDispatch();
    const styles = useStyles();
    const navigation = useNavigate();
    const individualAccountData = useSelector(selectIndividualAccountData);
    const loggedinUser = useSelector(selectUser);
    const today = dayjs();
    const [dob, setOwnerDOB] = useState<Dayjs | null>(today);


    useEffect(() => {
        dispatch(
            actions.setindividualAccountData({
                name: "dob",
                value: dob !== null ? dob?.format("YYYY-MM-DD") : "",
                form: "addIndividualAccount",
            })
        );
    }, [dob]);

    useEffect(() => {
        dispatch(actions.setindividualAccountData({ name: 'email', value: loggedinUser?.userEmail ? loggedinUser?.userEmail + "" : JSON.parse(localStorage.getItem("loginResponse")!).userEmail, form: "addIndividualAccount" }));
        dispatch(actions.setindividualAccountData({ name: 'userId', value: loggedinUser?.userId ? loggedinUser?.userId + "" : JSON.parse(localStorage.getItem("loginResponse")!).userId, form: "addIndividualAccount" }));
    }, [loggedinUser]);

    const onChangeEvent = (event) => {
        const { name, value } = event.target;

        if(name === 'ssn' || name == 'zip'){
            dispatch(
                actions.setindividualAccountData({ name, value: value.trim().replace(/[^\w\s]/gi, ""), form: "addIndividualAccount" })
            );
            
        }else{
            dispatch(
                actions.setindividualAccountData({ name, value: value.trim(), form: "addIndividualAccount" })
            );
        }
        // dispatch(
        //     actions.setindividualAccountData({ name, value: value.trim(), form: "addIndividualAccount" })
        // );
    }

    const onOwnerDOBChangeEvent = (value) => {
        setOwnerDOB(value);
    };

    const handleKeyEvent = (e) => {
        let charCode = (e.which) ? e.which : e.keyCode;
        if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
            e.preventDefault();
        }
    };


    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                width: "90%",
                alignItems: "flex-start",
                padding: "4px",
                margin: 'auto'
            }}
        >

            <div className={styles.disabledInputSpan}>
                <Typography
                    sx={{ color: "7D7D7D !important" }}
                    className={styles.pageTypoThresholdDisabled}
                >
                    Email Address
                </Typography>
                <input
                    className={styles.inputs}
                    placeholder="Enter Email Address"
                    name="email"
                    value={individualAccountData.email.value}
                    type="text"
                    disabled
                    onChange={onChangeEvent}
                />
                {individualAccountData.email.error && <Typography sx={{ color: '#922', marginLeft: '3%', width: '90%', alignSelf: 'center' }} >{individualAccountData.email.error}</Typography>}

            </div>

            <div className={styles.disabledInputSpan}>
                <Typography className={styles.pageTypoThresholdDisabled}>
                    First Name
                </Typography>
                <input
                    className={styles.inputs}
                    placeholder="Enter First Name"
                    name="firstName"
                    value={individualAccountData.firstName.value}
                    type="text"
                    onChange={onChangeEvent}
                />
                {individualAccountData.firstName.error && <Typography sx={{ color: '#922', marginLeft: '3%', width: '90%', alignSelf: 'center' }} >{individualAccountData.firstName.error}</Typography>}

            </div>


            <div className={styles.disabledInputSpan}>
                <Typography className={styles.pageTypoThresholdDisabled}>
                    Last Name
                </Typography>
                <input
                    className={styles.inputs}
                    placeholder="Enter Last Name"
                    name="lastName"
                    value={individualAccountData.lastName.value}
                    type="text"
                    onChange={onChangeEvent}
                />
                {individualAccountData.lastName.error && <Typography sx={{ color: '#922', marginLeft: '3%', width: '90%', alignSelf: 'center' }} >{individualAccountData.lastName.error}</Typography>}

            </div>

            <div
                style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "row",
                    flexWrap: "wrap",
                    alignItems: "flex-start",
                }}
            >
                <div style={{ width: "49%", marginRight: "1%" }}>

                    <div className={styles.disabledInputSpan}>
                        <Typography className={styles.pageTypoThresholdDisabledOne}>
                            SSN
                        </Typography>
                        <input
                            className={styles.inputs}
                            placeholder="Enter SSN"
                            name="ssn"
                            value={individualAccountData.ssn.value}
                            type="text"
                            onChange={onChangeEvent}
                            onKeyDown={handleKeyEvent}
                        />
                        {individualAccountData.ssn.error && <Typography sx={{ color: '#922', marginLeft: '3%', width: '90%', alignSelf: 'center' }} >{individualAccountData.ssn.error}</Typography>}
                    </div>
                </div>
                <div style={{ width: "49%", marginRight: "1%" }}>
                    <div className={styles.disabledInputSpan}>
                        <Typography className={styles.pageTypoThresholdDisabledOne}>
                            Date of Birth
                        </Typography>
                        <DatePickerBusiness
                            handleChange={onOwnerDOBChangeEvent}
                            value={dob}
                            width={'100%'}
                        />
                        {individualAccountData.dob.error && <Typography sx={{ color: '#922', marginLeft: '3%', width: '90%', alignSelf: 'center' }} >{individualAccountData.dob.error}</Typography>}
                    </div>

                </div>
            </div>
            <div className={styles.disabledInputSpan}>
                <Typography className={styles.pageTypoThresholdDisabled}>
                    Address
                </Typography>
                <input
                    className={styles.inputs}
                    placeholder="Enter Street name and number"
                    name="address"
                    value={individualAccountData.address.value}
                    type="text"
                    onChange={onChangeEvent}
                />
            </div>
            {individualAccountData.address.error && <Typography sx={{ color: '#922', marginLeft: '3%', width: '90%', alignSelf: 'center' }} >{individualAccountData.address.error}</Typography>}

            <div className={styles.disabledInputSpan}>
                <input
                    className={styles.inputs}
                    placeholder="Aprt, floor, suite, bldg #"
                    name="address2"
                    value={individualAccountData.address2.value}
                    type="text"
                    onChange={onChangeEvent}
                />
                <Typography sx={{ marginLeft: '3%', width: '90%', color: '#ccc', fontSize: '12px', alignSelf: 'center' }} >Optional field</Typography>

            </div>


            <div className={styles.disabledInputSpan}>
                <Typography className={styles.pageTypoThresholdDisabled}>
                    City
                </Typography>
                <input
                    className={styles.inputs}
                    placeholder="Enter City"
                    name="city"
                    value={individualAccountData.city.value}
                    type="text"
                    onChange={onChangeEvent}
                />
            </div>
            {individualAccountData.city.error && <Typography sx={{ color: '#922', marginLeft: '3%', width: '100%', alignSelf: 'center' }} >{individualAccountData.city.error}</Typography>}<div
                style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "row",
                    flexWrap: "wrap",
                    alignItems: "flex-start",
                }}
            >
                <div style={{ width: "49%", marginRight: "1%" }}>
                    <Typography className={styles.pageTypoThresholdDisabledOne}>
                        State
                    </Typography>
                    <div className={styles.disabledInputSpan}>
                        <input
                            className={styles.inputs}
                            placeholder="State"
                            name="state"
                            value={individualAccountData.state.value}
                            type="text"
                            onChange={onChangeEvent}
                        />
                    </div>
                    {individualAccountData.state.error && <Typography sx={{ color: '#922', marginLeft: '3%', width: '90%', alignSelf: 'center' }} >{individualAccountData.state.error}</Typography>}

                </div>
                <div style={{ width: "49%", marginRight: "1%" }}>
                    <Typography className={styles.pageTypoThresholdDisabledOne}>
                        Zip Code
                    </Typography>
                    <div className={styles.disabledInputSpan}>
                        <input
                            className={styles.inputs}
                            placeholder="Enter City"
                            name="zip"
                            value={individualAccountData.zip.value}
                            type="text"
                            onChange={onChangeEvent}
                            onKeyDown={handleKeyEvent}
                        />
                    </div>
                    {individualAccountData.zip.error && <Typography sx={{ color: '#922', marginLeft: '3%', width: '90%', alignSelf: 'center' }} >{individualAccountData.zip.error}</Typography>}
                </div>
            </div>


        </div>
    );
}
