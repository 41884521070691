import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  sheet: {
    display: "flex",
    flex: 1,
    backgroundColor: "#ffffff",
    position: "absolute",
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    flexDirection: "column",
  },
  signInButtonColorInvert: {
    "&:disabled": {
      background: "#C4C4C4",
      color: "#6B6B6B",
    },
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 16px",
    gap: "10px",
    height: "50px",
    background: "#ffffff",
    color: "#192342",
    borderRadius: "8px",
    marginTop: 10,
    border: "1px solid #192342",
    "&:hover": {
      background: "#ffffff",
      color: "#192342",
      border: "1px solid #192342",
    },
  },
  boxLease: {
    "&:disabled": {
      background: "#C4C4C4",
      color: "#6B6B6B",
    },
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 20px 10px 20px",
    gap: "10px",
    height: "44px",
    background: "#ffffff",
    color: "#192342",
    borderRadius: "16px",
    marginTop: 10,
    outline: "none",
    boxShadow:
      "7px 19px 8px rgba(194, 194, 194, 0.01), 4px 10px 7px rgba(194, 194, 194, 0.05), 2px 5px 5px rgba(194, 194, 194, 0.09), 0px 1px 3px rgba(194, 194, 194, 0.1), 0px 0px 0px rgba(194, 194, 194, 0.1)",
    "&:hover": {
      background: "#ffffff",
      color: "#192342",
      outline: "none",
    },
  },
  logo: {
    height: 'auto',
    width: '50%',
    // marginTop: 24,
    alignSelf: "center",
  },
  logoContainer: {
    display: "flex",
    justifyContent: "center",
  },
  title: {
    fontSize: 20,
    fontWeight: "700",
    color: "#F0BB00",
    alignSelf: "center",
  },
  subtitle: {
    fontSize: 18,
    color: "#192342",
    fontWeight: "600",
    marginTop: 16,
    alignSelf: "center",
    marginBottom: 10,
  },
  btn: {
    fontSize: "14px",
    fontWeight: 700,
    alignSelf: "center",
    padding: "12px 0",
    borderRadius: "10px",
    backgroundColor: "#192342",
    margin: "30px 0px",
    color: "#FFFFFF",
  },
  contentTxt: {
    marginTop: 10,
    fontSize: 14,
    fontWeight: "400",
    alignSelf: "center",
    color: "#192342",
  },
  imgView: {
    flexDirection: "row",
    flex: 1,
    justifyContent: "center",
    display: "flex",
    marginTop: 16,
  },
  icon: {
    height: 36,
    width: 36,
  },
  btmTxt: {
    fontSize: 16,
    fontWeight: "400",
    alignSelf: "center",
    marginTop: 24,
    // marginBottom: 40,
  },
  radioDiv: { marginBottom: "16px", display: "flex", alignItems: "center" },
  span: {
    color: "#F0BB00",
    fontWeight: "700",
  },
  signInButton: {
    "&:disabled": {
      background: "#C4C4C4",
      color: "#6B6B6B",
    },
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 16px",
    gap: "10px",
    height: "50px",
    background: "#192342",
    color: "#ffffff",
    borderRadius: "8px",
    marginTop: 19,
    marginBottom: 24,
    "&:hover": {
      background: "#192342",
      color: "#ffffff",
    },
  },
  inputs: {
    padding: "10px 16px",
    width: "100%",
    height: "48px",
    background: "#FFF7DD",
    // color: "#7D7D7D",
    font: 'inherit',
    borderRadius: "20px",
    border: "unset",
    margin: "5px",
    "&:focus": {
      outline: "none",
    },

    "&:disabled": {
      background: "#E0E0E0",
      color: "#7D7D7D",
    },
  },
  dateInput: {
    "& .MuiOutlinedInput-root ": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      padding: "10px 10px 10px 05px",
      width: "100%",
      height: "48px",
      background: "#FFF7DD",
      color: "#7D7D7D",
      borderRadius: "20px",
      margin: "5px 5px 10px 0px",
      alignItems: "center",
      outline: "none",
      border: "none",
      "&:focus fieldset": {
        outline: "none",
        border: "none",
      },
      "&:hover fieldset": {
        outline: "none",
        border: "none",
      },
      "&:disabled fieldset": {
        background: "#E0E0E0",
        color: "#7D7D7D",
        border: "none",
      },
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #484850",
      borderRadius: "5px 5px 0 0",
    },
    "& .MuiOutlinedInput-input": {
      width: "100%",
      height: "14px",
      background: "#FFF7DD",
      color: "#7D7D7D",
      borderRadius: "20px",
      "&:focus": {
        outline: "none",
        border: "none",
      },
      "&:disabled": {
        background: "#E0E0E0",
        color: "#7D7D7D",
        border: "none",
      },
    },
  },
  errorInputSpan: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    padding: "10px 16px 10px 0px",
    width: "100%",
    height: "48px",
    background: "#FFF7DD",
    color: "#7D7D7D",
    borderRadius: "20px",
    border: "unset",
    margin: "5px 5px 10px 0px",
    alignItems: "center",
    outline: "#EB5757 auto",
  },
  errorTypo: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "19px",
    alignSelf: "flex-start",
    color: "#EB5757",
    marginLeft: "20px",
    padding: "5px",
  },
  inputSpan: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    padding: "10px 16px 10px 0px",
    width: "100%",
    height: "48px",
    background: "#FFF7DD",
    color: "#7D7D7D",
    borderRadius: "20px",
    border: "unset",
    margin: "5px 5px 10px 0px",
    alignItems: "center",
    "&:active": {
      border: " 2px solid #F0BB00",
    },
    "&:focus": {
      border: " 2px solid #F0BB00",
    },
  },
  disabledInputSpan: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    padding: "10px 16px 10px 0px",
    width: "100%",
    height: "48px",
    background: "#E0E0E0",
    color: "#7D7D7D",
    borderRadius: "20px",
    border: "unset",
    margin: "5px 5px 10px 0px",
    alignItems: "center",
  },
  inputSpanPaypal: {
    display: "flex",
    flexDirection: "row",
    padding: "10px 16px 10px 0px",
    width: "100%",
    height: "48px",
    background: "#FFF7DD",
    color: "#7D7D7D",
    borderRadius: "20px",
    border: "unset",
    margin: "10px 5px 5px 0px",
    alignItems: "center",
  },
  buttonSpan: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    padding: "10px 16px 10px 0px",
    width: "100%",
    height: "48px",
    background: "#ffffff",
    color: "#192342",
    borderRadius: "20px",
    margin: "5px",
    alignItems: "center",
    outline: "#192342 auto",
    "&:disabled": {
      background: "#ffffff",
      color: "#192342",
      outline: "#192342 auto",
    },
    marginTop: 10,
    marginBottom: 110,
    "&:hover": {
      background: "#ffffff",
      color: "#192342",
      outline: "#192342 auto",
    },
  },
  pageHeading: {
    margin: "24px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: "25px",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "140%",
    letterSpacing: "0.2px",
    color: "#192342",
  },
  imagePageHeading: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "140%",
    letterSpacing: "0.2px",
    color: "#192342",
  },
  bigAddEnable: {
    height: "25px",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "140%",
    letterSpacing: "0.2px",
    color: "#F0BB00",
  },
  bigAddDisable: {
    height: "25px",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "140%",
    letterSpacing: "0.2px",
    color: "#BCBCBC",
  },
  checkbox: {
    marginRight: "6px",
    marginTop: "2px",
    "&:checked": {
      accentColor: "#192342",
    },
  },
  checkboxTypos: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    color: "#192342",
    display: "contents",
    marginLeft: "6px",
  },
  linkForgot: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    color: "#006CFD",
    textDecoration: "none",
  },
  upperContainer: {
    height: "32%",
  },
  lowerContainer: {
    height: "68%",
    background: "#FFFFFF",
    boxShadow:
      "0px -37px 15px rgba(222, 222, 222, 0.01), 0px -21px 12px rgba(222, 222, 222, 0.05), 0px -9px 9px rgba(222, 222, 222, 0.09), 0px -2px 5px rgba(222, 222, 222, 0.1), 0px 0px 0px rgba(222, 222, 222, 0.1)",
    borderRadius: "30px 30px 0px 0px",
    padding: "0% 2%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  singleContainer: {
    padding: "2% 2%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  pageTypoUI: {
    textAlign: "center",
    height: "fit-content",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "140%",
    letterSpacing: "0.2px",
    marginBottom: "10px",
    color: "#192342",
    position: "relative",
    left: 0,
    marginRight: "auto",
  },
  popup: {
    "& .MuiDialog-container": {},
    "& .MuiPaper-root ": {
      width: "100%",
      overflow: "hidden",
      padding: "1%",
      marginTop: "20%",
      background: "#FFFFFF",
      boxShadow:
        "11px 24px 11px rgba(61, 61, 61, 0.01), 6px 13px 9px rgba(61, 61, 61, 0.05), 3px 6px 7px rgba(61, 61, 61, 0.09), 1px 1px 4px rgba(61, 61, 61, 0.1), 0px 0px 0px rgba(61, 61, 61, 0.1)",
      borderRadius: "30px",
    },
  },
  pageTypo2: {
    textAlign: "center",
    height: "fit-content",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "140%",
    letterSpacing: "0.2px",
    marginBottom: "24px",
    color: "#192342",
    position: "relative",
    left: 0,
    marginRight: "auto",
  },
  pageTypoone: {
    textAlign: "center",
    height: "fit-content",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "140%",
    letterSpacing: "0.2px",
    marginBottom: "6px",
    color: "#192342",
    position: "relative",
    left: 0,
    marginRight: "auto",
  },
  buttonPaypal: {
    textAlign: "center",
    height: "fit-content",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.1px",
    color: "#192342",
  },
  pageTypoThreshold: {
    textAlign: "left",
    height: "fit-content",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "19px",
    letterSpacing: "0.2px",
    color: "#192342",
    paddingLeft: "22px",
    marginTop: "16px",
    marginBottom: "5px",
  },
  pageTypoThresholdWP: {
    textAlign: "left",
    height: "fit-content",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "19px",
    letterSpacing: "0.2px",
    color: "#192342",
  },
  pageTypoH: {
    textAlign: "left",
    height: "fit-content",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "19px",
    letterSpacing: "0.2px",
    margin: "24px 0 5px 3%",
    color: "#192342",
  },
  pageTypoThresholdDisabled: {
    textAlign: "left",
    height: "fit-content",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "19px",
    letterSpacing: "0.2px",
    margin: "0 0 5px 3%",
    color: "#7D7D7D",
  },
  pageTypoMinimum: {
    textAlign: "left",
    height: "fit-content",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "10px",
    lineHeight: "14px",
    letterSpacing: "0.2px",
    // margin: "0 0 5px 3%",
    color: "#192342",
    marginLeft: 16,
    marginTop: 2,
  },
  bottomTypo: {
    textAlign: "center",
    height: "fit-content",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    letterSpacing: "0.2px",
    color: "#192342",
  },
  bottomLink: {
    textAlign: "center",
    height: "fit-content",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    letterSpacing: "0.2px",
    color: "#006CFD",
    textDecoration: "none",
  },
  centerContainer: {
    width: "100%",
    height: "12%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 0,
    margin: "0px 46px 0px 46px",
  },
  dialogTypo: {
    textAlign: "center",
    height: "fit-content",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "140%",
    letterSpacing: "0.1px",
    color: "#192342",
    margin: "16px 0px 24px 0px",
  },
  whiteSpan: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
    height: "48px",
    background: "#FFFFFF",
    color: " #192342",
    borderRadius: "20px",
    alignItems: "center",
    border: "0.5px solid #BCBCBC",
    boxShadow:
      "7px 19px 8px rgba(194, 194, 194, 0.01), 4px 10px 7px rgba(194, 194, 194, 0.05), 2px 5px 5px rgba(194, 194, 194, 0.09), 0px 1px 3px rgba(194, 194, 194, 0.1), 0px 0px 0px rgba(194, 194, 194, 0.1)",
  },
  flexColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "flex-start",
    padding: "4px",
  },
  flexRow: {
    display: "flex",
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "flex-start",
  },
  footer: {
    marginBottom: "10px",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  hhcontain: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  closeIcon: {
    width: "11.67px",
    height: "11.67px",
    color: "#7D7D7D",
  },
  imageContainer: {
    width: "102px",
    height: "68px",
    postion: "absolute",
    top: "25px",
  },
  uploadedImage: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
    gap: "10px",
    width: "173px",
    height: "112px",
    border: "0.5px solid #BCBCBC",
    filter:
      "drop-shadow(7px 19px 8px rgba(194, 194, 194, 0.01)) drop-shadow(4px 10px 7px rgba(194, 194, 194, 0.05)) drop-shadow(2px 5px 5px rgba(194, 194, 194, 0.09)) drop-shadow(0px 1px 3px rgba(194, 194, 194, 0.1)) drop-shadow(0px 0px 0px rgba(194, 194, 194, 0.1))",
    borderRadius: "16px",
    position: "relative",
  },
});

export default useStyles;
