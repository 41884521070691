import {
  Button,
  Checkbox,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import CopyRightSection from "app/components/CopyRight";
import Page from "app/components/Page";
import PageImageHeader from "app/components/PageImageHeader";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import useWindowDimensions from "utils/hooks/useWindowDimensions";
import { LoggerClass } from "utils/logger";
import { selectError, selectAddThresholdData } from "../CreateBusinessAccount/redux/selectors";
import { actions } from "../CreateBusinessAccount/redux/slice";
import { selectUser } from "../selectors";
import { useStyles } from "./styles";
import { valdiator } from "./validator";


const Threshold = () => {
  const { width, height } = useWindowDimensions();
  const dispatch = useDispatch();
  const styles = useStyles();
  const navigation = useNavigate();
  const [disabled, setDisabled] = useState(true);
  const redirect = useSelector(selectError);
  const loggedinUser = useSelector(selectUser);
  const addThresholdData = useSelector(selectAddThresholdData);
  const errors = valdiator(addThresholdData);

  const location = useLocation();

  const { state } = location;
  const roleType = JSON.parse(localStorage.getItem('loginResponse')!).role;
  
  useEffect(() => {
    if (redirect === "Unauthorized") {
      dispatch(actions.resetModule());
      navigation("/auth");
    }
  }, [redirect]);

  
  useEffect(() => {   

    let allFieldsHaveValue = true;

for (const key in addThresholdData) {
  if (addThresholdData.hasOwnProperty(key) && addThresholdData[key].value.length === 0) {
  allFieldsHaveValue = false;
  break;
  } 
}
if (allFieldsHaveValue) {
    setDisabled(false);
} else {
    setDisabled(true);
}
  }, [addThresholdData]);
  

  const onChangeEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch(actions.setthresholdData({ name : 'UserId', value : loggedinUser?.userId ? loggedinUser?.userId+"" : JSON.parse(localStorage.getItem("loginResponse")!).userId, form: "addThreshold" }));
    dispatch(actions.setthresholdData({ name, value, form: "addThreshold" }));
  };

  const handleContinue = (event) => {
    event.preventDefault();
    if(errors.length){
      dispatch(actions.setthresholdError({ key: 'addIndividualAccount', errors }))
      return
    }
    dispatch(actions.addThreshold({ data: addThresholdData, callback: thresholdAddedSuccess }));  //will open, It is working fine

  };

  const thresholdAddedSuccess = (e) => {
      //navigation('/verification');

      if(state === "changeMobileNumber") {
        navigation("/landlord-account/landlord-profile", {state : state});              
      } else {
        if (roleType === "tenant") {
          navigation("/address");
        } else {
          navigation("/funding-source");
        }
      }
  }

  const handleKeyEvent = (e) => {
    let charCode = (e.which) ? e.which : e.keyCode;
    if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
        e.preventDefault();
    }
  };

  return (
    <Page>
      <PageImageHeader
        subHeading={
          <div style={{ display: "flex" }}>
            <Typography sx={{ fontSize: 14, color: "#fff" }}>
              {"have account"}
            </Typography>
            <Typography
              sx={{
                fontSize: 14,
                color: "#FAA61A",
                fontWeight: "bold",
                textDecoration: "underline",
                marginLeft: "4px",
              }}
            >
              {"login"}
            </Typography>
          </div>
        }
      />

      <div
        style={{
          width: width,
          top: height / 2.82,
        }}
        className={styles.sheet}
      >
        <div className={styles.title}>Create an Account</div>
        <div className={styles.title1}>Add Maintenance requests Threshold</div>

        <div className={styles.subTitle}>
        Service providers Estimates for Service requests below this threshold will be automatically approved
        </div>

        <div
          style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          width: "90%",
          alignItems: "flex-start",
          padding: "4px",
          margin: 'auto'
          }}          
          >  
          <div className={styles.disabledInputSpan}>  
          <Typography className={styles.pageTypoThresholdDisabled}>
          Threshold Amount
          </Typography> 
          <TextField
          className={styles.inputs}          
          required
          placeholder={"Enter threshold amount"}
          name="ThresholdAmt"
          value={addThresholdData.ThresholdAmt.value}
          onChange={onChangeEvent}
          onKeyDown={handleKeyEvent}
          InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <div className={styles.leftSide}>$</div>
            </InputAdornment>
          ),
          }}
          />  
          <Typography sx={{fontSize: '10px', margin: "0 0 0 3%"}}>Minimum 75$</Typography>  
          {addThresholdData.ThresholdAmt.error && <Typography sx={{color:'#922',marginLeft:'3%', width: '90%', alignSelf: 'center'}} >{addThresholdData.ThresholdAmt.error}</Typography>}
    
          </div>
          </div>
        <Button
          variant="contained"
          sx={{
            width: width / 1.15,
          }}
          disabled={disabled}
          className={styles.btn}
          onClick={handleContinue}
        >
          Continue
        </Button>

        <CopyRightSection/>
      </div>
    </Page>
  );
};

export default Threshold;
