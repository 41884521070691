import React from "react";
import useWindowDimensions from "utils/hooks/useWindowDimensions";

import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

type Props = {};

const useStyles = makeStyles(() => ({
  root: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    padding: 16,
  },
  text: {
    marginTop: 16,
    fontSize: 24,
    letterSpacing: ".5px",
    textAlign: "center",
    color: "#3C4858",
  },
}));

export const UnsupportedScreen = (props: Props) => {
  const styles = useStyles();
  const { width } = useWindowDimensions();
  return (
    <Box className={styles.root}>
      <img
        src={require("../../assets/logo.png")}
        alt=""
        style={{ height: 62, width: 116 }}
      />
      <div
        className={styles.text}
      >{`This application only supported for mobile devices`}</div>
      <div className={styles.text}>
        Your current window size is{" "}
        <span style={{ fontWeight: "bold" }}>{width}</span>px
      </div>
    </Box>
  );
};
