import {Button} from "@mui/material";
import Page from "app/components/Page";
import PageImageHeader from "app/components/PageImageHeader";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import useWindowDimensions from "utils/hooks/useWindowDimensions";
import { selectControllerOwnerData, selectLoading} from "../../redux/selectors";
import { valdiator } from "../CommonForms/BusinessControllerOwnerForm/validator";
import useStyles from "./styles";
import { actions } from "../../redux/slice";
import BusinessControllerOwnerForm from "../CommonForms/BusinessControllerOwnerForm";
import { PersonType, UserTypeEnum } from "../../redux/enum";
import { LoggerClass } from "utils/logger";
import Loader from "app/components/Loader";


const BusinessAccountOwner = () => {
  const { width, height } = useWindowDimensions();
  const dispatch = useDispatch();
  const styles = useStyles();
  const navigation = useNavigate();
  const [disabled, setDisabled] = useState<boolean>(true);
  const businessControllerData = useSelector(selectControllerOwnerData);
  const errors = valdiator(businessControllerData);
  const loading = useSelector(selectLoading);
  const location = useLocation();
  const [text, setText] = useState<string>("Add");


 useEffect(() => {
     if(location.state === "Loggedin" && location.search) {
      dispatch(actions.getOwners({data: location.search.substring(12), callback: onGetOwnerSuccess}));
      setText("Update");
    }
 },[dispatch]);

 const onGetOwnerSuccess = (e) => {
 }

  useEffect(() => {
  dispatch(actions.setControllerOwnerData({ name : "userType", value : UserTypeEnum.OWNER+"", form: "addControllerOwner" }));
  
  let allFieldsHaveValue = true;
  for (const key in businessControllerData) {
    if ((Number(businessControllerData['personType'].value) === PersonType.US) && (key !== "passportNumber" && key !== "issuanceCountry" && key !== "ownerAndControllerId" && key !== "userInfoId" && key !== "address2" && key !== "title" && key !== "country" && businessControllerData.hasOwnProperty(key) && businessControllerData[key].value.length === 0)) {
        allFieldsHaveValue = false;
      break;
    } 
    
    if ((Number(businessControllerData['personType'].value) === PersonType.NON_US) && (key !== "ssn" && key !== "title" && key !== "ownerAndControllerId" && key !== "userInfoId" && key !== "address2" && businessControllerData.hasOwnProperty(key) && businessControllerData[key].value.length === 0)) {
      allFieldsHaveValue = false;
    break;
    } 
  }
    if (allFieldsHaveValue) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }

  }, [businessControllerData]);
  
  const handleSubmit = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    if(errors.length){
      dispatch(actions.setControllerOwnerError({ key: 'addBusinessAccount', errors }))
      return
    }
    if(businessControllerData.userInfoId.value) {
      dispatch(actions.updateOwners({data: businessControllerData, callback: onLoginSuccess}));
    } else {
       dispatch(actions.createOwners({data: businessControllerData, callback: onLoginSuccess}));
    }
  };

  const onLoginSuccess = () => {
    dispatch(actions.resetOwnerAndController());
     LoggerClass.info("Go to owner details screen"); 
     navigation(-1);    
  };

  return (
    <>
    <Loader loading={loading} />    
    <Page>
      <PageImageHeader/>
      <div
        style={{
          width: '100%',
          top: height / 2.82,
        }}
        className={styles.sheet}
      >
        <div className={styles.title1} style={{
            marginBottom: "10px"}}
            >Add Owner</div> 
            <BusinessControllerOwnerForm formType={'Owner'}/>
        <div
          style={{
            marginBottom: "10px",
            width: "90%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            margin: 'auto'
          }}
        >
          <Button
            disabled={disabled}
            className={styles.signInButton}
            variant="contained"
            onClick={handleSubmit}
          >
            {text}
          </Button>          
        </div>     
      </div>
    </Page>
    </>
  );
};

export default BusinessAccountOwner;
