import { FormElement } from "app/containers/types";
import moment from "moment";
import { ServiceStatus, ServiceType } from "./Landlord.enum";

export interface ResponseError {
  message: string;
}
export type RequestError = ResponseError;

export type PendingBalance = {  
  balance : number,
}

export type LoggedInUserData = {  
  FirstName : String,
  LastName : String,
  Email : String,
  ProfileImageURL : String
}

export type AllocatedUsers = {
  id: string | null;
  propertyId: string | null;
  firstName: string | null;
  lastName: string | null;
  nextRentalPay: string | null;
};

export type PropertyListDropdown = {
  PropertyId: string;  
  Address: string | null;  
};

export type PropertyListItemType = {
  id: string;
  ownerId: string;
  address: string | null;
  zipCode: Number | null;
  state: string | null;
  city: string | null;
  type: string | null;
  occupationStatus: string | null;
  propertyStatus: string | null;
  email: string | null;
  imageUrl: string | null;
  allocatedUsers: Array<AllocatedUsers>;
};

export type PortfolioListItemType = {
  PropertyId: string;
  OwnerId: string;
  Address: string | null;
  Latitude: string | null,
  Longitude: string | null,
  Zip: string | null;
  State: string | null;
  City: string | null;
  Type: number | null;
  Status: number | null;
  Units: number | null;
  PropertyOccupationStatuses : number | null;
  Street: string | null;
  Image: string | null;
  RentAmount : string | null,
  DepositAmount : string | null,
  LeaseExpirationDate : string | null,
  PayDay : number | null,
  AllocatedUsers: Array<AllocatedPropertyUsers>;
};

export type AllocatedPropertyUsers = {
  UserId: string | null;
  UserName: string | null;  
};

export type ServiceRequestListItemType = {
  ServiceRequestID: string;
  ServiceType: ServiceType;
  PropertyId: string | null;
  PropertyAddress: string | null;
  ServiceProviderID: string | null;
  ServiceProviderName: string | null;
  CreatedDate: string | null;
  OpenedBy: string | null;
  WorkStatus: ServiceStatus;
  Description: string | null;
  AcceptedTimeFrame: string | null;
  VerificationOTP: string | null;
  EstimatedCost: string | null;
  Image: string | null;
  Video: string | null;
};

export interface NewPropertyRequest {
  address: string | null;
  zipCode: Number | null;
  state: string | null;
  city: string | null;
  type: string | null;
  occupationStatus: string | null;
  propertyStatus: string | null;
  email: string | null;
  isActive: boolean;
}

export type NewUserResponse = {
  id: any;
  address: string | null;
  zipCode: Number | null;
  state: string | null;
  city: string | null;
  type: string | null;
  occupationStatus: string | null;
  propertyStatus: string | null;
  blockedUntil: string | null;
  email: string;
  isActive: true;
  role: any;
  image: any;
  userType: string;
  createdAt: string;
  updatedAt: string;
  message?: string;
};

export type PropertyForm = {
  id: FormElement;
  address: FormElement;
  zipCode: FormElement;
  state: FormElement;
  city: FormElement;
  type: FormElement;
  occupationStatus: FormElement;
  propertyStatus: FormElement;
  email: FormElement;
  imageUrl: FormElement;
  isActive: FormElement;
  role: FormElement;
  createdAt: FormElement;
  updatedAt: FormElement;
  blockedUntil: FormElement;
};

export type addProperty = {  
  zip: FormElement;
  address: FormElement;
  city: FormElement;
  state: FormElement;
  type: FormElement;
  occupationStatus: FormElement;
  ownerId: FormElement;
  ownerName : FormElement;
  units: FormElement;
  street: FormElement;
  latitude: FormElement;
  longitude: FormElement;
};
export type Document = {
  DocumentId: FormElement,
  PropertyId: FormElement,
  DocumentURL: string,
  Type : FormElement,
  IsActive: Boolean
}

export type addLease = {
  LeaseId: FormElement;
  PropertyId: FormElement;
  LeaseExpirationDate: FormElement;
  ownerId: FormElement;
  PayDay: FormElement;
  RentAmount: FormElement;
  DepostiAmount: FormElement;
  Documents: Array<Document>; 
  Images: Array<Document>; 
};

export type serviceRequestData = {
  OpenedBy: FormElement;
  PropertyId : FormElement;
  PropertyAddress : FormElement;
  ServiceType: ServiceType | null;
  Image : Array<Document>;
  Video : Array<Document>;
  Description : FormElement;
};

export type PortfolioRequestBody = {
  ownerId: string | null,
  status : number | null,
  propertyStatus : number | null;
  pageNumber : number,
  pageSize : number,
};

export type MaintananceRequestBody = {
  propertyIds: Array<string>,
  status : number | null,
  openTime : number | null;
  pageNumber : number,
  pageSize : number,
};

export type PortfolioUpdateRequestBody = {
  propertyId: string | null,
  status : number | null
};

export type LandlordStateType = {
  impressionsQuery: {
    skip: number;
    limit: number;
    // q: string;
    // status: string;
    // orderField: string;
    // orderDirection: string;
    userId: string;
    impressionableId: string;
  };
  postsQuery: {
    page: number;
    limit: number;
    q: string;
    status: string;
    orderField: string;
    orderDirection: string;
    userId: string;
  };
  query: {
    limit: number;
    page: number;
    distributorId: string;
    user_type: string;
    q: string;
    orderField: string;
    orderDirection: string;
    createMin: string;
    createMax: string;
    updateMin: string;
    updateMax: string;
  };
  form: {
    property: PropertyForm;
  };
  serviceRequests: Array<ServiceRequestListItemType>;
  selectedServiceRequests: ServiceRequestListItemType;
  propertyListDropdown : {
    items: Array<PropertyListDropdown>;
  },
  list: {
    items: Array<PropertyListItemType>;
    meta: {
      totalItems: number;
      itemCount: number;
      itemsPerPage: number;
      totalPages: number;
      currentPage: number;
    };
    links: {
      first: string;
      last: string;
      previous: string;
      next: string;
    };
  };
  dashboardData : { 
      Occupied : number,
      Vaccant : number,
      OutStandingPayments : number,
      RentalPaidYearToDate : number 
  },
  serviceRequestDashboardData : {
    NewCount : number,
    InProgress : number,
    InReview : number
  }
  loading: boolean;
  error: string;
  links: {
    first: string | null;
    previous: string | null;
    next: string | null;
    last: string | null;
  };
  propertylist:  Array<PortfolioListItemType>,
  editModal: boolean;
  selectedProperty: PropertyListItemType;
  selectedPortfolio: PortfolioListItemType;
  showDownNavBar: { status: boolean; index: number };
  screenName: string | null;
  addProperty: addProperty;
  addedProperty: any;
  addedLease: any;
  addedServiceRequest: any;
  addLease : addLease; 
  serviceRequestData: serviceRequestData;
  PortfolioRequestBody: PortfolioRequestBody; 
  MaintananceRequestBody: MaintananceRequestBody;
  PortfolioUpdateRequestBody: PortfolioUpdateRequestBody;
  leaseDocumentFile : File | undefined;
  leaseDocument : string | null;
  documentUploadUrl : string | null;
  imageUploadUrl : string | null;
  videoUploadedUrl : string | null;
  videoUploadUrl : any | null;
  googleAPIData : any;
  checkStateStatus: string | null;
  checkLeaseState: string | null;
  checkAddedLeaseState: string | null;
  setPropertyStatusCheck: string | null;
  selectedPropertyIdViaDropdown: string | null;
  checkDailog: Boolean;
  requestVideo: string,
  requestThumbnailVideo: string,
  videoFile?: File | null,
  pendingBalance : PendingBalance,
  loggedInUserData : LoggedInUserData,
};

export const LandlordState: LandlordStateType = {
  loggedInUserData : {
    FirstName : "",
    LastName : "",
    Email : "",
    ProfileImageURL : "",
  }, 
  pendingBalance : {
    balance : 0,
  },
  requestVideo: "",
  requestThumbnailVideo: "",
  selectedPropertyIdViaDropdown: "",
  checkDailog:false,
  checkStateStatus: "",
  checkLeaseState: "",
  setPropertyStatusCheck: "",
  checkAddedLeaseState: "",
  propertylist : [],
  PortfolioRequestBody: {
    ownerId: "",
    status : null,
    propertyStatus : null,
    pageNumber : 0,
    pageSize : 100
  },
  
  selectedServiceRequests: {
    ServiceRequestID: "",
    ServiceType: ServiceType.AC,
    PropertyId: "",
    PropertyAddress: "",
    ServiceProviderID: "",
    ServiceProviderName: "",
    CreatedDate: "",
    OpenedBy: "",
    WorkStatus: ServiceStatus.NEW,
    Description: "",
    AcceptedTimeFrame: "",
    VerificationOTP: "",
    EstimatedCost: "",
    Image: "",
    Video: ""    
  },
  MaintananceRequestBody: {
    propertyIds: [],
    status: null,
    openTime: null,
    pageNumber: 0,
    pageSize: 100
  },

  PortfolioUpdateRequestBody: {
    propertyId: "",
    status:null
  },
  addedLease: "",
  addedServiceRequest:"",
  addedProperty : "",
  leaseDocumentFile : undefined,
  googleAPIData : undefined,
  leaseDocument : "",
  documentUploadUrl: "",
  imageUploadUrl:"",
  videoUploadedUrl: "",
  videoUploadUrl: null,
  addLease : {
    LeaseId: { value: ""},
    PropertyId: { value: "" },
    LeaseExpirationDate: { value: moment(new Date()).format("YYYY-MM-DD") },
    ownerId: { value: "" },
    PayDay: { value: "01" },
    RentAmount: { value: "" },
    DepostiAmount: { value: "" },
    Documents: [],
    Images: [],
  },
  serviceRequestData : {
    OpenedBy: {value: "" },
    PropertyId: { value: "" },
    PropertyAddress: {value: ""},
    ServiceType: null,
    Image: [],
    Video: [],
    Description: {value:""}
  },
  addProperty : {
    zip: { value: "" },
    address: { value: "" },
    city: { value: "" },
    state: { value: "" },
    type: { value: "" },
    occupationStatus: { value: "" },
    ownerId: { value: "" },
    ownerName: {value: ""},
    units: { value: 0 },
    street: { value: "" },
    latitude: {value: ""},
    longitude: {value: ""}
  },
  dashboardData : {
    Occupied : 0,
    Vaccant : 0,
    OutStandingPayments : 0,
    RentalPaidYearToDate : 0   
  },
  serviceRequestDashboardData : {
    NewCount : 0,
    InProgress : 0,
    InReview : 0
  },
  impressionsQuery: {
    userId: "",
    skip: 0,
    limit: 10,
    impressionableId: "",
  },
  postsQuery: {
    userId: "",
    page: 1,
    q: "",
    limit: 10,
    orderDirection: "",
    orderField: "",
    status: "",
  },
  query: {
    user_type: "",
    distributorId: "",
    limit: 15,
    page: 1,
    q: "",
    orderField: "",
    orderDirection: "",
    updateMax: "",
    updateMin: "",
    createMax: "",
    createMin: "",
  },
  links: {
    first: "",
    previous: "",
    next: "",
    last: "",
  },
  serviceRequests: [],
  propertyListDropdown: {
    items: []
  },
  list: {
    items: [],
    meta: {
      totalItems: 0,
      itemCount: 0,
      itemsPerPage: 100,
      totalPages: 1,
      currentPage: 1,
    },
    links: {
      first: "",
      previous: "",
      next: "",
      last: "",
    },
  },
  loading: false,
  form: {
    property: {
      id: { value: "" },
      address: { value: "" },
      zipCode: { value: 0 },
      state: { value: "" },
      city: { value: "" },
      type: { value: "" },
      occupationStatus: { value: "" },
      propertyStatus: { value: "" },
      email: { value: "" },
      imageUrl: { value: "" },
      isActive: { value: "" },
      role: { value: "" },
      createdAt: { value: "" },
      updatedAt: { value: "" },
      blockedUntil: { value: "" },
    },
  },
  error: "",
  editModal: false,
  selectedProperty: {
    id: "",
    ownerId: "",
    address: "",
    zipCode: 0,
    state: "",
    city: "",
    type: "",
    occupationStatus: "",
    propertyStatus: "",
    email: "",
    imageUrl: "",
    allocatedUsers: [],
  },
  selectedPortfolio : {
    PropertyId: "",
    OwnerId: "",
    Address: "",
    Latitude: "",
    Longitude: "",
    Zip: "",
    State: "",
    City: "",
    Type: null,
    Status: null,
    Units: null,
    PropertyOccupationStatuses : null,
    Street: "",
    Image: "",
    RentAmount : "",
    DepositAmount : "",
    LeaseExpirationDate : "",
    PayDay : 0,
    AllocatedUsers: []
  },
  showDownNavBar: { status: true, index: 0 },
  screenName: null,
};

export type ContainerState = LandlordStateType;
