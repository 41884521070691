import { Button, InputAdornment, TextField, Typography } from "@mui/material";
import Page from "app/components/Page";
import PageImageHeader from "app/components/PageImageHeader";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "utils/hooks/useWindowDimensions";
import { getLandlordData, selectedProperty, selectError } from "../selectors";
import { useStyles } from "./styles";
import { actions } from "../slice";

const ConfirmLandlord = () => {
  const { width, height } = useWindowDimensions();
  const landLord = useSelector(getLandlordData);
  const property = useSelector(selectedProperty);
  const styles = useStyles();
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const linkedTanentCallback = () => {
    navigation("/welcome-landlord");
  };

  const handleSubmitEvent = () => {
    dispatch(
      actions.LinkTanentToLandLord({
        data: property.PropertyId.value,
        callback: linkedTanentCallback,
      })
    );
  };

  return (
    <Page>
      <PageImageHeader
        subHeading={
          <div style={{ display: "flex" }}>
            <Typography sx={{ fontSize: 14, color: "#fff" }}>
              {"have account"}
            </Typography>
            <Typography
              onClick={() => {
                // navigate("/auth");
              }}
              sx={{
                fontSize: 14,
                color: "#FAA61A",
                fontWeight: "bold",
                textDecoration: "underline",
                marginLeft: "4px",
              }}
            >
              {"login"}
            </Typography>
          </div>
        }
      />

      <div
        style={{
          width: width,

          top: height / 2.82,
        }}
        className={styles.sheet}
      >
        <div className={styles.title}>Confirm landlord</div>

        <div className={styles.subTitle}>
          The property is being maneged with MyProperty under the following
          name:
        </div>
        <div className={styles.card}>
          {/* <img
            src={landLord.profileImageURL ? landLord.profileImageURL : require("../../../assets/photo.png")}
            alt={landLord.firstName+' '+landLord.lastName}
            className={styles.photo}
          /> */}
          <img
            src={require("../../../assets/photo.png")}
            alt={landLord.firstName + " " + landLord.lastName}
            className={styles.photo}
          />
          <div className={styles.clmnView}>
            <div className={styles.phtTitle}>
              {landLord.firstName + " " + landLord.lastName}
            </div>
            <div className={styles.subphtTitle}>
              {landLord.company == null || landLord.company.companyName == null ? "" : landLord.company.companyName}
            </div>
            <div className={styles.subphtTitle}>{property.Address.value}</div>
          </div>
        </div>

        <div className={styles.subTitle}>
          To avoid mistakes in rental payments recipients and thus loss of money
          during the rental time, please carefully confirm that this Landlord
          name is the name appears on your leasing document.
        </div>

        <Button
          type="submit"
          variant="contained"
          sx={{
            width: width / 1.15,
          }}
          disabled={false}
          className={styles.btn}
          onClick={handleSubmitEvent}
        >
          Yes, this is the Landlord
        </Button>

        <Button
          type="submit"
          variant="outlined"
          sx={{
            width: width / 1.15,
          }}
          //   disabled={disable}
          className={styles.outlinebtn}
          onClick={() => {
            navigation("/address", { state: "tenant" });
          }}
        >
          This is not the Landlord
        </Button>
        <div className={styles.subTitle}>
          Please check the address and try again.
        </div>

        <div className={styles.footerTxt}>Do you need help?</div>
        <div className={styles.footerTxt}>
          Contact us at{" "}
          <span className={styles.footTxt}> Support@Sre-Management.com</span>
        </div>
      </div>
      {/* </div> */}
    </Page>
  );
};

export default ConfirmLandlord;
