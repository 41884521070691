import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  outlinebtn:{ fontSize: "16px",alignSelf: "center",marginBottom:24,marginLeft:16,
      marginRight:16,width:'90%',fontWeight: '600', fontFamily: 'Open Sans'},
  subHeading: {
    width: '80%',
    margin: 'auto',
    textAlign: 'center',
    fontSize: '14px !important',
    font: 'inherit',
    marginBottom: '20px'
  },
  sheet: {
    display: "flex",
    flex: 1,
    backgroundColor: "#ffffff",
    position: "absolute",
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    flexDirection: "column",
  },
  title:{
    fontSize: 18,
    fontWeight: "600",
    color: "#192342",
    alignSelf: "center",
    marginTop:'10%',
    fontFamily:"Open Sans",
    marginBottom:6    
  },
  title1: {
    fontSize: 16,
    fontWeight: "600",
    color: "#192342",
    alignSelf: "center",
    marginTop:20,
    fontFamily:"Open Sans",
    marginBottom:6 
  },  
  signInButton: {
    "&:disabled": {
      background: "#C4C4C4",
      color: "#6B6B6B",
    },
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 16px",
    gap: "10px",
    height: "50px",
    background: "#192342",
    color: "#ffffff",
    borderRadius: "8px",
    marginTop: 100,
    marginBottom: 24,
    "&:hover": {
      background: "#192342",
      color: "#ffffff",
    },
  },
  signInButton1: {
    "&:disabled": {
      background: "#C4C4C4",
      color: "#6B6B6B",
    },
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 16px",
    gap: "10px",
    height: "50px",
    background: "#192342",
    color: "#ffffff",
    borderRadius: "8px",
    marginTop: 20,
    marginBottom: 0,
    "&:hover": {
      background: "#192342",
      color: "#ffffff",
    },
  },
  AddOwnerButton: {
    "&:disabled": {
      background: "#ffffff",
      color: "#192342",
    },
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 16px",
    height: "50px",
    background: "#ffffff",
    color: "#192342",
    border: '2px solid #192342',
    borderRadius: "8px",
    marginTop: 20,
    marginBottom: 24,
    "&:hover": {
      background: "#ffffff",
      color: "#192342",
    },
  },
  inputs: {
    padding: "10px 16px",
    width: "100%",
    height: "48px",
    background: "#FFF7DD",
    color: "#7D7D7D",
    font: 'inherit',
    borderRadius: "20px",
    border: "unset",
    margin: "5px 0px 0px 0px",
    "&:focus": {
      outline: "none",
    },

    "&:disabled": {
      background: "#E0E0E0",
      color: "#7D7D7D",
    },
  }, 
  section : {
    border : '1px solid #BCBCBC',
    width: '100%',
    padding: '10px 12px',
    marginBottom: '10px',
    borderRadius: '10px',
    position: 'relative'
  },
  titleName: {
    fontSize: 14,
    fontWeight: "700",
    color: "#192342",
    fontFamily:"Open Sans",
  },  
  ownerButton: {
    background: '#F0BB00',
    width: '180px',
    fontSize: '12px',
    padding: '5px 10px',
    color: "#192342",
    "&:hover": {
      background: "#F0BB00",
      color: "#192342",
    },
  },
  deleteSpan : {
    position: 'absolute',
    right: '10px',
    top: '10px'
  },
  btmTxt: {
    fontSize: 12,
    fontWeight: "400",
    textAlign: "left",
    display: 'flex',
    alignItems: 'flex-start'
  },
  btmTxtOnly: {

  },
});

export default useStyles;
