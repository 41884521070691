import { Box } from "@mui/material";
import React from "react";
import { LoadingIndicator } from "../LoadingIndicator";

type Props = { loading: boolean; className?: string };

const Loader = ({ loading, className }: Props) => {
  return (
    <>
      {loading && (
        <Box
          display={"flex"}
          py={4}
          justifyContent="center"
          sx={{
            background: "rgba(0,0,0,0.5)",
            position: "fixed",
            bottom: 0,
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
            zIndex: 999999,
          }}
        >
          <LoadingIndicator style={{ position: "absolute", top: "48%" }} />
        </Box>
      )}
    </>
  );
};

export default Loader;
