import { FormError } from "../../../../../app/containers/types";
import set from "lodash/set";
import { createSlice } from "../../../../../utils/@reduxjs/toolkit";

import { PayloadAction } from "@reduxjs/toolkit";
import { PortfolioListItemType, PropertyListItemType, PropertyState } from "./types";
import moment from "moment";
import { LoggerClass as Logger } from "utils/logger";

const initialState = PropertyState;

export const propertySlice = createSlice({
  name: "propertyState",
  initialState,
  reducers: {
    editProperty: (state, action: PayloadAction<PropertyListItemType>) => {
      state.form.property.id.value = action.payload.id;
      state.form.property.email.value = action.payload.email;
    },
    resetProperty: (state) => {
      state.addProperty = initialState.addProperty;
    },
    setSelectedProperty: (
      state,
      action: PayloadAction<PropertyListItemType>
    ) => {
      state.selectedProperty.id = action.payload.id;
      state.selectedProperty.ownerId = action.payload.id;
      state.selectedProperty.address = action.payload.address;
      state.selectedProperty.zipCode = action.payload.zipCode;
      state.selectedProperty.state = action.payload.state;
      state.selectedProperty.city = action.payload.city;
      state.selectedProperty.type = action.payload.type;
      state.selectedProperty.occupationStatus = action.payload.occupationStatus;
      state.selectedProperty.propertyStatus = action.payload.propertyStatus;
      state.selectedProperty.email = action.payload.email;
      state.selectedProperty.imageUrl = action.payload.imageUrl;
      state.selectedProperty.allocatedUsers = action.payload.allocatedUsers;
    },
    setSelectedPortfolioDetails: (state, action) => {
      state.loading = true;
    },
    setSelectedPortfolioDetailsSuccess: (state, action) => {
      state.loading = false;
      state.selectedPortfolio = action.payload;
      state.setPropertyStatusCheck = "";
    },
    setSelectedPortfolioDetailsError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    setSelectedPortfolio: (
      state,
      action: PayloadAction<PortfolioListItemType>
    ) => {
      state.selectedPortfolio.PropertyId = action.payload.PropertyId;
      state.selectedPortfolio.OwnerId = action.payload.OwnerId;
      state.selectedPortfolio.Address = action.payload.Address;
      state.selectedPortfolio.Latitude = action.payload.Latitude;
      state.selectedPortfolio.Longitude = action.payload.Longitude;
      state.selectedPortfolio.Zip = action.payload.Zip;
      state.selectedPortfolio.State = action.payload.State;
      state.selectedPortfolio.City = action.payload.City;
      state.selectedPortfolio.Type = action.payload.Type;
      state.selectedPortfolio.Status = action.payload.Status;
      state.selectedPortfolio.PropertyOccupationStatuses = action.payload.PropertyOccupationStatuses;
      state.selectedPortfolio.Image = action.payload.Image;
      state.selectedPortfolio.RentAmount = action.payload.RentAmount;
      state.selectedPortfolio.DepositAmount = action.payload.DepositAmount;
      state.selectedPortfolio.Street = action.payload.Street;
      state.selectedPortfolio.PayDay = action.payload.PayDay;
      state.selectedPortfolio.AllocatedUsers = action.payload.AllocatedUsers;
    },

    setShowBottomNavBar: (
      state,
      action: PayloadAction<{ status: boolean; index: number }>
    ) => {
      state.showDownNavBar = action.payload;
    },
    
    setDashboardData (
      state, action
    ) {
      state.loading = true;
      Logger.log("Correct Slice", state, action.payload);
    },
    
    setDashboardDataSuccess (
      state,
      action: PayloadAction<any>
    ) {
      state.loading = false;
      state.dashboardData = action.payload;
    },

    setDashboardDataError(state, action: PayloadAction<any>) {
      state.error = action.payload;
      state.loading = false;
    },

    resetDashbord(state) {
      state.error = "";
      state.dashboardData = initialState.dashboardData;
    },

    resetError(state) {
      state.error = "";
    },

    setStateStatus(state, action) {
      state.checkStateStatus = action.payload;
    },

    setLeaseState(state, action) {
      state.checkLeaseState = action.payload;
    },

    setDailogState(state, action) {
      state.checkDailog = action.payload;
    },

    create: (state, action) => {
      state.loading = true;
    },
    createSuccess: (state) => {
      state.loading = false;
      state.form.property = initialState.form.property;
    },
    getList: (state) => {
      state.loading = true;
    },
    getPropertyList: (state, action) => {
      state.loading = true;
    },
    getPropertiesListSuccess: (
      state,
      action: PayloadAction<{ items: any; links: any; meta: any }>
    ) => {
      state.loading = false;
      state.list.items = action.payload.items;
      state.list.meta = action.payload.meta;
      state.list.links = action.payload.links;
    },    

    getPortfolioListSuccess: (
      state,
      action: PayloadAction<any>
    ) => {
      state.loading = false;
      state.propertylist = action.payload.Data;
    },
    getPortfolioListError(state, action: PayloadAction<any>) {
      state.error = action.payload;
      state.loading = false;
    },
    getPropertiesDropdownListSuccess: (
      state,
      action: PayloadAction<any>
    ) => {
      state.loading = false;
      state.propertyListDropdown.items = action.payload;
    },

    resetPropertiesDropdownListSuccess: (
      state,
    ) => {
      state.loading = false;
      state.propertyListDropdown.items = initialState.propertyListDropdown.items;
    },

    setDropdownSelectedId : (state, action) => {
      state.selectedPropertyIdViaDropdown = action.payload;
    },
    getPropertiesDropdownListError(state, action: PayloadAction<any>) {
      state.error = action.payload;
      state.loading = false;
    },
    getServices: (state, action) => {
      state.loading = true;
    },
    getServicesSuccess: (
      state,
      action: PayloadAction<any>
    ) => {
      state.loading = false;
      state.serviceRequests = action.payload.Data
    },
    getServicesError(state, action: PayloadAction<any>) {
      state.error = action.payload;
      state.loading = false;
    },
    getAllocatedUsers: (state) => {
      state.loading = true;
    },
    setEditModal: (state, action: PayloadAction<boolean>) => {
      state.editModal = action.payload;
    },
    getPropertyById: (state, action: PayloadAction<{ id: string }>) => {
      state.loading = true;
    },
    deletePropertyById: (state, action: PayloadAction<{ id: string }>) => {
      state.list.items.filter((item) => action.payload.id !== item.id);
    },
    getPropertyByIdSuccess: (
      state,
      action: PayloadAction<{ property: any }>
    ) => {
      state.loading = false;
      state.selectedProperty = action.payload.property;
    },
    setPropertyOccupationStatus: (
      state,
      action: PayloadAction<{ occupationStatus: any }>
    ) => {
      state.loading = true;
      state.selectedProperty.occupationStatus = action.payload.occupationStatus;
    },
    getNextPage: (state, action: PayloadAction<any>) => {
      // state.loading = false;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    update: (
      state,
      action: PayloadAction<{ id: any; callback: () => void }>
    ) => {
      state.error = "";
      state.loading = true;
    },
    updateFormValue: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      set(state.form, `${action.payload.key}.value`, action.payload.value);
      set(state.form, `${action.payload.key}.error`, null);
      state.error = "";
    },
    updateSuccess(state, action: PayloadAction<PropertyListItemType>) {
      state.loading = false;
      const index = state.list.items.findIndex(
        (item) => item.id === action.payload.id
      );
      if (index >= 0) {
        state.list.items[index] = action.payload;
      }
    },
    updateFailed: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    delete: (state, action) => {},
    deleteSuccess: (state, action) => {
      state.list.items = state.list.items.filter(
        (item) => item.id !== action.payload
      );
    },
    deleteFailed: (state, action) => {
      state.error = action.payload;
      state.loading = false;
    },
    resetNewPropertyForm(state) {
      state.error = "";
      state.form = initialState.form;
    },
    resetPropertyListFromDropDown(state) {
      state.error = "";
      state.list = initialState.list;
      state.addLease = initialState.addLease;
    }, 
    setFormErrors(
      state,
      action: PayloadAction<{
        key: string;
        errors: FormError[];
      }>
    ) {
      action.payload.errors.forEach((error) => {
        set(
          state.form,
          `${action.payload.key}.${error.name}.error`,
          error.error
        );
      });
    },
    getNextPageItems: (state, action: PayloadAction<any>) => {
      state.loading = true;
    },
    getNextPageItemsSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.list.items = state.list.items.concat(action.payload.items);
      state.list.meta.currentPage = action.payload.meta.currentPage;
      state.list.links = action.payload.links;
      state.query.page = action.payload.meta.currentPage;
    },
    setQuery: (state, action: PayloadAction<{ name: string; value: any }>) => {
      const { name, value } = action.payload;
      state.query.page = 1;
      state.list = initialState.list;
      set(state.query, name, value);
    },
    resetModule: (state) => {
      state = initialState;
    },
    navigateRouteScreen: (state, action: PayloadAction<{ status: string }>) => {
      const { status } = action.payload;

      state.screenName = status;
    },
    handleBlock: (state, action: PayloadAction<string>) => {},
    setLeaseData: (  state,
      action: PayloadAction<{ name: string; value: string, form?: string }>
    ) => {
      const form = action.payload.form ? action.payload.form : 'form';
      const { name, value } = action.payload;      
      set(state.addLease, `${name}.value`, value);
      set(state.addLease, `${name}.error`, "");
    },
    resetLeaseData: (state) => {
      state.addLease = initialState.addLease;
    },
    setPropertyData: (  state,
      action: PayloadAction<{ name: string; value: string, form?: string }>
    ) => {
      const form = action.payload.form ? action.payload.form : 'form';
      const { name, value } = action.payload;      
      set(state.addProperty, `${name}.value`, value);
      set(state.addProperty, `${name}.error`, "");
    },
    resetPropertyData: (state) => {
      state.addProperty = initialState.addProperty;
    },
    addPropertyData: (state, action: PayloadAction<any>) => {
      state.loading = true;
    },
    addPropertyDataSuccess: (state, action: PayloadAction<any>) => {
      //setdata when added property
      state.addedProperty = action.payload.Data;
      state.loading = false;
    },
    addPropertyDataError(state, action: PayloadAction<any>) {
      state.error = action.payload;
      state.loading = false;
    },
    addPropertyFormDataError(state,
      action: PayloadAction<{
        key: string;
        errors: Array<FormError>;
      }>) {
        action.payload.errors.forEach((error) => {
          set(state.addProperty, `${error.name}.error`, error.error);
        });
      },
      setImageFile(state, action) {
        state.leaseDocumentFile = action.payload.file
        state.leaseDocument = action.payload.file.url
      },       
      getAllDataViaplaceId(state, action) {
        state.loading = true;
      },
      getAllDataViaplaceIdSuccess(state, action) {
        state.loading = false;
        state.googleAPIData = action.payload;
      },
      
      getDocumentUploadUrl(state, action) {
        state.loading = true;
      },
      getDocumentUploadUrlSuccess(state, action) {
        state.loading = false;
        if (action.payload.type === "properties/images") {
          state.imageUploadUrl = action.payload.response;
        } else {
          state.documentUploadUrl = action.payload.response;
        }
      },
      getDocumentUploadUrlError(state, action) {
        state.error = action.payload;
        state.loading = false;
      },

      getPropertyListViaOwnerId(state) {
        state.loading = true;
      },

      getLeaseDataByPropertyId: (state, action: PayloadAction<any>) => {
        state.loading = true;
      },
      getLeaseDataByPropertyIdSuccess: (state, action: PayloadAction<any>) => {
        state.addLease.LeaseId.value = action.payload.LeaseId;
        state.addLease.LeaseExpirationDate.value = moment(action.payload.LeaseExpirationDate).format("YYYY-MM-DD");
        state.addLease.PayDay.value = Number(action.payload.PayDay) < 10 ? '0'+action.payload.PayDay : action.payload.PayDay;
        state.addLease.RentAmount.value = action.payload.RentAmount;
        state.addLease.DepostiAmount.value = action.payload.DepostiAmount;
        state.addLease.Documents = action.payload.Documents;
        state.addLease.Images = action.payload.Images;
        state.loading = false;
      },

      resetLeaseDocument: (state) => {
        state.addLease.Documents[0].IsActive = false;
      },
      getLeaseDataByPropertyIdError(state, action: PayloadAction<any>) {
        state.error = action.payload;
        state.loading = false;
      },      
      // resetLeaseData: (state) => {
      //   state.addLease = state.addLease;
      // }, 
      
      getServiceRequestDashboardCount: (state, action: PayloadAction<any>) => {
        state.loading = true;
      },
      getServiceRequestDashboardCountSuccess: (state, action: PayloadAction<any>) => {
        state.serviceRequestDashboardData = action.payload.Data;
        state.loading = false;
      },
      getServiceRequestDashboardCountError(state, action: PayloadAction<any>) {
        state.error = action.payload;
        state.loading = false;
      },
      setSelectedServiceRequestStatusType: (state, action: PayloadAction<any>) => {
        state.selectedServiceRequests.WorkStatus = action.payload;
      },
      setSelectedServiceRequest: (state, action: PayloadAction<any>) => {
        state.loading = true;
      },
      setSelectedServiceRequestSuccess: (state, action: PayloadAction<any>) => {
        state.selectedServiceRequests = action.payload.Data;
        state.loading = false;
      },
      setSelectedServiceRequestError(state, action: PayloadAction<any>) {
        state.error = action.payload;
        state.loading = false;
      },

      createServiceRequest: (state, action: PayloadAction<any>) => {
        state.loading = true;
      },
      createServiceRequestSuccess: (state, action: PayloadAction<any>) => {
        state.addedServiceRequest = action.payload.Data;
        state.loading = false;
      },
      createServiceRequestError(state, action: PayloadAction<any>) {
        state.error = action.payload;
        state.loading = false;
      },
      addLeaseData: (state, action: PayloadAction<any>) => {
        state.loading = true;
      },
      addLeaseDataSuccess: (state, action: PayloadAction<any>) => {
        state.addedLease = action.payload.Data;
        state.loading = false;
      },
      addLeaseDataError(state, action: PayloadAction<any>) {
        state.error = action.payload;
        state.loading = false;
      },
      setpropertyRequest: (  state,
        action: PayloadAction<{ name: string; value: string, form?: string }>
      ) => {
        const form = action.payload.form ? action.payload.form : 'form';
        const { name, value } = action.payload;      
        set(state.PortfolioRequestBody, `${name}`, value);
      },
      setpropertyUpdateRequest: (  state,
        action: PayloadAction<{ name: string; value: string, form?: string }>
      ) => {
        const form = action.payload.form ? action.payload.form : 'form';
        const { name, value } = action.payload;      
        set(state.PortfolioUpdateRequestBody, `${name}`, value);
      },
      updateLeaseData: (state, action: PayloadAction<any>) => {
        state.loading = true;
      },
      updateLeaseDataSuccess: (state, action: PayloadAction<any>) => {
        state.addedLease = action.payload.Data;
        state.loading = false;
      },
      updateLeaseDataError(state, action: PayloadAction<any>) {
        state.error = action.payload;
        state.loading = false;
      },      
      updatePropertyStatus: (state, action: PayloadAction<any>) => {
        state.loading = true;
      },
      updatePropertyStatusSuccess: (state, action: PayloadAction<any>) => {
        state.selectedPortfolio = action.payload.Data;
        state.setPropertyStatusCheck = "Updated";
        state.loading = false;
      },
      setPropertyStatusCheck: (state, action: PayloadAction<any>) => {
        state.setPropertyStatusCheck = action.payload.Data;
      },

      checkAddedLeaseState: (state, action: PayloadAction<any>) => {
        state.checkAddedLeaseState = action.payload;
      },

      updatePropertyStatusError(state, action: PayloadAction<any>) {
        state.error = action.payload;
        state.loading = false;
      },

      setMaintainanceProperty: (state, action: PayloadAction<any>) => {
        state.MaintananceRequestBody.propertyIds = action.payload;        
      },

      ServiceRequestData: (  state,
        action: PayloadAction<{ name: string; value: string, form?: string }>
      ) => {
        const form = action.payload.form ? action.payload.form : 'form';
        const { name, value } = action.payload;      
        set(state.serviceRequestData, `${name}.value`, value);
        set(state.serviceRequestData, `${name}.error`, "");
      },
      resetServiceRequestData: (state) => {
        state.serviceRequestData = initialState.serviceRequestData;
      },
      ServiceRequestTypeData: (state, action: PayloadAction<any>) => {
        state.serviceRequestData.ServiceType = action.payload;
      },
      setVideoFile(state, action) {
        state.videoFile = action.payload.file
        state.requestThumbnailVideo = action.payload.file.url
        state.requestVideo = action.payload.file.url
        //state.loading = true;
      },
      resetVideoFile: (state) => {
        state.videoFile = initialState.videoFile;
      },
      restMaintananceRequest: (state) => {
        state.MaintananceRequestBody = initialState.MaintananceRequestBody;
      },
      getUploadUrl(state, action) {
        state.loading = true;
      },
      getUploadUrlSuccess(state, action) {
        state.loading = false;
        state.videoUploadUrl = action.payload;
      },
      resetUploadedUrl: (state) => {
        state.videoUploadUrl = initialState.videoUploadUrl;        
        state.imageUploadUrl = initialState.imageUploadUrl;
        state.videoFile = initialState.videoFile;
      },
      updateVideoUrl(state, action) {
        state.videoUploadedUrl = action.payload;
      },
      getUploadUrlError(state, action: PayloadAction<any>) {
        state.error = action.payload;
        state.loading = false;
      },
      startLoading(state) {
        state.loading = true;
      },
      stopLoading(state) {
        state.loading = false;
      },
  },
});

export const { reducer, actions, name: sliceKey } = propertySlice;
