import {
  Button,
  Typography,
  IconButton,
  InputAdornment,
  TextField,
  Box,
  Grid,
  Dialog,
  Select,
  MenuItem,
  Radio,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import { actions, reducer, sliceKey } from "../../../redux/slice";
import { useLandlordSaga } from "../../../redux/saga";
import { style } from "@mui/system";
import { useStyles } from "../../styles";
import OtpInput from "react-otp-input";

type Props = {};
const OtpVerify = ({ showPopup, closePopup, handleVerify = () => {} }) => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: useLandlordSaga });
  const styles = useStyles();
  const [show, setShow] = useState(false);
  const [reportType, setReportType] = useState<any>(null);
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [timeRange, setTimeRane] = useState("");
  const [error, setError] = useState(false);
  const [otp, setOtp] = useState("");
  const [disable, setDisable] = useState(true);

  const close = () => {
    setShow(false);
    closePopup();
  };

  useEffect(() => {
    if (otp?.length === 4) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [otp?.length]);

  useEffect(() => {
    setShow(showPopup);
  }, [showPopup]);

  const handleReportTypeChange = (event) => {
    const option = event.target.value;
    setReportType(option);
  };

  const handleChange = (otp) => {
    // dispatch(actions.setOtp(otp));
    // if (otp.length === 4) {
    //   dispatch(
    //     actions.verifyOtpRequest({
    //       callback: (url = "/thankyou") => {
    //         dispatch(actions.setOtp(""));
    //         navigate(url);
    //       },
    //     })
    //   );
    // }
    setOtp(otp);
  };

  return (
    <Dialog open={show} className={styles.popup}>
      <Box sx={{ display: "flex", flexDirection: "column", margin: "16px" }}>
        <Typography className={styles.modalTitle}>Verify Phone Number</Typography>
        <Typography className={styles.modalSubTitle}>
          The code has been sent to the phone number + 1 465 687 0099
        </Typography>
        <div
          style={{
            marginLeft: 20,
            marginRight: 15,
            marginTop: 10,
            marginBottom: 16,
          }}
        >
          <OtpInput
            inputStyle={{
              height: 40,
              width: 40,
              backgroundColor: "#FFF7DD",
              justifyContent: "center",
              display: "flex",
              color: "#192342",
              fontSize: 16,
              borderRadius: 4,
              marginRight: 5,
              // position: "absolute",
            }}
            hasErrored={error}
            errorStyle={{ border: "1px solid #EB5757" }}
            focusStyle={{ backgroundColorL: "red" }}
            value={otp}
            className={styles.otpInputRoot}
            onChange={handleChange}
            numInputs={4}
            isInputNum={true}
            //   isDisabled={timerEnded}
            //   isInputNum
          />
        </div>
        <div className={styles.footerTxt}>
          Didn’t receive the code?
          <span className={styles.footTxt}> Request again</span>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: 14,
          }}
        >
          <Button
            // disabled={reportType ? false : true}
            className={styles.outlinebtn}
            onClick={close}
            style={{ marginLeft: 0 }}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            className={styles.signInButton}
            disabled={disable}
            onClick={handleVerify}
          >
            Verify
          </Button>
        </div>
      </Box>
    </Dialog>
  );
};

export default OtpVerify;
