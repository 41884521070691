import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
    container:{ display: "flex", flexDirection: "column" },
  sheet: {
    display: "flex",
    flex: 1,
    backgroundColor: "#ffffff",
    position: "absolute",
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    flexDirection: "column",
  },
  logo: {
    height: 77,
    width: 144,
    marginTop: 24,
    alignSelf: "center",
    marginBottom: 16,
  },
  title: {
    fontSize: 20,
    fontWeight: "700",
    color: "#F0BB00",
    alignSelf: "center",
  },
  subtitle: {
    fontSize: 18,
    color: "#192342",
    fontWeight: "600",
    marginTop: 16,
    alignSelf: "center",
    marginBottom: 10,
  },
  btn: {
    fontSize: "14px",
    fontWeight: 700,
    alignSelf: "center",
    padding: "12px 0",
    borderRadius: "10px",
    backgroundColor: "#192342",
    margin: "30px 0px",
    color: "#FFFFFF",
  },
  contentTxt: {
    marginTop: 10,
    fontSize: 14,
    fontWeight: "400",
    alignSelf: "center",
    color: "#192342",
  },
  imgView: {
    flexDirection: "row",
    flex: 1,
    justifyContent: "center",
    display: "flex",
    marginTop: 16,
  },
  icon: { height: 36, width: 36 },
  btmTxt: {
    fontSize: 16,
    fontWeight: "400",
    alignSelf: "center",
    marginTop: 24,
    // marginBottom: 40,
  },
  span: { color: "#F0BB00", fontWeight: "700" },
  signInButton: {
    "&:disabled": {
      background: "#C4C4C4",
      color: "6B6B6B",
    },
    display: "flex",
    flexDirection: "row",
    width: "95%",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 16px",
    gap: "10px",
    height: "50px",
    background: "#192342",
    color: "#ffffff", 
    borderRadius: "8px",
    margin: "40px 10px 0px 10px ",
    "&:hover": {
      background: "#192342",
      color: "#ffffff",
    },
  },
});
