import { DisabledByDefaultOutlined } from "@mui/icons-material";
import { Button, Typography, TextField, Box, Dialog } from "@mui/material";
import React, { useEffect, useState } from "react";
import useStyles from "./styles";
const PropertyReportDialog = ({ showPopup, closePopup, address }) => {
  const styles = useStyles();
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState("");
  const [buttonEnable, setButtonEnable] = useState(false);
  const [okScreen, setOkScreen] = useState(false);
  const close = () => {
    setShow(false);
    closePopup();
  };
  const showNext = () => {
    setOkScreen(true);
  };
  useEffect(() => {
    setShow(showPopup);
  }, [showPopup]);
  useEffect(() => {}, [showNext]);

  useEffect(() => {
    var emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    if (email.length === 0 || email === "" || !emailRegex.test(email)) {
      setButtonEnable(false);
    } else {
      setButtonEnable(true);
    }
  }, [email]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  return (
    <Dialog open={show} className={styles.popup}>
      <Box sx={{ display: "flex", flexDirection: "column", margin: "16px" }}>
        <Typography className={styles.title}>
          Report Generation for property
        </Typography>
        <Typography sx={{ margin: "10px 0px" }} className={styles.address}>
          {address}
        </Typography>
        {!okScreen && (
          <>
            <Typography
              sx={{ margin: "16px 0px 10px 0px" }}
              className={styles.typo40014}
            >
              Enter Email adress to which the report will be sent.
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                className={styles.typo60014}
                sx={{ marginLeft: "16px" }}
              >
                Email
              </Typography>
              <TextField
                className={styles.inputSpan}
                placeholder="Enter Email"
                value={email}
                onChange={handleEmailChange}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Box sx={{ display: "flex", width: "48%" }} onClick={close}>
                <Button className={styles.signInButtonColorInvert}>
                  Cancel
                </Button>
              </Box>
              <Box sx={{ display: "flex", width: "48%" }}>
                <Button
                  disabled={!buttonEnable}
                  className={styles.signInButton}
                  onClick={showNext}
                >
                  Continue
                </Button>
              </Box>
            </Box>
          </>
        )}
        {okScreen && (
          <>
            {" "}
            <Typography
              sx={{ marginBottom: "16px" }}
              className={styles.typo40014}
            >
              The report is being generated and will be sent to the email
              {" " + email + " "}
              in 10 minutes.
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button
                className={styles.signInButton}
                sx={{ width: "40% !important" }}
                onClick={close}
              >
                OK
              </Button>
            </Box>
          </>
        )}
      </Box>
    </Dialog>
  );
};

export default PropertyReportDialog;
