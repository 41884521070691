import moment from "moment";
import { FormElement } from "../types";

export type CreateAccountSRE = {
  email: FormElement;
  password: FormElement;
  confirmPassword: FormElement;
  role: FormElement;
  name: FormElement;
  landLordType: FormElement;
};

export type VerificationForm = {
  phoneNumber: FormElement;
  emailCheck: FormElement;
  phoneCheck: FormElement;
};

export type ThresholdForm = {
  thresholdAmount: FormElement;  
};

export type selectedProperty = {
  PropertyId: FormElement;
  Zip: FormElement;
  Address: FormElement;
  City: FormElement;
  State: FormElement;
  Type: FormElement;
  Status: FormElement;
  OwnerId: FormElement;
  IsIDVerified: FormElement;
  LeaseExpirationDate: FormElement;
  PayDay: FormElement;
  DepostiAmount: FormElement;
  RentAmount: FormElement;
  Units: FormElement;
  Street: FormElement;
};

export type landLordData = {
  userId: FormElement;
  firstName: FormElement;
  lastName: FormElement;
  email: FormElement;
  mobile: FormElement;
  isMobileVerified: FormElement;
  createdAt: FormElement;
  profileImageURL: FormElement;
  isActive: FormElement;
  role: FormElement;
  company: any;
};

export type tanentLandLordAssoData = {
  tenantPropAssocId: FormElement;
  tenantId: FormElement;
  propertyId: FormElement;
  isActive: FormElement;
  fromDate: FormElement;
  toDate: FormElement;
};

export type addProperty = {
  zip: FormElement;
  address: FormElement;
  city: FormElement;
  state: FormElement;
  type: FormElement;
  occupationStatus: FormElement;
  ownerId: FormElement;
  ownerName: FormElement;
  units: FormElement;
  street: FormElement;
  latitude: FormElement;
  longitude: FormElement;
};

export type Document = {
  DocumentId: FormElement,
  PropertyId: FormElement,
  DocumentURL: string,
  Type : FormElement,
  IsActive: Boolean
}

export type addLease = {
  LeaseId: FormElement;
  PropertyId: FormElement;
  LeaseExpirationDate: FormElement;
  ownerId: FormElement;
  PayDay: FormElement;
  RentAmount: FormElement;
  DepostiAmount: FormElement;
  Documents: Array<Document>;
  Images: Array<Document>;
};

export type CreateAccountForm = {
  firstName: FormElement;
  lastName: FormElement;
  dateOfBirth: FormElement;
  email: FormElement;
  phone: FormElement;
  gender: FormElement;
  acceptPrivacy: FormElement;
  acceptTerms: FormElement;
};
export type LoginForm = {
  email: FormElement;
  password: FormElement;
};
/* --- STATE --- */

export type Auth = {
  tanentLandLordAsso: tanentLandLordAssoData;
  landLordData: landLordData;
  form: CreateAccountForm;
  createAccount: CreateAccountSRE;
  selectedProperty: selectedProperty;
  otp: FormElement;
  verification: VerificationForm;
  thresholdForm : ThresholdForm;
  error: string | null;
  loading: boolean;
  user: SREUser;
  token: string;
  loginForm: LoginForm;
  type: string;
  invalidAttempts: {
    attempts: number | string;
    duration: string;
  };
  userType: string | null;
  propertyList: Array<any> | any;
  company: string;
  addProperty: addProperty;
  addedProperty: any;
  addLease: addLease;
  addedLease: any;
  uploadedImageURL: string;
  uploadedDocumentURL: string;
  uploadedDocFile: File | undefined | null;
  uploadedImageFile: File | undefined | null;
};
export const initialAuthState: Auth = {
  addedProperty: "",
  addedLease: "",
  addProperty: {
    zip: { value: "" },
    address: { value: "" },
    city: { value: "" },
    state: { value: "" },
    type: { value: "" },
    occupationStatus: { value: "" },
    ownerId: { value: "" },
    ownerName: {value: ""},
    units: { value: 0 },
    street: { value: "" },
    latitude: { value: "" },
    longitude: { value: "" },
  },
  addLease: {
    LeaseId: { value: "" },
    PropertyId: { value: "" },
    LeaseExpirationDate: { value: moment(new Date()).format("YYYY-MM-DD") },
    ownerId: { value: "" },
    PayDay: { value: "01" },
    RentAmount: { value: "" },
    DepostiAmount: { value: "" },
    Documents: [],
    Images: [],
  },
  propertyList: [],
  form: {
    firstName: { value: "" },
    lastName: { value: "" },
    dateOfBirth: { value: "" },
    email: { value: "" },
    phone: { value: "" },
    gender: { value: "" },
    acceptPrivacy: { value: false },
    acceptTerms: { value: false },
  },
  verification: {
    phoneNumber: { value: "" },
    emailCheck: { value: false },
    phoneCheck: { value: false },
  },
  thresholdForm: {
    thresholdAmount: { value: "" }
  },
  createAccount: {
    email: { value: "" },
    password: { value: "" },
    confirmPassword: { value: "" },
    role: { value: "tenant" },
    name: { value: "" },
    landLordType: {value: ""}
  },
  selectedProperty: {
    PropertyId: { value: "" },
    Zip: { value: "" },
    Address: { value: "" },
    City: { value: "" },
    State: { value: "" },
    Type: { value: "" },
    Status: { value: "" },
    OwnerId: { value: "" },
    IsIDVerified: { value: false },
    LeaseExpirationDate: { value: "" },
    PayDay: { value: "" },
    DepostiAmount: { value: "" },
    RentAmount: { value: "" },
    Units: { value: "" },
    Street: { value: "" },
  },
  landLordData: {
    userId: { value: "" },
    firstName: { value: "" },
    lastName: { value: "" },
    email: { value: "" },
    mobile: { value: "" },
    isMobileVerified: { value: false },
    createdAt: { value: "" },
    profileImageURL: { value: "" },
    isActive: { value: false },
    role: { value: "" },
    company: {
      companyId: { value: "" },
      ownerId: { value: "" },
      companyName: { value: "" },
      createdAt: { value: "" },
    },
  },
  tanentLandLordAsso: {
    tenantPropAssocId: { value: "" },
    tenantId: { value: "" },
    propertyId: { value: "" },
    isActive: { value: "" },
    fromDate: { value: "" },
    toDate: { value: "" },
  },
  loginForm: {
    email: { value: "" },
    password: { value: "" },
  },
  otp: { value: "" },
  error: "",
  invalidAttempts: {
    attempts: 0,
    duration: "",
  },
  loading: false,
  type: "",
  userType: "tenant",
  user: {
    userId: "",
    userEmail: "",
    firstName: "",
    lastName: "",
    role: "",
    jwtToken: localStorage.getItem("sessionToken") || "",
    expiresIn: 0,
    isPhoneNoVerified: false,
    isActive: true,
  },
  token: localStorage.getItem("sessionToken") || "",
  company: "",
  uploadedImageURL: "",
  uploadedDocumentURL: "",
  uploadedDocFile: null,
  uploadedImageFile: null,
};

export type SREUser = {
  userId: string | null;
  userEmail: string | null;
  firstName: string | null;
  lastName: string | null;
  role: string | null;
  jwtToken: string;
  expiresIn: number | null;
  isPhoneNoVerified: boolean | null;
  isActive: boolean | null;
};

export enum LoginErrorType {
  USERNAME_EMPTY = 10,
  PASSWORD_EMPTY = 20,
  USER_NOT_FOUND = 404,
  SUCCESS = 200,
  UNAUTHORIZED = 400,
  ERROR = 500,
  NO_PERMISSIONS = 401,
}

/*
  If you want to use 'ContainerState' keyword everywhere in your feature folder,
  instead of the 'HomePageState' keyword.
*/
export type ContainerState = Auth;
