import { BusinessType } from "app/containers/Auth/CreateBusinessAccount/redux/enum";
import { FormElement } from "../../../types";

export type BusinessDetails = {
  FirstName : string; 
  LastName : string;
  BusinessName : string;
  Email : string;
  Phone : string;
  ThresholdAmt : string;
  BusinessEIN : string;
  BusinessType : number;
  IsIndividual : boolean;
};

export type ChangeMobileRequest = {
  phoneNumber : FormElement,
  otp : FormElement,
  emailCheck: FormElement;
  phoneCheck: FormElement;
}

export type LandlordProfileType = {
  error: string | null;
  loading: boolean; 
  businessDetails : BusinessDetails;
  changeMobileRequest : ChangeMobileRequest;
};
export const LandlordProfileState: LandlordProfileType = {
  error: "",
  loading: false,
  businessDetails : {
    FirstName: "",
    LastName : "",
    BusinessName : "",
    Email : "",
    Phone : "",
    ThresholdAmt : "",
    BusinessEIN : "",
    BusinessType : BusinessType.LLCS,
    IsIndividual : false,
  },
  changeMobileRequest : {
    phoneNumber : {value : ""},
    otp : { value : ""},
    emailCheck : {value : ""},
    phoneCheck : {value : ""}
  } 
};

export type ContainerState = LandlordProfileType;
