import { Box, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import stepThreeImage from "../../assets/step3.png";
import useStyle from "../../styles";
import { actions } from "app/containers/Tenant/redux/slice";
import { useDispatch, useSelector } from "react-redux";
import { selectServiceRequestData } from "app/containers/Tenant/redux/selector";
import { ServiceType } from "app/containers/LandlordAccount/redux/Landlord.enum";

const StepThreeComponent = ({ selectedService, descriptionProp }) => {
  const styles = useStyle();
  const dispatch = useDispatch();
  const serviceRequestData = useSelector(selectServiceRequestData);
  const [textValue, setTextValue] = useState(descriptionProp);

  const serviceType = (type) => { 
    switch (type) {
      case ServiceType.AC:
        return 'A/C';
      case ServiceType.ELECTRICAL:
        return 'Electrical';
      case ServiceType.HANDYMAN:
        return 'Handyman';
      case ServiceType.LOCKSMITH:
        return 'LockSmith';
      case ServiceType.PLUMBLING:
        return 'Plumbling';
      case ServiceType.STRUCTURE_SERVICE:
        return 'Structure Service';
      default:
        return '';
    }
  }
  
  useEffect(() => {
    if (textValue.length > 0) {
      selectedService({ status: false, description: textValue });
    } else {
      selectedService({ status: true, description: textValue });
    }
    dispatch(actions.ServiceRequestData({ name: 'Description', value : textValue, form: "addService" }));

  }, [textValue]);
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "24px",
        }}
      >
        <img
          src={stepThreeImage}
          style={{ width: "80px", height: "80px" }}
          alt="Step 2"
        />
      </Box>
      <Typography
        sx={{ marginBottom: "10px" }}
        className={styles.typo60018AlignCenter}
      >
        Free text Description
      </Typography>
      <Typography sx={{ marginBottom: "24px" }} className={styles.typo40014}>
        To help us understand the {serviceType(serviceRequestData.ServiceType)} issue better, please provide free text
        description.
      </Typography>
      <Box sx={{ width: "100%" }}>
        <Typography className={styles.typo60014}>
          Description of the {serviceType(serviceRequestData.ServiceType)} issue
        </Typography>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "160px",
            padding: "10px",
            background: "#FFF7DD",
            borderRadius: "20px",
            margin: "5px 0px 3px 0px",
          }}
        >
          <TextField
            className={styles.textBox}
            placeholder="Enter description"
            value={textValue}
            name="Description"
            multiline
            inputProps={{ maxLength: 1000 }}
            onChange={(e) => {
              setTextValue(e.target.value);
            }}
          ></TextField>
        </Box>
        <Typography className={styles.typo40010} sx={{ marginBottom: "24px" }}>
          {"Characters limit: " + textValue.length + "/1000"}
        </Typography>
      </Box>
    </Box>
  );
};
export default StepThreeComponent;
