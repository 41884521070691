import Header from "app/components/Header";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import BottomTenantTabs from "../BottomTabs";
import useStyles from "./styles";
type Props = {};
const Accounting = (props: Props) => {
  const styles = useStyles();
  const [index, setIndex] = useState(1);
  const navigation = useNavigate();
  return (
    <>
      <div className={styles.container}>
        <Header
          rightIcon={true}
          handleNavigation={() => {
            navigation("/tenant-account/tenant-profile", {
              state: "/tenant-account/accounting",
            });
          }}
        />

        <div className={styles.outstandingBox}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img
              src={require("../../../../app/assets/redWallet.png")}
              className={styles.redWallet}
              alt=""
            />
          </div>
          <div className={styles.outstanding}>You have outstanding payment</div>
          <div className={styles.outstandingDollar}>1000$</div>
          <div className={styles.due}>Past due on 14 days</div>
          <div className={styles.payNowButton}>
            <div className={styles.payNowText} onClick={()=>{navigation('/tenant-account/pay-my-rent')}}>Pay now</div>
          </div>
        </div>
        <div className={styles.title}>Unpaid invoices</div>
        <div className={styles.monthBox}>
          <div className={styles.overDueButton}>
            <div className={styles.overDueText}>Overdue</div>
          </div>
          <div className={styles.warningRow}>
            <img
              src={require("../../../../app/assets/warning.png")}
              className={styles.image}
              alt=""
            />
            <div style={{ paddingLeft: 6 }}>
              <div className={styles.monthRow}>
                <div className={styles.monthText}>August rental payment </div>
                <img
                  src={require("../../../../app/assets/rightArrow.png")}
                  className={styles.image}
                  alt=""
                />
              </div>
              <div className={styles.address}>
                3429 Adkins Rd. Nw Atlanta, GA 30331
              </div>
            </div>
          </div>
          <div className={styles.detailsRow}>
            <div className={styles.detailsBox}>
              <div className={styles.invoiceDate}>Invoice date</div>
              <div className={styles.date}>14/08/2022</div>
            </div>
            <div className={styles.detailsBox}>
              <div className={styles.invoiceAmount}>Invoice amount</div>
              <div className={styles.date}>1500$</div>
            </div>
            <div className={styles.detailsBox}>
              <div className={styles.invoiceAmount}>Paid amount</div>
              <div className={styles.date}>500$</div>
            </div>
          </div>
        </div>

        <div className={styles.monthBox}>
          <div className={styles.unpaidButton}>
            <div className={styles.overDueText}>Unpaid</div>
          </div>
          <div className={styles.warningRow}>
            <img
              src={require("../../../../app/assets/exclaim.png")}
              className={styles.image}
              alt=""
            />
            <div style={{ paddingLeft: 6 }}>
              <div className={styles.monthRow}>
                <div className={styles.monthRental}>
                  September rental payment
                </div>
                <img
                  src={require("../../../../app/assets/rightArrow.png")}
                  className={styles.image}
                  alt=""
                />
              </div>
              <div className={styles.address}>
                3429 Adkins Rd. Nw Atlanta, GA 30331
              </div>
            </div>
          </div>
          <div className={styles.detailsRow}>
            <div className={styles.detailsBox}>
              <div className={styles.invoiceAmount}>Invoice date</div>
              <div className={styles.date}>14/08/2022</div>
            </div>
            <div className={styles.detailsBox}>
              <div className={styles.invoiceAmount}>Invoice amount</div>
              <div className={styles.date}>1500$</div>
            </div>
            <div className={styles.detailsBox}>
              <div className={styles.invoiceAmount}>Paid amount</div>
              <div className={styles.date}>500$</div>
            </div>
          </div>
        </div>

        <div className={styles.title1Row}>
          <div className={styles.title1}>Transactions</div>
          <div className={styles.downloadRow}>
            <img
              src={require("../../../../app/assets/download.png")}
              className={styles.download}
              alt=""
            />
            <div className={styles.downloadReceipts}>Download All Receipts</div>
          </div>
        </div>
        <div className={styles.box}>
          <div className={styles.monthDateRow}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <img
                src={require("../../../../app/assets/greenTick.png")}
                className={styles.image}
                alt=""
              />
              <div style={{ paddingLeft: 6 }}>
                <div className={styles.monthRow}>
                  <div className={styles.monthRental1}>July rental payment</div>

                  <img
                    src={require("../../../../app/assets/blackDownload.png")}
                    className={styles.image}
                    alt=""
                  />
                </div>
                <div className={styles.confirmRow}>
                  <div className={styles.confirmText}>Confirmation</div>
                  <div className={styles.confirmId}>#325884965</div>
                </div>
              </div>
            </div>

            <div className={styles.confirmId}>14/07/2022</div>
          </div>

          <div className={styles.rectBox}>
            <div className={styles.rectBoxRow}>
              <div className={styles.payee}>Payee</div>
              <div className={styles.date}>Michael Jackson </div>
            </div>
          </div>

          <div className={styles.rectBox}>
            <div className={styles.rectBoxRow}>
              <div className={styles.payee}>Payment Method</div>
              <div className={styles.date}>Venmo</div>
            </div>
          </div>

          <div className={styles.rectBox}>
            <div className={styles.rectBoxRow}>
              <div className={styles.payee}>Transaction amount</div>
              <div className={styles.date}>1500$</div>
            </div>
          </div>
        </div>

        <div className={styles.box}>
          <div className={styles.monthDateRow}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
              }}
            >
              <img
                src={require("../../../../app/assets/greenTick.png")}
                className={styles.image}
                alt=""
              />
              <div style={{ paddingLeft: 6 }}>
                <div className={styles.monthRow}>
                  <div className={styles.monthRental1}>July rental payment</div>

                  <img
                    src={require("../../../../app/assets/blackDownload.png")}
                    className={styles.image}
                    alt=""
                  />
                </div>
                <div className={styles.confirmRow}>
                  <div className={styles.confirmText}>Confirmation</div>
                  <div className={styles.confirmId}>#325884965</div>
                </div>
              </div>
            </div>

            <div className={styles.confirmId}>14/07/2022</div>
          </div>

          <div className={styles.rectBox}>
            <div className={styles.rectBoxRow}>
              <div className={styles.payee}>Payee</div>
              <div className={styles.date}>Michael Jackson </div>
            </div>
          </div>

          <div className={styles.rectBox}>
            <div className={styles.rectBoxRow}>
              <div className={styles.payee}>Payment Method</div>
              <div className={styles.date}>Venmo</div>
            </div>
          </div>

          <div className={styles.rectBox}>
            <div className={styles.rectBoxRow}>
              <div className={styles.payee}>Transaction amount</div>
              <div className={styles.date}>1500$</div>
            </div>
          </div>
        </div>

        <BottomTenantTabs index={index} setIndex={setIndex} />
      </div>
    </>
  );
};
export default Accounting;
