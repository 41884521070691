import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  button: {
    fontSize: "24px",
    lineHeight: "28px",
    fontWeight: 800,
    padding: "12px 0",
    width: "85%",
    borderRadius: "10px",
    marginBottom: "100px  ",
  },
  actionContainer: {
    textAlign: "center",
  },
  checkboxLabel: { fontSize: "12px", color: "#FFF" },
  checkboxLabelHighlight: { color: "#FAA61A", textDecoration: "underline" },
  sheet: {
    display: "flex",
    flex: 1,
    backgroundColor: "#ffffff",
    position: "absolute",
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    flexDirection: "column",
    //   paddingLeft:16,
    // paddinRight:16
  },
  logo: {
    height: 77,
    width: 144,
    marginTop: 24,
    alignSelf: "center",
    marginBottom: 16,
  },
  title: {
    fontSize: 18,
    fontWeight: "600",
    color: "#192342",
    alignSelf: "center",
    marginTop: 20,
    fontFamily: "Open Sans",
    marginBottom: 24,
  },
  subtitle: {
    fontSize: 18,
    color: "#192342",
    fontWeight: "600",
    marginTop: 16,
    alignSelf: "center",
    marginBottom: 10,
  },
  btn: {
    fontSize: "14px",
    fontWeight: 700,
    alignSelf: "center",
    padding: "12px 0",
    borderRadius: "10px",
    backgroundColor: "#192342",
    margin: "30px 0px",
    color: "#FFFFFF",
  },
  contentTxt: {
    marginTop: 10,
    fontSize: 14,
    fontWeight: "400",
    alignSelf: "center",
    color: "#192342",
  },
  errorTypo: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "19px",
    alignSelf: "flex-start",
    color: "#EB5757",
    marginLeft: "20px",
    padding: "5px",
  },
  imgView: {
    flexDirection: "row",
    flex: 1,
    justifyContent: "center",
    display: "flex",
    marginTop: 16,
  },
  icon: { height: 36, width: 36 },
  btmTxt: {
    fontSize: 12,
    fontWeight: "400",
    // alignSelf: "center",
    marginTop: 24,
    // marginBottom: 40,
    textAlign: "left",
    marginLeft: 20,
    marginRight: 20,
    display: 'flex',
    alignItems: 'flex-start'
  },
  btmTxtOnly: {

  },
  span: { color: "#006CFD", cursor: "pointer" },
  container: { display: "flex", flexDirection: "column" },
  fieldStyle: {
    width: "100%",
    alignSelf: "center",
    marginBottom: 16,
    alignItems: 'center',
    display : 'flex',
    flexDirection: 'column',
    justifyContent : 'space-around'
    // align-self: center;
    // margin-bottom: 16px;
    // width: 100%;
    // display: flex;
    // align-items: center;
    // flex-direction: column;
    // justify-content: space-around;
  },
  textStyle: {
    width: "90%",
    alignSelf: "center",
  },
});
