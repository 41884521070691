/**
 * Gets the repositories of the user from Github
 */

import { toast } from "react-toastify";
import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { API_URL, APP_API_URL } from "utils/constants";
import { getDefaultHeaders, getFormattedDateTime } from "utils/helpers";
import { fileRequest, request } from "utils/request";
import queryString from "query-string";

// import { request } from 'utils/request';
import {
  selectCompany,
  selectLoginForm,
  selectToken,
  selectUploadedImageURL,
  selectUpoadedDocURL,
  selectUser,
} from "./selectors";
import { actions } from "./slice";
import { LoggerClass as Logger } from "utils/logger";

/**
 * Github repos request/response handler
 */

export function* createAccountWithRole(action) {
  yield delay(500);
  try {
    const token = yield select(selectToken);
    const options = {
      method: "POST",
      headers: { ...getDefaultHeaders(), Authorization: `Bearer ${token}` },
      body: JSON.stringify({
        email: action.payload.data.email.value,
        password: action.payload.data.password.value,
        name: action.payload.data.name.value,
        role: action.payload.data.role.value,
        landLordType: action.payload.data.landLordType.value
      }),
    };
    const response = yield request(`${API_URL}/Register`, options);
    if (response.Message === "User Already Exists") {
      yield put(actions.createAccountWithRoleError(response.Message));
      return false;
    }
    yield localStorage.setItem("sessionToken", response.jwtToken);
    yield localStorage.setItem("loginResponse", JSON.stringify(response));
    yield put(actions.createAccountWithRoleError(""));
    yield put(actions.createAccountWithRoleSuccess(response));
    yield call(action.payload?.callback);
  } catch (e: any) {
    yield put(actions.createAccountWithRoleError(e));
    return false;
  }
}
export function* otpRequest(action) {
  yield delay(500);
  try {
    const token = JSON.parse(localStorage.getItem("loginResponse")!).jwtToken;
    let userId = JSON.parse(localStorage.getItem("loginResponse")!).userId;
    if (!userId) return false;

    const options = {
      method: "POST",
      headers: { ...getDefaultHeaders(), Authorization: `Bearer ${token}` },
      body: JSON.stringify({
        userId: userId,
        mobile: "+1" + action.payload.data.phoneNumber.value,
        receiveNotificationOnEmail: action.payload.data.emailCheck.value ? action.payload.data.emailCheck.value : false,
        receiveNotificationOnSMS: action.payload.data.phoneCheck.value ? action.payload.data.phoneCheck.value : false,
        size: 6
      }),
    };
    const response = yield request(
      `${API_URL}/userManagement/OTP/GenerateOTP`,
      options
    );
    if (response.Status == 401) {
      yield put(actions.otpCommonError(response.Message));
      return false;
    }
    if (response.message) {
      yield put(actions.otpReceivedError(response.message));
      return false;
    }
    yield put(actions.otpReceivedSuccess(response.otp));
    yield call(action.payload?.callback);
  } catch (e: any) {
    yield put(actions.otpReceivedError(e));
  }
}

export function* verificationOtp(action) {
  try {
    try {
      yield delay(500);
      const token = JSON.parse(localStorage.getItem("loginResponse")!).jwtToken;
      let userId = JSON.parse(localStorage.getItem("loginResponse")!).userId;
      if (!userId) return false;

      const options = {
        method: "POST",
        headers: { ...getDefaultHeaders(), Authorization: `Bearer ${token}` },
      };
      const response = yield request(
        `${API_URL}/userManagement/OTP/VerifyOTP?userID=${userId}&OTP=${action.payload.data.value}`,
        options
      );
      if (response.Status == 401) {
        yield put(actions.otpCommonError(response.Message));
        return false;
      }
      if (!response.message) {
        yield put(actions.otpVerifiedError(response.message));
        return false;
      }
      yield put(actions.otpVerifiedSuccess(response.message));
      yield call(action.payload?.callback);
    } catch (e: any) {
      yield put(actions.otpVerifiedError(e));
    }
  } catch (e) {
    Logger.log(e);
  }
}

export function* loginRequest() {
  try {
    const loginData = yield select(selectLoginForm);
    const options = {
      method: "POST",
      headers: { ...getDefaultHeaders() },
      body: JSON.stringify({
        userEmail: loginData.email.value,
        password: loginData.password.value,
      }),
    };
    const response = yield request(`${API_URL}/Login`, options);
    if (response.Status == 401) {
      yield put(actions.setLoginError(response.Message));
      return false;
    }
    if (!response.jwtToken) {
      yield put(actions.setLoginError("Invalid email or password"));
      return false;
    }
    yield localStorage.setItem("sessionToken", response.jwtToken);
    yield localStorage.setItem("loginResponse", JSON.stringify(response));
    yield put(actions.setLoginSuccess(response));
  } catch (error) {
    yield put(actions.setLoginError("Invalid email or password"));
  }
}

export function* getPropertiesAddressList(action) {
  yield delay(500);
  try {
    const token = JSON.parse(localStorage.getItem("loginResponse")!).jwtToken;
    const options = {
      method: "POST",
      headers: { ...getDefaultHeaders(), Authorization: `Bearer ${token}` },
      body: JSON.stringify({
        zip: action.payload.Zip.value,
        address: action.payload.Address.value,
      }),
    };
    const response = yield request(
      `${API_URL}/propertiesManagement/SearchPropertiesByZipCode`,
      options
    );
    if (response.Message) {
      yield put(actions.getPropertiesAddressListFailed(response.Message));
      return false;
    }
    yield put(actions.getPropertiesAddressListSuccess(response));
    yield put(actions.getPropertiesAddressListFailed(""));
  } catch (e: any) {
    yield put(actions.getPropertiesAddressListFailed(e));
  }
}

export function* getLandlordViaProperty(action) {
  yield delay(500);
  try {
    const token = JSON.parse(localStorage.getItem("loginResponse")!).jwtToken;
    const options = {
      method: "POST",
      headers: { ...getDefaultHeaders(), Authorization: `Bearer ${token}` },
      body: JSON.stringify({
        userId: action.payload.data.OwnerId.value,
      }),
    };
    const response = yield request(
      `${API_URL}/userManagement/Users/GetUserById`,
      options
    );
    if (response.message) {
      yield put(actions.getLandlordViaPropertyFailed(response.message));
      return false;
    }
    yield put(actions.getLandlordViaPropertySuccess(response));
    yield call(action.payload?.callback);
  } catch (e: any) {
    yield put(actions.getLandlordViaPropertyFailed(e));
  }
}

export function* LinkTanentToLandLord(action) {
  yield delay(500);
  try {
    const user = yield select(selectUser);
    const token = user.jwtToken
      ? user.jwtToken
      : JSON.parse(localStorage.getItem("loginResponse")!).jwtToken;
    let userId = user.userId
      ? user.userId
      : JSON.parse(localStorage.getItem("loginResponse")!).userId;
    let tenantName = user.firstName
      ? user.firstName
      : JSON.parse(localStorage.getItem("loginResponse")!).firstName;
    if (!userId) return false;
    
    const options = {
      method: "POST",
      headers: { ...getDefaultHeaders(), Authorization: `Bearer ${token}` },
      body: JSON.stringify({
        tenantId: userId,
        propertyId: action.payload.data,
        tenantName: tenantName,
      }),
    };
    const response = yield request(
      `${API_URL}/propertiesManagement/TenantPropAssoc`,
      options
    );
    if (!response.Result) {
      yield put(actions.LinkTanentToLandLordFailed(response.Message));
      return false;
    }
    yield put(actions.LinkTanentToLandLordSuccess(response.Data));
    yield call(action.payload?.callback);
  } catch (e: any) {
    yield put(actions.LinkTanentToLandLordFailed(e));
  }
}

export function* createCompanyListRequest(action) {
  try {
    const company = yield select(selectCompany);
    const token = yield select(selectToken);
    const user = yield select(selectUser);
    const options = {
      method: "POST",
      headers: { ...getDefaultHeaders(), Authorization: `Bearer ${token}` },
      body: JSON.stringify({
        companyName: company,
        ownerId: user.userId,
      }),
    };
    const response = yield request(
      `${API_URL}/userManagement/Company`,
      options
    );
    if (response.Status == 401) {
      yield put(actions.otpCommonError(response.Message));
      return false;
    }
    if (!response.companyId) {
      yield put(
        actions.createCompanyListError(
          "Unable to add Company, Please try again."
        )
      );
      return false;
    }
    yield put(actions.createCompanyListSuccess(response));
    yield call(action.payload?.callback);
  } catch (error) {
    Logger.log(error);
  }
}

export function* addPropertyData(action) {
  yield delay(500);
  try {
    const token = yield select(selectToken);
    const options = {
      method: "POST",
      headers: { ...getDefaultHeaders(), Authorization: `Bearer ${token}` },
      body: JSON.stringify({
        ownerId: action.payload.data.ownerId.value,
        ownerName: action.payload.data.ownerName.value,
        zip: action.payload.data.zip.value,
        street: action.payload.data.street.value,
        address: action.payload.data.address.value,
        latitude: action.payload.data.latitude.value,
        longitude: action.payload.data.longitude.value,
        city: action.payload.data.city.value,
        state: action.payload.data.state.value,
        type: Number(action.payload.data.type.value),
        status: Number(action.payload.data.occupationStatus.value),
        units: Number(action.payload.data.units.value),
      }),
    };

    const response = yield request(
      `${API_URL}/propertiesManagement/Property`,
      options
    );
    if (response.Status == 401) {
      yield put(actions.addPropertyDataError(response.Message));
      return false;
    }

    if (!response.Result) {
      yield put(actions.addPropertyDataError(response));
      return false;
    }

    yield put(actions.addPropertyDataSuccess(response));
    toast.success("Property Added");
    yield call(action.payload?.callback);
  } catch (e: any) {
    yield put(actions.addPropertyDataError(e));
    return false;
  }
}

export function* addLeaseData(action) {
  yield delay(500);
  try {
    const token = yield select(selectToken);
    const docURL = yield select(selectUpoadedDocURL);
    const imageURL = yield select(selectUploadedImageURL);
    const options = {
      method: "POST",
      headers: { ...getDefaultHeaders(), Authorization: `Bearer ${token}` },
      body: JSON.stringify({
        ownerId: action.payload.ownerId.value,
        propertyId: action.payload.PropertyId.value,
        rentAmount: action.payload.RentAmount.value,
        leaseExpirationDate: action.payload.LeaseExpirationDate.value,
        payDay: action.payload.PayDay.value,
        depostiAmount: action.payload.DepostiAmount.value,
        documents: [
          {
            propertyId: action.payload.PropertyId.value,
            documentURL: docURL,
            type: "document",
            isActive: true,
          },
        ],
        images: [
          {
            propertyId: action.payload.PropertyId.value,
            documentURL: imageURL,
            type: "image",
            isActive: true,
          },
        ],
      }),
    };
    const response = yield request(
      `${API_URL}/propertiesManagement/Lease`,
      options
    );
    if (response.Status === 401 && response.status === 400) {
      yield put(actions.addLeaseDataError(response.Message));
      return false;
    }

    if (!response.Result) {
      yield put(actions.addLeaseDataError(response.Message));
      return false;
    }

    yield put(actions.addLeaseDataSuccess(response));
    toast.success("Lease Added");
  } catch (e: any) {
    yield put(actions.addLeaseDataError(e));
    return false;
  }
}

export function* getDocumentUploadUrlRequest(action: any) {
  yield delay(500);
  try {
    const token = yield select(selectToken);

    let formData = new FormData();
    formData.append("file", action.payload.fileData);
    formData.append("path", action.payload.contentType);
    const options = {
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      body: formData,
    };
    const response = yield call(
      fileRequest,
      `${APP_API_URL}/s3upload/FileUploader`,
      options
    );
    if (response.bucketURL) {
      yield put(
        actions.getDocumentUploadUrlSuccess({
          response: response.bucketURL,
          type: action.payload.contentType,
        })
      );
    } else {
      yield put(actions.getDocumentUploadUrlError("Document Not Uploaded"));
    }
  } catch (err: any) {
    yield put(actions.getDocumentUploadUrlError(err));
  }
}

export function* setDashboardData(action) {
  yield delay(500);
  const token = yield select(selectToken);
  const options = {
    method: "POST",
    headers: { ...getDefaultHeaders(), Authorization: `Bearer ${token}` },
  };
  const queries = queryString.stringify({
    tenantId: action?.payload.userId
      ? action?.payload.userId
      : JSON.parse(localStorage.getItem("loginResponse")!).userId,
  });
  try {
    const response = yield call(
      request,
      `${APP_API_URL}/propertiesManagement/Dashboard/GetTenantDashboard?${queries}`,
      options
    );
    if (response.Status === 401 || response.Status === 400) {
      yield put(actions.setDashboardDataError(response.Message));
      return false;
    }
    if (!response.Result) {
      yield put(actions.setDashboardDataError(response.Message));
      yield call(action.payload?.callback(response.Message));
      return false;
    }
    yield put(actions.setDashboardDataSuccess(response.Message));
    yield call(action.payload?.callback(response.Message));

  } catch (err: any) {
    yield put(actions.setDashboardDataError(err));
    return false;
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* userRepoSaga() {
  yield takeLatest(actions.addPropertyData.type, addPropertyData);
  yield takeLatest(actions.login.type, loginRequest);
  yield takeLatest(actions.createAccountWithRole.type, createAccountWithRole);
  yield takeLatest(actions.otpRequest.type, otpRequest);
  yield takeLatest(actions.verificationOtp.type, verificationOtp);
  yield takeLatest(
    actions.getPropertiesAddressList.type,
    getPropertiesAddressList
  );
  yield takeLatest(actions.getLandlordViaProperty.type, getLandlordViaProperty);
  yield takeLatest(actions.LinkTanentToLandLord.type, LinkTanentToLandLord);
  yield takeLatest(
    actions.createCompanyListRequest.type,
    createCompanyListRequest
  );
  yield takeLatest(actions.addLeaseData.type, addLeaseData);
  yield takeLatest(
    actions.getDocumentUploadUrl.type,
    getDocumentUploadUrlRequest
  );
  yield takeLatest(actions.setDashboardData.type, setDashboardData);
}
