import Header from "app/components/Header";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BottomTenantTabs from "../BottomTabs";
import useStyles from "./styles";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Tab } from "@mui/material";
import { useDispatch } from "react-redux";
import { actions } from "../redux/slice";
import IncomingFunds from "./components/IncomingFunds";
import OutgoingFunds from "./components/OutgoingFunds";

type Props = {};
const LandlordAccounting = (props: Props) => {
  const styles = useStyles();
  const [index, setIndex] = useState(2);
  const navigation = useNavigate();
  const [value, setValue] = React.useState('1');
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(actions.setShowBottomNavBar({ status: true, index: 2 }));
  }, [dispatch]);

  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  return (
    <>
      <div className={styles.container}>
        <Header
          rightIcon={true}
          handleNavigation={() => {
            navigation("/tenant-account/tenant-profile", {
              state: "/tenant-account/accounting",
            });
          }}
        />

        <div className={styles.outstandingBox}>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <img
              src={require("../../../../app/assets/redWallet.png")}
              className={styles.redWallet}
              alt=""
            />
          </div>
          <div className={styles.outstanding}>You have outstanding payment</div>
          <div className={styles.outstandingDollar}>1000$</div>
          {/* <div className={styles.due}>Past due on 14 days</div> */}
          <div className={styles.payNowButton}>
            <div className={styles.payNowText}>View now</div>
          </div>
        </div>

        <div style={{margin:"10px"}}>
        <TabContext value={value}>
          <Box sx={{ background: '#192342', borderRadius : '10px' }}>
            <TabList onChange={handleChangeTab} className={styles.tabList} aria-label="">
              <Tab className={styles.tabButton} label="Incoming funds" value="1"  sx={{width: '43%'}}/>
              <Tab className={styles.tabButton} label="Outgoing funds" value="2" sx={{width: '43%'}}/>
            </TabList>
          </Box>
          <TabPanel className={styles.tabPanel} value="1">
            <IncomingFunds />
          </TabPanel>
          <TabPanel className={styles.tabPanel} value="2">
            <OutgoingFunds />
          </TabPanel>
        </TabContext>
        </div>
        <BottomTenantTabs index={index} setIndex={setIndex} />
      </div>
    </>
  );
};
export default LandlordAccounting;
