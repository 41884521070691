/**
 * Homepage selectors
 */

 import { RootState } from "types";

 import { createSelector } from "@reduxjs/toolkit";
import { bussinessAccountState } from "./types";
 
 const selectDomain = (state: RootState) => state.businessAccountState || bussinessAccountState;

export const selectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);


export const selectError = createSelector(
  [selectDomain],
  (state) => state.error
);

 export const selectBusinessAccountData = createSelector(
   [selectDomain],
   (state) => state.createBusinessAccount
 );
 
 export const selectControllerOwnerData = createSelector(
  [selectDomain],
  (state) => state.createControllerOwnerSRE
);

export const selectCreatedBusinessData = createSelector(
  [selectDomain],
  (state) => state.createdBusinessData
);

export const selectIndividualAccountData = createSelector(
  [selectDomain],
  (state) => state.createIndividualAccount
);

export const selectCreatedIndividualData = createSelector(
  [selectDomain],
  (state) => state.createdIndividualAccount
);


export const selectCreatedControllerOwnerData = createSelector(
  [selectDomain],
  (state) => state.createdControllerOwnerData
);

export const selectAddThresholdData = createSelector(
  [selectDomain],
  (state) => state.addThresholdData
);

 

export const selectownerList = createSelector(
  [selectDomain],
  (state) => state.ownerList
);

export const selectownerListRequest = createSelector(
  [selectDomain],
  (state) => state.getOwnerListRequest
);
 