import { selectToken, selectUser } from "app/containers/Auth/selectors";
import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { API_URL, APP_API_URL } from "utils/constants";
import { getDefaultHeaders } from "utils/helpers";
import { request } from "utils/request";
import { actions } from "./slice";
import queryString from "query-string";
import { toast } from "react-toastify";
import { selectImageUploadUrl, selectS3UploadedVideoURL } from "../../CommonComponent/S3UploadSaga/selector";
import { LoggerClass as Logger } from "utils/logger";

const response = {
  items: [
    {
      id: "774609",
      ownerId: "",
      address: "1670 South Palace, Near Park Arear Milford",
      zipcode: 35004,
      state: "Hartford",
      city: "Tallahassee",
      type: "Single-unit",
      occupationStatus: "Tenant Occupied",
      propertyStatus: "OK",
      email: "tarley@gmail.com",
      imageUrl: "/homeImageOne.png",
      allocatedUsers: [
        {
          id: "U04101",
          propertyId: "P04101",
          firstName: "John",
          lastName: "Snow",
          nextRentalPay: "01/12/2024",
        },
        {
          id: "U04101",
          propertyId: "P04101",
          firstName: "Sansa",
          lastName: "Snow",
          nextRentalPay: "01/12/2024",
        },
      ],
    },
    {
      id: "2",
      address: "1872 North Palace, Near Park Arear Milford",
      zipcode: 35004,
      ownerId: "",
      state: "Hartford",
      city: "Tallahassee",
      type: "Single-unit",
      occupationStatus: "Vacant",
      propertyStatus: "Pending Tenant Approval",
      email: "tarley@gmail.com",
      imageUrl: "/homeImageOne.png",
      allocatedUsers: [
        {
          id: "U04101",
          propertyId: "P04101",
          firstName: "John",
          lastName: "Snow",
          nextRentalPay: "01/12/2024",
        },
      ],
    },
    {
      id: "3",
      address: "3429 Adkins Rd. Nw Atlanta, GA 30331",
      zipcode: 35004,
      ownerId: "",
      state: "Hartford",
      city: "Tallahassee",
      type: "Single-unit",
      occupationStatus: "Owner Occupied",
      propertyStatus: "OK",
      email: "tarley@gmail.com",
      imageUrl: "/homeImageThree.png",
      allocatedUsers: [
        {
          id: "U04101",
          propertyId: "P04101",
          firstName: "John",
          lastName: "Snow",
          nextRentalPay: "01/12/2024",
        },
      ],
    },
    {
      id: "4",
      address: "1670 South Palace, Near Park Arear Milford",
      zipcode: 35004,
      ownerId: "",
      state: "Hartford",
      city: "Tallahassee",
      type: "Single-unit",
      occupationStatus: "Tenant Occupied",
      propertyStatus: "OK",
      email: "tarley@gmail.com",
      imageUrl: "/homeImageOne.png",
      allocatedUsers: [
        {
          id: "U04101",
          propertyId: "P04101",
          firstName: "Sansa",
          lastName: "Snow",
          nextRentalPay: "01/12/2024",
        },
      ],
    },
    {
      id: "5",
      address: "1670 South Palace, Near Park Arear Milford",
      zipcode: 35004,
      ownerId: "",
      state: "Hartford",
      city: "Tallahassee",
      type: "Single-unit",
      occupationStatus: "Tenant Occupied",
      propertyStatus: "OK",
      email: "tarley@gmail.com",
      imageUrl: "/homeImageOne.png",
      allocatedUsers: [
        {
          id: "U04101",
          propertyId: "P04101",
          firstName: "Sansa",
          lastName: "Snow",
          nextRentalPay: "01/12/2024",
        },
      ],
    },
    {
      id: "6",
      address: "1670 South Palace, Near Park Arear Milford",
      zipcode: 35004,
      ownerId: "",
      state: "Hartford",
      city: "Tallahassee",
      type: "Single-unit",
      occupationStatus: "Tenant Occupied",
      propertyStatus: "OK",
      email: "tarley@gmail.com",
      imageUrl: "/homeImageOne.png",
      allocatedUsers: [
        {
          id: "U04101",
          propertyId: "P04101",
          firstName: "Sansa",
          lastName: "Snow",
          nextRentalPay: "01/12/2024",
        },
      ],
    },
    {
      id: "7",
      address: "1670 South Palace, Near Park Arear Milford",
      zipcode: 35004,
      ownerId: "",
      state: "Hartford",
      city: "Tallahassee",
      type: "Single-unit",
      occupationStatus: "Tenant Occupied",
      propertyStatus: "OK",
      email: "tarley@gmail.com",
      imageUrl: "/homeImageOne.png",
      allocatedUsers: [
        {
          id: "U04101",
          propertyId: "P04101",
          firstName: "Sansa",
          lastName: "Snow",
          nextRentalPay: "01/12/2024",
        },
      ],
    },
  ],
  meta: {
    totalItems: 5,
    itemCount: 5,
    itemsPerPage: 5,
    totalPages: 5,
    currentPage: 5,
  },
  links: {
    first: "",
    last: "",
    previous: "",
    next: "",
  },
};

export function* getList(action) {
  yield delay(500);
  try {
    yield put(actions.getPropertiesListSuccess(response));
  } catch (e: any) {
    alert("Something Went Wrong");
  }
}

export function* getServices(action) {
  yield delay(500);
  try {    
  const token = yield select(selectToken);
  const user = yield select(selectUser);
  const options = {
    method: "POST",
    headers: { ...getDefaultHeaders(), Authorization: `Bearer ${token}` } ,
    body: JSON.stringify({
      propertyIds : action.payload.propertyIds,
      pageNumber : action.payload.pageNumber,
      pageSize : action.payload.pageSize,
      openTime : action.payload.openTime ? action.payload.openTime : '',
      status : [] //action.payload.status ? action.payload.status : ''
    }),  
  };

  const response = yield call(
      request,
      `${APP_API_URL}/serviceRequestManagement/ServiceRequest/GetServiceRequests`,
      options
    )    
    if(response.Status === 401 || response.Status === 400) {      
      yield put(actions.getServicesError(response.Message));  
      return false;
    }

    if(!response.Result) {
      yield put(actions.getServicesError(response.Message));
      return false;
    }    

    yield put(actions.getServicesSuccess(response));
  } catch (err: any) {
    yield put(actions.getServicesError(err));
    return false;
 }
}

export function* createServiceRequest(action) {
  yield delay(500);
  try {
    Logger.log(action.payload);
    const token = yield select(selectToken);
    const imageURL = yield select(selectImageUploadUrl);
    const videoURL = yield select(selectS3UploadedVideoURL);    
    const localPropertyId = localStorage.getItem("property");
    const propertyAddress = localStorage.getItem("propertyAddress");
    const localUserId = JSON.parse(localStorage.getItem("loginResponse")!).userId;
    
    const options = {
      method: "POST",
      headers: { ...getDefaultHeaders(), Authorization: `Bearer ${token}` },
      body: JSON.stringify({
        openedBy: action.payload.data.OpenedBy.value ? action.payload.data.OpenedBy.value : localUserId,
        propertyId: action.payload.data.PropertyId.value ? action.payload.data.PropertyId.value : localPropertyId,
        PropertyAddress: action.payload.data.PropertyAddress.value ? action.payload.data.PropertyAddress.value : propertyAddress,
        serviceType: action.payload.data.ServiceType,
        description: action.payload.data.Description.value,        
        image: imageURL ? [
           {
            propertyId: action.payload.data.PropertyId.value ? action.payload.data.PropertyId.value : localPropertyId,
            documentURL: imageURL,
            type: "image",
            isActive: true,
          }
        ] : [],
        video: videoURL ? [
           {
            propertyId: action.payload.data.PropertyId.value ? action.payload.data.PropertyId.value : localPropertyId,
            documentURL: videoURL,
            type: "video",
            isActive: true,
          } 
        ] : [],
      }),
    };

    
    const response = yield request(
      `${API_URL}/serviceRequestManagement/ServiceRequest/AddServiceRequest`,
      options
    );

    if (response.Status == 401 || response.Status == 400) {
      yield put(actions.createServiceRequestError(response.Message));
      return false;
    }

    if (!response.Result) {
      yield put(actions.createServiceRequestError(response));
      return false;
    }

    yield put(actions.createServiceRequestSuccess(response));
    toast.success('Service Request Created'); 
    yield call(action.payload?.callback);   
  } catch (e: any) {
    yield put(actions.createServiceRequestError(e));
    return false;
  }
}

export function* getServiceRequestDashboardCount(action) {
  yield delay(500);
  const token = yield select(selectToken);
  const options = {
    method: "POST",
    headers: { ...getDefaultHeaders(), Authorization: `Bearer ${token}` },
    body: JSON.stringify(action.payload)
  };
  
  try {
    const response = yield call(
      request,
      `${APP_API_URL}/serviceRequestManagement/ServiceRequest/GetServiceRequestDashboardSummary`,
      options
    );
    if (response.Status === 401 || response.Status === 400) {
      yield put(actions.getServiceRequestDashboardCountError(response.Message));
      return false;
    }
    if (!response.Result) {
      yield put(actions.getServiceRequestDashboardCountError(response.Message));
      return false;
    }
    yield put(actions.getServiceRequestDashboardCountSuccess(response));
  } catch (err: any) {
    yield put(actions.getServiceRequestDashboardCountError(err));
    return false;
  }
}

export function* setSelectedServiceRequest(action) {
  yield delay(500);
  try {    
  const token = yield select(selectToken);
 
  const options = {
    method: "GET",
    headers: { ...getDefaultHeaders(), Authorization: `Bearer ${token}` }    
  };
  const queries = queryString.stringify({
    serviceRequestID : action.payload.id
  });

    const response = yield call(
      request,
      `${APP_API_URL}/serviceRequestManagement/ServiceRequest?${queries}`,
      options
    )

    if(response.Status === 401 || response.Status === 400) {      
      yield put(actions.setSelectedServiceRequestError(response.Message));
      return false;
    }

    if(!response.Result) {
      yield put(actions.setSelectedServiceRequestError(response.Message));
      return false;
    }    

    yield put(actions.setSelectedServiceRequestSuccess(response));
    yield call(action.payload?.callback);
  } catch (err: any) {
    yield put(actions.setSelectedServiceRequestError(err));
    return false;
  }
}

export function* getPendingBalance(action) {
  yield delay(500);
  const token = yield select(selectToken);
  const options = {
    method: "POST",
    headers: { ...getDefaultHeaders(), Authorization: `Bearer ${token}` },
  };
  const queries = queryString.stringify({
    ownerId: action?.payload ? action?.payload : JSON.parse(localStorage.getItem('loginResponse')!).userId,
  });
  try {
    // const response = yield call(
    //   request,
    //   `${APP_API_URL}/userManagement/Balance?${queries}`,
    //   options
    // );
    // if (response.Status === 401 || response.Status === 400) {
    //   yield put(actions.getPendingBalanceError(response.Message));
    //   return false;
    // }
    // if (!response.Result) {
    //   yield put(actions.getPendingBalanceError(response.Message));
    //   return false;
    // }
    yield put(actions.getPendingBalanceSuccess('1500')); //response.Data
  } catch (err: any) {
    yield put(actions.getPendingBalanceError(err));
    return false;
  }
}

export function* getLoggedInUserName(action) {
  yield delay(500);
  const token = yield select(selectToken);
  const options = {
    method: "GET",
    headers: { ...getDefaultHeaders(), Authorization: `Bearer ${token}` },
  };
  const queries = queryString.stringify({
    userId: action?.payload ? action?.payload : JSON.parse(localStorage.getItem('loginResponse')!).userId,
  });
  try {
    const response = yield call(
      request,
      `${APP_API_URL}/userManagement/Users/GetUserInfoByUserId?${queries}`,
      options
    );
    if (response.Status === 401 || response.Status === 400) {
      yield put(actions.getLoggedInUserNameError(response.Message));
      return false;
    }
    if (!response.Result) {
      yield put(actions.getLoggedInUserNameError(response.Message));
      return false;
    }
    yield put(actions.getLoggedInUserNameSuccess(response.Data)); 
  } catch (err: any) {
    yield put(actions.getLoggedInUserNameError(err));
    return false;
  }
}

export function* useLandlordSaga() {
  yield takeLatest(actions.getList.type, getList);
  yield takeLatest(actions.getServices.type, getServices);
  yield takeLatest(actions.createServiceRequest.type, createServiceRequest); 
  yield takeLatest(actions.getServiceRequestDashboardCount.type, getServiceRequestDashboardCount);
  yield takeLatest(actions.setSelectedServiceRequest.type, setSelectedServiceRequest);
  yield takeLatest(actions.getPendingBalance.type, getPendingBalance);
  yield takeLatest(actions.getLoggedInUserName.type, getLoggedInUserName);
}
