import { Button, InputAdornment, TextField, Typography } from "@mui/material";
import Page from "app/components/Page";
import PageImageHeader from "app/components/PageImageHeader";

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "utils/hooks/useWindowDimensions";
import { useStyles } from "./styles";

const NotListed = () => {
  const { width, height } = useWindowDimensions();
  const styles = useStyles();
  const navigation = useNavigate();
  const [disable, setDisable] = useState(true);
  const [zipCode, setZipCode] = useState("");
  const [error, setError] = useState(false);

  useEffect(() => {
    if (zipCode.length === 6) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [zipCode.length]);
  return (
    <Page>
      <PageImageHeader
        subHeading={
          <div style={{ display: "flex" }}>
            <Typography sx={{ fontSize: 14, color: "#fff" }}>
              {"have account"}
            </Typography>
            <Typography
              onClick={() => {
                // navigate("/auth");
              }}
              sx={{
                fontSize: 14,
                color: "#FAA61A",
                fontWeight: "bold",
                textDecoration: "underline",
                marginLeft: "4px",
              }}
            >
              {"login"}
            </Typography>
          </div>
        }
      />

      <div
        style={{
          width: width,

          top: height / 2.82,
        }}
        className={styles.sheet}
      >
        <div className={styles.title}>Can’t find your Propery</div>

        <div className={styles.subTitle}>
          We are sorry but we can’t find the property you’ve entered.
        </div>
        <div className={styles.subTitle}>
          Please check the address and try again.
        </div>

        <Button
          type="submit"
          variant="contained"
          sx={{
            width: width / 1.15,
          }}
          //   disabled={disable}
          className={styles.btn}
          onClick={() => {
            navigation(-1);
          }}
        >
          Try again
        </Button>

        <div className={styles.subTitle}>
          If that is not working, the property you are trying to add is not
          managed in our platform yet.
        </div>

        <div className={styles.subTitle}>
          Please tell your landloard to register his property with
          SRE-Management platform and start managing easily.
        </div>

        <Button
          type="submit"
          variant="outlined"
          sx={{
            width: width / 1.15,
          }}
          //   disabled={disable}
          className={styles.outlinebtn}
          onClick={() => {}}
        >
          Exit App
        </Button>
        <div className={styles.subTitle}>
          If you are the owner of your residence addredd, press here to register
          as the Property owner by choosing “Property owner” account type.
        </div>

        <Button
          type="submit"
          variant="contained"
          sx={{
            width: width / 1.15,
          }}
          //   disabled={disable}
          className={styles.altbtn}
          onClick={() => {}}
        >
          Move to Property Owner account
        </Button>

        {/* <div className={styles.title}>My Property</div>

      <div className={styles.subTitle}>
        <span className={styles.spanTitle}>Add your phone number</span> to be
        used with notifications and communications
      </div> */}
        {/* <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            backgroundColor: "red",
          }}
        > */}
        <div className={styles.footerTxt}>Do you need help?</div>
        <div className={styles.footerTxt}>
          Contact us at{" "}
          <span className={styles.footTxt}> Support@Sre-Management.com</span>
        </div>
      </div>
      {/* </div> */}
    </Page>
  );
};

export default NotListed;
