import {
  Button,
  Typography,
  IconButton,
  InputAdornment,
  TextField,
  Box,
  Grid,
} from "@mui/material";
import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import useStyles from "./styles";
import SRE_Logo from "../../../assets/logo.png";
import arrowBack from "../../../assets/backArrow.png";
import crosspng from "../../../assets/redcross.png";
import profilePng from "../../../assets/DavidProfile.png";
import checkImage from "../../../assets/check.png";
import Dialog from "@mui/material/Dialog";

type Props = {};
const ChangeControl = (props: Props) => {
  // const { width, height } = useWindowDimensions();
  const styles = useStyles();
  const navigate = useNavigate();
  // const loading = useSelector(selectLoading);

  const [approve, setApprove] = useState(false);
  const [reject, setReject] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [address, setAddress] = useState(
    "OLD 356  STEESE HWY, Boston, USA, 5668"
  );
  const [company, setCompany] = useState("DJ Holdings LLC");
  const [name, setName] = useState("David Jhonson");
  const goBack = () => {
    navigate(-1);
  };
  const [showNotifications, setShowNotifications] = useState(false);
  const [secondNotification, setSecondNotification] = useState(false);
  const handleSubmit = (e) => {
    setShowPopup(true);
  };

  return (
    <>
      <Dialog
        open={showNotifications}
        className={styles.popup}
        onClose={() => {
          setShowNotifications(false);
        }}
      >
        {secondNotification && (
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
              padding: "10px",
              margin: 0,
            }}
          >
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography className={styles.imagePageHeading}>
                Change of control Notification
              </Typography>
              <Typography className={styles.dialogTypo}>
                Your Change of control request of the following properties has
                been rejected by the tenant assigned to the property:
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "12px 30px",
                  gap: "12px",
                  width: "100%",
                  height: "76px",
                  background: "#FFFFFF",
                  border: "1px solid #BCBCBC",
                  boxShadow:
                    "9px 19px 8px rgba(199, 199, 199, 0.01), 5px 11px 7px rgba(199, 199, 199, 0.05), 2px 5px 5px rgba(199, 199, 199, 0.09), 1px 1px 3px rgba(199, 199, 199, 0.1), 0px 0px 0px rgba(199, 199, 199, 0.1)",
                  borderRadius: "20px",
                  marginBottom: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Open Sans",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "16px",
                    letterSpacing: "0.1px",
                    whiteSpace: "wrap",
                    textAlign: "center",
                    color: "#192342",
                  }}
                >
                  3429 Adkins, Rd. NW Atlanta, GA 30331
                </Typography>
              </Box>
              <Typography className={styles.dialogTypo}>
                Thus, their on-boarding process has been terminated. Please make
                sure the existing tenant signs a new leasing document with you
                before on-boarding the property.
              </Typography>
            </span>
            <Typography className={styles.dialogTypo}>
              If you believe it is a mistake, please contact us at
              <br />
              <Link className={styles.bottomLink} to="/welcomScreen">
                Support@Sre-Management.com
              </Link>
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Button
                className={styles.signInButton}
                variant="contained"
                onClick={() => {
                  setSecondNotification(false);
                  setShowNotifications(false);
                }}
              >
                Continue
              </Button>
            </Box>
          </Box>
        )}
        {!secondNotification && (
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
              padding: "10px",
              margin: 0,
            }}
          >
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
              }}
            >
              <Typography className={styles.imagePageHeading}>
                Change of control Notification
              </Typography>
              <Typography className={styles.dialogTypo}>
                We received a Change-Of-Control request from
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "12px 0px",
                  gap: "12px",
                  width: "100%",
                  height: "76px",
                  background: "#FFFFFF",
                  border: "1px solid #BCBCBC",
                  boxShadow:
                    "9px 19px 8px rgba(199, 199, 199, 0.01), 5px 11px 7px rgba(199, 199, 199, 0.05), 2px 5px 5px rgba(199, 199, 199, 0.09), 1px 1px 3px rgba(199, 199, 199, 0.1), 0px 0px 0px rgba(199, 199, 199, 0.1)",
                  borderRadius: "20px",
                  marginBottom: "10px",
                }}
              >
                <Box
                  sx={{
                    width: "52px",
                    height: "52px",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      objectFit: "cover",
                      borderRadius: "100%",
                    }}
                    src={profilePng}
                    alt="erro"
                  />
                </Box>
                <Box
                  sx={{
                    width: "66.66%",
                    height: "100%",
                  }}
                >
                  <Typography className={styles.name}>{name}</Typography>
                  <Typography className={styles.company}>{company}</Typography>
                </Box>
              </Box>
              <Typography className={styles.dialogTypo}>
                Claiming Properties from your portfolio. Thus, The following
                properties have been removed from your porfolio:
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "12px 30px",
                  gap: "12px",
                  width: "100%",
                  height: "76px",
                  background: "#FFFFFF",
                  border: "1px solid #BCBCBC",
                  boxShadow:
                    "9px 19px 8px rgba(199, 199, 199, 0.01), 5px 11px 7px rgba(199, 199, 199, 0.05), 2px 5px 5px rgba(199, 199, 199, 0.09), 1px 1px 3px rgba(199, 199, 199, 0.1), 0px 0px 0px rgba(199, 199, 199, 0.1)",
                  borderRadius: "20px",
                  marginBottom: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Open Sans",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "16px",
                    letterSpacing: "0.1px",
                    whiteSpace: "wrap",
                    textAlign: "center",
                    color: "#192342",
                  }}
                >
                  3429 Adkins, Rd. NW Atlanta, GA 30331
                </Typography>
              </Box>
              <Typography className={styles.dialogTypo}>
                Change of control requests are automatically confirmed if the
                property is Owner Occupied\Vacant, or if the tenant approved the
                change of control.
              </Typography>
            </span>
            <Typography className={styles.dialogTypo}>
              If you believe it is a mistake, please contact us at
              <br />
              <Link className={styles.bottomLink} to="/welcomScreen">
                Support@Sre-Management.com
              </Link>
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "100%",
              }}
            >
              <Button
                className={styles.signInButton}
                variant="contained"
                onClick={() => {
                  setSecondNotification(true);
                }}
              >
                Continue
              </Button>
            </Box>
          </Box>
        )}
      </Dialog>

      <Dialog
        open={showPopup}
        className={styles.popup}
        onClose={() => {
          setShowPopup(false);
        }}
      >
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
            padding: "10px",
            margin: 0,
          }}
        >
          {approve && (
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
              }}
            >
              <IconButton
                style={{
                  height: "78px",
                  width: "78px",
                  backgroundColor: "#ffffff",
                  borderRadius: "50%",
                  display: "inline-block",
                  boxShadow:
                    "0 2px 2px 0 rgb(0 0 0 / 20%), 0px 2px 13px 0 rgb(0 0 0 / 15%)",
                  borderWidth: 1,
                  borderColor: "rgba(0,0,0,0.2)",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "15px",
                }}
              >
                <img src={checkImage} alt={"Done"} />
              </IconButton>
              <Typography className={styles.dialogTypo}>
                The control of the property is changed. A new Landloard is
                assigned.
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "12px 12px",
                  gap: "12px",
                  width: "100%",
                  height: "114px",
                  background: "#FFFFFF",
                  border: "1px solid #BCBCBC",
                  boxShadow:
                    "9px 19px 8px rgba(199, 199, 199, 0.01), 5px 11px 7px rgba(199, 199, 199, 0.05), 2px 5px 5px rgba(199, 199, 199, 0.09), 1px 1px 3px rgba(199, 199, 199, 0.1), 0px 0px 0px rgba(199, 199, 199, 0.1)",
                  borderRadius: "20px",
                  margin: "16px 0px",
                }}
              >
                <Box
                  sx={{
                    width: "20%",
                    height: "100%",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      objectFit: "cover",
                      borderRadius: "100%",
                    }}
                    src={profilePng}
                    alt="erro"
                  />
                </Box>
                <Box
                  sx={{
                    width: "66.66%",
                    height: "100%",
                  }}
                >
                  <Typography className={styles.name}>{name}</Typography>
                  <Typography className={styles.company}>{company}</Typography>
                  <Typography className={styles.address}>{address}</Typography>
                </Box>
              </Box>
            </span>
          )}
          {reject && (
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
              }}
            >
              <IconButton
                style={{
                  height: "78px",
                  width: "78px",
                  backgroundColor: "#ffffff",
                  borderRadius: "50%",
                  display: "inline-block",
                  boxShadow:
                    "0 2px 2px 0 rgb(0 0 0 / 20%), 0px 2px 13px 0 rgb(0 0 0 / 15%)",
                  borderWidth: 1,
                  borderColor: "rgba(0,0,0,0.2)",
                  alignItems: "center",
                  justifyContent: "center",
                  margin: "15px",
                }}
              >
                <img src={crosspng} alt={"X"} />
              </IconButton>
              <Typography className={styles.dialogTypo}>
                The control process is s terminated due to Tenant Rejection.
              </Typography>
            </span>
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Button
              className={styles.signInButton}
              variant="contained"
              onClick={() => {
                if (approve) {
                  setShowPopup(false);
                  navigate("/landlord-account");
                } else {
                  setShowPopup(false);
                  setReject(false);
                }
                // setTimeout(() => {
                //   setReject(false);
                //   setApprove(false);
                // }, 500);
              }}
            >
              Continue
            </Button>
          </Box>
        </Box>
      </Dialog>

      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
          background: "#FFF7DD",
        }}
      >
        <Box className={styles.upperContainer}>
          <IconButton
            sx={{ width: "24px", height: "24px", margin: "20px 0 0px 20px" }}
            onClick={goBack}
          >
            <img
              src={arrowBack}
              style={{
                height: 16,
                width: 16,
              }}
              alt="<-"
            />
          </IconButton>
          <Box className={styles.logoContainer}>
            <img src={SRE_Logo} alt="logo" className={styles.logo} />
          </Box>
        </Box>
        <Box className={styles.lowerContainer}>
          <Typography className={styles.pageHeading}>Add Property</Typography>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
              padding: "0px 16px",
              position: "relative",
              bottom: "10px",
            }}
          >
            <Box
              style={{
                width: "100%",
                margin: "0px 16px 0px 16px",
                display: "flex",
                padding: "0",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography className={styles.pageTypo2}>
                Change of control request
              </Typography>
              <Typography className={styles.pageTypoH}>
                We received a request from another Landlord to take control of
                the property. If you signed a new Lease Document, please check
                carefully that the following individual or corporate name is the
                Owner of the property you are renting as shown in the leasing
                Document:
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                padding: "12px 30px",
                gap: "12px",
                width: "100%",
                height: "114px",
                background: "#FFFFFF",
                border: "1px solid #BCBCBC",
                boxShadow:
                  "9px 19px 8px rgba(199, 199, 199, 0.01), 5px 11px 7px rgba(199, 199, 199, 0.05), 2px 5px 5px rgba(199, 199, 199, 0.09), 1px 1px 3px rgba(199, 199, 199, 0.1), 0px 0px 0px rgba(199, 199, 199, 0.1)",
                borderRadius: "20px",
                margin: "16px 0px",
              }}
            >
              <Box
                sx={{
                  width: "33.33%",
                  height: "100%",
                }}
              >
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    borderRadius: "100%",
                  }}
                  src={profilePng}
                  alt="error"
                />
              </Box>
              <Box
                sx={{
                  width: "66.66%",
                  height: "100%",
                }}
              >
                <Typography className={styles.name}>{name}</Typography>
                <Typography className={styles.company}>{company}</Typography>
                <Typography className={styles.address}>{address}</Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                gap: "12px",
                background: "#FFFFFF",
                marginBottom: "16px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-around",
                  alignItems: "center",
                  paddingTop: "12px",
                  gap: "12px",
                  width: "50%",
                  height: "172px",
                  background: "#FFFFFF",
                  border: "1px solid #BCBCBC",
                  boxShadow:
                    "9px 19px 8px rgba(199, 199, 199, 0.01), 5px 11px 7px rgba(199, 199, 199, 0.05), 2px 5px 5px rgba(199, 199, 199, 0.09), 1px 1px 3px rgba(199, 199, 199, 0.1), 0px 0px 0px rgba(199, 199, 199, 0.1)",
                  borderRadius: "20px",
                }}
              >
                <Typography
                  sx={{ position: "relative", top: "10px", margin: "0px 8px" }}
                  className={styles.pageTypoExitButton12400}
                >
                  If you approve the change, future rental transactions will
                  reach the New landlord.
                  <br /> To approve the change select
                  <label style={{ fontWeight: 700 }}> “Approve”</label>.
                </Typography>
                <Button
                  className={styles.exitAppButton}
                  variant="contained"
                  onClick={() => {
                    setShowPopup(true);
                    setApprove(true);
                  }}
                >
                  <Typography className={styles.exitButtonTypo}>
                    Approve
                  </Typography>
                </Button>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  width: "50%",
                  flexDirection: "column",
                  justifyContent: "space-around",
                  alignItems: "center",
                  paddingTop: "12px",
                  gap: "12px",
                  height: "172px",
                  background: "#FFFFFF",
                  border: "1px solid #BCBCBC",
                  boxShadow:
                    "9px 19px 8px rgba(199, 199, 199, 0.01), 5px 11px 7px rgba(199, 199, 199, 0.05), 2px 5px 5px rgba(199, 199, 199, 0.09), 1px 1px 3px rgba(199, 199, 199, 0.1), 0px 0px 0px rgba(199, 199, 199, 0.1)",
                  borderRadius: "20px",
                }}
              >
                <Typography
                  sx={{ position: "relative", top: "10px", margin: "0px 8px" }}
                  className={styles.pageTypoExitButton12400}
                >
                  If the name is not the same as shown in the leasing document,
                  or if you didn’t any new leasing document, please select
                  <label style={{ fontWeight: 700 }}> “Reject”</label>.
                </Typography>
                <Button
                  className={styles.exitAppButton}
                  variant="contained"
                  onClick={() => {
                    setShowPopup(true);
                    setReject(true);
                  }}
                >
                  <Typography className={styles.exitButtonTypo}>
                    Reject
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
              padding: "0px 16px",
            }}
          >
            <Typography className={styles.pageTypo12400}>
              If you are not near the Leasing document and want to check it
              later, please exit the application for now.
            </Typography>
            <Button
              className={styles.signInButtonColorInvert}
              variant="contained"
              onClick={() => {
                setShowNotifications(true);
              }}
            >
              Exit Application
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default ChangeControl;
