/**
 * Homepage selectors
 */

 import { RootState } from "types";

 import { createSelector } from "@reduxjs/toolkit";
import { LandlordProfileState } from "./types";
 
 const selectDomain = (state: RootState) => state.landlordProfileState || LandlordProfileState;

export const selectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);

export const selectError = createSelector(
  [selectDomain],
  (state) => state.error
);

export const selectBusinessDetails = createSelector(
  [selectDomain],
  (state) => state.businessDetails
);

export const selectChangeMobile = createSelector(
    [selectDomain],
    (state) => state.changeMobileRequest
);

