import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  inputs: {
    padding: "10px 16px",
    width: "100%",
    height: "48px",
    background: "#FFF7DD",
    color: "#7D7D7D",
    font: 'inherit',
    borderRadius: "20px",
    border: "unset",
    margin: "5px 0px 0px 0px",
    "&:focus": {
      outline: "none",
    },

    "&:disabled": {
      background: "#E0E0E0",
      color: "#7D7D7D",
    },
  }, 
  disabledInputSpan: {
    width: "100%",
    marginBottom: "20px",
    alignItems: "center",
    border: 'none !important',
  },
  pageTypoThresholdDisabled: {
    textAlign: "left",
    height: "fit-content",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "19px",
    letterSpacing: "0.2px",
    margin: "0 0 0 3%",
    color: "#192342",
  },  
  pageTypoThresholdDisabledOne: {
    textAlign: "left",
    height: "fit-content",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "19px",
    letterSpacing: "0.2px",
    margin: "0 0 5px 6%",
    color: "#192342",
  },   
});

export default useStyles;
