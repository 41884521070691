import { Typography, IconButton, Button, Box, Checkbox, Radio } from "@mui/material";
import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
const Filter = ({ closePass, continuePass }) => {
  const styles = useStyles();
  const propertyOccupationStatusArray = [
    "From recent to the first",
    "From the first to recent",
  ];
  const propertyStatusArray = [
    "New",
    "Select Time Frame",
    "Scheduled, Pending Payment",
    "Pending Approval",
    "In Progress",
    "Completed, Pending payment",
    "Completed",
    "Reopened",
    "Discard",
  ];
  const [propertyOccupationStatus, setPropertyOccupationStatus] = useState("");
  const [propertyStatus, setPropertyStatus] = useState("");
  const [enableButton, setEnableButton] = useState(false);
  const goBack = () => {
    closePass();
  };
  const continueClick = () => {
    continuePass();
  };

  useEffect(() => {
    if (propertyOccupationStatus !== "" && propertyStatus !== "") {
      setEnableButton(true);
    } else {
      setEnableButton(false);
    }
  }, [propertyOccupationStatus, propertyStatus]);

  return (
    <Box
      style={{
        display: "flex",
        backgroundColor: "white",
        height: "100%",
        justifyContent: "space-between",
        alignItems: "flex-start",
        paddingLeft: 16,
        paddingRight: 16,
        position: "fixed",
        width: "100%",
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        zIndex: 3,
      }}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: "24px",
            marginTop: "16px",
          }}
        >
          <IconButton
            sx={{
              width: "16px",
              height: "16px",
              padding: "0",
              alignSelf: "center",
            }}
            onClick={goBack}
          >
            <ArrowBackIcon sx={{ color: "#192342" }} />
          </IconButton>
          <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <Typography className={styles.title} sx={{ width: "100%" }}>
              Filter
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography className={styles.filterTitle}>Open Time</Typography>
        </Box>
        {propertyOccupationStatusArray.map((status, index) => {
          return (
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Radio
                className={styles.checkbox}
                checked={propertyOccupationStatus === status}
                onChange={(e) => {
                  if (e.target.checked) {
                    setPropertyOccupationStatus(status);
                  } else {
                    setPropertyOccupationStatus("");
                  }
                }}
              />
              <Typography
                className={styles.typo40016}
                sx={{ alignSelf: "center" }}
              >
                {status}
              </Typography>
            </Box>
          );
        })}
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography className={styles.filterTitle}>
            Service Ticket Status
          </Typography>
        </Box>
        {propertyStatusArray.map((status, index) => {
          return (
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Checkbox
                className={styles.checkbox}
                checked={propertyStatus === status}
                onChange={(e) => {
                  if (e.target.checked) {
                    setPropertyStatus(status);
                  } else {
                    setPropertyStatus("");
                  }
                }}
              />
              <Typography
                className={styles.typo40016}
                sx={{ alignSelf: "center" }}
              >
                {status}
              </Typography>
            </Box>
          );
        })}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            position: "absolute",
            bottom: "40px",
            right: "16px",
            left: "16px",
          }}
        >
          <Button
            disabled={!enableButton}
            className={styles.signInButton}
            onClick={continueClick}
          >
            <Typography className={styles.typobutton70014}>Apply</Typography>
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default Filter;
