import {
  Button,
  Typography,
  IconButton,
  InputAdornment,
  TextField,
  Box,
  Grid,
  Dialog,
  Select,
  MenuItem,
  Radio,
  Checkbox,
  ListItemText,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from '@mui/icons-material/Close';
import useStyles from "../../styles";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { reducer, sliceKey } from "../../../redux/slice";
import { usePropertySaga } from "../../../redux/saga";
import { useSelector } from "react-redux";
import { getPropertyListResponse } from "../../../redux/selector";
import { style } from "@mui/system";
import { ElevatorSharp } from "@mui/icons-material";
import { LoggerClass as Logger } from "utils/logger";
const PortfolioDialog = ({ showPopup, closePopup }) => {
  const styles = useStyles();
  const items = useSelector(getPropertyListResponse);
  const [show, setShow] = useState(false);
  const [reportType, setReportType] = useState<any>('Choose report type');
  const [selectedProperties, setSelectedProperties] = useState<any>(['Choose property']);
  const [timeRange, setTimeRange] = useState<any>('Choose Time Range');
  const [propertyplaceholder, setPropertyPlaceholder] = useState<any>('Choose Property');
  const [buttonEnable, setButtonEnable] = useState(true);
  const [okScreen, setOkScreen] = useState(false);
  
  const close = () => {
    setShow(false);
    closePopup();
  }
  const showNext = () => {
    setOkScreen(true);
  }
  useEffect(() => {
    if (reportType !== 'Choose report type' && timeRange !== 'Choose Time Range' && selectedProperties.length > 1) {
      setButtonEnable(false);
    } else {
      setButtonEnable(true);
    }    
  }, [
    reportType, selectedProperties, timeRange
  ]);
  useEffect(() => {
    setShow(showPopup);
    setPropertyPlaceholder("Choose property");
  }, [showPopup]);
  useEffect(() => {
       }, [showNext]);
  const handleReportTypeChange = (event) => {
    const option = event.target.value;
    setReportType(option);
  };

  const handleTimeRangeChange = (event) => {
    const option = event.target.value;
    setTimeRange(option);
  };

  const handleSelectedPropertiesChange = (event) => {
    const option = event.target.value;
    Logger.log(selectedProperties);
    if(option.includes('Choose All')) {
      items?.map((val) => {
        if (!selectedProperties.includes(val?.Address)) {
          setSelectedProperties(selectedProperties=>selectedProperties.concat(val?.Address));
        }
      });
    } else {
    option.map((val) => {
      if (!selectedProperties.includes(val) && val !== 'Choose All') {
        setSelectedProperties(selectedProperties.concat(val));
      }
    });
  }
  };


  const NewIcon = (props) => (
    <KeyboardArrowDownIcon
      {...props}
      sx={{ color: "#192342" }}
    />
  );

  return <Dialog
    open={show}
    className={styles.popup}
  >
    {!okScreen && <Box sx={{ display: "flex", flexDirection: "column", margin: "16px" }}>
      <Typography className={styles.title}>Generate Report</Typography>
      <Typography sx={{ margin: "24px 0px" }} className={styles.typoWrap40016}>
        Select Report Type, Time Range you want to generate and for what Property:
      </Typography>
      <Box sx={{ display: "flex", flexDirection: "column", width: '100%' }}>
        <Typography className={styles.typo60014} sx={{ marginLeft: "16px" }}>Report Type</Typography>
        <Select
          MenuProps={{ classes: { paper: styles.menuPaper } }}
          id="reportType"
          value={reportType}
          className={styles.select}
          onChange={handleReportTypeChange}
          IconComponent={NewIcon}
          placeholder={"Choose report type"}
          renderValue={() => <Typography className={styles.typoGray40016}>
            {reportType}
          </Typography>}
        >          
          <MenuItem value={"Maintenance statement"} style={{whiteSpace: 'normal'}}>
            <Radio
              className={styles.radio}
              checked={reportType === 'Maintenance statement'}
            />
            <Typography className={styles.typoGray40016}>Maintenance statement</Typography>
          </MenuItem>
          <MenuItem value={"Incoming statement"} style={{whiteSpace: 'normal'}}>  <Radio
            className={styles.radio}
            checked={reportType === 'Incoming statement'}
          />
            <Typography className={styles.typoGray40016}>Incoming statement</Typography>
          </MenuItem>
          <MenuItem value={"General statement"} style={{whiteSpace: 'normal'}}>  <Radio
            className={styles.radio}
            checked={reportType === 'General statement'}
          />
            <Typography className={styles.typoGray40016}>General statement</Typography>
          </MenuItem>
        </Select>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", width: '100%' }}>
        <Typography className={styles.typo60014} sx={{ marginLeft: "16px" }}>Properties</Typography>

        <Select
          MenuProps={{ classes: { paper: styles.menuPaper } }}
          multiple
          placeholder="Choose property"
          value={selectedProperties}
          onChange={handleSelectedPropertiesChange}
          IconComponent={NewIcon}
          className={styles.select}
          renderValue={() => <Typography className={styles.typoGray40016}>
            {propertyplaceholder}
          </Typography>}
        >
          <MenuItem style={{whiteSpace: 'normal'}} value={"Choose All"}>
          <Checkbox 
              className={styles.checkbox}
              checked={selectedProperties?.length === Number(items.length+1)} 
              onChange={(e) => {
                if (!e.target.checked) {
                  setSelectedProperties(['Choose property']);
                }
              }}             
            />
            <Typography className={styles.typoGray40016}>Choose All</Typography>
          </MenuItem>
          {
            items.map((item) => (
              <MenuItem style={{whiteSpace: 'normal'}} key={item?.PropertyId} value={item?.Address?.toString()}>
                <Checkbox className={styles.checkbox} checked={selectedProperties.includes(item?.Address)}
                  onChange={(e) => {
                    if (!e.target.checked) {
                      setSelectedProperties(selectedProperties.filter(val => val !== item?.Address));
                    }
                  }}
                />
                <Typography className={styles.typoGray40016}>{item?.Address}</Typography>
              </MenuItem>
            ))
          }
        </Select>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        {          
          selectedProperties.map((item) => (
            ((item !== '' && item !== 'Choose property') && <Box className={styles.boxSimpleWhite}>
              <Typography className={styles.typo40016}>{item}</Typography>
              <CloseIcon sx={{ color: "#7D7D7D" }} onClick={() => {
                setSelectedProperties(selectedProperties.filter(val => val !== item));
              }} />
            </Box>)
          ))
        }
      </Box>
      <Box sx={{ display: "flex", flexDirection: "column", width: '100%'}}>
        <Typography className={styles.typo60014} sx={{ marginLeft: "16px" }}>Time Range</Typography>
        <Select
          MenuProps={{ classes: { paper: styles.menuPaper } }}
          id="timeRange"
          value={timeRange}
          className={styles.select}
          onChange={handleTimeRangeChange}
          IconComponent={NewIcon}
          renderValue={() =>
          (<Typography className={styles.typoGray40016}>
            {timeRange}
          </Typography>)
          }
        >
          <MenuItem value={"All Time"} style={{whiteSpace: 'normal'}}>
            <Radio
              className={styles.radio}
              checked={timeRange === 'All Time'}
            />
            <Typography className={styles.typoGray40016}>All Time</Typography>
          </MenuItem>
          <MenuItem value={"Annual"} style={{whiteSpace: 'normal'}}>  <Radio
            className={styles.radio}
            checked={timeRange === 'Annual'}
          />
            <Typography className={styles.typoGray40016}>Annual</Typography>
          </MenuItem>
          <MenuItem value={"Last Month"} style={{whiteSpace: 'normal'}}>  <Radio
            className={styles.radio}
            checked={timeRange === 'Last Month'}
          />
            <Typography className={styles.typoGray40016}>Last Month</Typography>
          </MenuItem>
          <MenuItem value={"Custom"} style={{whiteSpace: 'normal'}}>  <Radio
            className={styles.radio}
            checked={timeRange === 'Custom'}
          />
            <Typography className={styles.typoGray40016}>Custom</Typography>
          </MenuItem>
        </Select>
      </Box>
      <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
        <Box sx={{ display: "flex", width: "48%" }}>
          <Button disabled={buttonEnable} className={styles.signInButton} onClick={showNext}>
            Continue
          </Button>
        </Box>
        <Box sx={{ display: "flex", width: "48%" }} onClick={close}>
          <Button className={styles.signInButtonColorInvert}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Box>}
    {okScreen &&
      <Box sx={{ display: "flex", flexDirection: "column", margin: "16px" }}>
        <Typography className={styles.title}>Generate Report</Typography>
        <Typography sx={{ margin: "24px 0px" }} className={styles.typo40014}>
          The report for the properties is being generated and will be sent to the email
          <label className={styles.typo70014}>{" "}alexander@gmail.com{" "}</label>
          in 10 minutes.
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {
            selectedProperties.map((item) => (
              ((item !== '' && item !== 'Choose property') && <Box className={styles.boxSimpleWhite}>
                <Typography className={styles.typo40016}>{item}</Typography>
              </Box>)
            ))
          }
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center" }}>
          <Button className={styles.signInButton} sx={{width:"40% !important"}} onClick={close}>
            OK
          </Button>
        </Box>
      </Box>}
  </Dialog >;
};

export default PortfolioDialog;
