import { FormError } from "app/containers/types";
import { CreateIndividualAccount } from "../../../redux/types";
import { ageValidator, emailValidator, stateValidator } from "utils/helpers";

export function valdiator(form: CreateIndividualAccount): Array<FormError> {
  const errors: Array<FormError> = [];
  if (!form.email.value) {
    errors.push({
      name: "email",
      error: "Email is required",
    });
  }
  if (form.email.value && !emailValidator(form.email.value)) {
    errors.push({
      name: "email",
      error: "Enter valid email",
    });
  }
  if (!form.firstName.value) {
    errors.push({
      name: "firstName",
      error: "First name is required",
    });
  }
  if (!form.lastName.value) {
    errors.push({
      name: "lastName",
      error: "Last name is required",
    });
  }  
  if (!form.city.value) {
    errors.push({
      name: "city",
      error: "City is required",
    });
  }

  if (!form.state.value) {
    errors.push({
      name: "state",
      error: "State is required",
    });
  }

  if (form.state.value && !stateValidator(form.state.value)) {
    errors.push({
      name: "state",
      error: "State must be a 2-letter abbreviation with Uppercase.",
    });
  }

  if (!form.zip.value) {
    errors.push({
      name: "zip",
      error: "Zip Code is required",
    });
  }

  if (!form.ssn.value) {
    errors.push({
      name: "ssn",
      error: "SSN is required",
    });
  }

  if(form.ssn.value && form.ssn.value.length !== 9) {
    errors.push({
      name: "ssn",
      error: "SSN should be 9 Digit",
    });
  }  

if(!form.dob.value)
{
  errors.push({
  name: "dob",
  error: "DOB is required",
});
}

 
if(form.dob.value && !ageValidator(form.dob.value))
{
errors.push({
name: "dob",
error: "Must be between 18 to 125 years of age.",
});
}

  if (form.zip.value && form.zip.value.length < 5) {
    errors.push({
      name: "zip",
      error: "Invalid ZipCode for US Country",
    });
  }

  return errors;
}
