import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const Gallery = () => {
  const photos = [
    { name: require("../../../../../assets/photo1.png"), id: 1 },
    { name: require("../../../../../assets/photo2.png"), id: 2 },
    { name: require("../../../../../assets/photo3.png"), id: 3 },
  ];
  const navigate = useNavigate();
  const [selectedPhoto, setSelectPhoto] = useState<any>(null);
  const [selected, setSelected] = useState<any>(null);
  return (
    <div>
      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "space-between",
          marginLeft: 16,
          marginRight: 16,
          marginTop: 18,
        }}
      >
        <img
          src={require("../../../../../assets/backArrow.png")}
          alt=""
          style={{ height: 16, width: 16 }}
          onClick={() => {
            navigate(-1);
          }}
        />

        <div
          style={{
            fontFamily: "Open Sans",
            fontSize: 18,
            color: "#192342",
            fontWeight: "600",
            alignSelf: "center",
          }}
        >
          Recent{" "}
          <img
            src={require("../../../../../assets/downArrow.png")}
            alt=""
            style={{ height: 27, width: 27 }}
          />
        </div>

        <div
          style={{
            fontFamily: "Open Sans",
            fontSize: 18,
            color: selectedPhoto ? "#F0BB00" : "#BCBCBC",
            fontWeight: "600",
            alignSelf: "center",
          }}
          onClick={() => {
            navigate(`/landlord-account/landlord-profile`, {
              state: selected,
            });
          }}
        >
          Add
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "space-between",
          marginLeft: 10,
          marginRight: 10,
          marginTop: 24,
        }}
      >
        {photos.map((item, index) => {
          return (
            // <div
            //   key={index.toString()}
            //   style={{
            //     border: "2px solid #F0BB00",
            //     // height: 110,
            //     // width: 110,
            //     // backgroundColor: "red",
            //   }}
            // >
            <img
              key={index.toString()}
              onClick={() => {
                setSelected(item?.name);
                setSelectPhoto(index + 1);
              }}
              src={item?.name}
              alt=""
              style={{
                height: 110,
                width: 110,
                border: selectedPhoto === index + 1 ? "2px solid #F0BB00" : "",
              }}
            />
            // </div>
          );
        })}
      </div>
    </div>
  );
};

export default Gallery;
