import {
    Button,
    Box,
    Dialog,
    Typography,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import { useStyles } from "../../styles";


  type Props = {};
  const InformationDialog = ({
    showPopup,
    closePopup,
    handleClose = () => {},
    handleChange = () => {},
  }) => {
    const [show, setShow] = useState(false);
    const styles = useStyles();

    const close = () => {
        setShow(false);
        closePopup();
      };
    
    useEffect(() => {
        setShow(showPopup);
      }, [showPopup]);

    return (
      <Dialog open={show} className={styles.dailogBox}>
        <Box sx={{ display: "flex", flexDirection: "column", margin: "16px" }}>
          <div
            style={{
              fontFamily: "Open Sans",
              fontSize: 18,
              color: "#192342",
              fontWeight: "400",
              textAlign: "center",
            }}
          >
          <Typography className={styles.title1} sx={{marginBottom: '20px !important'}}>Documents’ format required</Typography>

            <div className="">
            <Typography className={styles.text}>Must be a color image, in a .jpg, .jpeg, or .png format, and less than 10MB in size, besides Business Documents which can be uploaded in a .pdf format as well.</Typography>
            <Typography className={styles.text}>4 Edges of the document should be visible.</Typography>
            <Typography className={styles.text}>Background should be a dark/high contrast.</Typography>
            <Typography className={styles.text}>At least 90% of the image should be the document.</Typography>
            <Typography className={styles.text}>Should be at least 300dpi.</Typography>
            <Typography className={styles.text}>Capture image from directly above the document.</Typography>
            <Typography className={styles.text}>The image should be aligned, not rotated, tilted or skewed.</Typography>
            <Typography className={styles.text}>No flash to reduce glare.</Typography>
            <Typography className={styles.text}>No black and white documents.</Typography>
            <Typography className={styles.text}>No expired IDs.</Typography>
            </div>
          </div>
  
          <div
            style={{
              fontFamily: "Open Sans",
              fontSize: 18,
              color: "#192342",
              fontWeight: "400",
              textAlign: "center",
            }}
          >
          <Button
            className={styles.signInButton}
            onClick={close}
            style={{ marginTop: 24 }}
          >
            Close
          </Button>
          </div>
        </Box>
      </Dialog>
    );
  };
  
  export default InformationDialog;
  