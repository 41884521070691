import { Button, Typography } from "@mui/material";
import React from "react";
import useStyles from "./styles";
import { useNavigate } from "react-router";
import PageContainer from "app/components/Container/PageContainer";
type Props = {};
const ResetSuccessfull = (props: Props) => {
  const styles = useStyles();
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    navigate("/welcomeScreen");
  };
  return (
    <PageContainer>
        <Typography className={styles.pageHeading}>
          The password is successfully changed!
        </Typography>
        <Button
          className={styles.signInButton}
          variant="contained"
          onClick={handleSubmit}
        >
          Continue
        </Button>
    </PageContainer>
  );
};

export default ResetSuccessfull;
