import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  MenuItem,
  Radio,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import useStyles from "../../styles";
import uploadpng from "../../../../../assets/upload.png";
import CompletedPopup from "../completedPopup";
import ApprovePopUp from "../ApprovePopup";
import RejectedPopUp from "../RejectedPopup";
import OtpPopUp from "../OtpPopUp";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "app/containers/Tenant/redux/slice";
import { SelectedPortfolio, selectedServiceRequests } from "app/containers/Tenant/redux/selector";
import { ServiceStatus, ServiceType } from "app/containers/LandlordAccount/redux/Landlord.enum";
import { LoggerClass as Logger } from "utils/logger";

type Props = {};
const ServiceTickets = ({
  clickedStatus,
  showServiceTickets,
  setShowServiceTickets,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const [index, setIndex] = useState(3);
  const navigation = useNavigate();
  const [disabledButtons, setDisabledButtons] = useState();
  const [reportType, setReportType] = useState<any>();
  const [propertyOccupationStatus, setPropertyOccupationStatus] = useState("");
  
  const selectedRequests = useSelector(selectedServiceRequests);
  const selectPortfolio = useSelector(SelectedPortfolio);
  const handleReportTypeChange = (event) => {
    const option = event.target.value;
    Logger.log(event.target);
    setReportType(option);
    dispatch(actions.setSelectedServiceRequestStatusType(event.target.value));
  };
  const [docName, setDocName] = useState("");
  const [docUploaded, setDocUploaded] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [approvePopup, setApprovePopup] = useState(false);
  const [rejectPopup, setRejectPopup] = useState(false);
  const [otpPopup, setOtpPopup] = useState(false);
  const [username, setUsername] = useState("");

  useEffect(() => {
    if (selectedRequests?.WorkStatus ===  ServiceStatus.COMPLETED) {
      setShowPopup(true);
    }

    if(clickedStatus?.ServiceRequestID) 
    {
      dispatch(actions.setSelectedServiceRequest({id : clickedStatus?.ServiceRequestID, callback : getPropertyDetails}));
    }
  }, [clickedStatus?.ServiceRequestID]);

  const getPropertyDetails = () => {
    dispatch(
      actions.setSelectedPortfolioDetails({ id: selectedRequests?.PropertyId ? selectedRequests?.PropertyId : clickedStatus?.PropertyID })
    );
  }

  useEffect(() => {
    let fullname = "";
    selectPortfolio?.AllocatedUsers?.map((user, index) => {
      let tempname = user.UserName;
      if (index !== 0) {
        fullname = fullname + "," + tempname;
      } else {
        fullname = user.UserName + "";
      }
    });
    setUsername(fullname);
}, [selectPortfolio]);

  const closePopup = () => {
    setShowPopup(false);
    setApprovePopup(false);
    setRejectPopup(false);
    setOtpPopup(false);
  };

  let uploadedDocument;
  const goBack = () => {
    Logger.log("entry ");

    setShowServiceTickets(false);
  };
  const handleDocumentUpload = (event) => {
    uploadedDocument.click();
  };

  const closeDoc = () => {
    setDocUploaded(false);
  };

  const serviceType = (type) => { 
    switch (type) {
      case ServiceType.AC:
        return 'A/C';
      case ServiceType.ELECTRICAL:
        return 'Electrical';
      case ServiceType.HANDYMAN:
        return 'Handyman';
      case ServiceType.LOCKSMITH:
        return 'LockSmith';
      case ServiceType.PLUMBLING:
        return 'Plumbling';
      case ServiceType.STRUCTURE_SERVICE:
        return 'Structure Service';
      default:
        return '';
    }
  }

  const serviceStatus = (status) => { 
    switch (status) {
      case ServiceStatus.NEW:
        return 'New';
      case ServiceStatus.SELECT_TIME_FRAME:
        return 'Select Time Frame';
      case ServiceStatus.SCHEDULED_PENDING_PAYMENT:
        return 'Scheduled, Pending Payment';
      case ServiceStatus.PENDING_APPROVAL:
        return 'Pending Approval';
      case ServiceStatus.INPROGRESS:
        return 'InProgress';
      case ServiceStatus.COMPLETED_PENDING_PAYMENT:
        return 'Completed, Pending Payment';
      case ServiceStatus.COMPLETED:
        return 'Completed';
      case ServiceStatus.REOPEN:
        return 'ReOpen';
      case ServiceStatus.DISCARD:
        return 'Discard';
      default:
        return '';
    }
  }
  
  return (
    <>
      <div className={styles.container}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "24px",
            alignItems: "center",
          }}
        >
          <IconButton onClick={goBack}>
            <ArrowBackIcon
              sx={{
                height: 24,
                width: 24,
                color: "#192342",
              }}
            />
          </IconButton>
          <Typography className={styles.headerTitle}>
            Service request ticket {serviceType(selectedRequests?.ServiceType)} #{selectedRequests?.ServiceRequestID}
          </Typography>
        </Box>

        {selectedRequests?.WorkStatus ===  ServiceStatus.COMPLETED ? (
          <CompletedPopup
            showPopup={showPopup}
            closePopup={closePopup}
            continueClick={() => {
              setShowPopup(false);
              setShowServiceTickets(false);
            }}
          />
        ) : null}

        {approvePopup ? (
          <ApprovePopUp
            showPopup={approvePopup}
            closePopup={closePopup}
            continueClick={() => {
              setApprovePopup(false);
            }}
          />
        ) : null}

        {rejectPopup ? (
          <RejectedPopUp
            showPopup={rejectPopup}
            closePopup={closePopup}
            continueClick={() => {
              setRejectPopup(false);
            }}
          />
        ) : null}

        {otpPopup ? (
          <OtpPopUp
            showPopup={otpPopup}
            closePopup={closePopup}
            continueClick={() => {
              setOtpPopup(false);
            }}
          />
        ) : null}

        {selectedRequests?.WorkStatus === ServiceStatus.SELECT_TIME_FRAME ||
        selectedRequests?.WorkStatus === ServiceStatus.NEW ||
        selectedRequests?.WorkStatus === ServiceStatus.SCHEDULED_PENDING_PAYMENT ||
        selectedRequests?.WorkStatus === ServiceStatus.INPROGRESS ||
        selectedRequests?.WorkStatus === ServiceStatus.PENDING_APPROVAL ||
        selectedRequests?.WorkStatus === ServiceStatus.COMPLETED_PENDING_PAYMENT ||
        selectedRequests?.WorkStatus === ServiceStatus.REOPEN ||
        selectedRequests?.WorkStatus === ServiceStatus.DISCARD ? (
          <>
            <div className={styles.label}>Address</div>
            <TextField
              color="primary"
              variant="outlined"
              required
              placeholder={"N/A"}
              name="PropertyAddress"
              value={selectedRequests?.PropertyAddress ? selectedRequests?.PropertyAddress : "N/A"}
              disabled={selectedRequests?.WorkStatus === ServiceStatus.REOPEN ? false : true}
              onChange={(event) => {              
              }}
              className={styles.textStyle}             
            />
          </>
        ) : null}
        {selectedRequests?.WorkStatus === ServiceStatus.SELECT_TIME_FRAME ||
        selectedRequests?.WorkStatus === ServiceStatus.NEW ||
        selectedRequests?.WorkStatus === ServiceStatus.SCHEDULED_PENDING_PAYMENT ||
        selectedRequests?.WorkStatus === ServiceStatus.INPROGRESS ||
        selectedRequests?.WorkStatus === ServiceStatus.PENDING_APPROVAL ||
        selectedRequests?.WorkStatus === ServiceStatus.REOPEN ||
        selectedRequests?.WorkStatus === ServiceStatus.COMPLETED_PENDING_PAYMENT ? (
          <>
            <div>
              <div className={styles.label}>Tenant</div>
              <TextField
                color="primary"
                variant="outlined"
                required
                placeholder={"John Smith"}
                name="tanantName"
                value={username ? username : "N/A"}
                disabled={selectedRequests?.WorkStatus === ServiceStatus.REOPEN ? false : true}
                onChange={(event) => {}}
                className={styles.textStyle}
              />
            </div>
          </>
        ) : null}
        {selectedRequests?.WorkStatus === ServiceStatus.SELECT_TIME_FRAME ||
        selectedRequests?.WorkStatus === ServiceStatus.REOPEN ||
        selectedRequests?.WorkStatus === ServiceStatus.NEW ||
        selectedRequests?.WorkStatus === ServiceStatus.SCHEDULED_PENDING_PAYMENT ||
        selectedRequests?.WorkStatus === ServiceStatus.INPROGRESS ||
        selectedRequests?.WorkStatus === ServiceStatus.PENDING_APPROVAL ||
        selectedRequests?.WorkStatus === ServiceStatus.COMPLETED_PENDING_PAYMENT ? (
          <>
            <div>
              <div className={styles.label}>Service Provider</div>
              <TextField
                color="primary"
                variant="outlined"
                required
                placeholder={"N/A"}
                name="ServiceProviderName"
                value={selectedRequests?.ServiceProviderName && selectedRequests?.ServiceProviderName !== " " ? selectedRequests?.ServiceProviderName : "N/A"}                
                disabled={selectedRequests?.WorkStatus === ServiceStatus.REOPEN ? false : true}
                onChange={(event) => {}}
                className={styles.textStyle}
              />
            </div>
          </>
        ) : null}

        {selectedRequests?.WorkStatus === ServiceStatus.SELECT_TIME_FRAME ||
        selectedRequests?.WorkStatus === ServiceStatus.REOPEN ||
        selectedRequests?.WorkStatus === ServiceStatus.NEW ||
        selectedRequests?.WorkStatus === ServiceStatus.SCHEDULED_PENDING_PAYMENT ||
        selectedRequests?.WorkStatus === ServiceStatus.INPROGRESS ||
        selectedRequests?.WorkStatus === ServiceStatus.PENDING_APPROVAL ||
        selectedRequests?.WorkStatus === ServiceStatus.COMPLETED_PENDING_PAYMENT ? (
          <>
            <div className={styles.label}>Job Status</div>
            <Select
              id="reportType"
              value={selectedRequests?.WorkStatus}
              className={styles.select}
              name="WorkStatus"
              onChange={handleReportTypeChange}
              renderValue={() => (
                <Typography className={styles.typoGray40016}>
                  {serviceStatus(selectedRequests?.WorkStatus)}
                </Typography>
              )}
            >
              <MenuItem value={ServiceStatus.SELECT_TIME_FRAME}>
                {serviceStatus(ServiceStatus.SELECT_TIME_FRAME)}
              </MenuItem>
              <MenuItem value={ServiceStatus.NEW}>{serviceStatus(ServiceStatus.NEW)}</MenuItem>
              <MenuItem value={ServiceStatus.SCHEDULED_PENDING_PAYMENT}>
                {serviceStatus(ServiceStatus.SCHEDULED_PENDING_PAYMENT)}
              </MenuItem>
              <MenuItem value={ServiceStatus.INPROGRESS}>{serviceStatus(ServiceStatus.INPROGRESS)}</MenuItem>
              <MenuItem value={ServiceStatus.PENDING_APPROVAL}>
                {serviceStatus(ServiceStatus.PENDING_APPROVAL)}
              </MenuItem>
              <MenuItem value={ServiceStatus.COMPLETED_PENDING_PAYMENT}>
                {serviceStatus(ServiceStatus.COMPLETED_PENDING_PAYMENT)}
              </MenuItem>
              <MenuItem value={ServiceStatus.COMPLETED}>{serviceStatus(ServiceStatus.COMPLETED)}</MenuItem>
              <MenuItem value={ServiceStatus.REOPEN}>{serviceStatus(ServiceStatus.REOPEN)}</MenuItem>
              <MenuItem value={ServiceStatus.DISCARD}>{serviceStatus(ServiceStatus.DISCARD)}</MenuItem>
            </Select>
          </>
        ) : null}
        {selectedRequests?.WorkStatus === ServiceStatus.SELECT_TIME_FRAME ||
        selectedRequests?.WorkStatus === ServiceStatus.REOPEN ||
        selectedRequests?.WorkStatus === ServiceStatus.NEW ||
        selectedRequests?.WorkStatus === ServiceStatus.SCHEDULED_PENDING_PAYMENT ||
        selectedRequests?.WorkStatus === ServiceStatus.INPROGRESS ||
        selectedRequests?.WorkStatus === ServiceStatus.PENDING_APPROVAL ||
        selectedRequests?.WorkStatus === ServiceStatus.COMPLETED_PENDING_PAYMENT ? (
          <>
            <div>
              <div className={styles.label}>Service Estimate</div>
              <TextField
                color="primary"
                variant="outlined"
                required                
                placeholder={
                  selectedRequests?.WorkStatus === ServiceStatus.PENDING_APPROVAL && selectedRequests?.EstimatedCost ? selectedRequests?.EstimatedCost+"$" : "N/A"
                }
                disabled={
                  selectedRequests?.WorkStatus === ServiceStatus.REOPEN ||
                  selectedRequests?.WorkStatus === ServiceStatus.PENDING_APPROVAL
                    ? false
                    : true
                }
                type="email"
                onChange={(event) => {                
                }}
                className={styles.textStyle}                
              />
            </div>
          </>
        ) : null}
        {selectedRequests?.WorkStatus === ServiceStatus.SELECT_TIME_FRAME ||
        selectedRequests?.WorkStatus === ServiceStatus.REOPEN ||
        selectedRequests?.WorkStatus === ServiceStatus.NEW ||
        selectedRequests?.WorkStatus === ServiceStatus.SCHEDULED_PENDING_PAYMENT ? (
          <>
            <div>
              <div className={styles.label}>Ticket Updates</div>
              <TextField
                color="primary"
                variant="outlined"
                required
                placeholder={"Ticket Updates will be shown here"}
                value={selectedRequests?.Description}
                disabled={selectedRequests?.WorkStatus === ServiceStatus.REOPEN ? false : true}
                type="email"
                onChange={(event) => {}}
                className={styles.textStyle}
              />
            </div>
          </>
        ) : null}
        {selectedRequests?.WorkStatus === ServiceStatus.INPROGRESS ||
        selectedRequests?.WorkStatus === ServiceStatus.PENDING_APPROVAL ? (
          <>
            <div>
              <div className={styles.label}>Ticket Updates</div>
              <TextField
                color="primary"
                variant="outlined"
                required
                placeholder={
                  "Kitchen Tap spills water on the floor when its open "
                }
                disabled={true}
                type="email"
                onChange={(event) => {}}
                className={styles.textStyle}
              />
            </div>
          </>
        ) : null}
        {selectedRequests?.WorkStatus === ServiceStatus.COMPLETED_PENDING_PAYMENT ? (
          <>
            <div className={styles.label}>Ticket Updates</div>
            <div className={styles.card}>
              <div className={styles.cardText}>
                <div>
                  <li> Service pro authentication success;</li>{" "}
                </div>
                <div>
                  <li> John says: “need to replace the tap”;</li>
                </div>
                <div>
                  <li> John updated Job Estimate - 600$;</li>
                </div>
                <div>
                  {" "}
                  <li>
                    Landlord Approved the estimate with Comment - “Please Upload
                    the receipt”;
                  </li>{" "}
                </div>
                <div>
                  <li>
                    John says - “Handyman replaced the Tap, it works okay now. I
                    have uploaded the receipt and I am closing the ticket”.
                  </li>
                </div>
              </div>
            </div>
          </>
        ) : null}
        {selectedRequests?.WorkStatus === ServiceStatus.SELECT_TIME_FRAME ||
        selectedRequests?.WorkStatus === ServiceStatus.REOPEN ||
        selectedRequests?.WorkStatus === ServiceStatus.NEW ||
        selectedRequests?.WorkStatus === ServiceStatus.SCHEDULED_PENDING_PAYMENT ||
        selectedRequests?.WorkStatus === ServiceStatus.INPROGRESS ||
        selectedRequests?.WorkStatus === ServiceStatus.PENDING_APPROVAL ||
        selectedRequests?.WorkStatus === ServiceStatus.COMPLETED_PENDING_PAYMENT ? (
          <>
            <div>
              <div className={styles.label}>Post a Ticket Update</div>
              <TextField
                color="primary"
                variant="outlined"
                required
                placeholder={"Type here"}                
                type="text"
                onChange={(event) => {                
                }}
                className={styles.textStyle}
              />
            </div>
          </>
        ) : null}
        {selectedRequests?.WorkStatus === ServiceStatus.SELECT_TIME_FRAME ||
        selectedRequests?.WorkStatus === ServiceStatus.REOPEN ? (
          <>
            <div className={styles.subTitle}>Choose Suggested Time frames</div>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Radio
                className={styles.checkbox}
                checked={
                  propertyOccupationStatus === " 25 Sep 2022 / 13:00 - 15:00"
                }
                onChange={(e) => {
                  if (e.target.checked) {
                    setPropertyOccupationStatus(" 25 Sep 2022 / 13:00 - 15:00");
                  } else {
                    setPropertyOccupationStatus("");
                  }
                }}
              />
              <div className={styles.radioContent}>
                25 Sep 2022 / 13:00 - 15:00
              </div>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Radio
                className={styles.checkbox}
                checked={
                  propertyOccupationStatus === " 26 Sep 2022 / 12:00 - 14:00"
                }
                onChange={(e) => {
                  if (e.target.checked) {
                    setPropertyOccupationStatus(" 26 Sep 2022 / 12:00 - 14:00");
                  } else {
                    setPropertyOccupationStatus("");
                  }
                }}
              />
              <div className={styles.radioContent}>
                26 Sep 2022 / 12:00 - 14:00
              </div>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Radio
                className={styles.checkbox}
                checked={
                  propertyOccupationStatus === " 28 Sep 2022 / 16:00 - 18:00"
                }
                onChange={(e) => {
                  if (e.target.checked) {
                    setPropertyOccupationStatus(" 28 Sep 2022 / 16:00 - 18:00");
                  } else {
                    setPropertyOccupationStatus("");
                  }
                }}
              />
              <div className={styles.radioContent}>
                28 Sep 2022 / 16:00 - 18:00
              </div>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Radio
                className={styles.checkbox}
                checked={propertyOccupationStatus === "Ask another Time frames"}
                onChange={(e) => {
                  if (e.target.checked) {
                    setPropertyOccupationStatus("Ask another Time frames");
                  } else {
                    setPropertyOccupationStatus("");
                  }
                }}
              />
              <div className={styles.radioContent}>Ask another Time frames</div>
            </Box>
          </>
        ) : null}

        {selectedRequests?.WorkStatus === ServiceStatus.PENDING_APPROVAL ||
        selectedRequests?.WorkStatus === ServiceStatus.SELECT_TIME_FRAME ||
        selectedRequests?.WorkStatus === ServiceStatus.REOPEN ||
        selectedRequests?.WorkStatus === ServiceStatus.NEW ||
        selectedRequests?.WorkStatus === ServiceStatus.SCHEDULED_PENDING_PAYMENT ||
        selectedRequests?.WorkStatus === ServiceStatus.INPROGRESS ||
        selectedRequests?.WorkStatus === ServiceStatus.COMPLETED_PENDING_PAYMENT ? (
          <>
            <Button
              className={styles.signInButtonColorInvert}
              style={{
                marginLeft: 16,
                marginRight: 16,
                width: "90%",
                marginTop: 10,
              }}
              variant="contained"
              onClick={handleDocumentUpload}
            >
              <InputAdornment position="end">
                <span style={{ cursor: "pointer" }}>
                  <IconButton sx={{ color: "#D1D0D0" }}>
                    <img src={uploadpng} alt={""} />
                  </IconButton>
                </span>
              </InputAdornment>
              <Typography
                sx={{
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  fontWeight: 700,
                  fontSize: "14px",
                  letterSpacing: "0.2px",
                }}
              >
                Upload a photo/video
              </Typography>
              <input
                type="file"
                accept=".pdf"
                ref={(refParam) => (uploadedDocument = refParam)}
                value={uploadedDocument}
                onChange={async (e: ChangeEvent<HTMLInputElement>) => {
                  if (e.target.files) {
                    setDocUploaded(true);
                    setDocName(e.target.files[0].name);
                  }
                }}
                hidden
              />
            </Button>
          </>
        ) : null}
        {selectedRequests?.WorkStatus === ServiceStatus.PENDING_APPROVAL ||
        selectedRequests?.WorkStatus === ServiceStatus.SELECT_TIME_FRAME ||
        selectedRequests?.WorkStatus === ServiceStatus.REOPEN ||
        selectedRequests?.WorkStatus === ServiceStatus.NEW ||
        selectedRequests?.WorkStatus === ServiceStatus.SCHEDULED_PENDING_PAYMENT ||
        selectedRequests?.WorkStatus === ServiceStatus.INPROGRESS ||
        selectedRequests?.WorkStatus === ServiceStatus.COMPLETED_PENDING_PAYMENT ? (
          <>
            <Button
              className={styles.signInButtonYellow}
              //   variant="contained"
              style={{ height: 50 }}
              onClick={() => {}}
              disabled={disabledButtons}
            >
              <Typography
                className={
                  disabledButtons
                    ? styles.exitButtonTypoDisabledBlue
                    : styles.exitButtonTypoBlue
                }
              >
                View media
              </Typography>
            </Button>
          </>
        ) : null}
        {selectedRequests?.WorkStatus === ServiceStatus.PENDING_APPROVAL ||
        selectedRequests?.WorkStatus === ServiceStatus.SELECT_TIME_FRAME ||
        selectedRequests?.WorkStatus === ServiceStatus.REOPEN ||
        selectedRequests?.WorkStatus === ServiceStatus.NEW ||
        selectedRequests?.WorkStatus === ServiceStatus.SCHEDULED_PENDING_PAYMENT ||
        selectedRequests?.WorkStatus === ServiceStatus.INPROGRESS ||
        selectedRequests?.WorkStatus === ServiceStatus.COMPLETED_PENDING_PAYMENT ? (
          <>
            <Button
              className={styles.signInButton}
              //   variant="contained"
              onClick={() => {
                if (selectedRequests?.WorkStatus === ServiceStatus.SCHEDULED_PENDING_PAYMENT) {
                  setOtpPopup(true);
                }
              }}
              style={{ marginTop: 24 }}
              disabled={disabledButtons}
            >
              <Typography
                className={
                  disabledButtons
                    ? styles.exitButtonTypoDisabled
                    : styles.exitButtonTypo
                }
              >
                Submit{" "}
              </Typography>
            </Button>
          </>
        ) : null}
      </div>
    </>
  );
};

export default ServiceTickets;
