import { FormElement } from "../../../types";


export type FundingSource = {
  userId : FormElement;
  userName: FormElement;
};

export type CreatedAccessToken = {
  accessToken : string; 
  accountId : string;
  userId : string;
  userName : string;
  userInfoId : string;
  location : string;
};

export type CreatedProcessToken = {
  FundingSourceDwollaStatus : string,
  FundingSourceDwollaToken : string,
  FundingSourceId : string,
  PlaidProcessorToken : string,
  UserId : string
}

export type IdVerificationRequest = {
  UserId : FormElement,
  Type : FormElement,
  LandlordType :FormElement,
  Benificary : FormElement
}

export type IdVerificationUserResponse = {
  status : string,
  userId : string,
}

export type IdVerificationBankResponse = {
  status : string,
  userId : string,
}

export type IdVerificationOwnerResponse = {
  status : string,
  userId : string,
}

export type FundingsType = {
  error: string | null;
  loading: boolean; 
  fundingSource : FundingSource;  
  linkToken : string;
  createdAccessToken : CreatedAccessToken;
  createdProcessToken : CreatedProcessToken;
  idVerificationRequest : IdVerificationRequest;
  idVerificationUserResponse : IdVerificationUserResponse; // will change accordinigly
  idVerificationBankResponse : IdVerificationBankResponse; // will change accordinigly
  idVerificationOwnerResponse : IdVerificationOwnerResponse; // will change accordinigly
};
export const FundingState: FundingsType = {
  error: "",
  loading: false,
  fundingSource : {
    userId: {value: ""},
    userName : {value: ""},
  },
  linkToken : "",
  createdAccessToken : {
    accessToken : "",
    accountId : "",
    userId : "",
    userName : "",
    userInfoId : "",
    location : ""
  },
  createdProcessToken : {
    FundingSourceDwollaStatus : "",
    FundingSourceDwollaToken : "",
    FundingSourceId : "",
    PlaidProcessorToken : "",
    UserId : ""
  },
  idVerificationRequest : {
    UserId : {value : ""},
    Type : {value : ""},
    LandlordType : {value : ""},
    Benificary : {value : ""}
  },
  idVerificationUserResponse : {
    status : "",
    userId : ""
  },
  idVerificationBankResponse : {
    status : "",
    userId : ""
  },
  idVerificationOwnerResponse : {
    status : "",
    userId : ""
  },
};



/*
  If you want to use 'ContainerState' keyword everywhere in your feature folder,
  instead of the 'HomePageState' keyword.
*/
export type ContainerState = FundingsType;
