import { Box, IconButton, Typography } from "@mui/material";
import React, { ChangeEvent, useEffect, useState } from "react";
import stepTwoImage from "../../assets/step2.png";
import uploadImage from "../../assets/upload.png";
import useStyle from "../../styles";
import CloseIcon from "@mui/icons-material/Close";
import { actions } from "app/containers/CommonComponent/Property/redux/slice";
import { actions as mediaActions } from "app/containers/CommonComponent/S3UploadSaga/slice";
import { useDispatch, useSelector } from 'react-redux';
import { uploadFile } from "utils/s3Helper";
import { selectLoading, selectUploadedVideoURL, selectVideoFile } from "app/containers/CommonComponent/S3UploadSaga/selector";
import Loader from "app/components/Loader";

const StepTwoComponent = ({
  selectedService,
  inputImage,
  imageStatus,
  videoStatus,
}) => {
  const styles = useStyle();
  let uploadedImage;
  const [isImageButtonShow, setIsImageButtonShow] = useState(imageStatus);
  const [imageUploaded, setImageUploaded] = useState<any>(inputImage);
  let uploadedVideo;
  const [isVideoButtonShow, setIsVideoButtonShow] = useState(videoStatus);
  const [fileProgress, setFileProgress] = useState<any>(0);

  const dispatch = useDispatch();
  const selectedVideoFile: any = useSelector(selectVideoFile);
  const uploadedVideoURL = useSelector(selectUploadedVideoURL);
  const loading = useSelector(selectLoading);
  
  useEffect(() => {
    if(!imageUploaded) {
      setImageUploaded("");
      setIsImageButtonShow(true);
    }
  }, [imageUploaded]);

  useEffect(() => {
    if(!selectedVideoFile) {
      setIsVideoButtonShow(true);
    }
    if(selectedVideoFile) {   
    dispatch(mediaActions.getUploadUrl({ fileName: selectedVideoFile?.name, contentType: 'properties/video' }));
    }
  }, [selectedVideoFile]);

  useEffect(() => {
    if (uploadedVideoURL && uploadedVideoURL.bucketURL) {
      uploadFilesToS3();
    }
 }, [uploadedVideoURL]);

 const uploadFilesToS3 = async () => {
  dispatch(mediaActions.startLoading());
  setFileProgress(0)
  uploadFile(selectedVideoFile, uploadedVideoURL.bucketURL, onProgress);
}

const onProgress = (fileName: any, data: any) => {

  if (data !== 'start' && data !== "end") {
      setFileProgress(data)
  }
  if (data === "end") {  
      const regex = /(.*)\?.*/;
      const result = uploadedVideoURL.bucketURL.match(regex)[1];   
      dispatch(mediaActions.updateVideoUrl(result));
      dispatch(mediaActions.stopLoading());
  }
}

  async function getBase64(file) {
    let result_base64 = await new Promise((resolve) => {
      let fileReader = new FileReader();
      fileReader.onload = (e) => resolve(fileReader.result);
      fileReader.readAsDataURL(file);
    });
    return result_base64;
  }
  const handleImageUpload = (event) => {
    uploadedImage.click();
  };
  const handleVideoUpload = (event) => {
    uploadedVideo.click();
  };

  useEffect(() => {
    selectedService({
      status: false, //isImageButtonShow || isVideoButtonShow,
      uploadedImage: imageUploaded,
      imageStatus: isImageButtonShow,
      videoStatus: isVideoButtonShow,
    });
  }, [imageUploaded, isImageButtonShow, isVideoButtonShow]);

  const handleFileChange = (event) => {
    setIsVideoButtonShow(false);
    dispatch(mediaActions.setVideoFile({
        file: {
            name: event.target.files[0].name,
            type: event.target.files[0].type,
            size: event.target.files[0].size,
            url: URL.createObjectURL(event.target.files[0])
        }
    }))   
  };
 
  return (
    <>
    <Loader loading={loading} />
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginBottom: "24px",
        }}
      >
        <img
          src={stepTwoImage}
          style={{ width: "80px", height: "80px" }}
          alt="Step 2"
        />
      </Box>
      <Typography
        sx={{ marginBottom: "10px" }}
        className={styles.typo60018AlignCenter}
      >
        Take a picture/video of the issue
      </Typography>
      <Typography sx={{ marginBottom: "24px" }} className={styles.typo40014}>
        Add photos and/or short video to visualise the issue or skip this step
        and click next.
      </Typography>
      <Box
        sx={{ display: "flex", flexDirection: "column", marginBottom: "24px" }}
      >
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          {!isImageButtonShow && (
            <Box
              className={styles.uploadedImage}
              sx={{ marginBottom: "16px", postion: "relative" }}
            >
              <Box
                sx={{
                  width: "142px",
                  height: "78px",
                  alignSelf: "center",
                  flex: "1",
                }}
              >
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                  src={imageUploaded}
                  alt="not uploaded"
                />
              </Box>
              <IconButton
                sx={{
                  position: "relative",
                  right: "0px",
                  top: "5px",
                  marginLeft: "auto",
                  alignSelf: "flex-start",
                }}
                onClick={() => {
                  setIsImageButtonShow(true);
                }}
              >
                <CloseIcon
                  sx={{
                    width: "12px",
                    height: "12px",
                    color: "#7D7D7D",
                  }}
                />
              </IconButton>
            </Box>
          )}
          {isImageButtonShow && (
            <Box className={styles.imageBox} sx={{ marginBottom: "16px" }}>
              <IconButton
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={handleImageUpload}
              >
                <img src={uploadImage} alt="Upload" />
                <input
                  type="file"
                  accept="image/*"
                  ref={(refParam) => (uploadedImage = refParam)}
                  value={uploadedImage}
                  onChange={async (e: ChangeEvent<HTMLInputElement>) => {
                    if (e.target.files) {
                      getBase64(e.target.files[0]).then((inputString) => {
                        setImageUploaded(inputString);
                        setIsImageButtonShow(false);
                      });
                      dispatch(
                        mediaActions.getDocumentUploadUrl({
                          fileData: e.target.files[0],
                          contentType: "properties/images",
                        })
                      );
                    }
                  }}
                  hidden
                />
              </IconButton>
              <Typography className={styles.typo40016}>
                Upload a photo
              </Typography>
            </Box>
          )}
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row" }}>
          {!isVideoButtonShow && (
            <Box
              className={styles.uploadedImage}
              sx={{ marginBottom: "16px", postion: "relative" }}
            >
              <Box
                sx={{
                  width: "142px",
                  height: "78px",
                  alignSelf: "center",
                  flex: "1",
                }}
              >
                
                <video src={selectedVideoFile?.url} style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}></video>
              </Box>
              <IconButton
                sx={{
                  position: "relative",
                  right: "0px",
                  top: "5px",
                  marginLeft: "auto",
                  alignSelf: "flex-start",
                }}
                onClick={() => {
                  setIsVideoButtonShow(true);
                }}
              >
                <CloseIcon
                  sx={{
                    width: "12px",
                    height: "12px",
                    color: "#7D7D7D",
                  }}
                />
              </IconButton>
            </Box>
          )}
          {isVideoButtonShow && (
            <Box className={styles.imageBox} sx={{ marginBottom: "16px" }}>
              <IconButton
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={handleVideoUpload}
              >
                <img src={uploadImage} alt="Upload" />
                <input
                  type="file"
                  accept="video/*"
                  ref={(refParam) => (uploadedVideo = refParam)}
                  value={uploadedVideo}
                  onChange={handleFileChange}
                  hidden
                />
              </IconButton>
              <Typography className={styles.typo40016}>
                Upload a video
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
    </>
  );
};
export default StepTwoComponent;
