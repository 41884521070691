import { Box, Button, Typography } from "@mui/material";
import Page from "app/components/Page";
import PageContent from "app/components/PageContent";
import PageImageHeader from "app/components/PageImageHeader";
import React, { useCallback, useEffect, useRef, useState } from "react";
import OtpInput from "react-otp-input";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import {
  selectError,
  selectInvalidAttempts,
  selectOtp,
  selectOtpError,
  selectType,
  selectVerificationSRE,
} from "../selectors";
import { actions } from "../slice";
import { actions as landlordProfileActions} from "../../LandlordAccount/LandlordProfile/redux/slice";
import { useStyles } from "./styles";
import TimerCount from "./components/TimerCount";
import useWindowDimensions from "utils/hooks/useWindowDimensions";

type Props = {};

const OtpVerification = (props: Props) => {
  const { width, height } = useWindowDimensions();
  const dispatch = useDispatch();

  const otp = useSelector(selectOtp);
  const verification = useSelector(selectVerificationSRE);

  const [timerEnded, setTimerEnded] = useState(false);
  const [error, setError] = useState(false);
  const [resendOTP, setResendOTP] = useState(false);

  useEffect(() => {
 
  }, [otp]);

  const [clicked, setClicked] = useState<boolean>(false);
  const styles = useStyles();
  const typeLogin = useSelector(selectType);

  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;
  const invalidAttempt = useSelector(selectInvalidAttempts);
  const redirect = useSelector(selectError);
  const otpError = useSelector(selectOtpError);
 
  useEffect(() => {
  },[otpError])
  useEffect(() => {
    if (redirect === "Unauthorized") {
      dispatch(actions.resetModule());
      navigate("/auth");
    }
  }, [redirect]);

  useEffect(() => {
    if (!verification.phoneNumber.value) {
      navigate(-1);
    }
  }, [verification.phoneNumber.value]);

  const onSuccess = (url = "/thankyou") => {
    dispatch(actions.setOtp(""));
    navigate(url);
  };

  const onChangeEvent = (otpValue: string) => {
    dispatch(actions.setOtpSRE({ value: otpValue }));
  };


  useEffect(
    useCallback(() => {
      setTimeout(() => {
        setClicked(false);
      }, 1000);
    }, [])
  );

  const handleVerifyOtpRequest = () => {
    dispatch(actions.setOtpErrorSRE());
    if (otp.value) {
      setError(false);
      dispatch(
        actions.verificationOtp({
          data: otp,
          callback: handleVerificationSuccess,
        })
      );
    } else {
      setError(true);
    }
  };

  const handleVerificationSuccess = () => {
    if(state === 'changeMobileNumber') {
       dispatch(landlordProfileActions.UpdateMobileNumber({data : verification.phoneNumber.value, callback: numberUpdated })); 
    } else {
    dispatch(actions.setOtpSRE({ value: "" }));
    navigate("/success-verification");
    }
  };

  const numberUpdated = () => {
    dispatch(actions.setOtpSRE({ value: "" }));
    navigate("/landlord-account/success-verification", {state : state});
  };

  const handleResendOTP = () => {
    dispatch(actions.setOtpSRE({ value: "" }));
    dispatch(actions.setOtpErrorSRE());
    dispatch(actions.otpRequest({ data: verification, callback: otpSent }));
  };

  const otpSent = () => {
    setTimeout(() => {
      setResendOTP(false);
    }, 5000);
    setResendOTP(true);
    setError(false);
  };

  return (
    <Page>
      <PageImageHeader
        subHeading={
          <div style={{ display: "flex" }}>
            <Typography sx={{ fontSize: 14, color: "#fff" }}>
              {"have account"}
            </Typography>
            <Typography
              sx={{
                fontSize: 14,
                color: "#FAA61A",
                fontWeight: "bold",
                textDecoration: "underline",
                marginLeft: "4px",
              }}
            >
              {"login"}
            </Typography>
          </div>
        }
      />

      <div
        className={styles.sheet}
        style={{
          width: width,
          display: "flex",
          flexDirection: "column",
          top: height / 2.82,
        }}
      >
        <div className={styles.title}>Verify phone number</div>

        <div className={styles.subTitle}>
          The code has been sent to the phone number + 1{" "}
          {verification.phoneNumber.value}
        </div>
        <div style={{ marginLeft: 20, marginRight: 15 }}>
          <OtpInput
            inputStyle={{
              height: 40,
              width: 40,
              backgroundColor: "#FFF7DD",
              justifyContent: "center",
              display: "flex",
              color: "#192342",
              fontSize: 16,
              borderRadius: 4,
              marginRight: 5,
            }}
            hasErrored={error}
            errorStyle={{ border: "1px solid #EB5757" }}
            focusStyle={{ backgroundColorL: "red" }}
            value={otp.value}
            className={styles.otpInputRoot}
            onChange={onChangeEvent}
            numInputs={6}
            isInputNum={true}
          />
        </div>
        {otpError ? (
          <div className={styles.footerTxt} style={{ color: "#EB5757" }}>
            You’ve entered invalid code. Please try again
          </div>
        ) : null}
        <div className={styles.footerTxt}>
          Didn’t receive the code?
          <span
            onClick={handleResendOTP}
            className={styles.footTxt}
            style={{ cursor: "pointer" }}
          >
            {" "}
            Request again
          </span>
        </div>

        <Button
          variant="contained"
          sx={{
            width: width / 1.15,
          }}
          disabled={false}
          className={styles.btn}
          onClick={handleVerifyOtpRequest}
        >
          Verify
        </Button>

        <div className={styles.footerTxt}>
          {resendOTP ? (
            <div className={styles.footerTxt} style={{ color: "#EB5757" }}>
              OTP has been resent successfully.
            </div>
          ) : null}
        </div>

        <div className={styles.footerTxt}>Do you need help?</div>
        <div className={styles.footerTxt}>
          Contact us at{" "}
          <span className={styles.footTxt}> Support@Sre-Management.com</span>
        </div>
      </div>
    </Page>
  );
};

export default OtpVerification;
