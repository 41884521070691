import moment from "moment";
import { FormElement } from "../../../types";
import { BusinessType, PersonType, UserTypeEnum } from "./enum";

export type CreateBusinessAccountSRE = {
  userId: FormElement;
  email: FormElement;
  firstName: FormElement;
  lastName: FormElement;
  businessName: FormElement;
  businessType: FormElement;
  businessEIN: FormElement;
  ownerSSN:FormElement;
  ownerDOB : FormElement;
  businessAddress: FormElement;
  businessAddress2: FormElement;
  city: FormElement;
  state: FormElement;
  zip : FormElement
};

export type CreateIndividualAccount = {
  userId: FormElement;
  email: FormElement;
  firstName: FormElement;
  lastName: FormElement;
  ssn:FormElement;
  dob : FormElement;
  address: FormElement;
  address2: FormElement;
  city: FormElement;
  state: FormElement;
  zip : FormElement
};

export type CreateControllerOwnerSRE = {
  userType: FormElement;
  userId: FormElement;
  businessId: FormElement;
  firstName: FormElement;
  lastName: FormElement;
  title: FormElement;
  personType: FormElement;
  dob : FormElement;
  ssn: FormElement;
  passportNumber: FormElement;
  issuanceCountry: FormElement;
  address: FormElement;
  address2: FormElement;
  country: FormElement;
  city: FormElement;
  state: FormElement;
  zip : FormElement;
  ownerAndControllerId : FormElement;
  userInfoId : FormElement;
};

export type CreatedBusinessData = {
  BusinessEIN : String,
  BusinessId : String,
  BusinessName : String,
  BusinessType : Number,
  CreatedDate : String,
  UserId : String,
  UserInfoId : String
}

export type AddThresholdData = {
  UserId : FormElement,
  ThresholdAmt : FormElement
}

export type AddedThreshold = {
  UserId : String,
  IsActive : Boolean,
  IsPhoneNoVerified: Boolean,
  Role : String,
  IsIndividual : Boolean,
  CreatedDate : String,
  UserInfoId : String,
  Threshold : String
};

export type CreatedControllerOwnerData = {
  OwnersAndControllersId : String,
  Type: UserTypeEnum.CONTROLLER,
  UserInfoId: String,
  BusinessId: String,
  Title: String,
  IsUSPerson: Boolean,
  PassportNumber : String,
  InsuranceCountry : String,
  IsDeleated: Boolean,
  CreatedDate: String
}

export type GetOwnerListRequest = {
  UserId : FormElement,
  BussinesId : FormElement
}

export type OwnerList = {
  OwnerAndControllerId : String,
  FirstName : String,
  LastName : String,
  Title  : String,
  UserType : Boolean,
  DwollaVerificationStatus : String | null,
  IsDeleated : false,  
}
export type CreatedIndividualAccount = {
  AddressLine1 : String | null,
  AddressLine2 : String | null,
  City : String | null,
  Country : String | null, 
  CreatedDate : String | null,
  DOB : String | null,
  DwollaVerifcationToken : String | null,
  DwollaVerificationStatus : String | null, 
  Email : String | null,
  FirstName : String | null,
  LastName :  String | null,
  Mobile : String | null,
  ProfileImageURL : String | null,
  SSN : String | null,
  State  : String | null,
  UserId : String | null,
  UserInfoId : String | null,
  ZipCode : String | null,
}

export type BusinessAccountType = {
  addThresholdData: AddThresholdData;
  addedThreshold : AddedThreshold;
  createdBusinessData: CreatedBusinessData;
  createBusinessAccount: CreateBusinessAccountSRE; 
  createIndividualAccount: CreateIndividualAccount; 
  createControllerOwnerSRE: CreateControllerOwnerSRE; 
  createdControllerOwnerData : CreatedControllerOwnerData; 
  createdIndividualAccount : CreatedIndividualAccount; 
  getOwnerListRequest : GetOwnerListRequest;
  ownerList: Array<OwnerList>;
  error: string | null;
  loading: boolean;    
};
export const bussinessAccountState: BusinessAccountType = {
  addThresholdData : {
    UserId : {value : ""},
    ThresholdAmt : { value : "" }
  },

  addedThreshold : {
    UserId : "",
    IsActive : false,
    IsPhoneNoVerified: false,
    Role : "",
    IsIndividual : false,
    CreatedDate : "",
    UserInfoId : "",
    Threshold : ""
  },

  createdBusinessData : {
    BusinessEIN : "",
    BusinessId : "",
    BusinessName : "",
    BusinessType : BusinessType.LLCS,
    CreatedDate : "",
    UserId : "",
    UserInfoId : ""
  },

  createdIndividualAccount: {
      AddressLine1 : "",
      AddressLine2 : "",
      City : "",
      Country : "", 
      CreatedDate : "",
      DOB : "",
      DwollaVerifcationToken : "",
      DwollaVerificationStatus : "", 
      Email : "",
      FirstName : "",
      LastName :  "",
      Mobile : "",
      ProfileImageURL : "",
      SSN : "",
      State  : "",
      UserId : "",
      UserInfoId : "",
      ZipCode : "",
  },
  createBusinessAccount: {
    userId: {value: ""},
    email: { value: "" },
    firstName: { value: "" },
    lastName: { value: "" },
    businessName: { value: "" },
    businessType: {value: BusinessType.LLCS},
    businessEIN: {value: ""},
    ownerSSN: {value: ""},
    ownerDOB: { value: moment(new Date()).format("YYYY-MM-DD") },
    businessAddress: {value: ""},
    businessAddress2: {value:""},
    city: {value: ""},
    state: {value: ""},
    zip: {value: ""}
  },  
  createIndividualAccount: {
    userId: {value: ""},
    email: { value: "" },
    firstName: { value: "" },
    lastName: { value: "" },
    ssn: {value: ""},
    dob: { value: moment(new Date()).format("YYYY-MM-DD") },
    address: {value: ""},
    address2: {value:""},
    city: {value: ""},
    state: {value: ""},
    zip: {value: ""}
  },

  createControllerOwnerSRE: {
    userType: {value: UserTypeEnum.CONTROLLER},
    userId: {value: ""},
    businessId: {value: ""},
    title: { value: "" },
    firstName: { value: "" },
    lastName: { value: "" },
    personType: { value: PersonType.US },
    dob: {value: moment(new Date()).format("YYYY-MM-DD")},
    ssn: {value: ""},
    passportNumber: {value: ""},
    issuanceCountry: { value: "" },
    address: {value: ""},
    address2: {value:""},
    country: {value:""},
    city: {value: ""},
    state: {value: ""},
    zip: {value: ""},
    ownerAndControllerId: {value: ""},
    userInfoId: {value: ""}
  },
  createdControllerOwnerData: {
    OwnersAndControllersId : "",
    Type: UserTypeEnum.CONTROLLER,
    UserInfoId: "",
    BusinessId: "",
    Title: "",
    IsUSPerson: false,
    PassportNumber : "",
    InsuranceCountry : "",
    IsDeleated: false,
    CreatedDate: ""
  },
  getOwnerListRequest: {
    UserId: {value: ""},
    BussinesId: {value: ""}
  },
  ownerList : [],
  error: "",
  loading: false,
};

/*
  If you want to use 'ContainerState' keyword everywhere in your feature folder,
  instead of the 'HomePageState' keyword.
*/
export type ContainerState = BusinessAccountType;
