import Header from "app/components/Header";
import React, { useEffect, useState } from "react";
import { useStyles } from "./styles";
import { Outlet } from "react-router";
import LeaseDialog from "../../CommonComponent/Property/LeaseDialog";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { actions } from "../redux/slice";
import { actions as propertyActions} from "../../CommonComponent/Property/redux/slice";
import ReactApexChart from 'react-apexcharts';
import { useSelector } from "react-redux";
import { selectPropertyListDD, selectdashboardData, selectPropertyError } from "../../CommonComponent/Property/redux/selector";
import { selectServiceRequestData, selectLoading, selectMaintananceRequest, SelectServiceDashboardData, selectPendingBalance } from "../redux/selector";
import { selectError, selectUser } from "app/containers/Auth/selectors";
import AddServiceDialog from "../Maintanence/Components/Dialog";
import AddNewServiceRequest from "../Maintanence/Components/AddNewServiceRequest";
import Loader from "app/components/Loader";
import { Typography } from "@mui/material";
import helpPng from "../../../assets/help.png";
import BalanceHelpPopup from "./Components/BalanceHelpPopup";

const Dashboard = () => {
  const styles = useStyles();
  const [showPopup, setShowPopup] = useState(false);
  const [leasePopup, setLeasePopup] = useState(false);
  const [balancePopup, setBalancePopup] = useState(false);
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const dashboardData = useSelector(selectdashboardData);
  const loggedInUser = useSelector(selectUser);
  const error = useSelector(selectError);
  const propertyError = useSelector(selectPropertyError);
  const serviceRequestData = useSelector(selectServiceRequestData);
  const loading = useSelector(selectLoading);
  const propertyList = useSelector(selectPropertyListDD);
  const maintananceRequest = useSelector(selectMaintananceRequest);
  const serviceDashboardData = useSelector(SelectServiceDashboardData); 
  const pendingBalance = useSelector(selectPendingBalance);

  const [showAddNewServiceRequest, setShowAddNewServiceRequest] =
    useState(false);

  const userId = loggedInUser?.userId ? loggedInUser?.userId : JSON.parse(localStorage.getItem('loginResponse')!).userId;
  const jwtToken = loggedInUser?.jwtToken ? loggedInUser?.jwtToken : JSON.parse(localStorage.getItem('loginResponse')!).jwtToken;
  dispatch(propertyActions.setLeaseState("dashboard"));

 
  const openPopup = () => {
    setBalancePopup(true);
  }
  useEffect(() => {
    if (error === "Unauthorized" || propertyError === "Unauthorized") {
      dispatch(propertyActions.resetModule());
      dispatch(propertyActions.resetError());      
      navigation("/auth");
    }
    if (propertyError === "MESSAGE.NOT_FOUND") {
      dispatch(propertyActions.resetDashbord());  
      dispatch(propertyActions.resetError());     
      navigation("/add-property");
    }
  }, [propertyError]);

  const closePopup = () => {
    setShowPopup(false);
  };

  
  const closeBalancePopup = () => {
    setBalancePopup(false);
  };

  const closeLeasePopup = () => {
    setLeasePopup(false);
    dispatch(propertyActions.resetPropertyListFromDropDown());
  };
  useEffect(() => {    
    dispatch(actions.setShowBottomNavBar({ status: true, index: 0 }));
    dispatch(propertyActions.setStateStatus(""));
    if(jwtToken && userId){
      dispatch(propertyActions.setDashboardData(userId));
      dispatch(propertyActions.getPropertyListViaOwnerId()); 
      dispatch(actions.getPendingBalance(userId));
      dispatch(actions.getLoggedInUserName(userId));
    } 
  }, [dispatch]);

  useEffect(() => {
    if(propertyList?.items.length > 0 ) 
    {
      const propertyIds = propertyList?.items?.map(property => property.PropertyId);
      dispatch(actions.setMaintainanceProperty(propertyIds));
    }
  }, [propertyList]);

  useEffect(() => {
    if(maintananceRequest?.propertyIds.length > 0 ) 
    {
      dispatch(actions.getServiceRequestDashboardCount(maintananceRequest.propertyIds));
    }
  }, [maintananceRequest]);


 const state = {
  series: [
    dashboardData?.Occupied ? dashboardData?.Occupied : 0,
    dashboardData?.Vaccant ? dashboardData?.Vaccant : 0,
  ],
  options: {
    chart: {
      width: 320,
      height: 320,
      type: "donut",
    },
    legend: {
      position: "none", // hide the legend
    },
  },
  dataLabels: {
    label: "value",
    enabled: true,
    formatter: function (val, opts) {
      return opts.w.globals.initialSeries[opts.seriesIndex];
    },
    style: {
      fontSize: "14px",
      fontFamily: "Helvetica, Arial, sans-serif",
      fontWeight: "normal",
      colors: undefined,
    },
    background: {
      enabled: true,
      foreColor: "#000",
      borderRadius: 20,
      padding: 5,
      borderWidth: 20,
      borderColor: "#fff",
      optacity:1,
    },
  },
  tooltip: {
    enabled: false,
  },
  labels: ["Occupied", "Vacant"],
  colors: ["#f0bb00", "#eb5757"],
};

const onCreatedSuccess = () => {
  if(jwtToken && userId){
    dispatch(propertyActions.setDashboardData(userId));
    dispatch(actions.getServiceRequestDashboardCount(maintananceRequest.propertyIds)); 
   } 
};

  return (
    <>
    <Loader loading={loading} />
    <BalanceHelpPopup showPopup={balancePopup} closePopup={closeBalancePopup} />

      {showAddNewServiceRequest && (
        <AddNewServiceRequest
          returnToParent={() => {
            setShowAddNewServiceRequest(false);
            if(serviceRequestData.Description.value) {
              dispatch(actions.createServiceRequest({data: serviceRequestData, callback: onCreatedSuccess}));
            }
          }}
        />
      )}
      {showPopup && (<AddServiceDialog
          showPopup={showPopup}
          closePopup={closePopup}
          continueClick={() => {
            setShowAddNewServiceRequest(true);
            setShowPopup(false);
          }}
        />
      )}
      <LeaseDialog showPopup={leasePopup} closePopup={closeLeasePopup} />
      <Outlet />
      {!showAddNewServiceRequest && (<div className={styles.container}>
        <Header
          rightIcon={true}
          handleNavigation={() => {
            navigation("landlord-profile");
          }}
        />
        <div>
          <div className={styles.title}>Properties</div>
          <div className={styles.rowView}>
            <div
              className={styles.card}
              style={{
                display: "flex",
                paddingBottom: 10,
                flexDirection: "column",
                paddingTop: 0,
              }}
            >
              <div
                style={{
                  backgroundColor: "white",
                  alignSelf: "flex-end",
                  marginLeft: 5,
                  borderBottomLeftRadius: 10,
                  borderTopRightRadius: 20,
                  fontSize: 10,
                  fontFamily: "Open Sans",
                  color: "#006CFD",
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingTop: 5,
                  paddingBottom: 5,
                }}
                onClick={() => {
                  navigation("portfolio", { state: false });
                }}
              >
                View all
              </div>
              <div>
                <ReactApexChart options={state} series={state.series} type="donut" width='100%'
      height={state.options.chart.height} />    
              <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
              >
                <div style={{ display: "flex", marginRight: 9 }}>
                <div
                style={{
                height: 10,
                width: 10,
                borderRadius: 10,
                backgroundColor: "#F0BB00",
                alignSelf: "center",
                marginRight: 3,
                }}
                />
                <div style={{ fontFamily: "Open Sans", fontSize: 12 }}>
                Occupied
                </div>
                </div>
                <div style={{ display: "flex" }}>
                <div
                style={{
                height: 10,
                width: 10,
                borderRadius: 10,
                backgroundColor: "#EB5757",
                alignSelf: "center",
                marginRight: 3,
                }}
                />
                <div style={{ fontFamily: "Open Sans", fontSize: 12 }}>
                Vacant
                </div>
                </div>
              </div> 

              </div>
              
            </div>
            <div
              className={styles.card}
              style={{
                display: "flex",
                flexDirection: "column",
                paddingTop: 0,
                paddingBottom: 10,
                justifyContent: 'center',
                position: 'relative',
              }}
            >
              <div
                style={{
                  backgroundColor: "white",
                  alignSelf: "flex-end",
                  marginLeft: 5,
                  borderBottomLeftRadius: 10,
                  borderTopRightRadius: 20,
                  fontSize: 10,
                  fontFamily: "Open Sans",
                  color: "#006CFD",
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingTop: 5,
                  paddingBottom: 5,
                  position: 'absolute',
                  top: '0px',
                }}
                onClick={() => {
                  navigation("maintanence", { state: false });
                }}
              >
                View all
              </div>
              <div
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: 10,
                    marginRight: 10,
                  }}
                >
                  <img
                    src={require("../../../assets/service.png")}
                    alt=""
                    className={styles.serviceIcon}
                  />
                  <div className={styles.topTxt}>Service request</div>
                </div>
                <div className={styles.indicator}>{serviceDashboardData?.NewCount} New</div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                    paddingLeft: 16,
                    paddingRight: 16,
                  }}
                >
                  <div>
                    <div className={styles.topTxt} style={{ fontSize: 12 }}>
                      In Progress
                    </div>
                    <div
                      className={styles.topTxt}
                      style={{ fontSize: 12, textAlign: "center" }}
                    >
                      {serviceDashboardData?.InProgress}
                    </div>
                  </div>
                  <div>
                    <div className={styles.topTxt} style={{ fontSize: 12 }}>
                      In Review
                    </div>
                    <div
                      className={styles.topTxt}
                      style={{ fontSize: 12, textAlign: "center" }}
                    >
                      {serviceDashboardData?.InReview}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className={styles.title}>Finance</div>
          <div className={styles.rowView}>
            <div className={styles.card} style={{ borderRadius: 10 }}>
              <div className={styles.tilesTxt}>Outstanding payments</div>
              <div className={styles.tilesVal}>{dashboardData?.OutStandingPayments ? dashboardData?.OutStandingPayments : '0'+'$'}</div>
              <div className={styles.float}>View</div>
            </div>
            <div className={styles.card} style={{ borderRadius: 10 }}>
              <div className={styles.tilesTxt}>Rental paid Year-To-Date</div>
              <div className={styles.tilesVal} style={{ color: "#0FC35C" }}>
                {dashboardData?.RentalPaidYearToDate ? dashboardData?.RentalPaidYearToDate : '0'+'$'}
              </div>
            </div>
          </div>
          <div className={styles.rowView}>
            <div className={styles.cardBalance} style={{ borderRadius: 10, position: 'relative' }}>
              <Typography className={styles.tilesTxt} style={{letterSpacing: '0.2'}}>Pending Balance: <span style={{color: '#F0BB00'}}>{pendingBalance?.balance}$</span></Typography>
              <span style={{position: 'absolute', right: '30px', top: '25%', cursor: 'pointer'}} onClick={openPopup}><img src={helpPng}/></span>
            </div>
          </div>
          <div className={styles.title}>Quick Buttons</div>
          <div className={styles.rowView}>
            <div
              className={styles.card}
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 10,
                paddingLeft: 17,
                paddingRight: 17,
                paddingTop: 12,
                paddingBottom: 12,
              }}
            >
              <img
                src={require("../../../assets/hometiles.png")}
                alt=""
                className={styles.img}
              />
              <img
                src={require("../../../assets/yellowPlus.png")}
                alt=""
                className={styles.plusIcon}
              />
              <div
                className={styles.tilesTxt}
                style={{ paddingLeft: 0, paddingRight: 0 }}                
                onClick={() => {
                  dispatch(propertyActions.resetPropertyData());
                  navigation("add-new-property", { state: true });
                }}
              >
                Add New Property
              </div>
            </div>
            <div
              className={styles.card}
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 10,
                paddingLeft: 17,
                paddingRight: 17,
                paddingTop: 12,
                paddingBottom: 12,
              }}
              onClick={() => {
                navigation("portfolio", { state: true });
              }}
            >
              <img
                src={require("../../../assets/file.png")}
                alt=""
                className={styles.img}
              />
              <div
                className={styles.tilesTxt}
                style={{ paddingLeft: 0, paddingRight: 0 }}
              >
                Generate report
              </div>
            </div>
          </div>
          <div className={styles.rowView} style={{ paddingBottom: 100 }}>
            <div
              className={styles.card}
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 10,
                paddingLeft: 17,
                paddingRight: 17,
                paddingTop: 12,
                paddingBottom: 12,
              }}
              onClick={() => {
                setShowPopup(true);
              }}
            >
              <img
                src={require("../../../assets/greenService.png")}
                alt=""
                className={styles.img}
              />
              <img
                src={require("../../../assets/greenPlus.png")}
                alt=""
                className={styles.plusIcon}
              />
              <div
                className={styles.tilesTxt}
                style={{ paddingLeft: 0, paddingRight: 0 }}
              >
                Add Service Request 
              </div>
            </div>
            <div
              className={styles.card}
              style={{
                display: "flex",
                flexDirection: "row",
                marginBottom: 10,
                paddingLeft: 17,
                paddingRight: 17,
                paddingTop: 12,
                paddingBottom: 12,
              }}
              onClick={() => {
                setLeasePopup(true);
              }}
            >
              <img
                src={require("../../../assets/oranfeProfile.png")}
                alt=""
                className={styles.img}
              />
              <img
                src={require("../../../assets/orangePlus.png")}
                alt=""
                className={styles.plusIcon}
              />
              <div
                className={styles.tilesTxt}
                style={{ paddingLeft: 0, paddingRight: 0 }}
              >
                Add New Lease
              </div>
            </div>
          </div>
        </div>
      </div>
      )}
    </>
  );
};

export default Dashboard;
