import {
  Button,
  Typography,
  IconButton,
  InputAdornment,
  TextField,
  Box,
  Grid,
  Dialog,
  Select,
  MenuItem,
  Radio,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import {
  actions,
  reducer,
  sliceKey,
} from "../../../../LandlordAccount/redux/slice";
import { useLandlordSaga } from "../../../../LandlordAccount/redux/saga";
import { style } from "@mui/system";
import { useStyles } from "../../styles";
import OtpInput from "react-otp-input";
import { LoggerClass as Logger } from "utils/logger";

type Props = {};
const Success = ({ showPopup, closePopup, handleClose = () => {} }) => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: useLandlordSaga });
  const styles = useStyles();
  const [show, setShow] = useState(false);
  const [reportType, setReportType] = useState<any>(null);
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [timeRange, setTimeRane] = useState("");
  const [error, setError] = useState(false);
  const [otp, setOtp] = useState("");
  const [disable, setDisable] = useState(true);

  const close = () => {
    setShow(false);
    closePopup();
  };

  useEffect(() => {
    if (otp?.length === 4) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [otp?.length]);

  useEffect(() => {
    setShow(showPopup);
  }, [showPopup]);

  const handleReportTypeChange = (event) => {
    const option = event.target.value;
    Logger.log(event.target);
    setReportType(option);
  };

  const handleChange = (otp) => {
    // dispatch(actions.setOtp(otp));
    // if (otp.length === 4) {
    //   dispatch(
    //     actions.verifyOtpRequest({
    //       callback: (url = "/thankyou") => {
    //         dispatch(actions.setOtp(""));
    //         navigate(url);
    //       },
    //     })
    //   );
    // }
    setOtp(otp);
  };

  return (
    <Dialog open={show} className={styles.popup}>
      <Box sx={{ display: "flex", flexDirection: "column", margin: "16px" }}>
        <img
          src={require("../../../../../assets/close_icon.png")}
          style={{ height: 20, width: 20, alignSelf: "flex-end" }}
          alt=""
          onClick={handleClose}
        />
        <img
          src={require("../../../../../assets/tick.png")}
          alt=""
          style={{ height: 84, width: 84, alignSelf: "center" }}
        />
        <Typography
          className={styles.modalTitle}
          style={{ marginLeft: 16, marginRight: 16 }}
        >
          The password is successfully changed!
        </Typography>
      </Box>
    </Dialog>
  );
};

export default Success;
