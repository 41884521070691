export class ResponseError extends Error {
  public response: Response;

  constructor(response: Response) {
    super(response.statusText);
    this.response = response;
  }
}
/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response: Response) {
  if (response.status === 204 || response.status === 205) {
    return null;
  }
  return response.json();
}

/**
 * Checks if a network request came back fine, and throws an error if not
 *
 * @param  {object} response   A response from a network request
 *
 * @return {object|undefined} Returns either the response, or throws an error
 */
async function checkStatus(response: Response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  }
  if (response.status === 401 || response.status === 400) {
    return response;
  }
  const error = new ResponseError(response);
  const res = response.statusText;
  error.message = res;
  error.response = response;
  throw error;
}

const defaultOptions = () => {
  const sessionToken = localStorage.getItem("sessionToken");
  return {
    headers: {
      "Content-Type": "application/json",
      ...(sessionToken ? { Authorization: `Bearer ${sessionToken}` } : {}),
    },
  };
};

/**
 * Requests a URL, returning a promise
 *
 * @param  {string} url       The URL we want to request
 * @param  {object} [options] The options we want to pass to "fetch"
 *
 * @return {object}           The response data
 */
export async function request(
  url: string,
  options?: RequestInit
): Promise<{} | { err: ResponseError }> {
  const payload = { ...defaultOptions(), ...options };
  const fetchResponse = await fetch(url, payload);
  const response = await checkStatus(fetchResponse);
  if (fetchResponse.status !== 200) {
    return {
      Status: response.status,
      Message: response.statusText,
      Ok: response.ok,
    };
  } else {
    return parseJSON(response);
  }
}

export async function fileRequest(
  url: string,
  options?: RequestInit
): Promise<{} | { err: ResponseError }> {
  const payload = { ...options };
  const fetchResponse = await fetch(url, payload);
  const response = await checkStatus(fetchResponse);
  if (fetchResponse.status !== 200) {
    return {
      Status: response.status,
      Message: response.statusText,
      Ok: response.ok,
    };
  } else {
    return parseJSON(response);
  }
}
