import {
  Button,
  Checkbox,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import CopyRightSection from "app/components/CopyRight";
import Page from "app/components/Page";
import PageImageHeader from "app/components/PageImageHeader";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useWindowDimensions from "utils/hooks/useWindowDimensions";
import { useStyles } from "./styles";
import { useSelector } from "react-redux";
import { selectCreateAccountSRE } from "../selectors";

const SucessfullVerification = () => {
  const { width, height } = useWindowDimensions();
  const styles = useStyles();
  const location = useLocation();
  const { state } = location;
  const navigation = useNavigate();
  const [emailCheck, setEmailCheck] = useState(false);
  const [phoneCheck, setPhoneCheck] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [disabled, setDisabled] = useState(false);

  const roleType = JSON.parse(localStorage.getItem('loginResponse')!).role;
  const tempData = useSelector(selectCreateAccountSRE);

  useEffect(() => {
    if (phoneNumber.length && (emailCheck || phoneCheck)) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [emailCheck, phoneCheck, phoneNumber.length]);

  return (
    <Page>
      <PageImageHeader
        subHeading={
          <div style={{ display: "flex" }}>
            <Typography sx={{ fontSize: 14, color: "#fff" }}>
              {"have account"}
            </Typography>
            <Typography
              onClick={() => {
                // navigate("/auth");
              }}
              sx={{
                fontSize: 14,
                color: "#FAA61A",
                fontWeight: "bold",
                textDecoration: "underline",
                marginLeft: "4px",
              }}
            >
              {"login"}
            </Typography>
          </div>
        }
      />

      <div
        style={{
          width: width,

          top: height / 2.82,
        }}
        className={styles.sheet}
      >
        <img
          src={require("../../../assets/tick.png")}
          style={{
            height: 128,
            width: 128,
            alignSelf: "center",
            marginTop: 24,
          }}
          alt=""
        />
        <div className={styles.title}>Verification is sucsessfuly done!</div>

        <Button
          type="submit"
          variant="contained"
          sx={{
            width: width / 1.15,
          }}
          disabled={false}
          className={styles.btn}
          onClick={() => {
            // if(state === "changeMobileNumber") {
            //   navigation("/landlord-account/landlord-profile", {state : state});              
            // } else {
            //   if (roleType === "tenant") {
            //     navigation("/address");
            //   } else {
            //     navigation("/funding-source");
            //   }
            // }

            if(tempData.role.value === "landlord" && tempData.landLordType.value === "business") {
              navigation("/business-account", { state: true });
            } else if(tempData.role.value === "landlord" && tempData.landLordType.value === "individual") {
              navigation("/individual-account", { state: true });
            } else {
               // dispatch(actions.resetPhoneNumber());
             // navigation("/verification", { state: false });

              if(state === "changeMobileNumber") {
                navigation("/landlord-account/landlord-profile", {state : state});              
              } else if (roleType === "tenant") {
                if (roleType === "tenant") {
                  navigation("/address");
                } else {
                  navigation("/funding-source");
                }
              }else{
                navigation("/verification", { state: false });
              }
            }
          }}
        >
          Continue
        </Button>
        {/* <div className={styles.title}>My Property</div>

        <div className={styles.subTitle}>
          <span className={styles.spanTitle}>Add your phone number</span> to be
          used with notifications and communications
        </div> */}

        <CopyRightSection/>
      </div>
    </Page>
  );
};

export default SucessfullVerification;
