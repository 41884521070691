import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import { actions, reducer, sliceKey } from "../../redux/slice";
import { useServiceRequestSaga } from "../../redux/saga";
import { useSelector } from "react-redux";
import { selectList } from "../../redux/selector";
import { useDispatch } from "react-redux";
import { ServiceRequestType } from "../../redux/types";
const HandleServiceRequest = () => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: useServiceRequestSaga });
  const list = useSelector(selectList);
  const styles = useStyles();
  const [from, setFrom] = React.useState<Dayjs | null>(
    dayjs("2022-04-17T00:00")
  );
  const [to, setTo] = React.useState<Dayjs | null>(dayjs("2022-04-17T00:00"));
  const [disableAdd, setDisableAdd] = useState(true);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [serviceDate, setServiceDate] = useState<any>(null);
  const onServiceDateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setServiceDate(event.target.value);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    var tempdate = new Date(serviceDate);
    if (to?.diff(from) !== undefined && serviceDate !== undefined) {
      if (
        (to?.diff(from) <= 0 && tempdate.getMilliseconds() >= 0) ||
        isNaN(tempdate.getMilliseconds())
      ) {
        setDisableAdd(true);
      } else {
        setDisableAdd(false);
      }
    }
  }, [from, to, serviceDate]);
  useEffect(() => {
    dispatch(actions.getList());
  }, []);
  useEffect(() => {
    if (list.items.length !== 0) {
      setDisableSubmit(false);
    } else {
      setDisableSubmit(true);
    }
  }, [list.items]);
  const addClick = () => {
    const item: ServiceRequestType = {
      serviceRequestId: "1234567890",
      serviceProviderId: "0987654321",
      from:
        from === null
          ? ""
          : from?.toDate().toLocaleString("en-GB").substring(12, 17),
      to:
        to === null
          ? ""
          : to?.toDate().toLocaleString("en-GB").substring(12, 17),
      serviceDate: new Date(serviceDate),
    };
    dispatch(actions.addTimeFrame(item));
    setFrom(null);
    setTo(null);
    setServiceDate("");
  };
  return (
    <Box className={styles.container}>
      <Box className={styles.boxHeader}>
        <Typography className={styles.pageHeading}>
          Handle service reuest
        </Typography>
      </Box>
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "113px",
        }}
      >
        <Box
          sx={{ display: "flex", flexDirection: "column", margin: "0px 16px" }}
        >
          <Typography className={styles.typo60018}>
            Add time frames for service profesional visit
          </Typography>
          <Typography className={styles.pageTypoThreshold}>
            Day for Service Request
          </Typography>
          <TextField
            sx={{ marginBottom: "24px" }}
            type={"date"}
            placeholder={"dd/mm/yyyy"}
            value={serviceDate}
            onChange={onServiceDateChange}
          />
          <Typography className={styles.typo60016}>Add Time Frames</Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              marginBottom: "24px",
            }}
          >
            <Box
              sx={{
                width: "38%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginRight: "16px",
              }}
            >
              <Typography className={styles.typo40016}>From</Typography>
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale="de"
              >
                <TimePicker
                  sx={{ width: "80px" }}
                  ampm={false}
                  value={from}
                  onChange={(newValue) => setFrom(newValue)}
                />
              </LocalizationProvider>
            </Box>
            <Box
              sx={{
                width: "29%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                marginRight: "16px",
              }}
            >
              <Typography className={styles.typo40016}>To</Typography>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <TimePicker
                  sx={{ width: "80px" }}
                  ampm={false}
                  value={to}
                  onChange={(newValue) => setTo(newValue)}
                />
              </LocalizationProvider>
            </Box>
            <Box
              sx={{
                width: "33%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Button
                disabled={disableAdd}
                className={styles.yellowButton}
                variant="contained"
                onClick={addClick}
              >
                Add
              </Button>
            </Box>
          </Box>
          <Typography className={styles.typo60016}>
            Added Time Frames
          </Typography>
          {list.items.map((item, index) => (
            <Box
              key={item.serviceRequestId}
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                margin: "10px 0px 0px 0px",
              }}
            >
              <img
                src={require("../../assets/spcheckmark.png")}
                alt="."
                style={{ marginRight: "8px" }}
              />
              <Typography className={styles.typo40016}>
                {String(item?.serviceDate.toISOString().substring(0, 10))} /{" "}
                {item.from} - {item.to}
              </Typography>
            </Box>
          ))}
          <Box sx={{ marginTop: "40px" }}>
            <Button
              disabled={disableSubmit}
              className={styles.signInButton}
              variant="contained"
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default HandleServiceRequest;
