import { Button, InputAdornment, TextField, Typography } from "@mui/material";
import Page from "app/components/Page";
import PageImageHeader from "app/components/PageImageHeader";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "utils/hooks/useWindowDimensions";
import { useStyles } from "./styles";
import zipLocPng from "../../../assets/ziploc.png";
import { useSelector } from "react-redux";
import {
  getPropertyAddressList,
  selectCreateAccountSREError,
  selectedProperty,
  selectError,
} from "../selectors";
import { useDispatch } from "react-redux";
import { actions } from "../slice";
import { valdiator } from "./validator";

const AccountAddress = () => {
  const { width, height } = useWindowDimensions();
  const styles = useStyles();
  const navigation = useNavigate();
  const property = useSelector(selectedProperty);
  const proAddressList = useSelector(getPropertyAddressList);
  const errorMessage = useSelector(selectCreateAccountSREError);
  const [showDropDown, setShowDropDown] = useState(false);
  const [selectedVal, setSelectedVal] = useState(false);
  const [zipcodeError, setZipcodeError] = useState(false);
  const dispatch = useDispatch();
  const errors = valdiator(property);
  const error = useSelector(selectError);
  useEffect(() => {
    if (error === "Unauthorized") {
      dispatch(actions.resetModule());
      navigation("/auth");
    }
  }, [error]);
  
  useEffect(() => {
    if (errors.length) {
      dispatch(
        actions.setPropertyDetailsError({ key: "selectedProperty", errors })
      );
      setShowDropDown(false);
      dispatch(
        actions.setPropertyDetails({
          name: "City",
          value: "",
          form: "selectedProperty",
        })
      );
      dispatch(
        actions.setPropertyDetails({
          name: "State",
          value: "",
          form: "selectedProperty",
        })
      );
      dispatch(actions.setPropertiesAddressListSuccess([]));
      return;
    }

    if (property.Address.value.length > 1 && !selectedVal) {
      dispatch(actions.getPropertiesAddressList(property));
      if (!errorMessage) {
        setShowDropDown(true);
      } else {
        setShowDropDown(false);
        dispatch(
          actions.setPropertyDetails({
            name: "City",
            value: "",
            form: "selectedProperty",
          })
        );
        dispatch(
          actions.setPropertyDetails({
            name: "State",
            value: "",
            form: "selectedProperty",
          })
        );
        dispatch(actions.setPropertiesAddressListSuccess([]));
      }
      setSelectedVal(false);
    } else {
      setShowDropDown(false);
    }
  }, [property.Address.value]);

  useEffect(() => {
    if (property.Address.value) {
      dispatch(
        actions.setPropertyDetails({
          name: "Address",
          value: "",
          form: "selectedProperty",
        })
      );
      dispatch(
        actions.setPropertyDetails({
          name: "City",
          value: "",
          form: "selectedProperty",
        })
      );
      dispatch(
        actions.setPropertyDetails({
          name: "State",
          value: "",
          form: "selectedProperty",
        })
      );
      dispatch(actions.setPropertiesAddressListSuccess([]));
    }
    if (property.Zip.value.length > 0) {
      if (errors.length) {
        dispatch(
          actions.setPropertyDetailsError({ key: "selectedProperty", errors })
        );
        setShowDropDown(false);
        return;
      }
      if (property.Zip.error) {
        setZipcodeError(true);
      } else {
        setZipcodeError(false);
      }
    }
  }, [property.Zip.value]);

  const handleChangeEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch(
      actions.setPropertyDetails({ name, value, form: "selectedProperty" })
    );
    setSelectedVal(false);
  };

  const handleClickEvent = (item) => {
    for (const property in item) {
      dispatch(
        actions.setPropertyDetails({
          name: property,
          value: item[property],
          form: "selectedProperty",
        })
      );
    }
  };

  const handleContinueEvent = () => {
    dispatch(
      actions.getLandlordViaProperty({
        data: property,
        callback: confirmLandlordEvent,
      })
    );
  };

  const confirmLandlordEvent = () => {
    navigation("/confirm-landlord");
  };

  const handlePasteEvent = (e) => {
    e.preventDefault();
  };

  const handleKeyEvent = (e) => {
    if (e.key === "e" || e.key === ".") {
      e.preventDefault();
    }
  };

  return (
    <Page>
      <PageImageHeader
        subHeading={
          <div style={{ display: "flex" }}>
            <Typography sx={{ fontSize: 14, color: "#fff" }}>
              {"have account"}
            </Typography>
            <Typography
              onClick={() => {
                // navigate("/auth");
              }}
              sx={{
                fontSize: 14,
                color: "#FAA61A",
                fontWeight: "bold",
                textDecoration: "underline",
                marginLeft: "4px",
              }}
            >
              {"login"}
            </Typography>
          </div>
        }
      />

      <div
        style={{
          width: '100%',

          top: height / 2.82,
        }}
        className={styles.sheet}
      >
        <div className={styles.title}>Finding your Property</div>

        <form noValidate>
          <div className={styles.inputView}>
            <div className={zipcodeError ? styles?.errorLabel : styles?.label}>
              Zip Code
            </div>
            <TextField
              color="primary"
              variant="outlined"
              required
              type="number"
              name="Zip"
              value={property.Zip.value}
              placeholder={"Enter Zip Code"}
              onChange={handleChangeEvent}
              className={styles.textStyle}
              error={!!property.Zip.error}
              helperText={property.Zip.error}
              onPaste={handlePasteEvent}
              onKeyDown={handleKeyEvent}
              InputProps={{
                endAdornment: <img src={zipLocPng} alt="?" />,
              }}
            />
            {zipcodeError && (
              <div className={styles?.errorLabel}>Invalid ZIP Code</div>
            )}
          </div>

          <div className={styles.inputView}>
            <div className={styles?.label}>Street Address</div>
            <TextField
              color="primary"
              variant="outlined"
              required
              id="Address"
              placeholder={"Start typing street adress"}
              name="Address"
              value={property.Address.value}
              onChange={handleChangeEvent}
              autoComplete="off"
              className={styles.textStyle}
              error={!!property.Address.error}
              helperText={property.Address.error}
            />
            {showDropDown && !errorMessage ? (
              <div className={styles.dropDownView}>
                {proAddressList.map((item: any, index) => {
                  return (
                    <div
                      key={index}
                      className={styles.address}
                      onClick={() => {
                        setSelectedVal(true);
                        setShowDropDown(false);
                        handleClickEvent(item);
                      }}
                    >
                      {item?.Address}
                    </div>
                  );
                })}
              </div>
            ) : null}
            {/* {property.Address.error && (
              <div className={styles?.errorLabel}>{property.Address.error}</div>
            )} */}
            {/* {errorMessage && property.Address.value.length > 1 && (
              <div className={styles?.errorLabel}>{errorMessage}</div>
            )} */}
          </div>
          <div className={styles.inputView}>
            <div className={styles?.label}>City</div>
            <TextField
              color="primary"
              variant="outlined"
              required
              id="city"
              placeholder={"City"}
              name="city"
              value={property.City.value}
              onChange={(event) => {
                // setConfirmPass(event.target.value);
              }}
              disabled={true}
              className={styles.textStyle}
            />
          </div>
          <div className={styles.inputView}>
            <div className={styles?.label}>State</div>
            <TextField
              color="primary"
              variant="outlined"
              required
              //   fullWidth
              id="state"
              placeholder={"State"}
              name="state"
              value={property.State.value}
              onChange={(event) => {
                // setConfirmPass(event.target.value);
              }}
              disabled={true}
              className={styles.textStyle}
            />
          </div>
          <div className={styles.inputView}>
            <div className={styles?.label}>Unit number</div>
            <TextField
              color="primary"
              variant="outlined"
              required
              //   fullWidth
              id="Unit"
              placeholder={"Enter unit number"}
              name="Unit"
              disabled={true}
              value={property.Units.value}
              onChange={(event) => {}}
              className={styles.textStyle}
            />
          </div>
          <div className={styles.inputViewButton}>
            <Button
              type="button"
              variant="contained"
              sx={{
                width: width / 1.15,
              }}
              disabled={!errorMessage && selectedVal ? false : true}
              className={styles.btn}
              onClick={handleContinueEvent}
            >
              Continue
            </Button>
          </div>
          <div className={styles.inputViewButton}>
            <Button
              type="button"
              variant="outlined"
              sx={{
                width: width / 1.15,
              }}
              disabled={
                errorMessage && property.Address.value.length > 1 ? false : true
              }
              className={styles.outlinebtn}
              onClick={() => {
                navigation("/address-notlisted");
              }}
            >
              Property is not listed
            </Button>
          </div>
        </form>
        {/* <div className={styles.title}>My Property</div>

      <div className={styles.subTitle}>
        <span className={styles.spanTitle}>Add your phone number</span> to be
        used with notifications and communications
      </div> */}

        <div className={styles.footerTxt}>Do you need help?</div>
        <div className={styles.footerTxt}>
          Contact us at{" "}
          <span className={styles.footTxt}> Support@Sre-Management.com</span>
        </div>
      </div>
    </Page>
  );
};

export default AccountAddress;
