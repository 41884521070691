import {Typography} from "@mui/material";
import React from "react";
import useWindowDimensions from "utils/hooks/useWindowDimensions";

const CopyRightSection = () => {
  const { width, height } = useWindowDimensions();

  return (
   <div
      style={{
        top: height / 2.82,
        paddingBottom: "20px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop : '20px !important',
        margin: 'auto',
        textAlign: 'center',        
        }}
      >
        <Typography sx={{ fontSize: 12, color: "#192342" }}>
              {"Do you need help?"}
        </Typography>
        <Typography sx={{ fontSize: 12, color: "#192342" }}>
              {"Contact us at "}
              <span style={{ fontWeight: '600', color: "#006CFD"}}>
                {"Support@Sre-Management.com"}
              </span>
        </Typography>       
      </div>
    
  );
};

export default CopyRightSection;
