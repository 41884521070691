import { Button, Typography, IconButton, Box } from "@mui/material";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useStyles from "./styles";
import disabledMsg from "../../../assets/DisabledMessage.png";
import disabledMaintainance from "../../../assets/DisabledMaintainance.png";
import disabledCards from "../../../assets/DisabledCards.png";
import { useSelector } from "react-redux";
import {
  getSelectedProperty,
  selectEditProperty,
} from "../../LandlordAccount/redux/selector";
import Header from "app/components/Header";
import BottomTenantTabs from "../BottomTabs";
import { selectUser, selectError } from "app/containers/Auth/selectors";
import { useDispatch } from "react-redux";
import { actions, reducer, sliceKey } from "../redux/slice";
import {
  selectDashboardData,
  selectImageUploadUrl,
  selectTanantError,
  selectLoading,
} from "../redux/selector";
import { DashboardData, ImageUploadRequest } from "../redux/types";

import GoogleMapReact from "google-map-react";
import { GOOGLE_API } from "utils/constants";
import redpinpng from "../../../assets/redpin.png";
import Loader from "app/components/Loader";
import { LoggerClass as Logger } from "utils/logger";
import TenantRemovedDialog from "./components/TenantRemovedDialog";
const AnyReactComponent = ({ text }) => (
  <div style={{ color: "black", fontWeight: "700" }}>{text}</div>
);

const TenantDashboard = ({ disabledButtonsProp = false }) => {
  const styles = useStyles();
  const loggedInUser = useSelector(selectUser);
  const imageURL = useSelector(selectImageUploadUrl);
  const navigate = useNavigate();
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [index, setIndex] = useState(0);
  const [disabledButtons, setDisabledButtons] = useState(disabledButtonsProp);
  const property = useSelector(getSelectedProperty);
  const userId = loggedInUser?.userId
    ? loggedInUser?.userId
    : JSON.parse(localStorage.getItem("loginResponse")!).userId;
  const jwtToken = loggedInUser?.jwtToken
    ? loggedInUser?.jwtToken
    : JSON.parse(localStorage.getItem("loginResponse")!).jwtToken;
  const dispatch = useDispatch();
  const dashboardData = useSelector(selectDashboardData);
  const error = useSelector(selectTanantError);
  const userError = useSelector(selectError);
  const isLoading = useSelector(selectLoading);
  const [tenantRemovedDialog, setTenantRemovedDialog] = useState(false);

  const handleTenantRemovedDialog = (selectedCheck) => {
    setTenantRemovedDialog(false);
  };

  const closePopup = () => {
    setTenantRemovedDialog(false);
  };

  let uploadedImage;
  useEffect(() => {
    if (error === "Unauthorized") {
      navigate("/auth");
    }
  }, [error, userError]);
  
  useEffect(() => {    
      if (jwtToken && userId && !userError && !error) {
        dispatch(actions.getDashboardData(userId));
      }
  }, [dispatch]);

  const handleImageUpload = (event) => {
    uploadedImage.click();
  };
  useEffect(() => {}, [dashboardData, showEditPopup, imageURL, isLoading]);
  const latitude = localStorage.getItem("latitude");
  const longitude = localStorage.getItem("longitude");
  const defaultProps = {
    center: {
      lat: dashboardData?.Latitude
        ? Number(dashboardData?.Latitude)
        : Number(latitude),
      lng: dashboardData?.Longitude
        ? Number(dashboardData?.Longitude)
        : Number(longitude),
    },
    zoom: 12,
  };
  const nextRentalPay = (PayDay) => {
    const currentDate = new Date();
    currentDate.setDate(PayDay ? Number(PayDay) : 1);
    currentDate.setMonth(currentDate.getMonth() + 1);
    const nextRenatal = currentDate.toISOString().substring(0, 10);
    return nextRenatal;
  };
  const uploadImageFn = (e: ChangeEvent<HTMLInputElement>) => {
    Logger.log(e.target.files);
    if (e.target.files) {
      var addImageRequest: ImageUploadRequest = {
        propertyId: dashboardData.PropertyId,
        uploadedByUserId: userId,
        documentURL: "",
        type: "image",
        isActive: true,
      };
      dispatch(
        actions.getDocumentUploadUrl({
          fileData: e.target.files[0],
          contentType: "properties/images",
          addImageRequest: addImageRequest,
        })
      );
    }
  };
  return (
    <>
      <div className={styles.container}>
        <Loader loading={isLoading} />
        <Header
          rightIcon={true}
          handleNavigation={() => {
            navigate("/tenant-account/tenant-profile", {
              state: "/tenant-account",
            });
          }}
        />
        <div
          style={{
            border: "0.5px solid #BCBCBC",
            boxShadow: "0px 1px 5px 9px 10px 10px #C4C4C4",
            borderRadius: 20,
            marginBottom: 16,
            marginLeft: 16,
            marginRight: 16,
            marginTop: 24,
            position: "relative",
          }}
        >
          <div
            style={{
              marginLeft: 10,
              marginTop: 10,
              position: "absolute",
              width: "100%",
            }}
          >
            <div
              style={{ display: "flex", flexDirection: "row", width: "100%" }}
            >
              <Box className={styles.onImageLableContainer}>
                <Box className={styles.onImageLabel}>
                  {disabledButtons
                    ? "Rent: NA"
                    : "Rent: " +
                      (dashboardData?.RentAmount &&
                      dashboardData?.RentAmount !== null
                        ? dashboardData?.RentAmount + "$"
                        : "" + "$")}
                </Box>
              </Box>
              <Box className={styles.onImageLableContainer}>
                <Box className={styles.onImageLabel}>
                  {disabledButtons
                    ? "Deposit: NA"
                    : "Deposit: " +
                      (dashboardData?.DepositAmount &&
                      dashboardData?.DepositAmount !== null
                        ? dashboardData?.DepositAmount + "$"
                        : "" + "$")}
                </Box>
              </Box>
              <IconButton
                className={styles.imageButton}
                onClick={handleImageUpload}
              >
                <img src={require("../assets/addImage.png")} alt="AddImage" />
                <input
                  type="file"
                  accept="image/*"
                  ref={(refParam) => (uploadedImage = refParam)}
                  value={uploadedImage}
                  onChange={uploadImageFn}
                  hidden
                />
              </IconButton>
            </div>
          </div>
          <div
            style={{
              borderRadius: 10,
              height: 200,
              width: "100%",
              overflow: "hidden",
            }}
          >
            <img
              src={
                imageURL !== ""
                  ? imageURL
                  : dashboardData?.Image
                  ? dashboardData?.Image
                  : "/homeImageOne.png"
              }
              style={{
                height: "auto",
                width: "100%",
              }}
              alt="i"
            />
          </div>

          <div style={{ paddingTop: 10, paddingLeft: 10, paddingRight: 10 }}>
            <div
              style={{
                paddingBottom: 10,
              }}
            >
              <div
                style={{
                  fontSize: 16,
                  lineHeight: 1.4,
                  fontWeight: "400",
                  color: "#192342",
                  marginBottom: 10,
                }}
              >
                {dashboardData.Address}
              </div>

              <Box sx={{ width: "100%", height: "220px", padding: "10px" }}>
                <GoogleMapReact
                  bootstrapURLKeys={{ key: `${GOOGLE_API}` }}
                  defaultCenter={defaultProps.center}
                  defaultZoom={defaultProps.zoom}
                >
                  <img src={redpinpng} />
                  <AnyReactComponent text={dashboardData?.Address} />
                </GoogleMapReact>
              </Box>
            </div>
          </div>
        </div>

        <Box
          sx={{
            border: "0.5px solid #BCBCBC",
            boxShadow:
              "9px 19px 8px rgba(199, 199, 199, 0.01), 5px 11px 7px rgba(199, 199, 199, 0.05), 2px 5px 5px rgba(199, 199, 199, 0.09), 1px 1px 3px rgba(199, 199, 199, 0.1), 0px 0px 0px rgba(199, 199, 199, 0.1)",
            borderRadius: "10px",
            padding: "10px",
            margin: "24px 0 10px 0",
            display: "flex",
            flexDirection: "column",
            marginLeft: "16px",
            marginRight: "16px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography className={styles.typo13700}>Owner</Typography>
            <Typography>
              {disabledButtons ? "NA" : dashboardData.OwnerName}{" "}
            </Typography>
          </Box>
          <Typography className={styles.typoLeft40016}>
            {property.occupationStatus}
          </Typography>
        </Box>
        <Box
          sx={{
            border: "0.5px solid #BCBCBC",
            boxShadow:
              "9px 19px 8px rgba(199, 199, 199, 0.01), 5px 11px 7px rgba(199, 199, 199, 0.05), 2px 5px 5px rgba(199, 199, 199, 0.09), 1px 1px 3px rgba(199, 199, 199, 0.1), 0px 0px 0px rgba(199, 199, 199, 0.1)",
            borderRadius: "10px",
            padding: "10px",
            margin: "24px 0 10px 0",
            display: "flex",
            flexDirection: "column",
            marginLeft: "16px",
            marginRight: "16px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography className={styles.typo13700}>
              Next rental payment
            </Typography>
            <Typography>
              {disabledButtons ? "NA" : nextRentalPay(dashboardData.PayDay)}
            </Typography>
          </Box>
          <Typography className={styles.typoLeft40016}>
            {property.occupationStatus}
          </Typography>
        </Box>

        <Button
          className={styles.signInButton}
          onClick={() => {}}
          style={{ marginTop: 24 }}
          disabled={disabledButtons}
        >
          <IconButton
            sx={{
              width: "22.5px",
              height: "22.5px",
              padding: "0",
              marginRight: "10px",
              alignSelf: "center",
            }}
          >
            {disabledButtons ? (
              <img
                src={disabledCards}
                style={{ objectFit: "contain", width: "100%" }}
                alt="<-"
              />
            ) : (
              <img
                src={require("../../../assets/creditcard.png")}
                style={{ objectFit: "contain", width: "100%" }}
                alt="<-"
              />
            )}
          </IconButton>
          <Typography
            className={
              disabledButtons
                ? styles.exitButtonTypoDisabled
                : styles.exitButtonTypo
            }
          >
            Pay my rent
          </Typography>
        </Button>

        <Button
          className={styles.signInButtonYellow}
          onClick={() => {}}
          disabled={disabledButtons}
        >
          <IconButton
            sx={{
              width: "22.5px",
              height: "22.5px",
              padding: "0",
              marginRight: "10px",
              alignSelf: "center",
            }}
          >
            {disabledButtons ? (
              <img
                src={disabledMaintainance}
                style={{ objectFit: "contain", width: "100%" }}
                alt="<-"
              />
            ) : (
              <img
                src={require("../../../assets/repair.png")}
                style={{ objectFit: "contain", width: "100%" }}
                alt="<-"
              />
            )}
          </IconButton>
          <Typography
            className={
              disabledButtons
                ? styles.exitButtonTypoDisabledBlue
                : styles.exitButtonTypoBlue
            }
          >
            Open Service Request
          </Typography>
        </Button>

        <Button
          className={styles.signInButtonInverted}
          variant="outlined"
          onClick={() => {setTenantRemovedDialog(true);}}
          disabled={disabledButtons}
        >
          <IconButton
            sx={{
              width: "22.4px",
              height: "22.4px",
              padding: "0",
              marginRight: "10px",
              alignSelf: "center",
            }}
          >
            {disabledButtons ? (
              <img
                src={disabledMsg}
                style={{ objectFit: "contain", width: "100%" }}
                alt="<-"
              />
            ) : (
              <img
                src={require("../../../assets/chat.png")}
                style={{ objectFit: "contain", width: "100%" }}
                alt="<-"
              />
            )}
          </IconButton>
          <Typography
            className={
              disabledButtons
                ? styles.exitButtonTypoDisabledBlue
                : styles.exitButtonTypoBlue
            }
          >
            Send Message
          </Typography>
        </Button>
        <BottomTenantTabs index={index} setIndex={setIndex} />
      </div>
      <TenantRemovedDialog
        showPopup={tenantRemovedDialog}
        closePopup={closePopup}
        handleSelect={(selectedCheck: any) => {
          handleTenantRemovedDialog(selectedCheck);
        }}
      />
    </>
  );
};

export default TenantDashboard;
