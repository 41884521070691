import {
  Box,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import Header from "app/components/Header";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BottomTenantTabs from "../BottomTabs";
import useStyles from "./styles";
import searchpng from "../../../assets/search.png";
import { useDispatch } from "react-redux";
import { actions } from "../redux/slice";
type Props = {};
const LandlordContacts = (props: Props) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const [index, setIndex] = useState(3);
  const [search, setSearch] = useState("");
  const navigation = useNavigate();
  const onChange = (e) => {
    setSearch(e.target.value);
  };
  useEffect(() => {
    dispatch(actions.setShowBottomNavBar({ status: true, index: 3 }));
  }, [dispatch]);
  const [chatList, setChatList] = useState([
    {
      time: " Jul 13, 2022 15:15",
      img: require("../../../../app/assets/house1.png"),
      address: "3429 Adkins Rd. Nw Atlanta, G...",
      name: "John Smith",
      message: "Is the issue solved?",
    },
    {
      time: " Jul 13, 2022 15:15",
      img: require("../../../../app/assets/house1.png"),
      address: "3429 Adkins Rd. Nw Atlanta, G...",
      name: "John Smith",
      message: "Hello, I need to postpone the rental...",
    },
    {
      time: " Jul 13, 2022 15:15",
      img: require("../../../../app/assets/house1.png"),
      address: "3429 Adkins Rd. Nw Atlanta, G...",
      name: "John Smith, Maria Kologen",
      message: "Is the issue solved?",
    },
    {
      time: null,
      img: require("../../../../app/assets/house1.png"),
      address: "Old 35553 Kenai Highway...",
      name: "John Smith",
      message: null,
    },
  ]);
  return (
    <>
      <div className={styles.container}>
        <Header
          rightIcon={true}
          handleNavigation={() => {
            navigation("/tenant-account/tenant-profile", {
              state: "/tenant-account/accounting",
            });
          }}
        />

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginLeft: "16px",
            marginRight: "16px",
          }}
        >
          <Typography className={styles.title1}>Messenger</Typography>
          <Box className={styles.inputSpan}>
            <input
              className={styles.inputs}
              placeholder="Search by tenant or property address"
              value={search}
              type="text"
              onChange={onChange}
            />
            <InputAdornment position="end">
              <span style={{ cursor: "pointer" }}>
                <IconButton sx={{ color: "#D1D0D0" }}>
                  <img src={searchpng} alt={""} />
                </IconButton>
              </span>
            </InputAdornment>
          </Box>
        </Box>
        {chatList.map((item, index) => {
          return (
            <div
              className={styles.card}
              onClick={() => {
                navigation("/landlord-account/messenger");
              }}
            >
              <div className={styles.time}>{item?.time}</div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <img src={item?.img} className={styles.img} />
                <div>
                  <div className={styles.address}>{item?.address}</div>
                  <div className={styles.name}>{item?.name}</div>
                  <div className={styles.message}>{item?.message}</div>
                </div>
              </div>
            </div>
          );
        })}

        <BottomTenantTabs index={index} setIndex={setIndex} />
      </div>
    </>
  );
};
export default LandlordContacts;
