import { createInjectorsEnhancer, forceReducerReload } from 'redux-injectors';
import { createLogger } from 'redux-logger';
import createSagaMiddleware from 'redux-saga';
import { IS_DEVELOPMENT, IS_PRODUCTION } from 'utils/constants';
import { configureStore } from '@reduxjs/toolkit';
import { createReducer } from './reducers';
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import { Iso } from '@mui/icons-material';
import { composeWithDevTools } from '@reduxjs/toolkit/dist/devtoolsExtension';
export function configureAppStore() {
  const printActions = (action, time, took)=>{
    return `action ${action.type.replaceAll("/", ".")} @ ${time} (in ${took.toFixed(2)} ms)`
  }
  const reduxSagaMonitorOptions = {};
  const sagaMiddleware = createSagaMiddleware(reduxSagaMonitorOptions);
  const { run: runSaga } = sagaMiddleware;
  // Create the store with saga middleware
  let  middlewares: any = [sagaMiddleware];
  
  if (IS_DEVELOPMENT) {
    const logger = createLogger({
      collapsed: true,
      duration: true,
      timestamp: true,
      titleFormatter: (action, time, took)=> printActions(action, time, took)
    });

    middlewares.push(logger);
  }
  // LOG ONLY IN DEVELOPMENT/STAGING PRODUCTION OPTIMIZATIONS
  if (IS_PRODUCTION) {
    middlewares = [...middlewares];
  }
  const enhancers = [
    createInjectorsEnhancer({
      createReducer,
      runSaga,
    }),
  ];
  const persistConfig = {
    key: "root",
    storage,
  };
  const persistedReducer = persistReducer(persistConfig, createReducer());
  const store = configureStore({
    reducer: persistedReducer,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({ serializableCheck: false }).concat(
        ...middlewares,
      ),  
    devTools:
      /* istanbul ignore next line */
      IS_DEVELOPMENT,
    enhancers,
  });
  // Make reducers hot reloadable, see http://mxs.is/googmo
  /* istanbul ignore next */
  if (module.hot) {
    module.hot.accept('./reducers', () => {
      forceReducerReload(store);
    });
  }

  const storeObject={
    store:store,
    presist:persistStore(store)
  } 
  
  return storeObject;
  
}
