import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import { useDispatch, useSelector } from "react-redux";
import uploadpng from "../../assets/upload.png"; 
import docpng from "../../assets/doc.png";
import { actions as S3UploadActions } from "../../containers/CommonComponent/S3UploadSaga/slice";
import CloseIcon from "@mui/icons-material/Close";
import { selectError } from "../../containers/CommonComponent/S3UploadSaga/selector";

import { Box, Button, IconButton, InputAdornment, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
export default function DocumentUpload({ handleChange, value, name, state, docUploaded, docName, selectedDocument }) {
  const styles = useStyles();

  const error = useSelector(selectError);
  useEffect(() => {
    if (error === "Unauthorized") {
      //navigation("/auth");
    }
  }, [error]);

   let uploadedDocument;

   const handleDocumentUpload = (event) => {
     uploadedDocument.click();
   };

   const closeDoc = () => {
    selectedDocument(true);
  };

  return (
    <>
    {docUploaded ?  
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  position: 'relative'
                }}
              >
                <Typography >  
                  Uploaded documents
                </Typography>
                <Box sx={{
                  display: "flex",
                  width: "100%",
                  borderRadius: '20px',
                  border: '1px solid #ccc',
                  flexDirection: 'row',
                  padding: '5px'
                }}>
                  <span style={{ display: "flex", alignItems: "center" }}>
                    <IconButton sx={{ cursor: "pointer", color: "#D1D0D0" }}>
                       <img src={docpng} alt={""} />
                    </IconButton>
                    <Typography
                      sx={{
                        fontFamily: "Open Sans",
                        fontStyle: "normal",
                        fontWeight: 700,
                        fontSize: "14px",
                        letterSpacing: "0.2px",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        maxWidth: "160px",
                      }}
                    >
                     {docName.substring(0, docName.length - 4)}
                    </Typography>
                  </span>
                  <span style={{ cursor: "pointer", right : '10px', position: 'absolute', bottom: '3px' }}>
                    <IconButton 
                    onClick={closeDoc} >
                      <CloseIcon sx={{ color: "#7D7D7D" }} />
                    </IconButton>
                  </span>
                </Box>
              </Box>
             :
             <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
              <Button
              className={styles.signInButtonColorInvert}
              variant="contained"
              onClick={handleDocumentUpload}
            >
              <InputAdornment position="end">
                <span style={{ cursor: "pointer" }}>
                  <IconButton sx={{ color: "#D1D0D0" }}>
                    <img src={uploadpng} alt={""} />
                  </IconButton>
                </span>
              </InputAdornment>
              <Typography
                sx={{
                  fontFamily: "Open Sans",
                  fontStyle: "normal",
                  fontWeight: 700,
                  fontSize: "14px",
                  letterSpacing: "0.2px",
                }}
              >
                Upload Document
              </Typography>
              <input
                type="file"
                accept={value === 'Other' ? '.pdf' : 'image/*' }
                ref={(refParam) => (uploadedDocument = refParam)}
                value={uploadedDocument}
                onChange={handleChange}
                hidden
              />
            </Button>
              
              {(state === 'individual') && 
                <Typography className={styles.note}><span style={{ fontWeight: '600'}}>Note: </span>Must be a color scan of U.S. government-issued identification.</Typography>
              } 
              {(state === 'businessSolo') && 
                <Typography className={styles.note}><span style={{ fontWeight: '600'}}>Note: </span>Must be EIN Letter (IRS-issued SS4 confirmation letter) in PDF format.</Typography>
              } 
              {(state === 'business') && 
                <Typography className={styles.note}><span style={{ fontWeight: '600'}}>Note: </span>
                If you choose <span style={{ fontWeight: '600'}}>License</span> or {" "}
                <span style={{ fontWeight: '600'}}>idCard</span> - must be a color scan of U.S. government-issued identification, If you choose <span style={{ fontWeight: '600'}}>Passport</span> - could be both U.S. and foreign.
                </Typography>
              } 
              
              </Box>
            }
    </>
  );
}
