import { Button, InputAdornment, TextField, Typography } from "@mui/material";
import Page from "app/components/Page";
import PageImageHeader from "app/components/PageImageHeader";

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "utils/hooks/useWindowDimensions";
import { selectedProperty, selectUser, userSelectedTypes } from "../selectors";
import { useStyles } from "./styles";

const WelcomeLandlord = () => {
  const { width, height } = useWindowDimensions();
  const user = useSelector(selectUser);
  const property = useSelector(selectedProperty);


  const styles = useStyles();
  const navigation = useNavigate();

  return (
    <Page>
      <PageImageHeader
        subHeading={
          <div style={{ display: "flex" }}>
            <Typography sx={{ fontSize: 14, color: "#fff" }}>
              {"have account"}
            </Typography>
            <Typography
              onClick={() => {
                // navigate("/auth");
              }}
              sx={{
                fontSize: 14,
                color: "#FAA61A",
                fontWeight: "bold",
                textDecoration: "underline",
                marginLeft: "4px",
              }}
            >
              {"login"}
            </Typography>
          </div>
        }
      />

      <div
        style={{
          width: width,

          top: height / 2.82,
        }}
        className={styles.sheet}
      >
        <div className={styles.title}>Welcome to MyProperty, {user.firstName+" "+user.lastName}! </div>

        <div className={styles.subTitle}>Your new Home</div>
        <div className={styles.card}>
          <div
            className={styles.title}
            style={{ fontSize: 14, marginBottom: 0, marginTop: 12 }}
          >
            {property?.Address.value+", "+property?.City.value+", "+property?.State.value}
          </div>
          <div
            // className={styles.title}
            style={{
              fontSize: 16,
              color: "#006CFD",
              marginTop: 4,
              fontFamily: "Open Sans",
              fontWeight: "600",
            }}
          >
            {property?.RentAmount.value+'$/month'}
          </div>
          <img
            src={require("../../../assets/map.png")}
            alt=""
            className={styles.photo}
          />
        </div>

        <div className={styles.subTitle}>
          Now you can set up and start paying your rent online, submit service
          requests and send messages.
        </div>

        <Button
          type="submit"
          variant="contained"
          sx={{
            width: width / 1.15,
          }}
          disabled={false}
          className={styles.btn}
          onClick={() => {
            if (user.role === "tenant") {
              navigation("/funding-source");
            } else {
              navigation("/confirm-landlord");
            }
          }}
        >
          Get started
        </Button>
      </div>
      {/* </div> */}
    </Page>
  );
};

export default WelcomeLandlord;
