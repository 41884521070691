import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  subHeading: {
    width: '80%',
    margin: 'auto',
    textAlign: 'center',
    fontSize: '14px !important',
    font: 'inherit',
    marginBottom: '20px'
  },
  sheet: {
    display: "flex",
    flex: 1,
    backgroundColor: "#ffffff",
    position: "absolute",
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    flexDirection: "column",
  },
  title:{
    fontSize: 18,
    fontWeight: "600",
    color: "#192342",
    alignSelf: "center",
    marginTop:'10%',
    fontFamily:"Open Sans",
    marginBottom:6    
  },
  title1: {
    fontSize: 16,
    fontWeight: "600",
    color: "#192342",
    alignSelf: "center",
    marginTop:20,
    fontFamily:"Open Sans",
    marginBottom:6 
  },  
  signInButton: {
    "&:disabled": {
      background: "#C4C4C4",
      color: "#6B6B6B",
    },
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 16px",
    gap: "10px",
    height: "50px",
    background: "#192342",
    color: "#ffffff",
    borderRadius: "8px",
    marginTop: 19,
    marginBottom: 24,
    "&:hover": {
      background: "#192342",
      color: "#ffffff",
    },
  },
  inputs: {
    padding: "10px 16px",
    width: "100%",
    height: "48px",
    background: "#FFF7DD",
    color: "#7D7D7D",
    font: 'inherit',
    borderRadius: "20px",
    border: "unset",
    margin: "5px 0px 0px 0px",
    "&:focus": {
      outline: "none",
    },

    "&:disabled": {
      background: "#E0E0E0",
      color: "#7D7D7D",
    },
  },  
});

export default useStyles;
