/**
 * Gets the repositories of the user from Github
 */
import { toast } from "react-toastify";
import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { API_URL } from "utils/constants";
import { getDefaultHeaders } from "utils/helpers";
import { fileRequest, request } from "utils/request";
import { actions } from "./slice";
import { LoggerClass as Logger } from "utils/logger";
import { selectToken } from "../../selectors";

/**
 * Github repos request/response handler
 */

 export function* getDocumentUpload(action: any) {
    yield delay(500);
    try {
      const token = yield select(selectToken);
  
      let formData = new FormData();
      formData.append("File", action.payload.fileData?.document);
      formData.append("FileName", action.payload.fileData?.document.name);
      formData.append("UserId", action.payload.fileData?.userId.value);
      formData.append("LandlordType", action.payload.fileData?.landlordType.value);
      formData.append("Benificary", action.payload.fileData?.benificary.value);
      formData.append("DocumentType", action.payload.fileData?.documentType.value);
      formData.append("s3documentUrl", action.payload.fileData?.documentUrl.value);      

      const options = {
        method: "POST",
        headers: { Authorization: `Bearer ${token}` },
        body: formData,
      };
      const response = yield call(
        fileRequest,
        `${API_URL}/userManagement/Dwolla/UploadDocumentForCustomerVerification`,
        options
      );
      if (response.Status === 401 || response.Status === 400) {
        yield put(actions.getDocumentFailed(response.Message));
        return false;
      }
      if (!response.Result) {
        yield put(actions.getDocumentFailed(response.Message));
        return false;
      }
      yield put(actions.getDocumentSuccess(response.Message));
      yield call(action.payload?.callback);
    } catch (err: any) {
      yield put(actions.getDocumentFailed(err));
    }
  }

/**
 * Root saga manages watcher lifecycle
 */
export function* uploadDocumentSaga() {
    yield takeLatest(actions.getDocumentUpload.type, getDocumentUpload);
}
