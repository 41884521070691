import Page from "app/components/Page";
import PageImageHeader from "app/components/PageImageHeader";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Checkbox, InputAdornment, TextField, Typography } from "@mui/material";
import { useStyles } from "./styles";
import { useSelector } from "react-redux";
import { selectCreateAccountSRE } from "../selectors";
import { useDispatch } from "react-redux";
import { actions } from "../slice";
import { valdiator } from "./validator";
import useWindowDimensions from "utils/hooks/useWindowDimensions";

type Props = {};

const CreateAccount = (props: Props) => {
  const createAccount = useSelector(selectCreateAccountSRE); 
  const { width, height } = useWindowDimensions();
  const navigation = useNavigate();
  const [hidePassword, setHidePassword] = useState(true);
  const [error, setError] = useState("");
  const [isClicked, setIsClciked] = useState(false);
  const [checked, setChecked] = useState(false);


  const errors = valdiator(createAccount);

  const dispatch = useDispatch();
  const onChangeEvent = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    dispatch(
      actions.setAccountSRE({ name, value, form: 'createAccount' }),
    );
  };

  const handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
     setChecked(!checked);
  };


  const styles = useStyles();
  const navigate = useNavigate();
  const handleSubmit = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    if(errors.length){
      dispatch(actions.setAccountSREError({ key: 'createAccount', errors }))
      return
    }
    dispatch(actions.createAccountSRE(createAccount));
    dispatch(actions.createAccountWithRoleError(''));
    dispatch(actions.resetCreateAccount());
    navigation("/account-type");   
  };
 
  return (
    <Page>
      <PageImageHeader />
      <div
        style={{
          width: width,

          top: height / 2.82,
        }}
        className={styles.sheet}
      >
        <div className={styles.title}>Create an Account</div>
        <div className={styles.fieldStyle}>
        <TextField
          sx={{
            mb: 0,
            "& fieldset": {            
            },
          }}
          color="primary"
          variant="outlined"
          required
          id="email"
          placeholder={"Email e.g. Jon.Snow@thewall.north"}
          name="email"
          value={createAccount.email.value}
          className={styles.textStyle}
          onChange={onChangeEvent}                  
          // InputProps={{
          //     endAdornment: (
          //       <InputAdornment position="end">
          //         {createAccount.email.error ? (
          //           <img
          //             src={require("../../../assets/warning.png")}
          //             alt=""
          //             style={{ height: 20, width: 20 }}
          //           />
          //         ) : null}
          //       </InputAdornment>
          //     ),
          //   }}
        />
        {createAccount.email.error && <Typography sx={{color:'#922',marginLeft:'8px', width: '90%', alignSelf: 'center'}} >{createAccount.email.error}</Typography>}
        </div>
        <div className={styles.fieldStyle}>
        <TextField
          sx={{
            mb: 0,
            "& fieldset": {
            },
          }}
          type="password"
          color="primary"
          variant="outlined"
          required
          id="password"
          placeholder={"Password e.g W!nt3rIsCom1ng"}
          name="password"
          value={createAccount.password.value}
          className={styles.textStyle}
          onChange={onChangeEvent}
            // InputProps={{
            //   endAdornment: (
            //     <InputAdornment position="end">
            //       {createAccount.password.error ? (
            //         <img
            //           src={require("../../../assets/warning.png")}
            //           alt=""
            //           style={{ height: 20, width: 20 }}
            //         />
            //       ) : null}
            //     </InputAdornment>
            //   ),
            // }}
        />
        {createAccount.password.error && <Typography sx={{color:'#922',marginLeft:'8px', width: '90%'}} >{createAccount.password.error}</Typography>}
        </div>
        <div className={styles.fieldStyle}>
          <TextField
          sx={{
            mb: 0,
            "& fieldset": {             
            },
          }}
          color="primary"
          variant="outlined"
          required
          type={hidePassword ? "password" : ""}
          id="confirmPassword"
          placeholder={"Confirm Password"}
          name="confirmPassword"
          value={createAccount.confirmPassword.value}
          onChange={onChangeEvent}
          className={styles.textStyle}
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
                onClick={() => {
                  setHidePassword(!hidePassword);
                }}
              >
                {/* {createAccount.confirmPassword.error ? ( */}
                <img
                  src={
                    hidePassword
                      ? require("../../../assets/eyeClose.png")
                      : require("../../../assets/eye.png")
                  }
                  alt=""
                  style={{ height: 24, width: 24 }}
                />
                {/* ) : null} */}
              </InputAdornment>
            ),
          }}
        />
        {createAccount.confirmPassword.error && <Typography sx={{color:'#922',marginLeft:'8px',width: '90%'}} >{createAccount.confirmPassword.error}</Typography>}
        </div>
        {isClicked ? (
          <div
            style={{
              color: "#F44D4D",
              fontFamily: "Open Sans",
              fontSize: 12,
              marginLeft: 20,
            }}
          >
            {error}
          </div>
        ) : null}

        <div className={styles.contentTxt}>Or continue with</div>
        <div className={styles.imgView}>
          <img
            src={require("../../../assets/google.png")}
            alt=""
            style={{ marginRight: 16 }}
            className={styles.icon}
          />
          <img
            src={require("../../../assets/ios.png")}
            alt=""
            className={styles.icon}
          />
        </div>
        <div className={styles.btmTxt}>
        <Checkbox
            name="phoneCheck"
            checked={checked}
            value={checked}
            onChange={handleCheck}            
            sx={{
              color: "#192342",
              "&.Mui-checked": {
                color: "#192342",
              },
            }}
          />
          <div className={styles.btmTxtOnly}>
          By Checking this box you are agreeing to our
          <span
            className={styles.span}
            onClick={() => {
              navigate("/create-account");
            }}
          >
            {" "}
            Terms of service
          </span>{" "}
          and{" "}
          <span
            className={styles.span}
            onClick={() => {
              navigate("/create-account");
            }}
          >
            {" "}
            Privacy Policy
          </span>
          , as well as our partner Dwolla’s Terms of service and Privacy Policy.
          </div>
        </div>
        <Button
          type="submit"
          variant="contained"
          sx={{
            width: width / 1.15,
          }}
          disabled={!checked}
          className={styles.btn}
          onClick={handleSubmit}          
        >
          Continue
        </Button>
      </div>
    </Page>
  );
};

export default CreateAccount;
