import {
  Button,
  Typography,
  IconButton,
  InputAdornment,
  TextField,
  Box,
  Grid,
  Dialog,
  Select,
  MenuItem,
  Radio,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import {
  actions,
  reducer,
  sliceKey,
} from "../../../../LandlordAccount/redux/slice";
import { useLandlordSaga } from "../../../../LandlordAccount/redux/saga";
import { style } from "@mui/system";
import { useStyles } from "../../styles";
import { LoggerClass as Logger } from "utils/logger";

type Props = {};
const Password = ({
  showPopup,
  closePopup,
  handleChange = () => {},
  handleSelect = () => {},
}) => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: useLandlordSaga });
  const styles = useStyles();
  const [show, setShow] = useState(false);
  const [reportType, setReportType] = useState<any>(null);
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [timeRange, setTimeRane] = useState("");
  const [oldpassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatpassword, setRepeatPassword] = useState("");
  const [disable, setDisable] = useState(true);

  const close = () => {
    handleSelect();
  };

  useEffect(() => {
    if (oldpassword?.length && newPassword?.length && repeatpassword?.length) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [newPassword?.length, oldpassword?.length, repeatpassword?.length]);

  useEffect(() => {
    setShow(showPopup);
    if (showPopup) {
      setOldPassword("");
      setNewPassword("");
      setRepeatPassword("");
    }
  }, [showPopup]);

  const handleReportTypeChange = (event) => {
    const option = event.target.value;
    Logger.log(event.target);
    setReportType(option);
  };
  return (
    <Dialog open={show} className={styles.popup}>
      <Box sx={{ display: "flex", flexDirection: "column", margin: "16px" }}>
        <Typography className={styles.modalTitle}>Change password </Typography>
        {/* <Typography className={styles.modalSubTitle}>
          We will send 4 digit code to your phone number for the verfication
          process.
        </Typography> */}
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <div>
            <div
              className={styles.label}
              style={{ marginLeft: 10, marginRight: 0, marginTop: 16 }}
            >
              Old Password
            </div>
            <TextField
              color="primary"
              variant="outlined"
              required
              //   fullWidth
              //   disabled={true}
              id="nickName"
              placeholder={"Enter Old Password"}
              name="clientId"
              value={oldpassword}
              type="password"
              onChange={(event) => {
                //   if (numberValidation.test(event.target.value)) {
                setOldPassword(event.target.value);
                //   }
              }}
              className={styles.textStyle}
              //   error={form.clientId.error}
              //   color={form.clientId.error ? "primary" : "secondary"}
              style={{
                marginLeft: 0,
                marginRight: 0,
                width: "100%",
                marginBottom: 0,
              }}
            />
          </div>

          <div>
            <div
              className={styles.label}
              style={{ marginLeft: 10, marginRight: 0, marginTop: 16 }}
            >
              New Password
            </div>
            <TextField
              color="primary"
              variant="outlined"
              required
              //   fullWidth
              //   disabled={true}
              id="nickName"
              placeholder={"Enter New Password"}
              name="clientId"
              value={newPassword}
              type="password"
              onChange={(event) => {
                //   if (numberValidation.test(event.target.value)) {
                setNewPassword(event.target.value);
                //   }
              }}
              className={styles.textStyle}
              //   error={form.clientId.error}
              //   color={form.clientId.error ? "primary" : "secondary"}
              style={{
                marginLeft: 0,
                marginRight: 0,
                width: "100%",
                marginBottom: 0,
              }}
            />
          </div>
          <div>
            <div
              className={styles.label}
              style={{ marginLeft: 10, marginRight: 0, marginTop: 16 }}
            >
              Repeat a New Password
            </div>
            <TextField
              color="primary"
              variant="outlined"
              required
              //   fullWidth
              //   disabled={true}
              id="nickName"
              placeholder={"Repeat a New Password"}
              name="clientId"
              value={repeatpassword}
              type="password"
              onChange={(event) => {
                //   if (numberValidation.test(event.target.value)) {
                setRepeatPassword(event.target.value);
                //   }
              }}
              className={styles.textStyle}
              //   error={form.clientId.error}
              //   color={form.clientId.error ? "primary" : "secondary"}
              style={{
                marginLeft: 0,
                marginRight: 0,
                width: "100%",
                marginBottom: 0,
              }}
            />
          </div>
        </Box>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: 25,
          }}
        >
          <Button
            // disabled={reportType ? false : true}
            className={styles.outlinebtn}
            onClick={close}
            style={{ marginLeft: 0 }}
            variant="outlined"
          >
            Cancel
          </Button>
          <Button
            className={styles.signInButton}
            disabled={disable}
            onClick={handleChange}
          >
            Change
          </Button>
        </div>
      </Box>
    </Dialog>
  );
};

export default Password;
