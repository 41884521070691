import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
    navText: {
        fontSize: 10, color: "white",
        fontFamily: 'Open Sans'
    },
    activenavText: {
        fontSize: 10, color: "#F0BB00",
        fontFamily: 'Open Sans'
    },

    icon: {
        height: 18,
        width: 18,
        marginBottom: 6

    }, navView: {
        alignItems: 'center',
        display: 'flex',
        flex: 1,
        flexDirection: 'column'
    },
    navigation: {
        display: "flex",
        backgroundColor: "#192342",
        height: 73,
        justifyContent: "space-between",
        alignItems: "center",
        bottom: 16,
        borderRadius: 32,
        paddingLeft: 16,
        paddingRight: 16,
        position: "fixed",
        width: "95%",
        left: "50%",
        marginLeft: "-47.5%",
        zIndex: 2,
    },
    indicator: {
        height: 2,
        backgroundColor: '#F0BB00',
        width: 24,
        position: 'absolute',
        top: 0
    }
})