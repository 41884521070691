import { FormError } from "../../../containers/types";
import set from "lodash/set";
import { createSlice } from "../../../../utils/@reduxjs/toolkit";

import { PayloadAction } from "@reduxjs/toolkit";
import { PortfolioListItemType, PropertyListItemType, LandlordState } from "./types";
import moment from "moment";

const initialState = LandlordState;

export const landlordSlice = createSlice({
  name: "landlordState",
  initialState,
  reducers: {
    editProperty: (state, action: PayloadAction<PropertyListItemType>) => {
      state.form.property.id.value = action.payload.id;
      state.form.property.email.value = action.payload.email;
    },
    
    setShowBottomNavBar: (
      state,
      action: PayloadAction<{ status: boolean; index: number }>
    ) => {
      state.showDownNavBar = action.payload;
    },
   
    getList: (state) => {
      state.loading = true;
    },
    getPropertiesListSuccess: (
      state,
      action: PayloadAction<{ items: any; links: any; meta: any }>
    ) => {
      state.loading = false;
      state.list.items = action.payload.items;
      state.list.meta = action.payload.meta;
      state.list.links = action.payload.links;
    },   
    
    setDropdownSelectedId : (state, action) => {
      state.selectedPropertyIdViaDropdown = action.payload;
    },

    getServices: (state, action) => {
      state.loading = true;
    },
    getServicesSuccess: (
      state,
      action: PayloadAction<any>
    ) => {
      state.loading = false;
      state.serviceRequests = action.payload.Data
    },
    getServicesError(state, action: PayloadAction<any>) {
      state.error = action.payload;
      state.loading = false;
    },

      getDocumentUploadUrl(state, action) {
        state.loading = true;
      },
      getDocumentUploadUrlSuccess(state, action) {
        state.loading = false;
        if (action.payload.type === "properties/images") {
          state.imageUploadUrl = action.payload.response;
        } else {
          state.documentUploadUrl = action.payload.response;
        }
      },
      getDocumentUploadUrlError(state, action) {
        state.error = action.payload;
        state.loading = false;
      },
      
      getServiceRequestDashboardCount: (state, action: PayloadAction<any>) => {
        state.loading = true;
      },
      getServiceRequestDashboardCountSuccess: (state, action: PayloadAction<any>) => {
        state.serviceRequestDashboardData = action.payload.Data;
        state.loading = false;
      },
      getServiceRequestDashboardCountError(state, action: PayloadAction<any>) {
        state.error = action.payload;
        state.loading = false;
      },
      setSelectedServiceRequestStatusType: (state, action: PayloadAction<any>) => {
        state.selectedServiceRequests.WorkStatus = action.payload;
      },
      setSelectedServiceRequest: (state, action: PayloadAction<any>) => {
        state.loading = true;
      },
      setSelectedServiceRequestSuccess: (state, action: PayloadAction<any>) => {
        state.selectedServiceRequests = action.payload.Data;
        state.loading = false;
      },
      setSelectedServiceRequestError(state, action: PayloadAction<any>) {
        state.error = action.payload;
        state.loading = false;
      },

      createServiceRequest: (state, action: PayloadAction<any>) => {
        state.loading = true;
      },
      createServiceRequestSuccess: (state, action: PayloadAction<any>) => {
        state.addedServiceRequest = action.payload.Data;
        state.loading = false;
      },
      createServiceRequestError(state, action: PayloadAction<any>) {
        state.error = action.payload;
        state.loading = false;
      },
 
      setMaintainanceProperty: (state, action: PayloadAction<any>) => {
        state.MaintananceRequestBody.propertyIds = action.payload;        
      },

      ServiceRequestData: (  state,
        action: PayloadAction<{ name: string; value: string, form?: string }>
      ) => {
        const form = action.payload.form ? action.payload.form : 'form';
        const { name, value } = action.payload;      
        set(state.serviceRequestData, `${name}.value`, value);
        set(state.serviceRequestData, `${name}.error`, "");
      },
      resetServiceRequestData: (state) => {
        state.serviceRequestData = initialState.serviceRequestData;
      },
      ServiceRequestTypeData: (state, action: PayloadAction<any>) => {
        state.serviceRequestData.ServiceType = action.payload;
      },
      setVideoFile(state, action) {
        state.videoFile = action.payload.file
        state.requestThumbnailVideo = action.payload.file.url
        state.requestVideo = action.payload.file.url
      },
      resetVideoFile: (state) => {
        state.videoFile = initialState.videoFile;
      },
      restMaintananceRequest: (state) => {
        state.MaintananceRequestBody = initialState.MaintananceRequestBody;
      },
      getUploadUrl(state, action) {
        state.loading = true;
      },
      getUploadUrlSuccess(state, action) {
        state.loading = false;
        state.videoUploadUrl = action.payload;
      },
      resetUploadedUrl: (state) => {
        state.videoUploadUrl = initialState.videoUploadUrl;        
        state.imageUploadUrl = initialState.imageUploadUrl;
        state.videoFile = initialState.videoFile;
      },
      updateVideoUrl(state, action) {
        state.videoUploadedUrl = action.payload;
      },
      startLoading(state) {
        state.loading = true;
      },
      stopLoading(state) {
        state.loading = false;
      },
      getPendingBalance(state, action) {
        state.loading = true;
      },
      getPendingBalanceSuccess(state, action) {
        state.loading = false;
        state.pendingBalance.balance = action.payload;
      },
      getPendingBalanceError(state, action) {
        state.loading = false;
        state.error = action.payload;
      },
      getLoggedInUserName(state, action) {
        state.loading = true;
      },
      getLoggedInUserNameSuccess(state, action) {
        state.loading = false;
        state.loggedInUserData = action.payload;
      },
      getLoggedInUserNameError(state, action) {
        state.loading = false;
        state.error = action.payload;
      },      
  },
});

export const { reducer, actions, name: sliceKey } = landlordSlice;
