import { Navigation } from "@mui/icons-material";
import { Typography } from "@mui/material";
import CopyRightSection from "app/components/CopyRight";
import Page from "app/components/Page";
import PageImageHeader from "app/components/PageImageHeader";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "utils/hooks/useWindowDimensions";
import PlaidConnect from "./Actions/PlaidConnection";
import { useStyles } from "./styles";
import { selectFundingSource, selectError, selectLinkToken } from "./redux/selectors";
import { selectUser } from "../selectors";
import { actions } from "./redux/slice";
import { actions as authActions } from "../slice";
import { LoggerClass } from "utils/logger";
import { BusinessType } from "../CreateBusinessAccount/redux/enum";


const FundingsSource = ({ callingFrom }) => {
  const { height } = useWindowDimensions();
  const dispatch = useDispatch();
  const styles = useStyles();
  const navigation = useNavigate();
  const loggedinUser = useSelector(selectUser);
  const fundingSource = useSelector(selectFundingSource);
  const error = useSelector(selectError);
  const linkToken = useSelector(selectLinkToken);

  const ownerType = localStorage.getItem("ownerType");
  const businessType = localStorage.getItem("businessType");
  const benificary = localStorage.getItem("benificaryType");


  useEffect(() => {
    if (callingFrom === "Loggedin") {
      if (ownerType === "business" && (Number(businessType) === BusinessType.SOLE_PARTNERSHIP || Number(businessType) === BusinessType.TRUST || Number(businessType) === BusinessType.UNICORPORATED_ASSOCIATION)) {
        dispatch(actions.setIdVerficationRequestData({ name: 'LandlordType', value: 'businessSolo', form: "idVerificationData" }));
        dispatch(actions.setIdVerficationRequestData({ name: 'Benificary', value: "", form: "idVerificationData" }));

      }
      else if (ownerType === "business" && (Number(businessType) === BusinessType.CORPORATION || Number(businessType) === BusinessType.LLCS || Number(businessType) === BusinessType.NON_PROFITS || Number(businessType) === BusinessType.PARTNERSHIP || Number(businessType) === BusinessType.PUBLICLY_TRADED_CORPORATIONS)) {
        dispatch(actions.setIdVerficationRequestData({ name: 'LandlordType', value: ownerType + "", form: "idVerificationData" }));
        dispatch(actions.setIdVerficationRequestData({ name: 'Benificary', value: benificary + "", form: "idVerificationData" }));
      }
      else {
        dispatch(actions.setIdVerficationRequestData({ name: 'LandlordType', value: ownerType + "", form: "idVerificationData" }));
        dispatch(actions.setIdVerficationRequestData({ name: 'Benificary', value: "", form: "idVerificationData" }));
      }
    }
  }, [dispatch]);


  useEffect(() => {
    dispatch(actions.setIdVerficationRequestData({ name: 'UserId', value: loggedinUser?.userId ? loggedinUser?.userId + "" : localStorage.getItem("ownerId") + "", form: "idVerificationData" }));
  }, [loggedinUser]);


  useEffect(() => {
    if (error === "Unauthorized") {
      dispatch(authActions.resetModule());
      dispatch(actions.resetModule());
      navigation("/auth");
    }
  }, [error]);

  useEffect(() => {
    dispatch(actions.setFundingSourceData({ name: 'userId', value: loggedinUser?.userId ? loggedinUser?.userId + "" : localStorage.getItem("ownerId") + "", form: "fundingSource" }));
    dispatch(actions.setFundingSourceData({ name: 'userName', value: loggedinUser?.firstName ? loggedinUser?.firstName + "" : localStorage.getItem("ownerName") + "", form: "fundingSource" }));

  }, [loggedinUser]);


  useEffect(() => {
  }, [linkToken]);

  useEffect(() => {
    if (fundingSource.userName.value) {
      dispatch(actions.createLinkToken(fundingSource));
    }
  }, [dispatch, fundingSource.userName.value]);

  return (
    <Page>
      {callingFrom !== 'Loggedin' && <PageImageHeader
        subHeading={
          <div style={{ display: "flex" }}>
            <Typography sx={{ fontSize: 14, color: "#fff" }}>
              {"have account"}
            </Typography>
            <Typography
              sx={{
                fontSize: 14,
                color: "#FAA61A",
                fontWeight: "bold",
                textDecoration: "underline",
                marginLeft: "4px",
              }}
            >
              {"login"}
            </Typography>
          </div>
        }
      />
      }
      <div
        style={{
          top: height / 2.82,
          marginBottom: "10px",
          width: "90%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          margin: 'auto'
        }}
      >
        {callingFrom === "notLoggedin" && <div className={styles.title}>Create an Account</div>}
        <div className={styles.title1}>Fundings Source</div>
        <PlaidConnect
          callingFrom={callingFrom}
          linkToken={linkToken}
          connectionResponse={(output) => {
            if (output) {
              navigation("/id-verification");
            }
          }}
        />
      </div>
      <div
        style={{
          top: height / 2.82,
          marginBottom: "30px",
          width: "80%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          margin: 'auto'
        }}
      >
      </div>
      <CopyRightSection />
    </Page>
  );
};

export default FundingsSource;
