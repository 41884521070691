import { delay, put, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";

const response = {
  items: [],
};
export function* getList(action) {
  yield delay(500);
  try {
    yield put(actions.getPropertiesListSuccess(response));
  } catch (e: any) {
    alert("Something Went Wrong");
  }
}
export function* useServiceRequestSaga() {
  yield takeLatest(actions.getList.type, getList);
}
