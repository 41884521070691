import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useStyles from "./styles";
import SRE_Logo from "../../../assets/logo.png";
import React from "react";
type Props = {};

const Undermaintainance = (props: Props) => {
  const styles = useStyles();
  const navigate = useNavigate();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        background: "#FFF7DD",
      }}
    >
      <div className={styles.upperContainer}>
        <img
          onClick={() => {
            navigate(-1);
          }}
          src={require("../../../assets/backArrow.png")}
          alt=""
          style={{
            height: 16,
            width: 16,
            position: "absolute",
            top: 20,
            left: 16,
          }}
        />
        <div className={styles.logoContainer}>
          <img src={SRE_Logo} alt="logo" className={styles.logo} />
        </div>
      </div>
      <div className={styles.lowerContainer}>
        <Typography className={styles.pageHeading}>
          Work in Progress ..................
        </Typography>
      </div>
    </div>
  );
};

export default Undermaintainance;
