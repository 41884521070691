import { Button, IconButton, Typography } from "@mui/material";
import Header from "app/components/Header";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BottomTenantTabs from "../BottomTabs";
import disabledMaintainance from "../../../assets/DisabledMaintainance.png";
import disabledCards from "../../../assets/DisabledCards.png";
import useStyles from "./styles";
import AddNewServiceRequest from "./Components/AddNewServiceRequest";
import ServiceTickets from "./Components/ServiceRequestTickets";
import { selectServiceRequests, selectDashboardData, selectMaintananceRequest, selectServiceRequestData, selectLoading } from "../redux/selector";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../redux/slice";
import { ServiceStatus, ServiceType } from "app/containers/LandlordAccount/redux/Landlord.enum";
import Loader from "app/components/Loader";
import { selectUser } from "app/containers/Auth/selectors";
import { ServiceRequestListItemType } from "../redux/types";
import { LoggerClass as Logger } from "utils/logger";
import Filter from "./filter";

type Props = {};
const Maintenance = ({ disabledButtonsProp = false }) => {
  const styles = useStyles();
  const navigation = useNavigate();
  const [index, setIndex] = useState(3);
  const [disabledButtons, setDisabledButtons] = useState(disabledButtonsProp);
  const [showAddNewServiceRequest, setShowAddNewServiceRequest] =
    useState(false);
  const [showServiceTickets, setShowServiceTickets] = useState(false);
  const [clickedStatus, setClickedStatus] = useState<ServiceRequestListItemType>();
  const dispatch = useDispatch();
  const dashboardData = useSelector(selectDashboardData); 
  const serviceRequests = useSelector(selectServiceRequests); 
  const maintananceRequest = useSelector(selectMaintananceRequest);
  const serviceRequestData = useSelector(selectServiceRequestData);
  const loading = useSelector(selectLoading);
  const loggedInUser = useSelector(selectUser);
  const userId = loggedInUser?.userId ? loggedInUser?.userId : JSON.parse(localStorage.getItem('loginResponse')!).userId;
  const propertyId = dashboardData?.PropertyId ? dashboardData?.PropertyId : localStorage.getItem('property');
  const [showFilter, setShowFilter] = useState(false);
  
  useEffect(() => {
    if(propertyId) {
      dispatch(actions.setMaintainanceProperty(propertyId));
    }
  }, [dispatch]);

  useEffect(() => {
    if(maintananceRequest?.propertyIds.length > 0 ) 
    {
      dispatch(actions.getServices(maintananceRequest));
    }
  }, [maintananceRequest]);

  const serviceOpenTime = (CreatedDate)  => {
    const today = new Date();
    const createdDate = new Date(CreatedDate);
    const diffTime = today.getTime() - createdDate.getTime();
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))-1; 
    return  diffDays > 1 ? diffDays+" days" : diffDays+" day";
  }

  const serviceType = (type) => { 
    switch (type) {
      case ServiceType.AC:
        return 'A/C';
      case ServiceType.ELECTRICAL:
        return 'Electrical';
      case ServiceType.HANDYMAN:
        return 'Handyman';
      case ServiceType.LOCKSMITH:
        return 'LockSmith';
      case ServiceType.PLUMBLING:
        return 'Plumbling';
      case ServiceType.STRUCTURE_SERVICE:
        return 'Structure Service';
      default:
        return '';
    }
  }

  const serviceStatus = (status) => { 
    switch (status) {
      case ServiceStatus.NEW:
        return 'New';
      case ServiceStatus.SELECT_TIME_FRAME:
        return 'Select Time Frame';
      case ServiceStatus.SCHEDULED_PENDING_PAYMENT:
        return 'Scheduled, Pending Payment';
      case ServiceStatus.PENDING_APPROVAL:
        return 'Pending Approval';
      case ServiceStatus.INPROGRESS:
        return 'InProgress';
      case ServiceStatus.COMPLETED_PENDING_PAYMENT:
        return 'Completed, Pending Payment';
      case ServiceStatus.COMPLETED:
        return 'Completed';
      case ServiceStatus.REOPEN:
        return 'ReOpen';
      case ServiceStatus.DISCARD:
        return 'Discard';
      default:
        return '';
    }
  }

  const onCreatedSuccess = () => {
    if(maintananceRequest?.propertyIds.length > 0 ) 
    {
      dispatch(actions.getServices(maintananceRequest));
    }   
  };


  const closeFilter = () => {
    setShowFilter(false);
  };

  const filterTask = () => {
    setShowFilter(false);
  };
  
  const showFilterTrue = () => {
    setShowFilter(true);
  };

  return (
    <>
      <Loader loading={loading} />
      {showFilter && (
        <Filter closePass={closeFilter} continuePass={filterTask} />
      )}
      {showAddNewServiceRequest ? (
        <AddNewServiceRequest
          returnToParent={() => {
            dispatch(actions.resetVideoFile());
            setShowAddNewServiceRequest(false);
            if(serviceRequestData.Description.value) {
              Logger.log("Reported", JSON.stringify(serviceRequestData));
            dispatch(actions.createServiceRequest({data: serviceRequestData, callback: onCreatedSuccess}));
            }
          }}
        />
      ) : (
        <>
          {showServiceTickets ? (
            <ServiceTickets
              clickedStatus={clickedStatus}
              showServiceTickets={showServiceTickets}
              setShowServiceTickets={setShowServiceTickets}
            />
          ) : (
            <div className={styles.container}>
              <Header
                rightIcon={true}
                handleNavigation={() => {
                  navigation("/tenant-account/tenant-profile", {
                    state: "/tenant-account",
                  });
                }}
              />
              <div className={styles.title}>Service Requests</div>
              <Button
                className={styles.signInButtonYellow}
                onClick={showFilterTrue}
                disabled={disabledButtons}
              >
                <IconButton
                  sx={{
                    width: "20px",
                    height: "20px",
                    padding: "0",
                    marginRight: "6px",
                    alignSelf: "center",
                  }}
                >
                  {disabledButtons ? (
                    <img
                      src={disabledMaintainance}
                      style={{ objectFit: "contain", width: "100%" }}
                      alt="<-"
                    />
                  ) : (
                    <img
                      src={require("../../../assets/filter.png")}
                      style={{ objectFit: "contain", width: "100%" }}
                      alt="<-"
                    />
                  )}
                </IconButton>
                <Typography
                  className={
                    disabledButtons
                      ? styles.exitButtonTypoDisabledBlue
                      : styles.exitButtonTypoBlue
                  }
                >
                  Filter options
                </Typography>
              </Button>

              <Button
                className={styles.signInButton}
                //   variant="contained"
                onClick={() => {
                  setShowAddNewServiceRequest(true);
                }}
                style={{ marginTop: 16 }}
                disabled={disabledButtons}
              >
                <IconButton
                  sx={{
                    width: "14px",
                    height: "14px",
                    padding: "0",
                    marginRight: "10px",
                    alignSelf: "center",
                  }}
                >
                  {disabledButtons ? (
                    <img
                      src={disabledCards}
                      style={{ objectFit: "contain", width: "100%" }}
                      alt="<-"
                    />
                  ) : (
                    <img
                      src={require("../../../assets/whitePlus.png")}
                      style={{ objectFit: "contain", width: "100%" }}
                      alt="<-"
                    />
                  )}
                </IconButton>
                <Typography
                  className={
                    disabledButtons
                      ? styles.exitButtonTypoDisabled
                      : styles.exitButtonTypo
                  }
                >
                  Add Service Request
                </Typography>
              </Button>

              {serviceRequests?.map((item, index) => {
                return (
                  <div className={styles.box}>
                    <div className={styles.image_row}>
                      <img
                        src={require("../../../../app/assets/image_profile.png")}
                        className={styles.image}
                        alt=""
                        onClick={() => {
                          if (item?.WorkStatus === ServiceStatus.DISCARD) {
                            setShowServiceTickets(false);
                          } else {
                            setShowServiceTickets(true);
                            setClickedStatus(item); //item?.WorkStatus
                          }
                        }}
                      />
                      <div className={styles.titleId}>{serviceType(item?.ServiceType) +" #"+item?.ServiceRequestID}</div>
                    </div>
                    <div className={styles.address_row}>
                      <img
                        src={require("../../../../app/assets/location_black.png")}
                        className={styles.location}
                        alt=""
                      />
                      <div className={styles.address}>{item?.PropertyAddress}</div>
                    </div>

                    <div className={styles.serviceProviderBox_Row}>
                      <div className={styles.serviceProviderBox}>
                        <div className={styles.serviceProvider}>
                          Service provider
                        </div>
                        <div className={styles.LLC}>
                          {item?.ServiceProviderName}
                        </div>
                      </div>

                      <div className={styles.serviceProviderBox1}>
                        <div className={styles.serviceProvider}>Open time</div>
                        <div className={styles.LLC}>{serviceOpenTime(item?.CreatedDate)}</div>
                      </div>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <div className={styles.pendingBox}>
                        <div className={styles.pending}>{serviceStatus(item?.WorkStatus)}</div>
                      </div>
                    </div>
                  </div>
                );
              })}

              <BottomTenantTabs index={index} setIndex={setIndex} />
            </div>
          )}
        </>
      )}
    </>
  );
};
export default Maintenance;
