import { Button, Typography, IconButton, InputAdornment } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useStyles from "./styles";
import SRE_Logo from "../../../assets/logo.png";
import arrowBack from "../../../assets/backArrow.png";
import addHome from "../../../assets/homeAddS.png";
import { Link } from "react-router-dom";
import { actions as propertyActions } from "../../CommonComponent/Property/redux/slice"
import { useDispatch } from "react-redux";
type Props = {};
const IDVerificationSuccess = (props: Props) => {
  // const { width, height } = useWindowDimensions();
  const styles = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();  // const loading = useSelector(selectLoading);

  const goBack = () => {
    navigate(-1);
  };

  const handleSubmit = (e) => {
    dispatch(propertyActions.setStateStatus("Auth"));
    navigate("/add-property");
  };

  const checkBox = () => {};
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        background: "#FFF7DD",
      }}
    >
      <div className={styles.upperContainer}>
        <IconButton
          sx={{ width: "24px", height: "24px", margin: "20px 0 0px 20px" }}
          onClick={goBack}
        >
          <img
            src={arrowBack}
            style={{
              height: 16,
              width: 16,
            }}
            alt="<-"
          />
        </IconButton>
        <div className={styles.logoContainer}>
          <img src={SRE_Logo} alt="logo" className={styles.logo} />
        </div>
      </div>
      <div className={styles.lowerContainer}>
        <Typography className={styles.pageHeading}>My Property</Typography>
        <div className={styles.centerContainer}>
          <Typography
            sx={{ color: "#F0BB00 !important", margin: "14px 0px 30px 0px" }}
            className={styles.pageTypo0}
          >
            ID Verification is successfully completed!
          </Typography>
          <Typography className={styles.pageTypo4}>
            Add your first property
          </Typography>
          <div
            style={{
              width: "87%",
              height: "154px",
              background: "#FFFFFF",
              border: "1px solid #7D7D7D",
              boxShadow:
                "10px 18px 8px rgba(168, 168, 168, 0.01), 5px 10px 7px rgba(168, 168, 168, 0.05), 2px 5px 5px rgba(168, 168, 168, 0.09), 1px 1px 3px rgba(168, 168, 168, 0.1), 0px 0px 0px rgba(168, 168, 168, 0.1)",
              borderRadius: "20px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <IconButton
              style={{
                height: "86px",
                width: "86px",
                backgroundColor: "#ffffff",
                borderRadius: "50%",
                display: "inline-block",
                boxShadow:
                  "0 2px 2px 0 rgb(0 0 0 / 20%), 0px 2px 13px 0 rgb(0 0 0 / 15%)",
                borderWidth: 1,
                borderColor: "rgba(0,0,0,0.2)",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "18px",
              }}
              onClick={handleSubmit}
            >
              <img src={addHome} alt={"Add"} />
            </IconButton>
            <Typography
              sx={{ color: "#7D7D7D !important", marginTop: "10px" }}
              className={styles.pageTypo4}
            >
              Add property
            </Typography>
          </div>
        </div>
        <Typography className={styles.bottomTypo}>
          Do you need help?
          <br />
          Contact us at{" "}
          <Link className={styles.bottomLink} to="/welcomScreen">
            Support@Sre-Management.com
          </Link>
        </Typography>
      </div>
    </div>
  );
};

export default IDVerificationSuccess;
