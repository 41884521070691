import { FormError } from "app/containers/types";
import { AddThresholdData} from "../CreateBusinessAccount/redux/types";

export function valdiator(form: AddThresholdData): Array<FormError> {
  const errors: Array<FormError> = [];
  if (!form.ThresholdAmt.value) {
    errors.push({
      name: "ThresholdAmt",
      error: "ThresholdAmt is required",
    });
  }
  if(form.ThresholdAmt.value && form.ThresholdAmt.value <= 74) {
    errors.push({
      name: "ThresholdAmt",
      error: "ThresholdAmt should be equal or greater than 75$",
    });
  }  

 return errors;
}
