import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  signInButton: {
    "&:disabled": {
      background: "#C4C4C4",
      color: "#6B6B6B",
    },
    display: "flex",
    flexDirection: "row",
    width: "95%",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 16px",
    gap: "10px",
    height: "50px",
    background: "#192342",
    color: "#ffffff",
    borderRadius: "8px",
    marginTop: 41,
    "&:hover": {
      background: "#192342",
      color: "#ffffff",
    },
  },
  inputs: {
    padding: "10px 16px",
    width: "100%",
    height: "48px",
    background: "#FFF7DD",
    color: "#7D7D7D",
    borderRadius: "20px",
    border: "unset",
    margin: "5px",
    "&:focus": {
      outline: "none",
    },
    "&:active" :{
    //   "-webkit-box-shadow": "0 0 0 0 red inset !important",
    //   background:"red !important"
    }
  },
  errorInputSpan: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    padding: "10px 16px 10px 0px",
    width: "100%",
    height: "48px",
    background: "#FFF7DD",
    color: "#7D7D7D",
    borderRadius: "20px",
    border: "unset",
    margin: "5px",
    alignItems: "center",
    outline: "#EB5757 auto",
  },
  reForgo: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
    padding: "10px",
  },
  fieldBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
  },
  errorTypo: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "19px",
    alignSelf: "flex-start",
    color: "#EB5757",
    marginLeft: "20px",
    padding: "5px",
  },
  pageHeading: {
    marginBottom: "24px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: "25px",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "140%",
    letterSpacing: "0.2px",
    color: "#192342",
  },
  inputSpan: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    padding: "10px 16px 10px 0px",
    width: "100%",
    height: "48px",
    background: "#FFF7DD",
    color: "#7D7D7D",
    borderRadius: "20px",
    border: "unset",
    margin: "5px",
    alignItems: "center",
  },
  
  checkbox: {
    marginRight: "6px",
    "&:checked": {
      accentColor: "#192342",
    },
  },
  checkboxTypos: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    color: "#192342",
    display: "contents",
    marginLeft: "6px",
  },
  linkForgot: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    color: "#006CFD",
    textDecoration: "none",
  },
  footerTxt:{
    fontSize:12,
    fontWeight:400,
    fontFamily:"Open Sans",
    textAlign:'center',
    color:'#192342',
    marginTop:16,
    // marginBottom:40
  },
});
export default useStyles;
