import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  sheet: {
    display: "flex",
    flex: 1,
    backgroundColor: "#ffffff",
    position: "absolute",
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    flexDirection: "column",
  },
  signInButtonColorInvert: {
    "&:disabled": {
      background: "#C4C4C4",
      color: "#6B6B6B",
    },
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    height: "50px",
    background: "#ffffff",
    color: "#192342",
    borderRadius: "8px",
    border: "1px solid #192342",
    "&:hover": {
      background: "#ffffff",
      color: "#192342",
    },
  },
  signInButton: {
    "&:disabled": {
      background: "#C4C4C4",
      color: "#6B6B6B",
    },
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    height: "50px",
    background: "#192342",
    color: "#ffffff",
    borderRadius: "8px",
    "&:hover": {
      background: "#192342",
      color: "#ffffff",
    },
  },
  yellowButton: {
    "&:disabled": {
      background: "#C4C4C4",
      color: "#6B6B6B",
    },
    display: "flex",
    padding: "0px",
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    height: "50px",
    color: "#192342",
    background: "#F0BB00",
    borderRadius: "8px",
    marginBottom: "10px",
    "&:hover": {
      color: "#192342",
      background: "#F0BB00",
    },
  },
  redButton: {
    "&:disabled": {
      background: "#C4C4C4",
      color: "#6B6B6B",
    },
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    height: "50px",
    background: "#ffffff",
    color: "#EB5757",
    outline: "#EB5757  auto",
    borderRadius: "8px",
    marginBottom: "24px",
    "&:hover": {
      background: "#ffffff",
      color: "#EB5757",
      outline: "#EB5757 auto",
    },
  },
  inputs: {
    padding: "10px 16px",
    width: "100%",
    height: "48px",
    background: "#FFF7DD",
    color: "#7D7D7D",
    borderRadius: "20px",
    border: "unset",
    margin: "5px",
    "&:focus": {
      outline: "none",
    },
    "&:disabled": {
      background: "#E0E0E0",
      color: "#7D7D7D",
    },
  },
  errorInputSpan: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    padding: "10px 16px 10px 0px",
    width: "100%",
    height: "48px",
    background: "#FFF7DD",
    color: "#7D7D7D",
    borderRadius: "20px",
    border: "unset",
    margin: "5px 5px 10px 0px",
    alignItems: "center",
    outline: "#EB5757 auto",
  },
  errorTypo: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "19px",
    alignSelf: "flex-start",
    color: "#EB5757",
    marginLeft: "20px",
    padding: "5px",
  },
  inputSpan: {
    "& .MuiOutlinedInput-root ": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      padding: "10px 10px 10px 05px",
      width: "100%",
      height: "48px",
      background: "#FFF7DD",
      color: "#7D7D7D",
      borderRadius: "20px",
      margin: "5px 5px 10px 0px",
      alignItems: "center",
      "& .MuiOutlinedInput-notchedOutline": {
        border: "unset",
        outline: "unset",
      },
    },
  },
  disabledInputSpan: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    padding: "10px 16px 10px 0px",
    width: "100%",
    height: "48px",
    background: "#E0E0E0",
    color: "#7D7D7D",
    borderRadius: "20px",
    border: "unset",
    margin: "5px 5px 10px 0px",
    alignItems: "center",
  },
  inputSpanPaypal: {
    display: "flex",
    flexDirection: "row",
    padding: "10px 16px 10px 0px",
    width: "100%",
    height: "48px",
    background: "#FFF7DD",
    color: "#7D7D7D",
    borderRadius: "20px",
    border: "unset",
    margin: "10px 5px 5px 0px",
    alignItems: "center",
  },
  buttonSpan: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    padding: "10px 16px 10px 0px",
    width: "100%",
    height: "48px",
    background: "#ffffff",
    color: "#192342",
    borderRadius: "20px",
    margin: "5px",
    alignItems: "center",
    outline: "#192342 auto",
    "&:disabled": {
      background: "#ffffff",
      color: "#192342",
      outline: "#192342 auto",
    },
    marginTop: 10,
    marginBottom: 110,
    "&:hover": {
      background: "#ffffff",
      color: "#192342",
      outline: "#192342 auto",
    },
  },
  pageHeading: {
    margin: "24px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: "25px",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "140%",
    letterSpacing: "0.2px",
    color: "#192342",
  },
  imagePageHeading: {
    textAlign: "center",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "140%",
    letterSpacing: "0.2px",
    color: "#192342",
    marginBottom: "16px",
  },
  bigAddEnable: {
    height: "25px",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "140%",
    letterSpacing: "0.2px",
    color: "#F0BB00",
  },
  boxSimpleWhite: {
    display: " flex",
    flexDirection: "row",
    alignItems: " center",
    padding: " 10px 20px",
    width: "100%",
    height: " 44px",
    background: " #FFFFFF",
    border: " 0.5px solid #BCBCBC",
    boxShadow:
      " 7px 19px 8px rgba(194, 194, 194, 0.01), 4px 10px 7px rgba(194, 194, 194, 0.05), 2px 5px 5px rgba(194, 194, 194, 0.09), 0px 1px 3px rgba(194, 194, 194, 0.1), 0px 0px 0px rgba(194, 194, 194, 0.1)",
    borderRadius: " 20px",
    marginBottom: "10px",
  },
  bigAddDisable: {
    height: "25px",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "20px",
    lineHeight: "140%",
    letterSpacing: "0.2px",
    color: "#BCBCBC",
  },
  checkbox: {
    marginRight: "6px",
    marginTop: "2px",
    color: "#192342 !important",
    "&:checked fieldset": {
      accentColor: "#192342",
    },
  },
  checkboxTypos: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    color: "#192342",
    display: "contents",
    marginLeft: "6px",
  },
  linkForgot: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    color: "#006CFD",
    textDecoration: "none",
  },
  upperContainer: {
    height: "32%",
  },
  lowerContainer: {
    height: "68%",
    background: "#FFFFFF",
    boxShadow:
      "0px -37px 15px rgba(222, 222, 222, 0.01), 0px -21px 12px rgba(222, 222, 222, 0.05), 0px -9px 9px rgba(222, 222, 222, 0.09), 0px -2px 5px rgba(222, 222, 222, 0.1), 0px 0px 0px rgba(222, 222, 222, 0.1)",
    borderRadius: "30px 30px 0px 0px",
    padding: "0% 2%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  singleContainer: {
    padding: "2% 2%",
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
  },
  pageTypoUI: {
    textAlign: "center",
    height: "fit-content",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "140%",
    letterSpacing: "0.2px",
    marginBottom: "10px",
    color: "#192342",
    position: "relative",
    left: 0,
    marginRight: "auto",
  },
  popup: {
    "& .MuiDialog-container": {},
    "& .MuiPaper-root ": {
      width: "100%",
      overflow: "auto",
      padding: "1%",
      marginTop: "10%",
      background: "#FFFFFF",
      boxShadow:
        "11px 24px 11px rgba(61, 61, 61, 0.01), 6px 13px 9px rgba(61, 61, 61, 0.05), 3px 6px 7px rgba(61, 61, 61, 0.09), 1px 1px 4px rgba(61, 61, 61, 0.1), 0px 0px 0px rgba(61, 61, 61, 0.1)",
      borderRadius: "30px",
    },
  },
  popupFullScreen: {
    "& .MuiDialog-container": {
      margin: "0px !important",
      width: "100%",
      height:"120%",
      "-webkit-align-items": "unset",
    },
    "& .MuiPaper-root ": {
      width: "100%",
      height: "100%",
      overflow: "auto",
      background: "#FFFFFF",
      margin:"0px",
      boxShadow:
        "11px 24px 11px rgba(61, 61, 61, 0.01), 6px 13px 9px rgba(61, 61, 61, 0.05), 3px 6px 7px rgba(61, 61, 61, 0.09), 1px 1px 4px rgba(61, 61, 61, 0.1), 0px 0px 0px rgba(61, 61, 61, 0.1)",
    },
  },
  filterPopup: {
    "& .MuiDialog-container": {},
    "& .MuiPaper-root ": {
      width: "100%",
      overflow: "auto",
      background: "#FFFFFF",
    },
  },
  pageTypo2: {
    textAlign: "center",
    height: "fit-content",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "140%",
    letterSpacing: "0.2px",
    marginBottom: "10px",
    color: "#192342",
  },
  exitButtonTypo: {
    textAlign: "center",
    height: "fit-content",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "18px",
    letterSpacing: "0.2px",
    color: "#FFFFFF",
  },
  typo70014: {
    textAlign: "center",
    height: "fit-content",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "18px",
    letterSpacing: "0.2px",
    color: "#192342",
  },
  typobutton70014: {
    textAlign: "center",
    height: "fit-content",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "18px",
    letterSpacing: "0.2px",
  },
  typo40014: {
    textAlign: "center",
    height: "fit-content",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "18px",
    letterSpacing: "0.2px",
    color: "#192342",
  },
  typo60014: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "18px",
    letterSpacing: "0.2px",
    color: "#192342",
  },
  typo12600button: {
    padding: "5px 12px",
    textAlign: "center",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    lineHeight: "18px",
    letterSpacing: "0.2px",
    color: "#192342",
  },
  pageTypoExitButton12400: {
    overflow: "hidden",
    width: "146px",
    height: "100%",
    textAlign: "center",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "16.8px",
    letterSpacing: "0.1px",
    color: "#192342",
    marginBottom: "5px",
    postion: "relative",
    top: 0,
  },
  exitAppButton: {
    "&:disabled": {
      background: "#C4C4C4",
      color: "#6B6B6B",
    },
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    height: "50px",
    background: "#192342",
    color: "#ffffff",
    borderRadius: "8px",
    position: "relative",
    bottom: "0px",
    "&:hover": {
      background: "#192342",
      color: "#ffffff",
    },
  },
  closeIcon: {
    position: "absolute",
    right: "5px",
    top: "5px",
  },
  checkIcon: {
    height: "84px",
    width: "84px",
    backgroundColor: "#ffffff",
    borderRadius: "50%",
    display: "inline-block",
    boxShadow: "0 2px 2px 0 rgb(0 0 0 / 20%), 0px 2px 13px 0 rgb(0 0 0 / 15%)",
    borderWidth: 1,
    borderColor: "rgba(0,0,0,0.2)",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    alignSelf: "center",
    textAlign: "center",
    height: "fit-content",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "140%",
    letterSpacing: "0.2px",
    color: "#192342",
  },
  typoLeftAligned: {
    position: "relative",
    marginBottom: "16px",
    left: 0,
    marginRight: "auto",
    alignSelf: "center",
    width: "150px",
    textAlign: "left",
    height: "fit-content",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "140%",
    letterSpacing: "0.2px",
    color: "#192342",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  filterTitle: {
    position: "relative",
    marginBottom: "16px",
    left: 0,
    marginRight: "auto",
    alignSelf: "center",
    textAlign: "left",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "140%",
    letterSpacing: "0.2px",
    color: "#192342",
  },
  typo13700: {
    alignSelf: "center",
    textAlign: "left",
    height: "fit-content",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "13px",
    lineHeight: "140%",
    letterSpacing: "0.2px",
    color: "#192342",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  typo12700: {
    alignSelf: "center",
    textAlign: "left",
    height: "fit-content",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "13px",
    lineHeight: "140%",
    letterSpacing: "0.2px",
    color: "#192342",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  company: {
    width: "105px",
    textAlign: "left",
    height: "fit-content",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "140%",
    letterSpacing: "0.2px",
    marginBottom: "6px",
    color: "#7D7D7D",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  address: {
    textAlign: "center",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "140%",
    letterSpacing: "0.2px",
    color: "#192342",
  },
  typoLeft40016: {
    textAlign: "left",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "140%",
    letterSpacing: "0.2px",
    color: "#192342",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  typo40016: {
    textAlign: "center",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "140%",
    letterSpacing: "0.2px",
    color: "#192342",
  },
  typoWrap40016: {
    textAlign: "center",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "140%",
    letterSpacing: "0.2px",
    color: "#192342",
  },
  typoBlue40016: {
    textAlign: "left",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "140%",
    letterSpacing: "0.2px",
    color: "#192342",
    marginBottom: "0px",
  },
  typo60018: {
    textAlign: "left",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "140%",
    letterSpacing: "0.2px",
    color: "#192342",
  },
  radio: {
    "&.MuiRadio-colorPrimary": {
      color: "#192342",
    },
  },
  select: {
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "none",
      borderRadius: "none",
    },
    height: "48px",
    background: "#FFF7DD",
    border: "none !important",
    margin: "5px 0px 16px 0px",
    padding: "12px 0px 16px 5px",
  },
  pageTypoone: {
    textAlign: "center",
    height: "fit-content",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "140%",
    letterSpacing: "0.2px",
    marginBottom: "6px",
    color: "#192342",
    position: "relative",
    left: 0,
    marginRight: "auto",
  },
  buttonPaypal: {
    textAlign: "center",
    height: "fit-content",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.1px",
    color: "#192342",
  },
  pageTypoThreshold: {
    textAlign: "left",
    height: "fit-content",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "19px",
    letterSpacing: "0.2px",
    color: "#192342",
    paddingLeft: "22px",
  },
  pageTypoThresholdWP: {
    textAlign: "left",
    height: "fit-content",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "19px",
    letterSpacing: "0.2px",
    color: "#192342",
  },
  pageTypoH: {
    textAlign: "center",
    height: "fit-content",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "19px",
    letterSpacing: "0.2px",
    color: "#192342",
  },
  pageTypo12400: {
    textAlign: "center",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    lineHeight: "16.8px",
    letterSpacing: "0.1px",
    color: "#192342",
    position: "relative",
    bottom: "22px",
  },
  pageTypoThresholdDisabled: {
    textAlign: "left",
    height: "fit-content",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "19px",
    letterSpacing: "0.2px",
    margin: "0 0 5px 3%",
    color: "#7D7D7D",
  },
  pageTypoMinimum: {
    textAlign: "left",
    height: "fit-content",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "10px",
    lineHeight: "14px",
    letterSpacing: "0.2px",
    margin: "0 0 5px 3%",
    color: "#192342",
  },
  bottomTypo: {
    textAlign: "center",
    height: "fit-content",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    letterSpacing: "0.2px",
    color: "#192342",
  },
  bottomLink: {
    textAlign: "center",
    height: "fit-content",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    letterSpacing: "0.2px",
    color: "#006CFD",
    textDecoration: "none",
  },
  centerContainer: {
    width: "100%",
    height: "12%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 0,
    margin: "0px 46px 0px 46px",
  },
  dialogTypo: {
    textAlign: "center",
    height: "fit-content",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "140%",
    letterSpacing: "0.1px",
    color: "#192342",
    marginBottom: "16px",
  },
  whiteSpan: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
    height: "48px",
    background: "#FFFFFF",
    color: " #192342",
    borderRadius: "20px",
    alignItems: "center",
    border: "0.5px solid #BCBCBC",
    boxShadow:
      "7px 19px 8px rgba(194, 194, 194, 0.01), 4px 10px 7px rgba(194, 194, 194, 0.05), 2px 5px 5px rgba(194, 194, 194, 0.09), 0px 1px 3px rgba(194, 194, 194, 0.1), 0px 0px 0px rgba(194, 194, 194, 0.1)",
  },
});

export default useStyles;
