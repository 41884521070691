import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  container: {
    backgroundColor: "#FFFFFF",
    minHeight: "100%",
    // position:"absolute",
    bottom: "auto",
    width: "100%",
  },
  title: {
    fontFamily: "Open Sans",
    fontSize: 18,
    fontWeight: "600",
    marginBottom: 16,
    marginTop: 24,
    marginLeft: 16,
    marginRight: 16,
  },
  rowView: {
    display: "flex",
    flexDirection: "row",
    flex: 1,
    justifyContent: "space-between",
    marginLeft: 16,
    marginRight: 16,
  },
  cardBalance: {
    border: "2px solid #FFFFFF",
    backgroundColor: "#F5F5F5",
    padding: '15px 0',
    flex: 1, 
    margin : '10px 0',   
    boxShadow:
      "0px -37px 15px rgba(222, 222, 222, 0.01), 0px -21px 12px rgba(222, 222, 222, 0.05), 0px -9px 9px rgba(222, 222, 222, 0.09), 0px -2px 5px rgba(222, 222, 222, 0.1), 0px 0px 0px rgba(222, 222, 222, 0.1)",
    },
  card: {
    // paddingLeft:17,
    // paddingRight:17,
    border: "2px solid #FFFFFF",
    backgroundColor: "#F5F5F5",
    borderRadius: 25,
    // paddingBottom:10.5,
    paddingTop: 10,
    flex: 0.47,
    // justifyContent: "center",
    // alignItems: "center",
    // boxShadow: '1px 1px 1px 1px #F5F5F5'

    boxShadow:
      "0px -37px 15px rgba(222, 222, 222, 0.01), 0px -21px 12px rgba(222, 222, 222, 0.05), 0px -9px 9px rgba(222, 222, 222, 0.09), 0px -2px 5px rgba(222, 222, 222, 0.1), 0px 0px 0px rgba(222, 222, 222, 0.1)",
        
    },
    pageTypoThreshold: {
      textAlign: "left",
      height: "fit-content",
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: "600",
      fontSize: "14px",
      lineHeight: "19px",
      letterSpacing: "0.2px",
      color: "#192342",
      paddingLeft: "22px",
    },
    pageTypoMinimum: {
      textAlign: "left",
      height: "fit-content",
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "10px",
      lineHeight: "14px",
      letterSpacing: "0.2px",
      margin: "0 0 5px 3%",
      color: "#192342",
    },
    errorTypo: {
      fontFamily: "Open Sans",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "14px",
      lineHeight: "19px",
      alignSelf: "flex-start",
      color: "#EB5757",
      marginLeft: "20px",
      padding: "5px",
    },
    floatText:{
        // display:'flex',
  
        backgroundColor:'#FFFFFF',
        borderRadius:10,
        // width:60,
       borderBottomLeftRadius:10,
       borderTopRightRadius:25,
        // paddingLeft:10,
        // paddingRight:10,
        // paddingTop:5,
        paddingBottom:5,
        // marginTop:-63,
        // marginRight:1,
        fontSize:10,
        color:'#006CFD',
        // float:'left',
        // right:20,
        // position:'absolute'
     
     
    },icon:{

    },tilesTxt:{
        fontFamily:'Open Sans',
        fontWeight:'600',
        color:'#192342',
        fontSize:14,
        textAlign:'center',
        paddingLeft:17,
        paddingRight:17,
    },
    topTxt:{
        fontFamily:'Open Sans',
        fontWeight:'600',
        color:'#192342',
        fontSize:14,
    //    backgroundColor:'red',
  },
  tilesVal: {
    fontFamily: "Open Sans",
    fontWeight: "700",
    color: "#F44D4D",
    marginTop: 7,
    textAlign: "center",
    // paddingLeft:17,
    // paddingRight:17,
  },
  float: {
    // position:'absolute',
    // right:0

    backgroundColor: "#FFFFFF",
    // borderRadius:10,
    borderTopLeftRadius: 10,
    borderBottomRightRadius: 10,
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 5,
    // paddingBottom:5,
    // marginTop:-9,
    fontSize: 10,
    color: "#006CFD",
    float: "right",
  },
  img: {
    height: 42,
    width: 42,
    marginRight: 10,
  },
  plusIcon: {
    height: 16,
    width: 16,
    float: "right",

    marginLeft: -20,
    // position:'absolute',
    // marginRight:50,
    marginTop: 10,
  },
  serviceIcon: {
    height: 32,
    width: 32,
    marginRight: 8,
  },
  indicator: {
    backgroundColor: "#F44D4D",
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 3,
    paddingBottom: 3,
    marginTop: 5,
    borderRadius: 8,
    fontSize: 10,
    color: "white",
    fontWeight: "600",
    // display:'flex',
    // alignSelf:'center',
    marginBottom: 9,
  },
  popup: {
    "& .MuiDialog-container": {},
    "& .MuiPaper-root ": {
      width: "100%",
      overflow: "auto",
      padding: "1%",
      marginTop: "10%",
      background: "#FFFFFF",
      boxShadow:
        "11px 24px 11px rgba(61, 61, 61, 0.01), 6px 13px 9px rgba(61, 61, 61, 0.05), 3px 6px 7px rgba(61, 61, 61, 0.09), 1px 1px 4px rgba(61, 61, 61, 0.1), 0px 0px 0px rgba(61, 61, 61, 0.1)",
      borderRadius: "30px",
    },
  },
  modalTitle: {
    fontFamily: "Open Sans",
    fontWeight: "600",
    fontSize: 16,
    color: "192342",
    textAlign: "center",
    marginBottom: "10px",
  },
  modalSubTitle: {
    fontFamily: "Open Sans",
    fontWeight: "400",
    fontSize: 16,
    color: "192342",
    textAlign: "center",
  },
  typo60014: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "18px",
    letterSpacing: "0.2px",
    color: "#192342",
    marginTop: "10px",
  },
  menuPaper: {
    maxHeight: 200, 
    width: 100,  
  },
  menuInput: {
    color: 'red',
  },
  select: {
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "none",
      borderRadius: "none",
    },
    height: "48px",
    background: "#FFF7DD",
    borderRadius: "20px",
    margin: "5px 0px 16px 0px",
    padding: "12px 0px 16px 12px",
  },
  signInButton: {
    "&:disabled": {
      background: "#C4C4C4",
      color: "#6B6B6B",
    },
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: "50px",
    background: "#192342",
    color: "#ffffff",
    borderRadius: "8px",
    margin: "10px 0px 10px 10px",
    "&:hover": {
      background: "#192342",
      color: "#ffffff",
    },
  },
  typoGray40016: {
    textAlign: "left",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "140%",
    letterSpacing: "0.2px",
    color: "#7D7D7D",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "100%",
  },
  outlinebtn: {
    height: "50px",
    alignSelf: "center",
    margin: "10px 0px 10px 10px",
    borderRadius: "8px",
  },
  label: {
    fontSize: 14,
    color: "#192342",
  },
  description: {
    fontSize: 14,
    fontWeight: "400",
    fontFamily: "Open Sans",
    marginTop: 24,
    marginLeft: 16,
    marginRight: 16,
    marginBottom: 16,
  },

  customUpload: {
    backgroundColor: "red",
    "&.file-upload-inner": {
      backgroundColor: "blue",
    },
  },
});
