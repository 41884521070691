import { Alert, Button } from "@mui/material";
import Page from "app/components/Page";
import PageImageHeader from "app/components/PageImageHeader";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "utils/hooks/useWindowDimensions";
import { BusinessType } from "../redux/enum";
import { selectBusinessAccountData, selectCreatedBusinessData, selectError, selectLoading } from "../redux/selectors";
import BusinessAccountForm from "./CommonForms/BusinessAccountForm";
import { valdiator } from "./CommonForms/BusinessAccountForm/validator";
import useStyles from "./styles";
import { actions } from "../redux/slice";
import { actions as authActions } from "../../slice";
import { LoggerClass } from "utils/logger";
import Loader from "app/components/Loader";


const BusinessAccount = () => {
  const { width, height } = useWindowDimensions();
  const dispatch = useDispatch();
  const styles = useStyles();
  const navigation = useNavigate();
  const [disabled, setDisabled] = useState<boolean>(true);
  const [errorMsg, setErrorMsg] = useState('');
  const businessAccountData = useSelector(selectBusinessAccountData);
  const createdbusinessData = useSelector(selectCreatedBusinessData);
  const error = useSelector(selectError);
  const loading = useSelector(selectLoading);
  const errors = valdiator(businessAccountData);

  useEffect(() => {
    if (error) setErrorMsg(error);
    if (error === "Unauthorized") {  // || error === "MESSAGE.ALREADY_EXIST"
      dispatch(authActions.resetModule());
      dispatch(actions.resetModule());
      navigation("/auth");
    }
  }, [error]);

  useEffect(() => {
    let allFieldsHaveValue = true;

    for (const key in businessAccountData) {
      if ((Number(businessAccountData['businessType'].value) === BusinessType.LLCS || Number(businessAccountData['businessType'].value) === BusinessType.CORPORATION || Number(businessAccountData['businessType'].value) === BusinessType.NON_PROFITS || Number(businessAccountData['businessType'].value) === BusinessType.PARTNERSHIP || Number(businessAccountData['businessType'].value) === BusinessType.PUBLICLY_TRADED_CORPORATIONS) && (key !== "ownerSSN" && key !== "ownerDOB" && key !== "businessAddress2" && businessAccountData.hasOwnProperty(key) && businessAccountData[key].value.length === 0)) {
        allFieldsHaveValue = false;
        break;
      }

      if ((Number(businessAccountData['businessType'].value) === BusinessType.TRUST || Number(businessAccountData['businessType'].value) === BusinessType.SOLE_PARTNERSHIP || Number(businessAccountData['businessType'].value) === BusinessType.UNICORPORATED_ASSOCIATION) && (key !== "businessEIN" && key !== "businessAddress2" && businessAccountData.hasOwnProperty(key) && businessAccountData[key].value.length === 0)) {
        allFieldsHaveValue = false;
        break;
      }
    }
    if (allFieldsHaveValue) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }

  }, [businessAccountData]);


  const handleSubmit = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    if (errors.length) {
      dispatch(actions.setBusinessAccountError({ key: 'addBusinessAccount', errors }))
      return
    }
    dispatch(actions.createBusinessAccount({ data: businessAccountData, callback: onLoginSuccess }));
  };

  const onLoginSuccess = () => {
    dispatch(actions.resetError());
    if (Number(businessAccountData.businessType.value) === BusinessType.TRUST || Number(businessAccountData.businessType.value) === BusinessType.SOLE_PARTNERSHIP || Number(businessAccountData.businessType.value) === BusinessType.UNICORPORATED_ASSOCIATION) {
      LoggerClass.info("Go to Next Screen");
      navigation("/add-threshold");
    } else {
      navigation("/add-controller?id=" + createdbusinessData.BusinessId);
    }
  };

  return (
    <>
      <Loader loading={loading} />

      <Page>
        <PageImageHeader />
        <div
          style={{
            width: width,
            top: height / 2.82,
          }}
          className={styles.sheet}
        >
          <div className={styles.title}>Create an Account</div>
          <div className={styles.title1} style={{
            marginBottom: "10px"
          }}
          >Create a Business Verified Customer Record</div>
          {errorMsg && (
            <Alert className={styles.error} severity="error">
              {errorMsg}
            </Alert>
          )}
          <BusinessAccountForm />
          <div
            style={{
              marginBottom: "10px",
              width: "90%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              margin: 'auto'
            }}
          >
            <Button
              disabled={disabled}
              className={styles.signInButton}
              variant="contained"
              onClick={handleSubmit}
            >
              Continue
            </Button>

          </div>
        </div>
      </Page>
    </>
  );
};

export default BusinessAccount;
