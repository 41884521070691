import {
  Button,
  Box,
  Dialog,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import { actions, reducer, sliceKey } from "../../../redux/slice";
import { usePropertySaga } from "../../../redux/saga";
import { style } from "@mui/system";
import useStyles from "./styles";

type Props = {};
const Message = ({
  showPopup,
  closePopup,
  handleClose = () => {},
  handleChange = () => {},
  selectedCheck,
}) => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: usePropertySaga });
  const styles = useStyles();
  const [show, setShow] = useState(false);
  const [otp, setOtp] = useState("");
  const [disable, setDisable] = useState(true);

  const close = () => {
    setShow(false);
    closePopup();
  };

  useEffect(() => {
    if (otp?.length === 4) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [otp?.length]);

  useEffect(() => {
    setShow(showPopup);
  }, [showPopup]);

  return (
    <Dialog open={show} className={styles.popup}>
      <Box sx={{ display: "flex", flexDirection: "column", margin: "16px" }}>
        <div
          style={{
            fontFamily: "Open Sans",
            fontSize: 18,
            color: "#192342",
            fontWeight: "400",
            textAlign: "center",
          }}
        >
          The tenant
          {selectedCheck?.map((item, index) => {
            return (
              <span
                key={index.toString()}
                style={{
                  fontFamily: "Open Sans",
                  fontSize: 18,
                  color: "#192342",
                  fontWeight: "600",
                }}
              >
                {" "}
                {index + 1 === selectedCheck?.length ? `${item.UserName} ` : `${item.UserName}, `}
              </span>
            );
          })}
          has been deleted from the property
          <span
            style={{
              fontFamily: "Open Sans",
              fontSize: 18,
              color: "#192342",
              fontWeight: "600",
            }}
          >
            {" "}
            3429 Adkins, Rd. NW Atlanta, GA 30331
          </span>
        </div>

        <Button
          className={styles.signInButton}
          onClick={close}
          style={{ marginTop: 24 }}
        >
          Continue
        </Button>
      </Box>
    </Dialog>
  );
};

export default Message;
