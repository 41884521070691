import set from "lodash/set";
import { createSlice } from "utils/@reduxjs/toolkit";
import { createAction, PayloadAction } from "@reduxjs/toolkit";
import { LandlordProfileState } from "./types";
import { FormError } from "app/containers/types";

export const logoutSuccess = createAction("LOGOUT_SUCCESS");
// The initial state of the GithubRepoForm container

const landlordProfileSlice = createSlice({
  name: "landlordProfileState",
  initialState: LandlordProfileState,
  reducers: {
    //set data via dispatch 
    getBusinessDetails: (state) => {
      state.loading = true;
    },
    getBusinessDetailsSuccess: (state, action) => {
      state.loading = false;
      state.businessDetails = action.payload;
    },
    getBusinessDetailsError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },    
    changeMobileSetData: (
      state,
      action
    ) => {
      state.changeMobileRequest.phoneNumber.value = action.payload;
    },
    UpdateMobileNumber: (state, action) => {
      state.loading = true;
    },
    UpdateMobileNumberSuccess: (state, action) => {
      state.loading = false;
    },
    UpdateMobileNumberError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },     
    
  },
});

export const { actions, reducer, name: sliceKey } = landlordProfileSlice;
