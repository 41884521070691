
import { LoggerClass as Logger } from "utils/logger";
const fetchResourceFromURI = async (uri:any) => {
  const response = await fetch(uri);
  Logger.log(response);
  const blob = await response.blob();
  return blob;
};

export async function uploadFile(file:any, signedRequest:any, callback:any ){
  Logger.log(file,signedRequest)
  file = await fetchResourceFromURI(file.url);
  Logger.log(file,signedRequest) 
  const xhr = new XMLHttpRequest();
  xhr.open('PUT', signedRequest);

  xhr.upload.onprogress = function (e) {
    if (e.lengthComputable) {
      if(callback){
          callback(file.name, ((e.loaded/ e.total)*100))
      }
    }
  }

  xhr.onloadstart = function (e) {
    if(callback){
      callback(file.name, "start")
    }
  }

  xhr.onloadend = function (e) {
    if(callback){
      callback(file.name, "end")
    }
  }

  xhr.onreadystatechange = () => {
    if(xhr.readyState === 4){
      if(xhr.status === 200){
        Logger.log("xhr.status", xhr)
      }
      else{
        Logger.log(xhr,'Could not upload file.');
      }
    }
  };
  xhr.send(file);
}