import useStyles from "./styles";
import { Radio, Typography } from "@mui/material";
import { selectControllerOwnerData, selectCreatedBusinessData } from "../../../redux/selectors";
import { actions } from "../../../redux/slice";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { PersonType } from "../../../redux/enum";
import { selectUser } from "app/containers/Auth/selectors";
import dayjs, { Dayjs } from "dayjs";
import DatePickerBusiness from "app/components/DatePickerBusiness";
import CountryDropDown from "app/components/CountryTypeDropDown";

export default function BusinessControllerOwnerForm({ formType }) {
    const dispatch = useDispatch();
    const styles = useStyles();
    const navigation = useNavigate();
    const location = useLocation();
    const controllerOwnerData = useSelector(selectControllerOwnerData);
    const createdbusinessData = useSelector(selectCreatedBusinessData);
    const loggedinUser = useSelector(selectUser);
    const today = dayjs();
    const [dob, setDOB] = useState<Dayjs | null>(today);
    const [country, setCountry] = useState<string>("Choose Country");

    useEffect(() => {
        dispatch(
            actions.setControllerOwnerData({
                name: "dob",
                value: dob !== null ? dob?.format("YYYY-MM-DD") : "",
                form: "addControllerOwner",
            })
        );
    }, [dob]);

    useEffect(() => {
        dispatch(actions.setControllerOwnerData({ name: 'userId', value: loggedinUser?.userId ? loggedinUser?.userId + "" : JSON.parse(localStorage.getItem("loginResponse")!).userId, form: "addControllerOwner" }));
        dispatch(actions.setControllerOwnerData({ name: 'businessId', value: createdbusinessData?.BusinessId ? createdbusinessData?.BusinessId + "" : localStorage.getItem("businessId") + "", form: "addControllerOwner" }));
    }, [loggedinUser]);

    const onChangeEvent = (event) => {
        const { name, value } = event.target;
        if (name === 'personType' && Number(value) === PersonType.US) {
            dispatch(actions.setControllerOwnerData({ name: "passportNumber", value: "", form: "addControllerOwner" }));
            dispatch(actions.setControllerOwnerData({ name: "issuanceCountry", value: "", form: "addControllerOwner" }));
            dispatch(actions.setControllerOwnerData({ name: "country", value: "", form: "addControllerOwner" }));
        }

        if (name === 'personType' && Number(value) === PersonType.NON_US) {
            dispatch(actions.setControllerOwnerData({ name: "ssn", value: "", form: "addControllerOwner" }));
        }

        if (name === 'country') {
            setCountry(value);
        }

        if(name === 'ssn' || name === 'zip'){
            dispatch(
                actions.setControllerOwnerData({ name, value: value.trim().replace(/[^\w\s]/gi, ""), form: "addControllerOwner" })
            );
        }else{
            dispatch(
                actions.setControllerOwnerData({ name, value: value.trim(), form: "addControllerOwner" })
            );
        }
        // dispatch(
        //     actions.setControllerOwnerData({ name, value: value.trim(), form: "addControllerOwner" })
        // );
    }

    const onOwnerDOBChangeEvent = (value) => {
        setDOB(value);
    };

    const handleKeyEvent = (e) => {
        let charCode = (e.which) ? e.which : e.keyCode;
        if (charCode != 46 && charCode > 31 && (charCode < 48 || charCode > 57)) {
            e.preventDefault();
        }
    };

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                width: "90%",
                alignItems: "flex-start",
                padding: "4px",
                margin: 'auto'
            }}
        >

            <div className={styles.disabledInputSpan}>
                <Typography className={styles.pageTypoThresholdDisabled}>
                    First Name
                </Typography>
                <input
                    className={styles.inputs}
                    placeholder="Enter First Name"
                    name="firstName"
                    value={controllerOwnerData.firstName.value}
                    type="text"
                    onChange={onChangeEvent}
                />
                {controllerOwnerData.firstName.error && <Typography sx={{ color: '#922', marginLeft: '3%', width: '90%', alignSelf: 'center' }} >{controllerOwnerData.firstName.error}</Typography>}

            </div>
            <div className={styles.disabledInputSpan}>
                <Typography className={styles.pageTypoThresholdDisabled}>
                    Last Name
                </Typography>
                <input
                    className={styles.inputs}
                    placeholder="Enter Last Name"
                    name="lastName"
                    value={controllerOwnerData.lastName.value}
                    type="text"
                    onChange={onChangeEvent}
                />
                {controllerOwnerData.lastName.error && <Typography sx={{ color: '#922', marginLeft: '3%', width: '90%', alignSelf: 'center' }} >{controllerOwnerData.lastName.error}</Typography>}

            </div>
            {formType === "Controller" && <div className={styles.disabledInputSpan}>
                <Typography
                    sx={{ color: "7D7D7D !important" }}
                    className={styles.pageTypoThresholdDisabled}
                >
                    Title
                </Typography>
                <input
                    className={styles.inputs}
                    placeholder="Eg. CEO"
                    name="title"
                    value={controllerOwnerData.title.value}
                    type="text"
                    onChange={onChangeEvent}
                />
                {controllerOwnerData.title.error && <Typography sx={{ color: '#922', marginLeft: '3%', width: '90%', alignSelf: 'center' }} >{controllerOwnerData.title.error}</Typography>}

            </div>
            }

            <div className={styles.disabledInputSpan}>
                <div
                    style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "row",
                        flexWrap: "wrap",
                        alignItems: "flex-start",
                    }}
                >
                    <div className={styles.radioButtonSec}>
                        <Radio
                            className={styles.checkbox}
                            checked={Number(controllerOwnerData.personType.value) === PersonType.US}
                            name="personType"
                            onChange={onChangeEvent}
                            value={PersonType.US}
                        />
                        <div>US Person</div>
                    </div>
                    <div className={styles.radioButtonSec}>
                        <Radio
                            className={styles.checkbox}
                            name="personType"
                            checked={Number(controllerOwnerData.personType.value) === PersonType.NON_US}
                            onChange={onChangeEvent}
                            value={PersonType.NON_US}
                        />
                        <div>Non- US Person</div>
                    </div>
                </div>
            </div>

            {((formType === "Controller") || (formType !== "Controller" && Number(controllerOwnerData.personType.value)) === PersonType.NON_US) && <div className={styles.disabledInputSpan}>
                <Typography className={styles.pageTypoThresholdDisabled}>
                    DOB
                </Typography>
                <DatePickerBusiness
                    handleChange={onOwnerDOBChangeEvent}
                    value={dob}
                    width={'100%'}
                />
                {controllerOwnerData.dob.error && <Typography sx={{ color: '#922', marginLeft: '3%', width: '90%', alignSelf: 'center' }} >{controllerOwnerData.dob.error}</Typography>}
            </div>}

            {(formType === "Controller" && Number(controllerOwnerData.personType.value) === PersonType.US) && <div className={styles.disabledInputSpan}>
                <Typography className={styles.pageTypoThresholdDisabled}>
                    {formType === "Controller" && 'Controller '}SSN
                </Typography>
                <input
                    className={styles.inputs}
                    placeholder="Enter 9 Digits SSN"
                    name="ssn"
                    value={controllerOwnerData.ssn.value}
                    type="text"
                    onChange={onChangeEvent}
                    onKeyDown={handleKeyEvent}
                />
                {controllerOwnerData.ssn.error && <Typography sx={{ color: '#922', marginLeft: '3%', width: '90%', alignSelf: 'center' }} >{controllerOwnerData.ssn.error}</Typography>}
            </div>
            }

            {(formType !== "Controller" && Number(controllerOwnerData.personType.value) === PersonType.US) &&
                <div
                    style={{
                        display: "flex",
                        flex: 1,
                        flexDirection: "row",
                        flexWrap: "wrap",
                        alignItems: "flex-start",
                    }}
                >
                    <div style={{ width: "49%", marginRight: "1%" }}>
                        <div className={styles.disabledInputSpan}>
                            <Typography className={styles.pageTypoThresholdDisabled}>
                                Date of Birth
                            </Typography>
                            <DatePickerBusiness
                                handleChange={onOwnerDOBChangeEvent}
                                value={dob}
                                width={'100%'}
                            />
                            {controllerOwnerData.dob.error && <Typography sx={{ color: '#922', marginLeft: '3%', width: '90%', alignSelf: 'center' }} >{controllerOwnerData.dob.error}</Typography>}
                        </div>
                    </div>
                    <div style={{ width: "49%", marginRight: "1%" }}>
                        <div className={styles.disabledInputSpan}>
                            <Typography className={styles.pageTypoThresholdDisabled}>
                                {formType === "Controller" && 'Controller '}SSN
                            </Typography>
                            <input
                                className={styles.inputs}
                                placeholder="Enter 9 Digits SSN"
                                name="ssn"
                                value={controllerOwnerData.ssn.value}
                                type="text"
                                onChange={onChangeEvent}
                                onKeyDown={handleKeyEvent}
                            />
                            {controllerOwnerData.ssn.error && <Typography sx={{ color: '#922', marginLeft: '3%', width: '90%', alignSelf: 'center' }} >{controllerOwnerData.ssn.error}</Typography>}
                        </div>
                    </div>
                </div>
            }

            {Number(controllerOwnerData.personType.value) === PersonType.NON_US &&
                <>
                    <div className={styles.disabledInputSpan}>
                        <Typography className={styles.pageTypoThresholdDisabled}>
                            Passport Number
                        </Typography>
                        <input
                            className={styles.inputs}
                            placeholder="Enter Passport Number"
                            name="passportNumber"
                            value={controllerOwnerData.passportNumber.value}
                            type="text"
                            onChange={onChangeEvent}
                        />
                        {controllerOwnerData.passportNumber.error && <Typography sx={{ color: '#922', marginLeft: '3%', width: '90%', alignSelf: 'center' }} >{controllerOwnerData.passportNumber.error}</Typography>}
                    </div>

                    <div className={styles.disabledInputSpan}>
                        <Typography className={styles.pageTypoThresholdDisabled}>
                            Issuance Country
                        </Typography>
                        <input
                            className={styles.inputs}
                            placeholder="Enter ISO Code"
                            name="issuanceCountry"
                            value={controllerOwnerData.issuanceCountry.value}
                            type="text"
                            onChange={onChangeEvent}
                        />
                        {controllerOwnerData.issuanceCountry.error && <Typography sx={{ color: '#922', marginLeft: '3%', width: '90%', alignSelf: 'center' }} >{controllerOwnerData.issuanceCountry.error}</Typography>}
                    </div>
                </>
            }
            <div className={styles.disabledInputSpan}>
                <Typography className={styles.pageTypoThresholdDisabled}>
                    {formType === "Controller" && 'Controller '}Address
                </Typography>
                <input
                    className={styles.inputs}
                    placeholder="Enter Street name and number"
                    name="address"
                    value={controllerOwnerData.address.value}
                    type="text"
                    onChange={onChangeEvent}
                />
                {controllerOwnerData.address.error && <Typography sx={{ color: '#922', marginLeft: '3%', width: '90%', alignSelf: 'center' }} >{controllerOwnerData.address.error}</Typography>}

            </div>

            <div className={styles.disabledInputSpan}>
                <input
                    className={styles.inputs}
                    placeholder="Aprt, floor, suite, bldg #"
                    name="address2"
                    value={controllerOwnerData.address2.value}
                    type="text"
                    onChange={onChangeEvent}
                />
                <Typography sx={{ marginLeft: '3%', width: '90%', color: '#ccc', fontSize: '12px', alignSelf: 'center' }} >Optional field</Typography>

            </div>
            {Number(controllerOwnerData.personType.value) === PersonType.NON_US && <div className={styles.disabledInputSpan}>
                <Typography className={styles.pageTypoThresholdDisabled}>
                    Country
                </Typography>
                <CountryDropDown value={country} name="country" handleChange={onChangeEvent} />
                {controllerOwnerData.country.error && <Typography sx={{ color: '#922', marginLeft: '3%', width: '90%', alignSelf: 'center' }} >{controllerOwnerData.country.error}</Typography>}
            </div>}


            <div className={styles.disabledInputSpan}>
                <Typography className={styles.pageTypoThresholdDisabled}>
                    City
                </Typography>
                <input
                    className={styles.inputs}
                    placeholder="Enter City"
                    name="city"
                    value={controllerOwnerData.city.value}
                    type="text"
                    onChange={onChangeEvent}
                />
                {controllerOwnerData.city.error && <Typography sx={{ color: '#922', marginLeft: '3%', width: '90%', alignSelf: 'center' }} >{controllerOwnerData.city.error}</Typography>}
            </div>

            <div
                style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "row",
                    flexWrap: "wrap",
                    alignItems: "flex-start",
                }}
            >
                <div style={{ width: "49%", marginRight: "1%" }}>
                    <Typography className={styles.pageTypoThresholdDisabled}>
                        {Number(controllerOwnerData.personType.value) === PersonType.NON_US ? 'State/Region/Province' : 'State'}
                    </Typography>
                    <div className={styles.disabledInputSpan}>
                        <input
                            className={styles.inputs}
                            placeholder={Number(controllerOwnerData.personType.value) === PersonType.NON_US ? 'S.R.P.' : 'State'}
                            name="state"
                            value={controllerOwnerData.state.value}
                            type="text"
                            onChange={onChangeEvent}
                        />
                        {controllerOwnerData.state.error && <Typography sx={{ color: '#922', marginLeft: '3%', width: '90%', alignSelf: 'center' }} >{controllerOwnerData.state.error}</Typography>}

                    </div>

                </div>
                <div style={{ width: "49%", marginRight: "1%" }}>
                    <Typography className={styles.pageTypoThresholdDisabled}>
                        Zip Code
                    </Typography>
                    <div className={styles.disabledInputSpan}>
                        <input
                            className={styles.inputs}
                            placeholder="ZIP Code"
                            name="zip"
                            value={controllerOwnerData.zip.value}
                            type="text"
                            onChange={onChangeEvent}
                            onKeyDown={handleKeyEvent}
                        />
                        {controllerOwnerData.zip.error && <Typography sx={{ color: '#922', marginLeft: '3%', width: '90%', alignSelf: 'center' }} >{controllerOwnerData.zip.error}</Typography>}

                    </div>
                </div>
            </div>
        </div>
    );
}
