import React, { useEffect } from "react";
import { Outlet } from "react-router";
import BottomTabs from "./BottomTabs/";
import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import { reducer, sliceKey, actions } from "./redux/slice";
import { useLandlordSaga } from "./redux/saga";
import { useSelector } from "react-redux";
import { selectShowDownNavBar } from "./redux/selector";
import { useDispatch } from "react-redux";
import { LoggerClass as Logger } from "utils/logger";
const LandlordPage = () => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: useLandlordSaga });
  const downNavBarProps = useSelector(selectShowDownNavBar);
  const showDownBar = downNavBarProps.status;
  const index = downNavBarProps.index;
  const dispatch = useDispatch();
  const setIndex = () => {
    dispatch(actions.setShowBottomNavBar({ status: true, index: index }));
  };
  useEffect(() => {
    Logger.log(downNavBarProps);
  }, [downNavBarProps]);
  return (
    <>
      <Outlet />
      {showDownBar && <BottomTabs index={index} setIndex={setIndex} />}
    </>
  );
};
export default LandlordPage;
