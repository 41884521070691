import React from "react";
import useStyles from "./styles";
import { Box } from "@mui/material";
import { useNavigate } from "react-router-dom";
import SRE_Logo from "../../assets/logo.png";
const PageContainer = (props) => {
  const styles = useStyles();
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        background: "#FFF7DD",
      }}
    >
      <Box className={styles.upperContainer}>
        <img
          onClick={() => {
            navigate(-1);
          }}
          src={require("../../assets/backArrow.png")}
          alt=""
          style={{
            height: 16,
            width: 16,
            position: "absolute",
            top: 20,
            left: 16,
          }}
        />
        <Box className={styles.logoContainer}>
          <img src={SRE_Logo} alt="logo" className={styles.logo} />
        </Box>
      </Box>
      <Box className={styles.lowerContainer}>{props?.children}</Box>
    </Box>
  );
};

export default PageContainer;
