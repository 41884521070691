import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
    sheet: {
        display: "flex",
          flex: 1,
          backgroundColor: "#ffffff",
          position: "absolute",
          borderTopLeftRadius: 30,
          borderTopRightRadius: 30,
          flexDirection: "column",
          paddingBottom:20
    },
    title:{
        fontSize: 18,
        fontWeight: "600",
        color: "#192342",
        alignSelf: "center",
        marginTop:20,
        fontFamily:"Open Sans",
        marginBottom:6    
      },
      title1: {
        fontSize: 14,
        fontWeight: "600",
        color: "#192342",
        alignSelf: "center",
        marginTop:20,
        fontFamily:"Open Sans",
        marginBottom:20,
        textAlign : 'center',
        justifyContnet:'center',
        alignItems:'center'
      },
      subTitle:{
        fontSize: 14,
        color: "#192342",
        fontWeight: "400",
        marginTop: 16,
        alignSelf: "center",
        textAlign:'center',
        marginBottom: 10,
        marginLeft:36,
        marginRight:36,
        fontFamily: 'Open Sans',
      },
      textStyle: {
        width:'90%',
        alignSelf:'center',
        // marginTop:24,
        // marginBottom:24
          

    },
    leftSide:{
        backgroundColor:"#FFFBEE",
        color:'#7D7D7D',
        paddingLeft:10,
        paddingRight:10,
        fontSize:16,
        borderRadius:6,
        paddingTop:2,
        paddingBottom:2
        },checkBoxView:{
            flexDirection:'row',
            display:'flex',
            flex:1,
            marginBottom:10,
            // backgroundColor:"red",
            paddingLeft:15
            // marginleft:29,
            
        },
        checkBoxTitle:{
            alignSelf:'center',
            fontSize:14
        }, btn:{
        fontSize: "14px",
            fontWeight: 700,
            alignSelf: "center",
            padding: "12px 0",
            borderRadius: "10px",
            backgroundColor: "#192342",
            margin: "30px 0px",
            color: "#FFFFFF",
      },footerTxt:{
        fontSize:12,
        fontWeight:400,
        fontFamily:"Open Sans",
        textAlign:'center',
        color:'#192342'
      },
      footTxt:{
        fontSize:12,
        fontWeight:600,
        fontFamily:"Open Sans",
        textAlign:'center',
        color:'#006CFD',

      },spanTitle:{
        fontWeight:'600'
      },
      signInButton: {
        "&:disabled": {
          background: "#C4C4C4",
          color: "#6B6B6B",
        },
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px 16px",
        gap: "10px",
        height: "50px",
        background: "#192342",
        color: "#ffffff",
        borderRadius: "8px",
        marginTop: 19,
        marginBottom: 24,
        "&:hover": {
          background: "#192342",
          color: "#ffffff",
        },
      },
      text: {
          fontSize: '13px !important',
          background: '#FFFAEB',
          marginBottom: '10px',
          border: '1px solid #f0bb00',
          borderRadius: '10px',
          padding: '5px',
          color: '#192342',
          font: 'inherit'
      },
      ConnectInButton: {
        "&:disabled": {
          background: "#C4C4C4",
          color: "#19234275",
        },
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        padding: "10px 16px",
        gap: "10px",
        height: "50px",
        background: "#F0BB00",
        color: "#192342",
        borderRadius: "8px",
        marginTop: 19,
        marginBottom: '5%',
        "&:hover": {
          background: "#F0BB00",
          color: "#192342",
        },
      },  
      dailogBox : {
        "& .MuiPaper-root ": {
          borderRadius : '50px',
        }
      },
      disabledInputSpan: {
        width: "100%",
        marginBottom: "20px",
        alignItems: "center",
        border: 'none !important',
      },
      pageTypoThresholdDisabled: {
        textAlign: "left",
        height: "fit-content",
        fontFamily: "Open Sans",
        fontStyle: "normal",
        fontWeight: "600",
        fontSize: "14px",
        lineHeight: "19px",
        letterSpacing: "0.2px",
        margin: "0 0 0 3%",
        color: "#192342",
      },  
      error: {
        width:'80%',
        alignSelf:'center',
        marginTop:24
    },   
})