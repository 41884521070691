import { RootState } from "../../../../types";
import { createSelector } from "@reduxjs/toolkit";
import { LandlordState } from "./types";
import { statusFilterOptions } from "utils/constants";

const selectDomain = (state: RootState) => state.landlordState || LandlordState;

export const selectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const getServiceRequests = createSelector(
  [selectDomain],
  (state) => state.serviceRequests
);

export const selectServiceRequests = createSelector(
  [selectDomain],
  (state) => state.selectedServiceRequests  
);

export const selectList = createSelector([selectDomain], (state) => state.list);
export const selectQuery = createSelector(
  [selectDomain],
  (state) => state.query
);
export const selectEditModal = createSelector(
  [selectDomain],
  (state) => state.editModal
);
export const selectShowDownNavBar = createSelector(
  [selectDomain],
  (state) => state.showDownNavBar
);
export const getSelectedProperty = createSelector(
  [selectDomain],
  (state) => state.selectedProperty
);
export const getSelectedPortfolio = createSelector(
  [selectDomain],
  (state) => state.selectedPortfolio
);
export const selectEditProperty = createSelector(
  [selectDomain],
  (state) => state.form.property
);
export const selectPropertyError = createSelector(
  [selectDomain],
  (state) => state.error
);

export const selectPropertyListDD = createSelector(
  [selectDomain],
  (state) => state.propertyListDropdown
);

export const selectdashboardData = createSelector(
  [selectDomain],
  (state) => state.dashboardData
);


export const SelectServiceDashboardData = createSelector(
  [selectDomain],
  (state) => state.serviceRequestDashboardData
);

export const addPropertyFormData = createSelector(
  [selectDomain],
  (state) => state.addProperty
);

export const addLeaseData = createSelector(
  [selectDomain],
  (state) => state.addLease
);

export const selectServiceRequestData = createSelector(
  [selectDomain],
  (state) => state.serviceRequestData
);

export const selectImageFile = createSelector(
  [selectDomain],
  (state) => state.leaseDocumentFile
);

export const selectImageUploadUrl = createSelector(
  [selectDomain],
  (state) => state.imageUploadUrl
);

export const selectDocumentUploadUrl = createSelector(
  [selectDomain],
  (state) => state.documentUploadUrl
);

export const selectPropertyList = createSelector(
  [selectDomain],
  (state) => state.list
);

export const screenNav = createSelector(
  [selectDomain],
  (state) => state.screenName
);

export const addedProperty = createSelector(
  [selectDomain],
  (state) => state.addedProperty
);

export const addedLease = createSelector(
  [selectDomain],
  (state) => state.addedLease
);

export const addedLeaseCondition = createSelector(
  [selectDomain],
  (state) => state.checkAddedLeaseState
);

export const getPropertyRequest = createSelector(
  [selectDomain],
  (state) => state.PortfolioRequestBody
);

export const selectMaintananceRequest = createSelector(
  [selectDomain],
  (state) => state.MaintananceRequestBody
);

export const PortfolioUpdateRequestBody = createSelector(
  [selectDomain],
  (state) => state.PortfolioUpdateRequestBody
);

export const getPropertyListResponse = createSelector(
  [selectDomain],
  (state) => state.propertylist
);


export const selectedPropertyIdViaDropdown = createSelector(
  [selectDomain],
  (state) => state.selectedPropertyIdViaDropdown
);

export const checkStateStatusData = createSelector(
  [selectDomain],
  (state) => state.checkStateStatus
);

export const checkLeaseStateData = createSelector(
  [selectDomain],
  (state) => state.checkLeaseState
);

export const setPropertyStatusCheck = createSelector(
  [selectDomain],
  (state) => state.setPropertyStatusCheck
);

export const selectDailogStatus = createSelector(
  [selectDomain],
  (state) => state.checkDailog
);

export const selectVideoFile = createSelector(
  [selectDomain],
  (state) => state.videoFile
)

export const selectUploadedVideoURL = createSelector(
  [selectDomain],
  (state) => state.videoUploadUrl
);

export const selectS3UploadedVideoURL = createSelector(
  [selectDomain],
  (state) => state.videoUploadedUrl
);

export const selectPendingBalance = createSelector(
  [selectDomain],
  (state) => state.pendingBalance
);

export const selectLoggedInUserData = createSelector(
  [selectDomain],
  (state) => state.loggedInUserData
)






