import { DisabledByDefaultOutlined } from "@mui/icons-material";
import {
  Button,
  Typography,
  TextField,
  Box,
  Dialog,
  Radio,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import checkImage from "../../../../../../assets/check.png";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import { actions, reducer } from "../../../redux/slice";
import { Navigate, useNavigate } from "react-router";
import { useSelector } from "react-redux";
import {
  getSelectedPortfolio,
  PortfolioUpdateRequestBody,
  selectDailogStatus,
  selectLoading,
  setPropertyStatusCheck,
} from "app/containers/CommonComponent/Property/redux/selector";
import { selectUser } from "app/containers/Auth/selectors";
import Loader from "app/components/Loader";
import { LoggerClass as Logger } from "utils/logger";
const EditPropertyDialog = ({
  showPopup,
  closePopup,
  address,
  occupationStatus,
  propertyId,
}) => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const navigation = useNavigate();
  const [show, setShow] = useState(false);
  const [thisOccupationStatus, setThisOccupationStatus] =
    useState(occupationStatus);
  const [okScreen, setOkScreen] = useState(false);

  const propertyUpdateRequest = useSelector(PortfolioUpdateRequestBody);
  const selectedProperty = useSelector(getSelectedPortfolio);
  localStorage.setItem("latitude", selectedProperty?.Latitude + "");
  localStorage.setItem("longitude", selectedProperty?.Longitude + "");
  const loggedInUser = useSelector(selectUser);
  const localPropertyId = localStorage.getItem("property");
  const localUserId = JSON.parse(localStorage.getItem("loginResponse")!).userId;
  const setPropertyStatus = useSelector(setPropertyStatusCheck);
  const dailogStatus = useSelector(selectDailogStatus);
  const loading = useSelector(selectLoading);

  const close = () => {
    dispatch(actions.setDailogState(false));
    setShow(false);
    closePopup();
  };
  const showNext = () => {
    if (Number(propertyUpdateRequest.status) === 1) {
      dispatch(
        actions.setLeaseData({
          name: "propertyId",
          value: localPropertyId + "",
          form: "addLease",
        })
      );
      dispatch(
        actions.setLeaseData({
          name: "ownerId",
          value: loggedInUser.userId ? loggedInUser.userId + "" : localUserId,
          form: "addLease",
        })
      );
      dispatch(
        actions.getLeaseDataByPropertyId({
          data: propertyUpdateRequest?.propertyId
            ? propertyUpdateRequest?.propertyId + ""
            : localPropertyId,
          callback: onLoginSuccess,
        })
      );
    } else {
      dispatch(actions.updatePropertyStatus(propertyUpdateRequest));
    }
  };

  useEffect(() => {
    Logger.log(dailogStatus);
    if (dailogStatus) {
      setOkScreen(true);
    }
  }, [dailogStatus]);

  useEffect(() => {
    if (setPropertyStatus) {
      dispatch(
        actions.setSelectedPortfolioDetails({
          id: propertyUpdateRequest?.propertyId
            ? propertyUpdateRequest?.propertyId
            : localPropertyId,
        })
      );
      setOkScreen(true);
    }
  }, [setPropertyStatus]);

  const onLoginSuccess = () => {
    navigation("/landlord-account/add-new-lease");
  };

  useEffect(() => {
    setShow(showPopup);
    dispatch(
      actions.setpropertyUpdateRequest({
        name: "propertyId",
        value: propertyId ? propertyId : localPropertyId,
        form: "updateProperty",
      })
    );
    dispatch(
      actions.setpropertyUpdateRequest({
        name: "status",
        value: occupationStatus,
        form: "updateProperty",
      })
    );
  }, [showPopup, occupationStatus]);

  const onStatusChange = (e) => {
    const { name, value } = e.target;
    dispatch(
      actions.setpropertyUpdateRequest({ name, value, form: "updateProperty" })
    );
  };

  return (
    <>
      <Loader loading={loading} />
      <Dialog open={show} className={styles.popup}>
        <Box sx={{ display: "flex", flexDirection: "column", margin: "16px" }}>
          {!okScreen  && (
            <>
              <Typography className={styles.title}>Edit Property</Typography>
              <Typography
                sx={{ margin: "10px 0px" }}
                className={styles.address}
              >
                {address}
              </Typography>
              <Typography
                sx={{ margin: "6px 0px 10px 0px" }}
                className={styles.pageTypo2}
              >
                Choose actual Property Occupation Status
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "16px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Radio
                    id="vac"
                    name="status"
                    className={styles.radio}
                    checked={Number(propertyUpdateRequest.status) === 0}
                    value="0"
                    onChange={onStatusChange}
                  />
                  <label htmlFor="vac" className={styles.typoBlue40016}>
                    Vacant
                  </label>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Radio
                    id="tocc"
                    name="status"
                    className={styles.radio}
                    checked={Number(propertyUpdateRequest.status) === 1}
                    value="1"
                    onChange={onStatusChange}
                  />
                  <label htmlFor="tocc" className={styles.typoBlue40016}>
                    Tenant Occupied
                  </label>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Radio
                    id="oocc"
                    className={styles.radio}
                    name="status"
                    checked={Number(propertyUpdateRequest.status) === 2}
                    value="2"
                    onChange={onStatusChange}
                  />
                  <label htmlFor="oocc" className={styles.typoBlue40016}>
                    Owner Occupied
                  </label>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box sx={{ display: "flex", width: "48%" }} onClick={close}>
                  <Button className={styles.signInButtonColorInvert}>
                    Cancel
                  </Button>
                </Box>
                <Box sx={{ display: "flex", width: "48%" }}>
                  <Button
                    disabled={
                      Number(propertyUpdateRequest.status) ===
                      Number(occupationStatus)
                    }
                    className={styles.signInButton}
                    onClick={showNext}
                  >
                    Change
                  </Button>
                </Box>
              </Box>
            </>
          )}
          {okScreen && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                margin: "16px",
                alignItems: "center",
              }}
            >
              <IconButton className={styles.closeIcon} onClick={close}>
                <CloseIcon />
              </IconButton>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "16px",
                  alignItems: "center",
                  marginBottom: "30px",
                }}
              >
                <IconButton className={styles.checkIcon}>
                  <img src={checkImage} alt={"Done"} />
                </IconButton>
              </Box>
              <Typography
                className={styles.title}
                sx={{ marginBottom: "10px" }}
              >
                The Occupation Status is successfully changed!
              </Typography>
              <Typography className={styles.typo40016}>
                {address ? "Property: " + address : ""}
              </Typography>
            </Box>
          )}
        </Box>
      </Dialog>
    </>
  );
};

export default EditPropertyDialog;
