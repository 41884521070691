import { Button, stepLabelClasses, Typography} from "@mui/material";
import React, { useEffect, useState } from "react";
import  useStyles from "../../styles";
import loadingLogo from "../../../../../assets/loadingS.png";
import checkPng from "../../../../../assets/check.png";
import redCross from "../../../../../assets/redcross.png";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../FundingsSource/redux/slice";
import { selectUser } from "app/containers/Auth/selectors";
import { selectIdVerificationRequest, selectIdVerificationUser, selectLoading } from "app/containers/Auth/FundingsSource/redux/selectors";
import { VerificationStatus } from "app/containers/Auth/FundingsSource/redux/enum";
import Loader from "app/components/Loader";
import { useNavigate } from "react-router-dom";
import { BusinessType, BusinessTypeValue } from "app/containers/Auth/CreateBusinessAccount/redux/enum";


const UserVerification = ({connectionResponse, businessType, ownerType, benificary}) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const idVerificationRequest = useSelector(selectIdVerificationRequest);
  const idVerificationUser = useSelector(selectIdVerificationUser);
  const loading = useSelector(selectLoading);
  const [buttonEnable, setButtonEnable] = useState(false);
  const [slug, setSlug] = useState('individual');

  const handleClick = () => {
    navigation("/upload-document?type="+slug, { state: slug });
  }
  useEffect(() => {
    if(ownerType === "business" && (Number(businessType) === BusinessType.SOLE_PARTNERSHIP || Number(businessType) === BusinessType.TRUST || Number(businessType) === BusinessType.UNICORPORATED_ASSOCIATION)) {
      setSlug('businessSolo');
    } 
    if(ownerType === "business" && (Number(businessType) === BusinessType.CORPORATION || Number(businessType) === BusinessType.LLCS || Number(businessType) === BusinessType.NON_PROFITS || Number(businessType) === BusinessType.PARTNERSHIP || Number(businessType) === BusinessType.PUBLICLY_TRADED_CORPORATIONS)) {
      setSlug('business');
    } 
  }, [dispatch]);

  const VerificationStatusText = (type) => { 
    switch (type) {
      case VerificationStatus.VERIFIED:
        return 'Done';
      case VerificationStatus.RETRY:
        return 'Pending';
      case VerificationStatus.DOCUMENT:
        return 'Pending';
      case VerificationStatus.SUSPENDED:
        return 'Failed';      
      default:
        return '';
    }
  }

  const BusinessTypeTitle = (type) => { 
    switch (type) {
        case BusinessType.LLCS:
        return BusinessTypeValue.LLCS;
        case BusinessType.CORPORATION:
        return BusinessTypeValue.CORPORATION;
        case BusinessType.NON_PROFITS:
        return BusinessTypeValue.NON_PROFITS;
        case BusinessType.PARTNERSHIP:
        return BusinessTypeValue.PARTNERSHIP;
        case BusinessType.PUBLICLY_TRADED_CORPORATIONS:
        return BusinessTypeValue.PUBLICLY_TRADED_CORPORATIONS;
        case BusinessType.SOLE_PARTNERSHIP:
        return BusinessTypeValue.SOLE_PARTNERSHIP;
        case BusinessType.TRUST:
        return BusinessTypeValue.TRUST;
        case BusinessType.UNICORPORATED_ASSOCIATION:
        return BusinessTypeValue.UNICORPORATED_ASSOCIATION;
      default:
        return '';
    }
  }

  useEffect(() => {  
    if(idVerificationRequest.UserId.value) {
        dispatch(actions.checkUserIDverification(idVerificationRequest));
    }  
  }, [idVerificationRequest]);

  useEffect(() => {  
    if(idVerificationUser.status !== VerificationStatus.VERIFIED) {
        setButtonEnable(true);
        connectionResponse(false);
    }  else {
        setButtonEnable(false);
        connectionResponse(true);
    }
  }, [idVerificationUser]);

  return ( 
    <>
        {/* <Loader loading={loading} />     */}
    <div className={VerificationStatusText(idVerificationUser.status) === "Done" ? styles.statusDoneSection : styles.statusSection}>         
        <img
          className={VerificationStatusText(idVerificationUser.status) === "Pending" ? styles.DoneImage : styles.PendingImage}
          src={VerificationStatusText(idVerificationUser.status) === "Done" ? checkPng : VerificationStatusText(idVerificationUser.status) === "Pending" ? loadingLogo : redCross }
          alt={"..."}
        />
        <Typography className={styles.textLine}>
          {businessType !== "" ? BusinessTypeTitle(Number(businessType)) : 'User Account'} {' '} Verification: <span className={VerificationStatusText(idVerificationUser.status) === "Done" ? styles.Done : VerificationStatusText(idVerificationUser.status) === "Pending" ? styles.Pending : styles.Failed }>{VerificationStatusText(idVerificationUser.status) }</span>
        </Typography> 

         {buttonEnable && <Button
         className={styles.ConnectInButton}
        variant="contained"
        onClick={handleClick}
        >
        Upload Document
        </Button> }          
    </div>
    </>  
  );
};

export default UserVerification;
