import { Button, Typography} from "@mui/material";
import React, { useEffect, useState } from "react";
import  useStyles from "../../styles";
import loadingLogo from "../../../../../assets/loadingS.png";
import checkPng from "../../../../../assets/check.png";
import redCross from "../../../../../assets/redcross.png";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../FundingsSource/redux/slice";
import { selectUser } from "app/containers/Auth/selectors";
import { selectIdVerificationRequest, selectIdVerificationOwner, selectLoading } from "app/containers/Auth/FundingsSource/redux/selectors";
import { VerificationStatus } from "app/containers/Auth/FundingsSource/redux/enum";
import Loader from "app/components/Loader";
import { useNavigate } from "react-router-dom";

const BanificialOwnerVerification = ({connectionResponse}) => {
    const styles = useStyles();
    const dispatch = useDispatch();
    const navigation = useNavigate(); 
    const idVerificationRequest = useSelector(selectIdVerificationRequest);
    const idVerificationOwner = useSelector(selectIdVerificationOwner);
    const loading = useSelector(selectLoading);

    const [buttonEnable, setButtonEnable] = useState(false);

    const handleClick = () => {
      navigation('/owner');
    }

    const VerificationStatusText = (type) => { 
        switch (type) {
          case VerificationStatus.VERIFIED:
            return 'Done';
          case VerificationStatus.RETRY:
            return 'Pending';
          case VerificationStatus.DOCUMENT:
            return 'Pending';
          case VerificationStatus.SUSPENDED:
            return 'Failed';      
          default:
            return '';
        }
      }
  
    useEffect(() => {  
      if(idVerificationRequest.UserId.value) {
          dispatch(actions.checkOwnerIDverification(idVerificationRequest));
      }  
    }, [idVerificationRequest]);
  
    useEffect(() => {  
      if(idVerificationOwner.status !== VerificationStatus.VERIFIED) {
           setButtonEnable(true);
           connectionResponse(false);
        }  else {
            setButtonEnable(false);
            connectionResponse(true);
        } 
    }, [idVerificationOwner]);
  return ( 
    <>
            {/* <Loader loading={loading} />     */}

    <div className={VerificationStatusText(idVerificationOwner.status) === "Done" ? styles.statusDoneSection : styles.statusSection}>         
        <img
          className={VerificationStatusText(idVerificationOwner.status) === "Pending" ? styles.DoneImage : styles.PendingImage}
          src={VerificationStatusText(idVerificationOwner.status) === "Done" ? checkPng : VerificationStatusText(idVerificationOwner.status) === "Pending" ? loadingLogo : redCross }
          alt={"..."}
        />
        <Typography className={styles.textLine}>
          Benificial Owner Verification: <span className={VerificationStatusText(idVerificationOwner.status) === "Done" ? styles.Done : VerificationStatusText(idVerificationOwner.status) === "Pending" ? styles.Pending : styles.Failed}>{ VerificationStatusText(idVerificationOwner.status) }</span>

        </Typography> 

        {buttonEnable && <Button
        className={styles.ConnectInButton}
        variant="contained"
        onClick={handleClick}
        >
        Add Benificial Owner
        </Button>  }         
    </div>  
    </>
  );
};

export default BanificialOwnerVerification;
