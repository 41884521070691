import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  checkbox: {
    marginRight: "6px",
    "&.MuiRadio-colorPrimary": {
      color: "#192342",
    },
    "&:checked": {
      accentColor: "#192342",
    },
  },
  checkboxText: {
    fontSize: '16px',
    fontWeight: '400',
  },
  button: {
    fontSize: "24px",
    lineHeight: "28px",
    fontWeight: 800,
    padding: "12px 0",
    width: "85%",
    borderRadius: "10px",
    marginBottom: '100px  '
  },
  actionContainer: {
    textAlign: "center",
  },
  checkboxLabel: { fontSize: "12px", color: "#FFF" },
  checkboxLabelHighlight: { color: "#FAA61A", textDecoration: "underline" },
  sheet: {
    display: "flex",
      flex: 1,
      backgroundColor: "#ffffff",
      position: "absolute",
      borderTopLeftRadius: 30,
      borderTopRightRadius: 30,
      flexDirection: "column",
    //   paddingLeft:16,
    // paddinRight:16  
},
logo:{
    height: 77,
    width: 144,
    marginTop: 24,
    alignSelf: "center",
    marginBottom: 16,
  },
  title:{
    fontSize: 18,
    fontWeight: "600",
    color: "#192342",
    alignSelf: "center",
    marginTop:20,
    fontFamily:"Open Sans",
    marginBottom:6

  },
  subtitle:{
    fontSize: 18,
    color: "#192342",
    fontWeight: "600",
    marginTop: 16,
    alignSelf: "center",
    marginBottom: 10,
  },
  btn:{
    fontSize: "14px",
        fontWeight: 700,
        alignSelf: "center",
        padding: "12px 0",
        borderRadius: "10px",
        backgroundColor: "#192342",
        margin: "30px 0px",
        color: "#FFFFFF",
  },contentTxt:{
    marginTop: 10,
    fontSize: 14,
    fontWeight: "400",
    alignSelf: "center",
    color: "#192342",
  },imgView:{
    flexDirection: "row",
    flex: 1,
    justifyContent: "center",
    display: "flex",
    marginTop: 16,
  },icon:
    { height: 36, width: 36 },
    btmTxt:{
        fontSize: 12,
        fontWeight: "400",
        alignSelf: "center",
        marginTop: 24,
        // marginBottom: 40,
        textAlign:"center",
        marginLeft:40,
        marginRight:40
      },
      span:{ color: "#006CFD",cursor:'pointer' },container:{ display: "flex", flexDirection: "column" },
      textStyle: {
        // backgroundColor: '#ffffff',
        // borderWidth: '3px',
        // color: 'black',
        // BorderColor: 'balck',
        width:'90%',
        alignSelf:'center',
        marginTop:24
          

    },
    error: {
        width:'80%',
        alignSelf:'center',
        marginTop:24
    },
    subTitle:{
        fontSize:12,
        fontWeight:'400',
        color:'#192342',
        fontFamily:"Open Sans",
        marginLeft:30,
        marginRight:30,
        textAlign:"center"
    },box:{marginTop:16,border:'0.5px solid #BCBCBC',borderRadius:20,display:'flex',justifyContent:'center',alignItems:"center",flexDirection:"column",alignSelf:'center',width:187,paddingLeft:11.5,paddingRight:11.5,paddingTop:20.5,paddingBottom:20.5},
    boxTitle:{textAlign:"center",
    marginBottom:10,
    fontSize:16,
    fontWeight:'700', fontFamily:"Open Sans",color:'#F0BB00'},
    boxSubTitle:{textAlign:'center', fontSize:14,
     fontFamily:"Open Sans",color:'#192342'},
     colorBox:{marginTop:16,border:'0.5px solid #DBAB00',borderRadius:20,display:'flex',justifyContent:'center',alignItems:"center",flexDirection:"column",alignSelf:'center',width:187,paddingLeft:11.5,paddingRight:11.5,paddingTop:20.5,paddingBottom:20.5,backgroundColor:'#F0BB00'},
     colorBoxTitle:{textAlign:"center",
     marginBottom:10,
     fontSize:16,
     fontWeight:'700', fontFamily:"Open Sans",color:'#FFF7DD'},
});
