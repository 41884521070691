import { Box, Typography } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import { APP_VERSION } from "utils/constants";
import useWindowDimensions from "utils/hooks/useWindowDimensions";

import LogoImage from "../../assets/logo.png";
import { useStyles } from "./styles";
type Props = {
  heading?: JSX.Element;
  subHeading?: JSX.Element;
};

const PageImageHeader = (props: Props) => {
  const styles = useStyles();
  const { width, height } = useWindowDimensions();
  const navigation = useNavigate();
  return (
    <Box className={styles.headerContainer} style={{ height: height / 2.5 }}>
      <img
        src={require("../../assets/backArrow.png")}
        alt=""
        className={styles.backArrrow}
        onClick={() => {
          navigation(-1);
        }}
      />
      <img
        src={require("../../assets/logo.png")}
        alt=""
        className={styles.icon}
      />
    </Box>
  );
};

export default PageImageHeader;
