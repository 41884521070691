import { FormElement } from "../../../types";


export type UploadDocumentRequst = {
  userId : FormElement;
  documentType: FormElement;
  landlordType: FormElement;
  benificary : FormElement;
  documentUrl : FormElement;
  document?: File | null, 
  fileName: FormElement;
};

export type UploadDocumentType = {
  uploadDocument: UploadDocumentRequst; 
  error: string | null;
  loading: boolean;  
};
export const UploadDocumentState: UploadDocumentType = {
  error: "",
  loading: false,
  uploadDocument: {
    userId: {value: ""},
    documentType: { value: "" },
    landlordType: { value: "" },
    benificary : {value : ""},
    documentUrl : {value : ""},
    document : null,
    fileName : {value : ""}
  },
};

/*
  If you want to use 'ContainerState' keyword everywhere in your feature folder,
  instead of the 'HomePageState' keyword.
*/
export type ContainerState = UploadDocumentType;
