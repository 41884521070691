import { Button, InputAdornment, TextField, Typography } from "@mui/material";
import Page from "app/components/Page";
import PageImageHeader from "app/components/PageImageHeader";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "utils/hooks/useWindowDimensions";
import { selectCompany, selectError } from "../selectors";
import { actions } from "../slice";
import { useStyles } from "./styles";

const AddCompany = () => {
  const { width, height } = useWindowDimensions();
  const styles = useStyles();
  const dispatch = useDispatch();
  const company = useSelector(selectCompany);
  const [companyName, setCompanyName] = useState("");
  const [error, setError] = useState(false);
  const companyError = useSelector(selectError);
  const navigate = useNavigate();
  useEffect(() => {
    if (companyError === "Unauthorized") {
      dispatch(actions.resetModule());
      navigate("/auth");
    }
  }, [companyError]);

  const handleContinue = () => {
    if (!company) {
      handleRedirect();
      return false;
    }
    dispatch(actions.createCompanyListRequest({ callback: handleRedirect }));
  };

  const handleCompanySave = () => {
    dispatch(actions.setCompany({ company: companyName.trim() }));
  };

  const handleRemoveCompany = () => {
    dispatch(actions.setCompany({ company: "" }));
  };

  const handleRedirect = () => {
    navigate("/id-verification");
  };

  return (
    <Page>
      <PageImageHeader
        subHeading={
          <div style={{ display: "flex" }}>
            <Typography sx={{ fontSize: 14, color: "#fff" }}>
              {"have account"}
            </Typography>
            <Typography
              onClick={() => {
                // navigate("/auth");
              }}
              sx={{
                fontSize: 14,
                color: "#FAA61A",
                fontWeight: "bold",
                textDecoration: "underline",
                marginLeft: "4px",
              }}
            >
              {"login"}
            </Typography>
          </div>
        }
      />
      <div
        style={{
          width: width,
          top: height / 2.82,
        }}
        className={styles.sheet}
      >
        <div className={styles.title}>My property</div>
        <div className={styles.subTitle}>
          If you have properties are registered under Corporation that you own,
          please write them here and press “Add”.
        </div>
        <div className={styles.subTitle}>
          If you don’t own any, just press continue.
        </div>
        <div className={styles.inputView}>
          <div className={styles?.label}>Corporation name</div>
          <TextField
            variant="outlined"
            required
            id="nickName"
            placeholder={"Enter Corporation Name"}
            name="clientId"
            value={companyName}
            onChange={(event) => {
              setCompanyName(event.target.value);
            }}
            className={styles.textStyle}
            error={error}
            color={"primary"}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      width: 76,
                      zIndex: 3,
                      left: 15,
                      height: 44,
                    }}
                    className={styles.smallbtn}
                    disabled={
                      !company?.trim()?.length && companyName?.trim()?.length
                        ? false
                        : true
                    }
                    onClick={handleCompanySave}
                    style={{ borderRadius: 20 }}
                  >
                    Add
                  </Button>
                </InputAdornment>
              ),
            }}
          />
        </div>
        {company?.length ? (
          <div className={styles.title}>Added Corporations</div>
        ) : null}
        {company && (
          <div
            style={{
              flexDirection: "row",
              display: "flex",
              flex: 1,
              justifyContent: "space-between",
              border: "1px solid #BCBCBC",
              borderRadius: 20,
              paddingTop: 10,
              paddingBottom: 10,
              paddingLeft: 20,
              paddingRight: 20,
              marginRight: 16,
              marginLeft: 16,
              marginBottom: 10,
            }}
          >
            <div>{company}</div>
            <img
              src={require("../../../assets/close_icon.png")}
              alt="close"
              style={{
                height: 18,
                width: 18,
                alignSelf: "center",
                cursor: "pointer",
              }}
              onClick={handleRemoveCompany}
            />
          </div>
        )}
        {companyError && (
          <div className={styles.footerTxt} style={{ color: "#EB5757" }}>
            {companyError}
          </div>
        )}
        <Button
          type="submit"
          variant="contained"
          sx={{
            width: width / 1.15,
          }}
          className={styles.btn}
          onClick={handleContinue}
        >
          Continue
        </Button>
        <div className={styles.footerTxt}>Do you need help?</div>
        <div className={styles.footerTxt}>
          Contact us at{" "}
          <span className={styles.footTxt}> Support@Sre-Management.com</span>
        </div>
      </div>
    </Page>
  );
};

export default AddCompany;
