import {
  Button,
  Typography,
  Box,
  Dialog,
  IconButton,
  Checkbox,
} from "@mui/material";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import checkImage from "../../../../../../assets/check.png";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import { actions } from "../../../redux/slice";
import profilePng from "../../../../../../assets/DavidProfile.png";
import { useNavigate } from "react-router";
import { ElevatorSharp } from "@mui/icons-material";
const TenantDeletedPropertyDialog = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const styles = useStyles();
  const [show, setShow] = useState(false);
  const [selectedDeleteReason, setSelectedDeleteReason] = useState("");
  const [okScreen, setOkScreen] = useState(false);
  const [showDeleteOptions, setShowDeleteOptions] = useState(false);
  const close = () => {
    setShow(false);
    navigate("/landlord-account/portfolio");
  };
  const showNext = () => {
    setShowDeleteOptions(false);
    setOkScreen(true);
  };
  const onStatusChange = (e) => {
    if (e.target.checked) {
      setSelectedDeleteReason(e.target.value);
    } else {
      setSelectedDeleteReason("");
    }
  };

  const handleDeleteOptions = () => {
    setShowDeleteOptions(true);
  };

  const [onMore, setOnMore] = useState(false);

  return (
    <>
      <Dialog open={true} className={styles.popup}>
        <Box sx={{ display: "flex", flexDirection: "column", margin: "16px" }}>
          {!onMore && !showDeleteOptions && !okScreen && (
            <>
              <Typography className={styles.title}>
                Property unmanaged notification
              </Typography>
              <Typography
                className={styles.typo40014}
                sx={{ margin: "10px 0px" }}
              >
                We received a request from the current Landlord to remove the
                property from his managed portfolio.
              </Typography>
              <Typography
                className={styles.typo40014}
                sx={{ margin: "10px 0px" }}
              >
                A landlord might decide to remove the property from the
                portfolio if he is not working with us or not own the property
                anymore.
              </Typography>
              <Typography
                className={styles.typo40014}
                sx={{ margin: "10px 0px" }}
              >
                For this reason, most of the platform functions will be
                unavailable for you.
              </Typography>
              <Typography
                className={styles.typo40014}
                sx={{ marginTop: "10px" }}
              >
                We will let you know when a current Landlord will claim control
                of the property.
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  margin: "0px 0px 16px 0px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Checkbox
                    id="vac"
                    className={styles.checkbox}
                    checked={
                      selectedDeleteReason ===
                      "Don’t show me this message on Login again"
                    }
                    value="Don’t show me this message on Login again"
                    onChange={onStatusChange}
                  />
                  <Box sx={{ paddingTop: "24px" }}>
                    <label htmlFor="vac" className={styles.typoBlue40016}>
                      Don’t show me this message on Login again
                    </label>
                  </Box>
                </Box>
              </Box>
              <Box sx={{ display: "flex", width: "100%" }}>
                <Button
                  className={styles.signInButton}
                  onClick={handleDeleteOptions}
                >
                  Continue
                </Button>
              </Box>
            </>
          )}
          {okScreen && (
            <>
              <Typography className={styles.title}>
                Change of control Notification
              </Typography>
              <Typography
                sx={{ margin: "10px 0px" }}
                className={styles.typo40016}
              >
                Your Change of control request of the following properties has
                been rejected by the tenant assigned to the property:
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "12px 30px",
                  gap: "12px",
                  width: "100%",
                  height: "76px",
                  background: "#FFFFFF",
                  border: "1px solid #BCBCBC",
                  boxShadow:
                    "9px 19px 8px rgba(199, 199, 199, 0.01), 5px 11px 7px rgba(199, 199, 199, 0.05), 2px 5px 5px rgba(199, 199, 199, 0.09), 1px 1px 3px rgba(199, 199, 199, 0.1), 0px 0px 0px rgba(199, 199, 199, 0.1)",
                  borderRadius: "20px",
                  marginBottom: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Open Sans",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "16px",
                    letterSpacing: "0.1px",
                    whiteSpace: "wrap",
                    textAlign: "center",
                    color: "#192342",
                  }}
                >
                  3429 Adkins, Rd. NW Atlanta, GA 30331
                </Typography>
              </Box>
              <Typography
                sx={{ marginBottom: "10px" }}
                className={styles.typo40016}
              >
                Thus, their on-boarding process has been terminated. Please make
                sure the existing tenant signs a new leasing document with you
                before on-boarding the property.
              </Typography>
              <Typography
                className={styles.typo40016}
                sx={{ marginBottom: "24px" }}
              >
                If you believe it is a mistake, please contact us at <br />
                <Link className={styles.bottomLink} to="/welcomScreen">
                  Support@Sre-Management.com
                </Link>
              </Typography>
              <Box sx={{ display: "flex", width: "100%" }}>
                <Button
                  className={styles.signInButton}
                  onClick={() => {
                    setOkScreen(false);
                    setOnMore(true);
                  }}
                >
                  Continue
                </Button>
              </Box>
            </>
          )}
          {showDeleteOptions && (
            <>
              <Typography className={styles.title}>
                Change of control Notification
              </Typography>
              <Typography
                sx={{ margin: "10px 0px" }}
                className={styles.typo40016}
              >
                We received a Change-Of-Control request from
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "12px 0px",
                  gap: "12px",
                  width: "100%",
                  height: "76px",
                  background: "#FFFFFF",
                  border: "1px solid #BCBCBC",
                  boxShadow:
                    "9px 19px 8px rgba(199, 199, 199, 0.01), 5px 11px 7px rgba(199, 199, 199, 0.05), 2px 5px 5px rgba(199, 199, 199, 0.09), 1px 1px 3px rgba(199, 199, 199, 0.1), 0px 0px 0px rgba(199, 199, 199, 0.1)",
                  borderRadius: "20px",
                  marginBottom: "10px",
                }}
              >
                <Box
                  sx={{
                    width: "52px",
                    height: "52px",
                  }}
                >
                  <img
                    style={{
                      width: "100%",
                      objectFit: "cover",
                      borderRadius: "100%",
                    }}
                    src={profilePng}
                    alt="erro"
                  />
                </Box>
                <Box
                  sx={{
                    width: "66.66%",
                    height: "100%",
                  }}
                >
                  <Typography className={styles.name}>David Jhonson</Typography>
                  <Typography className={styles.company}>
                    DJ Holdings LLC
                  </Typography>
                </Box>
              </Box>
              <Typography
                sx={{ marginBottom: "10px" }}
                className={styles.typo40016}
              >
                Claiming Properties from your portfolio. Thus, The following
                properties have been removed from your porfolio:
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "12px 30px",
                  gap: "12px",
                  width: "100%",
                  height: "76px",
                  background: "#FFFFFF",
                  border: "1px solid #BCBCBC",
                  boxShadow:
                    "9px 19px 8px rgba(199, 199, 199, 0.01), 5px 11px 7px rgba(199, 199, 199, 0.05), 2px 5px 5px rgba(199, 199, 199, 0.09), 1px 1px 3px rgba(199, 199, 199, 0.1), 0px 0px 0px rgba(199, 199, 199, 0.1)",
                  borderRadius: "20px",
                  marginBottom: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "Open Sans",
                    fontStyle: "normal",
                    fontWeight: 600,
                    fontSize: "16px",
                    letterSpacing: "0.1px",
                    whiteSpace: "wrap",
                    textAlign: "center",
                    color: "#192342",
                  }}
                >
                  3429 Adkins, Rd. NW Atlanta, GA 30331
                </Typography>
              </Box>
              <Typography
                sx={{ marginBottom: "10px" }}
                className={styles.typo40016}
              >
                Change of control requests are automatically confirmed if the
                property is Owner Occupied\Vacant, or if the tenant approved the
                change of control.
              </Typography>
              <Typography
                className={styles.typo40016}
                sx={{ marginBottom: "24px" }}
              >
                If you believe it is a mistake, please contact us at <br />
                <Link className={styles.bottomLink} to="/welcomScreen">
                  Support@Sre-Management.com
                </Link>
              </Typography>
              <Box sx={{ display: "flex", width: "100%" }}>
                <Button className={styles.signInButton} onClick={showNext}>
                  Continue
                </Button>
              </Box>
            </>
          )}
          {onMore && (
            <>
              <Typography className={styles.title}>
                Change of control Notification
              </Typography>
              <Typography
                sx={{ margin: "10px 0px" }}
                className={styles.typo40014}
              >
                Your Change of control request of the following properties has
                been rejected by the tenant assigned to the property:
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "12px 30px",
                  gap: "12px",
                  width: "100%",
                  height: "48px",
                  background: "#FFFFFF",
                  border: "1px solid #BCBCBC",
                  boxShadow:
                    "9px 19px 8px rgba(199, 199, 199, 0.01), 5px 11px 7px rgba(199, 199, 199, 0.05), 2px 5px 5px rgba(199, 199, 199, 0.09), 1px 1px 3px rgba(199, 199, 199, 0.1), 0px 0px 0px rgba(199, 199, 199, 0.1)",
                  borderRadius: "20px",
                  marginBottom: "10px",
                }}
              >
                <Typography className={styles.typoNoWrap40014}>
                  3429 Adkins, Rd. NW Atlanta, GA 30331
                </Typography>
              </Box>
              <Typography
                sx={{ marginBottom: "10px" }}
                className={styles.typo40014}
              >
                Thus, their on-boarding process has been terminated. Please make
                sure the existing tenant signs a new leasing document with you
                before on-boarding the property.
              </Typography>
              <Typography
                className={styles.typo40014}
                sx={{ marginBottom: "24px" }}
              >
                If you believe it is a mistake, please contact us at <br />
                <Link className={styles.bottomLink} to="/welcomScreen">
                  Support@Sre-Management.com
                </Link>
              </Typography>
            </>
          )}
        </Box>
      </Dialog>
    </>
  );
};

export default TenantDeletedPropertyDialog;
