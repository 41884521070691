/**
 * Gets the repositories of the user from Github
 */
import { toast } from "react-toastify";
import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { API_URL } from "utils/constants";
import { getDefaultHeaders } from "utils/helpers";
import { request } from "utils/request";
import { actions } from "./slice";
import { LoggerClass as Logger, LoggerClass } from "utils/logger";
import { selectToken } from "../../selectors";
import queryString from "query-string";

/**
 * Github repos request/response handler
 */

// const userResponse = {"Result":true,"Data":{"status":"verified","UserId":"08db5117-d03b-4ebc-8faf-e0176b1dc634"},"Message":"MESSAGE.SUCCESS"};
// const bankResponse = {"Result":true,"Data":{"status":"retry","UserId":"08db5117-d03b-4ebc-8faf-e0176b1dc634"},"Message":"MESSAGE.SUCCESS"};
// const ownerResponse = {"Result":true,"Data":{"status":"suspended","UserId":"08db5117-d03b-4ebc-8faf-e0176b1dc634"},"Message":"MESSAGE.SUCCESS"};


export function* createLinkToken(action) {
  yield delay(500);
  try {
    const token = yield select(selectToken);

    const options = {
      method: "GET",
      headers: { ...getDefaultHeaders(), Authorization: `Bearer ${token}` }
    };
    const queries = queryString.stringify({
      clientName: action.payload?.userName.value ? action.payload?.userName.value : localStorage.getItem('ownerName'),
      countryCodes: 'US'
    });

    const response = yield call(
      request,
      `${API_URL}/userManagement/Plaid/CreateLinkToken?${queries}`,
      options
    )

    if (response.Status === 401 || response.Status === 400) {
      yield put(actions.createLinkTokenError(response.Message));
      return false;
    }

    if (!response.Result) {
      yield put(actions.createLinkTokenError(response.Message));
      return false;
    }

    yield put(actions.createLinkTokenSuccess(response.Data)); //
  } catch (err: any) {
    yield put(actions.createLinkTokenError(err));
    return false;
  }
}

export function* exchangePublicToken(action) {
  yield delay(500);
  const token = yield select(selectToken);
  LoggerClass.info(action.payload);
  const options = {
    method: "POST",
    headers: { ...getDefaultHeaders(), Authorization: `Bearer ${token}` },
  };
  const queries = queryString.stringify({
    publicToken: action.payload.data
  });
  try {
    const response = yield call(
      request,
      `${API_URL}/userManagement/Plaid/ExchangePublicToken?${queries}`,
      options
    );
    if (response.Status === 401 || response.Status === 400) {
      yield put(actions.exchangePublicTokenError(response.Message));
      return false;
    }
    if (!response.Result) {
      yield put(actions.exchangePublicTokenError(response.Message));
      return false;
    }
    yield put(actions.exchangePublicTokenSuccess(response.Data));
    yield call(action.payload?.callback);

  } catch (err: any) {
    yield put(actions.exchangePublicTokenError(err));
    return false;
  }
}


export function* createProcessToken(action) {
  yield delay(500);
  const token = yield select(selectToken);
  LoggerClass.info(action.payload);
  const options = {
    method: "POST",
    headers: { ...getDefaultHeaders(), Authorization: `Bearer ${token}` },
    body: JSON.stringify({
      accessToken: action.payload.data?.accessToken,
      accountId: action.payload.data?.accountId,
      userId: action.payload.data?.userId,
      userName: action.payload.data?.userName,
      userInfoId: action.payload.data?.userInfoId,
    }),
  };

  try {
    const response = yield call(
      request,
      `${API_URL}/userManagement/Plaid/CreateProcessToken`,
      options
    );
    if (response.Status === 401 || response.Status === 400) {
      yield put(actions.createProcessTokenError(response.Message));
      return false;
    }
    if (!response.Result) {
      yield put(actions.createProcessTokenError(response.Message));
      return false;
    }
    yield put(actions.createProcessTokenSuccess(response.Data));
    yield call(action.payload?.callback);
  } catch (err: any) {
    yield put(actions.createProcessTokenError(err));
    return false;
  }
}

export function* checkBankIDverification(action) {
  yield delay(500);
  try {
    const token = yield select(selectToken);

    const options = {
      method: "POST",
      headers: { ...getDefaultHeaders(), Authorization: `Bearer ${token}` },
      body: JSON.stringify({
        userId: action.payload?.UserId.value ? action.payload?.UserId.value : localStorage.getItem('ownerId'),
        type: 'Bank',
        landlordType: action.payload?.LandlordType.value,
        benificary: action.payload?.Benificary.value ? Number(action.payload?.Benificary.value) : null
      }),
    };
    const response = yield call(
      request,
      `${API_URL}/userManagement/Dwolla/GetVerificationStatus`,
      options
    )


    if (response.Status === 401 || response.Status === 400 || response.Status === 500) {
      yield put(actions.checkBankIDverificationError(response.Message));
      return false;
    }
    if (!response.Result) {
      yield put(actions.checkBankIDverificationError(response.Message));
      return false;
    }

    yield put(actions.checkBankIDverificationSuccess(response.Data));
  } catch (err: any) {
    //yield put(actions.checkBankIDverificationError(err));
    return false;
  }
}

export function* checkUserIDverification(action) {
  yield delay(500);
  try {
    const token = yield select(selectToken);

    const options = {
      method: "POST",
      headers: { ...getDefaultHeaders(), Authorization: `Bearer ${token}` },
      body: JSON.stringify({
        userId: action.payload?.UserId.value ? action.payload?.UserId.value : localStorage.getItem('ownerId'),
        type: 'Customer',
        landlordType: action.payload?.LandlordType.value,
        benificary: action.payload?.Benificary.value ? Number(action.payload?.Benificary.value) : null
      }),
    };
    const response = yield call(
      request,
      `${API_URL}/userManagement/Dwolla/GetVerificationStatus`,
      options
    )

    if (response.Status === 401 || response.Status === 400) {
      yield put(actions.checkUserIDverificationError(response.Message));
      return false;
    }

    if (!response.Result) {
      yield put(actions.checkUserIDverificationError(response.Message));
      return false;
    }

    yield put(actions.checkUserIDverificationSuccess(response.Data));
  } catch (err: any) {
    yield put(actions.checkUserIDverificationError(err));
    return false;
  }
}

export function* checkOwnerIDverification(action) {
  yield delay(500);
  try {
    const token = yield select(selectToken);
    const options = {
      method: "POST",
      headers: { ...getDefaultHeaders(), Authorization: `Bearer ${token}` },
      body: JSON.stringify({
        userId: action.payload?.UserId.value ? action.payload?.UserId.value : localStorage.getItem('ownerId'),
        type: 'Customer',
        landlordType: action.payload?.LandlordType.value,
        benificary: 1
      }),
    };
    const response = yield call(
      request,
      `${API_URL}/userManagement/Dwolla/GetVerificationStatus`,
      options
    )

    if (response.Status === 401 || response.Status === 400) {
      yield put(actions.checkOwnerIDverificationError(response.Message));
      return false;
    }

    if (!response.Result) {
      yield put(actions.checkOwnerIDverificationError(response.Message));
      return false;
    }

    yield put(actions.checkOwnerIDverificationSuccess(response.Data));
  } catch (err: any) {
    yield put(actions.checkOwnerIDverificationError(err));
    return false;
  }
}


/**
 * Root saga manages watcher lifecycle
 */
export function* fundingsSaga() {
  yield takeLatest(actions.createLinkToken.type, createLinkToken);
  yield takeLatest(actions.exchangePublicToken.type, exchangePublicToken);
  yield takeLatest(actions.createProcessToken.type, createProcessToken);
  yield takeLatest(actions.checkUserIDverification.type, checkUserIDverification);
  yield takeLatest(actions.checkBankIDverification.type, checkBankIDverification);
  yield takeLatest(actions.checkOwnerIDverification.type, checkOwnerIDverification);
}
