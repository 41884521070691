import { Box, InputAdornment, TextField, Typography } from "@mui/material";
import Header from "app/components/Header";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useInjectReducer, useInjectSaga } from "redux-injectors";
import BottomTenantTabs from "../BottomTabs";
import { useTenantSaga } from "../redux/saga";
import { selectMessages } from "../redux/selector";
import { actions, reducer, sliceKey } from "../redux/slice";
import { MessageType } from "../redux/types";
import useStyles from "./styles";
import { LoggerClass as Logger } from "utils/logger";
type Props = {};
const Contacts = (props: Props) => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: useTenantSaga });
  const styles = useStyles();
  const messages = useSelector(selectMessages);
  const [index, setIndex] = useState(2);
  const navigation = useNavigate();
  const dispatch = useDispatch();
  const [chatMessage, setChatMessage] = useState<any>();
  const [chatArray, setChatArray] = useState<MessageType[]>([]);
  const [typeMessage, setTypeMessage] = useState(false);

  useEffect(() => {
    dispatch(actions.getMessages());
  }, []);
  useEffect(() => {
    setChatArray(messages.items);
  }, [messages]);
  return (
    <>
      <div className={styles.container}>
        <Header
          rightIcon={true}
          handleNavigation={() => {
            navigation("/tenant-account/tenant-profile", {
              state: "/tenant-account/accounting",
            });
          }}
        />
        <Box
          sx={{
            width: "100%",
            paddingRight: "16px",
            position: "relative",
            marginBottom: "24px",
            marginTop: "24px",
            backgroundColor: "#F0BB00",
            height: 67,
          }}
        >
          <div className={styles.houseRow}>
          <div style={{ float: 'left', width: '14%' }}>
            <img
              src={require("../../../../../src/app/assets/house1.png")}
              style={{ width: 44, height: 44, borderRadius: 10 }}
            />
            </div>
            <div style={{ paddingLeft: 10, width: '100%' }}>
              <Typography className={styles.address}>
                3429 Adkins Rd. Nw Atlanta, GA 30331 3429 Adkins Rd. Nw Atlanta
              </Typography>
              <Typography className={styles.landlord}>
                Landloard:
                <span className={styles.landlordName}>David Snow</span>
              </Typography>
            </div>
          </div>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            marginBottom: "24px",
          }}
        >
          <Typography className={styles.dob}> Jul 13, 2022</Typography>
          <Typography className={styles.typo1234}>
            {" "}
            The tenant Alexander Yehezkel joined to the property
          </Typography>
        </Box>
        <Typography className={styles.dob}> Jul 28, 2022</Typography>
        <div style={{ marginBottom: 100 }}>
          {chatArray.map((item, index) => {
            return (
              <div
                key={(index * 123) % 678}
                className={
                  item.title === "Me" ? styles.card : styles.alternativeCard
                }
              >
                <div className={styles.title}>{item?.title}</div>
                <div className={styles.description}>{item?.description}</div>
                <Typography className={styles.time}>
                  {item?.date.toLocaleTimeString("en-GB").substring(0, 5)}
                </Typography>
              </div>
            );
          })}
        </div>
        <TextField
          sx={{
            mb: 0,
            "& fieldset": {
              borderRadius: "8px",
              display: "flex",
              alignItems: "center",
            },
            "& .MuiInputBase-root": {
              background: "#F5F5F5",
              height: "auto",
            },
          }}
          placeholder={"Type a message"}
          value={chatMessage}
          onChange={(event) => {
            Logger.log(event);
            setChatMessage(event.target.value);
            if(event.target.value.length > 0) {
              setTypeMessage(true);
            } else {
              setTypeMessage(false);
            } 
          }}
          multiline={true}
          maxRows={3}
          className={styles.textStyle}
          style={{ position: "fixed", bottom: 100 }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <img
                  src={require("../../../../../src/app/assets/emojis.png")}
                  alt=""
                  style={{ height: 24, width: 24 }}
                />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                {typeMessage ?  <img
                  src={require("../../../../../src/app/assets/sendYellow.png")}
                  alt=""
                  style={{ height: 24, width: 24, cursor: "pointer" }}
                  onClick={() => {
                    setChatArray((chatArray) => [
                      ...chatArray,
                      {
                        title: "Me",
                        description: chatMessage,
                        date: new Date(Date.now()),
                      },
                    ]);
                    setChatMessage("");
                    setTypeMessage(false);
                  }}
                /> : 
                <img
                  src={require("../../../../../src/app/assets/send.png")}
                  alt=""
                  style={{ height: 24, width: 24 }}                  
                /> }
              </InputAdornment>
            ),
          }}
        />

        <BottomTenantTabs index={index} setIndex={setIndex} />
      </div>
    </>
  );
};
export default Contacts;
