import { Box, Button, Checkbox, InputAdornment, TextField, Typography } from "@mui/material";
import Header from "app/components/Header";
import React, { useState } from "react";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { handleChange } from "utils/helpers";
import useWindowDimensions from "utils/hooks/useWindowDimensions";
import { useStyles } from "./styles";
import { LoggerClass as Logger } from "utils/logger";
import PaymentStatusDialog from "./components/PaymentStatusDialog";

const PayMyRent = () => {
  const { width, height } = useWindowDimensions();
  const { state }: any = useLocation();
  const navigation = useNavigate();
  const styles = useStyles();
  const [paymentStatusDialog, setPaymentStatusDialog] = useState(false);
  Logger.log("state", state);

  const goBack = () => {
    Logger.log("state", state?.source);

    if (state?.source) {
      navigation(state?.source);
    } else {
      navigation(-1);
    }
  };
  return (
    <div>
      <div
        style={{
          flexDirection: "row",
          display: "flex",
          marginTop: 25,
          marginBottom: 25,
          marginLeft: 16,
          marginRight: 15,
          paddingRight: 15,
          paddingLeft: 15,
          //   paddingTop: 10,
          //   paddingBottom: 10,
          borderRadius: 16,
          justifyContent: "center",
        }}
      >
        <img
          src={require("../../../../../assets/backArrow.png")}
          alt=""
          style={{
            height: 16,
            width: 16,
            position: "absolute",
            zIndex: 3,
            left: 16,
          }}
          onClick={goBack}
        />
        <div
          style={{ fontFamily: "Open Sans", fontSize: 18, fontWeight: "600", color:"#192342" }}
        >
          Pay my rent
        </div>
      </div>

     
      <div style={{ marginTop: 16 }}>
        <div className={styles.label}>Property</div>
        <TextField
          color="primary"
          variant="outlined"
          required
          placeholder={"3429 Adkins, Rd. NW Atlanta, GA 30331"}
          disabled={true}
          onChange={(event) => {
          }}
          className={styles.textStyle}
        />
      </div>
      <div>
        <div className={styles.label}>Description</div>
        <TextField
          color="primary"
          variant="outlined"
          required
          placeholder={"Augost Rental Payment"}
          name="description"
          disabled={true}
          onChange={(event) => {
          }}
          className={styles.textStyle}
        />
      </div>
      <div>
        <div className={styles.label}>Recipient</div>
        <TextField
          color="primary"
          variant="outlined"
          required
          id="recipient"
          placeholder={"Michael Jordan"}
          name="recipient"
          disabled={true}
          onChange={(event) => {
          }}
          className={styles.textStyle}
        />
      </div>
      <div>
        <div className={styles.label}>Payment Amount</div>
        <TextField
          className={styles.inputs}          
          required
          placeholder={"Enter threshold amount"}
          name="paymentAmount"
          disabled={true}
          value={100}
          // onChange={onChangeEvent}
          // onKeyDown={handleKeyEvent}
          InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <div className={styles.leftSide}>$</div>
            </InputAdornment>
          ),
          }}
          />
      </div>
     
      <div className={styles.footerTxt}>This invoice Balance after payment: <span className={styles.footTxt}>0$</span></div>
      <div className={styles.footerTxt}>Total Outstanding Balance after payment: <span className={styles.footTxt}> 0$</span></div>
    
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          padding: "0px 16px",
          marginBottom: "24px",
          marginTop:"24px"
        }}
      >
        

        <Button
          className={styles.signInButton}
          sx={{ marginBottom: "16px" }}
          onClick={() => {setPaymentStatusDialog(true)}}
        >
          <Typography
            className={styles.typo70014}
            sx={{ color: "#FFFFFF !important" }}
          >
            Make a Payment
          </Typography>
        </Button>

      </Box>
      <PaymentStatusDialog
        showPopup={paymentStatusDialog}
        closePopup={()=>{setPaymentStatusDialog(false)}}
        handleSelect={(selectedCheck: any) => {
          setPaymentStatusDialog(selectedCheck);
        }}
      />
    </div>
  );
};

export default PayMyRent;
