import { Button, InputAdornment, TextField, Typography } from "@mui/material";
import Page from "app/components/Page";
import PageImageHeader from "app/components/PageImageHeader";

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useWindowDimensions from "utils/hooks/useWindowDimensions";
import { useStyles } from "./styles";

const ZipCode = () => {
  const { width, height } = useWindowDimensions();
  const styles = useStyles();
  const navigation = useNavigate();
  const [disable, setDisable] = useState(true);
  const [zipCode, setZipCode] = useState("");
  const [error, setError] = useState(false);

  useEffect(() => {
    if (zipCode.length === 6) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [zipCode.length]);
  return (
    <Page>
      <PageImageHeader
        subHeading={
          <div style={{ display: "flex" }}>
            <Typography sx={{ fontSize: 14, color: "#fff" }}>
              {"have account"}
            </Typography>
            <Typography
              onClick={() => {
                // navigate("/auth");
              }}
              sx={{
                fontSize: 14,
                color: "#FAA61A",
                fontWeight: "bold",
                textDecoration: "underline",
                marginLeft: "4px",
              }}
            >
              {"login"}
            </Typography>
          </div>
        }
      />

      <div
        style={{
          width: width,

          top: height / 2.82,
        }}
        className={styles.sheet}
      >
        <div className={styles.title}>Enter your zipcode</div>
        <div className={styles.inputView}>
          {/* <div className={styles?.label}>Zip Code</div> */}
          <TextField
            // color="primary"
            variant="outlined"
            required
            //   fullWidth
            id="nickName"
            placeholder={"Enter Zip Code"}
            name="clientId"
            //   value={confirmPass}
            onChange={(event) => {
              // setConfirmPass(event.target.value);
              setZipCode(event.target.value);
            }}
            className={styles.textStyle}
            error={error}
            color={"primary"}
            // style={{ borderRadius: 16 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <img
                    src={require("../../../assets/location.png")}
                    alt=""
                    style={{ height: 24, width: 24 }}
                  />
                </InputAdornment>
              ),
            }}
          />
          {error ? (
            <div
              style={{
                color: "#EB5757",
                fontSize: 14,
                fontFamily: "Open Sans",
              }}
            >
              Invalid zip code
            </div>
          ) : null}
        </div>

        <Button
          type="submit"
          variant="contained"
          sx={{
            width: width / 1.15,
          }}
          disabled={disable}
          className={styles.btn}
          onClick={() => {
            if (zipCode === "123456") {
              navigation("/address");
            } else {
              setError(true);
            }
          }}
        >
          Continue
        </Button>
        {/* <div className={styles.title}>My Property</div>

      <div className={styles.subTitle}>
        <span className={styles.spanTitle}>Add your phone number</span> to be
        used with notifications and communications
      </div> */}
        {/* <div
          style={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            backgroundColor: "red",
          }}
        > */}
        <div className={styles.footerTxt}>Do you need help?</div>
        <div className={styles.footerTxt}>
          Contact us at{" "}
          <span className={styles.footTxt}> Support@Sre-Management.com</span>
        </div>
      </div>
      {/* </div> */}
    </Page>
  );
};

export default ZipCode;
