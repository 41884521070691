import { selectToken, selectUser } from "app/containers/Auth/selectors";
import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { API_URL, APP_API_URL } from "utils/constants";
import { getDefaultHeaders, getFileDefaultHeaders } from "utils/helpers";
import { fileRequest, request } from "utils/request";
import { actions } from "./slice";
import queryString from "query-string";
import { toast } from "react-toastify";
import { selectDocumentUploadUrl } from "../../S3UploadSaga/selector";
import { LoggerClass as Logger } from "utils/logger";

const response = {
  items: [
    {
      id: "774609",
      ownerId: "",
      address: "1670 South Palace, Near Park Arear Milford",
      zipcode: 35004,
      state: "Hartford",
      city: "Tallahassee",
      type: "Single-unit",
      occupationStatus: "Tenant Occupied",
      propertyStatus: "OK",
      email: "tarley@gmail.com",
      imageUrl: "/homeImageOne.png",
      allocatedUsers: [
        {
          id: "U04101",
          propertyId: "P04101",
          firstName: "John",
          lastName: "Snow",
          nextRentalPay: "01/12/2024",
        },
        {
          id: "U04101",
          propertyId: "P04101",
          firstName: "Sansa",
          lastName: "Snow",
          nextRentalPay: "01/12/2024",
        },
      ],
    },
    {
      id: "2",
      address: "1872 North Palace, Near Park Arear Milford",
      zipcode: 35004,
      ownerId: "",
      state: "Hartford",
      city: "Tallahassee",
      type: "Single-unit",
      occupationStatus: "Vacant",
      propertyStatus: "Pending Tenant Approval",
      email: "tarley@gmail.com",
      imageUrl: "/homeImageOne.png",
      allocatedUsers: [
        {
          id: "U04101",
          propertyId: "P04101",
          firstName: "John",
          lastName: "Snow",
          nextRentalPay: "01/12/2024",
        },
      ],
    },
    {
      id: "3",
      address: "3429 Adkins Rd. Nw Atlanta, GA 30331",
      zipcode: 35004,
      ownerId: "",
      state: "Hartford",
      city: "Tallahassee",
      type: "Single-unit",
      occupationStatus: "Owner Occupied",
      propertyStatus: "OK",
      email: "tarley@gmail.com",
      imageUrl: "/homeImageThree.png",
      allocatedUsers: [
        {
          id: "U04101",
          propertyId: "P04101",
          firstName: "John",
          lastName: "Snow",
          nextRentalPay: "01/12/2024",
        },
      ],
    },
    {
      id: "4",
      address: "1670 South Palace, Near Park Arear Milford",
      zipcode: 35004,
      ownerId: "",
      state: "Hartford",
      city: "Tallahassee",
      type: "Single-unit",
      occupationStatus: "Tenant Occupied",
      propertyStatus: "OK",
      email: "tarley@gmail.com",
      imageUrl: "/homeImageOne.png",
      allocatedUsers: [
        {
          id: "U04101",
          propertyId: "P04101",
          firstName: "Sansa",
          lastName: "Snow",
          nextRentalPay: "01/12/2024",
        },
      ],
    },
    {
      id: "5",
      address: "1670 South Palace, Near Park Arear Milford",
      zipcode: 35004,
      ownerId: "",
      state: "Hartford",
      city: "Tallahassee",
      type: "Single-unit",
      occupationStatus: "Tenant Occupied",
      propertyStatus: "OK",
      email: "tarley@gmail.com",
      imageUrl: "/homeImageOne.png",
      allocatedUsers: [
        {
          id: "U04101",
          propertyId: "P04101",
          firstName: "Sansa",
          lastName: "Snow",
          nextRentalPay: "01/12/2024",
        },
      ],
    },
    {
      id: "6",
      address: "1670 South Palace, Near Park Arear Milford",
      zipcode: 35004,
      ownerId: "",
      state: "Hartford",
      city: "Tallahassee",
      type: "Single-unit",
      occupationStatus: "Tenant Occupied",
      propertyStatus: "OK",
      email: "tarley@gmail.com",
      imageUrl: "/homeImageOne.png",
      allocatedUsers: [
        {
          id: "U04101",
          propertyId: "P04101",
          firstName: "Sansa",
          lastName: "Snow",
          nextRentalPay: "01/12/2024",
        },
      ],
    },
    {
      id: "7",
      address: "1670 South Palace, Near Park Arear Milford",
      zipcode: 35004,
      ownerId: "",
      state: "Hartford",
      city: "Tallahassee",
      type: "Single-unit",
      occupationStatus: "Tenant Occupied",
      propertyStatus: "OK",
      email: "tarley@gmail.com",
      imageUrl: "/homeImageOne.png",
      allocatedUsers: [
        {
          id: "U04101",
          propertyId: "P04101",
          firstName: "Sansa",
          lastName: "Snow",
          nextRentalPay: "01/12/2024",
        },
      ],
    },
  ],
  meta: {
    totalItems: 5,
    itemCount: 5,
    itemsPerPage: 5,
    totalPages: 5,
    currentPage: 5,
  },
  links: {
    first: "",
    last: "",
    previous: "",
    next: "",
  },
};

export function* getList(action) {
  yield delay(500);
  try {
    yield put(actions.getPropertiesListSuccess(response));
  } catch (e: any) {
    alert("Something Went Wrong");
  }
}

export function* getPropertyList(action) {
  yield delay(500);
  try {    
  const token = yield select(selectToken);
  const user = yield select(selectUser);
  const options = {
    method: "GET",
    headers: { ...getDefaultHeaders(), Authorization: `Bearer ${token}` }    
  };
  const queries = queryString.stringify({
    ownerId: action.payload.ownerId ? action.payload.ownerId : JSON.parse(localStorage.getItem('loginResponse')!).userId,
    pageNumber : action.payload.pageNumber,
    pageSize : action.payload.pageSize,
    propertyStatus : action.payload.propertyStatus ? action.payload.propertyStatus : '',
    status : action.payload.status ? action.payload.status : ''
  });

    const response = yield call(
      request,
      `${APP_API_URL}/propertiesManagement/Property/GetPropertiesportfolio?${queries}`,
      options
    )    

    if(response.Status === 401 || response.Status === 400) {      
      yield put(actions.getPortfolioListError(response.Message));  
      redirect();    
      return false;
    }

    if(!response.Result) {
      yield put(actions.getPortfolioListError(response.Message));
      return false;
    }    

    yield put(actions.getPortfolioListSuccess(response));
  } catch (err: any) {
    yield put(actions.getPortfolioListError(err));
    return false;
  }
}

async function* redirect(){
  yield put(actions.resetModule());
  return window.location.href = '/auth';  
}

export function* setSelectedPortfolioDetails(action) {
  yield delay(500);
  try {    
  const token = yield select(selectToken);
  const user = yield select(selectUser);
  
  const options = {
    method: "GET",
    headers: { ...getDefaultHeaders(), Authorization: `Bearer ${token}` }    
  };
  const queries = queryString.stringify({
    ownerId: user?.userId ? user?.userId : JSON.parse(localStorage.getItem('loginResponse')!).userId,
    propertyId : action.payload.id
  });

    const response = yield call(
      request,
      `${APP_API_URL}/propertiesManagement/Property/GetPropertyDetails?${queries}`,
      options
    )

    if(response.Status === 401 || response.Status === 400) {      
      yield put(actions.setSelectedPortfolioDetailsError(response.Message));
      redirect();
      return false;
    }

    if(!response.Result) {
      yield put(actions.setSelectedPortfolioDetailsError(response.Message));
      return false;
    }    

    yield put(actions.setSelectedPortfolioDetailsSuccess(response.Data));
  } catch (err: any) {
    yield put(actions.setSelectedPortfolioDetailsError(err));
    return false;
  }
}


export function* getPropertyListViaOwnerId(action) {
  yield delay(500);
  const token = yield select(selectToken);
  const user = yield select(selectUser);
  const options = {
    method: "GET",
    headers: { ...getDefaultHeaders(), Authorization: `Bearer ${token}` },
  };
  const queries = queryString.stringify({
    OwnerId: user.userId ? user.userId : JSON.parse(localStorage.getItem('loginResponse')!).userId,
  });
  try {
    const response = yield call(
      request,
      `${APP_API_URL}/propertiesManagement/Property?${queries}`,
      options
    );
    if (response.Status === 401 || response.Status === 400) {
      yield put(actions.getPropertiesDropdownListError(response.Message));
      redirect();
      return false;
    }
    if (!response.Result) {
      yield put(actions.getPropertiesDropdownListError(response.Message));
      return false;
    }
    const propertyList = new Array();
    response.Data.map((item) => {
      propertyList.push({ PropertyId: item.PropertyId, Address: item.Address });
    });
    yield put(actions.getPropertiesDropdownListSuccess(propertyList));
  } catch (err: any) {
    yield put(actions.getPropertiesDropdownListError(err));
    return false;
  }
}

export function* setDashboardData(action) {
  yield delay(500);
  const token = yield select(selectToken);
  const options = {
    method: "POST",
    headers: { ...getDefaultHeaders(), Authorization: `Bearer ${token}` },
  };
  const queries = queryString.stringify({
    ownerId: action?.payload ? action?.payload : JSON.parse(localStorage.getItem('loginResponse')!).userId,
  });
  try {
    const response = yield call(
      request,
      `${APP_API_URL}/propertiesManagement/Dashboard?${queries}`,
      options
    );
    if (response.Status === 401 || response.Status === 400) {
      yield put(actions.setDashboardDataError(response.Message));
      redirect();
      return false;
    }
    if (!response.Result) {
      yield put(actions.setDashboardDataError(response.Message));
      return false;
    }
    yield put(actions.setDashboardDataSuccess(response.Data));
  } catch (err: any) {
    yield put(actions.setDashboardDataError(err));
    return false;
  }
}

export function* getPropertyById(action) {
  yield delay(500);
  try {
    const selectedItem = response.items.filter((item) => {
      return item.id === action.payload.id;
    });
    Logger.log("from saga");
    Logger.log(selectedItem[0]);
    yield put(actions.getPropertyByIdSuccess({ property: selectedItem[0] }));
  } catch (e: any) {
    alert("Something Went Wrong");
  }
}

export function* addPropertyData(action) {
  yield delay(500);
  try {
    const token = yield select(selectToken);
    const options = {
      method: "POST",
      headers: { ...getDefaultHeaders(), Authorization: `Bearer ${token}` },
      body: JSON.stringify({
        ownerId: action.payload.data.ownerId.value ? action.payload.data.ownerId.value : JSON.parse(localStorage.getItem('loginResponse')!).userId,
        ownerName: action.payload.data.ownerName.value ? action.payload.data.ownerName.value : JSON.parse(localStorage.getItem('loginResponse')!).firstName,
        zip: action.payload.data.zip.value,
        street: action.payload.data.street.value,
        address: action.payload.data.address.value,
        latitude: action.payload.data.latitude.value,
        longitude: action.payload.data.longitude.value,
        city: action.payload.data.city.value,
        state: action.payload.data.state.value,
        type: Number(action.payload.data.type.value),
        status: Number(action.payload.data.occupationStatus.value),
        units: Number(action.payload.data.units.value),
      }),
    };

    const response = yield request(
      `${API_URL}/propertiesManagement/Property`,
      options
    );
    if (response.Status == 401 || response.Status == 400) {
      yield put(actions.addPropertyDataError(response.Message));
      redirect();
      return false;
    }

    if (!response.Result) {
      yield put(actions.addPropertyDataError(response));
      return false;
    }
    yield put(actions.addPropertyDataSuccess(response));
    toast.success('Property Added');
    yield call(action.payload?.callback);
  } catch (e: any) {
    yield put(actions.addPropertyDataError(e));
    return false;
  }
}

export function* getLeaseDataByPropertyId(action) {
  yield delay(500);
  try {
    const token = yield select(selectToken);
    const options = {
      method: "GET",
      headers: { ...getDefaultHeaders(), Authorization: `Bearer ${token}` },
    };
    const queries = queryString.stringify({
      proeprtyId: action.payload.data,
    });

    const response = yield request(
      `${API_URL}/propertiesManagement/Lease?${queries}`,
      options
    );
    if (response.Status === 401 || response.Status == 400) {
      yield put(actions.getLeaseDataByPropertyIdError(response.Message));
      redirect();
      return false;
    }

    if (!response.Result) {
      yield put(actions.getLeaseDataByPropertyIdError(response.Message));
      yield put(actions.resetLeaseData());
      yield call(action.payload?.callback);
      return false;
    }
    yield put(actions.getLeaseDataByPropertyIdSuccess(response.Data));
    yield call(action.payload?.callback);
  } catch (e: any) {
    yield put(actions.getLeaseDataByPropertyIdError(e));
    return false;
  }
}

export function* addLeaseData(action) {
  yield delay(500);
  try {
    const token = yield select(selectToken);
    const docURL = yield select(selectDocumentUploadUrl);
    const localPropertyId = localStorage.getItem("property");
    const localUserId = JSON.parse(localStorage.getItem("loginResponse")!).userId;
    
    const options = {
      method: "POST",
      headers: { ...getDefaultHeaders(), Authorization: `Bearer ${token}` },
      body: JSON.stringify({
        ownerId: action.payload.ownerId.value ? action.payload.ownerId.value : localUserId,
        propertyId: action.payload.PropertyId.value ? action.payload.PropertyId.value : localPropertyId,
        rentAmount: action.payload.RentAmount.value,
        leaseExpirationDate: action.payload.LeaseExpirationDate.value,
        payDay: action.payload.PayDay.value,
        depostiAmount: action.payload.DepostiAmount.value,
        documents: [
          {
            propertyId: action.payload.PropertyId.value ? action.payload.PropertyId.value : localPropertyId,
            documentURL: docURL,
            type: "document",
            isActive: true,
          },
        ],
      }),
    };

    const response = yield request(
      `${API_URL}/propertiesManagement/Lease`,
      options
    );
    if (response.Status == 401 || response.Status == 400) {
      yield put(actions.addLeaseDataError(response.Message));
      redirect();
      return false;
    }

    if (!response.Result) {
      yield put(actions.addLeaseDataError(response));
      return false;
    }

    yield put(actions.addLeaseDataSuccess(response));
    toast.success('Lease Added');
  } catch (e: any) {
    yield put(actions.addLeaseDataError(e));
    return false;
  }
}

export function* updateLeaseData(action) {
  yield delay(500);
  try {
    const token = yield select(selectToken);
    const localPropertyId = localStorage.getItem("property");
    const localUserId = JSON.parse(localStorage.getItem("loginResponse")!).userId;
    const docURL = yield select(selectDocumentUploadUrl);
    const docObj = {
      propertyId: action.payload.PropertyId.value ? action.payload.PropertyId.value : localPropertyId,
      documentURL: docURL,
      type: "document",
      isActive: true,
    };
    const updatedDocument = [...action.payload.Documents, docObj];

    Logger.log(updatedDocument);
    const options = {
      method: "PUT",
      headers: { ...getDefaultHeaders(), Authorization: `Bearer ${token}` },
      body: JSON.stringify({
        leaseId: action.payload.LeaseId.value,
        ownerId: action.payload.ownerId.value ? action.payload.ownerId.value : localUserId,
        propertyId: action.payload.PropertyId.value ? action.payload.PropertyId.value : localPropertyId,
        rentAmount: action.payload.RentAmount.value,
        leaseExpirationDate: action.payload.LeaseExpirationDate.value,
        payDay: action.payload.PayDay.value,
        depostiAmount: action.payload.DepostiAmount.value,
        documents: updatedDocument,
        images: action.payload.Images
      }),
    };

    const response = yield request(
      `${API_URL}/propertiesManagement/Lease`,
      options
    );
    if (response.Status == 401 || response.Status == 400) {
      yield put(actions.updateLeaseDataError(response.Message));
      redirect();
      return false;
    }

    if (!response.Result) {
      yield put(actions.updateLeaseDataError(response));
      return false;
    }

    yield put(actions.updateLeaseDataSuccess(response));
    toast.success('Lease Updated');
  } catch (e: any) {
    yield put(actions.updateLeaseDataError(e));
    return false;
  }
}

export function* updatePropertyStatus(action) {
  yield delay(500);
  try {
    Logger.log(action.payload.Documents);
    const token = yield select(selectToken);
    const options = {
      method: "PUT",
      headers: { ...getDefaultHeaders(), Authorization: `Bearer ${token}` },
      body: JSON.stringify({        
        propertyId: action.payload.propertyId,
        status: Number(action.payload.status)
      }),
    };

    const response = yield request(
      `${API_URL}/propertiesManagement/Property`,
      options
    );
    if (response.Status == 401 || response.Status == 400) {
      yield put(actions.updatePropertyStatusError(response.Message));
      redirect();
      return false;
    }

    if (!response.Result) {
      yield put(actions.updatePropertyStatusError(response));
      return false;
    }

    yield put(actions.updatePropertyStatusSuccess(response.Data));
    yield put(actions.resetError());
    toast.success('Property Updated');
  } catch (e: any) {
    yield put(actions.updatePropertyStatusError(e));
    return false;
  }
}


export function* usePropertySaga() {
  yield takeLatest(actions.getList.type, getList);
  yield takeLatest(actions.getPropertyById, getPropertyById);
  yield takeLatest(actions.setDashboardData.type, setDashboardData);
  yield takeLatest(actions.addPropertyData.type, addPropertyData);
  yield takeLatest(
    actions.getPropertyListViaOwnerId.type,
    getPropertyListViaOwnerId
  );
  yield takeLatest(
    actions.getLeaseDataByPropertyId.type,
    getLeaseDataByPropertyId
  );
  yield takeLatest(actions.addLeaseData.type, addLeaseData);
  yield takeLatest(actions.getPropertyList.type, getPropertyList);
  yield takeLatest(actions.updateLeaseData.type, updateLeaseData);  
  yield takeLatest(actions.setSelectedPortfolioDetails.type, setSelectedPortfolioDetails);
  yield takeLatest(actions.updatePropertyStatus.type, updatePropertyStatus); 
}
