import set from "lodash/set";
import { createSlice } from "utils/@reduxjs/toolkit";
import { createAction, PayloadAction } from "@reduxjs/toolkit";
import { UploadDocumentState } from "./types";
import { FormError } from "app/containers/types";

export const logoutSuccess = createAction("LOGOUT_SUCCESS");

const uploadDocumentSlice = createSlice({
  name: "uploadDocumentState",
  initialState: UploadDocumentState,
  reducers: {
    //set data via dispatch 
    setUploadDocumentData: (
      state,
      action: PayloadAction<{ name: string; value: string; form?: string }>
    ) => {
      const form = action.payload.form ? action.payload.form : "form";
      const { name, value } = action.payload;
      set(state.uploadDocument, `${name}.value`, value);
      set(state.uploadDocument, `${name}.error`, "");
    },

    setUploadDocumentFile: (state, action) => {
      state.uploadDocument.document = action.payload;
    }, 

    setUploadDocumentFileName: (state, action) => {
      state.uploadDocument.fileName.value = action.payload;
    }, 
    
    resetUploadDocumentFile: (state) => {
      state.uploadDocument.document = null;
    },
    
    getDocumentUpload: (state, action) => {
      state.loading = true;
    }, 

    getDocumentSuccess: (state, action) => {
      state.loading = false;
      //updated data will set from here
    }, 

    getDocumentFailed: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    resetError: (state) => {
      state.error = UploadDocumentState.error;
    } 
  },
});

export const { actions, reducer, name: sliceKey } = uploadDocumentSlice;
