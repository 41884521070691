import set from "lodash/set";
import { createSlice } from "utils/@reduxjs/toolkit";
import { createAction, PayloadAction } from "@reduxjs/toolkit";
import { FundingState } from "./types";
import { FormError } from "app/containers/types";

export const logoutSuccess = createAction("LOGOUT_SUCCESS");
// The initial state of the GithubRepoForm container

const fundingsSlice = createSlice({
  name: "fundingState",
  initialState: FundingState,
  reducers: {
    //set data via dispatch 
    setFundingSourceData: (
      state,
      action: PayloadAction<{ name: string; value: string; form?: string }>
    ) => {
      const form = action.payload.form ? action.payload.form : "form";
      const { name, value } = action.payload;
      set(state.fundingSource, `${name}.value`, value);
      set(state.fundingSource, `${name}.error`, "");
    },

    createLinkToken: (state, action) => {
      state.loading = true;
    },
    createLinkTokenSuccess: (state, action) => {
      state.loading = false;
      state.linkToken = action.payload.LinkToken;
    },
    createLinkTokenError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    
    exchangePublicToken: (state, action) => {
      state.loading = true;
    },
    exchangePublicTokenSuccess: (state, action) => {
      state.loading = false;
      state.createdAccessToken.accessToken = action.payload.AccessToken;
    },
    exchangePublicTokenError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    resetModule : (state) => {
      state.fundingSource = FundingState.fundingSource
    },
    setCreatedAccessToken: (state, action) => {
      state.createdAccessToken.accountId = action.payload;
    }, 
    setUserId: (state, action) => {
      state.createdAccessToken.userId = action.payload;
    }, 
    setUserName: (state, action) => {
      state.createdAccessToken.userName = action.payload;
    }, 
    setUserInfoId: (state, action) => {
      state.createdAccessToken.userInfoId = action.payload;
    },  
    setLocation: (state, action) => {
      state.createdAccessToken.location = action.payload;
    },      
    createProcessToken: (state, action) => {
      state.loading = true;
    },
    createProcessTokenSuccess: (state, action) => {
      state.loading = false;
      state.createdProcessToken = action.payload;
    },
    createProcessTokenError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    setIdVerficationRequestData: (
      state,
      action: PayloadAction<{ name: string; value: string; form?: string }>
    ) => {
      const form = action.payload.form ? action.payload.form : "form";
      const { name, value } = action.payload;
      set(state.idVerificationRequest, `${name}.value`, value);
      set(state.idVerificationRequest, `${name}.error`, "");
    },
    checkUserIDverification: (state, action) => {
      state.loading = true;
    },
    checkUserIDverificationSuccess: (state, action) => {
      state.loading = false;
      state.idVerificationUserResponse.status = action.payload;
    },
    checkUserIDverificationError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    checkBankIDverification: (state, action) => {
      state.loading = true;
    },
    checkBankIDverificationSuccess: (state, action) => {
      state.loading = false;
      state.idVerificationBankResponse.status = action.payload;
    },
    checkBankIDverificationError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    checkOwnerIDverification: (state, action) => {
      state.loading = true;
    },
    checkOwnerIDverificationSuccess: (state, action) => {
      state.loading = false;
      state.idVerificationOwnerResponse.status = action.payload;
    },
    checkOwnerIDverificationError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { actions, reducer, name: sliceKey } = fundingsSlice;
