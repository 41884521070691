import { Alert, Button, IconButton, InputAdornment, Typography} from "@mui/material";
import CopyRightSection from "../../../components/CopyRight";
import DocumentTypeDropDown from "../../../components/DocumentTypeDropDown";
import Page from "../../../components/Page";
import PageImageHeader from "../../../components/PageImageHeader";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import useWindowDimensions from "../../../../utils/hooks/useWindowDimensions";
import InformationDialog from "./component/InformationDialog";
import { useStyles } from "./styles";
import DocumentUpload from "../../../components/DocumentUpload";
import { selectDocument } from "./redux/selectors";
import { selectUser } from "../../../containers/Auth/selectors";
import { actions } from "./redux/slice";
import { LoggerClass } from "utils/logger";
import { actions as S3UploadActions } from "../../CommonComponent/S3UploadSaga/slice";
import { selectDocumentUploadUrl, selectError, selectLoading as selectLoadingS3 } from "app/containers/CommonComponent/S3UploadSaga/selector";
import {selectLoading } from "../UploadDocument/redux/selectors";
import Loader from "app/components/Loader";
const UploadDocument = () => {
  const { width, height } = useWindowDimensions();
  const styles = useStyles();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const roleType = location?.search.substring(6);
  const titleText = (roleType === 'businessSolo') ? 'Please Choose Document Type “Other” and upload EIN Letter.' : 'Please upload Document of identification.';
  const [confirmMsg, setConfirmMsg] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [documentType, setDocumentType] = useState<string>("Choose Document Type");
  const uploadDocument = useSelector(selectDocument);
  const loggedinUser = useSelector(selectUser);
  const documentUploadUrl = useSelector(selectDocumentUploadUrl);
  const error = useSelector(selectError);
  const loading = useSelector(selectLoading);
  const loadings3 = useSelector(selectLoadingS3);
  const [docUploaded, setDocUploaded] = useState(false);
  const [docName, setDocName] = useState("");
  const [errorText, setErrorText] = useState("");
  const benificary = localStorage.getItem("benificaryType") ? localStorage.getItem("benificaryType") : '';  //localStorage.getItem("benificaryTypeOwner") ? localStorage.getItem("benificaryTypeOwner") :
  useEffect(() => {
    if(error) {
      setErrorText(error);
      if(error === "Unauthorized") {
        navigation('/');
      }
      dispatch(actions.resetError());
    }
  }, [error]);
  
  useEffect(() => {
    let allFieldsHaveValue = true;
    
    for (const key in uploadDocument) {
      if(key !== "document" && key !== "benificary" && uploadDocument.hasOwnProperty(key) && uploadDocument[key].value.length === 0) {
      allFieldsHaveValue = false;
      break;
      } 

      if(key === "document" && uploadDocument.hasOwnProperty(key) && uploadDocument[key] === null) {
        allFieldsHaveValue = false;
        break;
        } 
    }
    if (allFieldsHaveValue) {
        setDisabled(false);
    } else {
        setDisabled(true);
    }
    
    }, [uploadDocument]);


    useEffect(() => {
      dispatch(actions.setUploadDocumentData({ name : 'documentUrl', value : documentUploadUrl+"", form: "addIndividualAccount" })); 
      }, [documentUploadUrl]);

  useEffect(() => {
    dispatch(actions.setUploadDocumentData({ name : 'userId', value : loggedinUser?.userId ? loggedinUser?.userId+"" : JSON.parse(localStorage.getItem("loginResponse")!).userId, form: "addIndividualAccount" })); 
    dispatch(actions.setUploadDocumentData({ name : 'landlordType', value : roleType, form: "addIndividualAccount" })); 
    dispatch(actions.setUploadDocumentData({ name : 'benificary', value : benificary+"", form: "addIndividualAccount" })); 
    
    }, [loggedinUser]);

    const closePopup = () => {
    setConfirmMsg(false);
  };

  const handleDocumentFileChange = (event) => { 
    setDocUploaded(true);
    setDocName(event.target.files[0].name);

    dispatch(
      S3UploadActions.getDocumentUploadUrl({
        fileData: event.target.files[0],
        contentType: "properties/documents",
      })
    );

    dispatch(actions.setUploadDocumentFile( event.target.files[0])); 
    dispatch(actions.setUploadDocumentFileName(event.target.files[0].name)); 
 };

 const handleContinue = (event) => {
  dispatch(
    actions.getDocumentUpload({
      fileData: uploadDocument,
      contentType: "properties/documents",
      callback: onsuccessUploadedDocument
    })
  ); 

 }
 const onsuccessUploadedDocument = () => {
  LoggerClass.info("Go to next screen");
  localStorage.setItem("benificaryType", 0+"");
  navigation("/id-verification");
};

  const handleBusinessTypeChange = (event) => {
     setDocumentType(event.target.value.split("-")[1]);
     dispatch(actions.setUploadDocumentData({ name : 'documentType', value : event.target.value.split("-")[0], form: "uploadDocumentData" })); 
    };

  return (
    <>
    <Loader loading={loading} />
    <Loader loading={loadings3} />

    <InformationDialog
        showPopup={confirmMsg}
        closePopup={closePopup}
      />
      
    <Page>
      <PageImageHeader
        subHeading={
          <div style={{ display: "flex" }}>
            <Typography sx={{ fontSize: 14, color: "#fff" }}>
              {"have account"}
            </Typography>
            <Typography
              sx={{
                fontSize: 14,
                color: "#FAA61A",
                fontWeight: "bold",
                textDecoration: "underline",
                marginLeft: "4px",
              }}
            >
              {"login"}
            </Typography>
          </div>
        }
      />

      <div
      style={{
      top: height / 2.82,
      marginBottom: "10px",
      width: "80%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      margin: 'auto'
      }}
      >
        <div className={styles.title}>Create an Account</div>
        {errorText && (
        <Alert className={styles.error} severity="error">
          {errorText}
        </Alert>
      )}
        <div className={styles.title1}>Upload {roleType === 'businessSolo' ? 'Business' : roleType === 'individual' ? 'Personal' : 'Controller/Beneficial Owner'} Verification Document</div>
        <div className={styles.subTitle}>
            In order to verify you account, additional documents are required. if you already uploaded them, it might be blurry, or not in the right format. 
            <span style={{color: 'blue', fontWeight: '500', cursor: 'pointer'}} onClick={(e: any) => {
            setConfirmMsg(true);
            }}>Click {" "}</span> here for more information about format and try again.
        </div>        
         <div className={styles.title1}>{titleText}</div>
         <div className={styles.disabledInputSpan}>  
            <Typography className={styles.pageTypoThresholdDisabled}>
            Document Type
            </Typography> 
         <DocumentTypeDropDown value={documentType} name="documentType" state={roleType} handleChange={handleBusinessTypeChange}/>
         </div>
         <div className={styles.disabledInputSpan}>
         <DocumentUpload value={documentType} name="documentType" state={roleType} handleChange={handleDocumentFileChange} docUploaded={docUploaded} docName={docName} selectedDocument={(output) => {
            setDocUploaded(false);
            dispatch(actions.resetUploadDocumentFile()); 
          }}/>
         </div>
        </div>
      <div
      style={{
      top: height / 2.82,
      marginBottom: "10px",
      width: "80%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      margin: 'auto'
      }}
      >
      <Button
      disabled={disabled}
      className={styles.signInButton}
      variant="contained"
      onClick={handleContinue}
      >
      Submit
      </Button>
      </div>
      <CopyRightSection/>
    </Page>
    </>
  );
};

export default UploadDocument;
