import {
  Button,
  Typography,
  Box,
  Dialog,
  Select,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useStyles from "../../styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useSelector } from "react-redux";
import { selectList, selectPropertyListDD } from "../../../../CommonComponent/Property/redux/selector";
import { useDispatch } from "react-redux";
import { actions } from "../../../redux/slice";
import { selectUser } from "app/containers/Auth/selectors";
const AddServiceDialog = ({ showPopup, closePopup, continueClick }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const propertyList = useSelector(selectPropertyListDD);
  const loggedInUser = useSelector(selectUser);

  const { items } = propertyList;
  const [show, setShow] = useState(false);
  const [propertySelect, setPropertySelect] = useState<any>("Choose property");
  const [buttonEnable, setButtonEnable] = useState(true);

  const userId = loggedInUser?.userId ? loggedInUser?.userId : JSON.parse(localStorage.getItem('loginResponse')!).userId;
 

  const close = () => {
    setShow(false);
    closePopup();
  };
  useEffect(() => {
  }, []);

  useEffect(() => {
    if (propertySelect !== "Choose property") {
      setButtonEnable(false);
    } else {
      setButtonEnable(true);
    }
  }, [propertySelect]);
  useEffect(() => {
    setShow(showPopup);
  }, [showPopup]);

  const handlePropertySelectChange = (event) => {
    const option = event.target.value;
    setPropertySelect(option.split("_")[1]);
    const { name, value } = event.target;
    dispatch(actions.resetServiceRequestData());
    dispatch(actions.setDropdownSelectedId(value.split("_")[0]));
    localStorage.setItem("property", value.split("_")[0]);
    localStorage.setItem("propertyAddress", value.split("_")[1]);
    dispatch(actions.ServiceRequestData({ name: 'OpenedBy', value : userId+"", form: "addService" }));
    dispatch(actions.ServiceRequestData({ name, value : value.split("_")[0], form: "addService" }));
    dispatch(actions.ServiceRequestData({ name: 'PropertyAddress', value : value.split("_")[1], form: "addService" }));

  };
  const NewIcon = (props) => (
    <KeyboardArrowDownIcon {...props} sx={{ color: "#192342" }} />
  );

  return (
    <Dialog open={show} className={styles.popup}>
      <Box sx={{ display: "flex", flexDirection: "column", margin: "16px" }}>
        <Typography className={styles.title}>Add Service Request</Typography>
        <Typography
          sx={{ margin: "24px 0px" }}
          className={styles.typoWrap40016}
        >
          Select The property you want to open the service request for:
        </Typography>
        <Typography className={styles.typo60014}>Properties</Typography>
        <Select
          MenuProps={{ classes: { paper: styles.menuPaper } }}
          inputProps={{ classes: { paper: styles.menuInput } }}
          value={propertySelect}
          className={styles.select}
          name="PropertyId"
          onChange={handlePropertySelectChange}
          IconComponent={NewIcon}
          placeholder={"Choose Property"}
          renderValue={() => (
            <Typography className={styles.typoGray40016}>
              {propertySelect}
            </Typography>
          )}
        >
          {items.map((item) => {
            return (
              <MenuItem key={item?.PropertyId} value={item.PropertyId+"_"+item.Address}>
                <Typography className={styles.typoGray40016}>
                  {item?.Address}
                </Typography>
              </MenuItem>
            );
          })}
        </Select>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ display: "flex", width: "48%" }}>
            <Button
              disabled={buttonEnable}
              className={styles.signInButton}
              onClick={continueClick}
            >
              Continue
            </Button>
          </Box>
          <Box sx={{ display: "flex", width: "48%" }} onClick={close}>
            <Button className={styles.signInButtonColorInvert}>Cancel</Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default AddServiceDialog;
