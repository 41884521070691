import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  sheet: {
    display: "flex",
    flex: 1,
    backgroundColor: "#ffffff",
    position: "absolute",
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    flexDirection: "column",
    paddingBottom: 20,
    //   paddingLeft:16,
    // paddinRight:16
  },
  title: {
    fontSize: 18,
    fontWeight: "600",
    color: "#192342",
    alignSelf: "center",
    // marginTop:20,
    fontFamily: "Open Sans",
    marginBottom: 24,
    marginTop: 24,
    marginLeft: 16,
    marginRight: 16,
    textAlign: "center",
  },
  subTitle: {
    fontSize: 12,
    color: "#192342",
    fontWeight: "400",
    marginTop: 16,
    alignSelf: "center",
    textAlign: "center",
    marginBottom: 10,
    marginLeft: 36,
    marginRight: 36,
  },
  textStyle: {
    width: "100%",
    alignSelf: "center",
    // marginTop:24,
    marginBottom: 10,
  },
  leftSide: {
    backgroundColor: "#FFFBEE",
    color: "#7D7D7D",
    paddingLeft: 10,
    paddingRight: 10,
    fontSize: 16,
    borderRadius: 6,
    paddingTop: 2,
    paddingBottom: 2,
  },
  checkBoxView: {
    flexDirection: "row",
    display: "flex",
    flex: 1,
    marginBottom: 10,
    marginleft: 19,
  },
  checkBoxTitle: {
    alignSelf: "center",
    fontSize: 14,
  },
  btn: {
    fontSize: "14px",
    fontWeight: 700,
    alignSelf: "center",
    padding: "12px 0",
    borderRadius: "10px",
    backgroundColor: "#192342",
    margin: "30px 0px",
    color: "#FFFFFF",
    width: '100%',
  },
  outlinebtn: {
    fontSize: "14px",
    alignSelf: "center",
    marginTop: 10,
    marginBottom: 24,
    width: '100%',
  },
  footerTxt: {
    fontSize: 12,
    fontWeight: 400,
    fontFamily: "Open Sans",
    textAlign: "center",
    color: "#192342",
  },
  footTxt: {
    fontSize: 12,
    fontWeight: 600,
    fontFamily: "Open Sans",
    textAlign: "center",
    color: "#006CFD",
  },
  spanTitle: {
    fontWeight: "600",
  },
  label: {
    fontSize: 14,
    fontFamily: "Open Sans",
    color: "#192342",
    marginBottom: 5,
  },
  errorLabel: {
    fontSize: 14,
    fontFamily: "Open Sans",
    color: "#EB5757",
    marginBottom: 5,
  },
  inputView: { marginLeft: 16, marginRight: 16 },
  inputViewButton : { marginLeft: 16, marginRight: 16},
  address: {
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 16,
    color: "#7D7D7D",
    fontFamily: "Open Sans",
  },
  dropDownView: {
    position: "absolute",
    // height: 100,
    maxHeight: 352,

    borderRadius: 10,
    // width: 200,
    zIndex: 10,
    overflow: "hidden",
    overflowY: "scroll",
    width: "90%",
    backgroundColor: "white",
    alignSelf: "center",
    elevation: 2,

    boxShadow: "1px 1px 2px rgba(0, 0, 0, 0.15)",
    "&::-webkit-scrollbar-track": {
      // background: "#F4F4F4",
      // boxShadow: "inset 4px 4px 3px rgba(0, 0, 0, 0.15)",
      // borderRadius: "10px",
      // position: 'absolute',
    },

    /* Handle */
    "&::-webkit-scrollbar-thumb": {
      background: "#82BCEE",
      borderRadius: 3,
      border: "3px solid #82BCEE",

      // transform: "matrix(-1, 0, 0, 1, 0, 0)",
      // boxShadow: "1px 1px 2px rgba(0, 0, 0, 0.15)",
      // width: 5
    },

    /* Handle on hover */
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#387E8D",
    },
  },
});
