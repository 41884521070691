import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import useStyles from "./styles";
import { Box } from "@mui/material";
export default function PaymentDay({ handleChange, value, name }) {
  const dates = [
    "01",
    "02",
    "03",
    "04",
    "05",
    "06",
    "07",
    "08",
    "09",
    "10",
    "11",
    "12",
    "13",
    "14",
    "15",
    "16",
    "17",
    "18",
    "19",
    "20",
    "21",
    "22",
    "23",
    "24",
    "25",
    "26",
    "27",
    "28",    
  ];
  const styles = useStyles();
  return (
    <FormControl fullWidth>
      <Select value={value} MenuProps={{ classes: { paper: styles.menuPaper } }} name={name} onChange={handleChange}>
        {dates.map((value, index) => {
          return (
            <MenuItem key={index * 89} value={value}>
              {value}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
