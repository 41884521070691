import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  checkBoxView: {
    flexDirection: "row",
    display: "flex",
    flex: 1,
    marginBottom: 10,
    paddingLeft: 15,
  },
  checkBoxTitle: {
    alignSelf: "center",
    fontSize: 14,
  },
  textStyle: {
    width: "90%",
    alignSelf: "center",
    marginBottom: 10,
    marginLeft: 16,
    marginRight: 16,
  },
  typo60014: {
    textAlign: "center",
    height: "fit-content",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "18px",
    letterSpacing: "0.2px",
    color: "#192342",
  },
  typo70014: {
    textAlign: "center",
    height: "fit-content",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "14px",
    lineHeight: "18px",
    letterSpacing: "0.2px",
    color: "#192342",
  },
  signInButtonColorInvert: {
    "&:disabled": {
      background: "#C4C4C4",
      color: "#6B6B6B",
    },
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: "50px",
    width: "100%",
    background: "#ffffff",
    color: "#192342",
    borderRadius: "8px",
    outline: "#192342 auto",
    "&:hover": {
      background: "#ffffff",
      color: "#192342",
      outline: "#192342 auto",
    },
  },
  signInButton: {
    "&:disabled": {
      background: "#C4C4C4",
      color: "#6B6B6B",
    },
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: "50px",
    width: "100%",
    background: "#192342",
    color: "#ffffff",
    borderRadius: "8px",
    fontWeight: '600',
    fontSize: '16px',
    fontFamily:'Open Sans',
    "&:hover": {
      background: "#192342",
      color: "#ffffff",
    },
  },
  yellowButton: {
    "&:disabled": {
      background: "#C4C4C4",
      color: "#6B6B6B",
    },
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: "54px",
    width: "100%",
    color: "#192342",
    background: "#F0BB00",
    borderRadius: "8px",
    "&:hover": {
      color: "#192342",
      background: "#F0BB00",
    },
  },
  label: {
    fontSize: 14,
    color: "#7D7D7D",
    marginLeft: 32,
    marginBottom: 5,
    fontFamily:'Open Sans',
    fontWeight: '600'
  },
  box: {
    backgroundColor: "#F0BB00",
    color: "#192342",
    fontSize: "14px",
    fontWeight: "600",
    fontFamily: "Open Sans",
    paddingLeft: 13,
    paddingRight: 13,
    paddingTop: 7,
    paddingBottom: 7,
    borderRadius: 8,
    textAlign: "center",
    display: "flex",
    flex: 0.4,
    justifyContent: "center",
    alignItems: "center",
  },
  footerTxt: {
    fontSize: 12,
    fontWeight: 400,
    fontFamily: "Open Sans",
    textAlign: "left",
    color: "#192342",
    marginLeft: 16
  },
  footTxt: {
    fontSize: 12,
    fontWeight: 600,
    fontFamily: "Open Sans",
    textAlign: "center",
    color: "#219653",
  },
  btn: {
    fontSize: "14px",
    fontWeight: 700,
    alignSelf: "center",
    padding: "12px 0",
    borderRadius: "10px",
    backgroundColor: "#192342",
    margin: "30px 0px",
    color: "#FFFFFF",
    marginLeft: 16,
    marginRight: 16,
  },
  outlinebtn: {
    fontSize: "16px",
    alignSelf: "center",
    fontWeight: '600',
    fontFamily: 'Open Sans',
    marginBottom: 24,
    marginLeft: 16,
    marginRight: 16,
  },
  popup: {
    "& .MuiDialog-container": {},
    "& .MuiPaper-root ": {
      width: "100%",
      overflow: "auto",
      //   padding: "1%",
      marginTop: "10%",
      background: "#FFFFFF",
      boxShadow:
        "11px 24px 11px rgba(61, 61, 61, 0.01), 6px 13px 9px rgba(61, 61, 61, 0.05), 3px 6px 7px rgba(61, 61, 61, 0.09), 1px 1px 4px rgba(61, 61, 61, 0.1), 0px 0px 0px rgba(61, 61, 61, 0.1)",
      borderRadius: "30px",
    },
  },
  modalTitle: {
    fontFamily: "Open Sans",
    fontWeight: "600",
    fontSize: 16,
    color: "192342",
    textAlign: "center",
    marginBottom: "10px",
  },
  modalSubTitle: {
    fontFamily: "Open Sans",
    fontWeight: "400",
    fontSize: 16,
    color: "192342",
    textAlign: "center",
  },
  otpInputRoot: {
    "& input": {
      background: "transparent",
      outline: "none",
      border: "none",
      borderBottom: "2px solid #FFFFFF",
      fontSize: "42px",
      color: "#FFF",
      padding: "11px 0px",
    },
    display: "flex",
    flex: 1,
    justifyContent: "center",
  },
  inputs: {
    //width: "100%",
    color: "#7D7D7D",
    font: 'inherit',
    borderRadius: "20px",
    border: "unset",
    margin: "5px 0px 0px 0px",
    "&:focus": {
      outline: "none",
    },

    "&:disabled": {
      background: "#E0E0E0",
      color: "#7D7D7D",
    },
    width: "90%",
    alignSelf: "center",
    marginBottom: 10,
    marginLeft: 16,
    marginRight: 16,
  }, 
  leftSide:{
      color:'#7D7D7D',
      paddingLeft:10,
      paddingRight:10,
      fontSize:18,
      fontWeight: '600',
      borderRadius:6,
      paddingTop:2,
      paddingBottom:2
      }
});
