import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  textLine : {
    fontWeight: '600', 
    fontFamily: 'Open Sans', 
    fontSize : '14px !important', 
    textAlign: 'center',
  },
  ConnectInButton: {
    "&:disabled": {
      background: "#C4C4C4",
      color: "#19234275",
    },
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 16px",
    gap: "10px",
    font : 'inherit',
    fontWeight : '700',
    fontSize: '14px',
    height: "50px",
    background: "#F0BB00",
    color: "#192342",
    borderRadius: "8px",
    marginTop: 19,
    marginBottom: '5%',
    "&:hover": {
      background: "#F0BB00",
      color: "#192342",
    },
  },
  DoneImage : {
    margin: "24px 0 24px 0",
    height: '50px'
  },
  PendingImage : {
    margin: '24px 0px',
    height: '50px',
    boxShadow: '1px 2px 7px',
    padding: '10px',
    borderRadius: '50%'
  },
  Pending: {
    color: '#FF7800',
    fontWeight: '600'
  },
  Done: {
    color: '#219653',
    fontWeight: '600'
  },
  Failed: {
    color: '#EB5757',
    fontWeight: '600'
  },
  statusSection: {
    margin: 'auto',
    width: '90%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#F8F8F8',
    border: '0.5px solid #bcbcbc',
    borderRadius: '20px',
    padding: '10px 16px',
    marginBottom: '20px !important',
  },
  statusDoneSection: {
    margin: 'auto',
    width: '90%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    background: '#FFF7DD',
    border: '0.5px solid #bcbcbc',
    borderRadius: '20px',
    padding: '10px 16px',
    marginBottom: '20px !important',
  },
  sheet: {
    display: "flex",
    flex: 1,
    backgroundColor: "#ffffff",
    position: "absolute",
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    flexDirection: "column",
  },
  logo: {
    height: 121,
    width: 227,
    marginTop: 24,
    alignSelf: "center",
  },
  logoContainer: {
    display: "flex",
    justifyContent: "center",
  },
  title: {
    fontSize: 20,
    fontWeight: "700",
    color: "#F0BB00",
    alignSelf: "center",
  },
  subtitle: {
    fontSize: 18,
    color: "#192342",
    fontWeight: "600",
    marginTop: 16,
    alignSelf: "center",
    marginBottom: 10,
  },
  btn: {
    fontSize: "14px",
    fontWeight: 700,
    alignSelf: "center",
    padding: "12px 0",
    borderRadius: "10px",
    backgroundColor: "#192342",
    margin: "30px 0px",
    color: "#FFFFFF",
  },
  contentTxt: {
    marginTop: 10,
    fontSize: 14,
    fontWeight: "400",
    alignSelf: "center",
    color: "#192342",
  },
  imgView: {
    flexDirection: "row",
    flex: 1,
    justifyContent: "center",
    display: "flex",
    marginTop: 16,
  },
  icon: {
    height: 36,
    width: 36,
  },
  btmTxt: {
    fontSize: 16,
    fontWeight: "400",
    alignSelf: "center",
    marginTop: 24,
    // marginBottom: 40,
  },
  span: {
    color: "#F0BB00",
    fontWeight: "700",
  },
  signInButton: {
    "&:disabled": {
      background: "#C4C4C4",
      color: "#6B6B6B",
    },
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 16px",
    gap: "10px",
    height: "50px",
    fontSize : "14px",
    fontWeight : '700',
    font : 'inherit',
    background: "#192342",
    color: "#ffffff",
    borderRadius: "8px",
    "&:hover": {
      background: "#192342",
      color: "#ffffff",
    },
  },
  signInButtonColorInvert: {
    "&:disabled": {
      background: "#C4C4C4",
      color: "#6B6B6B",
    },
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px 16px",
    gap: "10px",
    height: "50px",
    background: "#ffffff",
    color: "#192342",
    borderRadius: "8px",
    marginTop: 10,
    border: "1px solid #192342",
    "&:hover": {
      background: "#ffffff",
      color: "#192342",
      border: "1px solid #192342",
    },
  },
  inputs: {
    padding: "10px 16px",
    width: "100%",
    height: "48px",
    background: "#FFF7DD",
    color: "#7D7D7D",
    borderRadius: "20px",
    border: "unset",
    margin: "5px",
    "&:focus": {
      outline: "none",
    },
  },
  errorInputSpan: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    padding: "10px 16px 10px 0px",
    width: "100%",
    height: "48px",
    background: "#FFF7DD",
    color: "#7D7D7D",
    borderRadius: "20px",
    border: "unset",
    margin: "5px 5px 5px 0px",
    alignItems: "center",
    outline: "#EB5757 auto",
  },
  errorTypo: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "19px",
    alignSelf: "flex-start",
    color: "#EB5757",
    marginLeft: "20px",
    padding: "5px",
  },
  inputSpan: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    padding: "10px 16px 10px 0px",
    width: "100%",
    height: "48px",
    background: "#FFF7DD",
    color: "#7D7D7D",
    borderRadius: "20px",
    border: "unset",
    margin: "5px 5px 5px 0px",
    alignItems: "center",
  },
  whiteSpan: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    padding: "10px 16px 10px 0px",
    width: "100%",
    height: "48px",
    background: "#FFFFFF",
    color: " #192342",
    borderRadius: "20px",
    margin: "5px 5px 24px 0px",
    alignItems: "center",
    border: "0.5px solid #BCBCBC",
    boxShadow:
      "7px 19px 8px rgba(194, 194, 194, 0.01), 4px 10px 7px rgba(194, 194, 194, 0.05), 2px 5px 5px rgba(194, 194, 194, 0.09), 0px 1px 3px rgba(194, 194, 194, 0.1), 0px 0px 0px rgba(194, 194, 194, 0.1)",
  },
  inputSpanPaypal: {
    display: "flex",
    flexDirection: "row",
    padding: "10px 16px 10px 0px",
    width: "100%",
    height: "48px",
    background: "#FFF7DD",
    color: "#7D7D7D",
    borderRadius: "20px",
    border: "unset",
    margin: "10px 5px 200px 0px",
    alignItems: "center",
  },
  buttonSpan: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    padding: "10px 16px 10px 0px",
    width: "100%",
    height: "48px",
    background: "#ffffff",
    color: "#192342",
    borderRadius: "20px",
    margin: "5px",
    alignItems: "center",
    outline: "#192342 auto",
    "&:disabled": {
      background: "#ffffff",
      color: "#192342",
      outline: "#192342 auto",
    },
    marginTop: 10,
    marginBottom: 110,
    "&:hover": {
      background: "#ffffff",
      color: "#192342",
      outline: "#192342 auto",
    },
  },
  pageHeading: {
    margin: "24px 0 10px 0",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    height: "25px",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "18px",
    lineHeight: "140%",
    letterSpacing: "0.2px",
    color: "#192342",
  },
  checkbox: {
    marginRight: "6px",
    "&:checked": {
      accentColor: "#192342",
    },
  },
  checkboxTypos: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    color: "#192342",
    display: "contents",
    marginLeft: "6px",
  },
  linkForgot: {
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    color: "#006CFD",
    textDecoration: "none",
  },
  upperContainer: {
    height: "32%",
  },
  lowerContainer: {
    position: "relative",
    height: "68%",
    background: "#FFFFFF",
    boxShadow:
      "0px -37px 15px rgba(222, 222, 222, 0.01), 0px -21px 12px rgba(222, 222, 222, 0.05), 0px -9px 9px rgba(222, 222, 222, 0.09), 0px -2px 5px rgba(222, 222, 222, 0.1), 0px 0px 0px rgba(222, 222, 222, 0.1)",
    borderRadius: "30px 30px 0px 0px",
    padding: "0% 2%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  pageTypo2: {
    textAlign: "center",
    height: "fit-content",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "140%",
    letterSpacing: "0.2px",
    marginBottom: "24px",
    color: "#192342",
  },
  pageTypo4: {
    textAlign: "center",
    height: "fit-content",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "140%",
    letterSpacing: "0.2px",
    marginBottom: "24px",
    color: "#192342",
  },
  pageTypo5: {
    textAlign: "center",
    height: "fit-content",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "140%",
    letterSpacing: "0.2px",
    margin: "0 50% 14px 0",
    color: "#192342",
  },
  pageTypo1: {
    height: "fit-content",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "16px",
    lineHeight: "140%",
    letterSpacing: "0.1px",
    color: "#192342",
    position: "absolute",
    marginLeft: "auto",
    marginRight: "auto",
    left: "0",
    right: "0",
    textAlign: "center",
  },
  pageTypo0: {
    textAlign: "center",
    height: "fit-content",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "140%",
    letterSpacing: "0.1px",
    marginBottom: "16px",
    color: "#219653",
  },
  buttonPaypal: {
    textAlign: "center",
    height: "fit-content",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "16px",
    lineHeight: "24px",
    letterSpacing: "0.1px",
    color: "#192342",
  },
  pageTypoThreshold: {
    textAlign: "left",
    height: "fit-content",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "14px",
    lineHeight: "19px",
    letterSpacing: "0.2px",
    margin: "0 0 5px 3%",
    color: "#192342",
  },
  pageTypoMinimum: {
    textAlign: "left",
    height: "fit-content",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "10px",
    lineHeight: "14px",
    letterSpacing: "0.2px",
    margin: "0 0 5px 3%",
    color: "#192342",
  },
  bottomTypo: {
    textAlign: "center",
    height: "fit-content",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "12px",
    letterSpacing: "0.2px",
    color: "#192342",
    position: "absolute",
    bottom: "10px",
  },
  bottomLink: {
    textAlign: "center",
    height: "fit-content",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "600",
    fontSize: "12px",
    letterSpacing: "0.2px",
    color: "#006CFD",
    textDecoration: "none",
  },
  centerContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: 0,
    margin: "0px 46px 0px 46px",
  },
  popup: {
    "& .MuiDialog-container": {},
    "& .MuiPaper-root ": {
      width: "100%",
      height: "55%",
      padding: "1%",
      marginTop: "50%",
      background: "#FFFFFF",
      boxShadow:
        "11px 24px 11px rgba(61, 61, 61, 0.01), 6px 13px 9px rgba(61, 61, 61, 0.05), 3px 6px 7px rgba(61, 61, 61, 0.09), 1px 1px 4px rgba(61, 61, 61, 0.1), 0px 0px 0px rgba(61, 61, 61, 0.1)",
      borderRadius: "30px",
    },
  },
  popupLowHeight: {
    "& .MuiDialog-container": {},
    "& .MuiPaper-root ": {
      width: "100%",
      height: "40%",
      padding: "1%",
      marginTop: "0%",
      background: "#FFFFFF",
      boxShadow:
        "11px 24px 11px rgba(61, 61, 61, 0.01), 6px 13px 9px rgba(61, 61, 61, 0.05), 3px 6px 7px rgba(61, 61, 61, 0.09), 1px 1px 4px rgba(61, 61, 61, 0.1), 0px 0px 0px rgba(61, 61, 61, 0.1)",
      borderRadius: "30px",
    },
  },
  dialogTypo: {
    textAlign: "center",
    height: "fit-content",
    fontFamily: "Open Sans",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "140%",
    letterSpacing: "0.1px",
    color: "#192342",
  },
});

export default useStyles;
