import { Button, Typography, Box } from "@mui/material";
import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import { useNavigate } from "react-router";
import PageContainer from "app/components/Container/PageContainer";
type Props = {};
const Login = (props: Props) => {
  const styles = useStyles();
  const navigate = useNavigate();
  const [newpassword, setNewpassword] = useState("");
  const [newpasswordError, setNewpasswordError] = useState(false);
  const [oldpassword, setOldpassword] = useState("");
  const [oldpasswordError, setOldpasswordError] = useState(false);
  const [keyDown, setKeyDown] = useState(false);
  const onOldpasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOldpassword(event.target.value);
  };
  const onNewpasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewpassword(event.target.value);
  };
  useEffect(() => {
    if (oldpassword.length <= 7 || oldpassword === "") {
      // setOldpasswordError(true);
      // setTimeout(() => {
      //   setOldpasswordError(false);
      // }, 5000);
      setKeyDown(false);
    } else if (newpassword.length <= 7 || newpassword === "") {
      // setNewpasswordError(true);
      // setTimeout(() => {
      //   setNewpasswordError(false);
      // }, 5000);
      setKeyDown(false);
    }else{
      setKeyDown(true);
    }
  }, [newpassword, oldpassword]);
  const handleSubmit = (e) => {
    e.preventDefault();
    navigate("/reset-successfull");
  };
  return (
    <PageContainer>
        <Typography className={styles.pageHeading}>
          Reset the password
        </Typography>
        <Typography className={styles.pageTypo2}>
          Set the new password for your account
        </Typography>
          <span
            className={
              !oldpasswordError ? styles.inputSpan : styles.errorInputSpan
            }
          >
            <input
              className={styles.inputs}
              placeholder="Enter Old Password"
              value={oldpassword}
              type="password"
              onChange={onOldpasswordChange}
            />
          </span>
          {newpasswordError && (
            <Typography className={styles.errorTypo}>Invalid Email</Typography>
          )}

          <span
            className={
              !oldpasswordError ? styles.inputSpan : styles.errorInputSpan
            }
          >
            <input
              className={styles.inputs}
              placeholder="Enter New Password"
              value={newpassword}
              type="password"
              onChange={onNewpasswordChange}
            />
          </span>
          {oldpasswordError && (
            <Typography className={styles.errorTypo}>
              Invalid Password
            </Typography>
          )}
        <Button
          disabled={!keyDown}
          className={styles.signInButton}
          variant="contained"
          onClick={handleSubmit}
        >
          Reset Password
        </Button>
    </PageContainer>
  );
};

export default Login;
