/**
 * Gets the repositories of the user from Github
 */
import { toast } from "react-toastify";
import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { API_URL } from "utils/constants";
import { getDefaultHeaders } from "utils/helpers";
import { request } from "utils/request";
import { actions } from "./slice";
import { LoggerClass as Logger } from "utils/logger";
import { selectToken } from "../../selectors";
import queryString from "query-string";
import { selectCreatedBusinessData } from "./selectors";


/**
 * Github repos request/response handler
 */
export function* getOwnerList(action) {
  yield delay(500);
  try {
    Logger.info(action.payload.data);
    const token = yield select(selectToken);
    const options = {
      method: "POST",
      headers: { ...getDefaultHeaders(), Authorization: `Bearer ${token}` }     
    };    
    const queries = queryString.stringify({
      UserId: action.payload.data.UserId?.value,
      BusinessId: action.payload.data.BussinesId?.value,  
    });

    const response = yield request(`${API_URL}/userManagement/OwnerAndController/GetOwnerDetailsByBusinessIdAndUserId?${queries}`, options);
    
    if(response.Status === 401 || response.Status === 400) {      
      yield put(actions.getOwnerListError(response.Message));  
      return false;
    }

    if(!response.Result) {
      yield put(actions.getOwnerListError(response.Message));
      return false;
    }   

    yield put(actions.getOwnerListSuccess(response));
  } catch (e: any) {
    yield put(actions.getOwnerListError(e));
    return false;
  }
}

export function* createBusinessAccount(action) {
  yield delay(500);
  try {
    Logger.info(action.payload.data);
    const token = yield select(selectToken);
    const options = {
      method: "POST",
      headers: { ...getDefaultHeaders(), Authorization: `Bearer ${token}` },
      body: JSON.stringify({
        userId: action.payload.data.userId?.value,
        email: action.payload.data.email?.value,
        firstName: action.payload.data.firstName?.value,
        lastName: action.payload.data.lastName?.value,
        businessName: action.payload.data.businessName?.value,
        businessType: action.payload.data.businessType?.value,
        businessEIN: action.payload.data.businessEIN?.value ? action.payload.data.businessEIN?.value : null,
        ownerSSN: action.payload.data.ownerSSN?.value ? action.payload.data.ownerSSN?.value : null,
        ownerDOB: action.payload.data.ownerDOB?.value ? action.payload.data.ownerDOB?.value : null,
        businessAddress: action.payload.data.businessAddress?.value,
        businessAddress2: action.payload.data.businessAddress2?.value,
        city: action.payload.data.city?.value,
        state: action.payload.data.state?.value,
        zipCode: action.payload.data.zip?.value,
        country: 'US',
      }),
    };    
    const response = yield request(`${API_URL}/userManagement/Business`, options);
    
    if(response.Status === 401 || response.Status === 400) {      
      yield put(actions.createBusinessAccountError(response.Message));  
      return false;
    }

    if(!response.Result) {
      yield put(actions.createBusinessAccountError(response.Message));
      return false;
    }   

    yield put(actions.createBusinessAccountSuccess(response));
    yield localStorage.setItem("ownerName", action.payload.data.firstName?.value+" "+action.payload.data.lastName?.value);
    yield localStorage.setItem("ownerId", action.payload.data.userId?.value);
    yield localStorage.setItem("ownerType", 'business');
    yield localStorage.setItem("businessType", action.payload.data.businessType?.value);
    yield localStorage.setItem("businessId", response.Data.BusinessId);

    toast.success('Business Account Created');
    yield call(action.payload?.callback);
  } catch (e: any) {
    yield put(actions.createBusinessAccountError(e));
    return false;
  }
}

export function* createIndividualAccount(action) {
  yield delay(500);
  try {
    Logger.info(action.payload.data);
    const token = yield select(selectToken);
    const options = {
      method: "POST",
      headers: { ...getDefaultHeaders(), Authorization: `Bearer ${token}` },
      body: JSON.stringify({
        userId: action.payload.data.userId?.value,
        email: action.payload.data.email?.value,
        firstName: action.payload.data.firstName?.value,
        lastName: action.payload.data.lastName?.value,
        ssn: action.payload.data.ssn?.value ? action.payload.data.ssn?.value : null,
        dob: action.payload.data.dob?.value ? action.payload.data.dob?.value : null,
        addressLine1: action.payload.data.address?.value,
        addressLine2: action.payload.data.address2?.value,
        city: action.payload.data.city?.value,
        state: action.payload.data.state?.value,
        zipCode: action.payload.data.zip?.value,
      }),
    };    
    const response = yield request(`${API_URL}/userManagement/Users/CreateIndividualLandlord`, options);
    
    if(response.Status === 401 || response.Status === 400) {      
      yield put(actions.createIndividualAccountError(response.Message));  
      return false;
    }

    if(!response.Result) {
      yield put(actions.createIndividualAccountError(response.Message));
      return false;
    }   

    yield put(actions.createIndividualAccountSuccess(response));
    yield localStorage.setItem("ownerName", action.payload.data.firstName?.value+" "+action.payload.data.lastName?.value);
    yield localStorage.setItem("ownerId", action.payload.data.userId?.value);
    yield localStorage.setItem("ownerType", 'individual');
    yield localStorage.setItem("businessType", '');
    yield localStorage.setItem("benificaryType", '');
    toast.success('Individual Account Created');
    yield call(action.payload?.callback);
  } catch (e: any) {
    yield put(actions.createIndividualAccountError(e));
    return false;
  }
}

export function* updateOwners(action) {
  console.log(action.payload);
  yield delay(500);
  try {
    const token = yield select(selectToken);
    const options = {
      method: "POST",
      headers: { ...getDefaultHeaders(), Authorization: `Bearer ${token}` },
      body: JSON.stringify({
        userType: Number(action.payload.data.userType?.value),
        userId: action.payload.data.userId?.value,
        businessId: action.payload.data.businessId?.value,
        firstName: action.payload.data.firstName?.value,
        lastName: action.payload.data.lastName?.value,
        title: action.payload.data.title?.value,
        isUSPerson: Number(action.payload.data.personType?.value),
        passportNumber: action.payload.data.passportNumber?.value ? action.payload.data.passportNumber?.value : null,
        issuanceCountry: action.payload.data.issuanceCountry?.value ? action.payload.data.issuanceCountry?.value : null,
        ssn: action.payload.data.ssn?.value ? action.payload.data.ssn?.value : null,
        dob: action.payload.data.dob?.value ? action.payload.data.dob?.value : null,
        address1: action.payload.data.address?.value,
        address2: action.payload.data.address2?.value,
        country: action.payload.data.country?.value ? action.payload.data.country?.value : "US",
        city: action.payload.data.city?.value,
        state: action.payload.data.state?.value,
        zipCode: action.payload.data.zip?.value,
        ownerAndControllerId: action.payload.data.ownerAndControllerId?.value,
        userInfoId: action.payload.data.userInfoId?.value       
      }),
    };   
    
    const response = yield request(`${API_URL}/userManagement/OwnerAndController/UpdateOwnerDetails`, options);
    
    if(response.Status === 401 || response.Status === 400) {      
      yield put(actions.updateOwnersError(response.Message));  
      return false;
    }

    if(!response.Result) {
      yield put(actions.updateOwnersError(response.Message));
      return false;
    }   

    yield put(actions.updateOwnersSuccess(response));
    toast.success('Owner Updated');
    yield call(action.payload?.callback);
  } catch (e: any) {
    yield put(actions.updateOwnersError(e));
    return false;
  }
}

export function* createOwners(action) {
  yield delay(500);
  try {
    const token = yield select(selectToken);
    const options = {
      method: "POST",
      headers: { ...getDefaultHeaders(), Authorization: `Bearer ${token}` },
      body: JSON.stringify({
        userType: Number(action.payload.data.userType?.value),
        userId: action.payload.data.userId?.value,
        businessId: action.payload.data.businessId?.value,
        firstName: action.payload.data.firstName?.value,
        lastName: action.payload.data.lastName?.value,
        title: action.payload.data.title?.value,
        isUSPerson: Number(action.payload.data.personType?.value),
        passportNumber: action.payload.data.passportNumber?.value ? action.payload.data.passportNumber?.value : null,
        issuanceCountry: action.payload.data.issuanceCountry?.value ? action.payload.data.issuanceCountry?.value : null,
        ssn: action.payload.data.ssn?.value ? action.payload.data.ssn?.value : null,
        dob: action.payload.data.dob?.value ? action.payload.data.dob?.value : null,
        address1: action.payload.data.address?.value,
        address2: action.payload.data.address2?.value,
        country: action.payload.data.country?.value ? action.payload.data.country?.value : "US",
        city: action.payload.data.city?.value,
        state: action.payload.data.state?.value,
        zipCode: action.payload.data.zip?.value       
      }),
    };   
    
    const response = yield request(`${API_URL}/userManagement/OwnerAndController/RegisterBeneficialOwner`, options);
    
    if(response.Status === 401 || response.Status === 400) {      
      yield put(actions.createOwnersError(response.Message));  
      return false;
    }

    if(!response.Result) {
      yield put(actions.createOwnersError(response.Message));
      return false;
    }   

    yield put(actions.createOwnersSuccess(response));
    toast.success('Owner Created');
    yield call(action.payload?.callback);
  } catch (e: any) {
    yield put(actions.createOwnersError(e));
    return false;
  }
}

export function* createControllerOwner(action) {
  yield delay(500);
  try {
    Logger.info(action.payload.data);
    const token = yield select(selectToken);
    const options = {
      method: "POST",
      headers: { ...getDefaultHeaders(), Authorization: `Bearer ${token}` },
      body: JSON.stringify({
        userType: Number(action.payload.data.userType?.value),
        userId: action.payload.data.userId?.value,
        businessId: action.payload.data.businessId?.value,
        firstName: action.payload.data.firstName?.value,
        lastName: action.payload.data.lastName?.value,
        title: action.payload.data.title?.value,
        isUSPerson: Number(action.payload.data.personType?.value),
        passportNumber: action.payload.data.passportNumber?.value ? action.payload.data.passportNumber?.value : null,
        issuanceCountry: action.payload.data.issuanceCountry?.value ? action.payload.data.issuanceCountry?.value : null,
        ssn: action.payload.data.ssn?.value ? action.payload.data.ssn?.value : null,
        dob: action.payload.data.dob?.value ? action.payload.data.dob?.value : null,
        address1: action.payload.data.address?.value,
        address2: action.payload.data.address2?.value,
        country: action.payload.data.country?.value ? action.payload.data.country?.value : "US",
        city: action.payload.data.city?.value,
        state: action.payload.data.state?.value,
        zipCode: action.payload.data.zip?.value,
      }),
    };   
    
    const response = yield request(`${API_URL}/userManagement/OwnerAndController/RegisterController`, options);
    
    if(response.Status === 401 || response.Status === 400) {      
      yield put(actions.createControllerOwnerError(response.Message));  
      return false;
    }

    if(!response.Result) {
      yield put(actions.createControllerOwnerError(response.Message));
      return false;
    }   

    yield put(actions.createControllerOwnerSuccess(response));
    yield localStorage.setItem("benificaryType", Number(action.payload.data.userType?.value)+"");
    toast.success('Controller Created');
    yield call(action.payload?.callback);
  } catch (e: any) {
    yield put(actions.createControllerOwnerError(e));
    return false;
  }
}

export function* addThreshold(action) {
  yield delay(500);
  try {
    const token = yield select(selectToken);
    const options = {
      method: "POST",
      headers: { ...getDefaultHeaders(), Authorization: `Bearer ${token}` },
      body: JSON.stringify({
        userId: action.payload.data.UserId.value,
        threshold : action.payload.data.ThresholdAmt.value
      })
      }; 
    
    const response = yield request(`${API_URL}/userManagement/Users/UpdateUserByUserId`, options);
    
    if(response.Status === 401 || response.Status === 400) {      
      yield put(actions.addThresholdError(response.Message));  
      return false;
    }

    if(!response.Result) {
      yield put(actions.addThresholdError(response.Message));
      return false;
    }   

    yield put(actions.addThresholdSuccess(response));
    toast.success('Threshold Added');
    yield call(action.payload?.callback);
  } catch (e: any) {
    yield put(actions.addThresholdError(e));
    return false;
  }
}


export function* getOwners(action) {
  yield delay(500);
  try {
    const token = yield select(selectToken);
    const options = {
      method: "GET",
      headers: { ...getDefaultHeaders(), Authorization: `Bearer ${token}` }      
      }; 

      const queries = queryString.stringify({
        UserInfoId: action.payload?.data
      });
    
      const response = yield call(
        request,
        `${API_URL}/userManagement/OwnerAndController/GetOwnerDetailsByUserInfoId?${queries}`,
        options
      );   
    
    if(response.Status === 401 || response.Status === 400) {      
      yield put(actions.getOwnersError(response.Message));  
      return false;
    }

    if(!response.Result) {
      yield put(actions.getOwnersError(response.Message));
      return false;
    }   

    yield put(actions.getOwnersSuccess(response.Data));
    yield call(action.payload?.callback);
  } catch (e: any) {
    yield put(actions.getOwnersError(e));
    return false;
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* businessAccountSaga() {
  yield takeLatest(actions.createIndividualAccount.type, createIndividualAccount);
  yield takeLatest(actions.createBusinessAccount.type, createBusinessAccount);
  yield takeLatest(actions.createControllerOwner.type, createControllerOwner);
  yield takeLatest(actions.createOwners.type, createOwners);
  yield takeLatest(actions.updateOwners.type, updateOwners);
  yield takeLatest(actions.addThreshold.type, addThreshold);  
  yield takeLatest(actions.getOwnerList.type, getOwnerList);
  yield takeLatest(actions.getOwners.type, getOwners);  
}
