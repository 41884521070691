import { Button, Typography, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useStyles from "./styles";
import questionmark from "../../../assets/questionMarkS.png";
import PageImageHeader from "app/components/PageImageHeader";
import Page from "app/components/Page";
import CopyRightSection from "app/components/CopyRight";
import useWindowDimensions from "utils/hooks/useWindowDimensions";
import UserVerification from "./Component/UserVerification";
import BankVerification from "./Component/BankVerification";
import BanificialOwnerVerification from "./Component/BenificialOwnerVerification";
import { useDispatch, useSelector } from "react-redux";
import Loader from "app/components/Loader";
import { selectLoading } from "../FundingsSource/redux/selectors";
import { selectUser } from "../selectors";
import { actions } from "../FundingsSource/redux/slice";
import { Navigation } from "@mui/icons-material";
import { BusinessType } from "../CreateBusinessAccount/redux/enum";
import greyPng from "../../../assets/grey.png";
import whitePng from "../../../assets/white.png";
import { actions as propertyActions } from "../../CommonComponent/Property/redux/slice";

type Props = {};
const IDVerification = (props: Props) => {
  const { height } = useWindowDimensions();

  const styles = useStyles();
  const dispatch = useDispatch();
  const loggedinUser = useSelector(selectUser);
  const loading = useSelector(selectLoading);
  const navigation = useNavigate();
  const [disabled, setDisabled] = useState(true);
  const [userVerification, setUserVerification] = useState(false);
  const [bankVerification, setBankVerification] = useState(false);
  const [ownerVerification, setOwnerVerification] = useState(false);
  const [landlordType, setLandlordType] = useState("individual");

  const ownerType = localStorage.getItem("ownerType");
  const businessType = localStorage.getItem("businessType");
  const benificary = localStorage.getItem("benificaryType");

  useEffect(() => {
    if (
      ownerType === "business" &&
      (Number(businessType) === BusinessType.SOLE_PARTNERSHIP ||
        Number(businessType) === BusinessType.TRUST ||
        Number(businessType) === BusinessType.UNICORPORATED_ASSOCIATION)
    ) {
      setLandlordType("businessSolo");
      dispatch(
        actions.setIdVerficationRequestData({
          name: "LandlordType",
          value: "businessSolo",
          form: "idVerificationData",
        })
      );
      dispatch(
        actions.setIdVerficationRequestData({
          name: "Benificary",
          value: "",
          form: "idVerificationData",
        })
      );
    } else if (
      ownerType === "business" &&
      (Number(businessType) === BusinessType.CORPORATION ||
        Number(businessType) === BusinessType.LLCS ||
        Number(businessType) === BusinessType.NON_PROFITS ||
        Number(businessType) === BusinessType.PARTNERSHIP ||
        Number(businessType) === BusinessType.PUBLICLY_TRADED_CORPORATIONS)
    ) {
      setLandlordType("business");
      dispatch(
        actions.setIdVerficationRequestData({
          name: "LandlordType",
          value: ownerType + "",
          form: "idVerificationData",
        })
      );
      dispatch(
        actions.setIdVerficationRequestData({
          name: "Benificary",
          value: benificary + "",
          form: "idVerificationData",
        })
      );
    } else {
      dispatch(
        actions.setIdVerficationRequestData({
          name: "LandlordType",
          value: ownerType + "",
          form: "idVerificationData",
        })
      );
      dispatch(
        actions.setIdVerficationRequestData({
          name: "Benificary",
          value: "",
          form: "idVerificationData",
        })
      );
    }
  }, [dispatch]);

  const handleSubmit = () => {
    if(loggedinUser?.role=="tenant")
    {
        navigation("/tenant-account");
    }else{
       dispatch(propertyActions.setStateStatus("Auth"));
    navigation("/add-property");
    }
  

   
  };
  useEffect(() => {
    console.log("loggedinUser",loggedinUser);
    
    dispatch(
      actions.setIdVerficationRequestData({
        name: "UserId",
        value: loggedinUser?.userId
          ? loggedinUser?.userId + ""
          : localStorage.getItem("ownerId") + "",
        form: "idVerificationData",
      })
    );
  }, [loggedinUser]);

  useEffect(() => {
    if (userVerification && bankVerification && ownerType === "individual") {
      setDisabled(false);
    } else if (
      userVerification &&
      bankVerification &&
      ownerType === "business" &&
      (Number(businessType) === BusinessType.TRUST ||
        Number(businessType) === BusinessType.SOLE_PARTNERSHIP ||
        Number(businessType) === BusinessType.UNICORPORATED_ASSOCIATION)
    ) {
      setDisabled(false);
    } else if (userVerification && bankVerification && ownerVerification) {
      setDisabled(false);
    }  else if ( bankVerification &&loggedinUser?.role=="tenant" ) {
      setDisabled(false);
    }else {
      setDisabled(true);
    }
  }, [userVerification, bankVerification, ownerVerification]);

  return (
    <>
      <Loader loading={loading} />
      <Page>
        <PageImageHeader />

        <div
          className={styles.sheet}
          style={{
            top: height / 2.82,
            marginBottom: "10px",
            width: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
          }}
        >
          <div className={styles.pageHeading}>Create an Account</div>
          {loggedinUser?.role!=="tenant" &&
          <UserVerification
            ownerType={ownerType}
            businessType={businessType}
            benificary={benificary}
            connectionResponse={(output) => {
              if (output) {
                setUserVerification(output);
              }
            }}
          /> }
          <BankVerification
            connectionResponse={(output) => {
              if (output) {
                setBankVerification(output);
              }
            }}
          />

          {loggedinUser?.role!=="tenant" && ownerType === "business" &&
            (Number(businessType) === BusinessType.LLCS ||
              Number(businessType) === BusinessType.CORPORATION ||
              Number(businessType) === BusinessType.NON_PROFITS ||
              Number(businessType) === BusinessType.PARTNERSHIP ||
              Number(businessType) ===
                BusinessType.PUBLICLY_TRADED_CORPORATIONS) && (
              <BanificialOwnerVerification
                connectionResponse={(output) => {
                  if (output) {
                    setOwnerVerification(output);
                  }
                }}
              />
            )}
          <div
            style={{
              marginBottom: "30px",
              width: "90%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              margin: "auto",
            }}
          >
            <Button
              disabled={disabled}
              className={styles.signInButton}
              variant="contained"
              onClick={handleSubmit}
            >
             {loggedinUser?.role!=="tenant"&& <img src={disabled ? greyPng : whitePng} />}
             {loggedinUser?.role!=="tenant"?"Add your first property":"Continue"}  
            </Button>
            {loggedinUser?.role!=="tenant"&&  <Typography
              style={{
                letterSpacing: "0.1px",
                fontFamily: "Open Sans",
                fontSize: "10px",
                fontWeight: "400",
                marginBottom: 20,
                marginTop: 10,
              }}
            >
              Adding properties will be Processed only when{" "}
              <span style={{ fontWeight: "600" }}>ID Verification</span> is{" "}
              <span style={{ fontWeight: "600" }}>OK</span>.
            </Typography>}
          </div>
          <CopyRightSection />
        </div>
      </Page>
    </>
  );
};

export default IDVerification;
