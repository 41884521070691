import { RootState } from "../../../../types";
import { createSelector } from "@reduxjs/toolkit";
import { TenantState } from "./types";

const selectDomain = (state: RootState) => state.tenantState || TenantState;

export const selectLoading = createSelector(
  [selectDomain],
  (state) => state.loading
);
export const selectDashboardData = createSelector(
  [selectDomain],
  (state) => state.dashboardData
);
export const selectImageUploadUrl = createSelector(
  [selectDomain],
  (state) => state.ImageUploadUrl
);
export const selectTanantError = createSelector(
  [selectDomain],
  (state) => state.error
);
export const selectServiceRequestData = createSelector(
  [selectDomain],
  (state) => state.serviceRequestData
);

export const selectMaintananceRequest = createSelector(
  [selectDomain],
  (state) => state.MaintananceRequestBody
);

export const selectServiceRequests = createSelector(
  [selectDomain],
  (state) => state.serviceRequests
);

export const selectServiceImageUploadUrl = createSelector(
  [selectDomain],
  (state) => state.imageUploadUrl
);

export const selectVideoFile = createSelector(
  [selectDomain],
  (state) => state.videoFile
)

export const selectUploadedVideoURL = createSelector(
  [selectDomain],
  (state) => state.videoUploadUrl
);

export const selectS3UploadedVideoURL = createSelector(
  [selectDomain],
  (state) => state.videoUploadedUrl
);

export const selectedServiceRequests = createSelector(
  [selectDomain],
  (state) => state.selectedServiceRequests  
);

export const SelectedPortfolio = createSelector(
  [selectDomain],
  (state) => state.selectedPortfolio
);

export const selectMessages = createSelector([selectDomain], (state) => state.messages);

