import {
    Button,
    Typography,
    IconButton,
    InputAdornment,
    TextField,
    Box,
    Grid,
    Dialog,
    Select,
    MenuItem,
    Radio,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  
  import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
  import { useStyles } from "./styles";
  import { useNavigate } from "react-router-dom";
  import { useSelector } from "react-redux";
  import { useDispatch } from "react-redux";
  import { selectUser } from "app/containers/Auth/selectors";
import CopyRightSection from "app/components/CopyRight";
  
    const BalanceHelpPopup = ({ showPopup, closePopup }) => {
        const styles = useStyles();

    const [show, setShow] = useState(false);

    useEffect(() => {
    setShow(showPopup); 
    }, [showPopup]);

    const close = () => {
    setShow(false);
    closePopup();
    };

    return (
      <Dialog open={show} className={styles.popup}>
        <Box sx={{ display: "flex", flexDirection: "column", margin: "16px" }}>
        <Typography className={styles.modalTitle}>Pending Balance Details</Typography>
        <Typography className={styles.modalSubTitle}>
        <span style={{fontWeight: '600'}}>Pending Balance </span> is the Balance held in your virtual account with payment gateway due to pending service transaction or due to failed ones.
        </Typography>
        <Typography className={styles.modalSubTitle}>
        Failed transaction could be service payment transaction that failed on the service provider side and the funds returned to the Balance, or a failed rental payment transaction on the landlord side.        </Typography>
        <Typography className={styles.modalSubTitle}>
        If you made any changes to your bank account, please edit the fund source from settings.
        </Typography>

            <div
            style={{
            marginBottom: "10px",
            width: "90%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            margin: 'auto'
            }}
            >
                <Button
                className={styles.signInButton}
                variant="contained"
                onClick={close}
                >
                    Close
                </Button>

            </div>

            <CopyRightSection/>
        </Box>
      </Dialog>
    );
  };
  
  export default BalanceHelpPopup;
  
  
  