import { FormError } from "app/containers/types";
import { CreateControllerOwnerSRE } from "../../../redux/types";
import { PersonType } from "../../../redux/enum";
import { ageValidator, stateValidator } from "utils/helpers";

export function valdiator(form: CreateControllerOwnerSRE): Array<FormError> {
  const errors: Array<FormError> = [];
   if (!form.firstName.value) {
    errors.push({
      name: "firstName",
      error: "Firstname is required",
    });
  }
  if (!form.lastName.value) {
    errors.push({
      name: "lastName",
      error: "Lastname is required",
    });
  }
  if (!form.title.value && form.userType.value === 0) {
    errors.push({
      name: "title",
      error: "Title is required",
    });
  }

  if ((Number(form.personType.value) === PersonType.US) && !form.ssn.value) {
    errors.push({
      name: "ssn",
      error: "SSN is required",
    });
  }

  if ((Number(form.personType.value) === PersonType.US) && form.ssn.value && form.ssn.value.length !== 9) {
    errors.push({
      name: "ssn",
      error: "SSN should 9 Digits",
    });
  }

  if (!form.dob.value) {
    errors.push({
      name: "dob",
      error: "DOB is required",
    });
  }

    if(form.dob.value && !ageValidator(form.dob.value))
    {
    errors.push({
    name: "dob",
    error: "Must be between 18 to 125 years of age.",
    });
    }

  if((Number(form.personType.value) === PersonType.NON_US) && !form.passportNumber.value)
    {
    errors.push({
      name: "passportNumber",
      error: "Passport Number is required",
    });
  }

  if((Number(form.personType.value) === PersonType.NON_US) && !form.issuanceCountry.value)
    {
    errors.push({
      name: "issuanceCountry",
      error: "Issuance Country is required",
    });
  }

  if ((Number(form.personType.value) === PersonType.NON_US) && !form.country.value) {
    errors.push({
      name: "country",
      error: "Country is required",
    });
  }

  if (!form.address.value) {
    errors.push({
      name: "address",
      error: "Address is required",
    });
  }

  if (!form.city.value) {
    errors.push({
      name: "city",
      error: "City is required",
    });
  }

  if (!form.state.value) {
    errors.push({
      name: "state",
      error: "State is required",
    });
  }

  if (form.state.value && !stateValidator(form.state.value)) {
    errors.push({
      name: "state",
      error: "State must be a 2-letter abbreviation with Uppercase.",
    });
  }

  if (!form.zip.value) {
    errors.push({
      name: "zip",
      error: "Zip is required",
    });
  }

  if (form.zip.value && form.zip.value.length < 5) {
    errors.push({
      name: "zip",
      error: "Invalid Zip",
    });
  }

  return errors;
}
