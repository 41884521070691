import { call, delay, put, select, takeLatest } from "redux-saga/effects";
import { actions } from "./slice";
import { getDefaultHeaders } from "utils/helpers";
import { selectToken, selectUser } from "app/containers/Auth/selectors";
import queryString from "query-string";
import { fileRequest, request } from "utils/request";
import { APP_API_URL } from "utils/constants";
import { ImageUploadRequest } from "./types";
import { toast } from "react-toastify";
import { selectS3UploadedVideoURL, selectServiceImageUploadUrl } from "./selector";
import { LoggerClass as Logger } from "utils/logger";

const response = {
  items: [
    {
      title: "Me",
      description:
        "Hello, I need to postpone the rental payment this month for few days.",
      date: new Date(2022, 12, 24, 4, 0),
    },
    {
      title: "David Snow",
      description: "No problem",
      date: new Date(2022, 12, 24, 5, 8),
    },
    {
      title: "Me",
      description: "Thanks! I will Pay on sunday",
      date: new Date(2022, 12, 24, 5, 10),
    },
  ],
};
async function* redirect() {
  yield put(actions.resetModule());
  return (window.location.href = "/auth");
}
export function* getMessages(action) {
  yield delay(500);
  try {
    yield put(actions.getMessagesSuccess(response.items));
  } catch (e: any) {
    alert("Something Went Wrong");
  }
}

export function* getDashboardData(action) {
  yield delay(500);
  const token = yield select(selectToken);
  const options = {
    method: "POST",
    headers: { ...getDefaultHeaders(), Authorization: `Bearer ${token}` },
  };
  const queries = queryString.stringify({
    tenantId: action?.payload
      ? action?.payload
      : JSON.parse(localStorage.getItem("loginResponse")!).userId,
  });
  try {
    const response = yield call(
      request,
      `${APP_API_URL}/propertiesManagement/Dashboard/GetTenantDashboard?${queries}`,
      options
    );
    if (response.Status === 401 || response.Status === 400) {
      yield put(actions.getDashboardDataError(response.Message));
      redirect();
      return false;
    }
    if (!response.Result) {
      yield put(actions.getDashboardDataError(response.Message));
      return false;
    }
    yield put(actions.getDashboardDataSuccess(response.Data));
    localStorage.setItem("latitude", response?.Data?.Latitude + "");
    localStorage.setItem("longitude", response?.Data?.Longitude + "");
    localStorage.setItem("propertyId", response?.Data?.PropertyId);
    localStorage.setItem("propertyAddress", response?.Data?.Address);
    localStorage.setItem("OwnerId", response?.Data?.OwnerId);
  } catch (err: any) {
    yield put(actions.getDashboardDataError(err));
    return false;
  }
}

export function* getDocumentUploadUrlRequest(action) {
  yield delay(500);
  try {
    const token = yield select(selectToken);
    let formData = new FormData();
    formData.append("file", action.payload.fileData);
    formData.append("path", action.payload.contentType);
    const options = {
      method: "POST",
      headers: { Authorization: `Bearer ${token}` },
      body: formData,
    };
    const response = yield call(
      fileRequest,
      `${APP_API_URL}/s3upload/FileUploader`,
      options
    );
    if (response.Status === 401) {
      yield put(actions.setError(response.Message));
      return false;
    }
    if (response.bucketURL) {
      var requestData = {
        propertyId: action.payload.addImageRequest.propertyId,
        uploadedByUserId: action.payload.addImageRequest.uploadedByUserId,
        documentURL: response.bucketURL,
        type: "image",
        isActive: true,
      };
      requestData.documentURL = response.bucketURL;
      const optionsAI = {
        method: "POST",
        headers: { ...getDefaultHeaders(), Authorization: `Bearer ${token}` },
        body: JSON.stringify(requestData),
      };
      const imageUploaded = yield call(
        request,
        `${APP_API_URL}/propertiesManagement/PropertiesDocuments`,
        optionsAI
      );
      if (imageUploaded.Status === 401) {
        yield put(actions.setError(imageUploaded.Message));
        return false;
      }
      yield put(actions.getDocumentUploadUrlSuccess(response.bucketURL));
      yield put(actions.setLoaderFalse());
    } else {
      yield put(actions.getDocumentUploadUrlError("Document Not Uploaded"));
    }
  } catch (err: any) {
    yield put(actions.getDocumentUploadUrlError(err));
  }
}

export function* getServices(action) {
  yield delay(500);
  try {    
  const token = yield select(selectToken);
  const user = yield select(selectUser);
  const options = {
    method: "POST",
    headers: { ...getDefaultHeaders(), Authorization: `Bearer ${token}` } ,
    body: JSON.stringify({
      propertyIds : action.payload.propertyIds,
      pageNumber : action.payload.pageNumber,
      pageSize : action.payload.pageSize,
      openTime : action.payload.openTime ? action.payload.openTime : '',
      status : [] //action.payload.status ? action.payload.status : ''
    }),  
  };

  const response = yield call(
      request,
      `${APP_API_URL}/serviceRequestManagement/ServiceRequest/GetServiceRequests`,
      options
    )    
    if(response.Status === 401 || response.Status === 400) {      
      yield put(actions.getServicesError(response.Message));  
      redirect();    
      return false;
    }

    if(!response.Result) {
      yield put(actions.getServicesError(response.Message));
      return false;
    }    

    yield put(actions.getServicesSuccess(response));
  } catch (err: any) {
    yield put(actions.getServicesError(err));
    return false;
 }
}

export function* createServiceRequest(action) {
  yield delay(500);
  try {
    Logger.log(action.payload);
    const token = yield select(selectToken);
    const imageURL = yield select(selectServiceImageUploadUrl);
    const videoURL = yield select(selectS3UploadedVideoURL);    
    const localPropertyId = localStorage.getItem("property");
    const propertyAddress = localStorage.getItem("propertyAddress");
    const localUserId = JSON.parse(localStorage.getItem("loginResponse")!).userId;
    
    const options = {
      method: "POST",
      headers: { ...getDefaultHeaders(), Authorization: `Bearer ${token}` },
      body: JSON.stringify({
        openedBy: action.payload.data.OpenedBy.value ? action.payload.data.OpenedBy.value : localUserId,
        propertyId: action.payload.data.PropertyId.value ? action.payload.data.PropertyId.value : localPropertyId,
        PropertyAddress: action.payload.data.PropertyAddress.value ? action.payload.data.PropertyAddress.value : propertyAddress,
        serviceType: action.payload.data.ServiceType,
        description: action.payload.data.Description.value,        
        image: imageURL ? [
           {
            propertyId: action.payload.data.PropertyId.value ? action.payload.data.PropertyId.value : localPropertyId,
            documentURL: imageURL,
            type: "image",
            isActive: true,
          }
        ] : [],
        video: videoURL ? [
           {
            propertyId: action.payload.data.PropertyId.value ? action.payload.data.PropertyId.value : localPropertyId,
            documentURL: videoURL,
            type: "video",
            isActive: true,
          } 
        ] : [],
      }),
    };

    
    const response = yield request(
      `${APP_API_URL}/serviceRequestManagement/ServiceRequest/AddServiceRequest`,
      options
    );

    if (response.Status == 401 || response.Status == 400) {
      yield put(actions.createServiceRequestError(response.Message));
      redirect();
      return false;
    }

    if (!response.Result) {
      yield put(actions.createServiceRequestError(response));
      return false;
    }

    yield put(actions.createServiceRequestSuccess(response));
    toast.success('Service Request Created'); 
    yield call(action.payload?.callback);   
  } catch (e: any) {
    yield put(actions.createServiceRequestError(e));
    return false;
  }
}

export function* getUploadUrlRequest(action: any) {
   yield delay(500);
   try {
  const token = yield select(selectToken);
  let formData = new FormData();
  formData.append("fileName", action.payload.fileName);
  formData.append("path", action.payload.contentType);
  const options = {
    method: "POST",
    headers: { Authorization: `Bearer ${token}` },
    body: formData,
  };

    const response = yield call(
      request,
      `${APP_API_URL}/s3upload/FileUploader/GetPreSignedURLForFileUpload`,
      options
    )

    yield put(actions.getUploadUrlSuccess(response));
  } catch (err: any) {
    yield put(actions.getUploadUrlError(err));
  }
}

export function* setSelectedServiceRequest(action) {
  yield delay(500);
  try {    
  const token = yield select(selectToken);
 
  const options = {
    method: "GET",
    headers: { ...getDefaultHeaders(), Authorization: `Bearer ${token}` }    
  };
  const queries = queryString.stringify({
    serviceRequestID : action.payload.id
  });

    const response = yield call(
      request,
      `${APP_API_URL}/serviceRequestManagement/ServiceRequest?${queries}`,
      options
    )

    if(response.Status === 401 || response.Status === 400) {      
      yield put(actions.setSelectedServiceRequestError(response.Message));
      redirect();
      return false;
    }

    if(!response.Result) {
      yield put(actions.setSelectedServiceRequestError(response.Message));
      return false;
    }    

    yield put(actions.setSelectedServiceRequestSuccess(response));
    yield call(action.payload?.callback);
  } catch (err: any) {
    yield put(actions.setSelectedServiceRequestError(err));
    return false;
  }
}

export function* setSelectedPortfolioDetails(action) {
  yield delay(500);
  try {    
  const token = yield select(selectToken);
  const user = yield select(selectUser);
  
  const options = {
    method: "GET",
    headers: { ...getDefaultHeaders(), Authorization: `Bearer ${token}` }    
  };
  const queries = queryString.stringify({
    ownerId: localStorage.getItem("OwnerId"),
    propertyId : action.payload.id ? action.payload.id : localStorage.getItem("PropertyId")
  });

    const response = yield call(
      request,
      `${APP_API_URL}/propertiesManagement/Property/GetPropertyDetails?${queries}`,
      options
    )

    if(response.Status === 401 || response.Status === 400) {      
      yield put(actions.setSelectedPortfolioDetailsError(response.Message));
      redirect();
      return false;
    }

    if(!response.Result) {
      yield put(actions.setSelectedPortfolioDetailsError(response.Message));
      return false;
    }    

    yield put(actions.setSelectedPortfolioDetailsSuccess(response.Data));
  } catch (err: any) {
    yield put(actions.setSelectedPortfolioDetailsError(err));
    return false;
  }
}


export function* useTenantSaga() {
  yield takeLatest(actions.getMessages.type, getMessages);
  yield takeLatest(actions.getDashboardData.type, getDashboardData);
  yield takeLatest(
    actions.getDocumentUploadUrl.type,
    getDocumentUploadUrlRequest
  );
  yield takeLatest(actions.getServices.type, getServices);
  yield takeLatest(actions.getUploadUrl.type, getUploadUrlRequest);
  yield takeLatest(actions.createServiceRequest.type, createServiceRequest); 
  yield takeLatest(actions.setSelectedServiceRequest.type, setSelectedServiceRequest);
  yield takeLatest(actions.setSelectedPortfolioDetails.type, setSelectedPortfolioDetails);

}
