import * as React from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import useStyles from "./styles";
import { Typography } from "@mui/material";
export default function DocumentTypeDropDown({ handleChange, value, name, state }) {
  const styles = useStyles(); 
  return (
        <Select
            // MenuProps={{ classes: { paper: styles.menuPaper } }}
            sx={{maxHeight:'100px', width: '100%'}}
            id={name}
            name={name}
            value={value}
            className={styles.select}
            onChange={
              handleChange}
            renderValue={() => (
              <Typography
               className={styles.typog}>
                {value}
              </Typography>
            )}
          >
              <MenuItem style={{whiteSpace: 'normal'}}
                  value={'0-Passport'}>Passport
              </MenuItem>
              <MenuItem style={{whiteSpace: 'normal'}}
                  value={'1-License'}>License
              </MenuItem>
              <MenuItem style={{whiteSpace: 'normal'}}
                  value={'2-IdCard'}>IdCard
              </MenuItem> 
              {state === 'businessSolo' && <MenuItem style={{whiteSpace: 'normal'}}
                  value={'3-Other'}>Other
              </MenuItem> }                     
      </Select>
  );
}
