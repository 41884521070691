import {
  Button,
  Typography,
  IconButton,
  InputAdornment,
  TextField,
  Box,
  Grid,
  Dialog,
  Select,
  MenuItem,
  Radio,
  Checkbox,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import { actions, reducer, sliceKey } from "../../../redux/slice";
import { usePropertySaga } from "../../../redux/saga";
import { style } from "@mui/system";
import useStyles from "./styles";
import OtpInput from "react-otp-input";
import { LoggerClass as Logger } from "utils/logger";

type Props = {};
const DeleteTenant = ({
  showPopup,
  closePopup = () => {},
  handleClose = () => {},
  handleDelete,
  values
}) => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: usePropertySaga });
  const styles = useStyles();
  const [show, setShow] = useState(false);
  const [otp, setOtp] = useState("");
  const [disable, setDisable] = useState(true);
  const [selectedCheck, setSelectedCheck] = useState<any>([]);

  const close = () => {
    setShow(false);
    closePopup();
  };

  useEffect(() => {
    if (otp?.length === 4) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  }, [otp?.length]);

  useEffect(() => {
    setSelectedCheck([]);
    setShow(showPopup);
  }, [showPopup]);

  return (
    <Dialog open={show} className={styles.popup}>
      <Box sx={{ display: "flex", flexDirection: "column", margin: "16px" }}>
        <div
          style={{
            fontFamily: "Open Sans",
            fontSize: 18,
            color: "#192342",
            fontWeight: "400",
            textAlign: "center",
          }}
        >
          Please choose the tenant which you want to delete from the property
          <span
            style={{
              fontFamily: "Open Sans",
              fontSize: 18,
              color: "#192342",
              fontWeight: "600",
            }}
          >
            {" "}
            3429 Adkins, Rd. NW Atlanta, GA 30331
          </span>
        </div>
        {values?.map((item, index) => {
          return (
            <div className={styles.checkBoxView}>
              <Checkbox
                onClick={() => {
                  setSelectedCheck((selectedCheck) => [
                    ...selectedCheck,
                    item?.UserName,
                  ]);
                }}
                sx={{
                  color: "#192342",
                  "&.Mui-checked": {
                    color: "#192342",
                  },
                }}
              />
              <div className={styles.checkBoxTitle}>{item?.UserName}</div>
            </div>
          );
        })}

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: 25,
          }}
        >
          <Button
            className={styles.outlinebtn}
            onClick={() => {
              handleDelete(selectedCheck);
            }}
            style={{ marginLeft: 0 }}
            variant="outlined"
          >
            Delete
          </Button>
          <Button
            className={styles.signInButton}
            onClick={close}
          >
            Cancel
          </Button>
        </div>
      </Box>
    </Dialog>
  );
};

export default DeleteTenant;
