import {
  Button,
  Typography,
  IconButton,
  InputAdornment,
  TextField,
  Box,
  Grid,
  Dialog,
  Select,
  MenuItem,
  Radio,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { useInjectReducer, useInjectSaga } from "utils/redux-injectors";
import { actions, reducer, sliceKey } from "../redux/slice";
import { usePropertySaga } from "../redux/saga";
import { style } from "@mui/system";
import { useStyles } from "../styles";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { addLeaseData, selectPropertyList, selectPropertyListDD } from "../redux/selector";
import { useDispatch } from "react-redux";
import { selectUser } from "app/containers/Auth/selectors";

const LeaseDialog = ({ showPopup, closePopup }) => {
  useInjectReducer({ key: sliceKey, reducer: reducer });
  useInjectSaga({ key: sliceKey, saga: usePropertySaga });
  const dispatch = useDispatch();
  const styles = useStyles();
  const addLease = useSelector(addLeaseData);
  const loggedInUser = useSelector(selectUser);
  const propertyList = useSelector(selectPropertyListDD);  
  const navigation = useNavigate();
  const [show, setShow] = useState(false);
  const [reportType, setReportType] = useState<any>("Choose property");

  const userId = loggedInUser?.userId ? loggedInUser?.userId : JSON.parse(localStorage.getItem('loginResponse')!).userId;
  const jwtToken = loggedInUser?.jwtToken ? loggedInUser?.jwtToken : JSON.parse(localStorage.getItem('loginResponse')!).jwtToken;


  const close = () => {
    setShow(false);
    setReportType("Choose property")
    closePopup();
  };

  useEffect(() => {
    setShow(showPopup); 
    if(jwtToken && userId) {
    dispatch(actions.getPropertyListViaOwnerId()); 
    }  
  }, [showPopup]);

  const handlePropertyChange = (event) => {
    const option = event.target.value;
    setReportType(option.split("_")[1]);
    const { name, value } = event.target;
    dispatch(actions.resetLeaseData());
    dispatch(actions.setDropdownSelectedId(value.split("_")[0]));
    dispatch(actions.setLeaseData({ name, value : value.split("_")[0], form: "addLease" }));
    dispatch(actions.setLeaseData({ name:'ownerId', value:loggedInUser.userId+"", form: "addLease" }));
  };

  const handleContinue = (event) => {
      localStorage.setItem("property", addLease?.PropertyId.value);
      dispatch(actions.getLeaseDataByPropertyId({data: addLease.PropertyId.value+"", callback: onLoginSuccess}))
  }

  const onLoginSuccess = () => {
    navigation('add-new-lease');
  };

  return (
    <Dialog open={show} className={styles.popup}>
      <Box sx={{ display: "flex", flexDirection: "column", margin: "16px" }}>
        <Typography className={styles.modalTitle}>Add New Lease</Typography>
        <Typography className={styles.modalSubTitle}>
          Select the property you want to add new lease for:
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography className={styles.typo60014}>Properties</Typography>
          <Select
            MenuProps={{ classes: { paper: styles.menuPaper } }}
            inputProps={{ classes: { paper: styles.menuInput } }}
            sx={{maxHeight:'100px'}}
            id="reportType"
            name="PropertyId"
            value={reportType}
            className={styles.select}
            onChange={handlePropertyChange}
            renderValue={() => (
              <Typography className={styles.typoGray40016}>
                {reportType}
              </Typography>
            )}
          >
            {propertyList.items && propertyList.items.map((item) => {
                    return (<MenuItem style={{whiteSpace: 'normal'}}
                  value={item.PropertyId+"_"+item.Address}>{item.Address}</MenuItem>)
                })}
          </Select>          
        </Box>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Button
            disabled={!reportType || reportType == "Choose property" ? true : false}
            className={styles.signInButton}           
            onClick={handleContinue}
          >
            Continue
          </Button>
          <Button
            variant="outlined"
            className={styles.outlinebtn}
            onClick={close}
          >
            Cancel
          </Button>
        </div>
      </Box>
    </Dialog>
  );
};

export default LeaseDialog;


